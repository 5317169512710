import { FC } from 'react';
import { Typography } from '@mui/material';
// components
// styles, interface, constants
import { NoDataCardProps } from '../../interfaces';
import { NO_CARRIER_DESCRIPTION_TEXT, NO_CARRIER_TEXT } from 'constants/index';

const NoDataCard: FC<NoDataCardProps> = ({
  title = NO_CARRIER_TEXT,
  description = NO_CARRIER_DESCRIPTION_TEXT,
}) => {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>

      <Typography variant="body2" color={`customGrayColor.light`} gutterBottom>
        {description}
      </Typography>
    </>
  );
};

export default NoDataCard;
