import { FC, Fragment, JSX } from 'react';
import { Box, Typography } from '@mui/material';
//components
import ProductTypeTable from './ProductTypeTable';
// constant, styles, reducer, graphql
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { PRODUCT_TYPE_TEXT } from 'constants/index';

const ProductType: FC = (): JSX.Element => {
  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{PRODUCT_TYPE_TEXT}</Typography>
      </Box>
      <ProductTypeTable />
    </Fragment>
  );
};

export default ProductType;
