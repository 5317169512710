import { FC, useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
// components
import { DashboardStatsChartImg } from 'assets/svgs';
import DashboardStatsCard from '../DashboardStatsCard';
import StaffTableDashboard from './DashboardStaffTable';
import DashboardTopContributorCard from './DashboardTopContributorCard';
import UserProfilePopover from 'components/main/Dashboard/UserAvatarPopover';
// styles, interfaces
import {
  HTTP_STATUS,
  DASHBOARD_TEXT,
  orgInitialValues,
  TOTAL_STAFF_TEXT,
  FORMULAS_CREATED_TEXT,
  CREATED_INGREDIENTS_TEXT,
} from 'constants/index';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { OrgDashboardPayload, useGetAdminDashboardLazyQuery } from 'generated/graphql';

const AdminDashboard: FC = () => {
  const { palette } = useTheme();

  const [data, setData] = useState<OrgDashboardPayload>(orgInitialValues);

  const [getAdminDashboard, { loading }] = useGetAdminDashboardLazyQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,

    onCompleted: (res) => {
      const { getOrgDashboard } = res || {};
      const { response } = getOrgDashboard || {};
      const { status } = response || {};

      if (status === HTTP_STATUS.SUCCESS) {
        setData(getOrgDashboard);
      } else {
        setData(orgInitialValues);
      }
    },
    onError: () => {
      setData(orgInitialValues);
    },
  });

  const fetchDashboard = useCallback(async () => {
    try {
      await getAdminDashboard();
    } catch (error) {
      console.log('error', error);
    }
  }, [getAdminDashboard]);

  useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard]);

  const { ingredients, formulas, users } = data || {};

  return (
    <>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{DASHBOARD_TEXT}</Typography>

        <UserProfilePopover />
      </Box>

      <Grid container spacing={3} mt={1}>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <DashboardStatsCard
            loading={loading}
            icon={<DashboardStatsChartImg color={palette.primary.main} />}
            stats={`${users ?? 0}`}
            title={TOTAL_STAFF_TEXT}
          />
        </Grid>

        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <DashboardStatsCard
            loading={loading}
            icon={<DashboardStatsChartImg color={palette.bluePaletteColor.main} />}
            stats={`${ingredients ?? 0}`}
            title={CREATED_INGREDIENTS_TEXT}
          />
        </Grid>

        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <DashboardStatsCard
            loading={loading}
            icon={<DashboardStatsChartImg color={palette.customRedColor.main} />}
            stats={`${formulas ?? 0}`}
            title={FORMULAS_CREATED_TEXT}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={1}>
        <Grid item xl={8} lg={12} md={12} sm={12} xs={12}>
          <StaffTableDashboard />
        </Grid>
        <Grid item xl={4} lg={12} md={12} sm={12} xs={12}>
          <DashboardTopContributorCard />
        </Grid>
      </Grid>
    </>
  );
};

export default AdminDashboard;
