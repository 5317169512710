import { FC, Fragment, JSX } from 'react';
import { Box, Typography } from '@mui/material';
// components
import SubProductTypeTable from './SubProductTypeTable';
// constant, styles, reducer, graphql
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { SUB_PRODUCT_TYPE_TEXT } from 'constants/index';

const SubProductType: FC = (): JSX.Element => {
  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{SUB_PRODUCT_TYPE_TEXT}</Typography>
        {/* <Button variant="contained" color="primary" onClick={() => navigate(ADD_SUB_PRODUCT_TYPE_ROUTE)} >
          <AddRoundedIcon />
          {ADD_NEW_TEXT}
        </Button> */}
      </Box>
      <SubProductTypeTable />
    </Fragment>
  );
};

export default SubProductType;
