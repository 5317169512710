import { FC, useContext } from 'react';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
// components
import IngredientsList from '../../Dosage/Add/components/IngredientsList';
// constants, reducer, context, interfaces
import { ActionType } from 'reducer/addFactBoxReducer';
import { BlendItemType, FormulaIngredientFormType } from 'interfaces';
import { FormulaIngredientContext } from 'contexts/FormulaByIngredientContext';

const Step1: FC = () => {
  const { watch } = useFormContext<FormulaIngredientFormType>();
  const { state, dispatch } = useContext(FormulaIngredientContext);

  const { ingredientsBlend } = state || {};
  const { formulaIngredients } = watch();

  const onBlendAdd = (data: BlendItemType[]) => {
    dispatch({ type: ActionType.SET_INGREDIENTS_BLEND, ingredientsBlend: data });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={7}>
        <IngredientsList
          xl={4}
          isFactBox
          isShowCategory
          showAddBlendBtn={false}
          onBlendAdd={onBlendAdd}
          ingredientsBlend={ingredientsBlend}
          formulaIngredients={formulaIngredients}
        />
      </Grid>
    </Grid>
  );
};

export default Step1;
