import { FC, Fragment, useContext, useState } from 'react';
import { Box, Card, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { KeyboardArrowDown as ArrowDownIcon, KeyboardArrowUp as ArrowUpIcon } from '@mui/icons-material';
// components
import RowView from 'components/common/RowView';
import DataLoader from 'components/common/DataLoader';
// context
import { formatToLowerCase, formatValue } from 'lib/helper';
import { FormulaDetailContext } from 'contexts/FormulaDetailContext';
import { VALUE_TEXT, POTENCY_TEXT, TYPE_TEXT, WEIGHT_TEXT, COST_TEXT } from 'constants/index';

const FormulaBlends: FC = () => {
  const { state, loading } = useContext(FormulaDetailContext);

  const { formula } = state;
  const { formulaBlends } = formula || {};

  const [blendOpenState, setBlendOpenState] = useState(
    (formulaBlends || []).reduce((acc, blend) => {
      if (blend && blend.id) {
        acc[blend.id] = false;
      }
      return acc;
    }, {} as Record<string, boolean>),
  );

  const handleBlendClick = (blendId: string) => {
    setBlendOpenState((prevState) => ({
      ...prevState,
      [blendId]: !prevState[blendId],
    }));
  };

  return (
    <Fragment>
      {loading ? (
        <DataLoader columns={12} rows={2} />
      ) : (
        formulaBlends?.map((item) => {
          const { name, formulaIngredients } = item || {};
          return (
            <Box
              flex={1}
              padding={2}
              mt={1.5}
              key={name}
              borderRadius={1}
              alignItems="center"
              bgcolor={'primary.main'}>
              <Box>
                <Typography variant="caption" fontWeight={700}>
                  {name}
                </Typography>
                <Box ml={1}>
                  {formulaIngredients?.map((item) => {
                    const { ingredient, potency, unit, type, value, mgWeight, totalCost } = item || {};
                    const { name: ingredientName, id } = ingredient || {};
                    const potencyInPercentage = potency ? potency * 100 : 0;
                    return (
                      <Fragment key={id}>
                        <Box
                          display="flex"
                          alignItems="center"
                          mt={1}
                          onClick={() => handleBlendClick(id ?? '')}>
                          <Typography ml={1} variant="caption" fontWeight={700} flex={1}>
                            {ingredientName}
                          </Typography>
                          <IconButton aria-label="expand row" size="small">
                            {id && blendOpenState[id] ? <ArrowUpIcon /> : <ArrowDownIcon />}
                          </IconButton>
                        </Box>
                        <Collapse in={(id && blendOpenState[id]) || false} timeout="auto" unmountOnExit>
                          <Card sx={{ borderRadius: 2 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} xl={4}>
                                <RowView
                                  mt={0}
                                  name={VALUE_TEXT}
                                  flexDirection="column"
                                  value={`${value ?? '--'} ${unit ? formatToLowerCase(unit) : ''}`}
                                />
                              </Grid>

                              <Grid item xs={12} xl={4}>
                                <RowView
                                  mt={0}
                                  name={POTENCY_TEXT}
                                  flexDirection="column"
                                  value={`${potencyInPercentage}`}
                                />
                              </Grid>
                              <Grid item xs={12} xl={4}>
                                <RowView
                                  mt={0}
                                  name={TYPE_TEXT}
                                  flexDirection="column"
                                  value={type ? formatValue(type) : '--'}
                                />
                              </Grid>

                              <Grid item xs={12} xl={4}>
                                <RowView
                                  mt={0}
                                  flexDirection="column"
                                  name={WEIGHT_TEXT}
                                  value={`${mgWeight || '--'} ${unit ? formatToLowerCase(unit) : ''}`}
                                />
                              </Grid>

                              <Grid item xs={12} xl={4}>
                                <RowView
                                  mt={0}
                                  flexDirection="column"
                                  name={COST_TEXT}
                                  value={totalCost ? `$ ${totalCost}` : '0'}
                                />
                              </Grid>
                            </Grid>
                          </Card>
                        </Collapse>
                      </Fragment>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          );
        })
      )}
    </Fragment>
  );
};

export default FormulaBlends;
