import { useNavigate } from 'react-router-dom';
import { Chip, TableCell, TableRow } from '@mui/material';
import { FC, Fragment, Reducer, useCallback, useEffect, useReducer } from 'react';
// components
import { Alert } from 'components/common/Alert';
import TableLoader from 'components/common/TableLoader';
import NoDataFound from 'components/common/NoDataFound';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import TableSearchField from 'components/common/TableSearchField';
import TableTabsComponent from 'components/common/TableTabsComponent';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
// constants, reducers, graphql, styles
import {
  HTTP_STATUS,
  ACTIVE_TEXT,
  INACTIVE_TEXT,
  LOADING_TABLE_ROWS,
  TabEnum,
  TABS,
  EXCIPIENT_TYPE_TABLE_HEADER,
  EDIT_EXCIPIENT_TYPES_ROUTE,
  PRODUCT_EXCIPIENT_ROUTE,
} from 'constants/index';
import { cursorPointer, textWhiteSpace } from 'styles/commonComponentStyle';
import {
  ExcipientTypesPayload,
  useFindAllExcipientTypesLazyQuery,
  useUpdateExcipientTypeMutation,
} from 'generated/graphql';
import { Action, ActionType, State, initialState, excipientTypeReducer } from 'reducer/excipientTypeReducer';

const ExcipientTable: FC = () => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(excipientTypeReducer, initialState);
  const { page, rowsPerPage, search, count, data, activeTab } = state;

  const navigate = useNavigate();

  const [findAllExcipientTypes, { loading: fetchLoading, error }] = useFindAllExcipientTypesLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllExcipientTypes } = data;
      const { pagination, response, data: excipientData } = findAllExcipientTypes || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { page, totalCount } = pagination || {};
        dispatch({ type: ActionType.SET_PAGE, page: page || 1 });
        dispatch({ type: ActionType.SET_COUNT, count: totalCount || 0 });
        dispatch({ type: ActionType.SET_DATA, data: excipientData as ExcipientTypesPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const [updateExcipientTypeStatus, { loading: updateLoading }] = useUpdateExcipientTypeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateExcipientType } = data;
      const { response } = updateExcipientType || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        fetchExcipientTypes();
      } else {
        Alert.error(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_COUNT, count: 0 });
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchExcipientTypes = useCallback(async () => {
    await findAllExcipientTypes({
      variables: {
        findAllExcipientTypesInput: {
          paginationOptions: {
            limit: rowsPerPage,
            page,
          },
          search,
          ...(activeTab !== TabEnum.All && { isActive: activeTab === TabEnum.Active }),
        },
      },
    });
  }, [findAllExcipientTypes, search, rowsPerPage, page, activeTab]);

  useEffect(() => {
    (!search.length || search.length > 2) && fetchExcipientTypes();
  }, [fetchExcipientTypes, search.length]);

  const tabHandler = (_: React.SyntheticEvent<Element, Event>, value: string) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab: value as TabEnum });
  };

  const onActiveHandler = async (id: string, status: boolean) => {
    await updateExcipientTypeStatus({
      variables: {
        updateExcipientTypeInput: {
          id,
          isActive: !status,
        },
      },
    });
  };

  const loading = fetchLoading || updateLoading;

  const noData = Boolean((!fetchLoading && data?.length === 0) || error);

  return (
    <TableContainer>
      <TableTabsComponent activeTab={activeTab} tabsList={TABS} tabHandler={tabHandler} />

      <TableSearchField
        search={search}
        onClear={() => dispatch({ type: ActionType.SET_SEARCH, search: '' })}
        onChange={(val) => dispatch({ type: ActionType.SET_SEARCH, search: val })}
      />

      <TableComponent
        noData={noData}
        page={page - 1}
        count={count}
        rowsPerPage={rowsPerPage}
        tableHeader={EXCIPIENT_TYPE_TABLE_HEADER}
        setPage={(p: number) => dispatch({ type: ActionType.SET_PAGE, page: p + 1 })}
        setRowsPerPage={(r: number) => dispatch({ type: ActionType.SET_ROWS_PER_PAGE, rowsPerPage: r })}>
        {loading ? (
          <TableLoader columns={8} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const { id, name, excipientLabel, loadAdd, loadAddUnit, perKgCost, isActive, productType } =
                cell || {};
              const { name: excipientLabelName } = excipientLabel || {};
              const { name: productTypeName } = productType || {};

              return (
                <TableRow key={id}>
                  <TableCell sx={cursorPointer} onClick={() => navigate(`${PRODUCT_EXCIPIENT_ROUTE}/${id}`)}>
                    <b>{name ?? '--'}</b>
                  </TableCell>
                  <TableCell sx={textWhiteSpace}>{productTypeName ?? '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{excipientLabelName ?? '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{perKgCost ? `$${perKgCost}` : '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{loadAdd ?? '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{loadAddUnit ?? '--'}</TableCell>

                  <TableCell>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={isActive ? ACTIVE_TEXT : INACTIVE_TEXT}
                      color={isActive ? 'success' : 'error'}
                    />
                  </TableCell>
                  <TableCell>
                    <ActionMenuDropdown
                      id={id ?? ''}
                      isActive={!!isActive}
                      editRoute={EDIT_EXCIPIENT_TYPES_ROUTE}
                      onActiveHandler={onActiveHandler}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>

      <NoDataFound noData={noData} />
    </TableContainer>
  );
};

export default ExcipientTable;
