import { FC, useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
// components
import { DashboardStatsChartImg } from 'assets/svgs';
import RecentFormulaTable from './RecentFormulaTable';
import DashboardStatsCard from '../DashboardStatsCard';
import UserProfilePopover from 'components/main/Dashboard/UserAvatarPopover';
// styles, interfaces
import {
  HTTP_STATUS,
  DASHBOARD_TEXT,
  orgInitialValues,
  MY_FORMULAS_TEXT,
  ORG_FORMULAS_TEXT,
  ORG_INGREDIENTS_TEXT,
} from 'constants/index';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { OrgDashboardPayload, useGetStaffDashboardLazyQuery } from 'generated/graphql';

const StaffDashboard: FC = () => {
  const { palette } = useTheme();

  const [data, setData] = useState<OrgDashboardPayload>(orgInitialValues);

  const [getStaffDashboard, { loading }] = useGetStaffDashboardLazyQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,

    onCompleted: (res) => {
      const { getOrgDashboard } = res || {};
      const { response } = getOrgDashboard || {};
      const { status } = response || {};

      if (status === HTTP_STATUS.SUCCESS) {
        setData(getOrgDashboard);
      } else {
        setData(orgInitialValues);
      }
    },
    onError: () => {
      setData(orgInitialValues);
    },
  });

  const fetchDashboard = useCallback(async () => {
    try {
      await getStaffDashboard();
    } catch (error) {
      console.log('error', error);
    }
  }, [getStaffDashboard]);

  useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard]);

  const { ingredients, formulas, myFormulas } = data || {};

  return (
    <>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{DASHBOARD_TEXT}</Typography>

        <UserProfilePopover />
      </Box>

      <Grid container spacing={3} mt={1}>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <DashboardStatsCard
            loading={loading}
            icon={<DashboardStatsChartImg color={palette.bluePaletteColor.main} />}
            stats={`${ingredients ?? 0}`}
            title={ORG_INGREDIENTS_TEXT}
          />
        </Grid>

        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <DashboardStatsCard
            loading={loading}
            icon={<DashboardStatsChartImg color={palette.customRedColor.main} />}
            stats={`${formulas ?? 0}`}
            title={ORG_FORMULAS_TEXT}
          />
        </Grid>

        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <DashboardStatsCard
            loading={loading}
            icon={<DashboardStatsChartImg color={palette.primary.main} />}
            stats={`${myFormulas ?? 0}`}
            title={MY_FORMULAS_TEXT}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={1}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <RecentFormulaTable />
        </Grid>
      </Grid>
    </>
  );
};

export default StaffDashboard;
