import {
  Box,
  Dialog,
  Button,
  Checkbox,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from '@mui/material';
import { ChangeEvent, FC, useContext, useState, JSX } from 'react';
// data
import {
  ACCEPT_TEXT,
  DECLINE_TEXT,
  TERMS_OF_USE_TEXT,
  AGREEMENT_PARA_1_TEXT,
  AGREEMENT_PARA_2_TEXT,
  AGREEMENT_CHECKBOX_TEXT,
} from 'constants/constant';
import { AuthContext } from 'contexts/AuthContext';
import { handleLogout } from 'lib/helper';
import { CONSENT_DATA } from 'constants/arrayMappedData';

const ConsentForm: FC = (): JSX.Element => {
  const [checked, setChecked] = useState(false);

  const { setIsAcceptConsent } = useContext(AuthContext);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const onDecline = () => {
    handleLogout();
  };

  const onAccept = () => {
    setChecked(false);
    setIsAcceptConsent(true);
  };

  return (
    <Dialog open fullWidth maxWidth="lg">
      <DialogTitle>{TERMS_OF_USE_TEXT}</DialogTitle>
      <DialogContent sx={{ p: 5 }}>
        <Typography variant="body2">{AGREEMENT_PARA_1_TEXT}</Typography>
        <Typography variant="body2">{AGREEMENT_PARA_2_TEXT}</Typography>

        {CONSENT_DATA.map((item, i) => {
          const { title, description, paras } = item || {};
          return (
            <Box mt={2} key={title}>
              <Typography variant="h2">
                {i + 1}. {title}
              </Typography>
              <Typography mt={2} variant="body2">
                {description}
              </Typography>
              {paras?.map((text, i) => (
                <Typography key={text} mt={1} variant="body2">
                  {text}
                </Typography>
              ))}
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Box width={'100%'} display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
          <Box>
            <FormControlLabel
              required
              control={<Checkbox checked={checked} onChange={handleChange} />}
              label={AGREEMENT_CHECKBOX_TEXT}
            />
          </Box>
          <Box>
            <Button variant="outlined" sx={{ mr: 2 }} onClick={onDecline}>
              {DECLINE_TEXT}
            </Button>
            <Button variant="contained" disabled={!checked} onClick={onAccept}>
              {ACCEPT_TEXT}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentForm;
