import { FC, useCallback, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
// component
import InputController from 'controller/InputController';
// constants, interfaces, styles, schema,
import {
  CANCEL_TEXT,
  FORMULA_NAME_TEXT,
  SAVE_TEXT,
  UNSAVED_FORMULA_MESSAGE,
  UNSAVED_FORMULA_TEXT,
  saveFormulaAsDraftFormInitialValue,
} from 'constants/index';
import { saveFormulaAsDraftSchema } from 'validationSchema';
import { forInputLabelBg } from 'styles/commonComponentStyle';
import { SaveAsDraftDialogBoxProps, SaveFormulaAsDraftFormType } from 'interfaces';

const SaveAsDraftDialogBox: FC<SaveAsDraftDialogBoxProps> = ({ onClose, onSave, open, formulaName }) => {
  const methods = useForm<SaveFormulaAsDraftFormType>({
    defaultValues: saveFormulaAsDraftFormInitialValue,
    resolver: yupResolver(saveFormulaAsDraftSchema),
  });

  const { handleSubmit, setValue, clearErrors } = methods;

  const onSubmit: SubmitHandler<SaveFormulaAsDraftFormType> = (data) => {
    onSave && onSave(data);
  };

  const setValues = useCallback(() => {
    setValue('name', formulaName ?? '');
  }, [formulaName, setValue]);

  useEffect(() => {
    formulaName && setValues();
  }, [formulaName, setValues]);

  return (
    <FormProvider {...methods}>
      <Dialog open={open} maxWidth={'md'} sx={{ padding: 5 }}>
        <DialogTitle>{UNSAVED_FORMULA_TEXT}</DialogTitle>
        <DialogContent sx={{ pb: 0 }}>
          <Typography variant="caption" fontSize={16} fontWeight={500}>
            {UNSAVED_FORMULA_MESSAGE}
          </Typography>
          <Box sx={[forInputLabelBg, { mt: 2 }]}>
            <InputController isRequired name="name" title={FORMULA_NAME_TEXT} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box display={'flex'} flexWrap={'wrap'}>
            <Button
              size="small"
              sx={{ mr: 2 }}
              color="primary"
              variant="contained"
              onClick={handleSubmit(onSubmit)}>
              {SAVE_TEXT}
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                clearErrors();
                onClose && onClose();
              }}
              size="small">
              {CANCEL_TEXT}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default SaveAsDraftDialogBox;
