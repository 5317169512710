import { FC, Fragment, JSX } from 'react';
import { Alert, Box } from '@mui/material';
// constants
import { DEMO_ACCOUNT_TEXT, IS_STAGE } from 'constants/index';

const DemoAlert: FC = (): JSX.Element => {
  if (IS_STAGE) {
    return (
      <Box mb={2}>
        <Alert variant="filled" severity="error">
          {DEMO_ACCOUNT_TEXT}
        </Alert>
      </Box>
    );
  }
  return <Fragment />;
};

export default DemoAlert;
