import { FC, Fragment } from 'react';
// component
import RowView from 'components/common/RowView';
import DataLoader from 'components/common/DataLoader';
// constants, interfaces
import {
  WEIGHT_TEXT,
  SYRUP_BASE_TEXT,
  SAND_COATING_TEXT,
  FLAVOR_WEIGHT_TEXT,
  COATING_WEIGHT_TEXT,
  EXCIPIENT_WEIGHT_TEXT,
  SWEETENER_WEIGHT_TEXT,
  INGREDIENT_WEIGHT_TEXT,
} from 'constants/index';
import { FormulaWeightPercentageCardProps } from 'interfaces';

const FormulaWeightPercentageCard: FC<FormulaWeightPercentageCardProps> = ({ costing, loading }) => {
  const { formulaWeightsPercentage } = costing || {};

  const {
    flavorWeight,
    coatingWeight,
    sweetenerWeight,
    excipientWeight,
    syrupBaseWeight,
    sandCoatingWeight,
    ingredientsWeight,
  } = formulaWeightsPercentage || {};

  return (
    <Fragment>
      {loading ? (
        <DataLoader columns={12} rows={6} />
      ) : (
        <Fragment>
          <RowView name={INGREDIENT_WEIGHT_TEXT} value={ingredientsWeight ? `${ingredientsWeight}%` : ''} />

          <RowView
            name={`${SYRUP_BASE_TEXT} ${WEIGHT_TEXT}`}
            value={syrupBaseWeight ? `${syrupBaseWeight}%` : ''}
          />

          <RowView
            name={`${SAND_COATING_TEXT} ${WEIGHT_TEXT}`}
            value={sandCoatingWeight ? `${sandCoatingWeight}%` : ''}
          />

          <RowView name={EXCIPIENT_WEIGHT_TEXT} value={excipientWeight ? `${excipientWeight}%` : ''} />

          <RowView name={COATING_WEIGHT_TEXT} value={coatingWeight ? `${coatingWeight}%` : ''} />

          <RowView name={FLAVOR_WEIGHT_TEXT} value={flavorWeight ? `${flavorWeight}%` : ''} />

          <RowView name={SWEETENER_WEIGHT_TEXT} value={sweetenerWeight ? `${sweetenerWeight}%` : ''} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default FormulaWeightPercentageCard;
