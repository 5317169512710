import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
// component
import NoDataFound from 'components/common/NoDataFound';
// constants, context, interfaces, graphql, styles, helper
import { flexEnd } from 'theme/styleConstant';
import { FactSheetModalProps } from 'interfaces';
import { formatCalcFormulaIngredients } from 'lib/helper';
import { CLOSE_TEXT, FACT_SHEET_TEXT, HTTP_STATUS } from 'constants/index';
import { FactBoxType, FormulaBlendInput, useGetFactSheetLazyQuery } from 'generated/graphql';

const FactSheetModal: FC<FactSheetModalProps> = ({
  open,
  onClose,
  servingUnit,
  factBoxType,
  capsuleTypeId,
  productTypeId,
  excipientTypeId,
  ingredientsBlend,
  sweetenerSystemId,
  formulaIngredients,
  desiredServingSize,
  innerCapsuleTypeId,
  outerCapsuleTypeId,
  servingPerContainer,
  productFormulationTypeId,
  sandCoatingId,
  productSizeId,
  subProductTypeId,
  tabletCoating,
}) => {
  const [pdfUrl, setPdfUrl] = useState('');

  const [fetchFactSheet, { loading }] = useGetFactSheetLazyQuery({
    onCompleted: (res) => {
      const { getFactSheet } = res || {};
      const { pdfStr, response } = getFactSheet || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setPdfUrl(pdfStr || '');
      } else {
        setPdfUrl('');
      }
    },
    onError: () => {
      setPdfUrl('');
    },
  });

  const fetchFormulaFactSheet = useCallback(async () => {
    const ingredients = formatCalcFormulaIngredients(formulaIngredients ?? []);

    const ingredientBlends = ingredientsBlend?.map<FormulaBlendInput>(
      ({ ingredients: blendIngredient, name }) => {
        const blendIngredients = formatCalcFormulaIngredients(blendIngredient);
        return {
          name,
          formulaIngredients: blendIngredients,
        };
      },
    );

    await fetchFactSheet({
      variables: {
        getFactSheetInput: {
          factBoxType: factBoxType ? (factBoxType as FactBoxType) : FactBoxType.Supplement,

          productTypeId,
          productFormulationTypeId,
          ...(subProductTypeId && { subProductTypeId }),

          formulaIngredients: ingredients,
          formulaBlends: ingredientBlends,

          servingUnit,
          servingSize: Number(desiredServingSize ?? '1'),
          servingContainer: Number(servingPerContainer ?? '1'),

          ...(excipientTypeId && { excipientTypeId }),

          ...(capsuleTypeId && { capsuleTypeId }),
          ...(innerCapsuleTypeId && { innerCapsuleTypeId }),
          ...(outerCapsuleTypeId && { outerCapsuleTypeId }),

          ...(sweetenerSystemId && { sweetenerSystemId }),

          ...(sandCoatingId && { sandCoatingId }),
          ...(tabletCoating && { tabletCoatingId: tabletCoating }),

          ...(productSizeId && { productSizeId }),
        },
      },
    });
  }, [
    ingredientsBlend,
    formulaIngredients,

    fetchFactSheet,

    productSizeId,
    productTypeId,
    subProductTypeId,
    productFormulationTypeId,

    servingUnit,
    desiredServingSize,
    servingPerContainer,

    factBoxType,

    excipientTypeId,
    sweetenerSystemId,

    capsuleTypeId,
    innerCapsuleTypeId,
    outerCapsuleTypeId,

    sandCoatingId,
    tabletCoating,
  ]);

  useEffect(() => {
    open && fetchFormulaFactSheet();
  }, [fetchFormulaFactSheet, open]);

  return (
    <Dialog open={open} fullWidth maxWidth={'lg'}>
      <DialogTitle>{FACT_SHEET_TEXT}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box textAlign="center">
            <CircularProgress size={40} />
          </Box>
        ) : pdfUrl ? (
          <object>
            <embed type="text/html" width="100%" height="600" src={pdfUrl} />
          </object>
        ) : (
          <NoDataFound noData={!pdfUrl} />
        )}
      </DialogContent>
      <DialogActions>
        <Box sx={flexEnd}>
          <Button variant="outlined" color="primary" onClick={onClose} size="small">
            {CLOSE_TEXT}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FactSheetModal;
