import { FC, Fragment } from 'react';
//components
import StaffDashboard from './Staff';
import AdminDashboard from './Admin';
import SuperDashboard from './SuperAdmin';
// Guards
import { AdminGuard } from 'routes/Guards/AdminGuard';
import { SuperAdminGuard } from 'routes/Guards/SuperAdminGuard';
import { StaffGuard } from 'routes/Guards/StaffGuard';

const DashboardContent: FC = (): JSX.Element => {
  return (
    <Fragment>
      <SuperAdminGuard>
        <SuperDashboard />
      </SuperAdminGuard>

      <AdminGuard>
        <AdminDashboard />
      </AdminGuard>

      <StaffGuard>
        <StaffDashboard />
      </StaffGuard>
    </Fragment>
  );
};

export default DashboardContent;
