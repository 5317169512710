import { Box, Chip, Grid, TableCell, TableRow } from '@mui/material';
import { FC, Fragment, Reducer, useCallback, useEffect, useReducer } from 'react';
// components
import TableLoader from 'components/common/TableLoader';
import { Alert } from 'components/common/Alert';
import NoDataFound from 'components/common/NoDataFound';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import TableSearchField from 'components/common/TableSearchField';
import TableTabsComponent from 'components/common/TableTabsComponent';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
import ProductTypesSelect from 'components/common/simpleSelectors/ProductType';
import SubProductTypeSelect from 'components/common/simpleSelectors/SubProductType';
// constants, reducers, graphql, styles
import { SelectType } from 'interfaces';
import { textWhiteSpace } from 'styles/commonComponentStyle';
import { Action, ActionType, State, initialState, productSizeReducer } from 'reducer/productSizeReducer';
import {
  TABS,
  TabEnum,
  HTTP_STATUS,
  ACTIVE_TEXT,
  INACTIVE_TEXT,
  PRODUCT_TYPE_TEXT,
  LOADING_TABLE_ROWS,
  SUB_PRODUCT_TYPE_TEXT,
  EDIT_PRODUCT_SIZE_ROUTE,
  PRODUCT_SIZE_TABLE_HEADER,
} from 'constants/index';
import {
  ProductSizesPayload,
  useUpdateProductSizeMutation,
  useFindAllProductSizeLazyQuery,
} from 'generated/graphql';

const ProductTypeTable: FC = () => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(productSizeReducer, initialState);
  const { page, rowsPerPage, search, count, data, activeTab, productType, subProductType } = state;

  const { value: productTypeId } = productType;
  const { value: subProductTypeId } = subProductType;

  const [findAllProductSize, { loading: fetchLoading, error }] = useFindAllProductSizeLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllProductSize } = data;
      const { pagination, response, data: productSizeData } = findAllProductSize || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { page, totalCount } = pagination || {};
        dispatch({ type: ActionType.SET_PAGE, page: page || 1 });
        dispatch({ type: ActionType.SET_COUNT, count: totalCount || 0 });
        dispatch({ type: ActionType.SET_DATA, data: productSizeData as ProductSizesPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const [updateProductSizeStatus, { loading: updateLoading }] = useUpdateProductSizeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateProductSize } = data;
      const { response } = updateProductSize || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        fetchProductSize();
      } else {
        Alert.error(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_COUNT, count: 0 });
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchProductSize = useCallback(async () => {
    await findAllProductSize({
      variables: {
        findAllProductSizesInput: {
          paginationOptions: {
            limit: rowsPerPage,
            page,
          },
          ...(productTypeId && { productTypeId }),
          ...(subProductTypeId && { subProductTypeId }),
          search,
          ...(activeTab !== TabEnum.All && { isActive: activeTab === TabEnum.Active }),
        },
      },
    });
  }, [findAllProductSize, rowsPerPage, page, productTypeId, subProductTypeId, search, activeTab]);

  useEffect(() => {
    fetchProductSize();
  }, [fetchProductSize]);

  const tabHandler = (_: React.SyntheticEvent<Element, Event>, value: string) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab: value as TabEnum });
  };

  const onActiveHandler = async (id: string, status: boolean) => {
    await updateProductSizeStatus({
      variables: {
        updateProductSizeInput: {
          id,
          isActive: !status,
        },
      },
    });
  };

  const onProductTypeChange = useCallback((item: SelectType) => {
    dispatch({ type: ActionType.SET_PRODUCT_TYPE, productType: item });
    dispatch({ type: ActionType.SET_SUB_PRODUCT, subProductType: initialState.subProductType });
  }, []);

  const onSubProductTypeChange = useCallback((item: SelectType) => {
    dispatch({ type: ActionType.SET_SUB_PRODUCT, subProductType: item });
  }, []);

  const loading = fetchLoading || updateLoading;

  const noData = Boolean((!fetchLoading && data?.length === 0) || error);

  return (
    <TableContainer>
      <TableTabsComponent activeTab={activeTab} tabsList={TABS} tabHandler={tabHandler} />
      <Box m={({ spacing }) => spacing(2, 3)}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <TableSearchField
              search={search}
              withSpacing={false}
              onClear={() => dispatch({ type: ActionType.SET_SEARCH, search: '' })}
              onChange={(val) => dispatch({ type: ActionType.SET_SEARCH, search: val })}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ProductTypesSelect
              isClearable
              value={productType}
              name="product-type"
              title={PRODUCT_TYPE_TEXT}
              handleChange={onProductTypeChange}
            />
          </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <SubProductTypeSelect
              isClearable
              value={subProductType}
              name="sub-product-type"
              title={SUB_PRODUCT_TYPE_TEXT}
              productTypeId={productTypeId}
              handleChange={onSubProductTypeChange}
            />
          </Grid>
        </Grid>
      </Box>

      <TableComponent
        noData={noData}
        page={page - 1}
        count={count}
        rowsPerPage={rowsPerPage}
        tableHeader={PRODUCT_SIZE_TABLE_HEADER}
        setPage={(p: number) => dispatch({ type: ActionType.SET_PAGE, page: p + 1 })}
        setRowsPerPage={(r: number) => dispatch({ type: ActionType.SET_ROWS_PER_PAGE, rowsPerPage: r })}>
        {loading ? (
          <TableLoader columns={9} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const { id, name, emptyWtMg, max, min, productType, subProductType, isActive, gummyWeight } =
                cell || {};
              const { name: productTypeName } = productType || {};
              const { name: subProductTypeName } = subProductType || {};

              return (
                <TableRow key={id}>
                  <TableCell sx={textWhiteSpace}>{name ?? '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{productTypeName ?? '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{subProductTypeName ?? '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{emptyWtMg ?? 0}</TableCell>
                  <TableCell sx={textWhiteSpace}>{gummyWeight ?? 0}</TableCell>
                  <TableCell sx={textWhiteSpace}>{min || '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{max || '--'}</TableCell>

                  <TableCell>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={isActive ? ACTIVE_TEXT : INACTIVE_TEXT}
                      color={isActive ? 'success' : 'error'}
                    />
                  </TableCell>
                  <TableCell>
                    <ActionMenuDropdown
                      id={id ?? ''}
                      isActive={!!isActive}
                      editRoute={EDIT_PRODUCT_SIZE_ROUTE}
                      onActiveHandler={onActiveHandler}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>

      <NoDataFound noData={noData} />
    </TableContainer>
  );
};

export default ProductTypeTable;
