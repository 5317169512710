import { FC, JSX } from 'react';
import { Grid } from '@mui/material';
// components
import SubProductTypeForm from './components/SubProductTypeForm';
// interfaces
import { SubProductTypeFormProps } from 'interfaces';

const AddSubProductType: FC<SubProductTypeFormProps> = ({ loading, isEdit = false }): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item lg={6} md={12}>
        <SubProductTypeForm loading={loading} isEdit={isEdit} />
      </Grid>
    </Grid>
  );
};

export default AddSubProductType;
