import { FC } from 'react';
import { Box, Container, Typography } from '@mui/material';
//component
import { AuthLayoutContainer } from 'theme/styleComponent';
//interfaces
import { AuthLayoutProps } from 'interfaces';
// theme
import { LogoIcon } from 'assets/svgs';
import { authLogoBox } from 'styles/commonComponentStyle';

export const AuthLayout: FC<AuthLayoutProps> = ({
  icon,
  title,
  children,
  subTitle,
  logoutBtn,
  mb = 1,
  maxWidth = '480px',
  paddingX = 'initial',
  lineHeight = 'initial',
}) => {
  return (
    <Box>
      <Container maxWidth={false}>
        <Box sx={authLogoBox}>
          <LogoIcon width={260} height={95} />

          {logoutBtn && <>{logoutBtn}</>}
        </Box>
      </Container>

      <AuthLayoutContainer>
        <Box width="100%" maxWidth={maxWidth} paddingX={paddingX} px={{ xs: 0 }}>
          {icon && (
            <Box textAlign="center" mb={5}>
              {icon}
            </Box>
          )}

          <Box pb={5} textAlign="left">
            <Typography variant="h5" mb={mb}>
              {title}
            </Typography>

            <Box>
              {subTitle && (
                <Typography variant="subtitle1" component="p" lineHeight={lineHeight}>
                  {subTitle}
                </Typography>
              )}
            </Box>
          </Box>

          {children}
        </Box>
      </AuthLayoutContainer>
    </Box>
  );
};
