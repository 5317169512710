import { FC, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogTitle, Typography } from '@mui/material';
// components
import { Alert } from 'components/common/Alert';
import InputController from 'controller/InputController';
// constants, styles, interfaces, schema, reducer, context
import {
  SAVE_TEXT,
  CANCEL_TEXT,
  ADD_BLEND_TEXT,
  BLEND_NAME_TEXT,
  blendItemInitialValues,
  ADD_BLEND_ERROR_MESSAGE,
} from 'constants/index';
import { flexEnd } from 'theme/styleConstant';
import { blendItemSchema } from 'validationSchema';
import { forInputLabelBg } from 'styles/commonComponentStyle';
import { AddBlendModalProps, AddNewFormulaFormType, BlendItemType, IngredientItemFormType } from 'interfaces';

const AddBlendModal: FC<AddBlendModalProps> = ({ onClose, open, ingredientsBlend, onBlendAdd }) => {
  const [selectedIngredientIds, setSelectedIngredientIds] = useState<string[]>([]);

  const methods = useForm<BlendItemType>({
    defaultValues: blendItemInitialValues,
    resolver: yupResolver(blendItemSchema),
  });

  const { handleSubmit, reset } = methods;

  const { watch, setValue } = useFormContext<AddNewFormulaFormType>();
  const { formulaIngredients } = watch();

  const onValChange = (ingredientId: string, isChecked: boolean) => {
    setSelectedIngredientIds((prev) =>
      isChecked ? [...prev, ingredientId] : prev.filter((id) => id !== ingredientId),
    );
  };

  const onSubmit: SubmitHandler<BlendItemType> = (data) => {
    const { name } = data;
    if (!selectedIngredientIds.length) {
      Alert.error(ADD_BLEND_ERROR_MESSAGE);
    } else {
      const filteredIngredients = formulaIngredients?.filter(
        ({ ingredientId }) => !selectedIngredientIds.includes(ingredientId),
      );
      const blends = formulaIngredients?.filter(({ ingredientId }) =>
        selectedIngredientIds.includes(ingredientId),
      );

      const Ingredient =
        filteredIngredients?.map<IngredientItemFormType>((item): IngredientItemFormType => {
          const { name, ingredientId, potency, ingredientPotency } = item || {};
          return {
            name: name ?? '',
            ingredientPotency,
            potency: potency ?? 1,
            value: ingredientId ?? '',
          };
        }) || [];

      const blendsList = [{ name, ingredients: blends }, ...ingredientsBlend];
      onBlendAdd(blendsList);

      setValue('formulaIngredients', filteredIngredients);
      setValue('ingredients', Ingredient);
      onClose();
      setSelectedIngredientIds([]);
      reset();
    }
  };

  return (
    <FormProvider {...methods}>
      <Dialog open={open} fullWidth maxWidth={'sm'} sx={forInputLabelBg}>
        <DialogTitle>{ADD_BLEND_TEXT}</DialogTitle>

        <Box px={3}>
          <InputController name="name" title={BLEND_NAME_TEXT} isRequired />
          {formulaIngredients?.map(({ name, ingredientId }) => {
            const isChecked = selectedIngredientIds?.includes(ingredientId);
            return (
              <Box
                mt={2}
                flex={1}
                paddingX={2}
                display="flex"
                alignItems="center"
                key={ingredientId}
                bgcolor={'primary.main'}
                borderRadius={1}>
                <Checkbox
                  color="secondary"
                  checked={isChecked}
                  value={ingredientId}
                  onChange={(value) => {
                    const val = value.currentTarget.checked;
                    onValChange(ingredientId, val);
                  }}
                />
                <Typography variant="caption">{name}</Typography>
              </Box>
            );
          })}
        </Box>

        <DialogActions>
          <Box sx={flexEnd}>
            <Box mr={2}>
              <Button variant="contained" size="small" onClick={handleSubmit(onSubmit)}>
                {SAVE_TEXT}
              </Button>
            </Box>

            <Button variant="outlined" color="secondary" onClick={onClose} size="small">
              {CANCEL_TEXT}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default AddBlendModal;
