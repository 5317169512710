import { FC, JSX } from 'react';
import { Box } from '@mui/material';
import { Editor } from 'react-draft-wysiwyg';
// interfaces
import { TextEditorProps } from 'interfaces';
// css
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const TextEditor: FC<TextEditorProps> = ({ editorState, onEditorStateChange }): JSX.Element => {
  const onPaste = () => {
    return false;
    // const html = convertEditorStateToHtml(state);
    // const text = convertHtmlToText(html);
    // const editorState = convertHtmlToEditorState(`<p>${text}</p>`);
    // onEditorStateChange(editorState);
  };

  return (
    <Box
      sx={{
        backgroundColor: ({ palette }) => palette.customBlackColor.dark,
        padding: ({ spacing }) => spacing(1, 1, 1, 1),
        marginTop: 1,
      }}>
      <Editor
        toolbar={{
          options: ['inline'],
          inline: {
            options: ['italic', 'bold'],
          },
        }}
        editorState={editorState}
        editorClassName="text-editor-editor"
        toolbarClassName="text-editor-toolbar"
        onEditorStateChange={onEditorStateChange}
        handlePastedText={onPaste}
      />
    </Box>
  );
};

export default TextEditor;
