// packages block
import { FC, Fragment } from 'react';
import { Typography, Box } from '@mui/material';
// constant, svg, interface
import { NoDataIcon } from 'assets/svgs';
import { NO_DATA_FOUND } from 'constants/index';
import { flexCenter } from 'theme/styleConstant';
import { NoDataFoundProps } from 'interfaces';

const NoDataFound: FC<NoDataFoundProps> = ({ noData }): JSX.Element => {
  return (
    <Fragment>
      {noData && (
        <Box sx={flexCenter} flexDirection="column" py={7}>
          <Box>
            <NoDataIcon />
          </Box>

          <Typography variant="h5" color={`customGrayColor.light`}>
            {NO_DATA_FOUND}
          </Typography>
        </Box>
      )}
    </Fragment>
  );
};

export default NoDataFound;
