import { Grid } from '@mui/material';
import { FC, Fragment, JSX } from 'react';
import { useFormContext } from 'react-hook-form';
// components
import CapInCap from './components/CapInCap';
import Beadlets from './components/Beadlets';
import TabletInCap from './components/TabletInCap';
import PowderFilled from './components/PowderFilled';
import MiniTabletInCap from './components/MiniTabletInCap';
import CapsuleTypeRadioGroup from 'components/common/selectors/capsuleType';
// interface, graphql, constant
import { DosageFormulationFormType } from 'interfaces';
import { ProductFormulationTypes } from 'generated/graphql';
import { TYPE_OF_CAPSULE_POLYMER_TEXT } from 'constants/index';

const CapsuleForm: FC = (): JSX.Element => {
  const { watch } = useFormContext<DosageFormulationFormType>();
  const { productFormulationType } = watch();
  const { type } = productFormulationType || {};

  const getForm = () => {
    switch (type) {
      case ProductFormulationTypes.PowderFilled:
        return <PowderFilled />;

      case ProductFormulationTypes.LiquidFilled:
        return <Fragment />;

      case ProductFormulationTypes.CapInCap:
        return <CapInCap />;

      case ProductFormulationTypes.LiquidFilledWithBeadlets:
        return <Beadlets />;

      case ProductFormulationTypes.LiquidFilledWithTablet:
        return <TabletInCap />;

      case ProductFormulationTypes.LiquidFilledWithMiniTabs:
        return <MiniTabletInCap />;

      default:
        return <Fragment />;
    }
  };

  return (
    <Fragment>
      {type !== ProductFormulationTypes.CapInCap ? (
        <Grid item xs={12} xl={12}>
          <CapsuleTypeRadioGroup isRequired name="capsuleType" title={TYPE_OF_CAPSULE_POLYMER_TEXT} />
        </Grid>
      ) : (
        <Fragment />
      )}
      {getForm()}
    </Fragment>
  );
};

export default CapsuleForm;
