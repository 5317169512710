import { FC, useCallback, useEffect, useState } from 'react';
// component
import RadioGroupController from 'controller/RadioGroupController';
// interfaces, constants, helper, graphql
import { HTTP_STATUS } from 'constants/index';
import { renderBeadlets } from 'lib/helper';
import { BeadletsPayload, useFindAllBeadletsLazyQuery } from 'generated/graphql';
import { BeadletsRadioGroupProps, RadioGroupControllerExtendedProps } from 'interfaces';

const BeadletsRadioGroup: FC<BeadletsRadioGroupProps> = ({
  name,
  title,
  isRequired = false,
  loading: getLoading,
}) => {
  const [options, setOptions] = useState<RadioGroupControllerExtendedProps[]>([]);

  const [findAll, { loading: findAllBeadletsLoading }] = useFindAllBeadletsLazyQuery({
    onCompleted: (res) => {
      const { findAllBeadlets } = res || {};
      const { data, response } = findAllBeadlets || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setOptions(renderBeadlets(data as BeadletsPayload['data']));
      } else {
        setOptions([]);
      }
    },

    onError: () => {
      setOptions([]);
    },
  });

  const fetchBeadlets = useCallback(async () => {
    await findAll({
      variables: {
        findAllBeadletsInput: {
          paginationOptions: {
            page: 1,
            limit: 10,
          },
        },
      },
    });
  }, [findAll]);

  useEffect(() => {
    fetchBeadlets();
  }, [fetchBeadlets]);

  const loading = findAllBeadletsLoading || getLoading;

  return (
    <RadioGroupController
      showTitle
      name={name}
      title={title}
      loaderRows={1}
      loading={loading}
      loaderColumns={6}
      options={options}
      isRequired={isRequired}
    />
  );
};

export default BeadletsRadioGroup;
