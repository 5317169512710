import { FC, useContext } from 'react';
// interface, context
import { ExceptionalContextProps } from 'interfaces';
import { ExceptionalContext } from 'contexts/ExceptionalContext';

let useAppRef: ExceptionalContextProps;

export const ExceptionalModalConfig: FC = () => {
  useAppRef = useContext(ExceptionalContext);
  return null;
};

export const ExceptionalModalUtil = {
  showModal: (msgs: string[]) => {
    useAppRef.showModal(msgs);
  },
};
