import { Box, Button, Card } from '@mui/material';
import { HTTP_STATUS, SAVE_TEXT } from 'constants/index';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
// components
import CheckboxGroupController from 'controller/CheckboxGroup';
// interfaces, helper, graphql
import { renderAllergens } from 'lib/helper';
import { flexMarginTopEndCenter } from 'styles/commonComponentStyle';
import { AllergensCardProps, SelectType } from 'interfaces';
import { AllergensPayload, useFindAllAllergensLazyQuery } from 'generated/graphql';

const AllergenInfoCard: FC<AllergensCardProps> = ({ isEdit, loading: getLoading }) => {
  const [options, setOptions] = useState<SelectType[]>([]);

  const [findAllAllergens, { loading: findAllAllergensLoading }] = useFindAllAllergensLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllAllergens } = data;
      const { data: allergens, response } = findAllAllergens || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setOptions(renderAllergens(allergens as AllergensPayload['data']));
      }
    },
    onError: () => {
      setOptions([]);
    },
  });

  const fetchAllergens = useCallback(async () => {
    await findAllAllergens({
      variables: {
        findAllAllergensInput: {
          paginationOptions: { page: 1, limit: 20 },
        },
      },
    });
  }, [findAllAllergens]);

  useEffect(() => {
    fetchAllergens();
  }, [fetchAllergens]);

  const loading = findAllAllergensLoading || getLoading;

  return (
    <Card>
      <CheckboxGroupController
        name="allergens"
        title=""
        options={options ?? []}
        loading={loading}
        loaderColumns={4}
        loaderRows={5}
      />

      {isEdit ? (
        <Box sx={flexMarginTopEndCenter}>
          <Button type="submit" variant="contained" id="allergen-btn" size="small" disabled={loading}>
            {SAVE_TEXT}
          </Button>
        </Box>
      ) : (
        <Fragment></Fragment>
      )}
    </Card>
  );
};

export default AllergenInfoCard;
