import { FC, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
//components
import { Alert } from 'components/common/Alert';
import InputController from 'controller/InputController';
//constants
import {
  AUTH_LINKS,
  HTTP_STATUS,
  PASSWORD_TEXT,
  SET_PASSWORD_TEXT,
  GO_TO_SIGNIN_BUTTON,
  CONFIRM_PASSWORD_TEXT,
  TOKEN_NOT_FOUND_TEXT,
} from 'constants/index';
import { ForgotPasswordInput } from 'interfaces';
import { forgotPasswordSchema } from 'validationSchema';
// import { useConfirmPasswordMutation } from 'generated/graphql';
import { cursorPointer } from 'styles/commonComponentStyle';
import { useConfirmPasswordMutation } from 'generated/graphql';

const ResetPassword: FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);

  const methods = useForm<ForgotPasswordInput>({
    mode: 'all',
    resolver: yupResolver(forgotPasswordSchema),

    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const { handleSubmit } = methods;
  const token = params.get('token');

  const [confirmPassword, { loading }] = useConfirmPasswordMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onError: ({ message }) => {
      Alert.error(message);
    },
    onCompleted: (data) => {
      if (data) {
        const { verifyEmailAndSetPassword } = data;

        const { response } = verifyEmailAndSetPassword;
        if (response) {
          const { status } = response;

          if (status === HTTP_STATUS.NOT_FOUND) {
            navigate(AUTH_LINKS.FORGOT_PASSWORD_LINK);
          }

          if (status === HTTP_STATUS.SUCCESS) {
            navigate(AUTH_LINKS.PASSWORD_SUCCESS);
          }
        }
      }
    },
  });

  const onSubmit: SubmitHandler<ForgotPasswordInput> = async (input) => {
    const { password } = input;
    if (!token) {
      Alert.error(TOKEN_NOT_FOUND_TEXT);
      return;
    }
    await confirmPassword({
      variables: {
        verifyEmailAndSetPasswordInput: {
          password,
          token,
        },
      },
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputController
          isRequired
          name={'password'}
          title={PASSWORD_TEXT}
          fieldType={showPassword ? 'text' : 'password'}
          inputAdornmentIcon={
            <Box sx={cursorPointer} onClick={handleClickShowPassword}>
              {showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
            </Box>
          }
        />

        <Box mt={3}>
          <InputController
            isRequired
            name={'confirmPassword'}
            title={CONFIRM_PASSWORD_TEXT}
            fieldType={showPassword ? 'text' : 'password'}
            inputAdornmentIcon={
              <Box sx={cursorPointer} onClick={handleClickShowPassword}>
                {showPassword ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
              </Box>
            }
          />
        </Box>

        <Box mt={3}>
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            startIcon={<>{loading && <CircularProgress color="inherit" size={20} />}</>}>
            {SET_PASSWORD_TEXT}
          </Button>
        </Box>
      </form>

      <Box height={'48px'} lineHeight={'48px'} mt={1} textAlign="center">
        <Typography component={Link} variant="subtitle2" to={AUTH_LINKS.LOGIN_LINK}>
          {GO_TO_SIGNIN_BUTTON}
        </Typography>
      </Box>
    </FormProvider>
  );
};

export default ResetPassword;
