import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
// components
import BottleSizeTable from './BottleSizeTable';
// constant, styles
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { ADD_BOTTLE_SIZE_ROUTE, ADD_NEW_TEXT, BOTTLE_SIZE_TEXT } from 'constants/index';

const BottleSize: FC = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{BOTTLE_SIZE_TEXT}</Typography>
        <Button variant="contained" color="primary" onClick={() => navigate(ADD_BOTTLE_SIZE_ROUTE)}>
          <AddRoundedIcon />
          {ADD_NEW_TEXT}
        </Button>
      </Box>
      <BottleSizeTable />
    </Fragment>
  );
};

export default BottleSize;
