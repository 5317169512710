import { FC } from 'react';
// component
import AddFormula from 'components/main/Formulas/Dosage/Add';
// context
import { FormulaContextProvider } from 'contexts/FormulaContext';

const AddFormulaByDosage: FC = () => {
  return (
    <FormulaContextProvider>
      <AddFormula />
    </FormulaContextProvider>
  );
};

export default AddFormulaByDosage;
