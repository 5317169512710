import { FC, Fragment, JSX } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material';
// components
import InputController from 'controller/InputController';
import StateSelector from 'components/common/selectors/state';
import CountrySelector from 'components/common/selectors/country';
import PhoneInputController from 'controller/PhoneInputController';
// constants, styles, interfaces
import { AddSupplierFormType, ContactFormProps } from 'interfaces';
import {
  CITY_TEXT,
  NAME_TEXT,
  SAVE_TEXT,
  EMAIL_TEXT,
  PHONE_TEXT,
  STATE_TEXT,
  COUNTRY_TEXT,
  CONTACT_TEXT,
  ZIP_CODE_TEXT,
  ADDRESS_1_TEXT,
  ADDRESS_2_TEXT,
  DESIGNATION_TEXT,
  selectTypeInitialValues,
} from 'constants/index';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';

const ContactForm: FC<ContactFormProps> = ({ isEdit = false, loading = false }): JSX.Element => {
  const { watch, setValue } = useFormContext<AddSupplierFormType>();
  const { country } = watch();
  const { value: countryCode } = country || {};

  const resetCountryValueOnChange = () => {
    setValue('state', selectTypeInitialValues);
  };

  return (
    <Card sx={forInputLabelBg}>
      <CardHeader title={CONTACT_TEXT} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <InputController name="name" fieldType="text" title={NAME_TEXT} isRequired disabled={loading} />
          </Grid>

          <Grid item xs={12} lg={12}>
            <InputController
              fieldType="text"
              name="designation"
              disabled={loading}
              title={DESIGNATION_TEXT}
            />
          </Grid>

          <Grid item xs={12} lg={12}>
            <InputController name="email" fieldType="text" disabled={loading} title={EMAIL_TEXT} />
          </Grid>

          <Grid item xs={12} lg={12}>
            <PhoneInputController name="phoneNo" disabled={loading} title={PHONE_TEXT} />
          </Grid>

          <Grid item xs={12} lg={12}>
            <InputController name="address1" fieldType="text" disabled={loading} title={ADDRESS_1_TEXT} />
          </Grid>

          <Grid item xs={12} lg={12}>
            <InputController name="address2" fieldType="text" disabled={loading} title={ADDRESS_2_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <CountrySelector
              disabled={loading}
              name="country"
              title={COUNTRY_TEXT}
              handleChange={resetCountryValueOnChange}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <StateSelector
              disabled={!!(loading || !countryCode)}
              name="state"
              title={STATE_TEXT}
              countryCode={countryCode}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="city" fieldType="text" title={CITY_TEXT} disabled={loading} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputController name="zipCode" fieldType="text" disabled={loading} title={ZIP_CODE_TEXT} />
          </Grid>
        </Grid>
      </CardContent>
      {isEdit ? (
        <CardActions>
          <Box sx={flexMarginTopEndCenter}>
            <Button type="submit" variant="contained" size="small" disabled={loading}>
              {SAVE_TEXT}
            </Button>
          </Box>
        </CardActions>
      ) : (
        <Fragment />
      )}
    </Card>
  );
};

export default ContactForm;
