// imports
import { Controller, useFormContext } from 'react-hook-form';
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
import { Box, Chip, MenuItem, TextField, Typography, Autocomplete, CircularProgress } from '@mui/material';
// interfaces, constants, helper
import { formatValue, renderSuppliers } from 'lib/helper';
import { useDebounce } from '../../../../hooks/useDebounce';
import { SelectType, SupplierSelectorProps } from 'interfaces';
import { HTTP_STATUS, selectTypeInitialValues } from 'constants/index';
import { SuppliersPayload, SupplierStatus, useFindAllSuppliersLazyQuery } from 'generated/graphql';

const SupplierSelector: FC<SupplierSelectorProps> = ({
  name,
  title,
  disabled,
  isRequired,
  handleChange,
  defaultValue,
  margin = 'dense',
  isMultiple = false,
  isClearable = false,
}) => {
  const observer = useRef<IntersectionObserver | null>(null);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [getLoading, setGetLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<SuppliersPayload['data']>([]);
  const debounceValue = useDebounce(searchQuery);

  const { control } = useFormContext();

  const [findAllSuppliers, { loading }] = useFindAllSuppliersLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllSuppliers } = data;
      const { data: suppliers, response, pagination } = findAllSuppliers || {};
      const { status } = response || {};

      if (status === HTTP_STATUS.SUCCESS) {
        const { totalPages: pages } = pagination || {};
        setTotalPages(pages);
        setOptions([
          ...(getLoading ? [] : options || []),
          ...((suppliers as SuppliersPayload['data']) ?? []),
        ]);
        setGetLoading(false);
      } else {
        setGetLoading(false);
      }
    },
    onError: () => {
      setGetLoading(false);

      setOptions([]);
    },
  });

  const fetchSuppliers = useCallback(
    async (page?: number, search?: string) => {
      if (!page) setGetLoading(true);
      await findAllSuppliers({
        variables: {
          findAllSuppliersInput: {
            paginationOptions: { page: page || 1, limit: 10 },
            ...(search && { search }),
          },
        },
      });
    },
    [findAllSuppliers],
  );

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  const lastElementRef = (node: HTMLLIElement) => {
    if (loading) return;
    if (observer?.current) observer?.current?.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      // fetch the coming suppliers if the last node and page is less than or equal to the total page.
      if (entries[0].isIntersecting && page < totalPages) {
        setPage((prev) => {
          const newPage = prev + 1;
          fetchSuppliers(newPage, searchQuery);
          return newPage;
        });
      }
    });
    if (node) observer.current.observe(node);
  };

  const onSearch = useCallback(
    async (val: string) => {
      try {
        setPage(1);
        setOptions([]);
        await fetchSuppliers(1, val);
      } catch (error) {
        console.log('error', error);
      }
    },
    [fetchSuppliers],
  );

  useEffect(() => {
    debounceValue && onSearch(debounceValue);
  }, [debounceValue, onSearch]);

  const onClose = () => {
    setPage(1);
    setOptions([]);
    setSearchQuery('');
    fetchSuppliers(1);
  };

  const updatedOptions = useMemo(() => {
    if (options?.length) {
      return renderSuppliers(options ?? []);
    }
    return [];
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { name, onBlur, onChange, value, ref } = field;
        const { error } = fieldState;
        const { message } = error || {};
        return (
          <Autocomplete
            ref={ref}
            multiple={isMultiple}
            id={name}
            onBlur={onBlur}
            disableClearable={!isClearable}
            disabled={disabled}
            options={updatedOptions}
            // renderTags={() => <></>}
            onClose={onClose}
            loading={loading}
            value={isMultiple ? (value as SelectType[]) : (value as SelectType)}
            onChange={(_, value) => {
              const selectedValue = isMultiple ? value ?? [] : value ?? selectTypeInitialValues;
              onChange(selectedValue);
              handleChange && handleChange(selectedValue);
            }}
            clearIcon={<CloseRoundedIcon />}
            popupIcon={<KeyboardArrowDownRoundedIcon />}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={({ value: option }, { value }) => value === option}
            renderOption={(props, { name, isActive, value: val }) => {
              return (
                <Box
                  component="li"
                  sx={{ '& > div': { width: '100%' } }}
                  key={val}
                  ref={lastElementRef}
                  {...props}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="caption">{name || '--'}</Typography>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={formatValue(isActive ? SupplierStatus.Active : SupplierStatus.Inactive)}
                      color={isActive ? 'success' : 'error'}
                    />
                  </Box>
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                margin={margin}
                variant="outlined"
                helperText={message}
                error={Boolean(error)}
                defaultValue={defaultValue}
                label={isRequired ? `${title} *` : title}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}>
                {updatedOptions?.map((item: SelectType) => {
                  const { name, value } = item;
                  return (
                    <MenuItem key={value} value={value}>
                      {name}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          />
        );
      }}
    />
  );
};

export default SupplierSelector;
