import {
  Box,
  Table,
  Button,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Typography,
  TableContainer,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FC, Fragment, Reducer, useCallback, useEffect, useReducer } from 'react';
import { KeyboardArrowRightRounded as KeyboardArrowRightRoundedIcon } from '@mui/icons-material';
//components
import NoDataFound from 'components/common/NoDataFound';
import TableLoader from 'components/common/TableLoader';
import CommonTableContainer from 'components/common/TableContainer';
//constants, reducers, graphql, styles
import {
  NAME_TEXT,
  TYPE_TEXT,
  HTTP_STATUS,
  FORMULA_ROUTE,
  VIEW_ALL_TEXT,
  TAB_QUERY_TYPE,
  FORMULATION_TEXT,
  LOADING_TABLE_ROWS,
  RECENT_FORMULAS_TEXT,
} from 'constants/index';
import { flexCenterJustifyBetween } from 'theme/styleConstant';
import { orderViewAllFooter, textWhiteSpace } from 'styles/commonComponentStyle';
import { Action, ActionType, State, formulaReducer, initialState } from 'reducer/formulaReducer';
import { FormulaStatus, FormulasPayload, useFindRecentFormulasLazyQuery } from 'generated/graphql';

const RecentFormulaTable: FC = () => {
  const navigate = useNavigate();

  const [state, dispatch] = useReducer<Reducer<State, Action>>(formulaReducer, initialState);
  const { data } = state;

  const [findRecentFormula, { loading, error }] = useFindRecentFormulasLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllFormulas } = data;
      const { response, data: formulas } = findAllFormulas || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        dispatch({ type: ActionType.SET_DATA, data: formulas as FormulasPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchFormulas = useCallback(async () => {
    await findRecentFormula({
      variables: {
        findAllFormulasInput: {
          paginationOptions: {
            limit: 5,
            page: 1,
          },
          status: FormulaStatus.Active,
        },
      },
    });
  }, [findRecentFormula]);

  useEffect(() => {
    fetchFormulas();
  }, [fetchFormulas]);

  const noData = Boolean((!loading && data?.length === 0) || error);

  return (
    <CommonTableContainer>
      <Box mb={2} pt={3} px={3} flexWrap="wrap" sx={flexCenterJustifyBetween}>
        <Typography variant="h6" mb={{ xs: 2, sm: 0 }}>
          {RECENT_FORMULAS_TEXT}
        </Typography>
      </Box>

      <Box px={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{NAME_TEXT}</TableCell>
                <TableCell>{TYPE_TEXT}</TableCell>
                <TableCell>
                  {FORMULATION_TEXT} {TYPE_TEXT}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader columns={6} rows={LOADING_TABLE_ROWS} />
              ) : (
                <Fragment>
                  {data?.map((cell) => {
                    const { id, name, productType, productFormulationType } = cell || {};
                    const { name: productName } = productType || {};
                    const { name: productFormulationTypeName } = productFormulationType || {};

                    return (
                      <TableRow key={id}>
                        <TableCell sx={textWhiteSpace}>{name ?? '--'}</TableCell>
                        <TableCell>{productName ?? '--'}</TableCell>
                        <TableCell sx={textWhiteSpace}>{productFormulationTypeName ?? '--'}</TableCell>
                      </TableRow>
                    );
                  })}
                </Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {data?.length === 0 ? (
        <></>
      ) : (
        <Box sx={orderViewAllFooter}>
          <Button
            variant="text"
            color="info"
            endIcon={<KeyboardArrowRightRoundedIcon />}
            onClick={() => navigate(`${FORMULA_ROUTE}?${TAB_QUERY_TYPE}=all`)}>
            {VIEW_ALL_TEXT}
          </Button>
        </Box>
      )}

      <NoDataFound noData={noData} />
    </CommonTableContainer>
  );
};

export default RecentFormulaTable;
