import { FC } from 'react';
import { Box, Grid } from '@mui/material';
// components
import RowView from 'components/common/RowView';
import DataLoader from 'components/common/DataLoader';
// interfaces, constants
import { ExcipientTypeCardProps } from 'interfaces';
import {
  PERCENTAGE_TEXT,
  PRODUCT_TYPE_TEXT,
  EXCIPIENT_LABEL_TEXT,
  EXCIPIENT_TYPES_TEXT,
} from 'constants/index';

const ExcipientTypeCard: FC<ExcipientTypeCardProps> = ({ excipientType, loading }) => {
  const { excipientLabel, name, productType, loadAdd } = excipientType || {};
  const { name: labelName } = excipientLabel || {};
  const { name: productName } = productType || {};
  return (
    <Box m={4}>
      {loading ? (
        <DataLoader columns={6} rows={2} />
      ) : (
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={6}>
            <RowView name={EXCIPIENT_TYPES_TEXT} value={name || ''} isInfo={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <RowView name={EXCIPIENT_LABEL_TEXT} value={labelName || ''} isInfo={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <RowView name={PRODUCT_TYPE_TEXT} value={productName || ''} isInfo={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <RowView name={PERCENTAGE_TEXT} value={`${loadAdd || '0'}`} isInfo={false} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ExcipientTypeCard;
