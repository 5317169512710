import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import ProductFormulationTypeForm from './components/ProductFormulationTypeForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  ProductFormulationTypePayload,
  useGetProductFormulationTypeLazyQuery,
  useUpdateProductFormulationTypeMutation,
} from 'generated/graphql';
import {
  HTTP_STATUS,
  productFormulationTypeInitialValue,
  EDIT_PRODUCT_FORMULATION_TYPE_TEXT,
  PRODUCT_FORMULATION_TYPE_IS_UPDATED_TEXT,
} from 'constants/index';
import { AddProductFormulationTypeForm, ParamType } from 'interfaces';
import { updateProductFormulationTypeSchema } from 'validationSchema';

const Edit: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const productFormulationTypeMethods = useForm<AddProductFormulationTypeForm>({
    defaultValues: productFormulationTypeInitialValue,
    resolver: yupResolver(updateProductFormulationTypeSchema),
  });

  const { handleSubmit: handleProductFormulationTypeSubmit, setValue: setProductFormulationTypeValue } =
    productFormulationTypeMethods;

  const [updateProductFormulationType, { loading: productTypeLoading }] =
    useUpdateProductFormulationTypeMutation({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { updateProductFormulationType } = data;
        const { response } = updateProductFormulationType || {};
        const { status, message } = response || {};
        if (status === HTTP_STATUS.SUCCESS) {
          Alert.success(message || '');
          navigation(-1);
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    });

  const [getProductFormulationType, { loading: getLoading }] = useGetProductFormulationTypeLazyQuery({
    onCompleted: (data) => {
      const { getProductFormulationType } = data || {};
      const { response, productFormulationType } = getProductFormulationType || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(productFormulationType as ProductFormulationTypePayload['productFormulationType']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });
  /**
   * Datas on supplier submit
   * @param data
   */
  const onProductTypeSubmit: SubmitHandler<AddProductFormulationTypeForm> = async (data) => {
    const {
      name,
      conversionCost,
      innerComponentCost,
      isInnerCapTab,
      lohInside,
      productSubType,
      productType,
    } = data || {};

    await updateProductFormulationType({
      variables: {
        updateProductFormulationTypeInput: {
          id: id || '',
          name,
          lohInside: Number(lohInside),
          isInnerCapTab,
          conversionCost: Number(conversionCost),
          innerComponentCost: Number(innerComponentCost),
          productTypeId: productType?.value,
          subProductTypeId: productSubType?.value,
        },
      },
    });
  };

  const fetchProductFormulationType = useCallback(async () => {
    id &&
      (await getProductFormulationType({
        variables: {
          getProductFormulationTypeInput: {
            id,
          },
        },
      }));
  }, [id, getProductFormulationType]);

  const setValues = (params: ProductFormulationTypePayload['productFormulationType']) => {
    const {
      name,
      isActive,
      conversionCost,
      innerComponentCost,
      isInnerCapTab,
      lohInside,
      productType,
      subProductType,
    } = params || {};
    const { id, name: productTypeName, type } = productType || {};
    const { id: subProductTypeId, name: subProductTypeName } = subProductType || {};
    setProductFormulationTypeValue('name', name || '');
    setProductFormulationTypeValue('isActive', isActive || true);
    setProductFormulationTypeValue('conversionCost', `${conversionCost}` || '0');
    setProductFormulationTypeValue('innerComponentCost', `${innerComponentCost}` || '0');
    setProductFormulationTypeValue('isInnerCapTab', isInnerCapTab || false);
    setProductFormulationTypeValue('lohInside', `${lohInside}` || '0');
    setProductFormulationTypeValue('productType', {
      value: id ?? '',
      name: productTypeName ?? '',
      type: type ?? '',
    });
    setProductFormulationTypeValue('productSubType', {
      value: subProductTypeId ?? '',
      name: subProductTypeName ?? '',
    });
  };

  useEffect(() => {
    id && fetchProductFormulationType();
  }, [id, fetchProductFormulationType]);

  const loading = productTypeLoading || getLoading;

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{EDIT_PRODUCT_FORMULATION_TYPE_TEXT}</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={6} md={12}>
          <FormProvider {...productFormulationTypeMethods}>
            <form onSubmit={handleProductFormulationTypeSubmit(onProductTypeSubmit)}>
              <ProductFormulationTypeForm loading={loading} isEdit />
            </form>
          </FormProvider>
        </Grid>
      </Grid>

      <BackdropLoader open={loading} text={PRODUCT_FORMULATION_TYPE_IS_UPDATED_TEXT} />
    </Fragment>
  );
};

export default Edit;
