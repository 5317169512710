import {
  Box,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FC, Fragment, Reducer, useCallback, useEffect, useReducer } from 'react';
import { KeyboardArrowRightRounded as KeyboardArrowRightRoundedIcon } from '@mui/icons-material';
//components
import TableLoader from 'components/common/TableLoader';
import NoDataFound from 'components/common/NoDataFound';
import CommonTableContainer from 'components/common/TableContainer';
//constants, reducers, graphql, styles
import {
  NAME_TEXT,
  EMAIL_TEXT,
  HTTP_STATUS,
  USERS_ROUTE,
  CONTACT_TEXT,
  VIEW_ALL_TEXT,
  NEW_USERS_TEXT,
  JOINING_DATE_TEXT,
  LOADING_TABLE_ROWS,
  TAB_QUERY_TYPE,
} from 'constants/index';
import { formatPhone, formatTimeStamp } from 'lib/helper';
import { flexCenterJustifyBetween } from 'theme/styleConstant';
import { orderViewAllFooter, textWhiteSpace } from 'styles/commonComponentStyle';
import { UserStatus, UsersPayload, useFindAllAdminLazyQuery } from 'generated/graphql';
import { Action, ActionType, State, initialState, userReducer } from 'reducer/userReducer';

const UsersTableDashboard: FC = () => {
  const navigate = useNavigate();

  const [state, dispatch] = useReducer<Reducer<State, Action>>(userReducer, initialState);
  const { page, search, data } = state;

  const [findAllAdmin, { loading, error }] = useFindAllAdminLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllAdmin } = data;
      const { pagination, response, data: usersData } = findAllAdmin || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { page, totalCount } = pagination || {};
        dispatch({ type: ActionType.SET_PAGE, page: page || 1 });
        dispatch({ type: ActionType.SET_COUNT, count: totalCount || 0 });
        dispatch({ type: ActionType.SET_DATA, data: usersData as UsersPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_COUNT, count: 0 });
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchAdmins = useCallback(async () => {
    await findAllAdmin({
      variables: {
        findAllAdminInput: {
          search,
          status: UserStatus.Active,
          paginationOptions: {
            limit: 5,
            page,
          },
        },
      },
    });
  }, [findAllAdmin, search, page]);

  useEffect(() => {
    (!search.length || search.length > 2) && fetchAdmins();
  }, [fetchAdmins, search.length]);

  const noData = Boolean((!loading && data?.length === 0) || error);

  return (
    <CommonTableContainer>
      <Box mb={2} pt={3} px={3} flexWrap="wrap" sx={flexCenterJustifyBetween}>
        <Typography variant="h6" mb={{ xs: 2, sm: 0 }}>
          {NEW_USERS_TEXT}
        </Typography>
      </Box>

      <Box px={3}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{NAME_TEXT}</TableCell>
                <TableCell>{EMAIL_TEXT}</TableCell>
                <TableCell>{CONTACT_TEXT}</TableCell>
                <TableCell>{JOINING_DATE_TEXT}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableLoader columns={6} rows={LOADING_TABLE_ROWS} />
              ) : (
                <Fragment>
                  {data?.map((cell) => {
                    const { id, email, firstName, lastName, phone, createdAt } = cell || {};

                    return (
                      <TableRow key={id}>
                        <TableCell sx={textWhiteSpace}>
                          {firstName ?? ''} {lastName ?? '--'}
                        </TableCell>
                        <TableCell>{email ?? '--'}</TableCell>
                        <TableCell sx={textWhiteSpace}>{formatPhone(phone || '')}</TableCell>
                        <TableCell>{formatTimeStamp(createdAt, 'MM/DD/YY')}</TableCell>
                      </TableRow>
                    );
                  })}
                </Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {data?.length === 0 ? (
        <></>
      ) : (
        <Box sx={orderViewAllFooter}>
          <Button
            variant="text"
            color="info"
            endIcon={<KeyboardArrowRightRoundedIcon />}
            onClick={() => navigate(`${USERS_ROUTE}?${TAB_QUERY_TYPE}=all`)}>
            {VIEW_ALL_TEXT}
          </Button>
        </Box>
      )}

      <NoDataFound noData={noData} />
    </CommonTableContainer>
  );
};

export default UsersTableDashboard;
