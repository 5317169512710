import { FC, useCallback, useEffect, useState } from 'react';
// component
import RadioGroupController from 'controller/RadioGroupController';
// interfaces, constants, helper, graphql
import { HTTP_STATUS } from 'constants/index';
import { renderProductFormulationTypes } from 'lib/helper';
import { RadioGroupControllerExtendedProps, TabletTypeRadioGroupProps } from 'interfaces';
import { ProductFormulationTypesPayload, useFindAllTabletTypeLazyQuery } from 'generated/graphql';

const TabletTypeRadioGroup: FC<TabletTypeRadioGroupProps> = ({
  name,
  title,
  showTitle = true,
  isRequired = false,
  loading: getLoading,
}) => {
  const [options, setOptions] = useState<RadioGroupControllerExtendedProps[]>([]);

  const [findAll, { loading: findAllLoading }] = useFindAllTabletTypeLazyQuery({
    onCompleted: (res) => {
      const { findAllProductFormulationType } = res || {};
      const { data, response } = findAllProductFormulationType || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setOptions(renderProductFormulationTypes(data as ProductFormulationTypesPayload['data']));
      } else {
        setOptions([]);
      }
    },

    onError: () => {
      setOptions([]);
    },
  });

  const fetchProductType = useCallback(async () => {
    await findAll({
      variables: {
        findAllProductFormulationTypeInput: {
          paginationOptions: {
            page: 1,
            limit: 10,
          },
          isActive: true,
          isInnerCapTab: true,
        },
      },
    });
  }, [findAll]);

  useEffect(() => {
    fetchProductType();
  }, [fetchProductType]);

  const loading = findAllLoading || getLoading;

  return (
    <RadioGroupController
      name={name}
      title={title}
      loaderRows={1}
      loading={loading}
      loaderColumns={4}
      options={options}
      showTitle={showTitle}
      isRequired={isRequired}
    />
  );
};

export default TabletTypeRadioGroup;
