import { Box, Chip, Grid, TableCell, TableRow } from '@mui/material';
import { FC, Fragment, Reducer, useCallback, useEffect, useReducer } from 'react';
// components
import { Alert } from 'components/common/Alert';
import Selector from 'components/common/Selector';
import TableLoader from 'components/common/TableLoader';
import NoDataFound from 'components/common/NoDataFound';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import TableTabsComponent from 'components/common/TableTabsComponent';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
import BottleSizeSelect from 'components/common/simpleSelectors/BottleSize';
// constants, reducers, graphql, styles
import {
  TYPE_TEXT,
  HTTP_STATUS,
  ACTIVE_TEXT,
  INACTIVE_TEXT,
  BOTTLE_SIZE_TEXT,
  TabEnum,
  TABS,
  LOADING_TABLE_ROWS,
  BOTTLE_TYPE_MAPPED,
  EDIT_BOTTLE_PRICE_ROUTE,
  BOTTLE_PRICE_TABLE_HEADER,
} from 'constants/index';
import { textWhiteSpace } from 'styles/commonComponentStyle';
import {
  BottlePricesPayload,
  useUpdateBottlePriceMutation,
  useFindAllBottlePriceLazyQuery,
  BottleTypeEnum,
} from 'generated/graphql';
import { Action, ActionType, State, initialState, bottlePriceReducer } from 'reducer/bottlePriceReducer';

const BottleSizeTable: FC = () => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(bottlePriceReducer, initialState);
  const { page, rowsPerPage, count, data, activeTab, bottleSize, bottleType } = state;
  const { value: bottleSizeId } = bottleSize;

  const [findAllBottlePrice, { loading: fetchLoading, error }] = useFindAllBottlePriceLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllBottlePrice } = data;
      const { pagination, response, data: bottlePriceData } = findAllBottlePrice || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { page, totalCount } = pagination || {};
        dispatch({ type: ActionType.SET_PAGE, page: page || 1 });
        dispatch({ type: ActionType.SET_COUNT, count: totalCount || 0 });
        dispatch({ type: ActionType.SET_DATA, data: bottlePriceData as BottlePricesPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const [updateBottlePriceStatus, { loading: updateLoading }] = useUpdateBottlePriceMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateBottlePrice } = data;
      const { response } = updateBottlePrice || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        fetchBottlePrice();
      } else {
        Alert.error(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_COUNT, count: 0 });
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchBottlePrice = useCallback(async () => {
    await findAllBottlePrice({
      variables: {
        findAllBottlePricesInput: {
          paginationOptions: {
            limit: rowsPerPage,
            page,
          },
          ...(bottleSizeId && { bottleSizeId }),
          ...(bottleType && { type: bottleType as BottleTypeEnum }),
          ...(activeTab !== TabEnum.All && { isActive: activeTab === TabEnum.Active }),
        },
      },
    });
  }, [findAllBottlePrice, rowsPerPage, page, bottleSizeId, bottleType, activeTab]);

  useEffect(() => {
    fetchBottlePrice();
  }, [fetchBottlePrice]);

  const tabHandler = (_: React.SyntheticEvent<Element, Event>, value: string) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab: value as TabEnum });
  };

  const onActiveHandler = async (id: string, status: boolean) => {
    await updateBottlePriceStatus({
      variables: {
        updateBottlePriceInput: {
          id,
          isActive: !status,
        },
      },
    });
  };

  const onTypeChange = (item: string) => dispatch({ type: ActionType.SET_BOTTLE_TYPE, bottleType: item });

  const loading = fetchLoading || updateLoading;

  const noData = Boolean((!fetchLoading && !data?.length) || error);

  return (
    <TableContainer>
      <TableTabsComponent activeTab={activeTab} tabsList={TABS} tabHandler={tabHandler} />

      <Box m={({ spacing }) => spacing(2, 3)}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <BottleSizeSelect
              isClearable
              value={bottleSize}
              name="bottle-size"
              title={BOTTLE_SIZE_TEXT}
              handleChange={(item) => dispatch({ type: ActionType.SET_BOTTLE_SIZE, bottleSize: item })}
            />
          </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Selector
              addAll
              displayEmpty
              value={bottleType}
              id="bottle-type"
              title={TYPE_TEXT}
              options={BOTTLE_TYPE_MAPPED}
              onChange={onTypeChange}
            />
          </Grid>
        </Grid>
      </Box>

      <TableComponent
        noData={noData}
        page={page - 1}
        count={count}
        rowsPerPage={rowsPerPage}
        tableHeader={BOTTLE_PRICE_TABLE_HEADER}
        setPage={(p: number) => dispatch({ type: ActionType.SET_PAGE, page: p + 1 })}
        setRowsPerPage={(r: number) => dispatch({ type: ActionType.SET_ROWS_PER_PAGE, rowsPerPage: r })}>
        {loading ? (
          <TableLoader columns={7} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const { id, isCr, price, isActive, bottleSize, type, bottlePriceSuppliers } = cell || {};
              const { name } = bottleSize || {};

              // make product type comma separated string
              const suppliers = bottlePriceSuppliers?.reduce((accumulator, bottlePriceSupplier) => {
                const name = bottlePriceSupplier?.supplier?.name;
                return name ? `${accumulator}${accumulator ? ', ' : ''}${name}` : accumulator;
              }, '');

              return (
                <TableRow key={id}>
                  <TableCell sx={textWhiteSpace}>{name ?? '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{type ?? '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{price ? `$${price}` : '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{isCr ? 'Yes' : 'No'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{suppliers ?? '--'}</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={isActive ? ACTIVE_TEXT : INACTIVE_TEXT}
                      color={isActive ? 'success' : 'error'}
                    />
                  </TableCell>
                  <TableCell>
                    <ActionMenuDropdown
                      id={id ?? ''}
                      isActive={!!isActive}
                      editRoute={EDIT_BOTTLE_PRICE_ROUTE}
                      onActiveHandler={onActiveHandler}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>

      <NoDataFound noData={noData} />
    </TableContainer>
  );
};

export default BottleSizeTable;
