import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import DataLoader from 'components/common/DataLoader';
import BottleSizeForm from './components/BottleSizeForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import {
  HTTP_STATUS,
  ADD_BOTTLE_SIZE_TEXT,
  EDIT_BOTTLE_SIZE_TEXT,
  bottleSizeInitialValue,
  BOTTLE_SIZE_IS_UPDATED_TEXT,
  BOTTLE_SIZE_IS_CREATED_TEXT,
  ADD_TEXT,
} from 'constants/index';
import { AddBottleSizeFormType, ParamType } from 'interfaces';
import { updateBottleSizeTypeSchema } from 'validationSchema';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  BottleSizePayload,
  useCreateBottleSizeMutation,
  useGetBottleSizeLazyQuery,
  useUpdateBottleSizeMutation,
} from 'generated/graphql';

const Edit: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const bottleSizeMethods = useForm<AddBottleSizeFormType>({
    defaultValues: bottleSizeInitialValue,
    resolver: yupResolver(updateBottleSizeTypeSchema),
  });

  const { handleSubmit: handleBottleSizeSubmit, setValue: setBottleSizeValue } = bottleSizeMethods;

  const [updateBottleSize, { loading: productTypeLoading }] = useUpdateBottleSizeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateBottleSize } = data;
      const { response } = updateBottleSize || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [createBottleSize, { loading: createLoading }] = useCreateBottleSizeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createBottleSize } = data;
      const { response } = createBottleSize || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getBottleSize, { loading: getLoading }] = useGetBottleSizeLazyQuery({
    onCompleted: (data) => {
      const { getBottleSize } = data || {};
      const { response, bottleSize } = getBottleSize || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(bottleSize as BottleSizePayload['bottleSize']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });

  const onProductTypeSubmit: SubmitHandler<AddBottleSizeFormType> = async (data) => {
    const { name, cc, lidSize, oz, productTypes } = data || {};
    const productTypesIds = productTypes?.map((item) => {
      return item?.value;
    });

    if (id) {
      await updateBottleSize({
        variables: {
          updateBottleSizeInput: {
            id,
            name,
            ...(oz && { oz: Number(oz) }),
            ...(cc && { cc: Number(cc) }),
            ...(lidSize && { lidSize: Number(lidSize) }),
            bottleSizeProductTypes: productTypesIds ?? [],
          },
        },
      });
    } else {
      await createBottleSize({
        variables: {
          createBottleSizeInput: {
            name,
            ...(oz && { oz: Number(oz) }),
            ...(cc && { cc: Number(cc) }),
            ...(lidSize && { lidSize: Number(lidSize) }),
            bottleSizeProductTypes: productTypesIds ?? [],
          },
        },
      });
    }
  };

  const fetchBottleSize = useCallback(async () => {
    id &&
      (await getBottleSize({
        variables: {
          getBottleSizeInput: {
            id,
          },
        },
      }));
  }, [id, getBottleSize]);

  const setValues = (params: BottleSizePayload['bottleSize']) => {
    const { name, cc, lidSize, oz, bottleSizeProductTypes } = params || {};

    const productType = bottleSizeProductTypes?.map((item) => {
      return { name: item?.productType?.name || '', value: item?.productType?.id || '' };
    });
    setBottleSizeValue('name', name || '');
    setBottleSizeValue('cc', `${cc || ''}`);
    setBottleSizeValue('lidSize', `${lidSize || ''}`);
    setBottleSizeValue('oz', `${oz || ''}`);
    setBottleSizeValue('productTypes', productType ?? []);
  };

  useEffect(() => {
    id && fetchBottleSize();
  }, [id, fetchBottleSize]);

  const loading = productTypeLoading || createLoading;

  return (
    <Fragment>
      <FormProvider {...bottleSizeMethods}>
        <form onSubmit={handleBottleSizeSubmit(onProductTypeSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{id ? EDIT_BOTTLE_SIZE_TEXT : ADD_BOTTLE_SIZE_TEXT}</Typography>
            {!id && (
              <Button type="submit" variant="contained" size="small">
                {ADD_TEXT}
              </Button>
            )}
          </Box>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12}>
              {getLoading ? (
                <DataLoader rows={5} columns={12} />
              ) : (
                <BottleSizeForm loading={loading} isEdit={!!id} />
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <BackdropLoader open={loading} text={id ? BOTTLE_SIZE_IS_UPDATED_TEXT : BOTTLE_SIZE_IS_CREATED_TEXT} />
    </Fragment>
  );
};

export default Edit;
