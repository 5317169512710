import { FC, Fragment, JSX } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
// components
import { CloseIcon } from 'assets/svgs';
import { Alert } from 'components/common/Alert';
import { BackdropLoader } from 'components/common/BackdropLoader';
import ProductExcipientForm from './components/ProductExcipientForm';
// Schema, styles, graphql, constants, helper
import {
  ADD_TEXT,
  HTTP_STATUS,
  ADD_PRODUCT_EXCIPIENT_TEXT,
  productExcipientInitialValue,
  PRODUCT_EXCIPIENT_IS_CREATING_TEXT,
} from 'constants/index';
import { productExcipientTypeSchema } from 'validationSchema';
import { useCreateProductExcipientMutation } from 'generated/graphql';
import { ProductExcipientFormType, AddProductExcipientProps } from 'interfaces';
import { suppliersIds } from 'lib/helper';

const Add: FC<AddProductExcipientProps> = ({ excipientType, onClose, open, fetch }): JSX.Element => {
  const methods = useForm<ProductExcipientFormType>({
    defaultValues: productExcipientInitialValue,
    resolver: yupResolver(productExcipientTypeSchema),
  });

  const { handleSubmit, setValue, clearErrors } = methods;

  const [createProductExcipient, { loading }] = useCreateProductExcipientMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createProductExcipient } = data;
      const { response } = createProductExcipient || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        fetch();
        onClose();
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });
  const handleClick = () => {
    resetFormValue();
    clearErrors();
    onClose();
  };

  const resetFormValue = () => {
    setValue('excipient', { value: '', name: '' });
    setValue('suppliers', []);
    setValue('percentage', '');
    setValue('perKgCost', '');
  };
  const onSubmit: SubmitHandler<ProductExcipientFormType> = async (data) => {
    const { percentage, perKgCost, excipient, suppliers } = data || {};
    const { value: excipientId } = excipient || {};

    const { id } = excipientType || {};

    await createProductExcipient({
      variables: {
        createProductExcipientInput: {
          excipientId,
          excipientTypeId: id || '',
          perKgCost: parseFloat(perKgCost),
          percentage: parseFloat(percentage),
          productExcipientSuppliers: suppliersIds(suppliers),
        },
      },
    });
  };

  return (
    <Fragment>
      <Dialog open={open}>
        <DialogTitle variant="h6">{ADD_PRODUCT_EXCIPIENT_TEXT}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClick}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent dividers>
              <ProductExcipientForm loading={loading} />
            </DialogContent>

            <DialogActions>
              <Button type="submit" variant="contained" size="small" disabled={loading}>
                {ADD_TEXT}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>

      <BackdropLoader open={loading} text={PRODUCT_EXCIPIENT_IS_CREATING_TEXT} />
    </Fragment>
  );
};

export default Add;
