import { FC, Fragment, JSX } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Card, CardActions, CardContent, Grid } from '@mui/material';
// components
import InputController from 'controller/InputController';
import BottleSizeSelector from 'components/common/selectors/bottleSize';
import ProductSizeSelector from 'components/common/selectors/productSize';
import ServingSizeSelector from 'components/common/selectors/servingSize';
import ProductTypesSelector from 'components/common/selectors/productType';
// constants, styles, interfaces
import {
  SAVE_TEXT,
  LOH_COST_TEXT,
  BOTTLE_SIZE_TEXT,
  SERVING_SIZE_TEXT,
  PRODUCT_SIZE_TEXT,
  PRODUCT_TYPE_TEXT,
  FILING_PERCENTAGE_TEXT,
} from 'constants/index';
import { ProductTypes } from 'generated/graphql';
import { AddBottleCapacityFormType, BottleCapacityFormProps } from 'interfaces';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';

const BottleCapacityForm: FC<BottleCapacityFormProps> = ({
  isEdit = false,
  loading = false,
}): JSX.Element => {
  const { watch } = useFormContext<AddBottleCapacityFormType>();
  const { productType } = watch();
  const { value: productTypeId, type } = productType || {};

  return (
    <Card sx={forInputLabelBg}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <InputController
              isRequired
              fieldType="number"
              name="lohCost"
              disabled={loading}
              title={LOH_COST_TEXT}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <BottleSizeSelector isRequired title={BOTTLE_SIZE_TEXT} name="bottleSize" />
          </Grid>

          <Grid item xs={12} lg={12}>
            <ProductTypesSelector isRequired title={PRODUCT_TYPE_TEXT} name="productType" />
          </Grid>
          <Grid item xs={12} xl={12}>
            <ProductSizeSelector
              isRequired
              name="productSize"
              title={PRODUCT_SIZE_TEXT}
              productTypeId={productTypeId}
              disabled={!productTypeId}
            />
          </Grid>
          {type !== ProductTypes.Powder && (
            <>
              <Grid item xs={12} lg={12}>
                <ServingSizeSelector title={SERVING_SIZE_TEXT} name="servingSize" />
              </Grid>

              <Grid item xs={12} lg={12}>
                <InputController
                  fieldType="number"
                  name="fillingPercentage"
                  disabled={loading}
                  title={FILING_PERCENTAGE_TEXT}
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>

      {isEdit ? (
        <CardActions>
          <Box sx={flexMarginTopEndCenter}>
            <Button type="submit" variant="contained" size="small" disabled={loading}>
              {SAVE_TEXT}
            </Button>
          </Box>
        </CardActions>
      ) : (
        <Fragment />
      )}
    </Card>
  );
};

export default BottleCapacityForm;
