import { useEffect, useState } from 'react';

export const useDebounce = (inputValue: string) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setValue(inputValue);
    }, 500);

    return () => clearTimeout(delayInputTimeoutId);
  }, [inputValue]);

  return value;
};
