import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
// components
import BottleCapacityTable from './BottleCapacityTable';
// constant, styles, reducer, graphql
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { ADD_NEW_TEXT, ADD_BOTTLE_CAPACITY_ROUTE, BOTTLE_CAPACITY_TEXT } from 'constants/index';

const BottleCapacity: FC = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{BOTTLE_CAPACITY_TEXT}</Typography>
        <Button color="primary" variant="contained" onClick={() => navigate(ADD_BOTTLE_CAPACITY_ROUTE)}>
          <AddRoundedIcon />
          {ADD_NEW_TEXT}
        </Button>
      </Box>
      <BottleCapacityTable />
    </Fragment>
  );
};

export default BottleCapacity;
