import { Fragment, FC, JSX } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { CircleRounded as CircleRoundedIcon } from '@mui/icons-material';
// component
import { CloseIcon } from 'assets/svgs';
// interfaces
import { ingredientsBlendProps } from 'interfaces';

const IngredientBlends: FC<ingredientsBlendProps> = ({ ingredientsBlend, onBlendAdd }): JSX.Element => {
  const onBlendDelete = (blendName: string) => {
    const data = ingredientsBlend?.filter(({ name }) => name !== blendName);
    onBlendAdd(data);
  };

  return (
    <Grid container spacing={2}>
      {ingredientsBlend?.map((item) => {
        const { name, ingredients } = item;
        return (
          <Fragment key={name}>
            <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
              <Box
                flex={1}
                padding={2}
                display="flex"
                borderRadius={1}
                alignItems="center"
                bgcolor={'primary.main'}>
                <Box>
                  <Typography variant="caption">{name}</Typography>
                  <Box>
                    {ingredients?.map(({ ingredientId, name }) => (
                      <Box key={ingredientId} display="flex" alignItems="center" mt={1}>
                        <CircleRoundedIcon fontSize="small" />
                        <Typography ml={1} variant="caption">
                          {name}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={1} xs={1} display="flex" alignItems="center">
              <Box>
                <IconButton onClick={() => onBlendDelete(name)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
};

export default IngredientBlends;
