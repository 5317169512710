import { HttpStatusCode } from 'axios';
import { useParams } from 'react-router-dom';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
import { FC, Fragment, JSX, useCallback, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
// components
import Modal from './Modal';
import Table from './components/Table';
import DetailCard from './components/Card';
import { Alert } from 'components/common/Alert';
import EmptyTableTab from 'components/common/EmptyTableTab';
import TableContainer from 'components/common/TableContainer';
// constant, styles, interfaces
import { ParamType } from 'interfaces';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { ADD_NEW_TEXT, FORMULATION_SYRUP_TYPE_INGREDIENT_TEXT } from 'constants/index';
import {
  ProductIngredientSyrupTypePayload,
  ProductIngredientSyrupTypesPayload,
  useRemoveProductIngredientSyrupTypeMutation,
  useFindAllProductIngredientSyrupTypesTableLazyQuery,
} from 'generated/graphql';

const Detail: FC = (): JSX.Element => {
  const params = useParams<ParamType>();
  const { id } = params || {};

  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState<ProductIngredientSyrupTypesPayload['data']>([]);
  const [item, setItem] = useState<ProductIngredientSyrupTypePayload['productIngredientSyrupType']>(null);

  const [findAllIngredientSyrupType, { loading: fetchLoading, error }] =
    useFindAllProductIngredientSyrupTypesTableLazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { findAllProductIngredientSyrupTypes } = data;
        const { response, data: ingredientSyrupTypes } = findAllProductIngredientSyrupTypes || {};
        const { status } = response || {};
        if (status === HttpStatusCode.Ok) {
          setData(ingredientSyrupTypes as ProductIngredientSyrupTypesPayload['data']);
        } else {
          resetPage();
        }
      },

      onError: () => {
        resetPage();
      },
    });

  const [removeProductIngredientSyrupType, { loading: delLoading }] =
    useRemoveProductIngredientSyrupTypeMutation({
      onCompleted: (data) => {
        const { removeProductIngredientSyrupType } = data;
        const { response } = removeProductIngredientSyrupType || {};
        const { status, message } = response || {};
        if (status === HttpStatusCode.Ok) {
          Alert.success(message ?? '');
          fetchIngredientSyrupType();
        } else {
          Alert.error(message ?? '');
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    });

  const fetchIngredientSyrupType = useCallback(async () => {
    try {
      id &&
        (await findAllIngredientSyrupType({
          variables: {
            findAllProductIngredientSyrupTypesInput: {
              productFormulationSyrupTypeId: id,
              paginationOptions: {
                limit: 10,
                page: 1,
              },
            },
          },
        }));
    } catch (error) {}
  }, [findAllIngredientSyrupType, id]);

  const onDelete = async (delId: string) => {
    try {
      await removeProductIngredientSyrupType({
        variables: {
          removeProductIngredientSyrupTypeInput: {
            id: delId,
          },
        },
      });
    } catch (error) {}
  };

  const resetPage = () => {
    setData([]);
  };

  useEffect(() => {
    id && fetchIngredientSyrupType();
  }, [fetchIngredientSyrupType, id]);

  const loading = fetchLoading || delLoading;

  const noData = Boolean((!fetchLoading && data?.length === 0) || error);

  const onClose = () => {
    setOpen(false);
    if (isEdit) {
      setIsEdit(false);
      setItem(null);
    }
  };

  const onEdit = (cell: ProductIngredientSyrupTypePayload['productIngredientSyrupType']) => {
    setIsEdit(true);
    setOpen(true);
    setItem(cell);
  };

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{FORMULATION_SYRUP_TYPE_INGREDIENT_TEXT}</Typography>
        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
          <AddRoundedIcon />
          {ADD_NEW_TEXT}
        </Button>
      </Box>

      <TableContainer>
        <EmptyTableTab />

        <DetailCard />

        <Table noData={noData} onEdit={onEdit} data={data} loading={loading} onDelete={onDelete} />
      </TableContainer>

      <Modal open={open} isEdit={isEdit} onClose={onClose} fetch={fetchIngredientSyrupType} item={item} />
    </Fragment>
  );
};

export default Detail;
