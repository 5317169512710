import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
//components
import IngredientsTable from 'components/main/Ingredients/IngredientsTable';
// constant, styles, reducer, graphql
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { ADD_INGREDIENT_ROUTE, ADD_NEW_TEXT, INGREDIENTS_TEXT } from 'constants/index';

const Ingredients: FC = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{INGREDIENTS_TEXT}</Typography>
        <Button
          color="primary"
          variant="contained"
          id="add-ingredient-btn"
          onClick={() => navigate(ADD_INGREDIENT_ROUTE)}>
          <AddRoundedIcon />
          {ADD_NEW_TEXT}
        </Button>
      </Box>

      <IngredientsTable />
    </Fragment>
  );
};

export default Ingredients;
