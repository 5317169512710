import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
//components
import EditStaffForm from './EditStaffForm';
//constants, interfaces, graphql
import { ParamType } from 'interfaces';
import { EDIT_STAFF_TEXT, HTTP_STATUS } from 'constants/index';
import { UserPayload, useGetUserLazyQuery } from 'generated/graphql';
//styles
import { flexCenterBetween } from 'styles/commonComponentStyle';

const EditStaff: FC = () => {
  const { id } = useParams<ParamType>();
  const [staff, setStaff] = useState<UserPayload['user']>(null);

  const [getUser, { loading }] = useGetUserLazyQuery({
    onCompleted: (data) => {
      const { getUser } = data || {};
      const { response, user } = getUser || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setStaff(user as UserPayload['user']);
      }
    },
    onError: () => {
      setStaff(null);
    },
  });

  const fetchUser = useCallback(async () => {
    await getUser({
      variables: {
        getUserInput: { id: id ?? '' },
      },
    });
  }, [getUser, id]);

  useEffect(() => {
    id && fetchUser();
  }, [fetchUser, id]);

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{EDIT_STAFF_TEXT}</Typography>
      </Box>

      <EditStaffForm loading={loading} id={id} user={staff} />
    </Fragment>
  );
};

export default EditStaff;
