import { FC, useCallback, useEffect, useMemo, useState } from 'react';
// component
import CheckboxGroupController from 'controller/CheckboxGroup';
import RadioGroupController from 'controller/RadioGroupController';
// interfaces, constants, helper, graphql, context
import { HTTP_STATUS } from 'constants/index';
import { renderProductTypes } from 'lib/helper';
import { ProductTypeSelect, ProductTypeSelectorProps } from 'interfaces';
import {
  FactBoxType,
  ProductTypes,
  ProductTypesPayload,
  useFindAllProductTypeLazyQuery,
} from 'generated/graphql';

const ProductTypeSelector: FC<ProductTypeSelectorProps> = ({
  name,
  title,
  factBoxType,
  isCheckbox = true,
  isRequired = false,
  loading: getLoading,
  onChange: handleOnChange,
}) => {
  const [options, setOptions] = useState<ProductTypeSelect[]>([]);

  const filteredOptions = useMemo(() => {
    return factBoxType === FactBoxType.Nutrition
      ? options?.filter((item) => item?.type === ProductTypes.Powder || item?.type === ProductTypes?.Gummies)
      : options;
  }, [factBoxType, options]);

  const [findAllProductType, { loading: getProductTypesLoading }] = useFindAllProductTypeLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllProductType } = data;
      const { response, data: productTypeData } = findAllProductType || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setOptions(renderProductTypes(productTypeData as ProductTypesPayload['data']));
      } else {
        setOptions([]);
      }
    },

    onError: () => {
      setOptions([]);
    },
  });

  const fetchProductTypes = useCallback(async () => {
    await findAllProductType({
      variables: {
        findAllProductTypeInput: {
          paginationOptions: {
            limit: 10,
            page: 1,
          },
          isActive: true,
        },
      },
    });
  }, [findAllProductType]);

  useEffect(() => {
    fetchProductTypes();
  }, [fetchProductTypes]);

  const loading = getProductTypesLoading || getLoading;

  return isCheckbox ? (
    <CheckboxGroupController
      name={name}
      title={title}
      loaderRows={1}
      loading={loading}
      loaderColumns={4}
      options={options}
      isRequired={isRequired}
    />
  ) : (
    <RadioGroupController
      name={name}
      title={title}
      loaderRows={1}
      loading={loading}
      loaderColumns={4}
      isRequired={isRequired}
      handleOnChange={handleOnChange}
      options={filteredOptions}
    />
  );
};

export default ProductTypeSelector;
