import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import ExcipientTypeForm from './ExcipientTypeForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { useCreateExcipientTypeMutation } from 'generated/graphql';
import {
  ADD_TEXT,
  HTTP_STATUS,
  ADD_EXCIPIENT_TYPE_TEXT,
  excipientTypeInitialValue,
  EXCIPIENT_TYPE_IS_CREATING_TEXT,
} from 'constants/index';
import { AddExcipientTypeFormType } from 'interfaces';
import { updateExcipientTypeSchema } from 'validationSchema';
import { flexCenterBetween } from 'styles/commonComponentStyle';

const Add: FC = (): JSX.Element => {
  const navigation = useNavigate();

  const methods = useForm<AddExcipientTypeFormType>({
    defaultValues: excipientTypeInitialValue,
    resolver: yupResolver(updateExcipientTypeSchema),
  });

  const { handleSubmit } = methods;

  const [createExcipientType, { loading }] = useCreateExcipientTypeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createExcipientType } = data;
      const { response } = createExcipientType || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<AddExcipientTypeFormType> = async (data) => {
    const { name, excipientLabel, loadAdd, loadAddUnit, perKgCost, productType } = data || {};
    const { value: productTypeId } = productType || {};

    await createExcipientType({
      variables: {
        createExcipientTypeInput: {
          name,
          loadAddUnit,
          loadAdd: parseFloat(loadAdd),
          perKgCost: parseFloat(perKgCost),
          excipientLabelId: excipientLabel,
          productTypeId: productTypeId,
        },
      },
    });
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{ADD_EXCIPIENT_TYPE_TEXT}</Typography>
            <Button variant="contained" color="primary" type="submit">
              {ADD_TEXT}
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12}>
              <ExcipientTypeForm loading={loading} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <BackdropLoader open={loading} text={EXCIPIENT_TYPE_IS_CREATING_TEXT} />
    </Fragment>
  );
};

export default Add;
