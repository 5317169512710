export const displayFlexAlignCenter = {
  display: 'flex',
  alignItems: 'center',
};

export const flexCenter = {
  justifyContent: 'center',
  ...displayFlexAlignCenter,
};

export const flexCenterJustifyBetween = {
  ...displayFlexAlignCenter,
  justifyContent: 'space-between',
};

export const flexJustifyBetween = {
  ...displayFlexAlignCenter,
  justifyContent: 'space-between',
};

export const flexEnd = {
  ...displayFlexAlignCenter,
  justifyContent: 'flex-end',
};

export const flexDirectionColumn = {
  display: 'flex',
  flexDirection: 'column',
};

export const flexTextCenterColumn = {
  ...flexDirectionColumn,
  textAlign: 'center',
  justifyContent: 'center',
};

export const flexStart = {
  display: 'flex',
  alignItems: 'flex-start',
};
