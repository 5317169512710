import { FC } from 'react';
import { useParams } from 'react-router-dom';
//components
import FormulaDetail from 'components/main/Formulas/FormulaDetail';
//interfaces, context
import { ParamType } from '../../interfaces';
import { FormulaDetailContextProvider } from 'contexts/FormulaDetailContext';

const FormulaDetailPage: FC = () => {
  const { id } = useParams<ParamType>();

  return (
    <FormulaDetailContextProvider id={id ?? ''}>
      <FormulaDetail />
    </FormulaDetailContextProvider>
  );
};

export default FormulaDetailPage;
