import { FC, Fragment } from 'react';
import { Box, Divider, Typography } from '@mui/material';
// component
import DataLoader from 'components/common/DataLoader';
// interface
import { FormulaEstimatorRowProps } from 'interfaces';

const RowView: FC<FormulaEstimatorRowProps> = ({
  name,
  value,
  mt = 2,
  loading,
  isInfo = true,
  flexDirection = 'row',
  justifyContent = 'space-between',
}) => {
  return (
    <Fragment>
      {value ? (
        <Fragment>
          <Box display="flex" flexDirection={flexDirection} justifyContent={justifyContent} mt={mt} mb={2}>
            {loading ? (
              <DataLoader columns={12} rows={1} />
            ) : (
              <Fragment>
                <Typography variant="caption" fontWeight={700} color="customGrayColor.main">
                  {name}
                </Typography>
                <Typography variant="caption" fontWeight={700}>
                  {value}
                </Typography>
              </Fragment>
            )}
          </Box>
          {isInfo ? <Divider /> : <Fragment />}
        </Fragment>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};

export default RowView;
