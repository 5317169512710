import { FC } from 'react';
import { Box, Grid } from '@mui/material';
// components
import RowView from 'components/common/RowView';
import DataLoader from 'components/common/DataLoader';
// interfaces, constants, helper
import { SweetenerCardProps } from 'interfaces';
import {
  SIZE_TEXT,
  TYPE_TEXT,
  NAME_TEXT,
  NATURE_TEXT,
  PRODUCT_TYPE_TEXT,
  MG_PER_SERVING_TEXT,
  PER_SERVING_COST_TEXT,
  PRODUCT_FORMULATION_TEXT,
} from 'constants/index';
import { formatValue } from 'lib/helper';

const SweetenerCard: FC<SweetenerCardProps> = ({ sweetener, loading }) => {
  const { productFormulationType, name, productType, mgPerServing, costPerServing, nature, size, type } =
    sweetener || {};
  const { name: productName } = productType || {};
  const { name: productFormulationTypeName } = productFormulationType || {};

  return (
    <Box m={4}>
      {loading ? (
        <DataLoader columns={4} rows={2} />
      ) : (
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={6}>
            <RowView name={NAME_TEXT} value={name || '--'} isInfo={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <RowView name={NATURE_TEXT} value={formatValue(`${nature || '--'}`)} isInfo={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <RowView name={SIZE_TEXT} value={formatValue(`${size || '--'}`)} isInfo={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <RowView name={TYPE_TEXT} value={formatValue(type || '--')} isInfo={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <RowView name={PRODUCT_TYPE_TEXT} value={productName || '--'} isInfo={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <RowView name={MG_PER_SERVING_TEXT} value={`${mgPerServing || '0'}`} isInfo={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <RowView name={PER_SERVING_COST_TEXT} value={`$${costPerServing || '0'}`} isInfo={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <RowView
              name={PRODUCT_FORMULATION_TEXT}
              value={`${productFormulationTypeName || ''}`}
              isInfo={false}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SweetenerCard;
