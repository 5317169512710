// packages import
import { FC } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
// constants, interfaces, theme
import { CommonLoaderProps } from 'interfaces';
import { APP_LOADING_TEXT } from 'constants/index';

export const CommonLoader: FC<CommonLoaderProps> = ({ text, isSmallLoader }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight={isSmallLoader ? 'auto' : 'calc(100vh - 16px)'}>
      <Box textAlign="center">
        <CircularProgress color="primary" />

        <Typography mt={2} variant="h5" color="primary">
          {text ?? APP_LOADING_TEXT}
        </Typography>
      </Box>
    </Box>
  );
};
