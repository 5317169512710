import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import ExcipientTypeForm from './ExcipientTypeForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import {
  LoadAddUnit,
  ExcipientTypePayload,
  useGetExcipientTypeLazyQuery,
  useUpdateExcipientTypeMutation,
} from 'generated/graphql';
import {
  HTTP_STATUS,
  EDIT_EXCIPIENT_TYPE_TEXT,
  excipientTypeInitialValue,
  EXCIPIENT_IS_UPDATED_TEXT,
} from 'constants/index';
import { updateExcipientTypeSchema } from 'validationSchema';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { AddExcipientTypeFormType, ParamType } from 'interfaces';

const Edit: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const excipientTypeMethods = useForm<AddExcipientTypeFormType>({
    defaultValues: excipientTypeInitialValue,
    resolver: yupResolver(updateExcipientTypeSchema),
  });

  const { handleSubmit: handleExcipientTypeSubmit, setValue: setExcipientTypeValue } = excipientTypeMethods;

  const [updateExcipientType, { loading: ExcipientLoading }] = useUpdateExcipientTypeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateExcipientType } = data;
      const { response } = updateExcipientType || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getExcipientType, { loading: getLoading }] = useGetExcipientTypeLazyQuery({
    onCompleted: (data) => {
      const { getExcipientType } = data || {};
      const { response, excipientType } = getExcipientType || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(excipientType as ExcipientTypePayload['excipientType']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });

  const onExcipientSubmit: SubmitHandler<AddExcipientTypeFormType> = async (data) => {
    const { name, excipientLabel, loadAdd, loadAddUnit, perKgCost, productType } = data || {};
    const { value: productTypeId } = productType || {};

    await updateExcipientType({
      variables: {
        updateExcipientTypeInput: {
          id: id || '',
          name,
          loadAddUnit,
          loadAdd: parseFloat(loadAdd),
          perKgCost: parseFloat(perKgCost),
          excipientLabelId: excipientLabel || '',
          productTypeId: productTypeId || '',
        },
      },
    });
  };

  const fetchExcipientType = useCallback(async () => {
    id &&
      (await getExcipientType({
        variables: {
          getExcipientTypeInput: {
            id,
          },
        },
      }));
  }, [id, getExcipientType]);

  const setValues = (params: ExcipientTypePayload['excipientType']) => {
    const { name, loadAdd, loadAddUnit, perKgCost, excipientLabel, productType } = params || {};
    const { id } = excipientLabel || {};
    const { id: productTypeId, name: productTypeName } = productType || {};

    setExcipientTypeValue('name', name || '');
    setExcipientTypeValue('excipientLabel', id || '');
    setExcipientTypeValue('loadAdd', `${loadAdd ?? ''}`);
    setExcipientTypeValue('perKgCost', `${perKgCost ?? 0}`);

    setExcipientTypeValue('loadAddUnit', loadAddUnit ?? LoadAddUnit.Mg);
    setExcipientTypeValue('productType', {
      value: productTypeId ?? '',
      name: productTypeName ?? '',
    });
  };

  useEffect(() => {
    id && fetchExcipientType();
  }, [id, fetchExcipientType]);

  const loading = ExcipientLoading || getLoading;

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{EDIT_EXCIPIENT_TYPE_TEXT}</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={6} md={12}>
          <FormProvider {...excipientTypeMethods}>
            <form onSubmit={handleExcipientTypeSubmit(onExcipientSubmit)}>
              <ExcipientTypeForm loading={loading} isEdit />
            </form>
          </FormProvider>
        </Grid>
      </Grid>

      <BackdropLoader open={loading} text={EXCIPIENT_IS_UPDATED_TEXT} />
    </Fragment>
  );
};

export default Edit;
