import { FC } from 'react';
// component
import AddFormula from 'components/main/Formulas/Ingredients/Add';
import { FormulaIngredientContextProvider } from 'contexts/FormulaByIngredientContext';

const AddFormulaByIngredients: FC = () => (
  <FormulaIngredientContextProvider>
    <AddFormula />
  </FormulaIngredientContextProvider>
);

export default AddFormulaByIngredients;
