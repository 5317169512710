import { FC, Fragment, JSX } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Card, CardActions, CardContent, Grid } from '@mui/material';
// components
import InputController from 'controller/InputController';
import ProductSizeSelector from 'components/common/selectors/productSize';
import ProductTypesSelector from 'components/common/selectors/productType';
import SubProductTypeSelector from 'components/common/selectors/productSubType';
import ProductFormulationTypeSelector from 'components/common/selectors/productFormulationType';
// constants, styles, interfaces
import { FormulationSyrupTypeFormType, FormulationSyrupFormProps } from 'interfaces';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';
import {
  COST_TEXT,
  SAVE_TEXT,
  WEIGHT_TEXT,
  PRODUCT_TYPE_TEXT,
  PRODUCT_SIZE_TEXT,
  SUB_PRODUCT_TYPE_TEXT,
  PRODUCT_FORMULATION_TEXT,
  selectTypeInitialValues,
} from 'constants/index';

const FormulationSyrupForm: FC<FormulationSyrupFormProps> = ({
  isEdit = false,
  loading = false,
}): JSX.Element => {
  const { watch, setValue } = useFormContext<FormulationSyrupTypeFormType>();
  const { productType, subProductType } = watch();
  const { value: productTypeId } = productType || {};
  const { value: subProductTypeId } = subProductType || {};

  const onProductChange = () => {
    setValue('subProductType', selectTypeInitialValues);
    setValue('productSize', selectTypeInitialValues);
    setValue('productFormulationType', selectTypeInitialValues);
  };

  const onSubProductChange = () => {
    setValue('productSize', selectTypeInitialValues);
    setValue('productFormulationType', selectTypeInitialValues);
  };

  return (
    <Card sx={forInputLabelBg}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <InputController isRequired fieldType="number" name="cost" disabled={loading} title={COST_TEXT} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <InputController
              isRequired
              fieldType="number"
              name="weight"
              disabled={loading}
              title={WEIGHT_TEXT}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ProductTypesSelector
              isRequired
              title={PRODUCT_TYPE_TEXT}
              name="productType"
              handleChange={onProductChange}
            />
          </Grid>

          <Grid item xs={12} lg={12}>
            <SubProductTypeSelector
              name="subProductType"
              productTypeId={productTypeId}
              title={SUB_PRODUCT_TYPE_TEXT}
              disabled={!productTypeId}
              handleChange={onSubProductChange}
            />
          </Grid>

          <Grid item xs={12} xl={12}>
            <ProductSizeSelector
              isRequired
              name="productSize"
              title={PRODUCT_SIZE_TEXT}
              disabled={!productTypeId}
              productTypeId={productTypeId}
              productSubTypeId={subProductTypeId}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ProductFormulationTypeSelector
              isRequired
              disabled={!productTypeId}
              productSubTypeId={subProductTypeId}
              productTypeId={productTypeId}
              name="productFormulationType"
              title={PRODUCT_FORMULATION_TEXT}
            />
          </Grid>
        </Grid>
      </CardContent>

      {isEdit ? (
        <CardActions>
          <Box sx={flexMarginTopEndCenter}>
            <Button type="submit" variant="contained" size="small" disabled={loading}>
              {SAVE_TEXT}
            </Button>
          </Box>
        </CardActions>
      ) : (
        <Fragment />
      )}
    </Card>
  );
};

export default FormulationSyrupForm;
