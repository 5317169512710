import { FC } from 'react';
// components
import HeavyMetalsInfoCard from './HeavyMetalsCard';
// interfaces
import { HeavyMetalsTabProps } from 'interfaces';

const HeavyMetals: FC<HeavyMetalsTabProps> = ({ loading, isEdit = false }) => {
  return <HeavyMetalsInfoCard loading={loading} isEdit={isEdit} />;
};

export default HeavyMetals;
