import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
// components
import ExcipientTable from './ExcipientTable';
// constant, styles, reducer, graphql
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { ADD_NEW_TEXT, ADD_EXCIPIENT_ROUTE, EXCIPIENT_AND_CARRIER_TEXT } from 'constants/index';

const Excipient: FC = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{EXCIPIENT_AND_CARRIER_TEXT}</Typography>
        <Button variant="contained" color="primary" onClick={() => navigate(ADD_EXCIPIENT_ROUTE)}>
          <AddRoundedIcon />
          {ADD_NEW_TEXT}
        </Button>
      </Box>
      <ExcipientTable />
    </Fragment>
  );
};

export default Excipient;
