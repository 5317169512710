import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography, Button } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import ExcipientForm from './ExcipientForm';
import { Alert } from 'components/common/Alert';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { AddExcipientFormType } from 'interfaces';
import { updateExcipientSchema } from 'validationSchema';
import { useCreateExcipientMutation } from 'generated/graphql';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  ADD_TEXT,
  HTTP_STATUS,
  ADD_EXCIPIENT_TEXT,
  excipientInitialValue,
  EXCIPIENT_IS_CREATING_TEXT,
} from 'constants/index';

const Add: FC = (): JSX.Element => {
  const navigation = useNavigate();

  const excipientMethods = useForm<AddExcipientFormType>({
    defaultValues: excipientInitialValue,
    resolver: yupResolver(updateExcipientSchema),
  });

  const { handleSubmit } = excipientMethods;

  const [addExcipient, { loading }] = useCreateExcipientMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createExcipient } = data;
      const { response } = createExcipient || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<AddExcipientFormType> = async (data) => {
    const { name } = data || {};

    await addExcipient({
      variables: {
        createExcipientInput: {
          name,
        },
      },
    });
  };

  return (
    <Fragment>
      <FormProvider {...excipientMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{ADD_EXCIPIENT_TEXT}</Typography>
            <Button variant="contained" color="primary" type="submit">
              {ADD_TEXT}
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12}>
              <ExcipientForm loading={loading} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <BackdropLoader open={loading} text={EXCIPIENT_IS_CREATING_TEXT} />
    </Fragment>
  );
};

export default Add;
