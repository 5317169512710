// packages block
import { FC, Fragment } from 'react';
import { Typography, Box } from '@mui/material';
// component
import { NoIngredientFoundIcon } from 'assets/svgs';
// constant, svg, interface
import { NoDataFoundProps } from 'interfaces';
import { flexCenter } from 'theme/styleConstant';
import { NO_INGREDIENT_TEXT, SEARCH_AND_ADD_INGREDIENTS_TEXT } from 'constants/index';

const NoIngredientFound: FC<NoDataFoundProps> = ({ noData }): JSX.Element => {
  return (
    <Fragment>
      {noData && (
        <Box sx={flexCenter} flexDirection="column" py={7}>
          <Box>
            <NoIngredientFoundIcon />
          </Box>

          <Typography variant="h5" fontSize={14} color={`customWhiteColor.main`}>
            {NO_INGREDIENT_TEXT}
          </Typography>

          <Typography variant="h4" fontSize={14} mt={1} color={`customWhiteColor.dark`}>
            {SEARCH_AND_ADD_INGREDIENTS_TEXT}
          </Typography>
        </Box>
      )}
    </Fragment>
  );
};

export default NoIngredientFound;
