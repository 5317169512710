import { FC } from 'react';
// components
import SubComponentInfoCard from './SubComponentCard';
// interfaces
import { SubComponentTabProps } from 'interfaces';

const SubComponent: FC<SubComponentTabProps> = ({ loading, isEdit = false }) => {
  return <SubComponentInfoCard loading={loading} isEdit={isEdit} />;
};

export default SubComponent;
