import { FC, JSX } from 'react';
// components
import ExcipientFields from '../../../Excipients';
// graphql
import { ProductTypes } from 'generated/graphql';

const PowderFilled: FC = (): JSX.Element => {
  return <ExcipientFields productType={ProductTypes.Capsule} />;
};

export default PowderFilled;
