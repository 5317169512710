import { FC } from 'react';
// component
import AddFormula from 'components/main/Formulas/FactBox/Add';
// context
import { AddFactBoxFormulaContextProvider } from 'contexts/AddFactBoxContext';

const AddFormulaByFactBox: FC = () => {
  return (
    <AddFactBoxFormulaContextProvider>
      <AddFormula />
    </AddFactBoxFormulaContextProvider>
  );
};

export default AddFormulaByFactBox;
