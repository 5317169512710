import { FC, createRef, useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
//interfaces, style, helper
import { InputControllerProps } from 'interfaces';
import { cursorPointer } from 'styles/commonComponentStyle';
import { splitStringIntoArrayOfString } from 'lib/helper';

const InputController: FC<InputControllerProps> = ({
  name,
  size,
  title,
  minRows,
  maxRows,
  disabled,
  fieldType,
  isPassword,
  isRequired,
  isMultiLine,
  placeholder,
  defaultValue,
  margin = 'dense',
  inputAdornmentIcon,
  isMultiValue = false,
}) => {
  const [show, setShow] = useState(false);

  const { control } = useFormContext();
  const input = createRef<HTMLDivElement>();

  const removeWheel = (e: Event) => {
    e?.preventDefault();
  };

  useEffect(() => {
    let inputRef = input?.current;
    if (fieldType === 'number') {
      inputRef?.addEventListener('wheel', removeWheel, { passive: false });
    }
    return () => {
      inputRef?.removeEventListener('wheel', removeWheel);
    };
  }, [fieldType, input, name]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { name, onBlur, onChange, value } = field;
        const { error } = fieldState;
        const { message } = error || {};
        return (
          <TextField
            ref={input}
            id={name}
            fullWidth
            name={name}
            size={size}
            value={value}
            margin={margin}
            onBlur={onBlur}
            maxRows={maxRows}
            minRows={minRows}
            variant="outlined"
            autoComplete="off"
            disabled={disabled}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (isMultiValue) {
                // Split the input string into an array of strings
                const newArrayValue = splitStringIntoArrayOfString(inputValue);
                onChange(newArrayValue);
              } else {
                onChange(inputValue);
              }
            }}
            helperText={message}
            error={Boolean(error)}
            multiline={isMultiLine}
            placeholder={placeholder}
            defaultValue={defaultValue}
            label={isRequired ? `${title} *` : title}
            type={isPassword ? (show ? 'text' : 'password') : fieldType}
            InputProps={{
              endAdornment: isPassword ? (
                <Box display="flex" alignItems="center" sx={cursorPointer} onClick={() => setShow(!show)}>
                  {show ? <VisibilityOff color="primary" /> : <Visibility color="primary" />}
                </Box>
              ) : (
                inputAdornmentIcon
              ),
            }}
          />
        );
      }}
    />
  );
};

export default InputController;
