import { CountriesListType, SelectType } from 'interfaces/index';

const countries: CountriesListType[] = [
  { name: 'Afghanistan', isoCode3: 'AFG', countryCode: '004' },
  { name: 'Åland Islands', isoCode3: 'ALA', countryCode: '248' },
  { name: 'Albania', isoCode3: 'ALB', countryCode: '008' },
  { name: 'Algeria', isoCode3: 'DZA', countryCode: '012' },
  { name: 'American Samoa', isoCode3: 'ASM', countryCode: '016' },
  { name: 'Andorra', isoCode3: 'AND', countryCode: '020' },
  { name: 'Angola', isoCode3: 'AGO', countryCode: '024' },
  { name: 'Anguilla', isoCode3: 'AIA', countryCode: '660' },
  { name: 'Antarctica', isoCode3: 'ATA', countryCode: '010' },
  { name: 'Antigua and Barbuda', isoCode3: 'ATG', countryCode: '028' },
  { name: 'Argentina', isoCode3: 'ARG', countryCode: '032' },
  { name: 'Armenia', isoCode3: 'ARM', countryCode: '051' },
  { name: 'Aruba', isoCode3: 'ABW', countryCode: '533' },
  { name: 'Australia', isoCode3: 'AUS', countryCode: '036' },
  { name: 'Austria', isoCode3: 'AUT', countryCode: '040' },
  { name: 'Azerbaijan', isoCode3: 'AZE', countryCode: '031' },
  { name: 'Bahamas', isoCode3: 'BHS', countryCode: '044' },
  { name: 'Bahrain', isoCode3: 'BHR', countryCode: '048' },
  { name: 'Bangladesh', isoCode3: 'BGD', countryCode: '050' },
  { name: 'Barbados', isoCode3: 'BRB', countryCode: '052' },
  { name: 'Belarus', isoCode3: 'BLR', countryCode: '112' },
  { name: 'Belgium', isoCode3: 'BEL', countryCode: '056' },
  { name: 'Belize', isoCode3: 'BLZ', countryCode: '084' },
  { name: 'Benin', isoCode3: 'BEN', countryCode: '204' },
  { name: 'Bermuda', isoCode3: 'BMU', countryCode: '060' },
  { name: 'Bhutan', isoCode3: 'BTN', countryCode: '064' },
  { name: 'Bolivia (Plurinational State of)', isoCode3: 'BOL', countryCode: '068' },
  { name: 'Bonaire, Sint Eustatius and Saba', isoCode3: 'BES', countryCode: '535' },
  { name: 'Bosnia and Herzegovina', isoCode3: 'BIH', countryCode: '070' },
  { name: 'Botswana', isoCode3: 'BWA', countryCode: '072' },
  { name: 'Bouvet Island', isoCode3: 'BVT', countryCode: '074' },
  { name: 'Brazil', isoCode3: 'BRA', countryCode: '076' },
  { name: 'British Indian Ocean Territory', isoCode3: 'IOT', countryCode: '086' },
  { name: 'Brunei Darussalam', isoCode3: 'BRN', countryCode: '096' },
  { name: 'Bulgaria', isoCode3: 'BGR', countryCode: '100' },
  { name: 'Burkina Faso', isoCode3: 'BFA', countryCode: '854' },
  { name: 'Burundi', isoCode3: 'BDI', countryCode: '108' },
  { name: 'Cabo Verde', isoCode3: 'CPV', countryCode: '132' },
  { name: 'Cambodia', isoCode3: 'KHM', countryCode: '116' },
  { name: 'Cameroon', isoCode3: 'CMR', countryCode: '120' },
  { name: 'Canada', isoCode3: 'CAN', countryCode: '124' },
  { name: 'Cayman Islands', isoCode3: 'CYM', countryCode: '136' },
  { name: 'Central African Republic', isoCode3: 'CAF', countryCode: '140' },
  { name: 'Chad', isoCode3: 'TCD', countryCode: '148' },
  { name: 'Chile', isoCode3: 'CHL', countryCode: '152' },
  { name: 'China', isoCode3: 'CHN', countryCode: '156' },
  { name: 'Christmas Island', isoCode3: 'CXR', countryCode: '162' },
  { name: 'Cocos (Keeling) Islands', isoCode3: 'CCK', countryCode: '166' },
  { name: 'Colombia', isoCode3: 'COL', countryCode: '170' },
  { name: 'Comoros', isoCode3: 'COM', countryCode: '174' },
  { name: 'Congo', isoCode3: 'COG', countryCode: '178' },
  { name: 'Congo, Democratic Republic of the', isoCode3: 'COD', countryCode: '180' },
  { name: 'Cook Islands', isoCode3: 'COK', countryCode: '184' },
  { name: 'Costa Rica', isoCode3: 'CRI', countryCode: '188' },
  { name: "Côte d'Ivoire", isoCode3: 'CIV', countryCode: '384' },
  { name: 'Croatia', isoCode3: 'HRV', countryCode: '191' },
  { name: 'Cuba', isoCode3: 'CUB', countryCode: '192' },
  { name: 'Curaçao', isoCode3: 'CUW', countryCode: '531' },
  { name: 'Cyprus', isoCode3: 'CYP', countryCode: '196' },
  { name: 'Czechia', isoCode3: 'CZE', countryCode: '203' },
  { name: 'Denmark', isoCode3: 'DNK', countryCode: '208' },
  { name: 'Djibouti', isoCode3: 'DJI', countryCode: '262' },
  { name: 'Dominica', isoCode3: 'DMA', countryCode: '212' },
  { name: 'Dominican Republic', isoCode3: 'DOM', countryCode: '214' },
  { name: 'Ecuador', isoCode3: 'ECU', countryCode: '218' },
  { name: 'Egypt', isoCode3: 'EGY', countryCode: '818' },
  { name: 'El Salvador', isoCode3: 'SLV', countryCode: '222' },
  { name: 'Equatorial Guinea', isoCode3: 'GNQ', countryCode: '226' },
  { name: 'Eritrea', isoCode3: 'ERI', countryCode: '232' },
  { name: 'Estonia', isoCode3: 'EST', countryCode: '233' },
  { name: 'Eswatini', isoCode3: 'SWZ', countryCode: '748' },
  { name: 'Ethiopia', isoCode3: 'ETH', countryCode: '231' },
  { name: 'Falkland Islands (Malvinas)', isoCode3: 'FLK', countryCode: '238' },
  { name: 'Faroe Islands', isoCode3: 'FRO', countryCode: '234' },
  { name: 'Fiji', isoCode3: 'FJI', countryCode: '242' },
  { name: 'Finland', isoCode3: 'FIN', countryCode: '246' },
  { name: 'France', isoCode3: 'FRA', countryCode: '250' },
  { name: 'French Guiana', isoCode3: 'GUF', countryCode: '254' },
  { name: 'French Polynesia', isoCode3: 'PYF', countryCode: '258' },
  { name: 'French Southern Territories', isoCode3: 'ATF', countryCode: '260' },
  { name: 'Gabon', isoCode3: 'GAB', countryCode: '266' },
  { name: 'Gambia', isoCode3: 'GMB', countryCode: '270' },
  { name: 'Georgia', isoCode3: 'GEO', countryCode: '268' },
  { name: 'Germany', isoCode3: 'DEU', countryCode: '276' },
  { name: 'Ghana', isoCode3: 'GHA', countryCode: '288' },
  { name: 'Gibraltar', isoCode3: 'GIB', countryCode: '292' },
  { name: 'Greece', isoCode3: 'GRC', countryCode: '300' },
  { name: 'Greenland', isoCode3: 'GRL', countryCode: '304' },
  { name: 'Grenada', isoCode3: 'GRD', countryCode: '308' },
  { name: 'Guadeloupe', isoCode3: 'GLP', countryCode: '312' },
  { name: 'Guam', isoCode3: 'GUM', countryCode: '316' },
  { name: 'Guatemala', isoCode3: 'GTM', countryCode: '320' },
  { name: 'Guernsey', isoCode3: 'GGY', countryCode: '831' },
  { name: 'Guinea', isoCode3: 'GIN', countryCode: '324' },
  { name: 'Guinea-Bissau', isoCode3: 'GNB', countryCode: '624' },
  { name: 'Guyana', isoCode3: 'GUY', countryCode: '328' },
  { name: 'Haiti', isoCode3: 'HTI', countryCode: '332' },
  { name: 'Heard Island and McDonald Islands', isoCode3: 'HMD', countryCode: '334' },
  { name: 'Holy See', isoCode3: 'VAT', countryCode: '336' },
  { name: 'Honduras', isoCode3: 'HND', countryCode: '340' },
  { name: 'Hong Kong', isoCode3: 'HKG', countryCode: '344' },
  { name: 'Hungary', isoCode3: 'HUN', countryCode: '348' },
  { name: 'Iceland', isoCode3: 'ISL', countryCode: '352' },
  { name: 'India', isoCode3: 'IND', countryCode: '356' },
  { name: 'Indonesia', isoCode3: 'IDN', countryCode: '360' },
  { name: 'Iran (Islamic Republic of)', isoCode3: 'IRN', countryCode: '364' },
  { name: 'Iraq', isoCode3: 'IRQ', countryCode: '368' },
  { name: 'Ireland', isoCode3: 'IRL', countryCode: '372' },
  { name: 'Isle of Man', isoCode3: 'IMN', countryCode: '833' },
  { name: 'Israel', isoCode3: 'ISR', countryCode: '376' },
  { name: 'Italy', isoCode3: 'ITA', countryCode: '380' },
  { name: 'Jamaica', isoCode3: 'JAM', countryCode: '388' },
  { name: 'Japan', isoCode3: 'JPN', countryCode: '392' },
  { name: 'Jersey', isoCode3: 'JEY', countryCode: '832' },
  { name: 'Jordan', isoCode3: 'JOR', countryCode: '400' },
  { name: 'Kazakhstan', isoCode3: 'KAZ', countryCode: '398' },
  { name: 'Kenya', isoCode3: 'KEN', countryCode: '404' },
  { name: 'Kiribati', isoCode3: 'KIR', countryCode: '296' },
  { name: "Korea (Democratic People's Republic of)", isoCode3: 'PRK', countryCode: '408' },
  { name: 'Korea, Republic of', isoCode3: 'KOR', countryCode: '410' },
  { name: 'Kuwait', isoCode3: 'KWT', countryCode: '414' },
  { name: 'Kyrgyzstan', isoCode3: 'KGZ', countryCode: '417' },
  { name: "Lao People's Democratic Republic", isoCode3: 'LAO', countryCode: '418' },
  { name: 'Latvia', isoCode3: 'LVA', countryCode: '428' },
  { name: 'Lebanon', isoCode3: 'LBN', countryCode: '422' },
  { name: 'Lesotho', isoCode3: 'LSO', countryCode: '426' },
  { name: 'Liberia', isoCode3: 'LBR', countryCode: '430' },
  { name: 'Libya', isoCode3: 'LBY', countryCode: '434' },
  { name: 'Liechtenstein', isoCode3: 'LIE', countryCode: '438' },
  { name: 'Lithuania', isoCode3: 'LTU', countryCode: '440' },
  { name: 'Luxembourg', isoCode3: 'LUX', countryCode: '442' },
  { name: 'Macao', isoCode3: 'MAC', countryCode: '446' },
  { name: 'Madagascar', isoCode3: 'MDG', countryCode: '450' },
  { name: 'Malawi', isoCode3: 'MWI', countryCode: '454' },
  { name: 'Malaysia', isoCode3: 'MYS', countryCode: '458' },
  { name: 'Maldives', isoCode3: 'MDV', countryCode: '462' },
  { name: 'Mali', isoCode3: 'MLI', countryCode: '466' },
  { name: 'Malta', isoCode3: 'MLT', countryCode: '470' },
  { name: 'Marshall Islands', isoCode3: 'MHL', countryCode: '584' },
  { name: 'Martinique', isoCode3: 'MTQ', countryCode: '474' },
  { name: 'Mauritania', isoCode3: 'MRT', countryCode: '478' },
  { name: 'Mauritius', isoCode3: 'MUS', countryCode: '480' },
  { name: 'Mayotte', isoCode3: 'MYT', countryCode: '175' },
  { name: 'Mexico', isoCode3: 'MEX', countryCode: '484' },
  { name: 'Micronesia (Federated States of)', isoCode3: 'FSM', countryCode: '583' },
  { name: 'Moldova, Republic of', isoCode3: 'MDA', countryCode: '498' },
  { name: 'Monaco', isoCode3: 'MCO', countryCode: '492' },
  { name: 'Mongolia', isoCode3: 'MNG', countryCode: '496' },
  { name: 'Montenegro', isoCode3: 'MNE', countryCode: '499' },
  { name: 'Montserrat', isoCode3: 'MSR', countryCode: '500' },
  { name: 'Morocco', isoCode3: 'MAR', countryCode: '504' },
  { name: 'Mozambique', isoCode3: 'MOZ', countryCode: '508' },
  { name: 'Myanmar', isoCode3: 'MMR', countryCode: '104' },
  { name: 'Namibia', isoCode3: 'NAM', countryCode: '516' },
  { name: 'Nauru', isoCode3: 'NRU', countryCode: '520' },
  { name: 'Nepal', isoCode3: 'NPL', countryCode: '524' },
  { name: 'Netherlands', isoCode3: 'NLD', countryCode: '528' },
  { name: 'New Caledonia', isoCode3: 'NCL', countryCode: '540' },
  { name: 'New Zealand', isoCode3: 'NZL', countryCode: '554' },
  { name: 'Nicaragua', isoCode3: 'NIC', countryCode: '558' },
  { name: 'Niger', isoCode3: 'NER', countryCode: '562' },
  { name: 'Nigeria', isoCode3: 'NGA', countryCode: '566' },
  { name: 'Niue', isoCode3: 'NIU', countryCode: '570' },
  { name: 'Norfolk Island', isoCode3: 'NFK', countryCode: '574' },
  { name: 'North Macedonia', isoCode3: 'MKD', countryCode: '807' },
  { name: 'Northern Mariana Islands', isoCode3: 'MNP', countryCode: '580' },
  { name: 'Norway', isoCode3: 'NOR', countryCode: '578' },
  { name: 'Oman', isoCode3: 'OMN', countryCode: '512' },
  { name: 'Pakistan', isoCode3: 'PAK', countryCode: '586' },
  { name: 'Palau', isoCode3: 'PLW', countryCode: '585' },
  { name: 'Palestine, State of', isoCode3: 'PSE', countryCode: '275' },
  { name: 'Panama', isoCode3: 'PAN', countryCode: '591' },
  { name: 'Papua New Guinea', isoCode3: 'PNG', countryCode: '598' },
  { name: 'Paraguay', isoCode3: 'PRY', countryCode: '600' },
  { name: 'Peru', isoCode3: 'PER', countryCode: '604' },
  { name: 'Philippines', isoCode3: 'PHL', countryCode: '608' },
  { name: 'Pitcairn', isoCode3: 'PCN', countryCode: '612' },
  { name: 'Poland', isoCode3: 'POL', countryCode: '616' },
  { name: 'Portugal', isoCode3: 'PRT', countryCode: '620' },
  { name: 'Puerto Rico', isoCode3: 'PRI', countryCode: '630' },
  { name: 'Qatar', isoCode3: 'QAT', countryCode: '634' },
  { name: 'Réunion', isoCode3: 'REU', countryCode: '638' },
  { name: 'Romania', isoCode3: 'ROU', countryCode: '642' },
  { name: 'Russian Federation', isoCode3: 'RUS', countryCode: '643' },
  { name: 'Rwanda', isoCode3: 'RWA', countryCode: '646' },
  { name: 'Saint Barthélemy', isoCode3: 'BLM', countryCode: '652' },
  { name: 'Saint Helena, Ascension and Tristan da Cunha', isoCode3: 'SHN', countryCode: '654' },
  { name: 'Saint Kitts and Nevis', isoCode3: 'KNA', countryCode: '659' },
  { name: 'Saint Lucia', isoCode3: 'LCA', countryCode: '662' },
  { name: 'Saint Martin (French part)', isoCode3: 'MAF', countryCode: '663' },
  { name: 'Saint Pierre and Miquelon', isoCode3: 'SPM', countryCode: '666' },
  { name: 'Saint Vincent and the Grenadines', isoCode3: 'VCT', countryCode: '670' },
  { name: 'Samoa', isoCode3: 'WSM', countryCode: '882' },
  { name: 'San Marino', isoCode3: 'SMR', countryCode: '674' },
  { name: 'Sao Tome and Principe', isoCode3: 'STP', countryCode: '678' },
  { name: 'Saudi Arabia', isoCode3: 'SAU', countryCode: '682' },
  { name: 'Senegal', isoCode3: 'SEN', countryCode: '686' },
  { name: 'Serbia', isoCode3: 'SRB', countryCode: '688' },
  { name: 'Seychelles', isoCode3: 'SYC', countryCode: '690' },
  { name: 'Sierra Leone', isoCode3: 'SLE', countryCode: '694' },
  { name: 'Singapore', isoCode3: 'SGP', countryCode: '702' },
  { name: 'Sint Maarten (Dutch part)', isoCode3: 'SXM', countryCode: '534' },
  { name: 'Slovakia', isoCode3: 'SVK', countryCode: '703' },
  { name: 'Slovenia', isoCode3: 'SVN', countryCode: '705' },
  { name: 'Solomon Islands', isoCode3: 'SLB', countryCode: '090' },
  { name: 'Somalia', isoCode3: 'SOM', countryCode: '706' },
  { name: 'South Africa', isoCode3: 'ZAF', countryCode: '710' },
  { name: 'South Georgia and the South Sandwich Islands', isoCode3: 'SGS', countryCode: '239' },
  { name: 'South Sudan', isoCode3: 'SSD', countryCode: '728' },
  { name: 'Spain', isoCode3: 'ESP', countryCode: '724' },
  { name: 'Sri Lanka', isoCode3: 'LKA', countryCode: '144' },
  { name: 'Sudan', isoCode3: 'SDN', countryCode: '729' },
  { name: 'Suriname', isoCode3: 'SUR', countryCode: '740' },
  { name: 'Svalbard and Jan Mayen', isoCode3: 'SJM', countryCode: '744' },
  { name: 'Sweden', isoCode3: 'SWE', countryCode: '752' },
  { name: 'Switzerland', isoCode3: 'CHE', countryCode: '756' },
  { name: 'Syrian Arab Republic', isoCode3: 'SYR', countryCode: '760' },
  { name: 'Taiwan, Province of China', isoCode3: 'TWN', countryCode: '158' },
  { name: 'Tajikistan', isoCode3: 'TJK', countryCode: '762' },
  { name: 'Tanzania, United Republic of', isoCode3: 'TZA', countryCode: '834' },
  { name: 'Thailand', isoCode3: 'THA', countryCode: '764' },
  { name: 'Timor-Leste', isoCode3: 'TLS', countryCode: '626' },
  { name: 'Togo', isoCode3: 'TGO', countryCode: '768' },
  { name: 'Tokelau', isoCode3: 'TKL', countryCode: '772' },
  { name: 'Tonga', isoCode3: 'TON', countryCode: '776' },
  { name: 'Trinidad and Tobago', isoCode3: 'TTO', countryCode: '780' },
  { name: 'Tunisia', isoCode3: 'TUN', countryCode: '788' },
  { name: 'Turkey', isoCode3: 'TUR', countryCode: '792' },
  { name: 'Turkmenistan', isoCode3: 'TKM', countryCode: '795' },
  { name: 'Turks and Caicos Islands', isoCode3: 'TCA', countryCode: '796' },
  { name: 'Tuvalu', isoCode3: 'TUV', countryCode: '798' },
  { name: 'Uganda', isoCode3: 'UGA', countryCode: '800' },
  { name: 'Ukraine', isoCode3: 'UKR', countryCode: '804' },
  { name: 'United Arab Emirates', isoCode3: 'ARE', countryCode: '784' },
  { name: 'United Kingdom of Great Britain and Northern Ireland', isoCode3: 'GBR', countryCode: '826' },
  { name: 'United States of America', isoCode3: 'USA', countryCode: '840' },
  { name: 'United States Minor Outlying Islands', isoCode3: 'UMI', countryCode: '581' },
  { name: 'Uruguay', isoCode3: 'URY', countryCode: '858' },
  { name: 'Uzbekistan', isoCode3: 'UZB', countryCode: '860' },
  { name: 'Vanuatu', isoCode3: 'VUT', countryCode: '548' },
  { name: 'Venezuela (Bolivarian Republic of)', isoCode3: 'VEN', countryCode: '862' },
  { name: 'Viet Nam', isoCode3: 'VNM', countryCode: '704' },
  { name: 'Virgin Islands (British)', isoCode3: 'VGB', countryCode: '092' },
  { name: 'Virgin Islands (U.S.)', isoCode3: 'VIR', countryCode: '850' },
  { name: 'Wallis and Futuna', isoCode3: 'WLF', countryCode: '876' },
  { name: 'Western Sahara', isoCode3: 'ESH', countryCode: '732' },
  { name: 'Yemen', isoCode3: 'YEM', countryCode: '887' },
  { name: 'Zambia', isoCode3: 'ZMB', countryCode: '894' },
  { name: 'Zimbabwe', isoCode3: 'ZWE', countryCode: '716' },
];

export const countrySelector = countries?.map<SelectType>(({ name }) => ({
  name,
  value: name,
}));
