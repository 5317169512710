import { FC, Fragment, JSX } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
//components
import { useNavigate } from 'react-router-dom';
import ExcipientLabelTable from './ExcipientLabelTable';
// constant, styles, reducer, graphql
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { ADD_EXCIPIENT_LABEL_ROUTE, ADD_NEW_TEXT, EXCIPIENT_LABEL_TEXT } from 'constants/index';

const ExcipientLabel: FC = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{EXCIPIENT_LABEL_TEXT}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(ADD_EXCIPIENT_LABEL_ROUTE)}
          startIcon={<AddRoundedIcon />}>
          {ADD_NEW_TEXT}
        </Button>
      </Box>
      <ExcipientLabelTable />
    </Fragment>
  );
};

export default ExcipientLabel;
