import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
//components
import FormulationSyrupTypeTable from './FormulationSyrupTypeTable';
// constant, styles, reducer, graphql
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  ADD_NEW_TEXT,
  FORMULATION_SYRUP_TYPE_TEXT,
  ADD_PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE,
} from 'constants/index';

const FormulationSyrupType: FC = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{FORMULATION_SYRUP_TYPE_TEXT}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(ADD_PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE)}>
          <AddRoundedIcon />
          {ADD_NEW_TEXT}
        </Button>
      </Box>
      <FormulationSyrupTypeTable />
    </Fragment>
  );
};

export default FormulationSyrupType;
