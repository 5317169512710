import { SelectType } from 'interfaces';

interface State {
  name: string;
  shortName: string;
}

const stateData: State[] = [
  {
    name: 'Badakhshan',
    shortName: 'BDS',
  },
  {
    name: 'Badghis',
    shortName: 'BDG',
  },
  {
    name: 'Baghlan',
    shortName: 'BGL',
  },
  {
    name: 'Balkh',
    shortName: 'BAL',
  },
  {
    name: 'Bamyan',
    shortName: 'BAM',
  },
  {
    name: 'Daykundi',
    shortName: 'DAY',
  },
  {
    name: 'Farah',
    shortName: 'FRA',
  },
  {
    name: 'Faryab',
    shortName: 'FYB',
  },
  {
    name: 'Ghazni',
    shortName: 'GHA',
  },
  {
    name: 'Ghōr',
    shortName: 'GHO',
  },
  {
    name: 'Helmand',
    shortName: 'HEL',
  },
  {
    name: 'Herat',
    shortName: 'HER',
  },
  {
    name: 'Jowzjan',
    shortName: 'JOW',
  },
  {
    name: 'Kabul',
    shortName: 'KAB',
  },
  {
    name: 'Kandahar',
    shortName: 'KAN',
  },
  {
    name: 'Kapisa',
    shortName: 'KAP',
  },
  {
    name: 'Khost',
    shortName: 'KHO',
  },
  {
    name: 'Kunar',
    shortName: 'KNR',
  },
  {
    name: 'Kunduz Province',
    shortName: 'KDZ',
  },
  {
    name: 'Laghman',
    shortName: 'LAG',
  },
  {
    name: 'Logar',
    shortName: 'LOG',
  },
  {
    name: 'Nangarhar',
    shortName: 'NAN',
  },
  {
    name: 'Nimruz',
    shortName: 'NIM',
  },
  {
    name: 'Nuristan',
    shortName: 'NUR',
  },
  {
    name: 'Paktia',
    shortName: 'PIA',
  },
  {
    name: 'Paktika',
    shortName: 'PKA',
  },
  {
    name: 'Panjshir',
    shortName: 'PAN',
  },
  {
    name: 'Parwan',
    shortName: 'PAR',
  },
  {
    name: 'Samangan',
    shortName: 'SAM',
  },
  {
    name: 'Sar-e Pol',
    shortName: 'SAR',
  },
  {
    name: 'Takhar',
    shortName: 'TAK',
  },
  {
    name: 'Urozgan',
    shortName: 'URU',
  },
  {
    name: 'Zabul',
    shortName: 'ZAB',
  },
  {
    name: 'Berat County',
    shortName: '01',
  },
  {
    name: 'Berat District',
    shortName: 'BR',
  },
  {
    name: 'Bulqizë District',
    shortName: 'BU',
  },
  {
    name: 'Delvinë District',
    shortName: 'DL',
  },
  {
    name: 'Devoll District',
    shortName: 'DV',
  },
  {
    name: 'Dibër County',
    shortName: '09',
  },
  {
    name: 'Dibër District',
    shortName: 'DI',
  },
  {
    name: 'Durrës County',
    shortName: '02',
  },
  {
    name: 'Durrës District',
    shortName: 'DR',
  },
  {
    name: 'Elbasan County',
    shortName: '03',
  },
  {
    name: 'Fier County',
    shortName: '04',
  },
  {
    name: 'Fier District',
    shortName: 'FR',
  },
  {
    name: 'Gjirokastër County',
    shortName: '05',
  },
  {
    name: 'Gjirokastër District',
    shortName: 'GJ',
  },
  {
    name: 'Gramsh District',
    shortName: 'GR',
  },
  {
    name: 'Has District',
    shortName: 'HA',
  },
  {
    name: 'Kavajë District',
    shortName: 'KA',
  },
  {
    name: 'Kolonjë District',
    shortName: 'ER',
  },
  {
    name: 'Korçë County',
    shortName: '06',
  },
  {
    name: 'Korçë District',
    shortName: 'KO',
  },
  {
    name: 'Krujë District',
    shortName: 'KR',
  },
  {
    name: 'Kuçovë District',
    shortName: 'KC',
  },
  {
    name: 'Kukës County',
    shortName: '07',
  },
  {
    name: 'Kukës District',
    shortName: 'KU',
  },
  {
    name: 'Kurbin District',
    shortName: 'KB',
  },
  {
    name: 'Lezhë County',
    shortName: '08',
  },
  {
    name: 'Lezhë District',
    shortName: 'LE',
  },
  {
    name: 'Librazhd District',
    shortName: 'LB',
  },
  {
    name: 'Lushnjë District',
    shortName: 'LU',
  },
  {
    name: 'Malësi e Madhe District',
    shortName: 'MM',
  },
  {
    name: 'Mallakastër District',
    shortName: 'MK',
  },
  {
    name: 'Mat District',
    shortName: 'MT',
  },
  {
    name: 'Mirditë District',
    shortName: 'MR',
  },
  {
    name: 'Peqin District',
    shortName: 'PQ',
  },
  {
    name: 'Përmet District',
    shortName: 'PR',
  },
  {
    name: 'Pogradec District',
    shortName: 'PG',
  },
  {
    name: 'Pukë District',
    shortName: 'PU',
  },
  {
    name: 'Sarandë District',
    shortName: 'SR',
  },
  {
    name: 'Shkodër County',
    shortName: '10',
  },
  {
    name: 'Shkodër District',
    shortName: 'SH',
  },
  {
    name: 'Skrapar District',
    shortName: 'SK',
  },
  {
    name: 'Tepelenë District',
    shortName: 'TE',
  },
  {
    name: 'Tirana County',
    shortName: '11',
  },
  {
    name: 'Tirana District',
    shortName: 'TR',
  },
  {
    name: 'Tropojë District',
    shortName: 'TP',
  },
  {
    name: 'Vlorë County',
    shortName: '12',
  },
  {
    name: 'Vlorë District',
    shortName: 'VL',
  },
  {
    name: 'Adrar',
    shortName: '01',
  },
  {
    name: 'Aïn Defla',
    shortName: '44',
  },
  {
    name: 'Aïn Témouchent',
    shortName: '46',
  },
  {
    name: 'Algiers',
    shortName: '16',
  },
  {
    name: 'Annaba',
    shortName: '23',
  },
  {
    name: 'Batna',
    shortName: '05',
  },
  {
    name: 'Béchar',
    shortName: '08',
  },
  {
    name: 'Béjaïa',
    shortName: '06',
  },
  {
    name: 'Béni Abbès',
    shortName: '53',
  },
  {
    name: 'Biskra',
    shortName: '07',
  },
  {
    name: 'Blida',
    shortName: '09',
  },
  {
    name: 'Bordj Baji Mokhtar',
    shortName: '52',
  },
  {
    name: 'Bordj Bou Arréridj',
    shortName: '34',
  },
  {
    name: 'Bouïra',
    shortName: '10',
  },
  {
    name: 'Boumerdès',
    shortName: '35',
  },
  {
    name: 'Chlef',
    shortName: '02',
  },
  {
    name: 'Constantine',
    shortName: '25',
  },
  {
    name: 'Djanet',
    shortName: '56',
  },
  {
    name: 'Djelfa',
    shortName: '17',
  },
  {
    name: 'El Bayadh',
    shortName: '32',
  },
  {
    name: "El M'ghair",
    shortName: '49',
  },
  {
    name: 'El Menia',
    shortName: '50',
  },
  {
    name: 'El Oued',
    shortName: '39',
  },
  {
    name: 'El Tarf',
    shortName: '36',
  },
  {
    name: 'Ghardaïa',
    shortName: '47',
  },
  {
    name: 'Guelma',
    shortName: '24',
  },
  {
    name: 'Illizi',
    shortName: '33',
  },
  {
    name: 'In Guezzam',
    shortName: '58',
  },
  {
    name: 'In Salah',
    shortName: '57',
  },
  {
    name: 'Jijel',
    shortName: '18',
  },
  {
    name: 'Khenchela',
    shortName: '40',
  },
  {
    name: 'Laghouat',
    shortName: '03',
  },
  {
    name: "M'Sila",
    shortName: '28',
  },
  {
    name: 'Mascara',
    shortName: '29',
  },
  {
    name: 'Médéa',
    shortName: '26',
  },
  {
    name: 'Mila',
    shortName: '43',
  },
  {
    name: 'Mostaganem',
    shortName: '27',
  },
  {
    name: 'Naama',
    shortName: '45',
  },
  {
    name: 'Oran',
    shortName: '31',
  },
  {
    name: 'Ouargla',
    shortName: '30',
  },
  {
    name: 'Ouled Djellal',
    shortName: '51',
  },
  {
    name: 'Oum El Bouaghi',
    shortName: '04',
  },
  {
    name: 'Relizane',
    shortName: '48',
  },
  {
    name: 'Saïda',
    shortName: '20',
  },
  {
    name: 'Sétif',
    shortName: '19',
  },
  {
    name: 'Sidi Bel Abbès',
    shortName: '22',
  },
  {
    name: 'Skikda',
    shortName: '21',
  },
  {
    name: 'Souk Ahras',
    shortName: '41',
  },
  {
    name: 'Tamanghasset',
    shortName: '11',
  },
  {
    name: 'Tébessa',
    shortName: '12',
  },
  {
    name: 'Tiaret',
    shortName: '14',
  },
  {
    name: 'Timimoun',
    shortName: '54',
  },
  {
    name: 'Tindouf',
    shortName: '37',
  },
  {
    name: 'Tipasa',
    shortName: '42',
  },
  {
    name: 'Tissemsilt',
    shortName: '38',
  },
  {
    name: 'Tizi Ouzou',
    shortName: '15',
  },
  {
    name: 'Tlemcen',
    shortName: '13',
  },
  {
    name: 'Touggourt',
    shortName: '55',
  },
  {
    name: 'Andorra la Vella',
    shortName: '07',
  },
  {
    name: 'Canillo',
    shortName: '02',
  },
  {
    name: 'Encamp',
    shortName: '03',
  },
  {
    name: 'Escaldes-Engordany',
    shortName: '08',
  },
  {
    name: 'La Massana',
    shortName: '04',
  },
  {
    name: 'Ordino',
    shortName: '05',
  },
  {
    name: 'Sant Julià de Lòria',
    shortName: '06',
  },

  {
    name: 'Bengo Province',
    shortName: 'BGO',
  },
  {
    name: 'Benguela Province',
    shortName: 'BGU',
  },
  {
    name: 'Bié Province',
    shortName: 'BIE',
  },
  {
    name: 'Cabinda Province',
    shortName: 'CAB',
  },
  {
    name: 'Cuando Cubango Province',
    shortName: 'CCU',
  },
  {
    name: 'Cuanza Norte Province',
    shortName: 'CNO',
  },
  {
    name: 'Cuanza Sul',
    shortName: 'CUS',
  },
  {
    name: 'Cunene Province',
    shortName: 'CNN',
  },
  {
    name: 'Huambo Province',
    shortName: 'HUA',
  },
  {
    name: 'Huíla Province',
    shortName: 'HUI',
  },
  {
    name: 'Luanda Province',
    shortName: 'LUA',
  },
  {
    name: 'Lunda Norte Province',
    shortName: 'LNO',
  },
  {
    name: 'Lunda Sul Province',
    shortName: 'LSU',
  },
  {
    name: 'Malanje Province',
    shortName: 'MAL',
  },
  {
    name: 'Moxico Province',
    shortName: 'MOX',
  },
  {
    name: 'Uíge Province',
    shortName: 'UIG',
  },
  {
    name: 'Zaire Province',
    shortName: 'ZAI',
  },

  {
    name: 'Barbuda',
    shortName: '10',
  },
  {
    name: 'Redonda',
    shortName: '11',
  },
  {
    name: 'Saint George Parish',
    shortName: '03',
  },
  {
    name: 'Saint John Parish',
    shortName: '04',
  },
  {
    name: 'Saint Mary Parish',
    shortName: '05',
  },
  {
    name: 'Saint Paul Parish',
    shortName: '06',
  },
  {
    name: 'Saint Peter Parish',
    shortName: '07',
  },
  {
    name: 'Saint Philip Parish',
    shortName: '08',
  },

  {
    name: 'Buenos Aires',
    shortName: 'B',
  },
  {
    name: 'Catamarca',
    shortName: 'K',
  },
  {
    name: 'Chaco',
    shortName: 'H',
  },
  {
    name: 'Chubut',
    shortName: 'U',
  },
  {
    name: 'Ciudad Autónoma de Buenos Aires',
    shortName: 'C',
  },
  {
    name: 'Córdoba',
    shortName: 'X',
  },
  {
    name: 'Corrientes',
    shortName: 'W',
  },
  {
    name: 'Entre Ríos',
    shortName: 'E',
  },
  {
    name: 'Formosa',
    shortName: 'P',
  },
  {
    name: 'Jujuy',
    shortName: 'Y',
  },
  {
    name: 'La Pampa',
    shortName: 'L',
  },
  {
    name: 'La Rioja',
    shortName: 'F',
  },
  {
    name: 'Mendoza',
    shortName: 'M',
  },
  {
    name: 'Misiones',
    shortName: 'N',
  },
  {
    name: 'Neuquén',
    shortName: 'Q',
  },
  {
    name: 'Río Negro',
    shortName: 'R',
  },
  {
    name: 'Salta',
    shortName: 'A',
  },
  {
    name: 'San Juan',
    shortName: 'J',
  },
  {
    name: 'San Luis',
    shortName: 'D',
  },
  {
    name: 'Santa Cruz',
    shortName: 'Z',
  },
  {
    name: 'Santa Fe',
    shortName: 'S',
  },
  {
    name: 'Santiago del Estero',
    shortName: 'G',
  },
  {
    name: 'Tierra del Fuego',
    shortName: 'V',
  },
  {
    name: 'Tucumán',
    shortName: 'T',
  },

  {
    name: 'Aragatsotn Region',
    shortName: 'AG',
  },
  {
    name: 'Ararat Province',
    shortName: 'AR',
  },
  {
    name: 'Armavir Region',
    shortName: 'AV',
  },
  {
    name: 'Gegharkunik Province',
    shortName: 'GR',
  },
  {
    name: 'Kotayk Region',
    shortName: 'KT',
  },
  {
    name: 'Lori Region',
    shortName: 'LO',
  },
  {
    name: 'Shirak Region',
    shortName: 'SH',
  },
  {
    name: 'Syunik Province',
    shortName: 'SU',
  },
  {
    name: 'Tavush Region',
    shortName: 'TV',
  },
  {
    name: 'Vayots Dzor Region',
    shortName: 'VD',
  },
  {
    name: 'Yerevan',
    shortName: 'ER',
  },
  {
    name: 'Australian Capital Territory',
    shortName: 'ACT',
  },
  {
    name: 'New South Wales',
    shortName: 'NSW',
  },
  {
    name: 'Northern Territory',
    shortName: 'NT',
  },
  {
    name: 'Queensland',
    shortName: 'QLD',
  },
  {
    name: 'South Australia',
    shortName: 'SA',
  },
  {
    name: 'Tasmania',
    shortName: 'TAS',
  },
  {
    name: 'Victoria',
    shortName: 'VIC',
  },
  {
    name: 'Western Australia',
    shortName: 'WA',
  },
  {
    name: 'Burgenland',
    shortName: '1',
  },
  {
    name: 'Carinthia',
    shortName: '2',
  },
  {
    name: 'Lower Austria',
    shortName: '3',
  },
  {
    name: 'Salzburg',
    shortName: '5',
  },
  {
    name: 'Styria',
    shortName: '6',
  },
  {
    name: 'Tyrol',
    shortName: '7',
  },
  {
    name: 'Upper Austria',
    shortName: '4',
  },
  {
    name: 'Vienna',
    shortName: '9',
  },
  {
    name: 'Vorarlberg',
    shortName: '8',
  },
  {
    name: 'Absheron District',
    shortName: 'ABS',
  },
  {
    name: 'Agdam District',
    shortName: 'AGM',
  },
  {
    name: 'Agdash District',
    shortName: 'AGS',
  },
  {
    name: 'Aghjabadi District',
    shortName: 'AGC',
  },
  {
    name: 'Agstafa District',
    shortName: 'AGA',
  },
  {
    name: 'Agsu District',
    shortName: 'AGU',
  },
  {
    name: 'Astara District',
    shortName: 'AST',
  },
  {
    name: 'Babek District',
    shortName: 'BAB',
  },
  {
    name: 'Baku',
    shortName: 'BA',
  },
  {
    name: 'Balakan District',
    shortName: 'BAL',
  },
  {
    name: 'Barda District',
    shortName: 'BAR',
  },
  {
    name: 'Beylagan District',
    shortName: 'BEY',
  },
  {
    name: 'Bilasuvar District',
    shortName: 'BIL',
  },
  {
    name: 'Dashkasan District',
    shortName: 'DAS',
  },
  {
    name: 'Fizuli District',
    shortName: 'FUZ',
  },
  {
    name: 'Ganja',
    shortName: 'GA',
  },
  {
    name: 'Gədəbəy',
    shortName: 'GAD',
  },
  {
    name: 'Gobustan District',
    shortName: 'QOB',
  },
  {
    name: 'Goranboy District',
    shortName: 'GOR',
  },
  {
    name: 'Goychay',
    shortName: 'GOY',
  },
  {
    name: 'Goygol District',
    shortName: 'GYG',
  },
  {
    name: 'Hajigabul District',
    shortName: 'HAC',
  },
  {
    name: 'Imishli District',
    shortName: 'IMI',
  },
  {
    name: 'Ismailli District',
    shortName: 'ISM',
  },
  {
    name: 'Jabrayil District',
    shortName: 'CAB',
  },
  {
    name: 'Jalilabad District',
    shortName: 'CAL',
  },
  {
    name: 'Julfa District',
    shortName: 'CUL',
  },
  {
    name: 'Kalbajar District',
    shortName: 'KAL',
  },
  {
    name: 'Kangarli District',
    shortName: 'KAN',
  },
  {
    name: 'Khachmaz District',
    shortName: 'XAC',
  },
  {
    name: 'Khizi District',
    shortName: 'XIZ',
  },
  {
    name: 'Khojali District',
    shortName: 'XCI',
  },
  {
    name: 'Kurdamir District',
    shortName: 'KUR',
  },
  {
    name: 'Lachin District',
    shortName: 'LAC',
  },
  {
    name: 'Lankaran',
    shortName: 'LAN',
  },
  {
    name: 'Lankaran District',
    shortName: 'LA',
  },
  {
    name: 'Lerik District',
    shortName: 'LER',
  },
  {
    name: 'Martuni',
    shortName: 'XVD',
  },
  {
    name: 'Masally District',
    shortName: 'MAS',
  },
  {
    name: 'Mingachevir',
    shortName: 'MI',
  },
  {
    name: 'Nakhchivan Autonomous Republic',
    shortName: 'NX',
  },
  {
    name: 'Neftchala District',
    shortName: 'NEF',
  },
  {
    name: 'Oghuz District',
    shortName: 'OGU',
  },
  {
    name: 'Ordubad District',
    shortName: 'ORD',
  },
  {
    name: 'Qabala District',
    shortName: 'QAB',
  },
  {
    name: 'Qakh District',
    shortName: 'QAX',
  },
  {
    name: 'Qazakh District',
    shortName: 'QAZ',
  },
  {
    name: 'Quba District',
    shortName: 'QBA',
  },
  {
    name: 'Qubadli District',
    shortName: 'QBI',
  },
  {
    name: 'Qusar District',
    shortName: 'QUS',
  },
  {
    name: 'Saatly District',
    shortName: 'SAT',
  },
  {
    name: 'Sabirabad District',
    shortName: 'SAB',
  },
  {
    name: 'Sadarak District',
    shortName: 'SAD',
  },
  {
    name: 'Salyan District',
    shortName: 'SAL',
  },
  {
    name: 'Samukh District',
    shortName: 'SMX',
  },
  {
    name: 'Shabran District',
    shortName: 'SBN',
  },
  {
    name: 'Shahbuz District',
    shortName: 'SAH',
  },
  {
    name: 'Shaki',
    shortName: 'SA',
  },
  {
    name: 'Shaki District',
    shortName: 'SAK',
  },
  {
    name: 'Shamakhi District',
    shortName: 'SMI',
  },
  {
    name: 'Shamkir District',
    shortName: 'SKR',
  },
  {
    name: 'Sharur District',
    shortName: 'SAR',
  },
  {
    name: 'Shirvan',
    shortName: 'SR',
  },
  {
    name: 'Shusha District',
    shortName: 'SUS',
  },
  {
    name: 'Siazan District',
    shortName: 'SIY',
  },
  {
    name: 'Sumqayit',
    shortName: 'SM',
  },
  {
    name: 'Tartar District',
    shortName: 'TAR',
  },
  {
    name: 'Tovuz District',
    shortName: 'TOV',
  },
  {
    name: 'Ujar District',
    shortName: 'UCA',
  },
  {
    name: 'Yardymli District',
    shortName: 'YAR',
  },
  {
    name: 'Yevlakh',
    shortName: 'YE',
  },
  {
    name: 'Yevlakh District',
    shortName: 'YEV',
  },
  {
    name: 'Zangilan District',
    shortName: 'ZAN',
  },
  {
    name: 'Zaqatala District',
    shortName: 'ZAQ',
  },
  {
    name: 'Zardab District',
    shortName: 'ZAR',
  },
  {
    name: 'Capital',
    shortName: '13',
  },
  {
    name: 'Central',
    shortName: '16',
  },
  {
    name: 'Muharraq',
    shortName: '15',
  },
  {
    name: 'Northern',
    shortName: '17',
  },
  {
    name: 'Southern',
    shortName: '14',
  },
  {
    name: 'Bagerhat District',
    shortName: '05',
  },
  {
    name: 'Bahadia',
    shortName: '33',
  },
  {
    name: 'Bandarban District',
    shortName: '01',
  },
  {
    name: 'Barguna District',
    shortName: '02',
  },
  {
    name: 'Barisal District',
    shortName: '06',
  },
  {
    name: 'Barisal Division',
    shortName: 'A',
  },
  {
    name: 'Bhola District',
    shortName: '07',
  },
  {
    name: 'Bogra District',
    shortName: '03',
  },
  {
    name: 'Brahmanbaria District',
    shortName: '04',
  },
  {
    name: 'Chandpur District',
    shortName: '09',
  },
  {
    name: 'Chapai Nawabganj District',
    shortName: '45',
  },
  {
    name: 'Chittagong District',
    shortName: '10',
  },
  {
    name: 'Chittagong Division',
    shortName: 'B',
  },
  {
    name: 'Chuadanga District',
    shortName: '12',
  },
  {
    name: 'Comilla District',
    shortName: '08',
  },
  {
    name: "Cox's Bazar District",
    shortName: '11',
  },
  {
    name: 'Dhaka District',
    shortName: '13',
  },
  {
    name: 'Dhaka Division',
    shortName: 'C',
  },
  {
    name: 'Dinajpur District',
    shortName: '14',
  },
  {
    name: 'Faridpur District',
    shortName: '15',
  },
  {
    name: 'Feni District',
    shortName: '16',
  },
  {
    name: 'Gaibandha District',
    shortName: '19',
  },
  {
    name: 'Gazipur District',
    shortName: '18',
  },
  {
    name: 'Gopalganj District',
    shortName: '17',
  },
  {
    name: 'Habiganj District',
    shortName: '20',
  },
  {
    name: 'Jamalpur District',
    shortName: '21',
  },
  {
    name: 'Jessore District',
    shortName: '22',
  },
  {
    name: 'Jhalokati District',
    shortName: '25',
  },
  {
    name: 'Jhenaidah District',
    shortName: '23',
  },
  {
    name: 'Joypurhat District',
    shortName: '24',
  },
  {
    name: 'Khagrachari District',
    shortName: '29',
  },
  {
    name: 'Khulna District',
    shortName: '27',
  },
  {
    name: 'Khulna Division',
    shortName: 'D',
  },
  {
    name: 'Kishoreganj District',
    shortName: '26',
  },
  {
    name: 'Kurigram District',
    shortName: '28',
  },
  {
    name: 'Kushtia District',
    shortName: '30',
  },
  {
    name: 'Lakshmipur District',
    shortName: '31',
  },
  {
    name: 'Lalmonirhat District',
    shortName: '32',
  },
  {
    name: 'Madaripur District',
    shortName: '36',
  },
  {
    name: 'Meherpur District',
    shortName: '39',
  },
  {
    name: 'Moulvibazar District',
    shortName: '38',
  },
  {
    name: 'Munshiganj District',
    shortName: '35',
  },
  {
    name: 'Mymensingh District',
    shortName: '34',
  },
  {
    name: 'Mymensingh Division',
    shortName: 'H',
  },
  {
    name: 'Naogaon District',
    shortName: '48',
  },
  {
    name: 'Narail District',
    shortName: '43',
  },
  {
    name: 'Narayanganj District',
    shortName: '40',
  },
  {
    name: 'Natore District',
    shortName: '44',
  },
  {
    name: 'Netrokona District',
    shortName: '41',
  },
  {
    name: 'Nilphamari District',
    shortName: '46',
  },
  {
    name: 'Noakhali District',
    shortName: '47',
  },
  {
    name: 'Pabna District',
    shortName: '49',
  },
  {
    name: 'Panchagarh District',
    shortName: '52',
  },
  {
    name: 'Patuakhali District',
    shortName: '51',
  },
  {
    name: 'Pirojpur District',
    shortName: '50',
  },
  {
    name: 'Rajbari District',
    shortName: '53',
  },
  {
    name: 'Rajshahi District',
    shortName: '54',
  },
  {
    name: 'Rajshahi Division',
    shortName: 'E',
  },
  {
    name: 'Rangamati Hill District',
    shortName: '56',
  },
  {
    name: 'Rangpur District',
    shortName: '55',
  },
  {
    name: 'Rangpur Division',
    shortName: 'F',
  },
  {
    name: 'Satkhira District',
    shortName: '58',
  },
  {
    name: 'Shariatpur District',
    shortName: '62',
  },
  {
    name: 'Sherpur District',
    shortName: '57',
  },
  {
    name: 'Sirajganj District',
    shortName: '59',
  },
  {
    name: 'Sunamganj District',
    shortName: '61',
  },
  {
    name: 'Sylhet District',
    shortName: '60',
  },
  {
    name: 'Sylhet Division',
    shortName: 'G',
  },
  {
    name: 'Tangail District',
    shortName: '63',
  },
  {
    name: 'Thakurgaon District',
    shortName: '64',
  },
  {
    name: 'Christ Church',
    shortName: '01',
  },
  {
    name: 'Saint Andrew',
    shortName: '02',
  },
  {
    name: 'Saint George',
    shortName: '03',
  },
  {
    name: 'Saint James',
    shortName: '04',
  },
  {
    name: 'Saint John',
    shortName: '05',
  },
  {
    name: 'Saint Joseph',
    shortName: '06',
  },
  {
    name: 'Saint Lucy',
    shortName: '07',
  },
  {
    name: 'Saint Michael',
    shortName: '08',
  },
  {
    name: 'Saint Peter',
    shortName: '09',
  },
  {
    name: 'Saint Philip',
    shortName: '10',
  },
  {
    name: 'Saint Thomas',
    shortName: '11',
  },
  {
    name: 'Brest Region',
    shortName: 'BR',
  },
  {
    name: 'Gomel Region',
    shortName: 'HO',
  },
  {
    name: 'Grodno Region',
    shortName: 'HR',
  },
  {
    name: 'Minsk',
    shortName: 'HM',
  },
  {
    name: 'Minsk Region',
    shortName: 'MI',
  },
  {
    name: 'Mogilev Region',
    shortName: 'MA',
  },
  {
    name: 'Vitebsk Region',
    shortName: 'VI',
  },
  {
    name: 'Antwerp',
    shortName: 'VAN',
  },
  {
    name: 'Brussels-Capital Region',
    shortName: 'BRU',
  },
  {
    name: 'East Flanders',
    shortName: 'VOV',
  },
  {
    name: 'Flanders',
    shortName: 'VLG',
  },
  {
    name: 'Flemish Brabant',
    shortName: 'VBR',
  },
  {
    name: 'Hainaut',
    shortName: 'WHT',
  },
  {
    name: 'Liège',
    shortName: 'WLG',
  },
  {
    name: 'Limburg',
    shortName: 'VLI',
  },
  {
    name: 'Luxembourg',
    shortName: 'WLX',
  },
  {
    name: 'Namur',
    shortName: 'WNA',
  },
  {
    name: 'Wallonia',
    shortName: 'WAL',
  },
  {
    name: 'Walloon Brabant',
    shortName: 'WBR',
  },
  {
    name: 'West Flanders',
    shortName: 'VWV',
  },
  {
    name: 'Belize District',
    shortName: 'BZ',
  },
  {
    name: 'Cayo District',
    shortName: 'CY',
  },
  {
    name: 'Corozal District',
    shortName: 'CZL',
  },
  {
    name: 'Orange Walk District',
    shortName: 'OW',
  },
  {
    name: 'Stann Creek District',
    shortName: 'SC',
  },
  {
    name: 'Toledo District',
    shortName: 'TOL',
  },
  {
    name: 'Alibori Department',
    shortName: 'AL',
  },
  {
    name: 'Atakora Department',
    shortName: 'AK',
  },
  {
    name: 'Atlantique Department',
    shortName: 'AQ',
  },
  {
    name: 'Borgou Department',
    shortName: 'BO',
  },
  {
    name: 'Collines Department',
    shortName: 'CO',
  },
  {
    name: 'Donga Department',
    shortName: 'DO',
  },
  {
    name: 'Kouffo Department',
    shortName: 'KO',
  },
  {
    name: 'Littoral Department',
    shortName: 'LI',
  },
  {
    name: 'Mono Department',
    shortName: 'MO',
  },
  {
    name: 'Ouémé Department',
    shortName: 'OU',
  },
  {
    name: 'Plateau Department',
    shortName: 'PL',
  },
  {
    name: 'Zou Department',
    shortName: 'ZO',
  },
  {
    name: 'Devonshire Parish',
    shortName: 'DEV',
  },
  {
    name: 'Hamilton Parish',
    shortName: 'HA',
  },
  {
    name: 'Paget Parish',
    shortName: 'PAG',
  },
  {
    name: 'Pembroke Parish',
    shortName: 'PEM',
  },
  {
    name: "Saint George's Parish",
    shortName: 'SGE',
  },
  {
    name: 'Sandys Parish',
    shortName: 'SAN',
  },
  {
    name: "Smith's Parish,",
    shortName: 'SMI',
  },
  {
    name: 'Southampton Parish',
    shortName: 'SOU',
  },
  {
    name: 'Warwick Parish',
    shortName: 'WAR',
  },
  {
    name: 'Bumthang District',
    shortName: '33',
  },
  {
    name: 'Chukha District',
    shortName: '12',
  },
  {
    name: 'Dagana District',
    shortName: '22',
  },
  {
    name: 'Gasa District',
    shortName: 'GA',
  },
  {
    name: 'Haa District',
    shortName: '13',
  },
  {
    name: 'Lhuntse District',
    shortName: '44',
  },
  {
    name: 'Mongar District',
    shortName: '42',
  },
  {
    name: 'Paro District',
    shortName: '11',
  },
  {
    name: 'Pemagatshel District',
    shortName: '43',
  },
  {
    name: 'Punakha District',
    shortName: '23',
  },
  {
    name: 'Samdrup Jongkhar District',
    shortName: '45',
  },
  {
    name: 'Samtse District',
    shortName: '14',
  },
  {
    name: 'Sarpang District',
    shortName: '31',
  },
  {
    name: 'Thimphu District',
    shortName: '15',
  },
  {
    name: 'Trashigang District',
    shortName: '41',
  },
  {
    name: 'Trongsa District',
    shortName: '32',
  },
  {
    name: 'Tsirang District',
    shortName: '21',
  },
  {
    name: 'Wangdue Phodrang District',
    shortName: '24',
  },
  {
    name: 'Zhemgang District',
    shortName: '34',
  },
  {
    name: 'Beni Department',
    shortName: 'B',
  },
  {
    name: 'Chuquisaca Department',
    shortName: 'H',
  },
  {
    name: 'Cochabamba Department',
    shortName: 'C',
  },
  {
    name: 'La Paz Department',
    shortName: 'L',
  },
  {
    name: 'Oruro Department',
    shortName: 'O',
  },
  {
    name: 'Pando Department',
    shortName: 'N',
  },
  {
    name: 'Potosí Department',
    shortName: 'P',
  },
  {
    name: 'Santa Cruz Department',
    shortName: 'S',
  },
  {
    name: 'Tarija Department',
    shortName: 'T',
  },
  {
    name: 'Bonaire',
    shortName: 'BQ1',
  },
  {
    name: 'Saba',
    shortName: 'BQ2',
  },
  {
    name: 'Sint Eustatius',
    shortName: 'BQ3',
  },
  {
    name: 'Bosnian Podrinje Canton',
    shortName: '05',
  },
  {
    name: 'Brčko District',
    shortName: 'BRC',
  },
  {
    name: 'Canton 10',
    shortName: '10',
  },
  {
    name: 'Central Bosnia Canton',
    shortName: '06',
  },
  {
    name: 'Federation of Bosnia and Herzegovina',
    shortName: 'BIH',
  },
  {
    name: 'Herzegovina-Neretva Canton',
    shortName: '07',
  },
  {
    name: 'Posavina Canton',
    shortName: '02',
  },
  {
    name: 'Republika Srpska',
    shortName: 'SRP',
  },
  {
    name: 'Sarajevo Canton',
    shortName: '09',
  },
  {
    name: 'Tuzla Canton',
    shortName: '03',
  },
  {
    name: 'Una-Sana Canton',
    shortName: '01',
  },
  {
    name: 'West Herzegovina Canton',
    shortName: '08',
  },
  {
    name: 'Zenica-Doboj Canton',
    shortName: '04',
  },
  {
    name: 'Central District',
    shortName: 'CE',
  },
  {
    name: 'Ghanzi District',
    shortName: 'GH',
  },
  {
    name: 'Kgalagadi District',
    shortName: 'KG',
  },
  {
    name: 'Kgatleng District',
    shortName: 'KL',
  },
  {
    name: 'Kweneng District',
    shortName: 'KW',
  },
  {
    name: 'Ngamiland',
    shortName: 'NG',
  },
  {
    name: 'North-East District',
    shortName: 'NE',
  },
  {
    name: 'North-West District',
    shortName: 'NW',
  },
  {
    name: 'South-East District',
    shortName: 'SE',
  },
  {
    name: 'Southern District',
    shortName: 'SO',
  },
  {
    name: 'Acre',
    shortName: 'AC',
  },
  {
    name: 'Alagoas',
    shortName: 'AL',
  },
  {
    name: 'Amapá',
    shortName: 'AP',
  },
  {
    name: 'Amazonas',
    shortName: 'AM',
  },
  {
    name: 'Bahia',
    shortName: 'BA',
  },
  {
    name: 'Ceará',
    shortName: 'CE',
  },
  {
    name: 'Distrito Federal',
    shortName: 'DF',
  },
  {
    name: 'Espírito Santo',
    shortName: 'ES',
  },
  {
    name: 'Goiás',
    shortName: 'GO',
  },
  {
    name: 'Maranhão',
    shortName: 'MA',
  },
  {
    name: 'Mato Grosso',
    shortName: 'MT',
  },
  {
    name: 'Mato Grosso do Sul',
    shortName: 'MS',
  },
  {
    name: 'Minas Gerais',
    shortName: 'MG',
  },
  {
    name: 'Pará',
    shortName: 'PA',
  },
  {
    name: 'Paraíba',
    shortName: 'PB',
  },
  {
    name: 'Paraná',
    shortName: 'PR',
  },
  {
    name: 'Pernambuco',
    shortName: 'PE',
  },
  {
    name: 'Piauí',
    shortName: 'PI',
  },
  {
    name: 'Rio de Janeiro',
    shortName: 'RJ',
  },
  {
    name: 'Rio Grande do Norte',
    shortName: 'RN',
  },
  {
    name: 'Rio Grande do Sul',
    shortName: 'RS',
  },
  {
    name: 'Rondônia',
    shortName: 'RO',
  },
  {
    name: 'Roraima',
    shortName: 'RR',
  },
  {
    name: 'Santa Catarina',
    shortName: 'SC',
  },
  {
    name: 'São Paulo',
    shortName: 'SP',
  },
  {
    name: 'Sergipe',
    shortName: 'SE',
  },
  {
    name: 'Tocantins',
    shortName: 'TO',
  },
  {
    name: 'Belait District',
    shortName: 'BE',
  },
  {
    name: 'Brunei-Muara District',
    shortName: 'BM',
  },
  {
    name: 'Temburong District',
    shortName: 'TE',
  },
  {
    name: 'Tutong District',
    shortName: 'TU',
  },
  {
    name: 'Blagoevgrad Province',
    shortName: '01',
  },
  {
    name: 'Burgas Province',
    shortName: '02',
  },
  {
    name: 'Dobrich Province',
    shortName: '08',
  },
  {
    name: 'Gabrovo Province',
    shortName: '07',
  },
  {
    name: 'Haskovo Province',
    shortName: '26',
  },
  {
    name: 'Kardzhali Province',
    shortName: '09',
  },
  {
    name: 'Kyustendil Province',
    shortName: '10',
  },
  {
    name: 'Lovech Province',
    shortName: '11',
  },
  {
    name: 'Montana Province',
    shortName: '12',
  },
  {
    name: 'Pazardzhik Province',
    shortName: '13',
  },
  {
    name: 'Pernik Province',
    shortName: '14',
  },
  {
    name: 'Pleven Province',
    shortName: '15',
  },
  {
    name: 'Plovdiv Province',
    shortName: '16',
  },
  {
    name: 'Razgrad Province',
    shortName: '17',
  },
  {
    name: 'Ruse Province',
    shortName: '18',
  },
  {
    name: 'Shumen',
    shortName: '27',
  },
  {
    name: 'Silistra Province',
    shortName: '19',
  },
  {
    name: 'Sliven Province',
    shortName: '20',
  },
  {
    name: 'Smolyan Province',
    shortName: '21',
  },
  {
    name: 'Sofia City Province',
    shortName: '22',
  },
  {
    name: 'Sofia Province',
    shortName: '23',
  },
  {
    name: 'Stara Zagora Province',
    shortName: '24',
  },
  {
    name: 'Targovishte Province',
    shortName: '25',
  },
  {
    name: 'Varna Province',
    shortName: '03',
  },
  {
    name: 'Veliko Tarnovo Province',
    shortName: '04',
  },
  {
    name: 'Vidin Province',
    shortName: '05',
  },
  {
    name: 'Vratsa Province',
    shortName: '06',
  },
  {
    name: 'Yambol Province',
    shortName: '28',
  },
  {
    name: 'Balé Province',
    shortName: 'BAL',
  },
  {
    name: 'Bam Province',
    shortName: 'BAM',
  },
  {
    name: 'Banwa Province',
    shortName: 'BAN',
  },
  {
    name: 'Bazèga Province',
    shortName: 'BAZ',
  },
  {
    name: 'Boucle du Mouhoun Region',
    shortName: '01',
  },
  {
    name: 'Bougouriba Province',
    shortName: 'BGR',
  },
  {
    name: 'Boulgou',
    shortName: 'BLG',
  },
  {
    name: 'Cascades Region',
    shortName: '02',
  },
  {
    name: 'Centre',
    shortName: '03',
  },
  {
    name: 'Centre-Est Region',
    shortName: '04',
  },
  {
    name: 'Centre-Nord Region',
    shortName: '05',
  },
  {
    name: 'Centre-Ouest Region',
    shortName: '06',
  },
  {
    name: 'Centre-Sud Region',
    shortName: '07',
  },
  {
    name: 'Comoé Province',
    shortName: 'COM',
  },
  {
    name: 'Est Region',
    shortName: '08',
  },
  {
    name: 'Ganzourgou Province',
    shortName: 'GAN',
  },
  {
    name: 'Gnagna Province',
    shortName: 'GNA',
  },
  {
    name: 'Gourma Province',
    shortName: 'GOU',
  },
  {
    name: 'Hauts-Bassins Region',
    shortName: '09',
  },
  {
    name: 'Houet Province',
    shortName: 'HOU',
  },
  {
    name: 'Ioba Province',
    shortName: 'IOB',
  },
  {
    name: 'Kadiogo Province',
    shortName: 'KAD',
  },
  {
    name: 'Kénédougou Province',
    shortName: 'KEN',
  },
  {
    name: 'Komondjari Province',
    shortName: 'KMD',
  },
  {
    name: 'Kompienga Province',
    shortName: 'KMP',
  },
  {
    name: 'Kossi Province',
    shortName: 'KOS',
  },
  {
    name: 'Koulpélogo Province',
    shortName: 'KOP',
  },
  {
    name: 'Kouritenga Province',
    shortName: 'KOT',
  },
  {
    name: 'Kourwéogo Province',
    shortName: 'KOW',
  },
  {
    name: 'Léraba Province',
    shortName: 'LER',
  },
  {
    name: 'Loroum Province',
    shortName: 'LOR',
  },
  {
    name: 'Mouhoun',
    shortName: 'MOU',
  },
  {
    name: 'Nahouri Province',
    shortName: 'NAO',
  },
  {
    name: 'Namentenga Province',
    shortName: 'NAM',
  },
  {
    name: 'Nayala Province',
    shortName: 'NAY',
  },
  {
    name: 'Nord Region, Burkina Faso',
    shortName: '10',
  },
  {
    name: 'Noumbiel Province',
    shortName: 'NOU',
  },
  {
    name: 'Oubritenga Province',
    shortName: 'OUB',
  },
  {
    name: 'Oudalan Province',
    shortName: 'OUD',
  },
  {
    name: 'Passoré Province',
    shortName: 'PAS',
  },
  {
    name: 'Plateau-Central Region',
    shortName: '11',
  },
  {
    name: 'Poni Province',
    shortName: 'PON',
  },
  {
    name: 'Sahel Region',
    shortName: '12',
  },
  {
    name: 'Sanguié Province',
    shortName: 'SNG',
  },
  {
    name: 'Sanmatenga Province',
    shortName: 'SMT',
  },
  {
    name: 'Séno Province',
    shortName: 'SEN',
  },
  {
    name: 'Sissili Province',
    shortName: 'SIS',
  },
  {
    name: 'Soum Province',
    shortName: 'SOM',
  },
  {
    name: 'Sourou Province',
    shortName: 'SOR',
  },
  {
    name: 'Sud-Ouest Region',
    shortName: '13',
  },
  {
    name: 'Tapoa Province',
    shortName: 'TAP',
  },
  {
    name: 'Tuy Province',
    shortName: 'TUI',
  },
  {
    name: 'Yagha Province',
    shortName: 'YAG',
  },
  {
    name: 'Yatenga Province',
    shortName: 'YAT',
  },
  {
    name: 'Ziro Province',
    shortName: 'ZIR',
  },
  {
    name: 'Zondoma Province',
    shortName: 'ZON',
  },
  {
    name: 'Zoundwéogo Province',
    shortName: 'ZOU',
  },
  {
    name: 'Bubanza Province',
    shortName: 'BB',
  },
  {
    name: 'Bujumbura Mairie Province',
    shortName: 'BM',
  },
  {
    name: 'Bujumbura Rural Province',
    shortName: 'BL',
  },
  {
    name: 'Bururi Province',
    shortName: 'BR',
  },
  {
    name: 'Cankuzo Province',
    shortName: 'CA',
  },
  {
    name: 'Cibitoke Province',
    shortName: 'CI',
  },
  {
    name: 'Gitega Province',
    shortName: 'GI',
  },
  {
    name: 'Karuzi Province',
    shortName: 'KR',
  },
  {
    name: 'Kayanza Province',
    shortName: 'KY',
  },
  {
    name: 'Kirundo Province',
    shortName: 'KI',
  },
  {
    name: 'Makamba Province',
    shortName: 'MA',
  },
  {
    name: 'Muramvya Province',
    shortName: 'MU',
  },
  {
    name: 'Muyinga Province',
    shortName: 'MY',
  },
  {
    name: 'Mwaro Province',
    shortName: 'MW',
  },
  {
    name: 'Ngozi Province',
    shortName: 'NG',
  },
  {
    name: 'Rumonge Province',
    shortName: 'RM',
  },
  {
    name: 'Rutana Province',
    shortName: 'RT',
  },
  {
    name: 'Ruyigi Province',
    shortName: 'RY',
  },
  {
    name: 'Banteay Meanchey',
    shortName: '1',
  },
  {
    name: 'Battambang',
    shortName: '2',
  },
  {
    name: 'Kampong Cham',
    shortName: '3',
  },
  {
    name: 'Kampong Chhnang',
    shortName: '4',
  },
  {
    name: 'Kampong Speu',
    shortName: '5',
  },
  {
    name: 'Kampong Thom',
    shortName: '6',
  },
  {
    name: 'Kampot',
    shortName: '7',
  },
  {
    name: 'Kandal',
    shortName: '8',
  },
  {
    name: 'Kep',
    shortName: '23',
  },
  {
    name: 'Koh Kong',
    shortName: '9',
  },
  {
    name: 'Kratie',
    shortName: '10',
  },
  {
    name: 'Mondulkiri',
    shortName: '11',
  },
  {
    name: 'Oddar Meanchey',
    shortName: '22',
  },
  {
    name: 'Pailin',
    shortName: '24',
  },
  {
    name: 'Phnom Penh',
    shortName: '12',
  },
  {
    name: 'Preah Vihear',
    shortName: '13',
  },
  {
    name: 'Prey Veng',
    shortName: '14',
  },
  {
    name: 'Pursat',
    shortName: '15',
  },
  {
    name: 'Ratanakiri',
    shortName: '16',
  },
  {
    name: 'Siem Reap',
    shortName: '17',
  },
  {
    name: 'Sihanoukville',
    shortName: '18',
  },
  {
    name: 'Stung Treng',
    shortName: '19',
  },
  {
    name: 'Svay Rieng',
    shortName: '20',
  },
  {
    name: 'Takeo',
    shortName: '21',
  },
  {
    name: 'Adamawa',
    shortName: 'AD',
  },
  {
    name: 'Centre',
    shortName: 'CE',
  },
  {
    name: 'East',
    shortName: 'ES',
  },
  {
    name: 'Far North',
    shortName: 'EN',
  },
  {
    name: 'Littoral',
    shortName: 'LT',
  },
  {
    name: 'North',
    shortName: 'NO',
  },
  {
    name: 'Northwest',
    shortName: 'NW',
  },
  {
    name: 'South',
    shortName: 'SU',
  },
  {
    name: 'Southwest',
    shortName: 'SW',
  },
  {
    name: 'West',
    shortName: 'OU',
  },
  {
    name: 'Alberta',
    shortName: 'AB',
  },
  {
    name: 'British Columbia',
    shortName: 'BC',
  },
  {
    name: 'Manitoba',
    shortName: 'MB',
  },
  {
    name: 'New Brunswick',
    shortName: 'NB',
  },
  {
    name: 'Newfoundland and Labrador',
    shortName: 'NL',
  },
  {
    name: 'Northwest Territories',
    shortName: 'NT',
  },
  {
    name: 'Nova Scotia',
    shortName: 'NS',
  },
  {
    name: 'Nunavut',
    shortName: 'NU',
  },
  {
    name: 'Ontario',
    shortName: 'ON',
  },
  {
    name: 'Prince Edward Island',
    shortName: 'PE',
  },
  {
    name: 'Quebec',
    shortName: 'QC',
  },
  {
    name: 'Saskatchewan',
    shortName: 'SK',
  },
  {
    name: 'Yukon',
    shortName: 'YT',
  },
  {
    name: 'Barlavento Islands',
    shortName: 'B',
  },
  {
    name: 'Boa Vista',
    shortName: 'BV',
  },
  {
    name: 'Brava',
    shortName: 'BR',
  },
  {
    name: 'Maio Municipality',
    shortName: 'MA',
  },
  {
    name: 'Mosteiros',
    shortName: 'MO',
  },
  {
    name: 'Paul',
    shortName: 'PA',
  },
  {
    name: 'Porto Novo',
    shortName: 'PN',
  },
  {
    name: 'Praia',
    shortName: 'PR',
  },
  {
    name: 'Ribeira Brava Municipality',
    shortName: 'RB',
  },
  {
    name: 'Ribeira Grande',
    shortName: 'RG',
  },
  {
    name: 'Ribeira Grande de Santiago',
    shortName: 'RS',
  },
  {
    name: 'Sal',
    shortName: 'SL',
  },
  {
    name: 'Santa Catarina',
    shortName: 'CA',
  },
  {
    name: 'Santa Catarina do Fogo',
    shortName: 'CF',
  },
  {
    name: 'Santa Cruz',
    shortName: 'CR',
  },
  {
    name: 'São Domingos',
    shortName: 'SD',
  },
  {
    name: 'São Filipe',
    shortName: 'SF',
  },
  {
    name: 'São Lourenço dos Órgãos',
    shortName: 'SO',
  },
  {
    name: 'São Miguel',
    shortName: 'SM',
  },
  {
    name: 'São Vicente',
    shortName: 'SV',
  },
  {
    name: 'Sotavento Islands',
    shortName: 'S',
  },
  {
    name: 'Tarrafal',
    shortName: 'TA',
  },
  {
    name: 'Tarrafal de São Nicolau',
    shortName: 'TS',
  },
  {
    name: 'Bamingui-Bangoran Prefecture',
    shortName: 'BB',
  },
  {
    name: 'Bangui',
    shortName: 'BGF',
  },
  {
    name: 'Basse-Kotto Prefecture',
    shortName: 'BK',
  },
  {
    name: 'Haut-Mbomou Prefecture',
    shortName: 'HM',
  },
  {
    name: 'Haute-Kotto Prefecture',
    shortName: 'HK',
  },
  {
    name: 'Kémo Prefecture',
    shortName: 'KG',
  },
  {
    name: 'Lobaye Prefecture',
    shortName: 'LB',
  },
  {
    name: 'Mambéré-Kadéï',
    shortName: 'HS',
  },
  {
    name: 'Mbomou Prefecture',
    shortName: 'MB',
  },
  {
    name: 'Nana-Grébizi Economic Prefecture',
    shortName: 'KB',
  },
  {
    name: 'Nana-Mambéré Prefecture',
    shortName: 'NM',
  },
  {
    name: "Ombella-M'Poko Prefecture",
    shortName: 'MP',
  },
  {
    name: 'Ouaka Prefecture',
    shortName: 'UK',
  },
  {
    name: 'Ouham Prefecture',
    shortName: 'AC',
  },
  {
    name: 'Ouham-Pendé Prefecture',
    shortName: 'OP',
  },
  {
    name: 'Sangha-Mbaéré',
    shortName: 'SE',
  },
  {
    name: 'Vakaga Prefecture',
    shortName: 'VK',
  },
  {
    name: 'Bahr el Gazel',
    shortName: 'BG',
  },
  {
    name: 'Batha',
    shortName: 'BA',
  },
  {
    name: 'Borkou',
    shortName: 'BO',
  },
  {
    name: 'Chari-Baguirmi',
    shortName: 'CB',
  },
  {
    name: 'Ennedi-Est',
    shortName: 'EE',
  },
  {
    name: 'Ennedi-Ouest',
    shortName: 'EO',
  },
  {
    name: 'Guéra',
    shortName: 'GR',
  },
  {
    name: 'Hadjer-Lamis',
    shortName: 'HL',
  },
  {
    name: 'Kanem',
    shortName: 'KA',
  },
  {
    name: 'Lac',
    shortName: 'LC',
  },
  {
    name: 'Logone Occidental',
    shortName: 'LO',
  },
  {
    name: 'Logone Oriental',
    shortName: 'LR',
  },
  {
    name: 'Mandoul',
    shortName: 'MA',
  },
  {
    name: 'Mayo-Kebbi Est',
    shortName: 'ME',
  },
  {
    name: 'Mayo-Kebbi Ouest',
    shortName: 'MO',
  },
  {
    name: 'Moyen-Chari',
    shortName: 'MC',
  },
  {
    name: "N'Djamena",
    shortName: 'ND',
  },
  {
    name: 'Ouaddaï',
    shortName: 'OD',
  },
  {
    name: 'Salamat',
    shortName: 'SA',
  },
  {
    name: 'Sila',
    shortName: 'SI',
  },
  {
    name: 'Tandjilé',
    shortName: 'TA',
  },
  {
    name: 'Tibesti',
    shortName: 'TI',
  },
  {
    name: 'Wadi Fira',
    shortName: 'WF',
  },
  {
    name: 'Aisén del General Carlos Ibañez del Campo',
    shortName: 'AI',
  },
  {
    name: 'Antofagasta',
    shortName: 'AN',
  },
  {
    name: 'Arica y Parinacota',
    shortName: 'AP',
  },
  {
    name: 'Atacama',
    shortName: 'AT',
  },
  {
    name: 'Biobío',
    shortName: 'BI',
  },
  {
    name: 'Coquimbo',
    shortName: 'CO',
  },
  {
    name: 'La Araucanía',
    shortName: 'AR',
  },
  {
    name: "Libertador General Bernardo O'Higgins",
    shortName: 'LI',
  },
  {
    name: 'Los Lagos',
    shortName: 'LL',
  },
  {
    name: 'Los Ríos',
    shortName: 'LR',
  },
  {
    name: 'Magallanes y de la Antártica Chilena',
    shortName: 'MA',
  },
  {
    name: 'Maule',
    shortName: 'ML',
  },
  {
    name: 'Ñuble',
    shortName: 'NB',
  },
  {
    name: 'Región Metropolitana de Santiago',
    shortName: 'RM',
  },
  {
    name: 'Tarapacá',
    shortName: 'TA',
  },
  {
    name: 'Valparaíso',
    shortName: 'VS',
  },
  {
    name: 'Anhui',
    shortName: 'AH',
  },
  {
    name: 'Beijing',
    shortName: 'BJ',
  },
  {
    name: 'Chongqing',
    shortName: 'CQ',
  },
  {
    name: 'Fujian',
    shortName: 'FJ',
  },
  {
    name: 'Gansu',
    shortName: 'GS',
  },
  {
    name: 'Guangdong',
    shortName: 'GD',
  },
  {
    name: 'Guangxi Zhuang',
    shortName: 'GX',
  },
  {
    name: 'Guizhou',
    shortName: 'GZ',
  },
  {
    name: 'Hainan',
    shortName: 'HI',
  },
  {
    name: 'Hebei',
    shortName: 'HE',
  },
  {
    name: 'Heilongjiang',
    shortName: 'HL',
  },
  {
    name: 'Henan',
    shortName: 'HA',
  },
  {
    name: 'Hong Kong SAR',
    shortName: 'HK',
  },
  {
    name: 'Hubei',
    shortName: 'HB',
  },
  {
    name: 'Hunan',
    shortName: 'HN',
  },
  {
    name: 'Inner Mongolia',
    shortName: 'NM',
  },
  {
    name: 'Jiangsu',
    shortName: 'JS',
  },
  {
    name: 'Jiangxi',
    shortName: 'JX',
  },
  {
    name: 'Jilin',
    shortName: 'JL',
  },
  {
    name: 'Liaoning',
    shortName: 'LN',
  },
  {
    name: 'Macau SAR',
    shortName: 'MO',
  },
  {
    name: 'Ningxia Huizu',
    shortName: 'NX',
  },
  {
    name: 'Qinghai',
    shortName: 'QH',
  },
  {
    name: 'Shaanxi',
    shortName: 'SN',
  },
  {
    name: 'Shandong',
    shortName: 'SD',
  },
  {
    name: 'Shanghai',
    shortName: 'SH',
  },
  {
    name: 'Shanxi',
    shortName: 'SX',
  },
  {
    name: 'Sichuan',
    shortName: 'SC',
  },
  {
    name: 'Taiwan',
    shortName: 'TW',
  },
  {
    name: 'Tianjin',
    shortName: 'TJ',
  },
  {
    name: 'Xinjiang',
    shortName: 'XJ',
  },
  {
    name: 'Xizang',
    shortName: 'XZ',
  },
  {
    name: 'Yunnan',
    shortName: 'YN',
  },
  {
    name: 'Zhejiang',
    shortName: 'ZJ',
  },
  {
    name: 'Amazonas',
    shortName: 'AMA',
  },
  {
    name: 'Antioquia',
    shortName: 'ANT',
  },
  {
    name: 'Arauca',
    shortName: 'ARA',
  },
  {
    name: 'Archipiélago de San Andrés, Providencia y Santa Catalina',
    shortName: 'SAP',
  },
  {
    name: 'Atlántico',
    shortName: 'ATL',
  },
  {
    name: 'Bogotá D.C.',
    shortName: 'DC',
  },
  {
    name: 'Bolívar',
    shortName: 'BOL',
  },
  {
    name: 'Boyacá',
    shortName: 'BOY',
  },
  {
    name: 'Caldas',
    shortName: 'CAL',
  },
  {
    name: 'Caquetá',
    shortName: 'CAQ',
  },
  {
    name: 'Casanare',
    shortName: 'CAS',
  },
  {
    name: 'Cauca',
    shortName: 'CAU',
  },
  {
    name: 'Cesar',
    shortName: 'CES',
  },
  {
    name: 'Chocó',
    shortName: 'CHO',
  },
  {
    name: 'Córdoba',
    shortName: 'COR',
  },
  {
    name: 'Cundinamarca',
    shortName: 'CUN',
  },
  {
    name: 'Guainía',
    shortName: 'GUA',
  },
  {
    name: 'Guaviare',
    shortName: 'GUV',
  },
  {
    name: 'Huila',
    shortName: 'HUI',
  },
  {
    name: 'La Guajira',
    shortName: 'LAG',
  },
  {
    name: 'Magdalena',
    shortName: 'MAG',
  },
  {
    name: 'Meta',
    shortName: 'MET',
  },
  {
    name: 'Nariño',
    shortName: 'NAR',
  },
  {
    name: 'Norte de Santander',
    shortName: 'NSA',
  },
  {
    name: 'Putumayo',
    shortName: 'PUT',
  },
  {
    name: 'Quindío',
    shortName: 'QUI',
  },
  {
    name: 'Risaralda',
    shortName: 'RIS',
  },
  {
    name: 'Santander',
    shortName: 'SAN',
  },
  {
    name: 'Sucre',
    shortName: 'SUC',
  },
  {
    name: 'Tolima',
    shortName: 'TOL',
  },
  {
    name: 'Valle del Cauca',
    shortName: 'VAC',
  },
  {
    name: 'Vaupés',
    shortName: 'VAU',
  },
  {
    name: 'Vichada',
    shortName: 'VID',
  },
  {
    name: 'Anjouan',
    shortName: 'A',
  },
  {
    name: 'Grande Comore',
    shortName: 'G',
  },
  {
    name: 'Mohéli',
    shortName: 'M',
  },
  {
    name: 'Bouenza Department',
    shortName: '11',
  },
  {
    name: 'Brazzaville',
    shortName: 'BZV',
  },
  {
    name: 'Cuvette Department',
    shortName: '8',
  },
  {
    name: 'Cuvette-Ouest Department',
    shortName: '15',
  },
  {
    name: 'Kouilou Department',
    shortName: '5',
  },
  {
    name: 'Lékoumou Department',
    shortName: '2',
  },
  {
    name: 'Likouala Department',
    shortName: '7',
  },
  {
    name: 'Niari Department',
    shortName: '9',
  },
  {
    name: 'Plateaux Department',
    shortName: '14',
  },
  {
    name: 'Pointe-Noire',
    shortName: '16',
  },
  {
    name: 'Pool Department',
    shortName: '12',
  },
  {
    name: 'Sangha Department',
    shortName: '13',
  },
  {
    name: 'Alajuela Province',
    shortName: 'A',
  },
  {
    name: 'Guanacaste Province',
    shortName: 'G',
  },
  {
    name: 'Heredia Province',
    shortName: 'H',
  },
  {
    name: 'Limón Province',
    shortName: 'L',
  },
  {
    name: 'Provincia de Cartago',
    shortName: 'C',
  },
  {
    name: 'Puntarenas Province',
    shortName: 'P',
  },
  {
    name: 'San José Province',
    shortName: 'SJ',
  },
  {
    name: 'Abidjan',
    shortName: 'AB',
  },
  {
    name: 'Agnéby',
    shortName: '16',
  },
  {
    name: 'Bafing Region',
    shortName: '17',
  },
  {
    name: 'Bas-Sassandra District',
    shortName: 'BS',
  },
  {
    name: 'Bas-Sassandra Region',
    shortName: '09',
  },
  {
    name: 'Comoé District',
    shortName: 'CM',
  },
  {
    name: 'Denguélé District',
    shortName: 'DN',
  },
  {
    name: 'Denguélé Region',
    shortName: '10',
  },
  {
    name: 'Dix-Huit Montagnes',
    shortName: '06',
  },
  {
    name: 'Fromager',
    shortName: '18',
  },
  {
    name: 'Gôh-Djiboua District',
    shortName: 'GD',
  },
  {
    name: 'Haut-Sassandra',
    shortName: '02',
  },
  {
    name: 'Lacs District',
    shortName: 'LC',
  },
  {
    name: 'Lacs Region',
    shortName: '07',
  },
  {
    name: 'Lagunes District',
    shortName: 'LG',
  },
  {
    name: 'Lagunes region',
    shortName: '01',
  },
  {
    name: 'Marahoué Region',
    shortName: '12',
  },
  {
    name: 'Montagnes District',
    shortName: 'MG',
  },
  {
    name: 'Moyen-Cavally',
    shortName: '19',
  },
  {
    name: 'Moyen-Comoé',
    shortName: '05',
  },
  {
    name: "N'zi-Comoé",
    shortName: '11',
  },
  {
    name: 'Sassandra-Marahoué District',
    shortName: 'SM',
  },
  {
    name: 'Savanes Region',
    shortName: '03',
  },
  {
    name: 'Sud-Bandama',
    shortName: '15',
  },
  {
    name: 'Sud-Comoé',
    shortName: '13',
  },
  {
    name: 'Vallée du Bandama District',
    shortName: 'VB',
  },
  {
    name: 'Vallée du Bandama Region',
    shortName: '04',
  },
  {
    name: 'Woroba District',
    shortName: 'WR',
  },
  {
    name: 'Worodougou',
    shortName: '14',
  },
  {
    name: 'Yamoussoukro',
    shortName: 'YM',
  },
  {
    name: 'Zanzan Region',
    shortName: 'ZZ',
  },
  {
    name: 'Bjelovar-Bilogora',
    shortName: '07',
  },
  {
    name: 'Brod-Posavina',
    shortName: '12',
  },
  {
    name: 'Dubrovnik-Neretva',
    shortName: '19',
  },
  {
    name: 'Istria',
    shortName: '18',
  },
  {
    name: 'Karlovac',
    shortName: '04',
  },
  {
    name: 'Koprivnica-Križevci',
    shortName: '06',
  },
  {
    name: 'Krapina-Zagorje',
    shortName: '02',
  },
  {
    name: 'Lika-Senj',
    shortName: '09',
  },
  {
    name: 'Međimurje',
    shortName: '20',
  },
  {
    name: 'Osijek-Baranja',
    shortName: '14',
  },
  {
    name: 'Požega-Slavonia',
    shortName: '11',
  },
  {
    name: 'Primorje-Gorski Kotar',
    shortName: '08',
  },
  {
    name: 'Šibenik-Knin',
    shortName: '15',
  },
  {
    name: 'Sisak-Moslavina',
    shortName: '03',
  },
  {
    name: 'Split-Dalmatia',
    shortName: '17',
  },
  {
    name: 'Varaždin',
    shortName: '05',
  },
  {
    name: 'Virovitica-Podravina',
    shortName: '10',
  },
  {
    name: 'Vukovar-Syrmia',
    shortName: '16',
  },
  {
    name: 'Zadar',
    shortName: '13',
  },
  {
    name: 'Zagreb',
    shortName: '01',
  },
  {
    name: 'Zagreb',
    shortName: '21',
  },
  {
    name: 'Artemisa Province',
    shortName: '15',
  },
  {
    name: 'Camagüey Province',
    shortName: '09',
  },
  {
    name: 'Ciego de Ávila Province',
    shortName: '08',
  },
  {
    name: 'Cienfuegos Province',
    shortName: '06',
  },
  {
    name: 'Granma Province',
    shortName: '12',
  },
  {
    name: 'Guantánamo Province',
    shortName: '14',
  },
  {
    name: 'Havana Province',
    shortName: '03',
  },
  {
    name: 'Holguín Province',
    shortName: '11',
  },
  {
    name: 'Isla de la Juventud',
    shortName: '99',
  },
  {
    name: 'Las Tunas Province',
    shortName: '10',
  },
  {
    name: 'Matanzas Province',
    shortName: '04',
  },
  {
    name: 'Mayabeque Province',
    shortName: '16',
  },
  {
    name: 'Pinar del Río Province',
    shortName: '01',
  },
  {
    name: 'Sancti Spíritus Province',
    shortName: '07',
  },
  {
    name: 'Santiago de Cuba Province',
    shortName: '13',
  },
  {
    name: 'Villa Clara Province',
    shortName: '05',
  },
  {
    name: 'Famagusta District (Mağusa)',
    shortName: '04',
  },
  {
    name: 'Kyrenia District (Keryneia)',
    shortName: '06',
  },
  {
    name: 'Larnaca District (Larnaka)',
    shortName: '03',
  },
  {
    name: 'Limassol District (Leymasun)',
    shortName: '02',
  },
  {
    name: 'Nicosia District (Lefkoşa)',
    shortName: '01',
  },
  {
    name: 'Paphos District (Pafos)',
    shortName: '05',
  },
  {
    name: 'Benešov',
    shortName: '201',
  },
  {
    name: 'Beroun',
    shortName: '202',
  },
  {
    name: 'Blansko',
    shortName: '641',
  },
  {
    name: 'Břeclav',
    shortName: '644',
  },
  {
    name: 'Brno-město',
    shortName: '642',
  },
  {
    name: 'Brno-venkov',
    shortName: '643',
  },
  {
    name: 'Bruntál',
    shortName: '801',
  },
  {
    name: 'Česká Lípa',
    shortName: '511',
  },
  {
    name: 'České Budějovice',
    shortName: '311',
  },
  {
    name: 'Český Krumlov',
    shortName: '312',
  },
  {
    name: 'Cheb',
    shortName: '411',
  },
  {
    name: 'Chomutov',
    shortName: '422',
  },
  {
    name: 'Chrudim',
    shortName: '531',
  },
  {
    name: 'Děčín',
    shortName: '421',
  },
  {
    name: 'Domažlice',
    shortName: '321',
  },
  {
    name: 'Frýdek-Místek',
    shortName: '802',
  },
  {
    name: 'Havlíčkův Brod',
    shortName: '631',
  },
  {
    name: 'Hodonín',
    shortName: '645',
  },
  {
    name: 'Hradec Králové',
    shortName: '521',
  },
  {
    name: 'Jablonec nad Nisou',
    shortName: '512',
  },
  {
    name: 'Jeseník',
    shortName: '711',
  },
  {
    name: 'Jičín',
    shortName: '522',
  },
  {
    name: 'Jihlava',
    shortName: '632',
  },
  {
    name: 'Jihočeský kraj',
    shortName: '31',
  },
  {
    name: 'Jihomoravský kraj',
    shortName: '64',
  },
  {
    name: 'Jindřichův Hradec',
    shortName: '313',
  },
  {
    name: 'Karlovarský kraj',
    shortName: '41',
  },
  {
    name: 'Karlovy Vary',
    shortName: '412',
  },
  {
    name: 'Karviná',
    shortName: '803',
  },
  {
    name: 'Kladno',
    shortName: '203',
  },
  {
    name: 'Klatovy',
    shortName: '322',
  },
  {
    name: 'Kolín',
    shortName: '204',
  },
  {
    name: 'Kraj Vysočina',
    shortName: '63',
  },
  {
    name: 'Královéhradecký kraj',
    shortName: '52',
  },
  {
    name: 'Kroměříž',
    shortName: '721',
  },
  {
    name: 'Kutná Hora',
    shortName: '205',
  },
  {
    name: 'Liberec',
    shortName: '513',
  },
  {
    name: 'Liberecký kraj',
    shortName: '51',
  },
  {
    name: 'Litoměřice',
    shortName: '423',
  },
  {
    name: 'Louny',
    shortName: '424',
  },
  {
    name: 'Mělník',
    shortName: '206',
  },
  {
    name: 'Mladá Boleslav',
    shortName: '207',
  },
  {
    name: 'Moravskoslezský kraj',
    shortName: '80',
  },
  {
    name: 'Most',
    shortName: '425',
  },
  {
    name: 'Náchod',
    shortName: '523',
  },
  {
    name: 'Nový Jičín',
    shortName: '804',
  },
  {
    name: 'Nymburk',
    shortName: '208',
  },
  {
    name: 'Olomouc',
    shortName: '712',
  },
  {
    name: 'Olomoucký kraj',
    shortName: '71',
  },
  {
    name: 'Opava',
    shortName: '805',
  },
  {
    name: 'Ostrava-město',
    shortName: '806',
  },
  {
    name: 'Pardubice',
    shortName: '532',
  },
  {
    name: 'Pardubický kraj',
    shortName: '53',
  },
  {
    name: 'Pelhřimov',
    shortName: '633',
  },
  {
    name: 'Písek',
    shortName: '314',
  },
  {
    name: 'Plzeň-jih',
    shortName: '324',
  },
  {
    name: 'Plzeň-město',
    shortName: '323',
  },
  {
    name: 'Plzeň-sever',
    shortName: '325',
  },
  {
    name: 'Plzeňský kraj',
    shortName: '32',
  },
  {
    name: 'Prachatice',
    shortName: '315',
  },
  {
    name: 'Praha-východ',
    shortName: '209',
  },
  {
    name: 'Praha-západ',
    shortName: '20A',
  },
  {
    name: 'Praha, Hlavní město',
    shortName: '10',
  },
  {
    name: 'Přerov',
    shortName: '714',
  },
  {
    name: 'Příbram',
    shortName: '20B',
  },
  {
    name: 'Prostějov',
    shortName: '713',
  },
  {
    name: 'Rakovník',
    shortName: '20C',
  },
  {
    name: 'Rokycany',
    shortName: '326',
  },
  {
    name: 'Rychnov nad Kněžnou',
    shortName: '524',
  },
  {
    name: 'Semily',
    shortName: '514',
  },
  {
    name: 'Sokolov',
    shortName: '413',
  },
  {
    name: 'Strakonice',
    shortName: '316',
  },
  {
    name: 'Středočeský kraj',
    shortName: '20',
  },
  {
    name: 'Šumperk',
    shortName: '715',
  },
  {
    name: 'Svitavy',
    shortName: '533',
  },
  {
    name: 'Tábor',
    shortName: '317',
  },
  {
    name: 'Tachov',
    shortName: '327',
  },
  {
    name: 'Teplice',
    shortName: '426',
  },
  {
    name: 'Třebíč',
    shortName: '634',
  },
  {
    name: 'Trutnov',
    shortName: '525',
  },
  {
    name: 'Uherské Hradiště',
    shortName: '722',
  },
  {
    name: 'Ústecký kraj',
    shortName: '42',
  },
  {
    name: 'Ústí nad Labem',
    shortName: '427',
  },
  {
    name: 'Ústí nad Orlicí',
    shortName: '534',
  },
  {
    name: 'Vsetín',
    shortName: '723',
  },
  {
    name: 'Vyškov',
    shortName: '646',
  },
  {
    name: 'Žďár nad Sázavou',
    shortName: '635',
  },
  {
    name: 'Zlín',
    shortName: '724',
  },
  {
    name: 'Zlínský kraj',
    shortName: '72',
  },
  {
    name: 'Znojmo',
    shortName: '647',
  },
  {
    name: 'Bas-Uélé',
    shortName: 'BU',
  },
  {
    name: 'Équateur',
    shortName: 'EQ',
  },
  {
    name: 'Haut-Katanga',
    shortName: 'HK',
  },
  {
    name: 'Haut-Lomami',
    shortName: 'HL',
  },
  {
    name: 'Haut-Uélé',
    shortName: 'HU',
  },
  {
    name: 'Ituri',
    shortName: 'IT',
  },
  {
    name: 'Kasaï',
    shortName: 'KS',
  },
  {
    name: 'Kasaï Central',
    shortName: 'KC',
  },
  {
    name: 'Kasaï Oriental',
    shortName: 'KE',
  },
  {
    name: 'Kinshasa',
    shortName: 'KN',
  },
  {
    name: 'Kongo Central',
    shortName: 'BC',
  },
  {
    name: 'Kwango',
    shortName: 'KG',
  },
  {
    name: 'Kwilu',
    shortName: 'KL',
  },
  {
    name: 'Lomami',
    shortName: 'LO',
  },
  {
    name: 'Lualaba',
    shortName: 'LU',
  },
  {
    name: 'Mai-Ndombe',
    shortName: 'MN',
  },
  {
    name: 'Maniema',
    shortName: 'MA',
  },
  {
    name: 'Mongala',
    shortName: 'MO',
  },
  {
    name: 'Nord-Kivu',
    shortName: 'NK',
  },
  {
    name: 'Nord-Ubangi',
    shortName: 'NU',
  },
  {
    name: 'Sankuru',
    shortName: 'SA',
  },
  {
    name: 'Sud-Kivu',
    shortName: 'SK',
  },
  {
    name: 'Sud-Ubangi',
    shortName: 'SU',
  },
  {
    name: 'Tanganyika',
    shortName: 'TA',
  },
  {
    name: 'Tshopo',
    shortName: 'TO',
  },
  {
    name: 'Tshuapa',
    shortName: 'TU',
  },
  {
    name: 'Capital Region of Denmark',
    shortName: '84',
  },
  {
    name: 'Central Denmark Region',
    shortName: '82',
  },
  {
    name: 'North Denmark Region',
    shortName: '81',
  },
  {
    name: 'Region of Southern Denmark',
    shortName: '83',
  },
  {
    name: 'Region Zealand',
    shortName: '85',
  },
  {
    name: 'Ali Sabieh Region',
    shortName: 'AS',
  },
  {
    name: 'Arta Region',
    shortName: 'AR',
  },
  {
    name: 'Dikhil Region',
    shortName: 'DI',
  },
  {
    name: 'Djibouti',
    shortName: 'DJ',
  },
  {
    name: 'Obock Region',
    shortName: 'OB',
  },
  {
    name: 'Tadjourah Region',
    shortName: 'TA',
  },
  {
    name: 'Saint Andrew Parish',
    shortName: '02',
  },
  {
    name: 'Saint David Parish',
    shortName: '03',
  },
  {
    name: 'Saint George Parish',
    shortName: '04',
  },
  {
    name: 'Saint John Parish',
    shortName: '05',
  },
  {
    name: 'Saint Joseph Parish',
    shortName: '06',
  },
  {
    name: 'Saint Luke Parish',
    shortName: '07',
  },
  {
    name: 'Saint Mark Parish',
    shortName: '08',
  },
  {
    name: 'Saint Patrick Parish',
    shortName: '09',
  },
  {
    name: 'Saint Paul Parish',
    shortName: '10',
  },
  {
    name: 'Saint Peter Parish',
    shortName: '11',
  },
  {
    name: 'Azua Province',
    shortName: '02',
  },
  {
    name: 'Baoruco Province',
    shortName: '03',
  },
  {
    name: 'Barahona Province',
    shortName: '04',
  },
  {
    name: 'Dajabón Province',
    shortName: '05',
  },
  {
    name: 'Distrito Nacional',
    shortName: '01',
  },
  {
    name: 'Duarte Province',
    shortName: '06',
  },
  {
    name: 'El Seibo Province',
    shortName: '08',
  },
  {
    name: 'Espaillat Province',
    shortName: '09',
  },
  {
    name: 'Hato Mayor Province',
    shortName: '30',
  },
  {
    name: 'Hermanas Mirabal Province',
    shortName: '19',
  },
  {
    name: 'Independencia',
    shortName: '10',
  },
  {
    name: 'La Altagracia Province',
    shortName: '11',
  },
  {
    name: 'La Romana Province',
    shortName: '12',
  },
  {
    name: 'La Vega Province',
    shortName: '13',
  },
  {
    name: 'María Trinidad Sánchez Province',
    shortName: '14',
  },
  {
    name: 'Monseñor Nouel Province',
    shortName: '28',
  },
  {
    name: 'Monte Cristi Province',
    shortName: '15',
  },
  {
    name: 'Monte Plata Province',
    shortName: '29',
  },
  {
    name: 'Pedernales Province',
    shortName: '16',
  },
  {
    name: 'Peravia Province',
    shortName: '17',
  },
  {
    name: 'Puerto Plata Province',
    shortName: '18',
  },
  {
    name: 'Samaná Province',
    shortName: '20',
  },
  {
    name: 'San Cristóbal Province',
    shortName: '21',
  },
  {
    name: 'San José de Ocoa Province',
    shortName: '31',
  },
  {
    name: 'San Juan Province',
    shortName: '22',
  },
  {
    name: 'San Pedro de Macorís',
    shortName: '23',
  },
  {
    name: 'Sánchez Ramírez Province',
    shortName: '24',
  },
  {
    name: 'Santiago Province',
    shortName: '25',
  },
  {
    name: 'Santiago Rodríguez Province',
    shortName: '26',
  },
  {
    name: 'Santo Domingo Province',
    shortName: '32',
  },
  {
    name: 'Valverde Province',
    shortName: '27',
  },
  {
    name: 'Aileu municipality',
    shortName: 'AL',
  },
  {
    name: 'Ainaro Municipality',
    shortName: 'AN',
  },
  {
    name: 'Baucau Municipality',
    shortName: 'BA',
  },
  {
    name: 'Bobonaro Municipality',
    shortName: 'BO',
  },
  {
    name: 'Cova Lima Municipality',
    shortName: 'CO',
  },
  {
    name: 'Dili municipality',
    shortName: 'DI',
  },
  {
    name: 'Ermera District',
    shortName: 'ER',
  },
  {
    name: 'Lautém Municipality',
    shortName: 'LA',
  },
  {
    name: 'Liquiçá Municipality',
    shortName: 'LI',
  },
  {
    name: 'Manatuto District',
    shortName: 'MT',
  },
  {
    name: 'Manufahi Municipality',
    shortName: 'MF',
  },
  {
    name: 'Viqueque Municipality',
    shortName: 'VI',
  },
  {
    name: 'Azuay',
    shortName: 'A',
  },
  {
    name: 'Bolívar',
    shortName: 'B',
  },
  {
    name: 'Cañar',
    shortName: 'F',
  },
  {
    name: 'Carchi',
    shortName: 'C',
  },
  {
    name: 'Chimborazo',
    shortName: 'H',
  },
  {
    name: 'Cotopaxi',
    shortName: 'X',
  },
  {
    name: 'El Oro',
    shortName: 'O',
  },
  {
    name: 'Esmeraldas',
    shortName: 'E',
  },
  {
    name: 'Galápagos',
    shortName: 'W',
  },
  {
    name: 'Guayas',
    shortName: 'G',
  },
  {
    name: 'Imbabura',
    shortName: 'I',
  },
  {
    name: 'Loja',
    shortName: 'L',
  },
  {
    name: 'Los Ríos',
    shortName: 'R',
  },
  {
    name: 'Manabí',
    shortName: 'M',
  },
  {
    name: 'Morona-Santiago',
    shortName: 'S',
  },
  {
    name: 'Napo',
    shortName: 'N',
  },
  {
    name: 'Orellana',
    shortName: 'D',
  },
  {
    name: 'Pastaza',
    shortName: 'Y',
  },
  {
    name: 'Pichincha',
    shortName: 'P',
  },
  {
    name: 'Santa Elena',
    shortName: 'SE',
  },
  {
    name: 'Santo Domingo de los Tsáchilas',
    shortName: 'SD',
  },
  {
    name: 'Sucumbíos',
    shortName: 'U',
  },
  {
    name: 'Tungurahua',
    shortName: 'T',
  },
  {
    name: 'Zamora Chinchipe',
    shortName: 'Z',
  },
  {
    name: 'Alexandria',
    shortName: 'ALX',
  },
  {
    name: 'Aswan',
    shortName: 'ASN',
  },
  {
    name: 'Asyut',
    shortName: 'AST',
  },
  {
    name: 'Beheira',
    shortName: 'BH',
  },
  {
    name: 'Beni Suef',
    shortName: 'BNS',
  },
  {
    name: 'Cairo',
    shortName: 'C',
  },
  {
    name: 'Dakahlia',
    shortName: 'DK',
  },
  {
    name: 'Damietta',
    shortName: 'DT',
  },
  {
    name: 'Faiyum',
    shortName: 'FYM',
  },
  {
    name: 'Gharbia',
    shortName: 'GH',
  },
  {
    name: 'Giza',
    shortName: 'GZ',
  },
  {
    name: 'Ismailia',
    shortName: 'IS',
  },
  {
    name: 'Kafr el-Sheikh',
    shortName: 'KFS',
  },
  {
    name: 'Luxor',
    shortName: 'LX',
  },
  {
    name: 'Matrouh',
    shortName: 'MT',
  },
  {
    name: 'Minya',
    shortName: 'MN',
  },
  {
    name: 'Monufia',
    shortName: 'MNF',
  },
  {
    name: 'New Valley',
    shortName: 'WAD',
  },
  {
    name: 'North Sinai',
    shortName: 'SIN',
  },
  {
    name: 'Port Said',
    shortName: 'PTS',
  },
  {
    name: 'Qalyubia',
    shortName: 'KB',
  },
  {
    name: 'Qena',
    shortName: 'KN',
  },
  {
    name: 'Red Sea',
    shortName: 'BA',
  },
  {
    name: 'Sharqia',
    shortName: 'SHR',
  },
  {
    name: 'Sohag',
    shortName: 'SHG',
  },
  {
    name: 'South Sinai',
    shortName: 'JS',
  },
  {
    name: 'Suez',
    shortName: 'SUZ',
  },
  {
    name: 'Ahuachapán Department',
    shortName: 'AH',
  },
  {
    name: 'Cabañas Department',
    shortName: 'CA',
  },
  {
    name: 'Chalatenango Department',
    shortName: 'CH',
  },
  {
    name: 'Cuscatlán Department',
    shortName: 'CU',
  },
  {
    name: 'La Libertad Department',
    shortName: 'LI',
  },
  {
    name: 'La Paz Department',
    shortName: 'PA',
  },
  {
    name: 'La Unión Department',
    shortName: 'UN',
  },
  {
    name: 'Morazán Department',
    shortName: 'MO',
  },
  {
    name: 'San Miguel Department',
    shortName: 'SM',
  },
  {
    name: 'San Salvador Department',
    shortName: 'SS',
  },
  {
    name: 'San Vicente Department',
    shortName: 'SV',
  },
  {
    name: 'Santa Ana Department',
    shortName: 'SA',
  },
  {
    name: 'Sonsonate Department',
    shortName: 'SO',
  },
  {
    name: 'Usulután Department',
    shortName: 'US',
  },
  {
    name: 'Annobón Province',
    shortName: 'AN',
  },
  {
    name: 'Bioko Norte Province',
    shortName: 'BN',
  },
  {
    name: 'Bioko Sur Province',
    shortName: 'BS',
  },
  {
    name: 'Centro Sur Province',
    shortName: 'CS',
  },
  {
    name: 'Insular Region',
    shortName: 'I',
  },
  {
    name: 'Kié-Ntem Province',
    shortName: 'KN',
  },
  {
    name: 'Litoral Province',
    shortName: 'LI',
  },
  {
    name: 'Río Muni',
    shortName: 'C',
  },
  {
    name: 'Wele-Nzas Province',
    shortName: 'WN',
  },
  {
    name: 'Anseba Region',
    shortName: 'AN',
  },
  {
    name: 'Debub Region',
    shortName: 'DU',
  },
  {
    name: 'Gash-Barka Region',
    shortName: 'GB',
  },
  {
    name: 'Maekel Region',
    shortName: 'MA',
  },
  {
    name: 'Northern Red Sea Region',
    shortName: 'SK',
  },
  {
    name: 'Southern Red Sea Region',
    shortName: 'DK',
  },
  {
    name: 'Harju County',
    shortName: '37',
  },
  {
    name: 'Hiiu County',
    shortName: '39',
  },
  {
    name: 'Ida-Viru County',
    shortName: '44',
  },
  {
    name: 'Järva County',
    shortName: '51',
  },
  {
    name: 'Jõgeva County',
    shortName: '49',
  },
  {
    name: 'Lääne County',
    shortName: '57',
  },
  {
    name: 'Lääne-Viru County',
    shortName: '59',
  },
  {
    name: 'Pärnu County',
    shortName: '67',
  },
  {
    name: 'Põlva County',
    shortName: '65',
  },
  {
    name: 'Rapla County',
    shortName: '70',
  },
  {
    name: 'Saare County',
    shortName: '74',
  },
  {
    name: 'Tartu County',
    shortName: '78',
  },
  {
    name: 'Valga County',
    shortName: '82',
  },
  {
    name: 'Viljandi County',
    shortName: '84',
  },
  {
    name: 'Võru County',
    shortName: '86',
  },
  {
    name: 'Addis Ababa',
    shortName: 'AA',
  },
  {
    name: 'Afar Region',
    shortName: 'AF',
  },
  {
    name: 'Amhara Region',
    shortName: 'AM',
  },
  {
    name: 'Benishangul-Gumuz Region',
    shortName: 'BE',
  },
  {
    name: 'Dire Dawa',
    shortName: 'DD',
  },
  {
    name: 'Gambela Region',
    shortName: 'GA',
  },
  {
    name: 'Harari Region',
    shortName: 'HA',
  },
  {
    name: 'Oromia Region',
    shortName: 'OR',
  },
  {
    name: 'Somali Region',
    shortName: 'SO',
  },
  {
    name: "Southern Nations, Nationalities, and Peoples' Region",
    shortName: 'SN',
  },
  {
    name: 'Tigray Region',
    shortName: 'TI',
  },
  {
    name: 'Ba',
    shortName: '01',
  },
  {
    name: 'Bua',
    shortName: '02',
  },
  {
    name: 'Cakaudrove',
    shortName: '03',
  },
  {
    name: 'Central Division',
    shortName: 'C',
  },
  {
    name: 'Eastern Division',
    shortName: 'E',
  },
  {
    name: 'Kadavu',
    shortName: '04',
  },
  {
    name: 'Lau',
    shortName: '05',
  },
  {
    name: 'Lomaiviti',
    shortName: '06',
  },
  {
    name: 'Macuata',
    shortName: '07',
  },
  {
    name: 'Nadroga-Navosa',
    shortName: '08',
  },
  {
    name: 'Naitasiri',
    shortName: '09',
  },
  {
    name: 'Namosi',
    shortName: '10',
  },
  {
    name: 'Northern Division',
    shortName: 'N',
  },
  {
    name: 'Ra',
    shortName: '11',
  },
  {
    name: 'Rewa',
    shortName: '12',
  },
  {
    name: 'Rotuma',
    shortName: 'R',
  },
  {
    name: 'Serua',
    shortName: '13',
  },
  {
    name: 'Tailevu',
    shortName: '14',
  },
  {
    name: 'Western Division',
    shortName: 'W',
  },
  {
    name: 'Åland Islands',
    shortName: '01',
  },
  {
    name: 'Central Finland',
    shortName: '08',
  },
  {
    name: 'Central Ostrobothnia',
    shortName: '07',
  },
  {
    name: 'Finland Proper',
    shortName: '19',
  },
  {
    name: 'Kainuu',
    shortName: '05',
  },
  {
    name: 'Kymenlaakso',
    shortName: '09',
  },
  {
    name: 'Lapland',
    shortName: '10',
  },
  {
    name: 'North Karelia',
    shortName: '13',
  },
  {
    name: 'Northern Ostrobothnia',
    shortName: '14',
  },
  {
    name: 'Northern Savonia',
    shortName: '15',
  },
  {
    name: 'Ostrobothnia',
    shortName: '12',
  },
  {
    name: 'Päijänne Tavastia',
    shortName: '16',
  },
  {
    name: 'Pirkanmaa',
    shortName: '11',
  },
  {
    name: 'Satakunta',
    shortName: '17',
  },
  {
    name: 'South Karelia',
    shortName: '02',
  },
  {
    name: 'Southern Ostrobothnia',
    shortName: '03',
  },
  {
    name: 'Southern Savonia',
    shortName: '04',
  },
  {
    name: 'Tavastia Proper',
    shortName: '06',
  },
  {
    name: 'Uusimaa',
    shortName: '18',
  },
  {
    name: 'Ain',
    shortName: '01',
  },
  {
    name: 'Aisne',
    shortName: '02',
  },
  {
    name: 'Allier',
    shortName: '03',
  },
  {
    name: 'Alpes-de-Haute-Provence',
    shortName: '04',
  },
  {
    name: 'Alpes-Maritimes',
    shortName: '06',
  },
  {
    name: 'Alsace',
    shortName: '6AE',
  },
  {
    name: 'Ardèche',
    shortName: '07',
  },
  {
    name: 'Ardennes',
    shortName: '08',
  },
  {
    name: 'Ariège',
    shortName: '09',
  },
  {
    name: 'Aube',
    shortName: '10',
  },
  {
    name: 'Aude',
    shortName: '11',
  },
  {
    name: 'Auvergne-Rhône-Alpes',
    shortName: 'ARA',
  },
  {
    name: 'Aveyron',
    shortName: '12',
  },
  {
    name: 'Bas-Rhin',
    shortName: '67',
  },
  {
    name: 'Bouches-du-Rhône',
    shortName: '13',
  },
  {
    name: 'Bourgogne-Franche-Comté',
    shortName: 'BFC',
  },
  {
    name: 'Bretagne',
    shortName: 'BRE',
  },
  {
    name: 'Calvados',
    shortName: '14',
  },
  {
    name: 'Cantal',
    shortName: '15',
  },
  {
    name: 'Centre-Val de Loire',
    shortName: 'CVL',
  },
  {
    name: 'Charente',
    shortName: '16',
  },
  {
    name: 'Charente-Maritime',
    shortName: '17',
  },
  {
    name: 'Cher',
    shortName: '18',
  },
  {
    name: 'Clipperton',
    shortName: 'CP',
  },
  {
    name: 'Corrèze',
    shortName: '19',
  },
  {
    name: 'Corse',
    shortName: '20R',
  },
  {
    name: 'Corse-du-Sud',
    shortName: '2A',
  },
  {
    name: "Côte-d'Or",
    shortName: '21',
  },
  {
    name: "Côtes-d'Armor",
    shortName: '22',
  },
  {
    name: 'Creuse',
    shortName: '23',
  },
  {
    name: 'Deux-Sèvres',
    shortName: '79',
  },
  {
    name: 'Dordogne',
    shortName: '24',
  },
  {
    name: 'Doubs',
    shortName: '25',
  },
  {
    name: 'Drôme',
    shortName: '26',
  },
  {
    name: 'Essonne',
    shortName: '91',
  },
  {
    name: 'Eure',
    shortName: '27',
  },
  {
    name: 'Eure-et-Loir',
    shortName: '28',
  },
  {
    name: 'Finistère',
    shortName: '29',
  },
  {
    name: 'French Guiana',
    shortName: '973',
  },
  {
    name: 'French Polynesia',
    shortName: 'PF',
  },
  {
    name: 'French Southern and Antarctic Lands',
    shortName: 'TF',
  },
  {
    name: 'Gard',
    shortName: '30',
  },
  {
    name: 'Gers',
    shortName: '32',
  },
  {
    name: 'Gironde',
    shortName: '33',
  },
  {
    name: 'Grand-Est',
    shortName: 'GES',
  },
  {
    name: 'Guadeloupe',
    shortName: '971',
  },
  {
    name: 'Haut-Rhin',
    shortName: '68',
  },
  {
    name: 'Haute-Corse',
    shortName: '2B',
  },
  {
    name: 'Haute-Garonne',
    shortName: '31',
  },
  {
    name: 'Haute-Loire',
    shortName: '43',
  },
  {
    name: 'Haute-Marne',
    shortName: '52',
  },
  {
    name: 'Haute-Saône',
    shortName: '70',
  },
  {
    name: 'Haute-Savoie',
    shortName: '74',
  },
  {
    name: 'Haute-Vienne',
    shortName: '87',
  },
  {
    name: 'Hautes-Alpes',
    shortName: '05',
  },
  {
    name: 'Hautes-Pyrénées',
    shortName: '65',
  },
  {
    name: 'Hauts-de-France',
    shortName: 'HDF',
  },
  {
    name: 'Hauts-de-Seine',
    shortName: '92',
  },
  {
    name: 'Hérault',
    shortName: '34',
  },
  {
    name: 'Île-de-France',
    shortName: 'IDF',
  },
  {
    name: 'Ille-et-Vilaine',
    shortName: '35',
  },
  {
    name: 'Indre',
    shortName: '36',
  },
  {
    name: 'Indre-et-Loire',
    shortName: '37',
  },
  {
    name: 'Isère',
    shortName: '38',
  },
  {
    name: 'Jura',
    shortName: '39',
  },
  {
    name: 'La Réunion',
    shortName: '974',
  },
  {
    name: 'Landes',
    shortName: '40',
  },
  {
    name: 'Loir-et-Cher',
    shortName: '41',
  },
  {
    name: 'Loire',
    shortName: '42',
  },
  {
    name: 'Loire-Atlantique',
    shortName: '44',
  },
  {
    name: 'Loiret',
    shortName: '45',
  },
  {
    name: 'Lot',
    shortName: '46',
  },
  {
    name: 'Lot-et-Garonne',
    shortName: '47',
  },
  {
    name: 'Lozère',
    shortName: '48',
  },
  {
    name: 'Maine-et-Loire',
    shortName: '49',
  },
  {
    name: 'Manche',
    shortName: '50',
  },
  {
    name: 'Marne',
    shortName: '51',
  },
  {
    name: 'Martinique',
    shortName: '972',
  },
  {
    name: 'Mayenne',
    shortName: '53',
  },
  {
    name: 'Mayotte',
    shortName: '976',
  },
  {
    name: 'Métropole de Lyon',
    shortName: '69M',
  },
  {
    name: 'Meurthe-et-Moselle',
    shortName: '54',
  },
  {
    name: 'Meuse',
    shortName: '55',
  },
  {
    name: 'Morbihan',
    shortName: '56',
  },
  {
    name: 'Moselle',
    shortName: '57',
  },
  {
    name: 'Nièvre',
    shortName: '58',
  },
  {
    name: 'Nord',
    shortName: '59',
  },
  {
    name: 'Normandie',
    shortName: 'NOR',
  },
  {
    name: 'Nouvelle-Aquitaine',
    shortName: 'NAQ',
  },
  {
    name: 'Occitanie',
    shortName: 'OCC',
  },
  {
    name: 'Oise',
    shortName: '60',
  },
  {
    name: 'Orne',
    shortName: '61',
  },
  {
    name: 'Paris',
    shortName: '75C',
  },
  {
    name: 'Pas-de-Calais',
    shortName: '62',
  },
  {
    name: 'Pays-de-la-Loire',
    shortName: 'PDL',
  },
  {
    name: 'Provence-Alpes-Côte-d’Azur',
    shortName: 'PAC',
  },
  {
    name: 'Puy-de-Dôme',
    shortName: '63',
  },
  {
    name: 'Pyrénées-Atlantiques',
    shortName: '64',
  },
  {
    name: 'Pyrénées-Orientales',
    shortName: '66',
  },
  {
    name: 'Rhône',
    shortName: '69',
  },
  {
    name: 'Saint Pierre and Miquelon',
    shortName: 'PM',
  },
  {
    name: 'Saint-Barthélemy',
    shortName: 'BL',
  },
  {
    name: 'Saint-Martin',
    shortName: 'MF',
  },
  {
    name: 'Saône-et-Loire',
    shortName: '71',
  },
  {
    name: 'Sarthe',
    shortName: '72',
  },
  {
    name: 'Savoie',
    shortName: '73',
  },
  {
    name: 'Seine-et-Marne',
    shortName: '77',
  },
  {
    name: 'Seine-Maritime',
    shortName: '76',
  },
  {
    name: 'Seine-Saint-Denis',
    shortName: '93',
  },
  {
    name: 'Somme',
    shortName: '80',
  },
  {
    name: 'Tarn',
    shortName: '81',
  },
  {
    name: 'Tarn-et-Garonne',
    shortName: '82',
  },
  {
    name: 'Territoire de Belfort',
    shortName: '90',
  },
  {
    name: "Val-d'Oise",
    shortName: '95',
  },
  {
    name: 'Val-de-Marne',
    shortName: '94',
  },
  {
    name: 'Var',
    shortName: '83',
  },
  {
    name: 'Vaucluse',
    shortName: '84',
  },
  {
    name: 'Vendée',
    shortName: '85',
  },
  {
    name: 'Vienne',
    shortName: '86',
  },
  {
    name: 'Vosges',
    shortName: '88',
  },
  {
    name: 'Wallis and Futuna',
    shortName: 'WF',
  },
  {
    name: 'Yonne',
    shortName: '89',
  },
  {
    name: 'Yvelines',
    shortName: '78',
  },
  {
    name: 'Estuaire Province',
    shortName: '1',
  },
  {
    name: 'Haut-Ogooué Province',
    shortName: '2',
  },
  {
    name: 'Moyen-Ogooué Province',
    shortName: '3',
  },
  {
    name: 'Ngounié Province',
    shortName: '4',
  },
  {
    name: 'Nyanga Province',
    shortName: '5',
  },
  {
    name: 'Ogooué-Ivindo Province',
    shortName: '6',
  },
  {
    name: 'Ogooué-Lolo Province',
    shortName: '7',
  },
  {
    name: 'Ogooué-Maritime Province',
    shortName: '8',
  },
  {
    name: 'Woleu-Ntem Province',
    shortName: '9',
  },
  {
    name: 'Banjul',
    shortName: 'B',
  },
  {
    name: 'Central River Division',
    shortName: 'M',
  },
  {
    name: 'Lower River Division',
    shortName: 'L',
  },
  {
    name: 'North Bank Division',
    shortName: 'N',
  },
  {
    name: 'Upper River Division',
    shortName: 'U',
  },
  {
    name: 'West Coast Division',
    shortName: 'W',
  },
  {
    name: 'Adjara',
    shortName: 'AJ',
  },
  {
    name: 'Autonomous Republic of Abkhazia',
    shortName: 'AB',
  },
  {
    name: 'Guria',
    shortName: 'GU',
  },
  {
    name: 'Imereti',
    shortName: 'IM',
  },
  {
    name: 'Kakheti',
    shortName: 'KA',
  },
  {
    name: 'Khelvachauri Municipality',
    shortName: '29',
  },
  {
    name: 'Kvemo Kartli',
    shortName: 'KK',
  },
  {
    name: 'Mtskheta-Mtianeti',
    shortName: 'MM',
  },
  {
    name: 'Racha-Lechkhumi and Kvemo Svaneti',
    shortName: 'RL',
  },
  {
    name: 'Samegrelo-Zemo Svaneti',
    shortName: 'SZ',
  },
  {
    name: 'Samtskhe-Javakheti',
    shortName: 'SJ',
  },
  {
    name: 'Senaki Municipality',
    shortName: '50',
  },
  {
    name: 'Shida Kartli',
    shortName: 'SK',
  },
  {
    name: 'Tbilisi',
    shortName: 'TB',
  },
  {
    name: 'Baden-Württemberg',
    shortName: 'BW',
  },
  {
    name: 'Bavaria',
    shortName: 'BY',
  },
  {
    name: 'Berlin',
    shortName: 'BE',
  },
  {
    name: 'Brandenburg',
    shortName: 'BB',
  },
  {
    name: 'Bremen',
    shortName: 'HB',
  },
  {
    name: 'Hamburg',
    shortName: 'HH',
  },
  {
    name: 'Hesse',
    shortName: 'HE',
  },
  {
    name: 'Lower Saxony',
    shortName: 'NI',
  },
  {
    name: 'Mecklenburg-Vorpommern',
    shortName: 'MV',
  },
  {
    name: 'North Rhine-Westphalia',
    shortName: 'NW',
  },
  {
    name: 'Rhineland-Palatinate',
    shortName: 'RP',
  },
  {
    name: 'Saarland',
    shortName: 'SL',
  },
  {
    name: 'Saxony',
    shortName: 'SN',
  },
  {
    name: 'Saxony-Anhalt',
    shortName: 'ST',
  },
  {
    name: 'Schleswig-Holstein',
    shortName: 'SH',
  },
  {
    name: 'Thuringia',
    shortName: 'TH',
  },
  {
    name: 'Ahafo',
    shortName: 'AF',
  },
  {
    name: 'Ashanti',
    shortName: 'AH',
  },
  {
    name: 'Bono',
    shortName: 'BO',
  },
  {
    name: 'Bono East',
    shortName: 'BE',
  },
  {
    name: 'Central',
    shortName: 'CP',
  },
  {
    name: 'Eastern',
    shortName: 'EP',
  },
  {
    name: 'Greater Accra',
    shortName: 'AA',
  },
  {
    name: 'North East',
    shortName: 'NE',
  },
  {
    name: 'Northern',
    shortName: 'NP',
  },
  {
    name: 'Oti',
    shortName: 'OT',
  },
  {
    name: 'Savannah',
    shortName: 'SV',
  },
  {
    name: 'Upper East',
    shortName: 'UE',
  },
  {
    name: 'Upper West',
    shortName: 'UW',
  },
  {
    name: 'Volta',
    shortName: 'TV',
  },
  {
    name: 'Western',
    shortName: 'WP',
  },
  {
    name: 'Western North',
    shortName: 'WN',
  },
  {
    name: 'Achaea Regional Unit',
    shortName: '13',
  },
  {
    name: 'Aetolia-Acarnania Regional Unit',
    shortName: '01',
  },
  {
    name: 'Arcadia Prefecture',
    shortName: '12',
  },
  {
    name: 'Argolis Regional Unit',
    shortName: '11',
  },
  {
    name: 'Attica Region',
    shortName: 'I',
  },
  {
    name: 'Boeotia Regional Unit',
    shortName: '03',
  },
  {
    name: 'Central Greece Region',
    shortName: 'H',
  },
  {
    name: 'Central Macedonia',
    shortName: 'B',
  },
  {
    name: 'Chania Regional Unit',
    shortName: '94',
  },
  {
    name: 'Corfu Prefecture',
    shortName: '22',
  },
  {
    name: 'Corinthia Regional Unit',
    shortName: '15',
  },
  {
    name: 'Crete Region',
    shortName: 'M',
  },
  {
    name: 'Drama Regional Unit',
    shortName: '52',
  },
  {
    name: 'East Attica Regional Unit',
    shortName: 'A2',
  },
  {
    name: 'East Macedonia and Thrace',
    shortName: 'A',
  },
  {
    name: 'Epirus Region',
    shortName: 'D',
  },
  {
    name: 'Euboea',
    shortName: '04',
  },
  {
    name: 'Grevena Prefecture',
    shortName: '51',
  },
  {
    name: 'Imathia Regional Unit',
    shortName: '53',
  },
  {
    name: 'Ioannina Regional Unit',
    shortName: '33',
  },
  {
    name: 'Ionian Islands Region',
    shortName: 'F',
  },
  {
    name: 'Karditsa Regional Unit',
    shortName: '41',
  },
  {
    name: 'Kastoria Regional Unit',
    shortName: '56',
  },
  {
    name: 'Kefalonia Prefecture',
    shortName: '23',
  },
  {
    name: 'Kilkis Regional Unit',
    shortName: '57',
  },
  {
    name: 'Kozani Prefecture',
    shortName: '58',
  },
  {
    name: 'Laconia',
    shortName: '16',
  },
  {
    name: 'Larissa Prefecture',
    shortName: '42',
  },
  {
    name: 'Lefkada Regional Unit',
    shortName: '24',
  },
  {
    name: 'Pella Regional Unit',
    shortName: '59',
  },
  {
    name: 'Peloponnese Region',
    shortName: 'J',
  },
  {
    name: 'Phthiotis Prefecture',
    shortName: '06',
  },
  {
    name: 'Preveza Prefecture',
    shortName: '34',
  },
  {
    name: 'Serres Prefecture',
    shortName: '62',
  },
  {
    name: 'South Aegean',
    shortName: 'L',
  },
  {
    name: 'Thessaloniki Regional Unit',
    shortName: '54',
  },
  {
    name: 'West Greece Region',
    shortName: 'G',
  },
  {
    name: 'West Macedonia Region',
    shortName: 'C',
  },
  {
    name: 'Carriacou and Petite Martinique',
    shortName: '10',
  },
  {
    name: 'Saint Andrew Parish',
    shortName: '01',
  },
  {
    name: 'Saint David Parish',
    shortName: '02',
  },
  {
    name: 'Saint George Parish',
    shortName: '03',
  },
  {
    name: 'Saint John Parish',
    shortName: '04',
  },
  {
    name: 'Saint Mark Parish',
    shortName: '05',
  },
  {
    name: 'Saint Patrick Parish',
    shortName: '06',
  },
  {
    name: 'Alta Verapaz Department',
    shortName: 'AV',
  },
  {
    name: 'Baja Verapaz Department',
    shortName: 'BV',
  },
  {
    name: 'Chimaltenango Department',
    shortName: 'CM',
  },
  {
    name: 'Chiquimula Department',
    shortName: 'CQ',
  },
  {
    name: 'El Progreso Department',
    shortName: 'PR',
  },
  {
    name: 'Escuintla Department',
    shortName: 'ES',
  },
  {
    name: 'Guatemala Department',
    shortName: 'GU',
  },
  {
    name: 'Huehuetenango Department',
    shortName: 'HU',
  },
  {
    name: 'Izabal Department',
    shortName: 'IZ',
  },
  {
    name: 'Jalapa Department',
    shortName: 'JA',
  },
  {
    name: 'Jutiapa Department',
    shortName: 'JU',
  },
  {
    name: 'Petén Department',
    shortName: 'PE',
  },
  {
    name: 'Quetzaltenango Department',
    shortName: 'QZ',
  },
  {
    name: 'Quiché Department',
    shortName: 'QC',
  },
  {
    name: 'Retalhuleu Department',
    shortName: 'RE',
  },
  {
    name: 'Sacatepéquez Department',
    shortName: 'SA',
  },
  {
    name: 'San Marcos Department',
    shortName: 'SM',
  },
  {
    name: 'Santa Rosa Department',
    shortName: 'SR',
  },
  {
    name: 'Sololá Department',
    shortName: 'SO',
  },
  {
    name: 'Suchitepéquez Department',
    shortName: 'SU',
  },
  {
    name: 'Totonicapán Department',
    shortName: 'TO',
  },
  {
    name: 'Beyla Prefecture',
    shortName: 'BE',
  },
  {
    name: 'Boffa Prefecture',
    shortName: 'BF',
  },
  {
    name: 'Boké Prefecture',
    shortName: 'BK',
  },
  {
    name: 'Boké Region',
    shortName: 'B',
  },
  {
    name: 'Conakry',
    shortName: 'C',
  },
  {
    name: 'Coyah Prefecture',
    shortName: 'CO',
  },
  {
    name: 'Dabola Prefecture',
    shortName: 'DB',
  },
  {
    name: 'Dalaba Prefecture',
    shortName: 'DL',
  },
  {
    name: 'Dinguiraye Prefecture',
    shortName: 'DI',
  },
  {
    name: 'Dubréka Prefecture',
    shortName: 'DU',
  },
  {
    name: 'Faranah Prefecture',
    shortName: 'FA',
  },
  {
    name: 'Forécariah Prefecture',
    shortName: 'FO',
  },
  {
    name: 'Fria Prefecture',
    shortName: 'FR',
  },
  {
    name: 'Gaoual Prefecture',
    shortName: 'GA',
  },
  {
    name: 'Guéckédou Prefecture',
    shortName: 'GU',
  },
  {
    name: 'Kankan Prefecture',
    shortName: 'KA',
  },
  {
    name: 'Kankan Region',
    shortName: 'K',
  },
  {
    name: 'Kérouané Prefecture',
    shortName: 'KE',
  },
  {
    name: 'Kindia Prefecture',
    shortName: 'KD',
  },
  {
    name: 'Kindia Region',
    shortName: 'D',
  },
  {
    name: 'Kissidougou Prefecture',
    shortName: 'KS',
  },
  {
    name: 'Koubia Prefecture',
    shortName: 'KB',
  },
  {
    name: 'Koundara Prefecture',
    shortName: 'KN',
  },
  {
    name: 'Kouroussa Prefecture',
    shortName: 'KO',
  },
  {
    name: 'Labé Prefecture',
    shortName: 'LA',
  },
  {
    name: 'Labé Region',
    shortName: 'L',
  },
  {
    name: 'Lélouma Prefecture',
    shortName: 'LE',
  },
  {
    name: 'Lola Prefecture',
    shortName: 'LO',
  },
  {
    name: 'Macenta Prefecture',
    shortName: 'MC',
  },
  {
    name: 'Mali Prefecture',
    shortName: 'ML',
  },
  {
    name: 'Mamou Prefecture',
    shortName: 'MM',
  },
  {
    name: 'Mamou Region',
    shortName: 'M',
  },
  {
    name: 'Mandiana Prefecture',
    shortName: 'MD',
  },
  {
    name: 'Nzérékoré Prefecture',
    shortName: 'NZ',
  },
  {
    name: 'Nzérékoré Region',
    shortName: 'N',
  },
  {
    name: 'Pita Prefecture',
    shortName: 'PI',
  },
  {
    name: 'Siguiri Prefecture',
    shortName: 'SI',
  },
  {
    name: 'Télimélé Prefecture',
    shortName: 'TE',
  },
  {
    name: 'Tougué Prefecture',
    shortName: 'TO',
  },
  {
    name: 'Yomou Prefecture',
    shortName: 'YO',
  },
  {
    name: 'Bafatá',
    shortName: 'BA',
  },
  {
    name: 'Biombo Region',
    shortName: 'BM',
  },
  {
    name: 'Bolama Region',
    shortName: 'BL',
  },
  {
    name: 'Cacheu Region',
    shortName: 'CA',
  },
  {
    name: 'Gabú Region',
    shortName: 'GA',
  },
  {
    name: 'Leste Province',
    shortName: 'L',
  },
  {
    name: 'Norte Province',
    shortName: 'N',
  },
  {
    name: 'Oio Region',
    shortName: 'OI',
  },
  {
    name: 'Quinara Region',
    shortName: 'QU',
  },
  {
    name: 'Sul Province',
    shortName: 'S',
  },
  {
    name: 'Tombali Region',
    shortName: 'TO',
  },
  {
    name: 'Barima-Waini',
    shortName: 'BA',
  },
  {
    name: 'Cuyuni-Mazaruni',
    shortName: 'CU',
  },
  {
    name: 'Demerara-Mahaica',
    shortName: 'DE',
  },
  {
    name: 'East Berbice-Corentyne',
    shortName: 'EB',
  },
  {
    name: 'Essequibo Islands-West Demerara',
    shortName: 'ES',
  },
  {
    name: 'Mahaica-Berbice',
    shortName: 'MA',
  },
  {
    name: 'Pomeroon-Supenaam',
    shortName: 'PM',
  },
  {
    name: 'Potaro-Siparuni',
    shortName: 'PT',
  },
  {
    name: 'Upper Demerara-Berbice',
    shortName: 'UD',
  },
  {
    name: 'Upper Takutu-Upper Essequibo',
    shortName: 'UT',
  },
  {
    name: 'Artibonite',
    shortName: 'AR',
  },
  {
    name: 'Centre',
    shortName: 'CE',
  },
  {
    name: "Grand'Anse",
    shortName: 'GA',
  },
  {
    name: 'Nippes',
    shortName: 'NI',
  },
  {
    name: 'Nord',
    shortName: 'ND',
  },
  {
    name: 'Nord-Est',
    shortName: 'NE',
  },
  {
    name: 'Nord-Ouest',
    shortName: 'NO',
  },
  {
    name: 'Ouest',
    shortName: 'OU',
  },
  {
    name: 'Sud',
    shortName: 'SD',
  },
  {
    name: 'Sud-Est',
    shortName: 'SE',
  },
  {
    name: 'Atlántida Department',
    shortName: 'AT',
  },
  {
    name: 'Bay Islands Department',
    shortName: 'IB',
  },
  {
    name: 'Choluteca Department',
    shortName: 'CH',
  },
  {
    name: 'Colón Department',
    shortName: 'CL',
  },
  {
    name: 'Comayagua Department',
    shortName: 'CM',
  },
  {
    name: 'Copán Department',
    shortName: 'CP',
  },
  {
    name: 'Cortés Department',
    shortName: 'CR',
  },
  {
    name: 'El Paraíso Department',
    shortName: 'EP',
  },
  {
    name: 'Francisco Morazán Department',
    shortName: 'FM',
  },
  {
    name: 'Gracias a Dios Department',
    shortName: 'GD',
  },
  {
    name: 'Intibucá Department',
    shortName: 'IN',
  },
  {
    name: 'La Paz Department',
    shortName: 'LP',
  },
  {
    name: 'Lempira Department',
    shortName: 'LE',
  },
  {
    name: 'Ocotepeque Department',
    shortName: 'OC',
  },
  {
    name: 'Olancho Department',
    shortName: 'OL',
  },
  {
    name: 'Santa Bárbara Department',
    shortName: 'SB',
  },
  {
    name: 'Valle Department',
    shortName: 'VA',
  },
  {
    name: 'Yoro Department',
    shortName: 'YO',
  },
  {
    name: 'Central and Western District',
    shortName: 'HCW',
  },
  {
    name: 'Eastern',
    shortName: 'HEA',
  },
  {
    name: 'Islands District',
    shortName: 'NIS',
  },
  {
    name: 'Kowloon City',
    shortName: 'KKC',
  },
  {
    name: 'Kwai Tsing',
    shortName: 'NKT',
  },
  {
    name: 'Kwun Tong',
    shortName: 'KKT',
  },
  {
    name: 'North',
    shortName: 'NNO',
  },
  {
    name: 'Sai Kung District',
    shortName: 'NSK',
  },
  {
    name: 'Sha Tin',
    shortName: 'NST',
  },
  {
    name: 'Sham Shui Po',
    shortName: 'KSS',
  },
  {
    name: 'Southern',
    shortName: 'HSO',
  },
  {
    name: 'Tai Po District',
    shortName: 'NTP',
  },
  {
    name: 'Tsuen Wan District',
    shortName: 'NTW',
  },
  {
    name: 'Tuen Mun',
    shortName: 'NTM',
  },
  {
    name: 'Wan Chai',
    shortName: 'HWC',
  },
  {
    name: 'Wong Tai Sin',
    shortName: 'KWT',
  },
  {
    name: 'Yau Tsim Mong',
    shortName: 'KYT',
  },
  {
    name: 'Yuen Long District',
    shortName: 'NYL',
  },
  {
    name: 'Bács-Kiskun',
    shortName: 'BK',
  },
  {
    name: 'Baranya',
    shortName: 'BA',
  },
  {
    name: 'Békés',
    shortName: 'BE',
  },
  {
    name: 'Békéscsaba',
    shortName: 'BC',
  },
  {
    name: 'Borsod-Abaúj-Zemplén',
    shortName: 'BZ',
  },
  {
    name: 'Budapest',
    shortName: 'BU',
  },
  {
    name: 'Csongrád County',
    shortName: 'CS',
  },
  {
    name: 'Debrecen',
    shortName: 'DE',
  },
  {
    name: 'Dunaújváros',
    shortName: 'DU',
  },
  {
    name: 'Eger',
    shortName: 'EG',
  },
  {
    name: 'Érd',
    shortName: 'ER',
  },
  {
    name: 'Fejér County',
    shortName: 'FE',
  },
  {
    name: 'Győr',
    shortName: 'GY',
  },
  {
    name: 'Győr-Moson-Sopron County',
    shortName: 'GS',
  },
  {
    name: 'Hajdú-Bihar County',
    shortName: 'HB',
  },
  {
    name: 'Heves County',
    shortName: 'HE',
  },
  {
    name: 'Hódmezővásárhely',
    shortName: 'HV',
  },
  {
    name: 'Jász-Nagykun-Szolnok County',
    shortName: 'JN',
  },
  {
    name: 'Kaposvár',
    shortName: 'KV',
  },
  {
    name: 'Kecskemét',
    shortName: 'KM',
  },
  {
    name: 'Komárom-Esztergom',
    shortName: 'KE',
  },
  {
    name: 'Miskolc',
    shortName: 'MI',
  },
  {
    name: 'Nagykanizsa',
    shortName: 'NK',
  },
  {
    name: 'Nógrád County',
    shortName: 'NO',
  },
  {
    name: 'Nyíregyháza',
    shortName: 'NY',
  },
  {
    name: 'Pécs',
    shortName: 'PS',
  },
  {
    name: 'Pest County',
    shortName: 'PE',
  },
  {
    name: 'Salgótarján',
    shortName: 'ST',
  },
  {
    name: 'Somogy County',
    shortName: 'SO',
  },
  {
    name: 'Sopron',
    shortName: 'SN',
  },
  {
    name: 'Szabolcs-Szatmár-Bereg County',
    shortName: 'SZ',
  },
  {
    name: 'Szeged',
    shortName: 'SD',
  },
  {
    name: 'Székesfehérvár',
    shortName: 'SF',
  },
  {
    name: 'Szekszárd',
    shortName: 'SS',
  },
  {
    name: 'Szolnok',
    shortName: 'SK',
  },
  {
    name: 'Szombathely',
    shortName: 'SH',
  },
  {
    name: 'Tatabánya',
    shortName: 'TB',
  },
  {
    name: 'Tolna County',
    shortName: 'TO',
  },
  {
    name: 'Vas County',
    shortName: 'VA',
  },
  {
    name: 'Veszprém',
    shortName: 'VM',
  },
  {
    name: 'Veszprém County',
    shortName: 'VE',
  },
  {
    name: 'Zala County',
    shortName: 'ZA',
  },
  {
    name: 'Zalaegerszeg',
    shortName: 'ZE',
  },
  {
    name: 'Capital Region',
    shortName: '1',
  },
  {
    name: 'Eastern Region',
    shortName: '7',
  },
  {
    name: 'Northeastern Region',
    shortName: '6',
  },
  {
    name: 'Northwestern Region',
    shortName: '5',
  },
  {
    name: 'Southern Peninsula Region',
    shortName: '2',
  },
  {
    name: 'Southern Region',
    shortName: '8',
  },
  {
    name: 'Western Region',
    shortName: '3',
  },
  {
    name: 'Westfjords',
    shortName: '4',
  },
  {
    name: 'Andaman and Nicobar Islands',
    shortName: 'AN',
  },
  {
    name: 'Andhra Pradesh',
    shortName: 'AP',
  },
  {
    name: 'Arunachal Pradesh',
    shortName: 'AR',
  },
  {
    name: 'Assam',
    shortName: 'AS',
  },
  {
    name: 'Bihar',
    shortName: 'BR',
  },
  {
    name: 'Chandigarh',
    shortName: 'CH',
  },
  {
    name: 'Chhattisgarh',
    shortName: 'CT',
  },
  {
    name: 'Dadra and Nagar Haveli and Daman and Diu',
    shortName: 'DH',
  },
  {
    name: 'Delhi',
    shortName: 'DL',
  },
  {
    name: 'Goa',
    shortName: 'GA',
  },
  {
    name: 'Gujarat',
    shortName: 'GJ',
  },
  {
    name: 'Haryana',
    shortName: 'HR',
  },
  {
    name: 'Himachal Pradesh',
    shortName: 'HP',
  },
  {
    name: 'Jammu and Kashmir',
    shortName: 'JK',
  },
  {
    name: 'Jharkhand',
    shortName: 'JH',
  },
  {
    name: 'Karnataka',
    shortName: 'KA',
  },
  {
    name: 'Kerala',
    shortName: 'KL',
  },
  {
    name: 'Ladakh',
    shortName: 'LA',
  },
  {
    name: 'Lakshadweep',
    shortName: 'LD',
  },
  {
    name: 'Madhya Pradesh',
    shortName: 'MP',
  },
  {
    name: 'Maharashtra',
    shortName: 'MH',
  },
  {
    name: 'Manipur',
    shortName: 'MN',
  },
  {
    name: 'Meghalaya',
    shortName: 'ML',
  },
  {
    name: 'Mizoram',
    shortName: 'MZ',
  },
  {
    name: 'Nagaland',
    shortName: 'NL',
  },
  {
    name: 'Odisha',
    shortName: 'OR',
  },
  {
    name: 'Puducherry',
    shortName: 'PY',
  },
  {
    name: 'Punjab',
    shortName: 'PB',
  },
  {
    name: 'Rajasthan',
    shortName: 'RJ',
  },
  {
    name: 'Sikkim',
    shortName: 'SK',
  },
  {
    name: 'Tamil Nadu',
    shortName: 'TN',
  },
  {
    name: 'Telangana',
    shortName: 'TG',
  },
  {
    name: 'Tripura',
    shortName: 'TR',
  },
  {
    name: 'Uttar Pradesh',
    shortName: 'UP',
  },
  {
    name: 'Uttarakhand',
    shortName: 'UT',
  },
  {
    name: 'West Bengal',
    shortName: 'WB',
  },
  {
    name: 'Aceh',
    shortName: 'AC',
  },
  {
    name: 'Bali',
    shortName: 'BA',
  },
  {
    name: 'Banten',
    shortName: 'BT',
  },
  {
    name: 'Bengkulu',
    shortName: 'BE',
  },
  {
    name: 'DI Yogyakarta',
    shortName: 'YO',
  },
  {
    name: 'DKI Jakarta',
    shortName: 'JK',
  },
  {
    name: 'Gorontalo',
    shortName: 'GO',
  },
  {
    name: 'Jambi',
    shortName: 'JA',
  },
  {
    name: 'Jawa Barat',
    shortName: 'JB',
  },
  {
    name: 'Jawa Tengah',
    shortName: 'JT',
  },
  {
    name: 'Jawa Timur',
    shortName: 'JI',
  },
  {
    name: 'Kalimantan Barat',
    shortName: 'KB',
  },
  {
    name: 'Kalimantan Selatan',
    shortName: 'KS',
  },
  {
    name: 'Kalimantan Tengah',
    shortName: 'KT',
  },
  {
    name: 'Kalimantan Timur',
    shortName: 'KI',
  },
  {
    name: 'Kalimantan Utara',
    shortName: 'KU',
  },
  {
    name: 'Kepulauan Bangka Belitung',
    shortName: 'BB',
  },
  {
    name: 'Kepulauan Riau',
    shortName: 'KR',
  },
  {
    name: 'Lampung',
    shortName: 'LA',
  },
  {
    name: 'Maluku',
    shortName: 'MA',
  },
  {
    name: 'Maluku Utara',
    shortName: 'MU',
  },
  {
    name: 'Nusa Tenggara Barat',
    shortName: 'NB',
  },
  {
    name: 'Nusa Tenggara Timur',
    shortName: 'NT',
  },
  {
    name: 'Papua',
    shortName: 'PA',
  },
  {
    name: 'Papua Barat',
    shortName: 'PB',
  },
  {
    name: 'Riau',
    shortName: 'RI',
  },
  {
    name: 'Sulawesi Barat',
    shortName: 'SR',
  },
  {
    name: 'Sulawesi Selatan',
    shortName: 'SN',
  },
  {
    name: 'Sulawesi Tengah',
    shortName: 'ST',
  },
  {
    name: 'Sulawesi Tenggara',
    shortName: 'SG',
  },
  {
    name: 'Sulawesi Utara',
    shortName: 'SA',
  },
  {
    name: 'Sumatera Barat',
    shortName: 'SB',
  },
  {
    name: 'Sumatera Selatan',
    shortName: 'SS',
  },
  {
    name: 'Sumatera Utara',
    shortName: 'SU',
  },
  {
    name: 'Alborz',
    shortName: '30',
  },
  {
    name: 'Ardabil',
    shortName: '24',
  },
  {
    name: 'Bushehr',
    shortName: '18',
  },
  {
    name: 'Chaharmahal and Bakhtiari',
    shortName: '14',
  },
  {
    name: 'East Azerbaijan',
    shortName: '03',
  },
  {
    name: 'Fars',
    shortName: '07',
  },
  {
    name: 'Gilan',
    shortName: '01',
  },
  {
    name: 'Golestan',
    shortName: '27',
  },
  {
    name: 'Hamadan',
    shortName: '13',
  },
  {
    name: 'Hormozgan',
    shortName: '22',
  },
  {
    name: 'Ilam',
    shortName: '16',
  },
  {
    name: 'Isfahan',
    shortName: '10',
  },
  {
    name: 'Kerman',
    shortName: '08',
  },
  {
    name: 'Kermanshah',
    shortName: '05',
  },
  {
    name: 'Khuzestan',
    shortName: '06',
  },
  {
    name: 'Kohgiluyeh and Boyer-Ahmad',
    shortName: '17',
  },
  {
    name: 'Kurdistan',
    shortName: '12',
  },
  {
    name: 'Lorestan',
    shortName: '15',
  },
  {
    name: 'Markazi',
    shortName: '00',
  },
  {
    name: 'Mazandaran',
    shortName: '02',
  },
  {
    name: 'North Khorasan',
    shortName: '28',
  },
  {
    name: 'Qazvin',
    shortName: '26',
  },
  {
    name: 'Qom',
    shortName: '25',
  },
  {
    name: 'Razavi Khorasan',
    shortName: '09',
  },
  {
    name: 'Semnan',
    shortName: '20',
  },
  {
    name: 'Sistan and Baluchestan',
    shortName: '11',
  },
  {
    name: 'South Khorasan',
    shortName: '29',
  },
  {
    name: 'Tehran',
    shortName: '23',
  },
  {
    name: 'West Azarbaijan',
    shortName: '04',
  },
  {
    name: 'Yazd',
    shortName: '21',
  },
  {
    name: 'Zanjan',
    shortName: '19',
  },
  {
    name: 'Al Anbar',
    shortName: 'AN',
  },
  {
    name: 'Al Muthanna',
    shortName: 'MU',
  },
  {
    name: 'Al-Qādisiyyah',
    shortName: 'QA',
  },
  {
    name: 'Babylon',
    shortName: 'BB',
  },
  {
    name: 'Baghdad',
    shortName: 'BG',
  },
  {
    name: 'Basra',
    shortName: 'BA',
  },
  {
    name: 'Dhi Qar',
    shortName: 'DQ',
  },
  {
    name: 'Diyala',
    shortName: 'DI',
  },
  {
    name: 'Dohuk',
    shortName: 'DA',
  },
  {
    name: 'Erbil',
    shortName: 'AR',
  },
  {
    name: 'Karbala',
    shortName: 'KA',
  },
  {
    name: 'Kirkuk',
    shortName: 'KI',
  },
  {
    name: 'Maysan',
    shortName: 'MA',
  },
  {
    name: 'Najaf',
    shortName: 'NA',
  },
  {
    name: 'Nineveh',
    shortName: 'NI',
  },
  {
    name: 'Saladin',
    shortName: 'SD',
  },
  {
    name: 'Sulaymaniyah',
    shortName: 'SU',
  },
  {
    name: 'Wasit',
    shortName: 'WA',
  },
  {
    name: 'Carlow',
    shortName: 'CW',
  },
  {
    name: 'Cavan',
    shortName: 'CN',
  },
  {
    name: 'Clare',
    shortName: 'CE',
  },
  {
    name: 'Connacht',
    shortName: 'C',
  },
  {
    name: 'Cork',
    shortName: 'CO',
  },
  {
    name: 'Donegal',
    shortName: 'DL',
  },
  {
    name: 'Dublin',
    shortName: 'D',
  },
  {
    name: 'Galway',
    shortName: 'G',
  },
  {
    name: 'Kerry',
    shortName: 'KY',
  },
  {
    name: 'Kildare',
    shortName: 'KE',
  },
  {
    name: 'Kilkenny',
    shortName: 'KK',
  },
  {
    name: 'Laois',
    shortName: 'LS',
  },
  {
    name: 'Leinster',
    shortName: 'L',
  },
  {
    name: 'Limerick',
    shortName: 'LK',
  },
  {
    name: 'Longford',
    shortName: 'LD',
  },
  {
    name: 'Louth',
    shortName: 'LH',
  },
  {
    name: 'Mayo',
    shortName: 'MO',
  },
  {
    name: 'Meath',
    shortName: 'MH',
  },
  {
    name: 'Monaghan',
    shortName: 'MN',
  },
  {
    name: 'Munster',
    shortName: 'M',
  },
  {
    name: 'Offaly',
    shortName: 'OY',
  },
  {
    name: 'Roscommon',
    shortName: 'RN',
  },
  {
    name: 'Sligo',
    shortName: 'SO',
  },
  {
    name: 'Tipperary',
    shortName: 'TA',
  },
  {
    name: 'Ulster',
    shortName: 'U',
  },
  {
    name: 'Waterford',
    shortName: 'WD',
  },
  {
    name: 'Westmeath',
    shortName: 'WH',
  },
  {
    name: 'Wexford',
    shortName: 'WX',
  },
  {
    name: 'Wicklow',
    shortName: 'WW',
  },
  {
    name: 'Central District',
    shortName: 'M',
  },
  {
    name: 'Haifa District',
    shortName: 'HA',
  },
  {
    name: 'Jerusalem District',
    shortName: 'JM',
  },
  {
    name: 'Northern District',
    shortName: 'Z',
  },
  {
    name: 'Southern District',
    shortName: 'D',
  },
  {
    name: 'Tel Aviv District',
    shortName: 'TA',
  },
  {
    name: 'Abruzzo',
    shortName: '65',
  },
  {
    name: 'Agrigento',
    shortName: 'AG',
  },
  {
    name: 'Alessandria',
    shortName: 'AL',
  },
  {
    name: 'Ancona',
    shortName: 'AN',
  },
  {
    name: 'Aosta Valley',
    shortName: '23',
  },
  {
    name: 'Apulia',
    shortName: '75',
  },
  {
    name: 'Ascoli Piceno',
    shortName: 'AP',
  },
  {
    name: 'Asti',
    shortName: 'AT',
  },
  {
    name: 'Avellino',
    shortName: 'AV',
  },
  {
    name: 'Barletta-Andria-Trani',
    shortName: 'BT',
  },
  {
    name: 'Basilicata',
    shortName: '77',
  },
  {
    name: 'Belluno',
    shortName: 'BL',
  },
  {
    name: 'Benevento',
    shortName: 'BN',
  },
  {
    name: 'Bergamo',
    shortName: 'BG',
  },
  {
    name: 'Biella',
    shortName: 'BI',
  },
  {
    name: 'Brescia',
    shortName: 'BS',
  },
  {
    name: 'Brindisi',
    shortName: 'BR',
  },
  {
    name: 'Calabria',
    shortName: '78',
  },
  {
    name: 'Caltanissetta',
    shortName: 'CL',
  },
  {
    name: 'Campania',
    shortName: '72',
  },
  {
    name: 'Campobasso',
    shortName: 'CB',
  },
  {
    name: 'Caserta',
    shortName: 'CE',
  },
  {
    name: 'Catanzaro',
    shortName: 'CZ',
  },
  {
    name: 'Chieti',
    shortName: 'CH',
  },
  {
    name: 'Como',
    shortName: 'CO',
  },
  {
    name: 'Cosenza',
    shortName: 'CS',
  },
  {
    name: 'Cremona',
    shortName: 'CR',
  },
  {
    name: 'Crotone',
    shortName: 'KR',
  },
  {
    name: 'Cuneo',
    shortName: 'CN',
  },
  {
    name: 'Emilia-Romagna',
    shortName: '45',
  },
  {
    name: 'Enna',
    shortName: 'EN',
  },
  {
    name: 'Fermo',
    shortName: 'FM',
  },
  {
    name: 'Ferrara',
    shortName: 'FE',
  },
  {
    name: 'Foggia',
    shortName: 'FG',
  },
  {
    name: 'Forlì-Cesena',
    shortName: 'FC',
  },
  {
    name: 'Friuli–Venezia Giulia',
    shortName: '36',
  },
  {
    name: 'Frosinone',
    shortName: 'FR',
  },
  {
    name: 'Gorizia',
    shortName: 'GO',
  },
  {
    name: 'Grosseto',
    shortName: 'GR',
  },
  {
    name: 'Imperia',
    shortName: 'IM',
  },
  {
    name: 'Isernia',
    shortName: 'IS',
  },
  {
    name: "L'Aquila",
    shortName: 'AQ',
  },
  {
    name: 'La Spezia',
    shortName: 'SP',
  },
  {
    name: 'Latina',
    shortName: 'LT',
  },
  {
    name: 'Lazio',
    shortName: '62',
  },
  {
    name: 'Lecce',
    shortName: 'LE',
  },
  {
    name: 'Lecco',
    shortName: 'LC',
  },
  {
    name: 'Liguria',
    shortName: '42',
  },
  {
    name: 'Livorno',
    shortName: 'LI',
  },
  {
    name: 'Lodi',
    shortName: 'LO',
  },
  {
    name: 'Lombardy',
    shortName: '25',
  },
  {
    name: 'Lucca',
    shortName: 'LU',
  },
  {
    name: 'Macerata',
    shortName: 'MC',
  },
  {
    name: 'Mantua',
    shortName: 'MN',
  },
  {
    name: 'Marche',
    shortName: '57',
  },
  {
    name: 'Massa and Carrara',
    shortName: 'MS',
  },
  {
    name: 'Matera',
    shortName: 'MT',
  },
  {
    name: 'Medio Campidano',
    shortName: 'VS',
  },
  {
    name: 'Modena',
    shortName: 'MO',
  },
  {
    name: 'Molise',
    shortName: '67',
  },
  {
    name: 'Monza and Brianza',
    shortName: 'MB',
  },
  {
    name: 'Novara',
    shortName: 'NO',
  },
  {
    name: 'Nuoro',
    shortName: 'NU',
  },
  {
    name: 'Oristano',
    shortName: 'OR',
  },
  {
    name: 'Padua',
    shortName: 'PD',
  },
  {
    name: 'Palermo',
    shortName: 'PA',
  },
  {
    name: 'Parma',
    shortName: 'PR',
  },
  {
    name: 'Pavia',
    shortName: 'PV',
  },
  {
    name: 'Perugia',
    shortName: 'PG',
  },
  {
    name: 'Pesaro and Urbino',
    shortName: 'PU',
  },
  {
    name: 'Pescara',
    shortName: 'PE',
  },
  {
    name: 'Piacenza',
    shortName: 'PC',
  },
  {
    name: 'Piedmont',
    shortName: '21',
  },
  {
    name: 'Pisa',
    shortName: 'PI',
  },
  {
    name: 'Pistoia',
    shortName: 'PT',
  },
  {
    name: 'Pordenone',
    shortName: 'PN',
  },
  {
    name: 'Potenza',
    shortName: 'PZ',
  },
  {
    name: 'Prato',
    shortName: 'PO',
  },
  {
    name: 'Ragusa',
    shortName: 'RG',
  },
  {
    name: 'Ravenna',
    shortName: 'RA',
  },
  {
    name: 'Reggio Emilia',
    shortName: 'RE',
  },
  {
    name: 'Rieti',
    shortName: 'RI',
  },
  {
    name: 'Rimini',
    shortName: 'RN',
  },
  {
    name: 'Rovigo',
    shortName: 'RO',
  },
  {
    name: 'Salerno',
    shortName: 'SA',
  },
  {
    name: 'Sardinia',
    shortName: '88',
  },
  {
    name: 'Sassari',
    shortName: 'SS',
  },
  {
    name: 'Savona',
    shortName: 'SV',
  },
  {
    name: 'Sicily',
    shortName: '82',
  },
  {
    name: 'Siena',
    shortName: 'SI',
  },
  {
    name: 'Siracusa',
    shortName: 'SR',
  },
  {
    name: 'Sondrio',
    shortName: 'SO',
  },
  {
    name: 'South Sardinia',
    shortName: 'SU',
  },
  {
    name: 'Taranto',
    shortName: 'TA',
  },
  {
    name: 'Teramo',
    shortName: 'TE',
  },
  {
    name: 'Terni',
    shortName: 'TR',
  },
  {
    name: 'Trapani',
    shortName: 'TP',
  },
  {
    name: 'Trentino-South Tyrol',
    shortName: '32',
  },
  {
    name: 'Treviso',
    shortName: 'TV',
  },
  {
    name: 'Trieste',
    shortName: 'TS',
  },
  {
    name: 'Tuscany',
    shortName: '52',
  },
  {
    name: 'Udine',
    shortName: 'UD',
  },
  {
    name: 'Umbria',
    shortName: '55',
  },
  {
    name: 'Varese',
    shortName: 'VA',
  },
  {
    name: 'Veneto',
    shortName: '34',
  },
  {
    name: 'Verbano-Cusio-Ossola',
    shortName: 'VB',
  },
  {
    name: 'Vercelli',
    shortName: 'VC',
  },
  {
    name: 'Verona',
    shortName: 'VR',
  },
  {
    name: 'Vibo Valentia',
    shortName: 'VV',
  },
  {
    name: 'Vicenza',
    shortName: 'VI',
  },
  {
    name: 'Viterbo',
    shortName: 'VT',
  },
  {
    name: 'Clarendon Parish',
    shortName: '13',
  },
  {
    name: 'Hanover Parish',
    shortName: '09',
  },
  {
    name: 'Kingston Parish',
    shortName: '01',
  },
  {
    name: 'Manchester Parish',
    shortName: '12',
  },
  {
    name: 'Portland Parish',
    shortName: '04',
  },
  {
    name: 'Saint Andrew',
    shortName: '02',
  },
  {
    name: 'Saint Ann Parish',
    shortName: '06',
  },
  {
    name: 'Saint Catherine Parish',
    shortName: '14',
  },
  {
    name: 'Saint Elizabeth Parish',
    shortName: '11',
  },
  {
    name: 'Saint James Parish',
    shortName: '08',
  },
  {
    name: 'Saint Mary Parish',
    shortName: '05',
  },
  {
    name: 'Saint Thomas Parish',
    shortName: '03',
  },
  {
    name: 'Trelawny Parish',
    shortName: '07',
  },
  {
    name: 'Westmoreland Parish',
    shortName: '10',
  },
  {
    name: 'Aichi Prefecture',
    shortName: '23',
  },
  {
    name: 'Akita Prefecture',
    shortName: '05',
  },
  {
    name: 'Aomori Prefecture',
    shortName: '02',
  },
  {
    name: 'Chiba Prefecture',
    shortName: '12',
  },
  {
    name: 'Ehime Prefecture',
    shortName: '38',
  },
  {
    name: 'Fukui Prefecture',
    shortName: '18',
  },
  {
    name: 'Fukuoka Prefecture',
    shortName: '40',
  },
  {
    name: 'Fukushima Prefecture',
    shortName: '07',
  },
  {
    name: 'Gifu Prefecture',
    shortName: '21',
  },
  {
    name: 'Gunma Prefecture',
    shortName: '10',
  },
  {
    name: 'Hiroshima Prefecture',
    shortName: '34',
  },
  {
    name: 'Hokkaidō Prefecture',
    shortName: '01',
  },
  {
    name: 'Hyōgo Prefecture',
    shortName: '28',
  },
  {
    name: 'Ibaraki Prefecture',
    shortName: '08',
  },
  {
    name: 'Ishikawa Prefecture',
    shortName: '17',
  },
  {
    name: 'Iwate Prefecture',
    shortName: '03',
  },
  {
    name: 'Kagawa Prefecture',
    shortName: '37',
  },
  {
    name: 'Kagoshima Prefecture',
    shortName: '46',
  },
  {
    name: 'Kanagawa Prefecture',
    shortName: '14',
  },
  {
    name: 'Kōchi Prefecture',
    shortName: '39',
  },
  {
    name: 'Kumamoto Prefecture',
    shortName: '43',
  },
  {
    name: 'Kyōto Prefecture',
    shortName: '26',
  },
  {
    name: 'Mie Prefecture',
    shortName: '24',
  },
  {
    name: 'Miyagi Prefecture',
    shortName: '04',
  },
  {
    name: 'Miyazaki Prefecture',
    shortName: '45',
  },
  {
    name: 'Nagano Prefecture',
    shortName: '20',
  },
  {
    name: 'Nagasaki Prefecture',
    shortName: '42',
  },
  {
    name: 'Nara Prefecture',
    shortName: '29',
  },
  {
    name: 'Niigata Prefecture',
    shortName: '15',
  },
  {
    name: 'Ōita Prefecture',
    shortName: '44',
  },
  {
    name: 'Okayama Prefecture',
    shortName: '33',
  },
  {
    name: 'Okinawa Prefecture',
    shortName: '47',
  },
  {
    name: 'Ōsaka Prefecture',
    shortName: '27',
  },
  {
    name: 'Saga Prefecture',
    shortName: '41',
  },
  {
    name: 'Saitama Prefecture',
    shortName: '11',
  },
  {
    name: 'Shiga Prefecture',
    shortName: '25',
  },
  {
    name: 'Shimane Prefecture',
    shortName: '32',
  },
  {
    name: 'Shizuoka Prefecture',
    shortName: '22',
  },
  {
    name: 'Tochigi Prefecture',
    shortName: '09',
  },
  {
    name: 'Tokushima Prefecture',
    shortName: '36',
  },
  {
    name: 'Tokyo',
    shortName: '13',
  },
  {
    name: 'Tottori Prefecture',
    shortName: '31',
  },
  {
    name: 'Toyama Prefecture',
    shortName: '16',
  },
  {
    name: 'Wakayama Prefecture',
    shortName: '30',
  },
  {
    name: 'Yamagata Prefecture',
    shortName: '06',
  },
  {
    name: 'Yamaguchi Prefecture',
    shortName: '35',
  },
  {
    name: 'Yamanashi Prefecture',
    shortName: '19',
  },
  {
    name: 'Ajloun',
    shortName: 'AJ',
  },
  {
    name: 'Amman',
    shortName: 'AM',
  },
  {
    name: 'Aqaba',
    shortName: 'AQ',
  },
  {
    name: 'Balqa',
    shortName: 'BA',
  },
  {
    name: 'Irbid',
    shortName: 'IR',
  },
  {
    name: 'Jerash',
    shortName: 'JA',
  },
  {
    name: 'Karak',
    shortName: 'KA',
  },
  {
    name: "Ma'an",
    shortName: 'MN',
  },
  {
    name: 'Madaba',
    shortName: 'MD',
  },
  {
    name: 'Mafraq',
    shortName: 'MA',
  },
  {
    name: 'Tafilah',
    shortName: 'AT',
  },
  {
    name: 'Zarqa',
    shortName: 'AZ',
  },
  {
    name: 'Akmola Region',
    shortName: 'AKM',
  },
  {
    name: 'Aktobe Region',
    shortName: 'AKT',
  },
  {
    name: 'Almaty',
    shortName: 'ALA',
  },
  {
    name: 'Almaty Region',
    shortName: 'ALM',
  },
  {
    name: 'Atyrau Region',
    shortName: 'ATY',
  },
  {
    name: 'Baikonur',
    shortName: 'BAY',
  },
  {
    name: 'East Kazakhstan Region',
    shortName: 'VOS',
  },
  {
    name: 'Jambyl Region',
    shortName: 'ZHA',
  },
  {
    name: 'Karaganda Region',
    shortName: 'KAR',
  },
  {
    name: 'Kostanay Region',
    shortName: 'KUS',
  },
  {
    name: 'Kyzylorda Region',
    shortName: 'KZY',
  },
  {
    name: 'Mangystau Region',
    shortName: 'MAN',
  },
  {
    name: 'North Kazakhstan Region',
    shortName: 'SEV',
  },
  {
    name: 'Nur-Sultan',
    shortName: 'AST',
  },
  {
    name: 'Pavlodar Region',
    shortName: 'PAV',
  },
  {
    name: 'Turkestan Region',
    shortName: 'YUZ',
  },
  {
    name: 'West Kazakhstan Province',
    shortName: 'ZAP',
  },
  {
    name: 'Baringo',
    shortName: '01',
  },
  {
    name: 'Bomet',
    shortName: '02',
  },
  {
    name: 'Bungoma',
    shortName: '03',
  },
  {
    name: 'Busia',
    shortName: '04',
  },
  {
    name: 'Elgeyo-Marakwet',
    shortName: '05',
  },
  {
    name: 'Embu',
    shortName: '06',
  },
  {
    name: 'Garissa',
    shortName: '07',
  },
  {
    name: 'Homa Bay',
    shortName: '08',
  },
  {
    name: 'Isiolo',
    shortName: '09',
  },
  {
    name: 'Kajiado',
    shortName: '10',
  },
  {
    name: 'Kakamega',
    shortName: '11',
  },
  {
    name: 'Kericho',
    shortName: '12',
  },
  {
    name: 'Kiambu',
    shortName: '13',
  },
  {
    name: 'Kilifi',
    shortName: '14',
  },
  {
    name: 'Kirinyaga',
    shortName: '15',
  },
  {
    name: 'Kisii',
    shortName: '16',
  },
  {
    name: 'Kisumu',
    shortName: '17',
  },
  {
    name: 'Kitui',
    shortName: '18',
  },
  {
    name: 'Kwale',
    shortName: '19',
  },
  {
    name: 'Laikipia',
    shortName: '20',
  },
  {
    name: 'Lamu',
    shortName: '21',
  },
  {
    name: 'Machakos',
    shortName: '22',
  },
  {
    name: 'Makueni',
    shortName: '23',
  },
  {
    name: 'Mandera',
    shortName: '24',
  },
  {
    name: 'Marsabit',
    shortName: '25',
  },
  {
    name: 'Meru',
    shortName: '26',
  },
  {
    name: 'Migori',
    shortName: '27',
  },
  {
    name: 'Mombasa',
    shortName: '28',
  },
  {
    name: "Murang'a",
    shortName: '29',
  },
  {
    name: 'Nairobi City',
    shortName: '30',
  },
  {
    name: 'Nakuru',
    shortName: '31',
  },
  {
    name: 'Nandi',
    shortName: '32',
  },
  {
    name: 'Narok',
    shortName: '33',
  },
  {
    name: 'Nyamira',
    shortName: '34',
  },
  {
    name: 'Nyandarua',
    shortName: '35',
  },
  {
    name: 'Nyeri',
    shortName: '36',
  },
  {
    name: 'Samburu',
    shortName: '37',
  },
  {
    name: 'Siaya',
    shortName: '38',
  },
  {
    name: 'Taita–Taveta',
    shortName: '39',
  },
  {
    name: 'Tana River',
    shortName: '40',
  },
  {
    name: 'Tharaka-Nithi',
    shortName: '41',
  },
  {
    name: 'Trans Nzoia',
    shortName: '42',
  },
  {
    name: 'Turkana',
    shortName: '43',
  },
  {
    name: 'Uasin Gishu',
    shortName: '44',
  },
  {
    name: 'Vihiga',
    shortName: '45',
  },
  {
    name: 'Wajir',
    shortName: '46',
  },
  {
    name: 'West Pokot',
    shortName: '47',
  },
  {
    name: 'Gilbert Islands',
    shortName: 'G',
  },
  {
    name: 'Line Islands',
    shortName: 'L',
  },
  {
    name: 'Phoenix Islands',
    shortName: 'P',
  },
  {
    name: 'Đakovica District (Gjakove)',
    shortName: 'XDG',
  },
  {
    name: 'Gjilan District',
    shortName: 'XGJ',
  },
  {
    name: 'Kosovska Mitrovica District',
    shortName: 'XKM',
  },
  {
    name: 'Peć District',
    shortName: 'XPE',
  },
  {
    name: 'Pristina (Priştine)',
    shortName: 'XPI',
  },
  {
    name: 'Prizren District',
    shortName: 'XPR',
  },
  {
    name: 'Uroševac District (Ferizaj)',
    shortName: 'XUF',
  },
  {
    name: 'Al Ahmadi',
    shortName: 'AH',
  },
  {
    name: 'Al Farwaniyah',
    shortName: 'FA',
  },
  {
    name: 'Al Jahra',
    shortName: 'JA',
  },
  {
    name: 'Capital',
    shortName: 'KU',
  },
  {
    name: 'Hawalli',
    shortName: 'HA',
  },
  {
    name: 'Mubarak Al-Kabeer',
    shortName: 'MU',
  },
  {
    name: 'Batken Region',
    shortName: 'B',
  },
  {
    name: 'Bishkek',
    shortName: 'GB',
  },
  {
    name: 'Chuy Region',
    shortName: 'C',
  },
  {
    name: 'Issyk-Kul Region',
    shortName: 'Y',
  },
  {
    name: 'Jalal-Abad Region',
    shortName: 'J',
  },
  {
    name: 'Naryn Region',
    shortName: 'N',
  },
  {
    name: 'Osh',
    shortName: 'GO',
  },
  {
    name: 'Osh Region',
    shortName: 'O',
  },
  {
    name: 'Talas Region',
    shortName: 'T',
  },
  {
    name: 'Attapeu Province',
    shortName: 'AT',
  },
  {
    name: 'Bokeo Province',
    shortName: 'BK',
  },
  {
    name: 'Bolikhamsai Province',
    shortName: 'BL',
  },
  {
    name: 'Champasak Province',
    shortName: 'CH',
  },
  {
    name: 'Houaphanh Province',
    shortName: 'HO',
  },
  {
    name: 'Khammouane Province',
    shortName: 'KH',
  },
  {
    name: 'Luang Namtha Province',
    shortName: 'LM',
  },
  {
    name: 'Luang Prabang Province',
    shortName: 'LP',
  },
  {
    name: 'Oudomxay Province',
    shortName: 'OU',
  },
  {
    name: 'Phongsaly Province',
    shortName: 'PH',
  },
  {
    name: 'Sainyabuli Province',
    shortName: 'XA',
  },
  {
    name: 'Salavan Province',
    shortName: 'SL',
  },
  {
    name: 'Savannakhet Province',
    shortName: 'SV',
  },
  {
    name: 'Sekong Province',
    shortName: 'XE',
  },
  {
    name: 'Vientiane Prefecture',
    shortName: 'VT',
  },
  {
    name: 'Vientiane Province',
    shortName: 'VI',
  },
  {
    name: 'Xaisomboun',
    shortName: 'XN',
  },
  {
    name: 'Xaisomboun Province',
    shortName: 'XS',
  },
  {
    name: 'Xiangkhouang Province',
    shortName: 'XI',
  },
  {
    name: 'Aglona Municipality',
    shortName: '001',
  },
  {
    name: 'Aizkraukle Municipality',
    shortName: '002',
  },
  {
    name: 'Aizpute Municipality',
    shortName: '003',
  },
  {
    name: 'Aknīste Municipality',
    shortName: '004',
  },
  {
    name: 'Aloja Municipality',
    shortName: '005',
  },
  {
    name: 'Alsunga Municipality',
    shortName: '006',
  },
  {
    name: 'Alūksne Municipality',
    shortName: '007',
  },
  {
    name: 'Amata Municipality',
    shortName: '008',
  },
  {
    name: 'Ape Municipality',
    shortName: '009',
  },
  {
    name: 'Auce Municipality',
    shortName: '010',
  },
  {
    name: 'Babīte Municipality',
    shortName: '012',
  },
  {
    name: 'Baldone Municipality',
    shortName: '013',
  },
  {
    name: 'Baltinava Municipality',
    shortName: '014',
  },
  {
    name: 'Balvi Municipality',
    shortName: '015',
  },
  {
    name: 'Bauska Municipality',
    shortName: '016',
  },
  {
    name: 'Beverīna Municipality',
    shortName: '017',
  },
  {
    name: 'Brocēni Municipality',
    shortName: '018',
  },
  {
    name: 'Burtnieki Municipality',
    shortName: '019',
  },
  {
    name: 'Carnikava Municipality',
    shortName: '020',
  },
  {
    name: 'Cēsis Municipality',
    shortName: '022',
  },
  {
    name: 'Cesvaine Municipality',
    shortName: '021',
  },
  {
    name: 'Cibla Municipality',
    shortName: '023',
  },
  {
    name: 'Dagda Municipality',
    shortName: '024',
  },
  {
    name: 'Daugavpils',
    shortName: 'DGV',
  },
  {
    name: 'Daugavpils Municipality',
    shortName: '025',
  },
  {
    name: 'Dobele Municipality',
    shortName: '026',
  },
  {
    name: 'Dundaga Municipality',
    shortName: '027',
  },
  {
    name: 'Durbe Municipality',
    shortName: '028',
  },
  {
    name: 'Engure Municipality',
    shortName: '029',
  },
  {
    name: 'Ērgļi Municipality',
    shortName: '030',
  },
  {
    name: 'Garkalne Municipality',
    shortName: '031',
  },
  {
    name: 'Grobiņa Municipality',
    shortName: '032',
  },
  {
    name: 'Gulbene Municipality',
    shortName: '033',
  },
  {
    name: 'Iecava Municipality',
    shortName: '034',
  },
  {
    name: 'Ikšķile Municipality',
    shortName: '035',
  },
  {
    name: 'Ilūkste Municipality',
    shortName: '036',
  },
  {
    name: 'Inčukalns Municipality',
    shortName: '037',
  },
  {
    name: 'Jaunjelgava Municipality',
    shortName: '038',
  },
  {
    name: 'Jaunpiebalga Municipality',
    shortName: '039',
  },
  {
    name: 'Jaunpils Municipality',
    shortName: '040',
  },
  {
    name: 'Jēkabpils',
    shortName: 'JKB',
  },
  {
    name: 'Jēkabpils Municipality',
    shortName: '042',
  },
  {
    name: 'Jelgava',
    shortName: 'JEL',
  },
  {
    name: 'Jelgava Municipality',
    shortName: '041',
  },
  {
    name: 'Jūrmala',
    shortName: 'JUR',
  },
  {
    name: 'Kandava Municipality',
    shortName: '043',
  },
  {
    name: 'Kārsava Municipality',
    shortName: '044',
  },
  {
    name: 'Ķegums Municipality',
    shortName: '051',
  },
  {
    name: 'Ķekava Municipality',
    shortName: '052',
  },
  {
    name: 'Kocēni Municipality',
    shortName: '045',
  },
  {
    name: 'Koknese Municipality',
    shortName: '046',
  },
  {
    name: 'Krāslava Municipality',
    shortName: '047',
  },
  {
    name: 'Krimulda Municipality',
    shortName: '048',
  },
  {
    name: 'Krustpils Municipality',
    shortName: '049',
  },
  {
    name: 'Kuldīga Municipality',
    shortName: '050',
  },
  {
    name: 'Lielvārde Municipality',
    shortName: '053',
  },
  {
    name: 'Liepāja',
    shortName: 'LPX',
  },
  {
    name: 'Līgatne Municipality',
    shortName: '055',
  },
  {
    name: 'Limbaži Municipality',
    shortName: '054',
  },
  {
    name: 'Līvāni Municipality',
    shortName: '056',
  },
  {
    name: 'Lubāna Municipality',
    shortName: '057',
  },
  {
    name: 'Ludza Municipality',
    shortName: '058',
  },
  {
    name: 'Madona Municipality',
    shortName: '059',
  },
  {
    name: 'Mālpils Municipality',
    shortName: '061',
  },
  {
    name: 'Mārupe Municipality',
    shortName: '062',
  },
  {
    name: 'Mazsalaca Municipality',
    shortName: '060',
  },
  {
    name: 'Mērsrags Municipality',
    shortName: '063',
  },
  {
    name: 'Naukšēni Municipality',
    shortName: '064',
  },
  {
    name: 'Nereta Municipality',
    shortName: '065',
  },
  {
    name: 'Nīca Municipality',
    shortName: '066',
  },
  {
    name: 'Ogre Municipality',
    shortName: '067',
  },
  {
    name: 'Olaine Municipality',
    shortName: '068',
  },
  {
    name: 'Ozolnieki Municipality',
    shortName: '069',
  },
  {
    name: 'Pārgauja Municipality',
    shortName: '070',
  },
  {
    name: 'Pāvilosta Municipality',
    shortName: '071',
  },
  {
    name: 'Pļaviņas Municipality',
    shortName: '072',
  },
  {
    name: 'Preiļi Municipality',
    shortName: '073',
  },
  {
    name: 'Priekule Municipality',
    shortName: '074',
  },
  {
    name: 'Priekuļi Municipality',
    shortName: '075',
  },
  {
    name: 'Rauna Municipality',
    shortName: '076',
  },
  {
    name: 'Rēzekne',
    shortName: 'REZ',
  },
  {
    name: 'Rēzekne Municipality',
    shortName: '077',
  },
  {
    name: 'Riebiņi Municipality',
    shortName: '078',
  },
  {
    name: 'Riga',
    shortName: 'RIX',
  },
  {
    name: 'Roja Municipality',
    shortName: '079',
  },
  {
    name: 'Ropaži Municipality',
    shortName: '080',
  },
  {
    name: 'Rucava Municipality',
    shortName: '081',
  },
  {
    name: 'Rugāji Municipality',
    shortName: '082',
  },
  {
    name: 'Rūjiena Municipality',
    shortName: '084',
  },
  {
    name: 'Rundāle Municipality',
    shortName: '083',
  },
  {
    name: 'Sala Municipality',
    shortName: '085',
  },
  {
    name: 'Salacgrīva Municipality',
    shortName: '086',
  },
  {
    name: 'Salaspils Municipality',
    shortName: '087',
  },
  {
    name: 'Saldus Municipality',
    shortName: '088',
  },
  {
    name: 'Saulkrasti Municipality',
    shortName: '089',
  },
  {
    name: 'Sēja Municipality',
    shortName: '090',
  },
  {
    name: 'Sigulda Municipality',
    shortName: '091',
  },
  {
    name: 'Skrīveri Municipality',
    shortName: '092',
  },
  {
    name: 'Skrunda Municipality',
    shortName: '093',
  },
  {
    name: 'Smiltene Municipality',
    shortName: '094',
  },
  {
    name: 'Stopiņi Municipality',
    shortName: '095',
  },
  {
    name: 'Strenči Municipality',
    shortName: '096',
  },
  {
    name: 'Talsi Municipality',
    shortName: '097',
  },
  {
    name: 'Tērvete Municipality',
    shortName: '098',
  },
  {
    name: 'Tukums Municipality',
    shortName: '099',
  },
  {
    name: 'Vaiņode Municipality',
    shortName: '100',
  },
  {
    name: 'Valka Municipality',
    shortName: '101',
  },
  {
    name: 'Valmiera',
    shortName: 'VMR',
  },
  {
    name: 'Varakļāni Municipality',
    shortName: '102',
  },
  {
    name: 'Vārkava Municipality',
    shortName: '103',
  },
  {
    name: 'Vecpiebalga Municipality',
    shortName: '104',
  },
  {
    name: 'Vecumnieki Municipality',
    shortName: '105',
  },
  {
    name: 'Ventspils',
    shortName: 'VEN',
  },
  {
    name: 'Ventspils Municipality',
    shortName: '106',
  },
  {
    name: 'Viesīte Municipality',
    shortName: '107',
  },
  {
    name: 'Viļaka Municipality',
    shortName: '108',
  },
  {
    name: 'Viļāni Municipality',
    shortName: '109',
  },
  {
    name: 'Zilupe Municipality',
    shortName: '110',
  },
  {
    name: 'Akkar',
    shortName: 'AK',
  },
  {
    name: 'Baalbek-Hermel',
    shortName: 'BH',
  },
  {
    name: 'Beirut',
    shortName: 'BA',
  },
  {
    name: 'Beqaa',
    shortName: 'BI',
  },
  {
    name: 'Mount Lebanon',
    shortName: 'JL',
  },
  {
    name: 'Nabatieh',
    shortName: 'NA',
  },
  {
    name: 'North',
    shortName: 'AS',
  },
  {
    name: 'South',
    shortName: 'JA',
  },
  {
    name: 'Berea District',
    shortName: 'D',
  },
  {
    name: 'Butha-Buthe District',
    shortName: 'B',
  },
  {
    name: 'Leribe District',
    shortName: 'C',
  },
  {
    name: 'Mafeteng District',
    shortName: 'E',
  },
  {
    name: 'Maseru District',
    shortName: 'A',
  },
  {
    name: "Mohale's Hoek District",
    shortName: 'F',
  },
  {
    name: 'Mokhotlong District',
    shortName: 'J',
  },
  {
    name: "Qacha's Nek District",
    shortName: 'H',
  },
  {
    name: 'Quthing District',
    shortName: 'G',
  },
  {
    name: 'Thaba-Tseka District',
    shortName: 'K',
  },
  {
    name: 'Bomi County',
    shortName: 'BM',
  },
  {
    name: 'Bong County',
    shortName: 'BG',
  },
  {
    name: 'Gbarpolu County',
    shortName: 'GP',
  },
  {
    name: 'Grand Bassa County',
    shortName: 'GB',
  },
  {
    name: 'Grand Cape Mount County',
    shortName: 'CM',
  },
  {
    name: 'Grand Gedeh County',
    shortName: 'GG',
  },
  {
    name: 'Grand Kru County',
    shortName: 'GK',
  },
  {
    name: 'Lofa County',
    shortName: 'LO',
  },
  {
    name: 'Margibi County',
    shortName: 'MG',
  },
  {
    name: 'Maryland County',
    shortName: 'MY',
  },
  {
    name: 'Montserrado County',
    shortName: 'MO',
  },
  {
    name: 'Nimba',
    shortName: 'NI',
  },
  {
    name: 'River Cess County',
    shortName: 'RI',
  },
  {
    name: 'River Gee County',
    shortName: 'RG',
  },
  {
    name: 'Sinoe County',
    shortName: 'SI',
  },
  {
    name: 'Al Wahat District',
    shortName: 'WA',
  },
  {
    name: 'Benghazi',
    shortName: 'BA',
  },
  {
    name: 'Derna District',
    shortName: 'DR',
  },
  {
    name: 'Ghat District',
    shortName: 'GT',
  },
  {
    name: 'Jabal al Akhdar',
    shortName: 'JA',
  },
  {
    name: 'Jabal al Gharbi District',
    shortName: 'JG',
  },
  {
    name: 'Jafara',
    shortName: 'JI',
  },
  {
    name: 'Jufra',
    shortName: 'JU',
  },
  {
    name: 'Kufra District',
    shortName: 'KF',
  },
  {
    name: 'Marj District',
    shortName: 'MJ',
  },
  {
    name: 'Misrata District',
    shortName: 'MI',
  },
  {
    name: 'Murqub',
    shortName: 'MB',
  },
  {
    name: 'Murzuq District',
    shortName: 'MQ',
  },
  {
    name: 'Nalut District',
    shortName: 'NL',
  },
  {
    name: 'Nuqat al Khams',
    shortName: 'NQ',
  },
  {
    name: 'Sabha District',
    shortName: 'SB',
  },
  {
    name: 'Sirte District',
    shortName: 'SR',
  },
  {
    name: 'Tripoli District',
    shortName: 'TB',
  },
  {
    name: 'Wadi al Hayaa District',
    shortName: 'WD',
  },
  {
    name: 'Wadi al Shatii District',
    shortName: 'WS',
  },
  {
    name: 'Zawiya District',
    shortName: 'ZA',
  },
  {
    name: 'Balzers',
    shortName: '01',
  },
  {
    name: 'Eschen',
    shortName: '02',
  },
  {
    name: 'Gamprin',
    shortName: '03',
  },
  {
    name: 'Mauren',
    shortName: '04',
  },
  {
    name: 'Planken',
    shortName: '05',
  },
  {
    name: 'Ruggell',
    shortName: '06',
  },
  {
    name: 'Schaan',
    shortName: '07',
  },
  {
    name: 'Schellenberg',
    shortName: '08',
  },
  {
    name: 'Triesen',
    shortName: '09',
  },
  {
    name: 'Triesenberg',
    shortName: '10',
  },
  {
    name: 'Vaduz',
    shortName: '11',
  },
  {
    name: 'Akmenė District Municipality',
    shortName: '01',
  },
  {
    name: 'Alytus City Municipality',
    shortName: '02',
  },
  {
    name: 'Alytus County',
    shortName: 'AL',
  },
  {
    name: 'Alytus District Municipality',
    shortName: '03',
  },
  {
    name: 'Birštonas Municipality',
    shortName: '05',
  },
  {
    name: 'Biržai District Municipality',
    shortName: '06',
  },
  {
    name: 'Druskininkai municipality',
    shortName: '07',
  },
  {
    name: 'Elektrėnai municipality',
    shortName: '08',
  },
  {
    name: 'Ignalina District Municipality',
    shortName: '09',
  },
  {
    name: 'Jonava District Municipality',
    shortName: '10',
  },
  {
    name: 'Joniškis District Municipality',
    shortName: '11',
  },
  {
    name: 'Jurbarkas District Municipality',
    shortName: '12',
  },
  {
    name: 'Kaišiadorys District Municipality',
    shortName: '13',
  },
  {
    name: 'Kalvarija municipality',
    shortName: '14',
  },
  {
    name: 'Kaunas City Municipality',
    shortName: '15',
  },
  {
    name: 'Kaunas County',
    shortName: 'KU',
  },
  {
    name: 'Kaunas District Municipality',
    shortName: '16',
  },
  {
    name: 'Kazlų Rūda municipality',
    shortName: '17',
  },
  {
    name: 'Kėdainiai District Municipality',
    shortName: '18',
  },
  {
    name: 'Kelmė District Municipality',
    shortName: '19',
  },
  {
    name: 'Klaipeda City Municipality',
    shortName: '20',
  },
  {
    name: 'Klaipėda County',
    shortName: 'KL',
  },
  {
    name: 'Klaipėda District Municipality',
    shortName: '21',
  },
  {
    name: 'Kretinga District Municipality',
    shortName: '22',
  },
  {
    name: 'Kupiškis District Municipality',
    shortName: '23',
  },
  {
    name: 'Lazdijai District Municipality',
    shortName: '24',
  },
  {
    name: 'Marijampolė County',
    shortName: 'MR',
  },
  {
    name: 'Marijampolė Municipality',
    shortName: '25',
  },
  {
    name: 'Mažeikiai District Municipality',
    shortName: '26',
  },
  {
    name: 'Molėtai District Municipality',
    shortName: '27',
  },
  {
    name: 'Neringa Municipality',
    shortName: '28',
  },
  {
    name: 'Pagėgiai municipality',
    shortName: '29',
  },
  {
    name: 'Pakruojis District Municipality',
    shortName: '30',
  },
  {
    name: 'Palanga City Municipality',
    shortName: '31',
  },
  {
    name: 'Panevėžys City Municipality',
    shortName: '32',
  },
  {
    name: 'Panevėžys County',
    shortName: 'PN',
  },
  {
    name: 'Panevėžys District Municipality',
    shortName: '33',
  },
  {
    name: 'Pasvalys District Municipality',
    shortName: '34',
  },
  {
    name: 'Plungė District Municipality',
    shortName: '35',
  },
  {
    name: 'Prienai District Municipality',
    shortName: '36',
  },
  {
    name: 'Radviliškis District Municipality',
    shortName: '37',
  },
  {
    name: 'Raseiniai District Municipality',
    shortName: '38',
  },
  {
    name: 'Rietavas municipality',
    shortName: '39',
  },
  {
    name: 'Rokiškis District Municipality',
    shortName: '40',
  },
  {
    name: 'Šakiai District Municipality',
    shortName: '41',
  },
  {
    name: 'Šalčininkai District Municipality',
    shortName: '42',
  },
  {
    name: 'Šiauliai City Municipality',
    shortName: '43',
  },
  {
    name: 'Šiauliai County',
    shortName: 'SA',
  },
  {
    name: 'Šiauliai District Municipality',
    shortName: '44',
  },
  {
    name: 'Šilalė District Municipality',
    shortName: '45',
  },
  {
    name: 'Šilutė District Municipality',
    shortName: '46',
  },
  {
    name: 'Širvintos District Municipality',
    shortName: '47',
  },
  {
    name: 'Skuodas District Municipality',
    shortName: '48',
  },
  {
    name: 'Švenčionys District Municipality',
    shortName: '49',
  },
  {
    name: 'Tauragė County',
    shortName: 'TA',
  },
  {
    name: 'Tauragė District Municipality',
    shortName: '50',
  },
  {
    name: 'Telšiai County',
    shortName: 'TE',
  },
  {
    name: 'Telšiai District Municipality',
    shortName: '51',
  },
  {
    name: 'Trakai District Municipality',
    shortName: '52',
  },
  {
    name: 'Ukmergė District Municipality',
    shortName: '53',
  },
  {
    name: 'Utena County',
    shortName: 'UT',
  },
  {
    name: 'Utena District Municipality',
    shortName: '54',
  },
  {
    name: 'Varėna District Municipality',
    shortName: '55',
  },
  {
    name: 'Vilkaviškis District Municipality',
    shortName: '56',
  },
  {
    name: 'Vilnius City Municipality',
    shortName: '57',
  },
  {
    name: 'Vilnius County',
    shortName: 'VL',
  },
  {
    name: 'Vilnius District Municipality',
    shortName: '58',
  },
  {
    name: 'Visaginas Municipality',
    shortName: '59',
  },
  {
    name: 'Zarasai District Municipality',
    shortName: '60',
  },
  {
    name: 'Canton of Capellen',
    shortName: 'CA',
  },
  {
    name: 'Canton of Clervaux',
    shortName: 'CL',
  },
  {
    name: 'Canton of Diekirch',
    shortName: 'DI',
  },
  {
    name: 'Canton of Echternach',
    shortName: 'EC',
  },
  {
    name: 'Canton of Esch-sur-Alzette',
    shortName: 'ES',
  },
  {
    name: 'Canton of Grevenmacher',
    shortName: 'GR',
  },
  {
    name: 'Canton of Luxembourg',
    shortName: 'LU',
  },
  {
    name: 'Canton of Mersch',
    shortName: 'ME',
  },
  {
    name: 'Canton of Redange',
    shortName: 'RD',
  },
  {
    name: 'Canton of Remich',
    shortName: 'RM',
  },
  {
    name: 'Canton of Vianden',
    shortName: 'VD',
  },
  {
    name: 'Canton of Wiltz',
    shortName: 'WI',
  },
  {
    name: 'Diekirch District',
    shortName: 'D',
  },
  {
    name: 'Grevenmacher District',
    shortName: 'G',
  },
  {
    name: 'Luxembourg District',
    shortName: 'L',
  },
  {
    name: 'Aerodrom Municipality',
    shortName: '01',
  },
  {
    name: 'Aračinovo Municipality',
    shortName: '02',
  },
  {
    name: 'Berovo Municipality',
    shortName: '03',
  },
  {
    name: 'Bitola Municipality',
    shortName: '04',
  },
  {
    name: 'Bogdanci Municipality',
    shortName: '05',
  },
  {
    name: 'Bogovinje Municipality',
    shortName: '06',
  },
  {
    name: 'Bosilovo Municipality',
    shortName: '07',
  },
  {
    name: 'Brvenica Municipality',
    shortName: '08',
  },
  {
    name: 'Butel Municipality',
    shortName: '09',
  },
  {
    name: 'Čair Municipality',
    shortName: '79',
  },
  {
    name: 'Čaška Municipality',
    shortName: '80',
  },
  {
    name: 'Centar Municipality',
    shortName: '77',
  },
  {
    name: 'Centar Župa Municipality',
    shortName: '78',
  },
  {
    name: 'Češinovo-Obleševo Municipality',
    shortName: '81',
  },
  {
    name: 'Čučer-Sandevo Municipality',
    shortName: '82',
  },
  {
    name: 'Debarca Municipality',
    shortName: '22',
  },
  {
    name: 'Delčevo Municipality',
    shortName: '23',
  },
  {
    name: 'Demir Hisar Municipality',
    shortName: '25',
  },
  {
    name: 'Demir Kapija Municipality',
    shortName: '24',
  },
  {
    name: 'Dojran Municipality',
    shortName: '26',
  },
  {
    name: 'Dolneni Municipality',
    shortName: '27',
  },
  {
    name: 'Drugovo Municipality',
    shortName: '28',
  },
  {
    name: 'Gazi Baba Municipality',
    shortName: '17',
  },
  {
    name: 'Gevgelija Municipality',
    shortName: '18',
  },
  {
    name: 'Gjorče Petrov Municipality',
    shortName: '29',
  },
  {
    name: 'Gostivar Municipality',
    shortName: '19',
  },
  {
    name: 'Gradsko Municipality',
    shortName: '20',
  },
  {
    name: 'Greater Skopje',
    shortName: '85',
  },
  {
    name: 'Ilinden Municipality',
    shortName: '34',
  },
  {
    name: 'Jegunovce Municipality',
    shortName: '35',
  },
  {
    name: 'Karbinci',
    shortName: '37',
  },
  {
    name: 'Karpoš Municipality',
    shortName: '38',
  },
  {
    name: 'Kavadarci Municipality',
    shortName: '36',
  },
  {
    name: 'Kičevo Municipality',
    shortName: '40',
  },
  {
    name: 'Kisela Voda Municipality',
    shortName: '39',
  },
  {
    name: 'Kočani Municipality',
    shortName: '42',
  },
  {
    name: 'Konče Municipality',
    shortName: '41',
  },
  {
    name: 'Kratovo Municipality',
    shortName: '43',
  },
  {
    name: 'Kriva Palanka Municipality',
    shortName: '44',
  },
  {
    name: 'Krivogaštani Municipality',
    shortName: '45',
  },
  {
    name: 'Kruševo Municipality',
    shortName: '46',
  },
  {
    name: 'Kumanovo Municipality',
    shortName: '47',
  },
  {
    name: 'Lipkovo Municipality',
    shortName: '48',
  },
  {
    name: 'Lozovo Municipality',
    shortName: '49',
  },
  {
    name: 'Makedonska Kamenica Municipality',
    shortName: '51',
  },
  {
    name: 'Makedonski Brod Municipality',
    shortName: '52',
  },
  {
    name: 'Mavrovo and Rostuša Municipality',
    shortName: '50',
  },
  {
    name: 'Mogila Municipality',
    shortName: '53',
  },
  {
    name: 'Negotino Municipality',
    shortName: '54',
  },
  {
    name: 'Novaci Municipality',
    shortName: '55',
  },
  {
    name: 'Novo Selo Municipality',
    shortName: '56',
  },
  {
    name: 'Ohrid Municipality',
    shortName: '58',
  },
  {
    name: 'Oslomej Municipality',
    shortName: '57',
  },
  {
    name: 'Pehčevo Municipality',
    shortName: '60',
  },
  {
    name: 'Petrovec Municipality',
    shortName: '59',
  },
  {
    name: 'Plasnica Municipality',
    shortName: '61',
  },
  {
    name: 'Prilep Municipality',
    shortName: '62',
  },
  {
    name: 'Probištip Municipality',
    shortName: '63',
  },
  {
    name: 'Radoviš Municipality',
    shortName: '64',
  },
  {
    name: 'Rankovce Municipality',
    shortName: '65',
  },
  {
    name: 'Resen Municipality',
    shortName: '66',
  },
  {
    name: 'Rosoman Municipality',
    shortName: '67',
  },
  {
    name: 'Saraj Municipality',
    shortName: '68',
  },
  {
    name: 'Sopište Municipality',
    shortName: '70',
  },
  {
    name: 'Staro Nagoričane Municipality',
    shortName: '71',
  },
  {
    name: 'Štip Municipality',
    shortName: '83',
  },
  {
    name: 'Struga Municipality',
    shortName: '72',
  },
  {
    name: 'Strumica Municipality',
    shortName: '73',
  },
  {
    name: 'Studeničani Municipality',
    shortName: '74',
  },
  {
    name: 'Šuto Orizari Municipality',
    shortName: '84',
  },
  {
    name: 'Sveti Nikole Municipality',
    shortName: '69',
  },
  {
    name: 'Tearce Municipality',
    shortName: '75',
  },
  {
    name: 'Tetovo Municipality',
    shortName: '76',
  },
  {
    name: 'Valandovo Municipality',
    shortName: '10',
  },
  {
    name: 'Vasilevo Municipality',
    shortName: '11',
  },
  {
    name: 'Veles Municipality',
    shortName: '13',
  },
  {
    name: 'Vevčani Municipality',
    shortName: '12',
  },
  {
    name: 'Vinica Municipality',
    shortName: '14',
  },
  {
    name: 'Vraneštica Municipality',
    shortName: '15',
  },
  {
    name: 'Vrapčište Municipality',
    shortName: '16',
  },
  {
    name: 'Zajas Municipality',
    shortName: '31',
  },
  {
    name: 'Zelenikovo Municipality',
    shortName: '32',
  },
  {
    name: 'Želino Municipality',
    shortName: '30',
  },
  {
    name: 'Zrnovci Municipality',
    shortName: '33',
  },
  {
    name: 'Antananarivo Province',
    shortName: 'T',
  },
  {
    name: 'Antsiranana Province',
    shortName: 'D',
  },
  {
    name: 'Fianarantsoa Province',
    shortName: 'F',
  },
  {
    name: 'Mahajanga Province',
    shortName: 'M',
  },
  {
    name: 'Toamasina Province',
    shortName: 'A',
  },
  {
    name: 'Toliara Province',
    shortName: 'U',
  },
  {
    name: 'Balaka District',
    shortName: 'BA',
  },
  {
    name: 'Blantyre District',
    shortName: 'BL',
  },
  {
    name: 'Central Region',
    shortName: 'C',
  },
  {
    name: 'Chikwawa District',
    shortName: 'CK',
  },
  {
    name: 'Chiradzulu District',
    shortName: 'CR',
  },
  {
    name: 'Chitipa district',
    shortName: 'CT',
  },
  {
    name: 'Dedza District',
    shortName: 'DE',
  },
  {
    name: 'Dowa District',
    shortName: 'DO',
  },
  {
    name: 'Karonga District',
    shortName: 'KR',
  },
  {
    name: 'Kasungu District',
    shortName: 'KS',
  },
  {
    name: 'Likoma District',
    shortName: 'LK',
  },
  {
    name: 'Lilongwe District',
    shortName: 'LI',
  },
  {
    name: 'Machinga District',
    shortName: 'MH',
  },
  {
    name: 'Mangochi District',
    shortName: 'MG',
  },
  {
    name: 'Mchinji District',
    shortName: 'MC',
  },
  {
    name: 'Mulanje District',
    shortName: 'MU',
  },
  {
    name: 'Mwanza District',
    shortName: 'MW',
  },
  {
    name: 'Mzimba District',
    shortName: 'MZ',
  },
  {
    name: 'Nkhata Bay District',
    shortName: 'NB',
  },
  {
    name: 'Nkhotakota District',
    shortName: 'NK',
  },
  {
    name: 'Northern Region',
    shortName: 'N',
  },
  {
    name: 'Nsanje District',
    shortName: 'NS',
  },
  {
    name: 'Ntcheu District',
    shortName: 'NU',
  },
  {
    name: 'Ntchisi District',
    shortName: 'NI',
  },
  {
    name: 'Phalombe District',
    shortName: 'PH',
  },
  {
    name: 'Rumphi District',
    shortName: 'RU',
  },
  {
    name: 'Salima District',
    shortName: 'SA',
  },
  {
    name: 'Southern Region',
    shortName: 'S',
  },
  {
    name: 'Thyolo District',
    shortName: 'TH',
  },
  {
    name: 'Zomba District',
    shortName: 'ZO',
  },
  {
    name: 'Johor',
    shortName: '01',
  },
  {
    name: 'Kedah',
    shortName: '02',
  },
  {
    name: 'Kelantan',
    shortName: '03',
  },
  {
    name: 'Kuala Lumpur',
    shortName: '14',
  },
  {
    name: 'Labuan',
    shortName: '15',
  },
  {
    name: 'Malacca',
    shortName: '04',
  },
  {
    name: 'Negeri Sembilan',
    shortName: '05',
  },
  {
    name: 'Pahang',
    shortName: '06',
  },
  {
    name: 'Penang',
    shortName: '07',
  },
  {
    name: 'Perak',
    shortName: '08',
  },
  {
    name: 'Perlis',
    shortName: '09',
  },
  {
    name: 'Putrajaya',
    shortName: '16',
  },
  {
    name: 'Sabah',
    shortName: '12',
  },
  {
    name: 'Sarawak',
    shortName: '13',
  },
  {
    name: 'Selangor',
    shortName: '10',
  },
  {
    name: 'Terengganu',
    shortName: '11',
  },
  {
    name: 'Addu Atoll',
    shortName: '01',
  },
  {
    name: 'Alif Alif Atoll',
    shortName: '02',
  },
  {
    name: 'Alif Dhaal Atoll',
    shortName: '00',
  },
  {
    name: 'Central Province',
    shortName: 'CE',
  },
  {
    name: 'Dhaalu Atoll',
    shortName: '17',
  },
  {
    name: 'Faafu Atoll',
    shortName: '14',
  },
  {
    name: 'Gaafu Alif Atoll',
    shortName: '27',
  },
  {
    name: 'Gaafu Dhaalu Atoll',
    shortName: '28',
  },
  {
    name: 'Gnaviyani Atoll',
    shortName: '29',
  },
  {
    name: 'Haa Alif Atoll',
    shortName: '07',
  },
  {
    name: 'Haa Dhaalu Atoll',
    shortName: '23',
  },
  {
    name: 'Kaafu Atoll',
    shortName: '26',
  },
  {
    name: 'Laamu Atoll',
    shortName: '05',
  },
  {
    name: 'Lhaviyani Atoll',
    shortName: '03',
  },
  {
    name: 'Malé',
    shortName: 'MLE',
  },
  {
    name: 'Meemu Atoll',
    shortName: '12',
  },
  {
    name: 'Noonu Atoll',
    shortName: '25',
  },
  {
    name: 'North Central Province',
    shortName: 'NC',
  },
  {
    name: 'North Province',
    shortName: 'NO',
  },
  {
    name: 'Raa Atoll',
    shortName: '13',
  },
  {
    name: 'Shaviyani Atoll',
    shortName: '24',
  },
  {
    name: 'South Central Province',
    shortName: 'SC',
  },
  {
    name: 'South Province',
    shortName: 'SU',
  },
  {
    name: 'Thaa Atoll',
    shortName: '08',
  },
  {
    name: 'Upper South Province',
    shortName: 'US',
  },
  {
    name: 'Vaavu Atoll',
    shortName: '04',
  },
  {
    name: 'Bamako',
    shortName: 'BKO',
  },
  {
    name: 'Gao Region',
    shortName: '7',
  },
  {
    name: 'Kayes Region',
    shortName: '1',
  },
  {
    name: 'Kidal Region',
    shortName: '8',
  },
  {
    name: 'Koulikoro Region',
    shortName: '2',
  },
  {
    name: 'Ménaka Region',
    shortName: '9',
  },
  {
    name: 'Mopti Region',
    shortName: '5',
  },
  {
    name: 'Ségou Region',
    shortName: '4',
  },
  {
    name: 'Sikasso Region',
    shortName: '3',
  },
  {
    name: 'Taoudénit Region',
    shortName: '10',
  },
  {
    name: 'Tombouctou Region',
    shortName: '6',
  },
  {
    name: 'Attard',
    shortName: '01',
  },
  {
    name: 'Balzan',
    shortName: '02',
  },
  {
    name: 'Birgu',
    shortName: '03',
  },
  {
    name: 'Birkirkara',
    shortName: '04',
  },
  {
    name: 'Birżebbuġa',
    shortName: '05',
  },
  {
    name: 'Cospicua',
    shortName: '06',
  },
  {
    name: 'Dingli',
    shortName: '07',
  },
  {
    name: 'Fgura',
    shortName: '08',
  },
  {
    name: 'Floriana',
    shortName: '09',
  },
  {
    name: 'Fontana',
    shortName: '10',
  },
  {
    name: 'Għajnsielem',
    shortName: '13',
  },
  {
    name: 'Għarb',
    shortName: '14',
  },
  {
    name: 'Għargħur',
    shortName: '15',
  },
  {
    name: 'Għasri',
    shortName: '16',
  },
  {
    name: 'Għaxaq',
    shortName: '17',
  },
  {
    name: 'Gudja',
    shortName: '11',
  },
  {
    name: 'Gżira',
    shortName: '12',
  },
  {
    name: 'Ħamrun',
    shortName: '18',
  },
  {
    name: 'Iklin',
    shortName: '19',
  },
  {
    name: 'Kalkara',
    shortName: '21',
  },
  {
    name: 'Kerċem',
    shortName: '22',
  },
  {
    name: 'Kirkop',
    shortName: '23',
  },
  {
    name: 'Lija',
    shortName: '24',
  },
  {
    name: 'Luqa',
    shortName: '25',
  },
  {
    name: 'Marsa',
    shortName: '26',
  },
  {
    name: 'Marsaskala',
    shortName: '27',
  },
  {
    name: 'Marsaxlokk',
    shortName: '28',
  },
  {
    name: 'Mdina',
    shortName: '29',
  },
  {
    name: 'Mellieħa',
    shortName: '30',
  },
  {
    name: 'Mġarr',
    shortName: '31',
  },
  {
    name: 'Mosta',
    shortName: '32',
  },
  {
    name: 'Mqabba',
    shortName: '33',
  },
  {
    name: 'Msida',
    shortName: '34',
  },
  {
    name: 'Mtarfa',
    shortName: '35',
  },
  {
    name: 'Munxar',
    shortName: '36',
  },
  {
    name: 'Nadur',
    shortName: '37',
  },
  {
    name: 'Naxxar',
    shortName: '38',
  },
  {
    name: 'Paola',
    shortName: '39',
  },
  {
    name: 'Pembroke',
    shortName: '40',
  },
  {
    name: 'Pietà',
    shortName: '41',
  },
  {
    name: 'Qala',
    shortName: '42',
  },
  {
    name: 'Qormi',
    shortName: '43',
  },
  {
    name: 'Qrendi',
    shortName: '44',
  },
  {
    name: 'Rabat',
    shortName: '46',
  },
  {
    name: 'Saint Lawrence',
    shortName: '50',
  },
  {
    name: 'San Ġwann',
    shortName: '49',
  },
  {
    name: 'Sannat',
    shortName: '52',
  },
  {
    name: 'Santa Luċija',
    shortName: '53',
  },
  {
    name: 'Santa Venera',
    shortName: '54',
  },
  {
    name: 'Senglea',
    shortName: '20',
  },
  {
    name: 'Siġġiewi',
    shortName: '55',
  },
  {
    name: 'Sliema',
    shortName: '56',
  },
  {
    name: "St. Julian's",
    shortName: '48',
  },
  {
    name: "St. Paul's Bay",
    shortName: '51',
  },
  {
    name: 'Swieqi',
    shortName: '57',
  },
  {
    name: "Ta' Xbiex",
    shortName: '58',
  },
  {
    name: 'Tarxien',
    shortName: '59',
  },
  {
    name: 'Valletta',
    shortName: '60',
  },
  {
    name: 'Victoria',
    shortName: '45',
  },
  {
    name: 'Xagħra',
    shortName: '61',
  },
  {
    name: 'Xewkija',
    shortName: '62',
  },
  {
    name: 'Xgħajra',
    shortName: '63',
  },
  {
    name: 'Żabbar',
    shortName: '64',
  },
  {
    name: 'Żebbuġ Gozo',
    shortName: '65',
  },
  {
    name: 'Żebbuġ Malta',
    shortName: '66',
  },
  {
    name: 'Żejtun',
    shortName: '67',
  },
  {
    name: 'Żurrieq',
    shortName: '68',
  },
  {
    name: 'Ralik Chain',
    shortName: 'L',
  },
  {
    name: 'Ratak Chain',
    shortName: 'T',
  },
  {
    name: 'Adrar',
    shortName: '07',
  },
  {
    name: 'Assaba',
    shortName: '03',
  },
  {
    name: 'Brakna',
    shortName: '05',
  },
  {
    name: 'Dakhlet Nouadhibou',
    shortName: '08',
  },
  {
    name: 'Gorgol',
    shortName: '04',
  },
  {
    name: 'Guidimaka',
    shortName: '10',
  },
  {
    name: 'Hodh Ech Chargui',
    shortName: '01',
  },
  {
    name: 'Hodh El Gharbi',
    shortName: '02',
  },
  {
    name: 'Inchiri',
    shortName: '12',
  },
  {
    name: 'Nouakchott-Nord',
    shortName: '14',
  },
  {
    name: 'Nouakchott-Ouest',
    shortName: '13',
  },
  {
    name: 'Nouakchott-Sud',
    shortName: '15',
  },
  {
    name: 'Tagant',
    shortName: '09',
  },
  {
    name: 'Tiris Zemmour',
    shortName: '11',
  },
  {
    name: 'Trarza',
    shortName: '06',
  },
  {
    name: 'Agalega Islands',
    shortName: 'AG',
  },
  {
    name: 'Black River',
    shortName: 'BL',
  },
  {
    name: 'Flacq',
    shortName: 'FL',
  },
  {
    name: 'Grand Port',
    shortName: 'GP',
  },
  {
    name: 'Moka',
    shortName: 'MO',
  },
  {
    name: 'Pamplemousses',
    shortName: 'PA',
  },
  {
    name: 'Plaines Wilhems',
    shortName: 'PW',
  },
  {
    name: 'Port Louis',
    shortName: 'PL',
  },
  {
    name: 'Rivière du Rempart',
    shortName: 'RR',
  },
  {
    name: 'Rodrigues Island',
    shortName: 'RO',
  },
  {
    name: 'Saint Brandon Islands',
    shortName: 'CC',
  },
  {
    name: 'Savanne',
    shortName: 'SA',
  },
  {
    name: 'Aguascalientes',
    shortName: 'AGU',
  },
  {
    name: 'Baja California',
    shortName: 'BCN',
  },
  {
    name: 'Baja California Sur',
    shortName: 'BCS',
  },
  {
    name: 'Campeche',
    shortName: 'CAM',
  },
  {
    name: 'Chiapas',
    shortName: 'CHP',
  },
  {
    name: 'Chihuahua',
    shortName: 'CHH',
  },
  {
    name: 'Ciudad de México',
    shortName: 'CDMX',
  },
  {
    name: 'Coahuila de Zaragoza',
    shortName: 'COA',
  },
  {
    name: 'Colima',
    shortName: 'COL',
  },
  {
    name: 'Durango',
    shortName: 'DUR',
  },
  {
    name: 'Estado de México',
    shortName: 'MEX',
  },
  {
    name: 'Guanajuato',
    shortName: 'GUA',
  },
  {
    name: 'Guerrero',
    shortName: 'GRO',
  },
  {
    name: 'Hidalgo',
    shortName: 'HID',
  },
  {
    name: 'Jalisco',
    shortName: 'JAL',
  },
  {
    name: 'Michoacán de Ocampo',
    shortName: 'MIC',
  },
  {
    name: 'Morelos',
    shortName: 'MOR',
  },
  {
    name: 'Nayarit',
    shortName: 'NAY',
  },
  {
    name: 'Nuevo León',
    shortName: 'NLE',
  },
  {
    name: 'Oaxaca',
    shortName: 'OAX',
  },
  {
    name: 'Puebla',
    shortName: 'PUE',
  },
  {
    name: 'Querétaro',
    shortName: 'QUE',
  },
  {
    name: 'Quintana Roo',
    shortName: 'ROO',
  },
  {
    name: 'San Luis Potosí',
    shortName: 'SLP',
  },
  {
    name: 'Sinaloa',
    shortName: 'SIN',
  },
  {
    name: 'Sonora',
    shortName: 'SON',
  },
  {
    name: 'Tabasco',
    shortName: 'TAB',
  },
  {
    name: 'Tamaulipas',
    shortName: 'TAM',
  },
  {
    name: 'Tlaxcala',
    shortName: 'TLA',
  },
  {
    name: 'Veracruz de Ignacio de la Llave',
    shortName: 'VER',
  },
  {
    name: 'Yucatán',
    shortName: 'YUC',
  },
  {
    name: 'Zacatecas',
    shortName: 'ZAC',
  },
  {
    name: 'Chuuk State',
    shortName: 'TRK',
  },
  {
    name: 'Kosrae State',
    shortName: 'KSA',
  },
  {
    name: 'Pohnpei State',
    shortName: 'PNI',
  },
  {
    name: 'Yap State',
    shortName: 'YAP',
  },
  {
    name: 'Anenii Noi District',
    shortName: 'AN',
  },
  {
    name: 'Bălți Municipality',
    shortName: 'BA',
  },
  {
    name: 'Basarabeasca District',
    shortName: 'BS',
  },
  {
    name: 'Bender Municipality',
    shortName: 'BD',
  },
  {
    name: 'Briceni District',
    shortName: 'BR',
  },
  {
    name: 'Cahul District',
    shortName: 'CA',
  },
  {
    name: 'Călărași District',
    shortName: 'CL',
  },
  {
    name: 'Cantemir District',
    shortName: 'CT',
  },
  {
    name: 'Căușeni District',
    shortName: 'CS',
  },
  {
    name: 'Chișinău Municipality',
    shortName: 'CU',
  },
  {
    name: 'Cimișlia District',
    shortName: 'CM',
  },
  {
    name: 'Criuleni District',
    shortName: 'CR',
  },
  {
    name: 'Dondușeni District',
    shortName: 'DO',
  },
  {
    name: 'Drochia District',
    shortName: 'DR',
  },
  {
    name: 'Dubăsari District',
    shortName: 'DU',
  },
  {
    name: 'Edineț District',
    shortName: 'ED',
  },
  {
    name: 'Fălești District',
    shortName: 'FA',
  },
  {
    name: 'Florești District',
    shortName: 'FL',
  },
  {
    name: 'Gagauzia',
    shortName: 'GA',
  },
  {
    name: 'Glodeni District',
    shortName: 'GL',
  },
  {
    name: 'Hîncești District',
    shortName: 'HI',
  },
  {
    name: 'Ialoveni District',
    shortName: 'IA',
  },
  {
    name: 'Nisporeni District',
    shortName: 'NI',
  },
  {
    name: 'Ocnița District',
    shortName: 'OC',
  },
  {
    name: 'Orhei District',
    shortName: 'OR',
  },
  {
    name: 'Rezina District',
    shortName: 'RE',
  },
  {
    name: 'Rîșcani District',
    shortName: 'RI',
  },
  {
    name: 'Sîngerei District',
    shortName: 'SI',
  },
  {
    name: 'Șoldănești District',
    shortName: 'SD',
  },
  {
    name: 'Soroca District',
    shortName: 'SO',
  },
  {
    name: 'Ștefan Vodă District',
    shortName: 'SV',
  },
  {
    name: 'Strășeni District',
    shortName: 'ST',
  },
  {
    name: 'Taraclia District',
    shortName: 'TA',
  },
  {
    name: 'Telenești District',
    shortName: 'TE',
  },
  {
    name: 'Transnistria autonomous territorial unit',
    shortName: 'SN',
  },
  {
    name: 'Ungheni District',
    shortName: 'UN',
  },
  {
    name: 'La Colle',
    shortName: 'CL',
  },
  {
    name: 'La Condamine',
    shortName: 'CO',
  },
  {
    name: 'Moneghetti',
    shortName: 'MG',
  },
  {
    name: 'Arkhangai Province',
    shortName: '073',
  },
  {
    name: 'Bayan-Ölgii Province',
    shortName: '071',
  },
  {
    name: 'Bayankhongor Province',
    shortName: '069',
  },
  {
    name: 'Bulgan Province',
    shortName: '067',
  },
  {
    name: 'Darkhan-Uul Province',
    shortName: '037',
  },
  {
    name: 'Dornod Province',
    shortName: '061',
  },
  {
    name: 'Dornogovi Province',
    shortName: '063',
  },
  {
    name: 'Dundgovi Province',
    shortName: '059',
  },
  {
    name: 'Govi-Altai Province',
    shortName: '065',
  },
  {
    name: 'Govisümber Province',
    shortName: '064',
  },
  {
    name: 'Khentii Province',
    shortName: '039',
  },
  {
    name: 'Khovd Province',
    shortName: '043',
  },
  {
    name: 'Khövsgöl Province',
    shortName: '041',
  },
  {
    name: 'Ömnögovi Province',
    shortName: '053',
  },
  {
    name: 'Orkhon Province',
    shortName: '035',
  },
  {
    name: 'Övörkhangai Province',
    shortName: '055',
  },
  {
    name: 'Selenge Province',
    shortName: '049',
  },
  {
    name: 'Sükhbaatar Province',
    shortName: '051',
  },
  {
    name: 'Töv Province',
    shortName: '047',
  },
  {
    name: 'Uvs Province',
    shortName: '046',
  },
  {
    name: 'Zavkhan Province',
    shortName: '057',
  },
  {
    name: 'Andrijevica Municipality',
    shortName: '01',
  },
  {
    name: 'Bar Municipality',
    shortName: '02',
  },
  {
    name: 'Berane Municipality',
    shortName: '03',
  },
  {
    name: 'Bijelo Polje Municipality',
    shortName: '04',
  },
  {
    name: 'Budva Municipality',
    shortName: '05',
  },
  {
    name: 'Danilovgrad Municipality',
    shortName: '07',
  },
  {
    name: 'Gusinje Municipality',
    shortName: '22',
  },
  {
    name: 'Kolašin Municipality',
    shortName: '09',
  },
  {
    name: 'Kotor Municipality',
    shortName: '10',
  },
  {
    name: 'Mojkovac Municipality',
    shortName: '11',
  },
  {
    name: 'Nikšić Municipality',
    shortName: '12',
  },
  {
    name: 'Old Royal Capital Cetinje',
    shortName: '06',
  },
  {
    name: 'Petnjica Municipality',
    shortName: '23',
  },
  {
    name: 'Plav Municipality',
    shortName: '13',
  },
  {
    name: 'Pljevlja Municipality',
    shortName: '14',
  },
  {
    name: 'Plužine Municipality',
    shortName: '15',
  },
  {
    name: 'Podgorica Municipality',
    shortName: '16',
  },
  {
    name: 'Rožaje Municipality',
    shortName: '17',
  },
  {
    name: 'Šavnik Municipality',
    shortName: '18',
  },
  {
    name: 'Tivat Municipality',
    shortName: '19',
  },
  {
    name: 'Ulcinj Municipality',
    shortName: '20',
  },
  {
    name: 'Žabljak Municipality',
    shortName: '21',
  },
  {
    name: 'Agadir-Ida-Ou-Tanane',
    shortName: 'AGD',
  },
  {
    name: 'Al Haouz',
    shortName: 'HAO',
  },
  {
    name: 'Al Hoceïma',
    shortName: 'HOC',
  },
  {
    name: 'Aousserd (EH)',
    shortName: 'AOU',
  },
  {
    name: 'Assa-Zag (EH-partial)',
    shortName: 'ASZ',
  },
  {
    name: 'Azilal',
    shortName: 'AZI',
  },
  {
    name: 'Béni Mellal',
    shortName: 'BEM',
  },
  {
    name: 'Béni Mellal-Khénifra',
    shortName: '05',
  },
  {
    name: 'Benslimane',
    shortName: 'BES',
  },
  {
    name: 'Berkane',
    shortName: 'BER',
  },
  {
    name: 'Berrechid',
    shortName: 'BRR',
  },
  {
    name: 'Boujdour (EH)',
    shortName: 'BOD',
  },
  {
    name: 'Boulemane',
    shortName: 'BOM',
  },
  {
    name: 'Casablanca',
    shortName: 'CAS',
  },
  {
    name: 'Casablanca-Settat',
    shortName: '06',
  },
  {
    name: 'Chefchaouen',
    shortName: 'CHE',
  },
  {
    name: 'Chichaoua',
    shortName: 'CHI',
  },
  {
    name: 'Chtouka-Ait Baha',
    shortName: 'CHT',
  },
  {
    name: 'Dakhla-Oued Ed-Dahab (EH)',
    shortName: '12',
  },
  {
    name: 'Drâa-Tafilalet',
    shortName: '08',
  },
  {
    name: 'Driouch',
    shortName: 'DRI',
  },
  {
    name: 'El Hajeb',
    shortName: 'HAJ',
  },
  {
    name: 'El Jadida',
    shortName: 'JDI',
  },
  {
    name: 'El Kelâa des Sraghna',
    shortName: 'KES',
  },
  {
    name: 'Errachidia',
    shortName: 'ERR',
  },
  {
    name: 'Es-Semara (EH-partial)',
    shortName: 'ESM',
  },
  {
    name: 'Essaouira',
    shortName: 'ESI',
  },
  {
    name: 'Fahs-Anjra',
    shortName: 'FAH',
  },
  {
    name: 'Fès',
    shortName: 'FES',
  },
  {
    name: 'Fès-Meknès',
    shortName: '03',
  },
  {
    name: 'Figuig',
    shortName: 'FIG',
  },
  {
    name: 'Fquih Ben Salah',
    shortName: 'FQH',
  },
  {
    name: 'Guelmim',
    shortName: 'GUE',
  },
  {
    name: 'Guelmim-Oued Noun (EH-partial)',
    shortName: '10',
  },
  {
    name: 'Guercif',
    shortName: 'GUF',
  },
  {
    name: 'Ifrane',
    shortName: 'IFR',
  },
  {
    name: 'Inezgane-Ait Melloul',
    shortName: 'INE',
  },
  {
    name: 'Jerada',
    shortName: 'JRA',
  },
  {
    name: 'Kénitra',
    shortName: 'KEN',
  },
  {
    name: 'Khémisset',
    shortName: 'KHE',
  },
  {
    name: 'Khénifra',
    shortName: 'KHN',
  },
  {
    name: 'Khouribga',
    shortName: 'KHO',
  },
  {
    name: "L'Oriental",
    shortName: '02',
  },
  {
    name: 'Laâyoune (EH)',
    shortName: 'LAA',
  },
  {
    name: 'Laâyoune-Sakia El Hamra (EH-partial)',
    shortName: '11',
  },
  {
    name: 'Larache',
    shortName: 'LAR',
  },
  {
    name: 'M’diq-Fnideq',
    shortName: 'MDF',
  },
  {
    name: 'Marrakech',
    shortName: 'MAR',
  },
  {
    name: 'Marrakesh-Safi',
    shortName: '07',
  },
  {
    name: 'Médiouna',
    shortName: 'MED',
  },
  {
    name: 'Meknès',
    shortName: 'MEK',
  },
  {
    name: 'Midelt',
    shortName: 'MID',
  },
  {
    name: 'Mohammadia',
    shortName: 'MOH',
  },
  {
    name: 'Moulay Yacoub',
    shortName: 'MOU',
  },
  {
    name: 'Nador',
    shortName: 'NAD',
  },
  {
    name: 'Nouaceur',
    shortName: 'NOU',
  },
  {
    name: 'Ouarzazate',
    shortName: 'OUA',
  },
  {
    name: 'Oued Ed-Dahab (EH)',
    shortName: 'OUD',
  },
  {
    name: 'Ouezzane',
    shortName: 'OUZ',
  },
  {
    name: 'Oujda-Angad',
    shortName: 'OUJ',
  },
  {
    name: 'Rabat',
    shortName: 'RAB',
  },
  {
    name: 'Rabat-Salé-Kénitra',
    shortName: '04',
  },
  {
    name: 'Rehamna',
    shortName: 'REH',
  },
  {
    name: 'Safi',
    shortName: 'SAF',
  },
  {
    name: 'Salé',
    shortName: 'SAL',
  },
  {
    name: 'Sefrou',
    shortName: 'SEF',
  },
  {
    name: 'Settat',
    shortName: 'SET',
  },
  {
    name: 'Sidi Bennour',
    shortName: 'SIB',
  },
  {
    name: 'Sidi Ifni',
    shortName: 'SIF',
  },
  {
    name: 'Sidi Kacem',
    shortName: 'SIK',
  },
  {
    name: 'Sidi Slimane',
    shortName: 'SIL',
  },
  {
    name: 'Skhirate-Témara',
    shortName: 'SKH',
  },
  {
    name: 'Souss-Massa',
    shortName: '09',
  },
  {
    name: 'Tan-Tan (EH-partial)',
    shortName: 'TNT',
  },
  {
    name: 'Tanger-Assilah',
    shortName: 'TNG',
  },
  {
    name: 'Tanger-Tétouan-Al Hoceïma',
    shortName: '01',
  },
  {
    name: 'Taounate',
    shortName: 'TAO',
  },
  {
    name: 'Taourirt',
    shortName: 'TAI',
  },
  {
    name: 'Tarfaya (EH-partial)',
    shortName: 'TAF',
  },
  {
    name: 'Taroudannt',
    shortName: 'TAR',
  },
  {
    name: 'Tata',
    shortName: 'TAT',
  },
  {
    name: 'Taza',
    shortName: 'TAZ',
  },
  {
    name: 'Tétouan',
    shortName: 'TET',
  },
  {
    name: 'Tinghir',
    shortName: 'TIN',
  },
  {
    name: 'Tiznit',
    shortName: 'TIZ',
  },
  {
    name: 'Youssoufia',
    shortName: 'YUS',
  },
  {
    name: 'Zagora',
    shortName: 'ZAG',
  },
  {
    name: 'Cabo Delgado Province',
    shortName: 'P',
  },
  {
    name: 'Gaza Province',
    shortName: 'G',
  },
  {
    name: 'Inhambane Province',
    shortName: 'I',
  },
  {
    name: 'Manica Province',
    shortName: 'B',
  },
  {
    name: 'Maputo',
    shortName: 'MPM',
  },
  {
    name: 'Maputo Province',
    shortName: 'L',
  },
  {
    name: 'Nampula Province',
    shortName: 'N',
  },
  {
    name: 'Niassa Province',
    shortName: 'A',
  },
  {
    name: 'Sofala Province',
    shortName: 'S',
  },
  {
    name: 'Tete Province',
    shortName: 'T',
  },
  {
    name: 'Zambezia Province',
    shortName: 'Q',
  },
  {
    name: 'Ayeyarwady Region',
    shortName: '07',
  },
  {
    name: 'Bago',
    shortName: '02',
  },
  {
    name: 'Chin State',
    shortName: '14',
  },
  {
    name: 'Kachin State',
    shortName: '11',
  },
  {
    name: 'Kayah State',
    shortName: '12',
  },
  {
    name: 'Kayin State',
    shortName: '13',
  },
  {
    name: 'Magway Region',
    shortName: '03',
  },
  {
    name: 'Mandalay Region',
    shortName: '04',
  },
  {
    name: 'Mon State',
    shortName: '15',
  },
  {
    name: 'Naypyidaw Union Territory',
    shortName: '18',
  },
  {
    name: 'Rakhine State',
    shortName: '16',
  },
  {
    name: 'Sagaing Region',
    shortName: '01',
  },
  {
    name: 'Shan State',
    shortName: '17',
  },
  {
    name: 'Tanintharyi Region',
    shortName: '05',
  },
  {
    name: 'Yangon Region',
    shortName: '06',
  },
  {
    name: 'Erongo Region',
    shortName: 'ER',
  },
  {
    name: 'Hardap Region',
    shortName: 'HA',
  },
  {
    name: 'Karas Region',
    shortName: 'KA',
  },
  {
    name: 'Kavango East Region',
    shortName: 'KE',
  },
  {
    name: 'Kavango West Region',
    shortName: 'KW',
  },
  {
    name: 'Khomas Region',
    shortName: 'KH',
  },
  {
    name: 'Kunene Region',
    shortName: 'KU',
  },
  {
    name: 'Ohangwena Region',
    shortName: 'OW',
  },
  {
    name: 'Omaheke Region',
    shortName: 'OH',
  },
  {
    name: 'Omusati Region',
    shortName: 'OS',
  },
  {
    name: 'Oshana Region',
    shortName: 'ON',
  },
  {
    name: 'Oshikoto Region',
    shortName: 'OT',
  },
  {
    name: 'Otjozondjupa Region',
    shortName: 'OD',
  },
  {
    name: 'Zambezi Region',
    shortName: 'CA',
  },
  {
    name: 'Aiwo District',
    shortName: '01',
  },
  {
    name: 'Anabar District',
    shortName: '02',
  },
  {
    name: 'Anetan District',
    shortName: '03',
  },
  {
    name: 'Anibare District',
    shortName: '04',
  },
  {
    name: 'Baiti District',
    shortName: '05',
  },
  {
    name: 'Boe District',
    shortName: '06',
  },
  {
    name: 'Buada District',
    shortName: '07',
  },
  {
    name: 'Denigomodu District',
    shortName: '08',
  },
  {
    name: 'Ewa District',
    shortName: '09',
  },
  {
    name: 'Ijuw District',
    shortName: '10',
  },
  {
    name: 'Meneng District',
    shortName: '11',
  },
  {
    name: 'Nibok District',
    shortName: '12',
  },
  {
    name: 'Uaboe District',
    shortName: '13',
  },
  {
    name: 'Yaren District',
    shortName: '14',
  },
  {
    name: 'Bagmati Zone',
    shortName: 'BA',
  },
  {
    name: 'Bheri Zone',
    shortName: 'BH',
  },
  {
    name: 'Central Region',
    shortName: '1',
  },
  {
    name: 'Dhaulagiri Zone',
    shortName: 'DH',
  },
  {
    name: 'Eastern Development Region',
    shortName: '4',
  },
  {
    name: 'Far-Western Development Region',
    shortName: '5',
  },
  {
    name: 'Gandaki Zone',
    shortName: 'GA',
  },
  {
    name: 'Janakpur Zone',
    shortName: 'JA',
  },
  {
    name: 'Karnali Zone',
    shortName: 'KA',
  },
  {
    name: 'Kosi Zone',
    shortName: 'KO',
  },
  {
    name: 'Lumbini Zone',
    shortName: 'LU',
  },
  {
    name: 'Mahakali Zone',
    shortName: 'MA',
  },
  {
    name: 'Mechi Zone',
    shortName: 'ME',
  },
  {
    name: 'Mid-Western Region',
    shortName: '2',
  },
  {
    name: 'Narayani Zone',
    shortName: 'NA',
  },
  {
    name: 'Rapti Zone',
    shortName: 'RA',
  },
  {
    name: 'Sagarmatha Zone',
    shortName: 'SA',
  },
  {
    name: 'Seti Zone',
    shortName: 'SE',
  },
  {
    name: 'Western Region',
    shortName: '3',
  },
  {
    name: 'Bonaire',
    shortName: 'BQ1',
  },
  {
    name: 'Drenthe',
    shortName: 'DR',
  },
  {
    name: 'Flevoland',
    shortName: 'FL',
  },
  {
    name: 'Friesland',
    shortName: 'FR',
  },
  {
    name: 'Gelderland',
    shortName: 'GE',
  },
  {
    name: 'Groningen',
    shortName: 'GR',
  },
  {
    name: 'Limburg',
    shortName: 'LI',
  },
  {
    name: 'North Brabant',
    shortName: 'NB',
  },
  {
    name: 'North Holland',
    shortName: 'NH',
  },
  {
    name: 'Overijssel',
    shortName: 'OV',
  },
  {
    name: 'Saba',
    shortName: 'BQ2',
  },
  {
    name: 'Sint Eustatius',
    shortName: 'BQ3',
  },
  {
    name: 'South Holland',
    shortName: 'ZH',
  },
  {
    name: 'Utrecht',
    shortName: 'UT',
  },
  {
    name: 'Zeeland',
    shortName: 'ZE',
  },
  {
    name: 'Auckland Region',
    shortName: 'AUK',
  },
  {
    name: 'Bay of Plenty Region',
    shortName: 'BOP',
  },
  {
    name: 'Canterbury Region',
    shortName: 'CAN',
  },
  {
    name: 'Chatham Islands',
    shortName: 'CIT',
  },
  {
    name: 'Gisborne District',
    shortName: 'GIS',
  },
  {
    name: "Hawke's Bay Region",
    shortName: 'HKB',
  },
  {
    name: 'Manawatu-Wanganui Region',
    shortName: 'MWT',
  },
  {
    name: 'Marlborough Region',
    shortName: 'MBH',
  },
  {
    name: 'Nelson Region',
    shortName: 'NSN',
  },
  {
    name: 'Northland Region',
    shortName: 'NTL',
  },
  {
    name: 'Otago Region',
    shortName: 'OTA',
  },
  {
    name: 'Southland Region',
    shortName: 'STL',
  },
  {
    name: 'Taranaki Region',
    shortName: 'TKI',
  },
  {
    name: 'Tasman District',
    shortName: 'TAS',
  },
  {
    name: 'Waikato Region',
    shortName: 'WKO',
  },
  {
    name: 'Wellington Region',
    shortName: 'WGN',
  },
  {
    name: 'West Coast Region',
    shortName: 'WTC',
  },
  {
    name: 'Boaco',
    shortName: 'BO',
  },
  {
    name: 'Carazo',
    shortName: 'CA',
  },
  {
    name: 'Chinandega',
    shortName: 'CI',
  },
  {
    name: 'Chontales',
    shortName: 'CO',
  },
  {
    name: 'Estelí',
    shortName: 'ES',
  },
  {
    name: 'Granada',
    shortName: 'GR',
  },
  {
    name: 'Jinotega',
    shortName: 'JI',
  },
  {
    name: 'León',
    shortName: 'LE',
  },
  {
    name: 'Madriz',
    shortName: 'MD',
  },
  {
    name: 'Managua',
    shortName: 'MN',
  },
  {
    name: 'Masaya',
    shortName: 'MS',
  },
  {
    name: 'Matagalpa',
    shortName: 'MT',
  },
  {
    name: 'North Caribbean Coast',
    shortName: 'AN',
  },
  {
    name: 'Nueva Segovia',
    shortName: 'NS',
  },
  {
    name: 'Río San Juan',
    shortName: 'SJ',
  },
  {
    name: 'Rivas',
    shortName: 'RI',
  },
  {
    name: 'South Caribbean Coast',
    shortName: 'AS',
  },
  {
    name: 'Agadez Region',
    shortName: '1',
  },
  {
    name: 'Diffa Region',
    shortName: '2',
  },
  {
    name: 'Dosso Region',
    shortName: '3',
  },
  {
    name: 'Maradi Region',
    shortName: '4',
  },
  {
    name: 'Tahoua Region',
    shortName: '5',
  },
  {
    name: 'Tillabéri Region',
    shortName: '6',
  },
  {
    name: 'Zinder Region',
    shortName: '7',
  },
  {
    name: 'Abia',
    shortName: 'AB',
  },
  {
    name: 'Abuja Federal Capital Territory',
    shortName: 'FC',
  },
  {
    name: 'Adamawa',
    shortName: 'AD',
  },
  {
    name: 'Akwa Ibom',
    shortName: 'AK',
  },
  {
    name: 'Anambra',
    shortName: 'AN',
  },
  {
    name: 'Bauchi',
    shortName: 'BA',
  },
  {
    name: 'Bayelsa',
    shortName: 'BY',
  },
  {
    name: 'Benue',
    shortName: 'BE',
  },
  {
    name: 'Borno',
    shortName: 'BO',
  },
  {
    name: 'Cross River',
    shortName: 'CR',
  },
  {
    name: 'Delta',
    shortName: 'DE',
  },
  {
    name: 'Ebonyi',
    shortName: 'EB',
  },
  {
    name: 'Edo',
    shortName: 'ED',
  },
  {
    name: 'Ekiti',
    shortName: 'EK',
  },
  {
    name: 'Enugu',
    shortName: 'EN',
  },
  {
    name: 'Gombe',
    shortName: 'GO',
  },
  {
    name: 'Imo',
    shortName: 'IM',
  },
  {
    name: 'Jigawa',
    shortName: 'JI',
  },
  {
    name: 'Kaduna',
    shortName: 'KD',
  },
  {
    name: 'Kano',
    shortName: 'KN',
  },
  {
    name: 'Katsina',
    shortName: 'KT',
  },
  {
    name: 'Kebbi',
    shortName: 'KE',
  },
  {
    name: 'Kogi',
    shortName: 'KO',
  },
  {
    name: 'Kwara',
    shortName: 'KW',
  },
  {
    name: 'Lagos',
    shortName: 'LA',
  },
  {
    name: 'Nasarawa',
    shortName: 'NA',
  },
  {
    name: 'Niger',
    shortName: 'NI',
  },
  {
    name: 'Ogun',
    shortName: 'OG',
  },
  {
    name: 'Ondo',
    shortName: 'ON',
  },
  {
    name: 'Osun',
    shortName: 'OS',
  },
  {
    name: 'Oyo',
    shortName: 'OY',
  },
  {
    name: 'Plateau',
    shortName: 'PL',
  },
  {
    name: 'Rivers',
    shortName: 'RI',
  },
  {
    name: 'Sokoto',
    shortName: 'SO',
  },
  {
    name: 'Taraba',
    shortName: 'TA',
  },
  {
    name: 'Yobe',
    shortName: 'YO',
  },
  {
    name: 'Zamfara',
    shortName: 'ZA',
  },
  {
    name: 'Chagang Province',
    shortName: '04',
  },
  {
    name: 'Kangwon Province',
    shortName: '07',
  },
  {
    name: 'North Hamgyong Province',
    shortName: '09',
  },
  {
    name: 'North Hwanghae Province',
    shortName: '06',
  },
  {
    name: 'North Pyongan Province',
    shortName: '03',
  },
  {
    name: 'Pyongyang',
    shortName: '01',
  },
  {
    name: 'Rason',
    shortName: '13',
  },
  {
    name: 'Ryanggang Province',
    shortName: '10',
  },
  {
    name: 'South Hamgyong Province',
    shortName: '08',
  },
  {
    name: 'South Hwanghae Province',
    shortName: '05',
  },
  {
    name: 'South Pyongan Province',
    shortName: '02',
  },
  {
    name: 'Agder',
    shortName: '42',
  },
  {
    name: 'Innlandet',
    shortName: '34',
  },
  {
    name: 'Jan Mayen',
    shortName: '22',
  },
  {
    name: 'Møre og Romsdal',
    shortName: '15',
  },
  {
    name: 'Nordland',
    shortName: '18',
  },
  {
    name: 'Oslo',
    shortName: '03',
  },
  {
    name: 'Rogaland',
    shortName: '11',
  },
  {
    name: 'Svalbard',
    shortName: '21',
  },
  {
    name: 'Troms og Finnmark',
    shortName: '54',
  },
  {
    name: 'Trøndelag',
    shortName: '50',
  },
  {
    name: 'Vestfold og Telemark',
    shortName: '38',
  },
  {
    name: 'Vestland',
    shortName: '46',
  },
  {
    name: 'Viken',
    shortName: '30',
  },
  {
    name: 'Ad Dakhiliyah',
    shortName: 'DA',
  },
  {
    name: 'Ad Dhahirah',
    shortName: 'ZA',
  },
  {
    name: 'Al Batinah North',
    shortName: 'BS',
  },
  {
    name: 'Al Batinah Region',
    shortName: 'BA',
  },
  {
    name: 'Al Batinah South',
    shortName: 'BJ',
  },
  {
    name: 'Al Buraimi',
    shortName: 'BU',
  },
  {
    name: 'Al Wusta',
    shortName: 'WU',
  },
  {
    name: 'Ash Sharqiyah North',
    shortName: 'SS',
  },
  {
    name: 'Ash Sharqiyah Region',
    shortName: 'SH',
  },
  {
    name: 'Ash Sharqiyah South',
    shortName: 'SJ',
  },
  {
    name: 'Dhofar',
    shortName: 'ZU',
  },
  {
    name: 'Musandam',
    shortName: 'MU',
  },
  {
    name: 'Muscat',
    shortName: 'MA',
  },
  {
    name: 'Azad Kashmir',
    shortName: 'JK',
  },
  {
    name: 'Balochistan',
    shortName: 'BA',
  },
  {
    name: 'Federally Administered Tribal Areas',
    shortName: 'TA',
  },
  {
    name: 'Gilgit-Baltistan',
    shortName: 'GB',
  },
  {
    name: 'Islamabad Capital Territory',
    shortName: 'IS',
  },
  {
    name: 'Khyber Pakhtunkhwa',
    shortName: 'KP',
  },
  {
    name: 'Punjab',
    shortName: 'PB',
  },
  {
    name: 'Sindh',
    shortName: 'SD',
  },
  {
    name: 'Aimeliik',
    shortName: '002',
  },
  {
    name: 'Airai',
    shortName: '004',
  },
  {
    name: 'Angaur',
    shortName: '010',
  },
  {
    name: 'Hatohobei',
    shortName: '050',
  },
  {
    name: 'Kayangel',
    shortName: '100',
  },
  {
    name: 'Koror',
    shortName: '150',
  },
  {
    name: 'Melekeok',
    shortName: '212',
  },
  {
    name: 'Ngaraard',
    shortName: '214',
  },
  {
    name: 'Ngarchelong',
    shortName: '218',
  },
  {
    name: 'Ngardmau',
    shortName: '222',
  },
  {
    name: 'Ngatpang',
    shortName: '224',
  },
  {
    name: 'Ngchesar',
    shortName: '226',
  },
  {
    name: 'Ngeremlengui',
    shortName: '227',
  },
  {
    name: 'Ngiwal',
    shortName: '228',
  },
  {
    name: 'Peleliu',
    shortName: '350',
  },
  {
    name: 'Sonsorol',
    shortName: '370',
  },
  {
    name: 'Bethlehem',
    shortName: 'BTH',
  },
  {
    name: 'Deir El Balah',
    shortName: 'DEB',
  },
  {
    name: 'Gaza',
    shortName: 'GZA',
  },
  {
    name: 'Hebron',
    shortName: 'HBN',
  },
  {
    name: 'Jenin',
    shortName: 'JEN',
  },
  {
    name: 'Jericho and Al Aghwar',
    shortName: 'JRH',
  },
  {
    name: 'Jerusalem',
    shortName: 'JEM',
  },
  {
    name: 'Khan Yunis',
    shortName: 'KYS',
  },
  {
    name: 'Nablus',
    shortName: 'NBS',
  },
  {
    name: 'North Gaza',
    shortName: 'NGZ',
  },
  {
    name: 'Qalqilya',
    shortName: 'QQA',
  },
  {
    name: 'Rafah',
    shortName: 'RFH',
  },
  {
    name: 'Ramallah',
    shortName: 'RBH',
  },
  {
    name: 'Salfit',
    shortName: 'SLT',
  },
  {
    name: 'Tubas',
    shortName: 'TBS',
  },
  {
    name: 'Tulkarm',
    shortName: 'TKM',
  },
  {
    name: 'Bocas del Toro Province',
    shortName: '1',
  },
  {
    name: 'Chiriquí Province',
    shortName: '4',
  },
  {
    name: 'Coclé Province',
    shortName: '2',
  },
  {
    name: 'Colón Province',
    shortName: '3',
  },
  {
    name: 'Darién Province',
    shortName: '5',
  },
  {
    name: 'Emberá-Wounaan Comarca',
    shortName: 'EM',
  },
  {
    name: 'Guna Yala',
    shortName: 'KY',
  },
  {
    name: 'Herrera Province',
    shortName: '6',
  },
  {
    name: 'Los Santos Province',
    shortName: '7',
  },
  {
    name: 'Ngöbe-Buglé Comarca',
    shortName: 'NB',
  },
  {
    name: 'Panamá Oeste Province',
    shortName: '10',
  },
  {
    name: 'Panamá Province',
    shortName: '8',
  },
  {
    name: 'Veraguas Province',
    shortName: '9',
  },
  {
    name: 'Bougainville',
    shortName: 'NSB',
  },
  {
    name: 'Central Province',
    shortName: 'CPM',
  },
  {
    name: 'Chimbu Province',
    shortName: 'CPK',
  },
  {
    name: 'East New Britain',
    shortName: 'EBR',
  },
  {
    name: 'Eastern Highlands Province',
    shortName: 'EHG',
  },
  {
    name: 'Enga Province',
    shortName: 'EPW',
  },
  {
    name: 'Gulf',
    shortName: 'GPK',
  },
  {
    name: 'Hela',
    shortName: 'HLA',
  },
  {
    name: 'Jiwaka Province',
    shortName: 'JWK',
  },
  {
    name: 'Madang Province',
    shortName: 'MPM',
  },
  {
    name: 'Manus Province',
    shortName: 'MRL',
  },
  {
    name: 'Milne Bay Province',
    shortName: 'MBA',
  },
  {
    name: 'Morobe Province',
    shortName: 'MPL',
  },
  {
    name: 'New Ireland Province',
    shortName: 'NIK',
  },
  {
    name: 'Oro Province',
    shortName: 'NPP',
  },
  {
    name: 'Port Moresby',
    shortName: 'NCD',
  },
  {
    name: 'Sandaun Province',
    shortName: 'SAN',
  },
  {
    name: 'Southern Highlands Province',
    shortName: 'SHM',
  },
  {
    name: 'West New Britain Province',
    shortName: 'WBK',
  },
  {
    name: 'Western Highlands Province',
    shortName: 'WHM',
  },
  {
    name: 'Western Province',
    shortName: 'WPD',
  },
  {
    name: 'Alto Paraguay Department',
    shortName: '16',
  },
  {
    name: 'Alto Paraná Department',
    shortName: '10',
  },
  {
    name: 'Amambay Department',
    shortName: '13',
  },
  {
    name: 'Boquerón Department',
    shortName: '19',
  },
  {
    name: 'Caaguazú',
    shortName: '5',
  },
  {
    name: 'Caazapá',
    shortName: '6',
  },
  {
    name: 'Canindeyú',
    shortName: '14',
  },
  {
    name: 'Central Department',
    shortName: '11',
  },
  {
    name: 'Concepción Department',
    shortName: '1',
  },
  {
    name: 'Cordillera Department',
    shortName: '3',
  },
  {
    name: 'Guairá Department',
    shortName: '4',
  },
  {
    name: 'Itapúa',
    shortName: '7',
  },
  {
    name: 'Misiones Department',
    shortName: '8',
  },
  {
    name: 'Ñeembucú Department',
    shortName: '12',
  },
  {
    name: 'Paraguarí Department',
    shortName: '9',
  },
  {
    name: 'Presidente Hayes Department',
    shortName: '15',
  },
  {
    name: 'San Pedro Department',
    shortName: '2',
  },
  {
    name: 'Amazonas',
    shortName: 'AMA',
  },
  {
    name: 'Áncash',
    shortName: 'ANC',
  },
  {
    name: 'Apurímac',
    shortName: 'APU',
  },
  {
    name: 'Arequipa',
    shortName: 'ARE',
  },
  {
    name: 'Ayacucho',
    shortName: 'AYA',
  },
  {
    name: 'Cajamarca',
    shortName: 'CAJ',
  },
  {
    name: 'Callao',
    shortName: 'CAL',
  },
  {
    name: 'Cusco',
    shortName: 'CUS',
  },
  {
    name: 'Huancavelica',
    shortName: 'HUV',
  },
  {
    name: 'Huanuco',
    shortName: 'HUC',
  },
  {
    name: 'Ica',
    shortName: 'ICA',
  },
  {
    name: 'Junín',
    shortName: 'JUN',
  },
  {
    name: 'La Libertad',
    shortName: 'LAL',
  },
  {
    name: 'Lambayeque',
    shortName: 'LAM',
  },
  {
    name: 'Lima',
    shortName: 'LIM',
  },
  {
    name: 'Loreto',
    shortName: 'LOR',
  },
  {
    name: 'Madre de Dios',
    shortName: 'MDD',
  },
  {
    name: 'Moquegua',
    shortName: 'MOQ',
  },
  {
    name: 'Pasco',
    shortName: 'PAS',
  },
  {
    name: 'Piura',
    shortName: 'PIU',
  },
  {
    name: 'Puno',
    shortName: 'PUN',
  },
  {
    name: 'San Martín',
    shortName: 'SAM',
  },
  {
    name: 'Tacna',
    shortName: 'TAC',
  },
  {
    name: 'Tumbes',
    shortName: 'TUM',
  },
  {
    name: 'Ucayali',
    shortName: 'UCA',
  },
  {
    name: 'Abra',
    shortName: 'ABR',
  },
  {
    name: 'Agusan del Norte',
    shortName: 'AGN',
  },
  {
    name: 'Agusan del Sur',
    shortName: 'AGS',
  },
  {
    name: 'Aklan',
    shortName: 'AKL',
  },
  {
    name: 'Albay',
    shortName: 'ALB',
  },
  {
    name: 'Antique',
    shortName: 'ANT',
  },
  {
    name: 'Apayao',
    shortName: 'APA',
  },
  {
    name: 'Aurora',
    shortName: 'AUR',
  },
  {
    name: 'Autonomous Region in Muslim Mindanao',
    shortName: '14',
  },
  {
    name: 'Basilan',
    shortName: 'BAS',
  },
  {
    name: 'Bataan',
    shortName: 'BAN',
  },
  {
    name: 'Batanes',
    shortName: 'BTN',
  },
  {
    name: 'Batangas',
    shortName: 'BTG',
  },
  {
    name: 'Benguet',
    shortName: 'BEN',
  },
  {
    name: 'Bicol',
    shortName: '05',
  },
  {
    name: 'Biliran',
    shortName: 'BIL',
  },
  {
    name: 'Bohol',
    shortName: 'BOH',
  },
  {
    name: 'Bukidnon',
    shortName: 'BUK',
  },
  {
    name: 'Bulacan',
    shortName: 'BUL',
  },
  {
    name: 'Cagayan',
    shortName: 'CAG',
  },
  {
    name: 'Cagayan Valley',
    shortName: '02',
  },
  {
    name: 'Calabarzon',
    shortName: '40',
  },
  {
    name: 'Camarines Norte',
    shortName: 'CAN',
  },
  {
    name: 'Camarines Sur',
    shortName: 'CAS',
  },
  {
    name: 'Camiguin',
    shortName: 'CAM',
  },
  {
    name: 'Capiz',
    shortName: 'CAP',
  },
  {
    name: 'Caraga',
    shortName: '13',
  },
  {
    name: 'Catanduanes',
    shortName: 'CAT',
  },
  {
    name: 'Cavite',
    shortName: 'CAV',
  },
  {
    name: 'Cebu',
    shortName: 'CEB',
  },
  {
    name: 'Central Luzon',
    shortName: '03',
  },
  {
    name: 'Central Visayas',
    shortName: '07',
  },
  {
    name: 'Compostela Valley',
    shortName: 'COM',
  },
  {
    name: 'Cordillera Administrative',
    shortName: '15',
  },
  {
    name: 'Cotabato',
    shortName: 'NCO',
  },
  {
    name: 'Davao',
    shortName: '11',
  },
  {
    name: 'Davao del Norte',
    shortName: 'DAV',
  },
  {
    name: 'Davao del Sur',
    shortName: 'DAS',
  },
  {
    name: 'Davao Occidental',
    shortName: 'DVO',
  },
  {
    name: 'Davao Oriental',
    shortName: 'DAO',
  },
  {
    name: 'Dinagat Islands',
    shortName: 'DIN',
  },
  {
    name: 'Eastern Samar',
    shortName: 'EAS',
  },
  {
    name: 'Eastern Visayas',
    shortName: '08',
  },
  {
    name: 'Guimaras',
    shortName: 'GUI',
  },
  {
    name: 'Ifugao',
    shortName: 'IFU',
  },
  {
    name: 'Ilocos',
    shortName: '01',
  },
  {
    name: 'Ilocos Norte',
    shortName: 'ILN',
  },
  {
    name: 'Ilocos Sur',
    shortName: 'ILS',
  },
  {
    name: 'Iloilo',
    shortName: 'ILI',
  },
  {
    name: 'Isabela',
    shortName: 'ISA',
  },
  {
    name: 'Kalinga',
    shortName: 'KAL',
  },
  {
    name: 'La Union',
    shortName: 'LUN',
  },
  {
    name: 'Laguna',
    shortName: 'LAG',
  },
  {
    name: 'Lanao del Norte',
    shortName: 'LAN',
  },
  {
    name: 'Lanao del Sur',
    shortName: 'LAS',
  },
  {
    name: 'Leyte',
    shortName: 'LEY',
  },
  {
    name: 'Maguindanao',
    shortName: 'MAG',
  },
  {
    name: 'Marinduque',
    shortName: 'MAD',
  },
  {
    name: 'Masbate',
    shortName: 'MAS',
  },
  {
    name: 'Metro Manila',
    shortName: 'NCR',
  },
  {
    name: 'Mimaropa',
    shortName: '41',
  },
  {
    name: 'Misamis Occidental',
    shortName: 'MSC',
  },
  {
    name: 'Misamis Oriental',
    shortName: 'MSR',
  },
  {
    name: 'Mountain Province',
    shortName: 'MOU',
  },
  {
    name: 'Negros Occidental',
    shortName: 'NEC',
  },
  {
    name: 'Negros Oriental',
    shortName: 'NER',
  },
  {
    name: 'Northern Mindanao',
    shortName: '10',
  },
  {
    name: 'Northern Samar',
    shortName: 'NSA',
  },
  {
    name: 'Nueva Ecija',
    shortName: 'NUE',
  },
  {
    name: 'Nueva Vizcaya',
    shortName: 'NUV',
  },
  {
    name: 'Occidental Mindoro',
    shortName: 'MDC',
  },
  {
    name: 'Oriental Mindoro',
    shortName: 'MDR',
  },
  {
    name: 'Palawan',
    shortName: 'PLW',
  },
  {
    name: 'Pampanga',
    shortName: 'PAM',
  },
  {
    name: 'Pangasinan',
    shortName: 'PAN',
  },
  {
    name: 'Quezon',
    shortName: 'QUE',
  },
  {
    name: 'Quirino',
    shortName: 'QUI',
  },
  {
    name: 'Rizal',
    shortName: 'RIZ',
  },
  {
    name: 'Romblon',
    shortName: 'ROM',
  },
  {
    name: 'Sarangani',
    shortName: 'SAR',
  },
  {
    name: 'Siquijor',
    shortName: 'SIG',
  },
  {
    name: 'Soccsksargen',
    shortName: '12',
  },
  {
    name: 'Sorsogon',
    shortName: 'SOR',
  },
  {
    name: 'South Cotabato',
    shortName: 'SCO',
  },
  {
    name: 'Southern Leyte',
    shortName: 'SLE',
  },
  {
    name: 'Sultan Kudarat',
    shortName: 'SUK',
  },
  {
    name: 'Sulu',
    shortName: 'SLU',
  },
  {
    name: 'Surigao del Norte',
    shortName: 'SUN',
  },
  {
    name: 'Surigao del Sur',
    shortName: 'SUR',
  },
  {
    name: 'Tarlac',
    shortName: 'TAR',
  },
  {
    name: 'Tawi-Tawi',
    shortName: 'TAW',
  },
  {
    name: 'Western Samar',
    shortName: 'WSA',
  },
  {
    name: 'Western Visayas',
    shortName: '06',
  },
  {
    name: 'Zambales',
    shortName: 'ZMB',
  },
  {
    name: 'Zamboanga del Norte',
    shortName: 'ZAN',
  },
  {
    name: 'Zamboanga del Sur',
    shortName: 'ZAS',
  },
  {
    name: 'Zamboanga Peninsula',
    shortName: '09',
  },
  {
    name: 'Zamboanga Sibugay',
    shortName: 'ZSI',
  },
  {
    name: 'Greater Poland Voivodeship',
    shortName: 'WP',
  },
  {
    name: 'Kuyavian-Pomeranian Voivodeship',
    shortName: 'KP',
  },
  {
    name: 'Lesser Poland Voivodeship',
    shortName: 'MA',
  },
  {
    name: 'Lower Silesian Voivodeship',
    shortName: 'DS',
  },
  {
    name: 'Lublin Voivodeship',
    shortName: 'LU',
  },
  {
    name: 'Lubusz Voivodeship',
    shortName: 'LB',
  },
  {
    name: 'Łódź Voivodeship',
    shortName: 'LD',
  },
  {
    name: 'Masovian Voivodeship',
    shortName: 'MZ',
  },
  {
    name: 'Opole Voivodeship',
    shortName: 'OP',
  },
  {
    name: 'Podkarpackie Voivodeship',
    shortName: 'PK',
  },
  {
    name: 'Podlaskie Voivodeship',
    shortName: 'PD',
  },
  {
    name: 'Pomeranian Voivodeship',
    shortName: 'PM',
  },
  {
    name: 'Silesian Voivodeship',
    shortName: 'SL',
  },
  {
    name: 'Świętokrzyskie Voivodeship',
    shortName: 'SK',
  },
  {
    name: 'Warmian-Masurian Voivodeship',
    shortName: 'WN',
  },
  {
    name: 'West Pomeranian Voivodeship',
    shortName: 'ZP',
  },
  {
    name: 'Açores',
    shortName: '20',
  },
  {
    name: 'Aveiro',
    shortName: '01',
  },
  {
    name: 'Beja',
    shortName: '02',
  },
  {
    name: 'Braga',
    shortName: '03',
  },
  {
    name: 'Bragança',
    shortName: '04',
  },
  {
    name: 'Castelo Branco',
    shortName: '05',
  },
  {
    name: 'Coimbra',
    shortName: '06',
  },
  {
    name: 'Évora',
    shortName: '07',
  },
  {
    name: 'Faro',
    shortName: '08',
  },
  {
    name: 'Guarda',
    shortName: '09',
  },
  {
    name: 'Leiria',
    shortName: '10',
  },
  {
    name: 'Lisbon',
    shortName: '11',
  },
  {
    name: 'Madeira',
    shortName: '30',
  },
  {
    name: 'Portalegre',
    shortName: '12',
  },
  {
    name: 'Porto',
    shortName: '13',
  },
  {
    name: 'Santarém',
    shortName: '14',
  },
  {
    name: 'Setúbal',
    shortName: '15',
  },
  {
    name: 'Viana do Castelo',
    shortName: '16',
  },
  {
    name: 'Vila Real',
    shortName: '17',
  },
  {
    name: 'Viseu',
    shortName: '18',
  },
  {
    name: 'Arecibo',
    shortName: 'AR',
  },
  {
    name: 'Bayamon',
    shortName: 'BY',
  },
  {
    name: 'Caguas',
    shortName: 'CG',
  },
  {
    name: 'Carolina',
    shortName: 'CL',
  },
  {
    name: 'Guaynabo',
    shortName: 'GN',
  },
  {
    name: 'Mayagüez',
    shortName: 'MG',
  },
  {
    name: 'Ponce',
    shortName: 'PO',
  },
  {
    name: 'San Juan',
    shortName: 'SJ',
  },
  {
    name: 'Toa Baja',
    shortName: 'TB',
  },
  {
    name: 'Trujillo Alto',
    shortName: 'TA',
  },
  {
    name: 'Al Daayen',
    shortName: 'ZA',
  },
  {
    name: 'Al Khor',
    shortName: 'KH',
  },
  {
    name: 'Al Rayyan Municipality',
    shortName: 'RA',
  },
  {
    name: 'Al Wakrah',
    shortName: 'WA',
  },
  {
    name: 'Al-Shahaniya',
    shortName: 'SH',
  },
  {
    name: 'Doha',
    shortName: 'DA',
  },
  {
    name: 'Madinat ash Shamal',
    shortName: 'MS',
  },
  {
    name: 'Umm Salal Municipality',
    shortName: 'US',
  },
  {
    name: 'Alba',
    shortName: 'AB',
  },
  {
    name: 'Arad County',
    shortName: 'AR',
  },
  {
    name: 'Arges',
    shortName: 'AG',
  },
  {
    name: 'Bacău County',
    shortName: 'BC',
  },
  {
    name: 'Bihor County',
    shortName: 'BH',
  },
  {
    name: 'Bistrița-Năsăud County',
    shortName: 'BN',
  },
  {
    name: 'Botoșani County',
    shortName: 'BT',
  },
  {
    name: 'Braila',
    shortName: 'BR',
  },
  {
    name: 'Brașov County',
    shortName: 'BV',
  },
  {
    name: 'Bucharest',
    shortName: 'B',
  },
  {
    name: 'Buzău County',
    shortName: 'BZ',
  },
  {
    name: 'Călărași County',
    shortName: 'CL',
  },
  {
    name: 'Caraș-Severin County',
    shortName: 'CS',
  },
  {
    name: 'Cluj County',
    shortName: 'CJ',
  },
  {
    name: 'Constanța County',
    shortName: 'CT',
  },
  {
    name: 'Covasna County',
    shortName: 'CV',
  },
  {
    name: 'Dâmbovița County',
    shortName: 'DB',
  },
  {
    name: 'Dolj County',
    shortName: 'DJ',
  },
  {
    name: 'Galați County',
    shortName: 'GL',
  },
  {
    name: 'Giurgiu County',
    shortName: 'GR',
  },
  {
    name: 'Gorj County',
    shortName: 'GJ',
  },
  {
    name: 'Harghita County',
    shortName: 'HR',
  },
  {
    name: 'Hunedoara County',
    shortName: 'HD',
  },
  {
    name: 'Ialomița County',
    shortName: 'IL',
  },
  {
    name: 'Iași County',
    shortName: 'IS',
  },
  {
    name: 'Ilfov County',
    shortName: 'IF',
  },
  {
    name: 'Maramureș County',
    shortName: 'MM',
  },
  {
    name: 'Mehedinți County',
    shortName: 'MH',
  },
  {
    name: 'Mureș County',
    shortName: 'MS',
  },
  {
    name: 'Neamț County',
    shortName: 'NT',
  },
  {
    name: 'Olt County',
    shortName: 'OT',
  },
  {
    name: 'Prahova County',
    shortName: 'PH',
  },
  {
    name: 'Sălaj County',
    shortName: 'SJ',
  },
  {
    name: 'Satu Mare County',
    shortName: 'SM',
  },
  {
    name: 'Sibiu County',
    shortName: 'SB',
  },
  {
    name: 'Suceava County',
    shortName: 'SV',
  },
  {
    name: 'Teleorman County',
    shortName: 'TR',
  },
  {
    name: 'Timiș County',
    shortName: 'TM',
  },
  {
    name: 'Tulcea County',
    shortName: 'TL',
  },
  {
    name: 'Vâlcea County',
    shortName: 'VL',
  },
  {
    name: 'Vaslui County',
    shortName: 'VS',
  },
  {
    name: 'Vrancea County',
    shortName: 'VN',
  },
  {
    name: 'Altai Krai',
    shortName: 'ALT',
  },
  {
    name: 'Altai Republic',
    shortName: 'AL',
  },
  {
    name: 'Amur Oblast',
    shortName: 'AMU',
  },
  {
    name: 'Arkhangelsk',
    shortName: 'ARK',
  },
  {
    name: 'Astrakhan Oblast',
    shortName: 'AST',
  },
  {
    name: 'Belgorod Oblast',
    shortName: 'BEL',
  },
  {
    name: 'Bryansk Oblast',
    shortName: 'BRY',
  },
  {
    name: 'Chechen Republic',
    shortName: 'CE',
  },
  {
    name: 'Chelyabinsk Oblast',
    shortName: 'CHE',
  },
  {
    name: 'Chukotka Autonomous Okrug',
    shortName: 'CHU',
  },
  {
    name: 'Chuvash Republic',
    shortName: 'CU',
  },
  {
    name: 'Irkutsk',
    shortName: 'IRK',
  },
  {
    name: 'Ivanovo Oblast',
    shortName: 'IVA',
  },
  {
    name: 'Jewish Autonomous Oblast',
    shortName: 'YEV',
  },
  {
    name: 'Kabardino-Balkar Republic',
    shortName: 'KB',
  },
  {
    name: 'Kaliningrad',
    shortName: 'KGD',
  },
  {
    name: 'Kaluga Oblast',
    shortName: 'KLU',
  },
  {
    name: 'Kamchatka Krai',
    shortName: 'KAM',
  },
  {
    name: 'Karachay-Cherkess Republic',
    shortName: 'KC',
  },
  {
    name: 'Kemerovo Oblast',
    shortName: 'KEM',
  },
  {
    name: 'Khabarovsk Krai',
    shortName: 'KHA',
  },
  {
    name: 'Khanty-Mansi Autonomous Okrug',
    shortName: 'KHM',
  },
  {
    name: 'Kirov Oblast',
    shortName: 'KIR',
  },
  {
    name: 'Komi Republic',
    shortName: 'KO',
  },
  {
    name: 'Kostroma Oblast',
    shortName: 'KOS',
  },
  {
    name: 'Krasnodar Krai',
    shortName: 'KDA',
  },
  {
    name: 'Krasnoyarsk Krai',
    shortName: 'KYA',
  },
  {
    name: 'Kurgan Oblast',
    shortName: 'KGN',
  },
  {
    name: 'Kursk Oblast',
    shortName: 'KRS',
  },
  {
    name: 'Leningrad Oblast',
    shortName: 'LEN',
  },
  {
    name: 'Lipetsk Oblast',
    shortName: 'LIP',
  },
  {
    name: 'Magadan Oblast',
    shortName: 'MAG',
  },
  {
    name: 'Mari El Republic',
    shortName: 'ME',
  },
  {
    name: 'Moscow',
    shortName: 'MOW',
  },
  {
    name: 'Moscow Oblast',
    shortName: 'MOS',
  },
  {
    name: 'Murmansk Oblast',
    shortName: 'MUR',
  },
  {
    name: 'Nenets Autonomous Okrug',
    shortName: 'NEN',
  },
  {
    name: 'Nizhny Novgorod Oblast',
    shortName: 'NIZ',
  },
  {
    name: 'Novgorod Oblast',
    shortName: 'NGR',
  },
  {
    name: 'Novosibirsk',
    shortName: 'NVS',
  },
  {
    name: 'Omsk Oblast',
    shortName: 'OMS',
  },
  {
    name: 'Orenburg Oblast',
    shortName: 'ORE',
  },
  {
    name: 'Oryol Oblast',
    shortName: 'ORL',
  },
  {
    name: 'Penza Oblast',
    shortName: 'PNZ',
  },
  {
    name: 'Perm Krai',
    shortName: 'PER',
  },
  {
    name: 'Primorsky Krai',
    shortName: 'PRI',
  },
  {
    name: 'Pskov Oblast',
    shortName: 'PSK',
  },
  {
    name: 'Republic of Adygea',
    shortName: 'AD',
  },
  {
    name: 'Republic of Bashkortostan',
    shortName: 'BA',
  },
  {
    name: 'Republic of Buryatia',
    shortName: 'BU',
  },
  {
    name: 'Republic of Dagestan',
    shortName: 'DA',
  },
  {
    name: 'Republic of Ingushetia',
    shortName: 'IN',
  },
  {
    name: 'Republic of Kalmykia',
    shortName: 'KL',
  },
  {
    name: 'Republic of Karelia',
    shortName: 'KR',
  },
  {
    name: 'Republic of Khakassia',
    shortName: 'KK',
  },
  {
    name: 'Republic of Mordovia',
    shortName: 'MO',
  },
  {
    name: 'Republic of North Ossetia-Alania',
    shortName: 'SE',
  },
  {
    name: 'Republic of Tatarstan',
    shortName: 'TA',
  },
  {
    name: 'Rostov Oblast',
    shortName: 'ROS',
  },
  {
    name: 'Ryazan Oblast',
    shortName: 'RYA',
  },
  {
    name: 'Saint Petersburg',
    shortName: 'SPE',
  },
  {
    name: 'Sakha Republic',
    shortName: 'SA',
  },
  {
    name: 'Sakhalin',
    shortName: 'SAK',
  },
  {
    name: 'Samara Oblast',
    shortName: 'SAM',
  },
  {
    name: 'Saratov Oblast',
    shortName: 'SAR',
  },
  {
    name: 'Sevastopol',
    shortName: 'UA-40',
  },
  {
    name: 'Smolensk Oblast',
    shortName: 'SMO',
  },
  {
    name: 'Stavropol Krai',
    shortName: 'STA',
  },
  {
    name: 'Sverdlovsk',
    shortName: 'SVE',
  },
  {
    name: 'Tambov Oblast',
    shortName: 'TAM',
  },
  {
    name: 'Tomsk Oblast',
    shortName: 'TOM',
  },
  {
    name: 'Tula Oblast',
    shortName: 'TUL',
  },
  {
    name: 'Tuva Republic',
    shortName: 'TY',
  },
  {
    name: 'Tver Oblast',
    shortName: 'TVE',
  },
  {
    name: 'Tyumen Oblast',
    shortName: 'TYU',
  },
  {
    name: 'Udmurt Republic',
    shortName: 'UD',
  },
  {
    name: 'Ulyanovsk Oblast',
    shortName: 'ULY',
  },
  {
    name: 'Vladimir Oblast',
    shortName: 'VLA',
  },
  {
    name: 'Volgograd Oblast',
    shortName: 'VGG',
  },
  {
    name: 'Vologda Oblast',
    shortName: 'VLG',
  },
  {
    name: 'Voronezh Oblast',
    shortName: 'VOR',
  },
  {
    name: 'Yamalo-Nenets Autonomous Okrug',
    shortName: 'YAN',
  },
  {
    name: 'Yaroslavl Oblast',
    shortName: 'YAR',
  },
  {
    name: 'Zabaykalsky Krai',
    shortName: 'ZAB',
  },
  {
    name: 'Eastern Province',
    shortName: '02',
  },
  {
    name: 'Kigali district',
    shortName: '01',
  },
  {
    name: 'Northern Province',
    shortName: '03',
  },
  {
    name: 'Southern Province',
    shortName: '05',
  },
  {
    name: 'Western Province',
    shortName: '04',
  },
  {
    name: 'Christ Church Nichola Town Parish',
    shortName: '01',
  },
  {
    name: 'Nevis',
    shortName: 'N',
  },
  {
    name: 'Saint Anne Sandy Point Parish',
    shortName: '02',
  },
  {
    name: 'Saint George Gingerland Parish',
    shortName: '04',
  },
  {
    name: 'Saint James Windward Parish',
    shortName: '05',
  },
  {
    name: 'Saint John Capisterre Parish',
    shortName: '06',
  },
  {
    name: 'Saint John Figtree Parish',
    shortName: '07',
  },
  {
    name: 'Saint Kitts',
    shortName: 'K',
  },
  {
    name: 'Saint Mary Cayon Parish',
    shortName: '08',
  },
  {
    name: 'Saint Paul Capisterre Parish',
    shortName: '09',
  },
  {
    name: 'Saint Paul Charlestown Parish',
    shortName: '10',
  },
  {
    name: 'Saint Peter Basseterre Parish',
    shortName: '11',
  },
  {
    name: 'Saint Thomas Lowland Parish',
    shortName: '12',
  },
  {
    name: 'Saint Thomas Middle Island Parish',
    shortName: '13',
  },
  {
    name: 'Trinity Palmetto Point Parish',
    shortName: '15',
  },
  {
    name: 'Anse la Raye Quarter',
    shortName: '01',
  },
  {
    name: 'Canaries',
    shortName: '12',
  },
  {
    name: 'Castries Quarter',
    shortName: '02',
  },
  {
    name: 'Choiseul Quarter',
    shortName: '03',
  },
  {
    name: 'Dauphin Quarter',
    shortName: '04',
  },
  {
    name: 'Dennery Quarter',
    shortName: '05',
  },
  {
    name: 'Gros Islet Quarter',
    shortName: '06',
  },
  {
    name: 'Laborie Quarter',
    shortName: '07',
  },
  {
    name: 'Micoud Quarter',
    shortName: '08',
  },
  {
    name: 'Praslin Quarter',
    shortName: '09',
  },
  {
    name: 'Soufrière Quarter',
    shortName: '10',
  },
  {
    name: 'Vieux Fort Quarter',
    shortName: '11',
  },
  {
    name: 'Charlotte Parish',
    shortName: '01',
  },
  {
    name: 'Grenadines Parish',
    shortName: '06',
  },
  {
    name: 'Saint Andrew Parish',
    shortName: '02',
  },
  {
    name: 'Saint David Parish',
    shortName: '03',
  },
  {
    name: 'Saint George Parish',
    shortName: '04',
  },
  {
    name: 'Saint Patrick Parish',
    shortName: '05',
  },
  {
    name: "A'ana",
    shortName: 'AA',
  },
  {
    name: 'Aiga-i-le-Tai',
    shortName: 'AL',
  },
  {
    name: 'Atua',
    shortName: 'AT',
  },
  {
    name: "Fa'asaleleaga",
    shortName: 'FA',
  },
  {
    name: "Gaga'emauga",
    shortName: 'GE',
  },
  {
    name: "Gaga'ifomauga",
    shortName: 'GI',
  },
  {
    name: 'Palauli',
    shortName: 'PA',
  },
  {
    name: "Satupa'itea",
    shortName: 'SA',
  },
  {
    name: 'Tuamasaga',
    shortName: 'TU',
  },
  {
    name: "Va'a-o-Fonoti",
    shortName: 'VF',
  },
  {
    name: 'Vaisigano',
    shortName: 'VS',
  },
  {
    name: 'Acquaviva',
    shortName: '01',
  },
  {
    name: 'Borgo Maggiore',
    shortName: '06',
  },
  {
    name: 'Chiesanuova',
    shortName: '02',
  },
  {
    name: 'Domagnano',
    shortName: '03',
  },
  {
    name: 'Faetano',
    shortName: '04',
  },
  {
    name: 'Fiorentino',
    shortName: '05',
  },
  {
    name: 'Montegiardino',
    shortName: '08',
  },
  {
    name: 'San Marino',
    shortName: '07',
  },
  {
    name: 'Serravalle',
    shortName: '09',
  },
  {
    name: 'Príncipe Province',
    shortName: 'P',
  },
  {
    name: 'São Tomé Province',
    shortName: 'S',
  },
  {
    name: "'Asir",
    shortName: '14',
  },
  {
    name: 'Al Bahah',
    shortName: '11',
  },
  {
    name: 'Al Jawf',
    shortName: '12',
  },
  {
    name: 'Al Madinah',
    shortName: '03',
  },
  {
    name: 'Al-Qassim',
    shortName: '05',
  },
  {
    name: 'Eastern Province',
    shortName: '04',
  },
  {
    name: "Ha'il",
    shortName: '06',
  },
  {
    name: 'Jizan',
    shortName: '09',
  },
  {
    name: 'Makkah',
    shortName: '02',
  },
  {
    name: 'Najran',
    shortName: '10',
  },
  {
    name: 'Northern Borders',
    shortName: '08',
  },
  {
    name: 'Riyadh',
    shortName: '01',
  },
  {
    name: 'Tabuk',
    shortName: '07',
  },
  {
    name: 'Dakar',
    shortName: 'DK',
  },
  {
    name: 'Diourbel Region',
    shortName: 'DB',
  },
  {
    name: 'Fatick',
    shortName: 'FK',
  },
  {
    name: 'Kaffrine',
    shortName: 'KA',
  },
  {
    name: 'Kaolack',
    shortName: 'KL',
  },
  {
    name: 'Kédougou',
    shortName: 'KE',
  },
  {
    name: 'Kolda',
    shortName: 'KD',
  },
  {
    name: 'Louga',
    shortName: 'LG',
  },
  {
    name: 'Matam',
    shortName: 'MT',
  },
  {
    name: 'Saint-Louis',
    shortName: 'SL',
  },
  {
    name: 'Sédhiou',
    shortName: 'SE',
  },
  {
    name: 'Tambacounda Region',
    shortName: 'TC',
  },
  {
    name: 'Thiès Region',
    shortName: 'TH',
  },
  {
    name: 'Ziguinchor',
    shortName: 'ZG',
  },
  {
    name: 'Belgrade',
    shortName: '00',
  },
  {
    name: 'Bor District',
    shortName: '14',
  },
  {
    name: 'Braničevo District',
    shortName: '11',
  },
  {
    name: 'Central Banat District',
    shortName: '02',
  },
  {
    name: 'Jablanica District',
    shortName: '23',
  },
  {
    name: 'Kolubara District',
    shortName: '09',
  },
  {
    name: 'Mačva District',
    shortName: '08',
  },
  {
    name: 'Moravica District',
    shortName: '17',
  },
  {
    name: 'Nišava District',
    shortName: '20',
  },
  {
    name: 'North Bačka District',
    shortName: '01',
  },
  {
    name: 'North Banat District',
    shortName: '03',
  },
  {
    name: 'Pčinja District',
    shortName: '24',
  },
  {
    name: 'Pirot District',
    shortName: '22',
  },
  {
    name: 'Podunavlje District',
    shortName: '10',
  },
  {
    name: 'Pomoravlje District',
    shortName: '13',
  },
  {
    name: 'Rasina District',
    shortName: '19',
  },
  {
    name: 'Raška District',
    shortName: '18',
  },
  {
    name: 'South Bačka District',
    shortName: '06',
  },
  {
    name: 'South Banat District',
    shortName: '04',
  },
  {
    name: 'Srem District',
    shortName: '07',
  },
  {
    name: 'Šumadija District',
    shortName: '12',
  },
  {
    name: 'Toplica District',
    shortName: '21',
  },
  {
    name: 'Vojvodina',
    shortName: 'VO',
  },
  {
    name: 'West Bačka District',
    shortName: '05',
  },
  {
    name: 'Zaječar District',
    shortName: '15',
  },
  {
    name: 'Zlatibor District',
    shortName: '16',
  },
  {
    name: 'Anse Boileau',
    shortName: '02',
  },
  {
    name: 'Anse Royale',
    shortName: '05',
  },
  {
    name: 'Anse-aux-Pins',
    shortName: '01',
  },
  {
    name: 'Au Cap',
    shortName: '04',
  },
  {
    name: 'Baie Lazare',
    shortName: '06',
  },
  {
    name: 'Baie Sainte Anne',
    shortName: '07',
  },
  {
    name: 'Beau Vallon',
    shortName: '08',
  },
  {
    name: 'Bel Air',
    shortName: '09',
  },
  {
    name: 'Bel Ombre',
    shortName: '10',
  },
  {
    name: 'Cascade',
    shortName: '11',
  },
  {
    name: 'Glacis',
    shortName: '12',
  },
  {
    name: "Grand'Anse Mahé",
    shortName: '13',
  },
  {
    name: "Grand'Anse Praslin",
    shortName: '14',
  },
  {
    name: 'La Digue',
    shortName: '15',
  },
  {
    name: 'La Rivière Anglaise',
    shortName: '16',
  },
  {
    name: 'Les Mamelles',
    shortName: '24',
  },
  {
    name: 'Mont Buxton',
    shortName: '17',
  },
  {
    name: 'Mont Fleuri',
    shortName: '18',
  },
  {
    name: 'Plaisance',
    shortName: '19',
  },
  {
    name: 'Pointe La Rue',
    shortName: '20',
  },
  {
    name: 'Port Glaud',
    shortName: '21',
  },
  {
    name: 'Roche Caiman',
    shortName: '25',
  },
  {
    name: 'Saint Louis',
    shortName: '22',
  },
  {
    name: 'Takamaka',
    shortName: '23',
  },
  {
    name: 'Eastern Province',
    shortName: 'E',
  },
  {
    name: 'Northern Province',
    shortName: 'N',
  },
  {
    name: 'Southern Province',
    shortName: 'S',
  },
  {
    name: 'Western Area',
    shortName: 'W',
  },
  {
    name: 'Central Singapore Community Development Council',
    shortName: '01',
  },
  {
    name: 'North East Community Development Council',
    shortName: '02',
  },
  {
    name: 'North West Community Development Council',
    shortName: '03',
  },
  {
    name: 'South East Community Development Council',
    shortName: '04',
  },
  {
    name: 'South West Community Development Council',
    shortName: '05',
  },
  {
    name: 'Banská Bystrica Region',
    shortName: 'BC',
  },
  {
    name: 'Bratislava Region',
    shortName: 'BL',
  },
  {
    name: 'Košice Region',
    shortName: 'KI',
  },
  {
    name: 'Nitra Region',
    shortName: 'NI',
  },
  {
    name: 'Prešov Region',
    shortName: 'PV',
  },
  {
    name: 'Trenčín Region',
    shortName: 'TC',
  },
  {
    name: 'Trnava Region',
    shortName: 'TA',
  },
  {
    name: 'Žilina Region',
    shortName: 'ZI',
  },
  {
    name: 'Ajdovščina Municipality',
    shortName: '001',
  },
  {
    name: 'Ankaran Municipality',
    shortName: '213',
  },
  {
    name: 'Beltinci Municipality',
    shortName: '002',
  },
  {
    name: 'Benedikt Municipality',
    shortName: '148',
  },
  {
    name: 'Bistrica ob Sotli Municipality',
    shortName: '149',
  },
  {
    name: 'Bled Municipality',
    shortName: '003',
  },
  {
    name: 'Bloke Municipality',
    shortName: '150',
  },
  {
    name: 'Bohinj Municipality',
    shortName: '004',
  },
  {
    name: 'Borovnica Municipality',
    shortName: '005',
  },
  {
    name: 'Bovec Municipality',
    shortName: '006',
  },
  {
    name: 'Braslovče Municipality',
    shortName: '151',
  },
  {
    name: 'Brda Municipality',
    shortName: '007',
  },
  {
    name: 'Brežice Municipality',
    shortName: '009',
  },
  {
    name: 'Brezovica Municipality',
    shortName: '008',
  },
  {
    name: 'Cankova Municipality',
    shortName: '152',
  },
  {
    name: 'Cerklje na Gorenjskem Municipality',
    shortName: '012',
  },
  {
    name: 'Cerknica Municipality',
    shortName: '013',
  },
  {
    name: 'Cerkno Municipality',
    shortName: '014',
  },
  {
    name: 'Cerkvenjak Municipality',
    shortName: '153',
  },
  {
    name: 'City Municipality of Celje',
    shortName: '011',
  },
  {
    name: 'City Municipality of Novo Mesto',
    shortName: '085',
  },
  {
    name: 'Črenšovci Municipality',
    shortName: '015',
  },
  {
    name: 'Črna na Koroškem Municipality',
    shortName: '016',
  },
  {
    name: 'Črnomelj Municipality',
    shortName: '017',
  },
  {
    name: 'Destrnik Municipality',
    shortName: '018',
  },
  {
    name: 'Divača Municipality',
    shortName: '019',
  },
  {
    name: 'Dobje Municipality',
    shortName: '154',
  },
  {
    name: 'Dobrepolje Municipality',
    shortName: '020',
  },
  {
    name: 'Dobrna Municipality',
    shortName: '155',
  },
  {
    name: 'Dobrova–Polhov Gradec Municipality',
    shortName: '021',
  },
  {
    name: 'Dobrovnik Municipality',
    shortName: '156',
  },
  {
    name: 'Dol pri Ljubljani Municipality',
    shortName: '022',
  },
  {
    name: 'Dolenjske Toplice Municipality',
    shortName: '157',
  },
  {
    name: 'Domžale Municipality',
    shortName: '023',
  },
  {
    name: 'Dornava Municipality',
    shortName: '024',
  },
  {
    name: 'Dravograd Municipality',
    shortName: '025',
  },
  {
    name: 'Duplek Municipality',
    shortName: '026',
  },
  {
    name: 'Gorenja Vas–Poljane Municipality',
    shortName: '027',
  },
  {
    name: 'Gorišnica Municipality',
    shortName: '028',
  },
  {
    name: 'Gorje Municipality',
    shortName: '207',
  },
  {
    name: 'Gornja Radgona Municipality',
    shortName: '029',
  },
  {
    name: 'Gornji Grad Municipality',
    shortName: '030',
  },
  {
    name: 'Gornji Petrovci Municipality',
    shortName: '031',
  },
  {
    name: 'Grad Municipality',
    shortName: '158',
  },
  {
    name: 'Grosuplje Municipality',
    shortName: '032',
  },
  {
    name: 'Hajdina Municipality',
    shortName: '159',
  },
  {
    name: 'Hoče–Slivnica Municipality',
    shortName: '160',
  },
  {
    name: 'Hodoš Municipality',
    shortName: '161',
  },
  {
    name: 'Horjul Municipality',
    shortName: '162',
  },
  {
    name: 'Hrastnik Municipality',
    shortName: '034',
  },
  {
    name: 'Hrpelje–Kozina Municipality',
    shortName: '035',
  },
  {
    name: 'Idrija Municipality',
    shortName: '036',
  },
  {
    name: 'Ig Municipality',
    shortName: '037',
  },
  {
    name: 'Ivančna Gorica Municipality',
    shortName: '039',
  },
  {
    name: 'Izola Municipality',
    shortName: '040',
  },
  {
    name: 'Jesenice Municipality',
    shortName: '041',
  },
  {
    name: 'Jezersko Municipality',
    shortName: '163',
  },
  {
    name: 'Juršinci Municipality',
    shortName: '042',
  },
  {
    name: 'Kamnik Municipality',
    shortName: '043',
  },
  {
    name: 'Kanal ob Soči Municipality',
    shortName: '044',
  },
  {
    name: 'Kidričevo Municipality',
    shortName: '045',
  },
  {
    name: 'Kobarid Municipality',
    shortName: '046',
  },
  {
    name: 'Kobilje Municipality',
    shortName: '047',
  },
  {
    name: 'Kočevje Municipality',
    shortName: '048',
  },
  {
    name: 'Komen Municipality',
    shortName: '049',
  },
  {
    name: 'Komenda Municipality',
    shortName: '164',
  },
  {
    name: 'Koper City Municipality',
    shortName: '050',
  },
  {
    name: 'Kostanjevica na Krki Municipality',
    shortName: '197',
  },
  {
    name: 'Kostel Municipality',
    shortName: '165',
  },
  {
    name: 'Kozje Municipality',
    shortName: '051',
  },
  {
    name: 'Kranj City Municipality',
    shortName: '052',
  },
  {
    name: 'Kranjska Gora Municipality',
    shortName: '053',
  },
  {
    name: 'Križevci Municipality',
    shortName: '166',
  },
  {
    name: 'Kungota',
    shortName: '055',
  },
  {
    name: 'Kuzma Municipality',
    shortName: '056',
  },
  {
    name: 'Laško Municipality',
    shortName: '057',
  },
  {
    name: 'Lenart Municipality',
    shortName: '058',
  },
  {
    name: 'Lendava Municipality',
    shortName: '059',
  },
  {
    name: 'Litija Municipality',
    shortName: '060',
  },
  {
    name: 'Ljubljana City Municipality',
    shortName: '061',
  },
  {
    name: 'Ljubno Municipality',
    shortName: '062',
  },
  {
    name: 'Ljutomer Municipality',
    shortName: '063',
  },
  {
    name: 'Log–Dragomer Municipality',
    shortName: '208',
  },
  {
    name: 'Logatec Municipality',
    shortName: '064',
  },
  {
    name: 'Loška Dolina Municipality',
    shortName: '065',
  },
  {
    name: 'Loški Potok Municipality',
    shortName: '066',
  },
  {
    name: 'Lovrenc na Pohorju Municipality',
    shortName: '167',
  },
  {
    name: 'Luče Municipality',
    shortName: '067',
  },
  {
    name: 'Lukovica Municipality',
    shortName: '068',
  },
  {
    name: 'Majšperk Municipality',
    shortName: '069',
  },
  {
    name: 'Makole Municipality',
    shortName: '198',
  },
  {
    name: 'Maribor City Municipality',
    shortName: '070',
  },
  {
    name: 'Markovci Municipality',
    shortName: '168',
  },
  {
    name: 'Medvode Municipality',
    shortName: '071',
  },
  {
    name: 'Mengeš Municipality',
    shortName: '072',
  },
  {
    name: 'Metlika Municipality',
    shortName: '073',
  },
  {
    name: 'Mežica Municipality',
    shortName: '074',
  },
  {
    name: 'Miklavž na Dravskem Polju Municipality',
    shortName: '169',
  },
  {
    name: 'Miren–Kostanjevica Municipality',
    shortName: '075',
  },
  {
    name: 'Mirna Municipality',
    shortName: '212',
  },
  {
    name: 'Mirna Peč Municipality',
    shortName: '170',
  },
  {
    name: 'Mislinja Municipality',
    shortName: '076',
  },
  {
    name: 'Mokronog–Trebelno Municipality',
    shortName: '199',
  },
  {
    name: 'Moravče Municipality',
    shortName: '077',
  },
  {
    name: 'Moravske Toplice Municipality',
    shortName: '078',
  },
  {
    name: 'Mozirje Municipality',
    shortName: '079',
  },
  {
    name: 'Municipality of Apače',
    shortName: '195',
  },
  {
    name: 'Municipality of Cirkulane',
    shortName: '196',
  },
  {
    name: 'Municipality of Ilirska Bistrica',
    shortName: '038',
  },
  {
    name: 'Municipality of Krško',
    shortName: '054',
  },
  {
    name: 'Municipality of Škofljica',
    shortName: '123',
  },
  {
    name: 'Murska Sobota City Municipality',
    shortName: '080',
  },
  {
    name: 'Muta Municipality',
    shortName: '081',
  },
  {
    name: 'Naklo Municipality',
    shortName: '082',
  },
  {
    name: 'Nazarje Municipality',
    shortName: '083',
  },
  {
    name: 'Nova Gorica City Municipality',
    shortName: '084',
  },
  {
    name: 'Odranci Municipality',
    shortName: '086',
  },
  {
    name: 'Oplotnica',
    shortName: '171',
  },
  {
    name: 'Ormož Municipality',
    shortName: '087',
  },
  {
    name: 'Osilnica Municipality',
    shortName: '088',
  },
  {
    name: 'Pesnica Municipality',
    shortName: '089',
  },
  {
    name: 'Piran Municipality',
    shortName: '090',
  },
  {
    name: 'Pivka Municipality',
    shortName: '091',
  },
  {
    name: 'Podčetrtek Municipality',
    shortName: '092',
  },
  {
    name: 'Podlehnik Municipality',
    shortName: '172',
  },
  {
    name: 'Podvelka Municipality',
    shortName: '093',
  },
  {
    name: 'Poljčane Municipality',
    shortName: '200',
  },
  {
    name: 'Polzela Municipality',
    shortName: '173',
  },
  {
    name: 'Postojna Municipality',
    shortName: '094',
  },
  {
    name: 'Prebold Municipality',
    shortName: '174',
  },
  {
    name: 'Preddvor Municipality',
    shortName: '095',
  },
  {
    name: 'Prevalje Municipality',
    shortName: '175',
  },
  {
    name: 'Ptuj City Municipality',
    shortName: '096',
  },
  {
    name: 'Puconci Municipality',
    shortName: '097',
  },
  {
    name: 'Rače–Fram Municipality',
    shortName: '098',
  },
  {
    name: 'Radeče Municipality',
    shortName: '099',
  },
  {
    name: 'Radenci Municipality',
    shortName: '100',
  },
  {
    name: 'Radlje ob Dravi Municipality',
    shortName: '101',
  },
  {
    name: 'Radovljica Municipality',
    shortName: '102',
  },
  {
    name: 'Ravne na Koroškem Municipality',
    shortName: '103',
  },
  {
    name: 'Razkrižje Municipality',
    shortName: '176',
  },
  {
    name: 'Rečica ob Savinji Municipality',
    shortName: '209',
  },
  {
    name: 'Renče–Vogrsko Municipality',
    shortName: '201',
  },
  {
    name: 'Ribnica Municipality',
    shortName: '104',
  },
  {
    name: 'Ribnica na Pohorju Municipality',
    shortName: '177',
  },
  {
    name: 'Rogaška Slatina Municipality',
    shortName: '106',
  },
  {
    name: 'Rogašovci Municipality',
    shortName: '105',
  },
  {
    name: 'Rogatec Municipality',
    shortName: '107',
  },
  {
    name: 'Ruše Municipality',
    shortName: '108',
  },
  {
    name: 'Šalovci Municipality',
    shortName: '033',
  },
  {
    name: 'Selnica ob Dravi Municipality',
    shortName: '178',
  },
  {
    name: 'Semič Municipality',
    shortName: '109',
  },
  {
    name: 'Šempeter–Vrtojba Municipality',
    shortName: '183',
  },
  {
    name: 'Šenčur Municipality',
    shortName: '117',
  },
  {
    name: 'Šentilj Municipality',
    shortName: '118',
  },
  {
    name: 'Šentjernej Municipality',
    shortName: '119',
  },
  {
    name: 'Šentjur Municipality',
    shortName: '120',
  },
  {
    name: 'Šentrupert Municipality',
    shortName: '211',
  },
  {
    name: 'Sevnica Municipality',
    shortName: '110',
  },
  {
    name: 'Sežana Municipality',
    shortName: '111',
  },
  {
    name: 'Škocjan Municipality',
    shortName: '121',
  },
  {
    name: 'Škofja Loka Municipality',
    shortName: '122',
  },
  {
    name: 'Slovenj Gradec City Municipality',
    shortName: '112',
  },
  {
    name: 'Slovenska Bistrica Municipality',
    shortName: '113',
  },
  {
    name: 'Slovenske Konjice Municipality',
    shortName: '114',
  },
  {
    name: 'Šmarje pri Jelšah Municipality',
    shortName: '124',
  },
  {
    name: 'Šmarješke Toplice Municipality',
    shortName: '206',
  },
  {
    name: 'Šmartno ob Paki Municipality',
    shortName: '125',
  },
  {
    name: 'Šmartno pri Litiji Municipality',
    shortName: '194',
  },
  {
    name: 'Sodražica Municipality',
    shortName: '179',
  },
  {
    name: 'Solčava Municipality',
    shortName: '180',
  },
  {
    name: 'Šoštanj Municipality',
    shortName: '126',
  },
  {
    name: 'Središče ob Dravi',
    shortName: '202',
  },
  {
    name: 'Starše Municipality',
    shortName: '115',
  },
  {
    name: 'Štore Municipality',
    shortName: '127',
  },
  {
    name: 'Straža Municipality',
    shortName: '203',
  },
  {
    name: 'Sveta Ana Municipality',
    shortName: '181',
  },
  {
    name: 'Sveta Trojica v Slovenskih Goricah Municipality',
    shortName: '204',
  },
  {
    name: 'Sveti Andraž v Slovenskih Goricah Municipality',
    shortName: '182',
  },
  {
    name: 'Sveti Jurij ob Ščavnici Municipality',
    shortName: '116',
  },
  {
    name: 'Sveti Jurij v Slovenskih Goricah Municipality',
    shortName: '210',
  },
  {
    name: 'Sveti Tomaž Municipality',
    shortName: '205',
  },
  {
    name: 'Tabor Municipality',
    shortName: '184',
  },
  {
    name: 'Tišina Municipality',
    shortName: '010',
  },
  {
    name: 'Tolmin Municipality',
    shortName: '128',
  },
  {
    name: 'Trbovlje Municipality',
    shortName: '129',
  },
  {
    name: 'Trebnje Municipality',
    shortName: '130',
  },
  {
    name: 'Trnovska Vas Municipality',
    shortName: '185',
  },
  {
    name: 'Tržič Municipality',
    shortName: '131',
  },
  {
    name: 'Trzin Municipality',
    shortName: '186',
  },
  {
    name: 'Turnišče Municipality',
    shortName: '132',
  },
  {
    name: 'Velika Polana Municipality',
    shortName: '187',
  },
  {
    name: 'Velike Lašče Municipality',
    shortName: '134',
  },
  {
    name: 'Veržej Municipality',
    shortName: '188',
  },
  {
    name: 'Videm Municipality',
    shortName: '135',
  },
  {
    name: 'Vipava Municipality',
    shortName: '136',
  },
  {
    name: 'Vitanje Municipality',
    shortName: '137',
  },
  {
    name: 'Vodice Municipality',
    shortName: '138',
  },
  {
    name: 'Vojnik Municipality',
    shortName: '139',
  },
  {
    name: 'Vransko Municipality',
    shortName: '189',
  },
  {
    name: 'Vrhnika Municipality',
    shortName: '140',
  },
  {
    name: 'Vuzenica Municipality',
    shortName: '141',
  },
  {
    name: 'Zagorje ob Savi Municipality',
    shortName: '142',
  },
  {
    name: 'Žalec Municipality',
    shortName: '190',
  },
  {
    name: 'Zavrč Municipality',
    shortName: '143',
  },
  {
    name: 'Železniki Municipality',
    shortName: '146',
  },
  {
    name: 'Žetale Municipality',
    shortName: '191',
  },
  {
    name: 'Žiri Municipality',
    shortName: '147',
  },
  {
    name: 'Žirovnica Municipality',
    shortName: '192',
  },
  {
    name: 'Zreče Municipality',
    shortName: '144',
  },
  {
    name: 'Žužemberk Municipality',
    shortName: '193',
  },
  {
    name: 'Central Province',
    shortName: 'CE',
  },
  {
    name: 'Choiseul Province',
    shortName: 'CH',
  },
  {
    name: 'Guadalcanal Province',
    shortName: 'GU',
  },
  {
    name: 'Honiara',
    shortName: 'CT',
  },
  {
    name: 'Isabel Province',
    shortName: 'IS',
  },
  {
    name: 'Makira-Ulawa Province',
    shortName: 'MK',
  },
  {
    name: 'Malaita Province',
    shortName: 'ML',
  },
  {
    name: 'Rennell and Bellona Province',
    shortName: 'RB',
  },
  {
    name: 'Temotu Province',
    shortName: 'TE',
  },
  {
    name: 'Western Province',
    shortName: 'WE',
  },
  {
    name: 'Awdal Region',
    shortName: 'AW',
  },
  {
    name: 'Bakool',
    shortName: 'BK',
  },
  {
    name: 'Banaadir',
    shortName: 'BN',
  },
  {
    name: 'Bari',
    shortName: 'BR',
  },
  {
    name: 'Bay',
    shortName: 'BY',
  },
  {
    name: 'Galguduud',
    shortName: 'GA',
  },
  {
    name: 'Gedo',
    shortName: 'GE',
  },
  {
    name: 'Hiran',
    shortName: 'HI',
  },
  {
    name: 'Lower Juba',
    shortName: 'JH',
  },
  {
    name: 'Lower Shebelle',
    shortName: 'SH',
  },
  {
    name: 'Middle Juba',
    shortName: 'JD',
  },
  {
    name: 'Middle Shebelle',
    shortName: 'SD',
  },
  {
    name: 'Mudug',
    shortName: 'MU',
  },
  {
    name: 'Nugal',
    shortName: 'NU',
  },
  {
    name: 'Sanaag Region',
    shortName: 'SA',
  },
  {
    name: 'Togdheer Region',
    shortName: 'TO',
  },
  {
    name: 'Eastern Cape',
    shortName: 'EC',
  },
  {
    name: 'Free State',
    shortName: 'FS',
  },
  {
    name: 'Gauteng',
    shortName: 'GP',
  },
  {
    name: 'KwaZulu-Natal',
    shortName: 'KZN',
  },
  {
    name: 'Limpopo',
    shortName: 'LP',
  },
  {
    name: 'Mpumalanga',
    shortName: 'MP',
  },
  {
    name: 'North West',
    shortName: 'NW',
  },
  {
    name: 'Northern Cape',
    shortName: 'NC',
  },
  {
    name: 'Western Cape',
    shortName: 'WC',
  },
  {
    name: 'Busan',
    shortName: '26',
  },
  {
    name: 'Daegu',
    shortName: '27',
  },
  {
    name: 'Daejeon',
    shortName: '30',
  },
  {
    name: 'Gangwon Province',
    shortName: '42',
  },
  {
    name: 'Gwangju',
    shortName: '29',
  },
  {
    name: 'Gyeonggi Province',
    shortName: '41',
  },
  {
    name: 'Incheon',
    shortName: '28',
  },
  {
    name: 'Jeju',
    shortName: '49',
  },
  {
    name: 'North Chungcheong Province',
    shortName: '43',
  },
  {
    name: 'North Gyeongsang Province',
    shortName: '47',
  },
  {
    name: 'North Jeolla Province',
    shortName: '45',
  },
  {
    name: 'Sejong City',
    shortName: '50',
  },
  {
    name: 'Seoul',
    shortName: '11',
  },
  {
    name: 'South Chungcheong Province',
    shortName: '44',
  },
  {
    name: 'South Gyeongsang Province',
    shortName: '48',
  },
  {
    name: 'South Jeolla Province',
    shortName: '46',
  },
  {
    name: 'Ulsan',
    shortName: '31',
  },
  {
    name: 'Central Equatoria',
    shortName: 'EC',
  },
  {
    name: 'Eastern Equatoria',
    shortName: 'EE',
  },
  {
    name: 'Jonglei State',
    shortName: 'JG',
  },
  {
    name: 'Lakes',
    shortName: 'LK',
  },
  {
    name: 'Northern Bahr el Ghazal',
    shortName: 'BN',
  },
  {
    name: 'Unity',
    shortName: 'UY',
  },
  {
    name: 'Upper Nile',
    shortName: 'NU',
  },
  {
    name: 'Warrap',
    shortName: 'WR',
  },
  {
    name: 'Western Bahr el Ghazal',
    shortName: 'BW',
  },
  {
    name: 'Western Equatoria',
    shortName: 'EW',
  },
  {
    name: 'A Coruña',
    shortName: 'C',
  },
  {
    name: 'Albacete',
    shortName: 'AB',
  },
  {
    name: 'Alicante',
    shortName: 'A',
  },
  {
    name: 'Almeria',
    shortName: 'AL',
  },
  {
    name: 'Araba',
    shortName: 'VI',
  },
  {
    name: 'Asturias',
    shortName: 'O',
  },
  {
    name: 'Ávila',
    shortName: 'AV',
  },
  {
    name: 'Badajoz',
    shortName: 'BA',
  },
  {
    name: 'Barcelona',
    shortName: 'B',
  },
  {
    name: 'Bizkaia',
    shortName: 'BI',
  },
  {
    name: 'Burgos',
    shortName: 'BU',
  },
  {
    name: 'Caceres',
    shortName: 'CC',
  },
  {
    name: 'Cádiz',
    shortName: 'CA',
  },
  {
    name: 'Cantabria',
    shortName: 'S',
  },
  {
    name: 'Castellón',
    shortName: 'CS',
  },
  {
    name: 'Ciudad Real',
    shortName: 'CR',
  },
  {
    name: 'Córdoba',
    shortName: 'CO',
  },
  {
    name: 'Cuenca',
    shortName: 'CU',
  },
  {
    name: 'Gipuzkoa',
    shortName: 'SS',
  },
  {
    name: 'Girona',
    shortName: 'GI',
  },
  {
    name: 'Granada',
    shortName: 'GR',
  },
  {
    name: 'Guadalajara',
    shortName: 'GU',
  },
  {
    name: 'Huelva',
    shortName: 'H',
  },
  {
    name: 'Huesca',
    shortName: 'HU',
  },
  {
    name: 'Islas Baleares',
    shortName: 'PM',
  },
  {
    name: 'Jaén',
    shortName: 'J',
  },
  {
    name: 'La Rioja',
    shortName: 'LO',
  },
  {
    name: 'Las Palmas',
    shortName: 'GC',
  },
  {
    name: 'Léon',
    shortName: 'LE',
  },
  {
    name: 'Lleida',
    shortName: 'L',
  },
  {
    name: 'Lugo',
    shortName: 'LU',
  },
  {
    name: 'Madrid',
    shortName: 'M',
  },
  {
    name: 'Málaga',
    shortName: 'MA',
  },
  {
    name: 'Murcia',
    shortName: 'MU',
  },
  {
    name: 'Navarra',
    shortName: 'NA',
  },
  {
    name: 'Ourense',
    shortName: 'OR',
  },
  {
    name: 'Palencia',
    shortName: 'P',
  },
  {
    name: 'Pontevedra',
    shortName: 'PO',
  },
  {
    name: 'Salamanca',
    shortName: 'SA',
  },
  {
    name: 'Santa Cruz de Tenerife',
    shortName: 'TF',
  },
  {
    name: 'Segovia',
    shortName: 'SG',
  },
  {
    name: 'Sevilla',
    shortName: 'SE',
  },
  {
    name: 'Soria',
    shortName: 'SO',
  },
  {
    name: 'Tarragona',
    shortName: 'T',
  },
  {
    name: 'Teruel',
    shortName: 'TE',
  },
  {
    name: 'Toledo',
    shortName: 'TO',
  },
  {
    name: 'Valencia',
    shortName: 'V',
  },
  {
    name: 'Valladolid',
    shortName: 'VA',
  },
  {
    name: 'Zamora',
    shortName: 'ZA',
  },
  {
    name: 'Zaragoza',
    shortName: 'Z',
  },
  {
    name: 'Ampara District',
    shortName: '52',
  },
  {
    name: 'Anuradhapura District',
    shortName: '71',
  },
  {
    name: 'Badulla District',
    shortName: '81',
  },
  {
    name: 'Batticaloa District',
    shortName: '51',
  },
  {
    name: 'Central Province',
    shortName: '2',
  },
  {
    name: 'Colombo District',
    shortName: '11',
  },
  {
    name: 'Eastern Province',
    shortName: '5',
  },
  {
    name: 'Galle District',
    shortName: '31',
  },
  {
    name: 'Gampaha District',
    shortName: '12',
  },
  {
    name: 'Hambantota District',
    shortName: '33',
  },
  {
    name: 'Jaffna District',
    shortName: '41',
  },
  {
    name: 'Kalutara District',
    shortName: '13',
  },
  {
    name: 'Kandy District',
    shortName: '21',
  },
  {
    name: 'Kegalle District',
    shortName: '92',
  },
  {
    name: 'Kilinochchi District',
    shortName: '42',
  },
  {
    name: 'Mannar District',
    shortName: '43',
  },
  {
    name: 'Matale District',
    shortName: '22',
  },
  {
    name: 'Matara District',
    shortName: '32',
  },
  {
    name: 'Monaragala District',
    shortName: '82',
  },
  {
    name: 'Mullaitivu District',
    shortName: '45',
  },
  {
    name: 'North Central Province',
    shortName: '7',
  },
  {
    name: 'North Western Province',
    shortName: '6',
  },
  {
    name: 'Northern Province',
    shortName: '4',
  },
  {
    name: 'Nuwara Eliya District',
    shortName: '23',
  },
  {
    name: 'Polonnaruwa District',
    shortName: '72',
  },
  {
    name: 'Puttalam District',
    shortName: '62',
  },
  {
    name: 'Ratnapura district',
    shortName: '91',
  },
  {
    name: 'Sabaragamuwa Province',
    shortName: '9',
  },
  {
    name: 'Southern Province',
    shortName: '3',
  },
  {
    name: 'Trincomalee District',
    shortName: '53',
  },
  {
    name: 'Uva Province',
    shortName: '8',
  },
  {
    name: 'Vavuniya District',
    shortName: '44',
  },
  {
    name: 'Western Province',
    shortName: '1',
  },
  {
    name: 'Al Jazirah',
    shortName: 'GZ',
  },
  {
    name: 'Al Qadarif',
    shortName: 'GD',
  },
  {
    name: 'Blue Nile',
    shortName: 'NB',
  },
  {
    name: 'Central Darfur',
    shortName: 'DC',
  },
  {
    name: 'East Darfur',
    shortName: 'DE',
  },
  {
    name: 'Kassala',
    shortName: 'KA',
  },
  {
    name: 'Khartoum',
    shortName: 'KH',
  },
  {
    name: 'North Darfur',
    shortName: 'DN',
  },
  {
    name: 'North Kordofan',
    shortName: 'KN',
  },
  {
    name: 'Northern',
    shortName: 'NO',
  },
  {
    name: 'Red Sea',
    shortName: 'RS',
  },
  {
    name: 'River Nile',
    shortName: 'NR',
  },
  {
    name: 'Sennar',
    shortName: 'SI',
  },
  {
    name: 'South Darfur',
    shortName: 'DS',
  },
  {
    name: 'South Kordofan',
    shortName: 'KS',
  },
  {
    name: 'West Darfur',
    shortName: 'DW',
  },
  {
    name: 'West Kordofan',
    shortName: 'GK',
  },
  {
    name: 'White Nile',
    shortName: 'NW',
  },
  {
    name: 'Brokopondo District',
    shortName: 'BR',
  },
  {
    name: 'Commewijne District',
    shortName: 'CM',
  },
  {
    name: 'Coronie District',
    shortName: 'CR',
  },
  {
    name: 'Marowijne District',
    shortName: 'MA',
  },
  {
    name: 'Nickerie District',
    shortName: 'NI',
  },
  {
    name: 'Para District',
    shortName: 'PR',
  },
  {
    name: 'Paramaribo District',
    shortName: 'PM',
  },
  {
    name: 'Saramacca District',
    shortName: 'SA',
  },
  {
    name: 'Sipaliwini District',
    shortName: 'SI',
  },
  {
    name: 'Wanica District',
    shortName: 'WA',
  },
  {
    name: 'Hhohho District',
    shortName: 'HH',
  },
  {
    name: 'Lubombo District',
    shortName: 'LU',
  },
  {
    name: 'Manzini District',
    shortName: 'MA',
  },
  {
    name: 'Shiselweni District',
    shortName: 'SH',
  },
  {
    name: 'Blekinge',
    shortName: 'K',
  },
  {
    name: 'Dalarna County',
    shortName: 'W',
  },
  {
    name: 'Gävleborg County',
    shortName: 'X',
  },
  {
    name: 'Gotland County',
    shortName: 'I',
  },
  {
    name: 'Halland County',
    shortName: 'N',
  },
  {
    name: 'Jönköping County',
    shortName: 'F',
  },
  {
    name: 'Kalmar County',
    shortName: 'H',
  },
  {
    name: 'Kronoberg County',
    shortName: 'G',
  },
  {
    name: 'Norrbotten County',
    shortName: 'BD',
  },
  {
    name: 'Örebro County',
    shortName: 'T',
  },
  {
    name: 'Östergötland County',
    shortName: 'E',
  },
  {
    name: 'Skåne County',
    shortName: 'M',
  },
  {
    name: 'Södermanland County',
    shortName: 'D',
  },
  {
    name: 'Stockholm County',
    shortName: 'AB',
  },
  {
    name: 'Uppsala County',
    shortName: 'C',
  },
  {
    name: 'Värmland County',
    shortName: 'S',
  },
  {
    name: 'Västerbotten County',
    shortName: 'AC',
  },
  {
    name: 'Västernorrland County',
    shortName: 'Y',
  },
  {
    name: 'Västmanland County',
    shortName: 'U',
  },
  {
    name: 'Västra Götaland County',
    shortName: 'O',
  },
  {
    name: 'Aargau',
    shortName: 'AG',
  },
  {
    name: 'Appenzell Ausserrhoden',
    shortName: 'AR',
  },
  {
    name: 'Appenzell Innerrhoden',
    shortName: 'AI',
  },
  {
    name: 'Basel-Land',
    shortName: 'BL',
  },
  {
    name: 'Basel-Stadt',
    shortName: 'BS',
  },
  {
    name: 'Bern',
    shortName: 'BE',
  },
  {
    name: 'Fribourg',
    shortName: 'FR',
  },
  {
    name: 'Geneva',
    shortName: 'GE',
  },
  {
    name: 'Glarus',
    shortName: 'GL',
  },
  {
    name: 'Graubünden',
    shortName: 'GR',
  },
  {
    name: 'Jura',
    shortName: 'JU',
  },
  {
    name: 'Lucerne',
    shortName: 'LU',
  },
  {
    name: 'Neuchâtel',
    shortName: 'NE',
  },
  {
    name: 'Nidwalden',
    shortName: 'NW',
  },
  {
    name: 'Obwalden',
    shortName: 'OW',
  },
  {
    name: 'Schaffhausen',
    shortName: 'SH',
  },
  {
    name: 'Schwyz',
    shortName: 'SZ',
  },
  {
    name: 'Solothurn',
    shortName: 'SO',
  },
  {
    name: 'St. Gallen',
    shortName: 'SG',
  },
  {
    name: 'Thurgau',
    shortName: 'TG',
  },
  {
    name: 'Ticino',
    shortName: 'TI',
  },
  {
    name: 'Uri',
    shortName: 'UR',
  },
  {
    name: 'Valais',
    shortName: 'VS',
  },
  {
    name: 'Vaud',
    shortName: 'VD',
  },
  {
    name: 'Zug',
    shortName: 'ZG',
  },
  {
    name: 'Zürich',
    shortName: 'ZH',
  },
  {
    name: 'Al-Hasakah',
    shortName: 'HA',
  },
  {
    name: 'Al-Raqqah',
    shortName: 'RA',
  },
  {
    name: 'Aleppo',
    shortName: 'HL',
  },
  {
    name: 'As-Suwayda',
    shortName: 'SU',
  },
  {
    name: 'Damascus',
    shortName: 'DI',
  },
  {
    name: 'Daraa',
    shortName: 'DR',
  },
  {
    name: 'Deir ez-Zor',
    shortName: 'DY',
  },
  {
    name: 'Hama',
    shortName: 'HM',
  },
  {
    name: 'Homs',
    shortName: 'HI',
  },
  {
    name: 'Idlib',
    shortName: 'ID',
  },
  {
    name: 'Latakia',
    shortName: 'LA',
  },
  {
    name: 'Quneitra',
    shortName: 'QU',
  },
  {
    name: 'Rif Dimashq',
    shortName: 'RD',
  },
  {
    name: 'Tartus',
    shortName: 'TA',
  },
  {
    name: 'Changhua',
    shortName: 'CHA',
  },
  {
    name: 'Chiayi',
    shortName: 'CYI',
  },
  {
    name: 'Chiayi',
    shortName: 'CYQ',
  },
  {
    name: 'Hsinchu',
    shortName: 'HSQ',
  },
  {
    name: 'Hsinchu',
    shortName: 'HSZ',
  },
  {
    name: 'Hualien',
    shortName: 'HUA',
  },
  {
    name: 'Kaohsiung',
    shortName: 'KHH',
  },
  {
    name: 'Keelung',
    shortName: 'KEE',
  },
  {
    name: 'Kinmen',
    shortName: 'KIN',
  },
  {
    name: 'Lienchiang',
    shortName: 'LIE',
  },
  {
    name: 'Miaoli',
    shortName: 'MIA',
  },
  {
    name: 'Nantou',
    shortName: 'NAN',
  },
  {
    name: 'New Taipei',
    shortName: 'NWT',
  },
  {
    name: 'Penghu',
    shortName: 'PEN',
  },
  {
    name: 'Pingtung',
    shortName: 'PIF',
  },
  {
    name: 'Taichung',
    shortName: 'TXG',
  },
  {
    name: 'Tainan',
    shortName: 'TNN',
  },
  {
    name: 'Taipei',
    shortName: 'TPE',
  },
  {
    name: 'Taitung',
    shortName: 'TTT',
  },
  {
    name: 'Taoyuan',
    shortName: 'TAO',
  },
  {
    name: 'Yilan',
    shortName: 'ILA',
  },
  {
    name: 'Yunlin',
    shortName: 'YUN',
  },
  {
    name: 'districts of Republican Subordination',
    shortName: 'RA',
  },
  {
    name: 'Gorno-Badakhshan Autonomous Province',
    shortName: 'GB',
  },
  {
    name: 'Khatlon Province',
    shortName: 'KT',
  },
  {
    name: 'Sughd Province',
    shortName: 'SU',
  },
  {
    name: 'Arusha',
    shortName: '01',
  },
  {
    name: 'Dar es Salaam',
    shortName: '02',
  },
  {
    name: 'Dodoma',
    shortName: '03',
  },
  {
    name: 'Geita',
    shortName: '27',
  },
  {
    name: 'Iringa',
    shortName: '04',
  },
  {
    name: 'Kagera',
    shortName: '05',
  },
  {
    name: 'Katavi',
    shortName: '28',
  },
  {
    name: 'Kigoma',
    shortName: '08',
  },
  {
    name: 'Kilimanjaro',
    shortName: '09',
  },
  {
    name: 'Lindi',
    shortName: '12',
  },
  {
    name: 'Manyara',
    shortName: '26',
  },
  {
    name: 'Mara',
    shortName: '13',
  },
  {
    name: 'Mbeya',
    shortName: '14',
  },
  {
    name: 'Morogoro',
    shortName: '16',
  },
  {
    name: 'Mtwara',
    shortName: '17',
  },
  {
    name: 'Mwanza',
    shortName: '18',
  },
  {
    name: 'Njombe',
    shortName: '29',
  },
  {
    name: 'Pemba North',
    shortName: '06',
  },
  {
    name: 'Pemba South',
    shortName: '10',
  },
  {
    name: 'Pwani',
    shortName: '19',
  },
  {
    name: 'Rukwa',
    shortName: '20',
  },
  {
    name: 'Ruvuma',
    shortName: '21',
  },
  {
    name: 'Shinyanga',
    shortName: '22',
  },
  {
    name: 'Simiyu',
    shortName: '30',
  },
  {
    name: 'Singida',
    shortName: '23',
  },
  {
    name: 'Songwe',
    shortName: '31',
  },
  {
    name: 'Tabora',
    shortName: '24',
  },
  {
    name: 'Tanga',
    shortName: '25',
  },
  {
    name: 'Zanzibar North',
    shortName: '07',
  },
  {
    name: 'Zanzibar South',
    shortName: '11',
  },
  {
    name: 'Zanzibar West',
    shortName: '15',
  },
  {
    name: 'Amnat Charoen',
    shortName: '37',
  },
  {
    name: 'Ang Thong',
    shortName: '15',
  },
  {
    name: 'Bangkok',
    shortName: '10',
  },
  {
    name: 'Bueng Kan',
    shortName: '38',
  },
  {
    name: 'Buri Ram',
    shortName: '31',
  },
  {
    name: 'Chachoengsao',
    shortName: '24',
  },
  {
    name: 'Chai Nat',
    shortName: '18',
  },
  {
    name: 'Chaiyaphum',
    shortName: '36',
  },
  {
    name: 'Chanthaburi',
    shortName: '22',
  },
  {
    name: 'Chiang Mai',
    shortName: '50',
  },
  {
    name: 'Chiang Rai',
    shortName: '57',
  },
  {
    name: 'Chon Buri',
    shortName: '20',
  },
  {
    name: 'Chumphon',
    shortName: '86',
  },
  {
    name: 'Kalasin',
    shortName: '46',
  },
  {
    name: 'Kamphaeng Phet',
    shortName: '62',
  },
  {
    name: 'Kanchanaburi',
    shortName: '71',
  },
  {
    name: 'Khon Kaen',
    shortName: '40',
  },
  {
    name: 'Krabi',
    shortName: '81',
  },
  {
    name: 'Lampang',
    shortName: '52',
  },
  {
    name: 'Lamphun',
    shortName: '51',
  },
  {
    name: 'Loei',
    shortName: '42',
  },
  {
    name: 'Lop Buri',
    shortName: '16',
  },
  {
    name: 'Mae Hong Son',
    shortName: '58',
  },
  {
    name: 'Maha Sarakham',
    shortName: '44',
  },
  {
    name: 'Mukdahan',
    shortName: '49',
  },
  {
    name: 'Nakhon Nayok',
    shortName: '26',
  },
  {
    name: 'Nakhon Pathom',
    shortName: '73',
  },
  {
    name: 'Nakhon Phanom',
    shortName: '48',
  },
  {
    name: 'Nakhon Ratchasima',
    shortName: '30',
  },
  {
    name: 'Nakhon Sawan',
    shortName: '60',
  },
  {
    name: 'Nakhon Si Thammarat',
    shortName: '80',
  },
  {
    name: 'Nan',
    shortName: '55',
  },
  {
    name: 'Narathiwat',
    shortName: '96',
  },
  {
    name: 'Nong Bua Lam Phu',
    shortName: '39',
  },
  {
    name: 'Nong Khai',
    shortName: '43',
  },
  {
    name: 'Nonthaburi',
    shortName: '12',
  },
  {
    name: 'Pathum Thani',
    shortName: '13',
  },
  {
    name: 'Pattani',
    shortName: '94',
  },
  {
    name: 'Pattaya',
    shortName: 'S',
  },
  {
    name: 'Phangnga',
    shortName: '82',
  },
  {
    name: 'Phatthalung',
    shortName: '93',
  },
  {
    name: 'Phayao',
    shortName: '56',
  },
  {
    name: 'Phetchabun',
    shortName: '67',
  },
  {
    name: 'Phetchaburi',
    shortName: '76',
  },
  {
    name: 'Phichit',
    shortName: '66',
  },
  {
    name: 'Phitsanulok',
    shortName: '65',
  },
  {
    name: 'Phra Nakhon Si Ayutthaya',
    shortName: '14',
  },
  {
    name: 'Phrae',
    shortName: '54',
  },
  {
    name: 'Phuket',
    shortName: '83',
  },
  {
    name: 'Prachin Buri',
    shortName: '25',
  },
  {
    name: 'Prachuap Khiri Khan',
    shortName: '77',
  },
  {
    name: 'Ranong',
    shortName: '85',
  },
  {
    name: 'Ratchaburi',
    shortName: '70',
  },
  {
    name: 'Rayong',
    shortName: '21',
  },
  {
    name: 'Roi Et',
    shortName: '45',
  },
  {
    name: 'Sa Kaeo',
    shortName: '27',
  },
  {
    name: 'Sakon Nakhon',
    shortName: '47',
  },
  {
    name: 'Samut Prakan',
    shortName: '11',
  },
  {
    name: 'Samut Sakhon',
    shortName: '74',
  },
  {
    name: 'Samut Songkhram',
    shortName: '75',
  },
  {
    name: 'Saraburi',
    shortName: '19',
  },
  {
    name: 'Satun',
    shortName: '91',
  },
  {
    name: 'Si Sa Ket',
    shortName: '33',
  },
  {
    name: 'Sing Buri',
    shortName: '17',
  },
  {
    name: 'Songkhla',
    shortName: '90',
  },
  {
    name: 'Sukhothai',
    shortName: '64',
  },
  {
    name: 'Suphan Buri',
    shortName: '72',
  },
  {
    name: 'Surat Thani',
    shortName: '84',
  },
  {
    name: 'Surin',
    shortName: '32',
  },
  {
    name: 'Tak',
    shortName: '63',
  },
  {
    name: 'Trang',
    shortName: '92',
  },
  {
    name: 'Trat',
    shortName: '23',
  },
  {
    name: 'Ubon Ratchathani',
    shortName: '34',
  },
  {
    name: 'Udon Thani',
    shortName: '41',
  },
  {
    name: 'Uthai Thani',
    shortName: '61',
  },
  {
    name: 'Uttaradit',
    shortName: '53',
  },
  {
    name: 'Yala',
    shortName: '95',
  },
  {
    name: 'Yasothon',
    shortName: '35',
  },
  {
    name: 'Acklins',
    shortName: 'AK',
  },
  {
    name: 'Acklins and Crooked Islands',
    shortName: 'AC',
  },
  {
    name: 'Berry Islands',
    shortName: 'BY',
  },
  {
    name: 'Bimini',
    shortName: 'BI',
  },
  {
    name: 'Black Point',
    shortName: 'BP',
  },
  {
    name: 'Cat Island',
    shortName: 'CI',
  },
  {
    name: 'Central Abaco',
    shortName: 'CO',
  },
  {
    name: 'Central Andros',
    shortName: 'CS',
  },
  {
    name: 'Central Eleuthera',
    shortName: 'CE',
  },
  {
    name: 'Crooked Island',
    shortName: 'CK',
  },
  {
    name: 'East Grand Bahama',
    shortName: 'EG',
  },
  {
    name: 'Exuma',
    shortName: 'EX',
  },
  {
    name: 'Freeport',
    shortName: 'FP',
  },
  {
    name: 'Fresh Creek',
    shortName: 'FC',
  },
  {
    name: "Governor's Harbour",
    shortName: 'GH',
  },
  {
    name: 'Grand Cay',
    shortName: 'GC',
  },
  {
    name: 'Green Turtle Cay',
    shortName: 'GT',
  },
  {
    name: 'Harbour Island',
    shortName: 'HI',
  },
  {
    name: 'High Rock',
    shortName: 'HR',
  },
  {
    name: 'Hope Town',
    shortName: 'HT',
  },
  {
    name: 'Inagua',
    shortName: 'IN',
  },
  {
    name: 'Kemps Bay',
    shortName: 'KB',
  },
  {
    name: 'Long Island',
    shortName: 'LI',
  },
  {
    name: 'Mangrove Cay',
    shortName: 'MC',
  },
  {
    name: 'Marsh Harbour',
    shortName: 'MH',
  },
  {
    name: 'Mayaguana District',
    shortName: 'MG',
  },
  {
    name: 'New Providence',
    shortName: 'NP',
  },
  {
    name: 'Nichollstown and Berry Islands',
    shortName: 'NB',
  },
  {
    name: 'North Abaco',
    shortName: 'NO',
  },
  {
    name: 'North Andros',
    shortName: 'NS',
  },
  {
    name: 'North Eleuthera',
    shortName: 'NE',
  },
  {
    name: 'Ragged Island',
    shortName: 'RI',
  },
  {
    name: 'Rock Sound',
    shortName: 'RS',
  },
  {
    name: 'Rum Cay District',
    shortName: 'RC',
  },
  {
    name: 'San Salvador and Rum Cay',
    shortName: 'SR',
  },
  {
    name: 'San Salvador Island',
    shortName: 'SS',
  },
  {
    name: 'Sandy Point',
    shortName: 'SP',
  },
  {
    name: 'South Abaco',
    shortName: 'SO',
  },
  {
    name: 'South Andros',
    shortName: 'SA',
  },
  {
    name: 'South Eleuthera',
    shortName: 'SE',
  },
  {
    name: 'Spanish Wells',
    shortName: 'SW',
  },
  {
    name: 'West Grand Bahama',
    shortName: 'WG',
  },
  {
    name: 'Centrale Region',
    shortName: 'C',
  },
  {
    name: 'Kara Region',
    shortName: 'K',
  },
  {
    name: 'Maritime',
    shortName: 'M',
  },
  {
    name: 'Plateaux Region',
    shortName: 'P',
  },
  {
    name: 'Savanes Region',
    shortName: 'S',
  },
  {
    name: 'Haʻapai',
    shortName: '02',
  },
  {
    name: 'ʻEua',
    shortName: '01',
  },
  {
    name: 'Niuas',
    shortName: '03',
  },
  {
    name: 'Tongatapu',
    shortName: '04',
  },
  {
    name: 'Vavaʻu',
    shortName: '05',
  },
  {
    name: 'Arima',
    shortName: 'ARI',
  },
  {
    name: 'Chaguanas',
    shortName: 'CHA',
  },
  {
    name: 'Couva-Tabaquite-Talparo Regional Corporation',
    shortName: 'CTT',
  },
  {
    name: 'Diego Martin Regional Corporation',
    shortName: 'DMN',
  },
  {
    name: 'Eastern Tobago',
    shortName: 'ETO',
  },
  {
    name: 'Penal-Debe Regional Corporation',
    shortName: 'PED',
  },
  {
    name: 'Point Fortin',
    shortName: 'PTF',
  },
  {
    name: 'Port of Spain',
    shortName: 'POS',
  },
  {
    name: 'Princes Town Regional Corporation',
    shortName: 'PRT',
  },
  {
    name: 'Rio Claro-Mayaro Regional Corporation',
    shortName: 'MRC',
  },
  {
    name: 'San Fernando',
    shortName: 'SFO',
  },
  {
    name: 'San Juan-Laventille Regional Corporation',
    shortName: 'SJL',
  },
  {
    name: 'Sangre Grande Regional Corporation',
    shortName: 'SGE',
  },
  {
    name: 'Siparia Regional Corporation',
    shortName: 'SIP',
  },
  {
    name: 'Tunapuna-Piarco Regional Corporation',
    shortName: 'TUP',
  },
  {
    name: 'Western Tobago',
    shortName: 'WTO',
  },
  {
    name: 'Ariana',
    shortName: '12',
  },
  {
    name: 'Ben Arous',
    shortName: '13',
  },
  {
    name: 'Bizerte',
    shortName: '23',
  },
  {
    name: 'Gabès',
    shortName: '81',
  },
  {
    name: 'Gafsa',
    shortName: '71',
  },
  {
    name: 'Jendouba',
    shortName: '32',
  },
  {
    name: 'Kairouan',
    shortName: '41',
  },
  {
    name: 'Kasserine',
    shortName: '42',
  },
  {
    name: 'Kassrine',
    shortName: '31',
  },
  {
    name: 'Kebili',
    shortName: '73',
  },
  {
    name: 'Kef',
    shortName: '33',
  },
  {
    name: 'Mahdia',
    shortName: '53',
  },
  {
    name: 'Manouba',
    shortName: '14',
  },
  {
    name: 'Medenine',
    shortName: '82',
  },
  {
    name: 'Monastir',
    shortName: '52',
  },
  {
    name: 'Nabeul',
    shortName: '21',
  },
  {
    name: 'Sfax',
    shortName: '61',
  },
  {
    name: 'Sidi Bouzid',
    shortName: '43',
  },
  {
    name: 'Siliana',
    shortName: '34',
  },
  {
    name: 'Sousse',
    shortName: '51',
  },
  {
    name: 'Tataouine',
    shortName: '83',
  },
  {
    name: 'Tozeur',
    shortName: '72',
  },
  {
    name: 'Tunis',
    shortName: '11',
  },
  {
    name: 'Zaghouan',
    shortName: '22',
  },
  {
    name: 'Adana',
    shortName: '01',
  },
  {
    name: 'Adıyaman',
    shortName: '02',
  },
  {
    name: 'Afyonkarahisar',
    shortName: '03',
  },
  {
    name: 'Ağrı',
    shortName: '04',
  },
  {
    name: 'Aksaray',
    shortName: '68',
  },
  {
    name: 'Amasya',
    shortName: '05',
  },
  {
    name: 'Ankara',
    shortName: '06',
  },
  {
    name: 'Antalya',
    shortName: '07',
  },
  {
    name: 'Ardahan',
    shortName: '75',
  },
  {
    name: 'Artvin',
    shortName: '08',
  },
  {
    name: 'Aydın',
    shortName: '09',
  },
  {
    name: 'Balıkesir',
    shortName: '10',
  },
  {
    name: 'Bartın',
    shortName: '74',
  },
  {
    name: 'Batman',
    shortName: '72',
  },
  {
    name: 'Bayburt',
    shortName: '69',
  },
  {
    name: 'Bilecik',
    shortName: '11',
  },
  {
    name: 'Bingöl',
    shortName: '12',
  },
  {
    name: 'Bitlis',
    shortName: '13',
  },
  {
    name: 'Bolu',
    shortName: '14',
  },
  {
    name: 'Burdur',
    shortName: '15',
  },
  {
    name: 'Bursa',
    shortName: '16',
  },
  {
    name: 'Çanakkale',
    shortName: '17',
  },
  {
    name: 'Çankırı',
    shortName: '18',
  },
  {
    name: 'Çorum',
    shortName: '19',
  },
  {
    name: 'Denizli',
    shortName: '20',
  },
  {
    name: 'Diyarbakır',
    shortName: '21',
  },
  {
    name: 'Düzce',
    shortName: '81',
  },
  {
    name: 'Edirne',
    shortName: '22',
  },
  {
    name: 'Elazığ',
    shortName: '23',
  },
  {
    name: 'Erzincan',
    shortName: '24',
  },
  {
    name: 'Erzurum',
    shortName: '25',
  },
  {
    name: 'Eskişehir',
    shortName: '26',
  },
  {
    name: 'Gaziantep',
    shortName: '27',
  },
  {
    name: 'Giresun',
    shortName: '28',
  },
  {
    name: 'Gümüşhane',
    shortName: '29',
  },
  {
    name: 'Hakkâri',
    shortName: '30',
  },
  {
    name: 'Hatay',
    shortName: '31',
  },
  {
    name: 'Iğdır',
    shortName: '76',
  },
  {
    name: 'Isparta',
    shortName: '32',
  },
  {
    name: 'İstanbul',
    shortName: '34',
  },
  {
    name: 'İzmir',
    shortName: '35',
  },
  {
    name: 'Kahramanmaraş',
    shortName: '46',
  },
  {
    name: 'Karabük',
    shortName: '78',
  },
  {
    name: 'Karaman',
    shortName: '70',
  },
  {
    name: 'Kars',
    shortName: '36',
  },
  {
    name: 'Kastamonu',
    shortName: '37',
  },
  {
    name: 'Kayseri',
    shortName: '38',
  },
  {
    name: 'Kilis',
    shortName: '79',
  },
  {
    name: 'Kırıkkale',
    shortName: '71',
  },
  {
    name: 'Kırklareli',
    shortName: '39',
  },
  {
    name: 'Kırşehir',
    shortName: '40',
  },
  {
    name: 'Kocaeli',
    shortName: '41',
  },
  {
    name: 'Konya',
    shortName: '42',
  },
  {
    name: 'Kütahya',
    shortName: '43',
  },
  {
    name: 'Malatya',
    shortName: '44',
  },
  {
    name: 'Manisa',
    shortName: '45',
  },
  {
    name: 'Mardin',
    shortName: '47',
  },
  {
    name: 'Mersin',
    shortName: '33',
  },
  {
    name: 'Muğla',
    shortName: '48',
  },
  {
    name: 'Muş',
    shortName: '49',
  },
  {
    name: 'Nevşehir',
    shortName: '50',
  },
  {
    name: 'Niğde',
    shortName: '51',
  },
  {
    name: 'Ordu',
    shortName: '52',
  },
  {
    name: 'Osmaniye',
    shortName: '80',
  },
  {
    name: 'Rize',
    shortName: '53',
  },
  {
    name: 'Sakarya',
    shortName: '54',
  },
  {
    name: 'Samsun',
    shortName: '55',
  },
  {
    name: 'Şanlıurfa',
    shortName: '63',
  },
  {
    name: 'Siirt',
    shortName: '56',
  },
  {
    name: 'Sinop',
    shortName: '57',
  },
  {
    name: 'Sivas',
    shortName: '58',
  },
  {
    name: 'Şırnak',
    shortName: '73',
  },
  {
    name: 'Tekirdağ',
    shortName: '59',
  },
  {
    name: 'Tokat',
    shortName: '60',
  },
  {
    name: 'Trabzon',
    shortName: '61',
  },
  {
    name: 'Tunceli',
    shortName: '62',
  },
  {
    name: 'Uşak',
    shortName: '64',
  },
  {
    name: 'Van',
    shortName: '65',
  },
  {
    name: 'Yalova',
    shortName: '77',
  },
  {
    name: 'Yozgat',
    shortName: '66',
  },
  {
    name: 'Zonguldak',
    shortName: '67',
  },
  {
    name: 'Ahal Region',
    shortName: 'A',
  },
  {
    name: 'Ashgabat',
    shortName: 'S',
  },
  {
    name: 'Balkan Region',
    shortName: 'B',
  },
  {
    name: 'Daşoguz Region',
    shortName: 'D',
  },
  {
    name: 'Lebap Region',
    shortName: 'L',
  },
  {
    name: 'Mary Region',
    shortName: 'M',
  },
  {
    name: 'Funafuti',
    shortName: 'FUN',
  },
  {
    name: 'Nanumanga',
    shortName: 'NMG',
  },
  {
    name: 'Nanumea',
    shortName: 'NMA',
  },
  {
    name: 'Niutao Island Council',
    shortName: 'NIT',
  },
  {
    name: 'Nui',
    shortName: 'NUI',
  },
  {
    name: 'Nukufetau',
    shortName: 'NKF',
  },
  {
    name: 'Nukulaelae',
    shortName: 'NKL',
  },
  {
    name: 'Vaitupu',
    shortName: 'VAI',
  },
  {
    name: 'Abim District',
    shortName: '314',
  },
  {
    name: 'Adjumani District',
    shortName: '301',
  },
  {
    name: 'Agago District',
    shortName: '322',
  },
  {
    name: 'Alebtong District',
    shortName: '323',
  },
  {
    name: 'Amolatar District',
    shortName: '315',
  },
  {
    name: 'Amudat District',
    shortName: '324',
  },
  {
    name: 'Amuria District',
    shortName: '216',
  },
  {
    name: 'Amuru District',
    shortName: '316',
  },
  {
    name: 'Apac District',
    shortName: '302',
  },
  {
    name: 'Arua District',
    shortName: '303',
  },
  {
    name: 'Budaka District',
    shortName: '217',
  },
  {
    name: 'Bududa District',
    shortName: '218',
  },
  {
    name: 'Bugiri District',
    shortName: '201',
  },
  {
    name: 'Buhweju District',
    shortName: '420',
  },
  {
    name: 'Buikwe District',
    shortName: '117',
  },
  {
    name: 'Bukedea District',
    shortName: '219',
  },
  {
    name: 'Bukomansimbi District',
    shortName: '118',
  },
  {
    name: 'Bukwo District',
    shortName: '220',
  },
  {
    name: 'Bulambuli District',
    shortName: '225',
  },
  {
    name: 'Buliisa District',
    shortName: '416',
  },
  {
    name: 'Bundibugyo District',
    shortName: '401',
  },
  {
    name: 'Bunyangabu District',
    shortName: '430',
  },
  {
    name: 'Bushenyi District',
    shortName: '402',
  },
  {
    name: 'Busia District',
    shortName: '202',
  },
  {
    name: 'Butaleja District',
    shortName: '221',
  },
  {
    name: 'Butambala District',
    shortName: '119',
  },
  {
    name: 'Butebo District',
    shortName: '233',
  },
  {
    name: 'Buvuma District',
    shortName: '120',
  },
  {
    name: 'Buyende District',
    shortName: '226',
  },
  {
    name: 'Central Region',
    shortName: 'C',
  },
  {
    name: 'Dokolo District',
    shortName: '317',
  },
  {
    name: 'Eastern Region',
    shortName: 'E',
  },
  {
    name: 'Gomba District',
    shortName: '121',
  },
  {
    name: 'Gulu District',
    shortName: '304',
  },
  {
    name: 'Ibanda District',
    shortName: '417',
  },
  {
    name: 'Iganga District',
    shortName: '203',
  },
  {
    name: 'Isingiro District',
    shortName: '418',
  },
  {
    name: 'Jinja District',
    shortName: '204',
  },
  {
    name: 'Kaabong District',
    shortName: '318',
  },
  {
    name: 'Kabale District',
    shortName: '404',
  },
  {
    name: 'Kabarole District',
    shortName: '405',
  },
  {
    name: 'Kaberamaido District',
    shortName: '213',
  },
  {
    name: 'Kagadi District',
    shortName: '427',
  },
  {
    name: 'Kakumiro District',
    shortName: '428',
  },
  {
    name: 'Kalangala District',
    shortName: '101',
  },
  {
    name: 'Kaliro District',
    shortName: '222',
  },
  {
    name: 'Kalungu District',
    shortName: '122',
  },
  {
    name: 'Kampala District',
    shortName: '102',
  },
  {
    name: 'Kamuli District',
    shortName: '205',
  },
  {
    name: 'Kamwenge District',
    shortName: '413',
  },
  {
    name: 'Kanungu District',
    shortName: '414',
  },
  {
    name: 'Kapchorwa District',
    shortName: '206',
  },
  {
    name: 'Kasese District',
    shortName: '406',
  },
  {
    name: 'Katakwi District',
    shortName: '207',
  },
  {
    name: 'Kayunga District',
    shortName: '112',
  },
  {
    name: 'Kibaale District',
    shortName: '407',
  },
  {
    name: 'Kiboga District',
    shortName: '103',
  },
  {
    name: 'Kibuku District',
    shortName: '227',
  },
  {
    name: 'Kiruhura District',
    shortName: '419',
  },
  {
    name: 'Kiryandongo District',
    shortName: '421',
  },
  {
    name: 'Kisoro District',
    shortName: '408',
  },
  {
    name: 'Kitgum District',
    shortName: '305',
  },
  {
    name: 'Koboko District',
    shortName: '319',
  },
  {
    name: 'Kole District',
    shortName: '325',
  },
  {
    name: 'Kotido District',
    shortName: '306',
  },
  {
    name: 'Kumi District',
    shortName: '208',
  },
  {
    name: 'Kween District',
    shortName: '228',
  },
  {
    name: 'Kyankwanzi District',
    shortName: '123',
  },
  {
    name: 'Kyegegwa District',
    shortName: '422',
  },
  {
    name: 'Kyenjojo District',
    shortName: '415',
  },
  {
    name: 'Kyotera District',
    shortName: '125',
  },
  {
    name: 'Lamwo District',
    shortName: '326',
  },
  {
    name: 'Lira District',
    shortName: '307',
  },
  {
    name: 'Luuka District',
    shortName: '229',
  },
  {
    name: 'Luwero District',
    shortName: '104',
  },
  {
    name: 'Lwengo District',
    shortName: '124',
  },
  {
    name: 'Lyantonde District',
    shortName: '114',
  },
  {
    name: 'Manafwa District',
    shortName: '223',
  },
  {
    name: 'Maracha District',
    shortName: '320',
  },
  {
    name: 'Masaka District',
    shortName: '105',
  },
  {
    name: 'Masindi District',
    shortName: '409',
  },
  {
    name: 'Mayuge District',
    shortName: '214',
  },
  {
    name: 'Mbale District',
    shortName: '209',
  },
  {
    name: 'Mbarara District',
    shortName: '410',
  },
  {
    name: 'Mitooma District',
    shortName: '423',
  },
  {
    name: 'Mityana District',
    shortName: '115',
  },
  {
    name: 'Moroto District',
    shortName: '308',
  },
  {
    name: 'Moyo District',
    shortName: '309',
  },
  {
    name: 'Mpigi District',
    shortName: '106',
  },
  {
    name: 'Mubende District',
    shortName: '107',
  },
  {
    name: 'Mukono District',
    shortName: '108',
  },
  {
    name: 'Nakapiripirit District',
    shortName: '311',
  },
  {
    name: 'Nakaseke District',
    shortName: '116',
  },
  {
    name: 'Nakasongola District',
    shortName: '109',
  },
  {
    name: 'Namayingo District',
    shortName: '230',
  },
  {
    name: 'Namisindwa District',
    shortName: '234',
  },
  {
    name: 'Namutumba District',
    shortName: '224',
  },
  {
    name: 'Napak District',
    shortName: '327',
  },
  {
    name: 'Nebbi District',
    shortName: '310',
  },
  {
    name: 'Ngora District',
    shortName: '231',
  },
  {
    name: 'Northern Region',
    shortName: 'N',
  },
  {
    name: 'Ntoroko District',
    shortName: '424',
  },
  {
    name: 'Ntungamo District',
    shortName: '411',
  },
  {
    name: 'Nwoya District',
    shortName: '328',
  },
  {
    name: 'Omoro District',
    shortName: '331',
  },
  {
    name: 'Otuke District',
    shortName: '329',
  },
  {
    name: 'Oyam District',
    shortName: '321',
  },
  {
    name: 'Pader District',
    shortName: '312',
  },
  {
    name: 'Pakwach District',
    shortName: '332',
  },
  {
    name: 'Pallisa District',
    shortName: '210',
  },
  {
    name: 'Rakai District',
    shortName: '110',
  },
  {
    name: 'Rubanda District',
    shortName: '429',
  },
  {
    name: 'Rubirizi District',
    shortName: '425',
  },
  {
    name: 'Rukiga District',
    shortName: '431',
  },
  {
    name: 'Rukungiri District',
    shortName: '412',
  },
  {
    name: 'Sembabule District',
    shortName: '111',
  },
  {
    name: 'Serere District',
    shortName: '232',
  },
  {
    name: 'Sheema District',
    shortName: '426',
  },
  {
    name: 'Sironko District',
    shortName: '215',
  },
  {
    name: 'Soroti District',
    shortName: '211',
  },
  {
    name: 'Tororo District',
    shortName: '212',
  },
  {
    name: 'Wakiso District',
    shortName: '113',
  },
  {
    name: 'Western Region',
    shortName: 'W',
  },
  {
    name: 'Yumbe District',
    shortName: '313',
  },
  {
    name: 'Zombo District',
    shortName: '330',
  },
  {
    name: 'Autonomous Republic of Crimea',
    shortName: '43',
  },
  {
    name: 'Cherkaska oblast',
    shortName: '71',
  },
  {
    name: 'Chernihivska oblast',
    shortName: '74',
  },
  {
    name: 'Chernivetska oblast',
    shortName: '77',
  },
  {
    name: 'Dnipropetrovska oblast',
    shortName: '12',
  },
  {
    name: 'Donetska oblast',
    shortName: '14',
  },
  {
    name: 'Ivano-Frankivska oblast',
    shortName: '26',
  },
  {
    name: 'Kharkivska oblast',
    shortName: '63',
  },
  {
    name: 'Khersonska oblast',
    shortName: '65',
  },
  {
    name: 'Khmelnytska oblast',
    shortName: '68',
  },
  {
    name: 'Kirovohradska oblast',
    shortName: '35',
  },
  {
    name: 'Kyiv',
    shortName: '30',
  },
  {
    name: 'Kyivska oblast',
    shortName: '32',
  },
  {
    name: 'Luhanska oblast',
    shortName: '09',
  },
  {
    name: 'Lvivska oblast',
    shortName: '46',
  },
  {
    name: 'Mykolaivska oblast',
    shortName: '48',
  },
  {
    name: 'Odeska oblast',
    shortName: '51',
  },
  {
    name: 'Poltavska oblast',
    shortName: '53',
  },
  {
    name: 'Rivnenska oblast',
    shortName: '56',
  },
  {
    name: 'Sumska oblast',
    shortName: '59',
  },
  {
    name: 'Ternopilska oblast',
    shortName: '61',
  },
  {
    name: 'Vinnytska oblast',
    shortName: '05',
  },
  {
    name: 'Volynska oblast',
    shortName: '07',
  },
  {
    name: 'Zakarpatska Oblast',
    shortName: '21',
  },
  {
    name: 'Zaporizka oblast',
    shortName: '23',
  },
  {
    name: 'Zhytomyrska oblast',
    shortName: '18',
  },
  {
    name: 'Abu Dhabi Emirate',
    shortName: 'AZ',
  },
  {
    name: 'Ajman Emirate',
    shortName: 'AJ',
  },
  {
    name: 'Dubai',
    shortName: 'DU',
  },
  {
    name: 'Fujairah',
    shortName: 'FU',
  },
  {
    name: 'Ras al-Khaimah',
    shortName: 'RK',
  },
  {
    name: 'Sharjah Emirate',
    shortName: 'SH',
  },
  {
    name: 'Umm al-Quwain',
    shortName: 'UQ',
  },
  {
    name: 'Aberdeen',
    shortName: 'ABE',
  },
  {
    name: 'Aberdeenshire',
    shortName: 'ABD',
  },
  {
    name: 'Angus',
    shortName: 'ANS',
  },
  {
    name: 'Antrim',
    shortName: 'ANT',
  },
  {
    name: 'Antrim and Newtownabbey',
    shortName: 'ANN',
  },
  {
    name: 'Ards',
    shortName: 'ARD',
  },
  {
    name: 'Ards and North Down',
    shortName: 'AND',
  },
  {
    name: 'Argyll and Bute',
    shortName: 'AGB',
  },
  {
    name: 'Armagh City and District Council',
    shortName: 'ARM',
  },
  {
    name: 'Armagh, Banbridge and Craigavon',
    shortName: 'ABC',
  },
  {
    name: 'Ascension Island',
    shortName: 'SH-AC',
  },
  {
    name: 'Ballymena Borough',
    shortName: 'BLA',
  },
  {
    name: 'Ballymoney',
    shortName: 'BLY',
  },
  {
    name: 'Banbridge',
    shortName: 'BNB',
  },
  {
    name: 'Barnsley',
    shortName: 'BNS',
  },
  {
    name: 'Bath and North East Somerset',
    shortName: 'BAS',
  },
  {
    name: 'Bedford',
    shortName: 'BDF',
  },
  {
    name: 'Belfast district',
    shortName: 'BFS',
  },
  {
    name: 'Birmingham',
    shortName: 'BIR',
  },
  {
    name: 'Blackburn with Darwen',
    shortName: 'BBD',
  },
  {
    name: 'Blackpool',
    shortName: 'BPL',
  },
  {
    name: 'Blaenau Gwent County Borough',
    shortName: 'BGW',
  },
  {
    name: 'Bolton',
    shortName: 'BOL',
  },
  {
    name: 'Bournemouth',
    shortName: 'BMH',
  },
  {
    name: 'Bracknell Forest',
    shortName: 'BRC',
  },
  {
    name: 'Bradford',
    shortName: 'BRD',
  },
  {
    name: 'Bridgend County Borough',
    shortName: 'BGE',
  },
  {
    name: 'Brighton and Hove',
    shortName: 'BNH',
  },
  {
    name: 'Buckinghamshire',
    shortName: 'BKM',
  },
  {
    name: 'Bury',
    shortName: 'BUR',
  },
  {
    name: 'Caerphilly County Borough',
    shortName: 'CAY',
  },
  {
    name: 'Calderdale',
    shortName: 'CLD',
  },
  {
    name: 'Cambridgeshire',
    shortName: 'CAM',
  },
  {
    name: 'Carmarthenshire',
    shortName: 'CMN',
  },
  {
    name: 'Carrickfergus Borough Council',
    shortName: 'CKF',
  },
  {
    name: 'Castlereagh',
    shortName: 'CSR',
  },
  {
    name: 'Causeway Coast and Glens',
    shortName: 'CCG',
  },
  {
    name: 'Central Bedfordshire',
    shortName: 'CBF',
  },
  {
    name: 'Ceredigion',
    shortName: 'CGN',
  },
  {
    name: 'Cheshire East',
    shortName: 'CHE',
  },
  {
    name: 'Cheshire West and Chester',
    shortName: 'CHW',
  },
  {
    name: 'City and County of Cardiff',
    shortName: 'CRF',
  },
  {
    name: 'City and County of Swansea',
    shortName: 'SWA',
  },
  {
    name: 'City of Bristol',
    shortName: 'BST',
  },
  {
    name: 'City of Derby',
    shortName: 'DER',
  },
  {
    name: 'City of Kingston upon Hull',
    shortName: 'KHL',
  },
  {
    name: 'City of Leicester',
    shortName: 'LCE',
  },
  {
    name: 'City of London',
    shortName: 'LND',
  },
  {
    name: 'City of Nottingham',
    shortName: 'NGM',
  },
  {
    name: 'City of Peterborough',
    shortName: 'PTE',
  },
  {
    name: 'City of Plymouth',
    shortName: 'PLY',
  },
  {
    name: 'City of Portsmouth',
    shortName: 'POR',
  },
  {
    name: 'City of Southampton',
    shortName: 'STH',
  },
  {
    name: 'City of Stoke-on-Trent',
    shortName: 'STE',
  },
  {
    name: 'City of Sunderland',
    shortName: 'SND',
  },
  {
    name: 'City of Westminster',
    shortName: 'WSM',
  },
  {
    name: 'City of Wolverhampton',
    shortName: 'WLV',
  },
  {
    name: 'City of York',
    shortName: 'YOR',
  },
  {
    name: 'Clackmannanshire',
    shortName: 'CLK',
  },
  {
    name: 'Coleraine Borough Council',
    shortName: 'CLR',
  },
  {
    name: 'Conwy County Borough',
    shortName: 'CWY',
  },
  {
    name: 'Cookstown District Council',
    shortName: 'CKT',
  },
  {
    name: 'Cornwall',
    shortName: 'CON',
  },
  {
    name: 'County Durham',
    shortName: 'DUR',
  },
  {
    name: 'Coventry',
    shortName: 'COV',
  },
  {
    name: 'Craigavon Borough Council',
    shortName: 'CGV',
  },
  {
    name: 'Cumbria',
    shortName: 'CMA',
  },
  {
    name: 'Darlington',
    shortName: 'DAL',
  },
  {
    name: 'Denbighshire',
    shortName: 'DEN',
  },
  {
    name: 'Derbyshire',
    shortName: 'DBY',
  },
  {
    name: 'Derry City and Strabane',
    shortName: 'DRS',
  },
  {
    name: 'Derry City Council',
    shortName: 'DRY',
  },
  {
    name: 'Devon',
    shortName: 'DEV',
  },
  {
    name: 'Doncaster',
    shortName: 'DNC',
  },
  {
    name: 'Dorset',
    shortName: 'DOR',
  },
  {
    name: 'Down District Council',
    shortName: 'DOW',
  },
  {
    name: 'Dudley',
    shortName: 'DUD',
  },
  {
    name: 'Dumfries and Galloway',
    shortName: 'DGY',
  },
  {
    name: 'Dundee',
    shortName: 'DND',
  },
  {
    name: 'Dungannon and South Tyrone Borough Council',
    shortName: 'DGN',
  },
  {
    name: 'East Ayrshire',
    shortName: 'EAY',
  },
  {
    name: 'East Dunbartonshire',
    shortName: 'EDU',
  },
  {
    name: 'East Lothian',
    shortName: 'ELN',
  },
  {
    name: 'East Renfrewshire',
    shortName: 'ERW',
  },
  {
    name: 'East Riding of Yorkshire',
    shortName: 'ERY',
  },
  {
    name: 'East Sussex',
    shortName: 'ESX',
  },
  {
    name: 'Edinburgh',
    shortName: 'EDH',
  },
  {
    name: 'England',
    shortName: 'ENG',
  },
  {
    name: 'Essex',
    shortName: 'ESS',
  },
  {
    name: 'Falkirk',
    shortName: 'FAL',
  },
  {
    name: 'Fermanagh and Omagh',
    shortName: 'FMO',
  },
  {
    name: 'Fermanagh District Council',
    shortName: 'FER',
  },
  {
    name: 'Fife',
    shortName: 'FIF',
  },
  {
    name: 'Flintshire',
    shortName: 'FLN',
  },
  {
    name: 'Gateshead',
    shortName: 'GAT',
  },
  {
    name: 'Glasgow',
    shortName: 'GLG',
  },
  {
    name: 'Gloucestershire',
    shortName: 'GLS',
  },
  {
    name: 'Gwynedd',
    shortName: 'GWN',
  },
  {
    name: 'Halton',
    shortName: 'HAL',
  },
  {
    name: 'Hampshire',
    shortName: 'HAM',
  },
  {
    name: 'Hartlepool',
    shortName: 'HPL',
  },
  {
    name: 'Herefordshire',
    shortName: 'HEF',
  },
  {
    name: 'Hertfordshire',
    shortName: 'HRT',
  },
  {
    name: 'Highland',
    shortName: 'HLD',
  },
  {
    name: 'Inverclyde',
    shortName: 'IVC',
  },
  {
    name: 'Isle of Wight',
    shortName: 'IOW',
  },
  {
    name: 'Isles of Scilly',
    shortName: 'IOS',
  },
  {
    name: 'Kent',
    shortName: 'KEN',
  },
  {
    name: 'Kirklees',
    shortName: 'KIR',
  },
  {
    name: 'Knowsley',
    shortName: 'KWL',
  },
  {
    name: 'Lancashire',
    shortName: 'LAN',
  },
  {
    name: 'Larne Borough Council',
    shortName: 'LRN',
  },
  {
    name: 'Leeds',
    shortName: 'LDS',
  },
  {
    name: 'Leicestershire',
    shortName: 'LEC',
  },
  {
    name: 'Limavady Borough Council',
    shortName: 'LMV',
  },
  {
    name: 'Lincolnshire',
    shortName: 'LIN',
  },
  {
    name: 'Lisburn and Castlereagh',
    shortName: 'LBC',
  },
  {
    name: 'Lisburn City Council',
    shortName: 'LSB',
  },
  {
    name: 'Liverpool',
    shortName: 'LIV',
  },
  {
    name: 'London Borough of Barking and Dagenham',
    shortName: 'BDG',
  },
  {
    name: 'London Borough of Barnet',
    shortName: 'BNE',
  },
  {
    name: 'London Borough of Bexley',
    shortName: 'BEX',
  },
  {
    name: 'London Borough of Brent',
    shortName: 'BEN',
  },
  {
    name: 'London Borough of Bromley',
    shortName: 'BRY',
  },
  {
    name: 'London Borough of Camden',
    shortName: 'CMD',
  },
  {
    name: 'London Borough of Croydon',
    shortName: 'CRY',
  },
  {
    name: 'London Borough of Ealing',
    shortName: 'EAL',
  },
  {
    name: 'London Borough of Enfield',
    shortName: 'ENF',
  },
  {
    name: 'London Borough of Hackney',
    shortName: 'HCK',
  },
  {
    name: 'London Borough of Hammersmith and Fulham',
    shortName: 'HMF',
  },
  {
    name: 'London Borough of Haringey',
    shortName: 'HRY',
  },
  {
    name: 'London Borough of Harrow',
    shortName: 'HRW',
  },
  {
    name: 'London Borough of Havering',
    shortName: 'HAV',
  },
  {
    name: 'London Borough of Hillingdon',
    shortName: 'HIL',
  },
  {
    name: 'London Borough of Hounslow',
    shortName: 'HNS',
  },
  {
    name: 'London Borough of Islington',
    shortName: 'ISL',
  },
  {
    name: 'London Borough of Lambeth',
    shortName: 'LBH',
  },
  {
    name: 'London Borough of Lewisham',
    shortName: 'LEW',
  },
  {
    name: 'London Borough of Merton',
    shortName: 'MRT',
  },
  {
    name: 'London Borough of Newham',
    shortName: 'NWM',
  },
  {
    name: 'London Borough of Redbridge',
    shortName: 'RDB',
  },
  {
    name: 'London Borough of Richmond upon Thames',
    shortName: 'RIC',
  },
  {
    name: 'London Borough of Southwark',
    shortName: 'SWK',
  },
  {
    name: 'London Borough of Sutton',
    shortName: 'STN',
  },
  {
    name: 'London Borough of Tower Hamlets',
    shortName: 'TWH',
  },
  {
    name: 'London Borough of Waltham Forest',
    shortName: 'WFT',
  },
  {
    name: 'London Borough of Wandsworth',
    shortName: 'WND',
  },
  {
    name: 'Magherafelt District Council',
    shortName: 'MFT',
  },
  {
    name: 'Manchester',
    shortName: 'MAN',
  },
  {
    name: 'Medway',
    shortName: 'MDW',
  },
  {
    name: 'Merthyr Tydfil County Borough',
    shortName: 'MTY',
  },
  {
    name: 'Metropolitan Borough of Wigan',
    shortName: 'WGN',
  },
  {
    name: 'Mid and East Antrim',
    shortName: 'MEA',
  },
  {
    name: 'Mid Ulster',
    shortName: 'MUL',
  },
  {
    name: 'Middlesbrough',
    shortName: 'MDB',
  },
  {
    name: 'Midlothian',
    shortName: 'MLN',
  },
  {
    name: 'Milton Keynes',
    shortName: 'MIK',
  },
  {
    name: 'Monmouthshire',
    shortName: 'MON',
  },
  {
    name: 'Moray',
    shortName: 'MRY',
  },
  {
    name: 'Moyle District Council',
    shortName: 'MYL',
  },
  {
    name: 'Neath Port Talbot County Borough',
    shortName: 'NTL',
  },
  {
    name: 'Newcastle upon Tyne',
    shortName: 'NET',
  },
  {
    name: 'Newport',
    shortName: 'NWP',
  },
  {
    name: 'Newry and Mourne District Council',
    shortName: 'NYM',
  },
  {
    name: 'Newry, Mourne and Down',
    shortName: 'NMD',
  },
  {
    name: 'Newtownabbey Borough Council',
    shortName: 'NTA',
  },
  {
    name: 'Norfolk',
    shortName: 'NFK',
  },
  {
    name: 'North Ayrshire',
    shortName: 'NAY',
  },
  {
    name: 'North Down Borough Council',
    shortName: 'NDN',
  },
  {
    name: 'North East Lincolnshire',
    shortName: 'NEL',
  },
  {
    name: 'North Lanarkshire',
    shortName: 'NLK',
  },
  {
    name: 'North Lincolnshire',
    shortName: 'NLN',
  },
  {
    name: 'North Somerset',
    shortName: 'NSM',
  },
  {
    name: 'North Tyneside',
    shortName: 'NTY',
  },
  {
    name: 'North Yorkshire',
    shortName: 'NYK',
  },
  {
    name: 'Northamptonshire',
    shortName: 'NTH',
  },
  {
    name: 'Northern Ireland',
    shortName: 'NIR',
  },
  {
    name: 'Northumberland',
    shortName: 'NBL',
  },
  {
    name: 'Nottinghamshire',
    shortName: 'NTT',
  },
  {
    name: 'Oldham',
    shortName: 'OLD',
  },
  {
    name: 'Omagh District Council',
    shortName: 'OMH',
  },
  {
    name: 'Orkney Islands',
    shortName: 'ORK',
  },
  {
    name: 'Outer Hebrides',
    shortName: 'ELS',
  },
  {
    name: 'Oxfordshire',
    shortName: 'OXF',
  },
  {
    name: 'Pembrokeshire',
    shortName: 'PEM',
  },
  {
    name: 'Perth and Kinross',
    shortName: 'PKN',
  },
  {
    name: 'Poole',
    shortName: 'POL',
  },
  {
    name: 'Powys',
    shortName: 'POW',
  },
  {
    name: 'Reading',
    shortName: 'RDG',
  },
  {
    name: 'Redcar and Cleveland',
    shortName: 'RCC',
  },
  {
    name: 'Renfrewshire',
    shortName: 'RFW',
  },
  {
    name: 'Rhondda Cynon Taf',
    shortName: 'RCT',
  },
  {
    name: 'Rochdale',
    shortName: 'RCH',
  },
  {
    name: 'Rotherham',
    shortName: 'ROT',
  },
  {
    name: 'Royal Borough of Greenwich',
    shortName: 'GRE',
  },
  {
    name: 'Royal Borough of Kensington and Chelsea',
    shortName: 'KEC',
  },
  {
    name: 'Royal Borough of Kingston upon Thames',
    shortName: 'KTT',
  },
  {
    name: 'Rutland',
    shortName: 'RUT',
  },
  {
    name: 'Saint Helena',
    shortName: 'SH-HL',
  },
  {
    name: 'Salford',
    shortName: 'SLF',
  },
  {
    name: 'Sandwell',
    shortName: 'SAW',
  },
  {
    name: 'Scotland',
    shortName: 'SCT',
  },
  {
    name: 'Scottish Borders',
    shortName: 'SCB',
  },
  {
    name: 'Sefton',
    shortName: 'SFT',
  },
  {
    name: 'Sheffield',
    shortName: 'SHF',
  },
  {
    name: 'Shetland Islands',
    shortName: 'ZET',
  },
  {
    name: 'Shropshire',
    shortName: 'SHR',
  },
  {
    name: 'Slough',
    shortName: 'SLG',
  },
  {
    name: 'Solihull',
    shortName: 'SOL',
  },
  {
    name: 'Somerset',
    shortName: 'SOM',
  },
  {
    name: 'South Ayrshire',
    shortName: 'SAY',
  },
  {
    name: 'South Gloucestershire',
    shortName: 'SGC',
  },
  {
    name: 'South Lanarkshire',
    shortName: 'SLK',
  },
  {
    name: 'South Tyneside',
    shortName: 'STY',
  },
  {
    name: 'Southend-on-Sea',
    shortName: 'SOS',
  },
  {
    name: 'St Helens',
    shortName: 'SHN',
  },
  {
    name: 'Staffordshire',
    shortName: 'STS',
  },
  {
    name: 'Stirling',
    shortName: 'STG',
  },
  {
    name: 'Stockport',
    shortName: 'SKP',
  },
  {
    name: 'Stockton-on-Tees',
    shortName: 'STT',
  },
  {
    name: 'Strabane District Council',
    shortName: 'STB',
  },
  {
    name: 'Suffolk',
    shortName: 'SFK',
  },
  {
    name: 'Surrey',
    shortName: 'SRY',
  },
  {
    name: 'Swindon',
    shortName: 'SWD',
  },
  {
    name: 'Tameside',
    shortName: 'TAM',
  },
  {
    name: 'Telford and Wrekin',
    shortName: 'TFW',
  },
  {
    name: 'Thurrock',
    shortName: 'THR',
  },
  {
    name: 'Torbay',
    shortName: 'TOB',
  },
  {
    name: 'Torfaen',
    shortName: 'TOF',
  },
  {
    name: 'Trafford',
    shortName: 'TRF',
  },
  {
    name: 'United Kingdom',
    shortName: 'UKM',
  },
  {
    name: 'Vale of Glamorgan',
    shortName: 'VGL',
  },
  {
    name: 'Wakefield',
    shortName: 'WKF',
  },
  {
    name: 'Wales',
    shortName: 'WLS',
  },
  {
    name: 'Walsall',
    shortName: 'WLL',
  },
  {
    name: 'Warrington',
    shortName: 'WRT',
  },
  {
    name: 'Warwickshire',
    shortName: 'WAR',
  },
  {
    name: 'West Berkshire',
    shortName: 'WBK',
  },
  {
    name: 'West Dunbartonshire',
    shortName: 'WDU',
  },
  {
    name: 'West Lothian',
    shortName: 'WLN',
  },
  {
    name: 'West Sussex',
    shortName: 'WSX',
  },
  {
    name: 'Wiltshire',
    shortName: 'WIL',
  },
  {
    name: 'Windsor and Maidenhead',
    shortName: 'WNM',
  },
  {
    name: 'Wirral',
    shortName: 'WRL',
  },
  {
    name: 'Wokingham',
    shortName: 'WOK',
  },
  {
    name: 'Worcestershire',
    shortName: 'WOR',
  },
  {
    name: 'Wrexham County Borough',
    shortName: 'WRX',
  },

  {
    name: 'Alabama',
    shortName: 'AL',
  },
  {
    name: 'Alaska',
    shortName: 'AK',
  },
  {
    name: 'American Samoa',
    shortName: 'AS',
  },
  {
    name: 'Arizona',
    shortName: 'AZ',
  },
  {
    name: 'Arkansas',
    shortName: 'AR',
  },
  {
    name: 'Baker Island',
    shortName: 'UM-81',
  },
  {
    name: 'California',
    shortName: 'CA',
  },
  {
    name: 'Colorado',
    shortName: 'CO',
  },
  {
    name: 'Connecticut',
    shortName: 'CT',
  },
  {
    name: 'Delaware',
    shortName: 'DE',
  },
  {
    name: 'District of Columbia',
    shortName: 'DC',
  },
  {
    name: 'Florida',
    shortName: 'FL',
  },
  {
    name: 'Georgia',
    shortName: 'GA',
  },
  {
    name: 'Guam',
    shortName: 'GU',
  },
  {
    name: 'Hawaii',
    shortName: 'HI',
  },
  {
    name: 'Howland Island',
    shortName: 'UM-84',
  },
  {
    name: 'Idaho',
    shortName: 'ID',
  },
  {
    name: 'Illinois',
    shortName: 'IL',
  },
  {
    name: 'Indiana',
    shortName: 'IN',
  },
  {
    name: 'Iowa',
    shortName: 'IA',
  },
  {
    name: 'Jarvis Island',
    shortName: 'UM-86',
  },
  {
    name: 'Johnston Atoll',
    shortName: 'UM-67',
  },
  {
    name: 'Kansas',
    shortName: 'KS',
  },
  {
    name: 'Kentucky',
    shortName: 'KY',
  },
  {
    name: 'Kingman Reef',
    shortName: 'UM-89',
  },
  {
    name: 'Louisiana',
    shortName: 'LA',
  },
  {
    name: 'Maine',
    shortName: 'ME',
  },
  {
    name: 'Maryland',
    shortName: 'MD',
  },
  {
    name: 'Massachusetts',
    shortName: 'MA',
  },
  {
    name: 'Michigan',
    shortName: 'MI',
  },
  {
    name: 'Midway Atoll',
    shortName: 'UM-71',
  },
  {
    name: 'Minnesota',
    shortName: 'MN',
  },
  {
    name: 'Mississippi',
    shortName: 'MS',
  },
  {
    name: 'Missouri',
    shortName: 'MO',
  },
  {
    name: 'Montana',
    shortName: 'MT',
  },
  {
    name: 'Navassa Island',
    shortName: 'UM-76',
  },
  {
    name: 'Nebraska',
    shortName: 'NE',
  },
  {
    name: 'Nevada',
    shortName: 'NV',
  },
  {
    name: 'New Hampshire',
    shortName: 'NH',
  },
  {
    name: 'New Jersey',
    shortName: 'NJ',
  },
  {
    name: 'New Mexico',
    shortName: 'NM',
  },
  {
    name: 'New York',
    shortName: 'NY',
  },
  {
    name: 'North Carolina',
    shortName: 'NC',
  },
  {
    name: 'North Dakota',
    shortName: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    shortName: 'MP',
  },
  {
    name: 'Ohio',
    shortName: 'OH',
  },
  {
    name: 'Oklahoma',
    shortName: 'OK',
  },
  {
    name: 'Oregon',
    shortName: 'OR',
  },
  {
    name: 'Palmyra Atoll',
    shortName: 'UM-95',
  },
  {
    name: 'Pennsylvania',
    shortName: 'PA',
  },
  {
    name: 'Puerto Rico',
    shortName: 'PR',
  },
  {
    name: 'Rhode Island',
    shortName: 'RI',
  },
  {
    name: 'South Carolina',
    shortName: 'SC',
  },
  {
    name: 'South Dakota',
    shortName: 'SD',
  },
  {
    name: 'Tennessee',
    shortName: 'TN',
  },

  {
    name: 'Texas',
    shortName: 'TX',
  },
  {
    name: 'United States Minor Outlying Islands',
    shortName: 'UM',
  },
  {
    name: 'United States Virgin Islands',
    shortName: 'VI',
  },
  {
    name: 'Utah',
    shortName: 'UT',
  },
  {
    name: 'Vermont',
    shortName: 'VT',
  },
  {
    name: 'Virginia',
    shortName: 'VA',
  },
  {
    name: 'Wake Island',
    shortName: 'UM-79',
  },
  {
    name: 'Washington',
    shortName: 'WA',
  },
  {
    name: 'West Virginia',
    shortName: 'WV',
  },
  {
    name: 'Wisconsin',
    shortName: 'WI',
  },
  {
    name: 'Wyoming',
    shortName: 'WY',
  },
  {
    name: 'Artigas Department',
    shortName: 'AR',
  },
  {
    name: 'Canelones Department',
    shortName: 'CA',
  },
  {
    name: 'Cerro Largo Department',
    shortName: 'CL',
  },
  {
    name: 'Colonia Department',
    shortName: 'CO',
  },
  {
    name: 'Durazno Department',
    shortName: 'DU',
  },
  {
    name: 'Flores Department',
    shortName: 'FS',
  },
  {
    name: 'Florida Department',
    shortName: 'FD',
  },
  {
    name: 'Lavalleja Department',
    shortName: 'LA',
  },
  {
    name: 'Maldonado Department',
    shortName: 'MA',
  },
  {
    name: 'Montevideo Department',
    shortName: 'MO',
  },
  {
    name: 'Paysandú Department',
    shortName: 'PA',
  },
  {
    name: 'Río Negro Department',
    shortName: 'RN',
  },
  {
    name: 'Rivera Department',
    shortName: 'RV',
  },
  {
    name: 'Rocha Department',
    shortName: 'RO',
  },
  {
    name: 'Salto Department',
    shortName: 'SA',
  },
  {
    name: 'San José Department',
    shortName: 'SJ',
  },
  {
    name: 'Soriano Department',
    shortName: 'SO',
  },
  {
    name: 'Tacuarembó Department',
    shortName: 'TA',
  },
  {
    name: 'Treinta y Tres Department',
    shortName: 'TT',
  },
  {
    name: 'Andijan Region',
    shortName: 'AN',
  },
  {
    name: 'Bukhara Region',
    shortName: 'BU',
  },
  {
    name: 'Fergana Region',
    shortName: 'FA',
  },
  {
    name: 'Jizzakh Region',
    shortName: 'JI',
  },
  {
    name: 'Karakalpakstan',
    shortName: 'QR',
  },
  {
    name: 'Namangan Region',
    shortName: 'NG',
  },
  {
    name: 'Navoiy Region',
    shortName: 'NW',
  },
  {
    name: 'Qashqadaryo Region',
    shortName: 'QA',
  },
  {
    name: 'Samarqand Region',
    shortName: 'SA',
  },
  {
    name: 'Sirdaryo Region',
    shortName: 'SI',
  },
  {
    name: 'Surxondaryo Region',
    shortName: 'SU',
  },
  {
    name: 'Tashkent',
    shortName: 'TK',
  },
  {
    name: 'Tashkent Region',
    shortName: 'TO',
  },
  {
    name: 'Xorazm Region',
    shortName: 'XO',
  },
  {
    name: 'Malampa',
    shortName: 'MAP',
  },
  {
    name: 'Penama',
    shortName: 'PAM',
  },
  {
    name: 'Sanma',
    shortName: 'SAM',
  },
  {
    name: 'Shefa',
    shortName: 'SEE',
  },
  {
    name: 'Tafea',
    shortName: 'TAE',
  },
  {
    name: 'Torba',
    shortName: 'TOB',
  },
  {
    name: 'Amazonas',
    shortName: 'Z',
  },
  {
    name: 'Anzoátegui',
    shortName: 'B',
  },
  {
    name: 'Apure',
    shortName: 'C',
  },
  {
    name: 'Aragua',
    shortName: 'D',
  },
  {
    name: 'Barinas',
    shortName: 'E',
  },
  {
    name: 'Bolívar',
    shortName: 'F',
  },
  {
    name: 'Carabobo',
    shortName: 'G',
  },
  {
    name: 'Cojedes',
    shortName: 'H',
  },
  {
    name: 'Delta Amacuro',
    shortName: 'Y',
  },
  {
    name: 'Distrito Capital',
    shortName: 'A',
  },
  {
    name: 'Falcón',
    shortName: 'I',
  },
  {
    name: 'Federal Dependencies of Venezuela',
    shortName: 'W',
  },
  {
    name: 'Guárico',
    shortName: 'J',
  },
  {
    name: 'La Guaira',
    shortName: 'X',
  },
  {
    name: 'Lara',
    shortName: 'K',
  },
  {
    name: 'Mérida',
    shortName: 'L',
  },
  {
    name: 'Miranda',
    shortName: 'M',
  },
  {
    name: 'Monagas',
    shortName: 'N',
  },
  {
    name: 'Nueva Esparta',
    shortName: 'O',
  },
  {
    name: 'Portuguesa',
    shortName: 'P',
  },
  {
    name: 'Sucre',
    shortName: 'R',
  },
  {
    name: 'Táchira',
    shortName: 'S',
  },
  {
    name: 'Trujillo',
    shortName: 'T',
  },
  {
    name: 'Yaracuy',
    shortName: 'U',
  },
  {
    name: 'Zulia',
    shortName: 'V',
  },
  {
    name: 'An Giang',
    shortName: '44',
  },
  {
    name: 'Bà Rịa-Vũng Tàu',
    shortName: '43',
  },
  {
    name: 'Bắc Giang',
    shortName: '54',
  },
  {
    name: 'Bắc Kạn',
    shortName: '53',
  },
  {
    name: 'Bạc Liêu',
    shortName: '55',
  },
  {
    name: 'Bắc Ninh',
    shortName: '56',
  },
  {
    name: 'Bến Tre',
    shortName: '50',
  },
  {
    name: 'Bình Dương',
    shortName: '57',
  },
  {
    name: 'Bình Định',
    shortName: '31',
  },
  {
    name: 'Bình Phước',
    shortName: '58',
  },
  {
    name: 'Bình Thuận',
    shortName: '40',
  },
  {
    name: 'Cà Mau',
    shortName: '59',
  },
  {
    name: 'Cần Thơ',
    shortName: 'CT',
  },
  {
    name: 'Cao Bằng',
    shortName: '04',
  },
  {
    name: 'Đà Nẵng',
    shortName: 'DN',
  },
  {
    name: 'Đắk Lắk',
    shortName: '33',
  },
  {
    name: 'Đắk Nông',
    shortName: '72',
  },
  {
    name: 'Điện Biên',
    shortName: '71',
  },
  {
    name: 'Đồng Nai',
    shortName: '39',
  },
  {
    name: 'Đồng Tháp',
    shortName: '45',
  },
  {
    name: 'Gia Lai',
    shortName: '30',
  },
  {
    name: 'Hà Giang',
    shortName: '03',
  },
  {
    name: 'Hà Nam',
    shortName: '63',
  },
  {
    name: 'Hà Nội',
    shortName: 'HN',
  },
  {
    name: 'Hà Tĩnh',
    shortName: '23',
  },
  {
    name: 'Hải Dương',
    shortName: '61',
  },
  {
    name: 'Hải Phòng',
    shortName: 'HP',
  },
  {
    name: 'Hậu Giang',
    shortName: '73',
  },
  {
    name: 'Hồ Chí Minh',
    shortName: 'SG',
  },
  {
    name: 'Hòa Bình',
    shortName: '14',
  },
  {
    name: 'Hưng Yên',
    shortName: '66',
  },
  {
    name: 'Khánh Hòa',
    shortName: '34',
  },
  {
    name: 'Kiên Giang',
    shortName: '47',
  },
  {
    name: 'Kon Tum',
    shortName: '28',
  },
  {
    name: 'Lai Châu',
    shortName: '01',
  },
  {
    name: 'Lâm Đồng',
    shortName: '35',
  },
  {
    name: 'Lạng Sơn',
    shortName: '09',
  },
  {
    name: 'Lào Cai',
    shortName: '02',
  },
  {
    name: 'Long An',
    shortName: '41',
  },
  {
    name: 'Nam Định',
    shortName: '67',
  },
  {
    name: 'Nghệ An',
    shortName: '22',
  },
  {
    name: 'Ninh Bình',
    shortName: '18',
  },
  {
    name: 'Ninh Thuận',
    shortName: '36',
  },
  {
    name: 'Phú Thọ',
    shortName: '68',
  },
  {
    name: 'Phú Yên',
    shortName: '32',
  },
  {
    name: 'Quảng Bình',
    shortName: '24',
  },
  {
    name: 'Quảng Nam',
    shortName: '27',
  },
  {
    name: 'Quảng Ngãi',
    shortName: '29',
  },
  {
    name: 'Quảng Ninh',
    shortName: '13',
  },
  {
    name: 'Quảng Trị',
    shortName: '25',
  },
  {
    name: 'Sóc Trăng',
    shortName: '52',
  },
  {
    name: 'Sơn La',
    shortName: '05',
  },
  {
    name: 'Tây Ninh',
    shortName: '37',
  },
  {
    name: 'Thái Bình',
    shortName: '20',
  },
  {
    name: 'Thái Nguyên',
    shortName: '69',
  },
  {
    name: 'Thanh Hóa',
    shortName: '21',
  },
  {
    name: 'Thừa Thiên-Huế',
    shortName: '26',
  },
  {
    name: 'Tiền Giang',
    shortName: '46',
  },
  {
    name: 'Trà Vinh',
    shortName: '51',
  },
  {
    name: 'Tuyên Quang',
    shortName: '07',
  },
  {
    name: 'Vĩnh Long',
    shortName: '49',
  },
  {
    name: 'Vĩnh Phúc',
    shortName: '70',
  },
  {
    name: 'Yên Bái',
    shortName: '06',
  },
  {
    name: 'Saint Croix',
    shortName: 'SC',
  },
  {
    name: 'Saint John',
    shortName: 'SJ',
  },
  {
    name: 'Saint Thomas',
    shortName: 'ST',
  },
  {
    name: "'Adan",
    shortName: 'AD',
  },
  {
    name: "'Amran",
    shortName: 'AM',
  },
  {
    name: 'Abyan',
    shortName: 'AB',
  },
  {
    name: "Al Bayda'",
    shortName: 'BA',
  },
  {
    name: 'Al Hudaydah',
    shortName: 'HU',
  },
  {
    name: 'Al Jawf',
    shortName: 'JA',
  },
  {
    name: 'Al Mahrah',
    shortName: 'MR',
  },
  {
    name: 'Al Mahwit',
    shortName: 'MW',
  },
  {
    name: 'Dhamar',
    shortName: 'DH',
  },
  {
    name: 'Hadhramaut',
    shortName: 'HD',
  },
  {
    name: 'Hajjah',
    shortName: 'HJ',
  },
  {
    name: 'Ibb',
    shortName: 'IB',
  },
  {
    name: 'Lahij',
    shortName: 'LA',
  },
  {
    name: "Ma'rib",
    shortName: 'MA',
  },
  {
    name: 'Raymah',
    shortName: 'RA',
  },
  {
    name: 'Saada',
    shortName: 'SD',
  },
  {
    name: "Sana'a",
    shortName: 'SN',
  },
  {
    name: "Sana'a",
    shortName: 'SA',
  },
  {
    name: 'Shabwah',
    shortName: 'SH',
  },
  {
    name: 'Socotra',
    shortName: 'SU',
  },
  {
    name: "Ta'izz",
    shortName: 'TA',
  },
  {
    name: 'Central Province',
    shortName: '02',
  },
  {
    name: 'Copperbelt Province',
    shortName: '08',
  },
  {
    name: 'Eastern Province',
    shortName: '03',
  },
  {
    name: 'Luapula Province',
    shortName: '04',
  },
  {
    name: 'Lusaka Province',
    shortName: '09',
  },
  {
    name: 'Muchinga Province',
    shortName: '10',
  },
  {
    name: 'Northern Province',
    shortName: '05',
  },
  {
    name: 'Northwestern Province',
    shortName: '06',
  },
  {
    name: 'Southern Province',
    shortName: '07',
  },
  {
    name: 'Western Province',
    shortName: '01',
  },
  {
    name: 'Bulawayo Province',
    shortName: 'BU',
  },
  {
    name: 'Harare Province',
    shortName: 'HA',
  },
  {
    name: 'Manicaland',
    shortName: 'MA',
  },
  {
    name: 'Mashonaland Central Province',
    shortName: 'MC',
  },
  {
    name: 'Mashonaland East Province',
    shortName: 'ME',
  },
  {
    name: 'Mashonaland West Province',
    shortName: 'MW',
  },
  {
    name: 'Masvingo Province',
    shortName: 'MV',
  },
  {
    name: 'Matabeleland North Province',
    shortName: 'MN',
  },
  {
    name: 'Matabeleland South Province',
    shortName: 'MS',
  },
  {
    name: 'Midlands Province',
    shortName: 'MI',
  },
];

export const stateSelector = stateData?.map<SelectType>(({ name }) => ({
  name,
  value: name,
}));
