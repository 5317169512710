import { FC } from 'react';
import { Card } from '@mui/material';
// component
import InputController from 'controller/InputController';
// constant, styles
import { FORMULA_NAME_TEXT } from 'constants/index';
import { forInputLabelBg } from 'styles/commonComponentStyle';

const FormulaInfoCard: FC = () => {
  return (
    <Card sx={forInputLabelBg}>
      <InputController isRequired name="name" title={FORMULA_NAME_TEXT} />
    </Card>
  );
};

export default FormulaInfoCard;
