import { Box, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
// component
import ClearComponent from 'components/common/ClearComponent';
import RadioGroupController from 'controller/RadioGroupController';
// interfaces, constants, helper, graphql
import { renderSandCoating } from 'lib/helper';
import { HTTP_STATUS, OPTIONAL_TEXT } from 'constants/index';
import { flexCenterJustifyBetween } from 'theme/styleConstant';
import { SandCoatingsPayload, useFindAllSandCoatingLazyQuery } from 'generated/graphql';
import { RadioGroupControllerExtendedProps, SandCoatingRadioGroupProps } from 'interfaces';

const SandCoatingRadioGroup: FC<SandCoatingRadioGroupProps> = ({
  name,
  title,
  showTitle = true,
  isRequired = false,
  loading: getLoading,
  onClear,
}) => {
  const [options, setOptions] = useState<RadioGroupControllerExtendedProps[]>([]);

  const [findAll, { loading: findAllLoading }] = useFindAllSandCoatingLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (res) => {
      const { findAllSandCoating } = res;
      const { response, data } = findAllSandCoating || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setOptions(renderSandCoating(data as SandCoatingsPayload['data']));
      } else {
        setOptions([]);
      }
    },

    onError: () => {
      setOptions([]);
    },
  });

  const fetchTabletCoating = useCallback(async () => {
    await findAll({
      variables: {
        findAllSandCoatingInput: {
          paginationOptions: {
            limit: 10,
            page: 1,
          },
        },
      },
    });
  }, [findAll]);

  useEffect(() => {
    fetchTabletCoating();
  }, [fetchTabletCoating]);

  const loading = findAllLoading || getLoading;

  return (
    <RadioGroupController
      name={name}
      title={
        <Box sx={flexCenterJustifyBetween}>
          <Box>
            {title}
            <ClearComponent
              onClick={() => {
                onClear && onClear();
              }}
            />
          </Box>

          <Typography color="primary">{OPTIONAL_TEXT}</Typography>
        </Box>
      }
      loaderRows={1}
      loading={loading}
      loaderColumns={4}
      options={options}
      showTitle={showTitle}
      isRequired={isRequired}
    />
  );
};

export default SandCoatingRadioGroup;
