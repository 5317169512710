import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FC, Fragment, useContext } from 'react';
// components
import Step1 from '../../Dosage/Steps/Step1';
// constants, reducer, interfaces, context, style, helper
import { ProductTypes } from 'generated/graphql';
import { modifyFormulaIngredient } from 'lib/helper';
import { ActionType } from 'reducer/addFactBoxReducer';
import { forInputLabelBg } from 'styles/commonComponentStyle';
import { AddFactBoxFormulaContext } from 'contexts/AddFactBoxContext';
import {
  SelectType,
  FactBoxStep2Props,
  ProductTypeSelect,
  IngredientItemType,
  DosageFormulationFormType,
} from 'interfaces';

const FactBoxStep2: FC<FactBoxStep2Props> = ({ formulaIngredients, setValue: setFormulaValues }) => {
  const { state, dispatch } = useContext(AddFactBoxFormulaContext);

  const { formulation, productType } = state;
  const { value: productTypeId, type } = productType;
  const { factBoxType } = formulation;

  const { watch, reset, setValue } = useFormContext<DosageFormulationFormType>();

  const { productSubType } = watch();

  const productTypeDispatch = (item: ProductTypeSelect) => {
    dispatch({ type: ActionType.SET_PRODUCT_TYPE, productType: item });
  };

  const productSubTypeDispatch = (item: SelectType) => {
    dispatch({ type: ActionType.SET_SUB_PRODUCT_TYPE, subProductType: item });
  };

  const productFormulationTypeDispatch = (item: ProductTypeSelect) => {
    dispatch({ type: ActionType.SET_PRODUCT_FORMULATION_TYPE, productFormulationType: item });
  };

  const setFormulaIngredients = () => {
    const modifiedFormulaIngredients = modifyFormulaIngredient(formulaIngredients);
    setFormulaValues('formulaIngredients', modifiedFormulaIngredients as IngredientItemType[]);
  };

  const resetBlends = () => {
    dispatch({ type: ActionType.SET_INGREDIENTS_BLEND, ingredientsBlend: [] });
  };

  return (
    <Fragment>
      <Grid container spacing={2} sx={forInputLabelBg}>
        <Grid item xs={12} xl={8}>
          <Step1
            xl={12}
            isFactBox
            reset={reset}
            setValue={setValue}
            factBoxType={factBoxType}
            resetBlends={resetBlends}
            type={type as ProductTypes}
            productTypeId={productTypeId}
            productSubType={productSubType}
            productTypeDispatch={productTypeDispatch}
            resetFormulaIngredients={setFormulaIngredients}
            productSubTypeDispatch={productSubTypeDispatch}
            productFormulationTypeDispatch={productFormulationTypeDispatch}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default FactBoxStep2;
