// imports
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material';
// Components
import { Alert } from 'components/common/Alert';
import { CloseIcon, EditIcon, FileUploadIcon } from 'assets/svgs';
// style, theme, constants, graphql, interfaces
import {
  AttachmentType,
  AttachmentTitle,
  useGetAttachmentLazyQuery,
  useRemoveAttachmentMutation,
} from 'generated/graphql';
import {
  HTTP_STATUS,
  REMOVE_IMAGE,
  UPDATE_IMAGE,
  UPLOAD_IMAGE,
  MAX_IMAGE_SIZE,
  IMAGE_FORMAT_TEXT,
  UPLOAD_IMAGE_TEXT,
  IMAGE_MAX_SIZE_TEXT,
  IMAGE_SIZE_ERROR_MESSAGE,
  INVALID_IMAGE_EXTENSION_MESSAGE,
} from 'constants/index';
import { UploadOrgImageProps } from 'interfaces';
import { attachmentUpdate, attachmentUpload } from 'lib/helper';
import { FileUploadBox, FileUploadBoxBorder, imageUploadCard } from 'styles/commonComponentStyle';

const UploadImage: FC<UploadOrgImageProps> = ({
  id,
  images = [],
  attachmentId,
  onImageChange,
  isEdit = false,
  onChangeAttachmentId,
  imageType = AttachmentType.User,
  imageLogoTitle = AttachmentTitle.UserThumbnail,
}) => {
  const [imgLoading, setImgLoading] = useState(false);
  const [removeAttachment, { loading: removeLoading }] = useRemoveAttachmentMutation({
    onCompleted: (data) => {
      const { removeAttachment } = data;
      const { response } = removeAttachment || {};
      const { status, message } = response || {};
      if (status !== HTTP_STATUS.SUCCESS) {
        Alert.warning(message ?? '');
      } else {
        Alert.success(message ?? '');
        onChangeAttachmentId && onChangeAttachmentId('');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getAttachment, { loading: attachmentLoading }] = useGetAttachmentLazyQuery({
    onCompleted: (data) => {
      const { getAttachmentByType } = data || {};
      const { attachment, response } = getAttachmentByType || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { attachmentUrl, id: imgId } = attachment || {};
        attachmentUrl && onImageChange && onImageChange([{ dataURL: attachmentUrl }], imgId);
      }
    },
    onError: () => {},
  });

  const fetchAttachment = useCallback(async () => {
    await getAttachment({
      variables: {
        getAttachmentByTypeInput: {
          typeId: id ?? '',
          type: imageType,
          title: imageLogoTitle,
        },
      },
    });
  }, [getAttachment, id, imageType, imageLogoTitle]);

  useEffect(() => {
    id && isEdit && fetchAttachment();
  }, [fetchAttachment, id, isEdit]);

  const onChange = (imageList: ImageListType) => {
    isEdit && attachmentHandler(imageList);
    onImageChange && onImageChange(imageList);
  };

  const attachmentHandler = async (imageList: ImageListType) => {
    if (attachmentId) {
      if (imageList?.length) {
        const { file } = imageList?.[0] || {};
        const { size } = file || {};
        if ((size ?? 0) > MAX_IMAGE_SIZE) {
          Alert.error(IMAGE_SIZE_ERROR_MESSAGE);
          return;
        }

        if (file) {
          setImgLoading(true);
          await attachmentUpdate(
            file,
            {
              id: attachmentId,
              typeId: id ?? '',
              type: imageType,
              title: imageLogoTitle,
            },
            true,
          );
          setImgLoading(false);
        }
      } else {
        setImgLoading(true);
        await removeAttachment({
          variables: {
            removeAttachmentInput: {
              id: attachmentId,
            },
          },
        });
        setImgLoading(false);
      }
    } else {
      if (imageList?.length) {
        const acceptedExtensions = ['jpeg', 'jpg', 'png', 'svg'];
        const { file } = imageList?.[0] || {};
        const { size, name: fileName } = file || {};
        if ((size ?? 0) > MAX_IMAGE_SIZE) {
          Alert.error(IMAGE_SIZE_ERROR_MESSAGE);
          return;
        }

        const extension = fileName?.split('.')[1];

        if (!extension || !acceptedExtensions.includes(extension)) {
          Alert.error(INVALID_IMAGE_EXTENSION_MESSAGE);
          return;
        }

        if (file) {
          setImgLoading(true);

          const attachment = await attachmentUpload(
            file,
            {
              type: imageType,
              typeId: id ?? '',
              title: imageLogoTitle,
            },
            true,
          );
          setImgLoading(false);

          const { id: attachId } = attachment || {};
          attachId && onChangeAttachmentId && onChangeAttachmentId(attachId);
        }
      }
    }
  };

  const loading = attachmentLoading || removeLoading || imgLoading;

  return (
    <Card sx={imageUploadCard}>
      <ImageUploading value={images} onChange={onChange} maxNumber={1}>
        {({ imageList, onImageUpload, onImageUpdate, onImageRemove }) => {
          const isImages = images.length <= 0;
          return (
            <div>
              {isImages && (
                <Fragment>
                  <Box display="flex" alignItems="center" justifyContent="center" sx={FileUploadBoxBorder}>
                    <Button sx={FileUploadBox} onClick={onImageUpload} disabled={loading}>
                      <FileUploadIcon />
                      {UPLOAD_IMAGE}
                    </Button>
                  </Box>

                  <Button
                    color="info"
                    variant="text"
                    sx={{ mt: 1 }}
                    onClick={onImageUpload}
                    startIcon={<EditIcon />}
                    disabled={loading}>
                    {UPLOAD_IMAGE_TEXT}
                  </Button>
                </Fragment>
              )}

              {imageList?.map((image, index) => {
                const { dataURL } = image || {};
                return (
                  <div key={index}>
                    <Box display="flex" alignItems="center" justifyContent="center" sx={FileUploadBoxBorder}>
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <Box sx={FileUploadBox}>
                          <img src={dataURL ?? ''} alt="" width="100%" />
                        </Box>
                      )}
                    </Box>

                    <Box mt={1}>
                      <Button
                        color="info"
                        variant="text"
                        disabled={loading}
                        onClick={() => onImageUpdate(index)}
                        startIcon={<EditIcon />}>
                        {UPDATE_IMAGE}
                      </Button>
                      <Button
                        variant="text"
                        color="error"
                        disabled={loading}
                        startIcon={<CloseIcon />}
                        onClick={() => onImageRemove(index)}>
                        {REMOVE_IMAGE}
                      </Button>
                    </Box>
                  </div>
                );
              })}
            </div>
          );
        }}
      </ImageUploading>
      <Box maxWidth="250px" margin="0px auto" textAlign="left">
        <ul style={{ padding: 0 }}>
          <li>
            <Typography variant="body1" fontWeight="400" color={`customGrayColor.light`}>
              {IMAGE_MAX_SIZE_TEXT}
            </Typography>
          </li>

          <li>
            <Typography variant="body1" fontWeight="400" color={`customGrayColor.light`}>
              {IMAGE_FORMAT_TEXT}
            </Typography>
          </li>
        </ul>
      </Box>
    </Card>
  );
};

export default UploadImage;
