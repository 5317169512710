import { FC } from 'react';
import { Box, Typography } from '@mui/material';
// constants
import { SETTINGS_TEXT } from 'constants/index';
import ChangePasswordForm from 'components/main/ChangePassword/ChangePasswordForm';

const Settings: FC = () => {
  return (
    <>
      <Box mb={3}>
        <Typography variant="h5">{SETTINGS_TEXT}</Typography>
      </Box>

      <ChangePasswordForm />
    </>
  );
};

export default Settings;
