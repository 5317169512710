import { useParams } from 'react-router-dom';
import { FC, useCallback, useEffect, useState } from 'react';
//components
import FormulaForm from './SetEditFormulaFormValues';
//constants, interfaces, graphql, context
import { ParamType } from 'interfaces';
import { HTTP_STATUS } from 'constants/index';
import { FormulaContextProvider } from 'contexts/FormulaContext';
import { FormulaPayload, useGetFormulaLazyQuery } from 'generated/graphql';

const EditFormula: FC = () => {
  const { id } = useParams<ParamType>();
  const [formula, setFormula] = useState<FormulaPayload['formula']>(null);

  const [getFormula, { loading }] = useGetFormulaLazyQuery({
    onCompleted: (data) => {
      const { getFormula } = data || {};
      const { response, formula } = getFormula || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setFormula(formula as FormulaPayload['formula']);
      }
    },
    onError: () => {
      setFormula(null);
    },
  });

  const fetchFormula = useCallback(async () => {
    await getFormula({
      variables: {
        getFormulaInput: { id: id ?? '' },
      },
    });
  }, [getFormula, id]);

  useEffect(() => {
    id && fetchFormula();
  }, [fetchFormula, id]);

  return (
    <FormulaContextProvider>
      <FormulaForm formula={formula} loading={loading} />
    </FormulaContextProvider>
  );
};

export default EditFormula;
