import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
//components
import { Alert } from 'components/common/Alert';
import UserForm from 'components/main/Users/Add/UserForm';
//interfaces, schema, graphql, constants
import { AddNewUserFormType } from 'interfaces';
import { addNewUserSchema } from 'validationSchema';
import { useInviteOrganizationMutation } from 'generated/graphql';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { ADD_NEW_USER_TEXT, ADD_USER_TEXT, HTTP_STATUS, addNewUserInitialValues } from 'constants/index';

const AddUser = () => {
  const navigate = useNavigate();

  const methods = useForm<AddNewUserFormType>({
    resolver: yupResolver(addNewUserSchema),
    defaultValues: addNewUserInitialValues,
  });

  const { handleSubmit } = methods;

  const [inviteOrganization, { loading }] = useInviteOrganizationMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { inviteOrganization } = data;
      const { response } = inviteOrganization || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigate(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<AddNewUserFormType> = async (data) => {
    const { email, firstName, lastName, organizationName, password, phone, maxUser } = data || {};
    await inviteOrganization({
      variables: {
        inviteOrganizationInput: {
          organization: {
            name: organizationName,
            maxUser: Number(maxUser),
          },
          user: {
            email,
            firstName,
            lastName,
            password,
            ...(phone && { phone: phone }),
          },
        },
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={flexCenterBetween}>
          <Typography variant="h5">{ADD_NEW_USER_TEXT}</Typography>
          <Button variant="contained" color="primary" type="submit">
            {ADD_USER_TEXT}
          </Button>
        </Box>
        <UserForm loading={loading} />
      </form>
    </FormProvider>
  );
};

export default AddUser;
