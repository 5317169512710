import { FC } from 'react';
import { Typography } from '@mui/material';
// interfaces
import { CLEAR_TEXT } from 'constants/index';
import { ClearComponentProps } from 'interfaces';

const ClearComponent: FC<ClearComponentProps> = ({ onClick, btnText = CLEAR_TEXT }) => {
  return (
    <Typography
      mt={1}
      ml={2}
      component={'span'}
      textAlign="end"
      color="primary"
      sx={{
        cursor: 'pointer',
      }}
      onClick={onClick}>
      ( {btnText} )
    </Typography>
  );
};

export default ClearComponent;
