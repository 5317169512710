import { FC, JSX } from 'react';
import { Grid } from '@mui/material';
// components
import Form from './Form';
// interfaces
import { FormulationSyrupFormProps } from 'interfaces';

const FormulationSyrupForm: FC<FormulationSyrupFormProps> = ({ loading, isEdit = false }): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item lg={6} md={12}>
        <Form loading={loading} isEdit={isEdit} />
      </Grid>
    </Grid>
  );
};

export default FormulationSyrupForm;
