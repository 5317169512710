// imports
import { Controller, useFormContext } from 'react-hook-form';
import { FC, useMemo, useState } from 'react';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
//interfaces , constants , helper
import { CountrySelectorProps, SelectType } from 'interfaces';
import { countriesToSelectType } from 'lib/helper';

const CountrySelector: FC<CountrySelectorProps> = ({
  name,
  title,
  disabled,
  isRequired,
  defaultValue,
  handleChange,
  margin = 'dense',
  isMultiple = false,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { control } = useFormContext();
  const options = useMemo(() => countriesToSelectType(searchQuery), [searchQuery]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { name, onBlur, onChange, value, ref } = field;
        const { error } = fieldState;
        const { message } = error || {};
        return (
          <Autocomplete
            id={name}
            ref={ref}
            onBlur={onBlur}
            disableClearable
            options={options}
            disabled={disabled}
            multiple={isMultiple}
            value={isMultiple ? (value as SelectType[]) : (value as SelectType)}
            onChange={(_, value) => {
              handleChange && handleChange();
              onChange(value);
              setSearchQuery('');
            }}
            popupIcon={<KeyboardArrowDownRoundedIcon />}
            getOptionLabel={(option) => option.name || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                margin={margin}
                variant="outlined"
                helperText={message}
                error={Boolean(error)}
                defaultValue={defaultValue}
                label={isRequired ? `${title} *` : title}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                }}>
                {options.slice(0, 10)?.map((item) => {
                  const { name, value } = item;
                  return (
                    <MenuItem key={value} value={value}>
                      {name}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          />
        );
      }}
    />
  );
};

export default CountrySelector;
