import { FC } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CircularProgress, Grid } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
//components
import { Alert } from 'components/common/Alert';
import InputController from 'controller/InputController';
//interfaces, constants, graphql
import {
  NEW_PASSWORD_TEXT,
  OLD_PASSWORD_TEXT,
  SAVE_CHANGES_TEXT,
  CONFIRM_NEW_PASSWORD_TEXT,
  changePasswordInitialValues,
} from 'constants/index';
import { ChangeFormType } from 'interfaces';
import { changePasswordSchema } from 'validationSchema';
import { forInputLabelBg } from 'styles/commonComponentStyle';
import { useChangePasswordMutation } from 'generated/graphql';

const ChangePasswordForm: FC = () => {
  const methods = useForm<ChangeFormType>({
    defaultValues: changePasswordInitialValues,
    resolver: yupResolver(changePasswordSchema),
  });

  const { handleSubmit, reset } = methods;

  const [changePassword, { loading }] = useChangePasswordMutation({
    onCompleted: (data) => {
      const { changePassword } = data;
      const { response } = changePassword || {};
      const { message, status } = response || {};
      if (status === 200) {
        Alert.success(message || '');
        reset(changePasswordInitialValues);
      } else {
        Alert.error(message || '');
      }
    },
    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<ChangeFormType> = async (data) => {
    const { oldPassword, newPassword } = data;

    await changePassword({
      variables: {
        changePasswordInput: {
          newPassword,
          oldPassword,
        },
      },
    });
  };

  return (
    <Card sx={[forInputLabelBg, { maxWidth: 628 }]}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <InputController
                isRequired
                name="oldPassword"
                fieldType="password"
                title={OLD_PASSWORD_TEXT}
                isPassword
              />
            </Grid>

            <Grid item xs={12} lg={12}>
              <InputController
                isRequired
                name="newPassword"
                fieldType="password"
                title={NEW_PASSWORD_TEXT}
                isPassword
              />
            </Grid>

            <Grid item xs={12} lg={12}>
              <InputController
                isRequired
                name="conPassword"
                fieldType="password"
                title={CONFIRM_NEW_PASSWORD_TEXT}
                isPassword
              />
            </Grid>

            <Grid item xs={12} lg={12} display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{ height: 36 }}
                startIcon={<>{loading && <CircularProgress color="inherit" size={20} />}</>}>
                {SAVE_CHANGES_TEXT}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Card>
  );
};

export default ChangePasswordForm;
