import { FC } from 'react';
import { Box, MenuItem, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
// interfaces
import { SelectControllerProps } from 'interfaces';
// styles
import { flexCenter } from 'theme/styleConstant';

const SelectController: FC<SelectControllerProps> = ({
  name,
  title,
  options,
  disabled,
  isRequired,
  defaultValue,
  handleChange,
  margin = 'dense',
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { name, onBlur, onChange, value, ref } = field;
        const { error } = fieldState;
        const { message } = error || {};
        return (
          <TextField
            select
            ref={ref}
            fullWidth
            id={name}
            name={name}
            value={value}
            margin={margin}
            onBlur={onBlur}
            variant="outlined"
            disabled={disabled}
            onChange={(e) => {
              onChange(e);
              handleChange && handleChange(value as string);
            }}
            helperText={message}
            error={Boolean(error)}
            SelectProps={{
              IconComponent: (props) => (
                <Box sx={flexCenter} color="customGrayColor.light" {...props}>
                  <KeyboardArrowDownRoundedIcon />
                </Box>
              ),
            }}
            defaultValue={defaultValue}
            label={isRequired ? `${title} *` : title}>
            {options?.map((item) => {
              const { name, value } = item;
              return (
                <MenuItem id={name} key={value} value={value}>
                  {name}
                </MenuItem>
              );
            })}
          </TextField>
        );
      }}
    />
  );
};

export default SelectController;
