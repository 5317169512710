import { FC } from 'react';
// components
import CarriersInfoCard from './CarriersCard';
// interfaces
import { CarriersTabProps } from 'interfaces';

const Carriers: FC<CarriersTabProps> = ({ loading, isEdit = false }) => {
  return <CarriersInfoCard loading={loading} isEdit={isEdit} />;
};

export default Carriers;
