import { FC, JSX } from 'react';
import { Grid } from '@mui/material';
// components
import InputController from 'controller/InputController';
import ProductIngredientSelector from 'components/common/selectors/productIngredient';
// constants, styles, interfaces
import { forInputLabelBg } from 'styles/commonComponentStyle';
import { PERCENTAGE_TEXT, PRODUCT_INGREDIENT_TEXT } from 'constants/index';

const Form: FC = (): JSX.Element => {
  return (
    <Grid container spacing={2} sx={forInputLabelBg}>
      <Grid item xs={12} lg={12}>
        <InputController isRequired fieldType="number" name="percentage" title={PERCENTAGE_TEXT} />
      </Grid>

      <Grid item xs={12} lg={12}>
        <ProductIngredientSelector isRequired title={PRODUCT_INGREDIENT_TEXT} name="productIngredient" />
      </Grid>
    </Grid>
  );
};

export default Form;
