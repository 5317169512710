import { FC, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import ConsentForm from './ConsentForm';
import MainLayout from 'components/common/MainLayout';
import { CommonLoader } from 'components/common/CommonLoader';
// context, interfaces, utils
import { isSuperAdmin } from 'lib/helper';
import { AUTH_LINKS } from 'constants/index';
import { PrivateRouteProps } from 'interfaces';
import { AuthContext } from 'contexts/AuthContext';

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const location = useLocation();
  const { currentUser, isLoading, isLoaded, isLoggedIn, userRoles } = useContext(AuthContext);
  const { id, isAccepted } = currentUser || {};
  const isSuper = isSuperAdmin(userRoles);

  if (!isLoading && isLoaded) {
    if (id && isLoggedIn) {
      if (!isSuper) {
        if (!isAccepted) {
          return <ConsentForm />;
        }
      }
      return <MainLayout>{children}</MainLayout>;
    } else {
      return <Navigate to={AUTH_LINKS.LOGIN_LINK} state={{ from: location }} replace />;
    }
  }
  return <CommonLoader />;
};

export default PrivateRoute;
