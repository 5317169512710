import { FC, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Card, Grid } from '@mui/material';
//components
import DataLoader from 'components/common/DataLoader';
import InputController from 'controller/InputController';
import PhoneInputController from 'controller/PhoneInputController';
//constants, interfaces, helper, interface, styles
import {
  SAVE_TEXT,
  ROLE_TEXT,
  PHONE_TEXT,
  EMAIL_TEXT,
  PASSWORD_TEXT,
  LAST_NAME_TEXT,
  FIRST_NAME_TEXT,
  GENERATE_PASSWORD_TEXT,
} from 'constants/index';
import { generateRandomPassword } from 'lib/helper';
import { AddNewStaffFormType, UserCardProps } from 'interfaces';
import { forInputLabelBg } from 'styles/commonComponentStyle';

const StaffCard: FC<UserCardProps> = ({ loading, isEdit = false }): JSX.Element => {
  const { setValue } = useFormContext<AddNewStaffFormType>();

  const generatePassword = () => {
    const password = generateRandomPassword();
    setValue('password', password);
  };

  return (
    <Card sx={forInputLabelBg}>
      {loading ? (
        <DataLoader columns={12} rows={isEdit ? 4 : 5} />
      ) : (
        <Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <InputController
                isRequired
                name="firstName"
                fieldType="text"
                disabled={loading}
                title={FIRST_NAME_TEXT}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <InputController
                isRequired
                name="lastName"
                fieldType="text"
                disabled={loading}
                title={LAST_NAME_TEXT}
              />
            </Grid>

            <Grid item xs={12} lg={12}>
              <InputController name="email" fieldType="text" title={EMAIL_TEXT} isRequired />
            </Grid>

            <Grid item xs={12} lg={12}>
              <PhoneInputController name="phone" title={PHONE_TEXT} />
            </Grid>

            <Grid item xs={12} lg={12}>
              <InputController fieldType="text" name="role" title={ROLE_TEXT} />
            </Grid>

            {!isEdit ? (
              <Fragment>
                <Grid item xs={12} lg={7}>
                  <InputController
                    isRequired
                    name="password"
                    title={PASSWORD_TEXT}
                    fieldType="password"
                    isPassword
                  />
                </Grid>

                <Grid item xs={12} lg={5}>
                  <Button variant="contained" fullWidth sx={{ height: 56 }} onClick={generatePassword}>
                    {GENERATE_PASSWORD_TEXT}
                  </Button>
                </Grid>
              </Fragment>
            ) : (
              <Grid item xs={12} lg={12} justifyContent="flex-end" display="flex">
                <Button type="submit" variant="contained" size="small" disabled={loading}>
                  {SAVE_TEXT}
                </Button>
              </Grid>
            )}
          </Grid>
        </Fragment>
      )}
    </Card>
  );
};

export default StaffCard;
