import { SelectType } from 'interfaces';
import { BottlePricesPayload } from 'generated/graphql';
import {} from 'interfaces/TabTypes';
import { TabEnum, PAGE_LIMIT, selectTypeInitialValues } from 'constants/index';

export type State = {
  page: number;
  count: number;
  rowsPerPage: number;
  bottleSize: SelectType;
  bottleType: string;
  activeTab: TabEnum;
  data: BottlePricesPayload['data'];
};

export const initialState: State = {
  page: 1,
  count: 0,
  data: [],
  bottleType: '',
  rowsPerPage: PAGE_LIMIT,
  bottleSize: selectTypeInitialValues,
  activeTab: TabEnum.Active,
};

export enum ActionType {
  SET_PAGE = 'setPage',
  SET_DATA = 'setData',
  SET_COUNT = 'setCount',
  SET_BOTTLE_TYPE = 'setBottleType',
  SET_BOTTLE_SIZE = 'setBottleSize',
  SET_ACTIVE_TAB = 'setActiveTab',
  SET_ROWS_PER_PAGE = 'setRowsPerPage',
}

export type Action =
  | { type: ActionType.SET_PAGE; page: number }
  | { type: ActionType.SET_COUNT; count: number }
  | { type: ActionType.SET_BOTTLE_TYPE; bottleType: string }
  | { type: ActionType.SET_ROWS_PER_PAGE; rowsPerPage: number }
  | { type: ActionType.SET_BOTTLE_SIZE; bottleSize: SelectType }
  | { type: ActionType.SET_DATA; data: BottlePricesPayload['data'] }
  | { type: ActionType.SET_ACTIVE_TAB; activeTab: TabEnum };

export const bottlePriceReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case ActionType.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
      };

    case ActionType.SET_COUNT:
      return {
        ...state,
        count: action.count,
      };

    case ActionType.SET_DATA:
      return {
        ...state,
        data: action.data,
      };

    case ActionType.SET_BOTTLE_SIZE:
      return {
        ...state,
        bottleSize: action.bottleSize,
      };

    case ActionType.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };

    case ActionType.SET_BOTTLE_TYPE:
      return {
        ...state,
        bottleType: action.bottleType,
      };
  }
};
