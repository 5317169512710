import { FC, Fragment } from 'react';
import { Box } from '@mui/material';
// components
import SupplierInfoCard from 'components/main/Ingredients/Add/components/General/SupplierInfoCard';
import IngredientCostInfoCard from 'components/main/Ingredients/Add/components/General/IngredientCostInfoCard';
import IngredientBasicInfoCard from 'components/main/Ingredients/Add/components/General/IngredientBasicInfoCard';
import IngredientDensityInfoCard from 'components/main/Ingredients/Add/components/General/IngredientDensityInfoCard';
import IngredientManufacturingInfoCard from 'components/main/Ingredients/Add/components/General/IngredientManufacturingInfoCard';
// interfaces
import { isLoadingType } from 'interfaces';

const General: FC<isLoadingType> = ({ loading }) => {
  return (
    <Fragment>
      <IngredientBasicInfoCard loading={loading} />
      <Box mt={3}>
        <IngredientCostInfoCard loading={loading} />
      </Box>
      <Box mt={3}>
        <SupplierInfoCard loading={loading} />
      </Box>
      <Box mt={3}>
        <IngredientManufacturingInfoCard loading={loading} />
      </Box>
      <Box mt={3}>
        <IngredientDensityInfoCard loading={loading} />
      </Box>
    </Fragment>
  );
};

export default General;
