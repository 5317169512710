import { FC, Fragment } from 'react';
// components
import RowView from 'components/common/RowView';
import DataLoader from 'components/common/DataLoader';
// constants, context, graphql
import {
  BOTTLE_TEXT,
  SERVING_TEXT,
  LOH_COST_TEXT,
  BOTTLE_COST_TEXT,
  FLAVOR_COST_TEXT,
  COATING_COST_TEXT,
  NECKBAND_COST_TEXT,
  PER_100_PRICE_TEXT,
  EXCIPIENT_COST_TEXT,
  SWEETENER_COST_TEXT,
  CONVERSION_COST_TEXT,
  INGREDIENTS_COST_TEXT,
  SAND_COATING_TEXT,
  SYRUP_BASE_TEXT,
  COST_TEXT,
} from 'constants/index';
import { FormulaCostCardProps } from 'interfaces';

const FormulaCostCard: FC<FormulaCostCardProps> = ({ costing, loading }) => {
  const { formulaCost } = costing || {};

  const {
    lohCost,
    neckband,
    flavorCost,
    coatingCost,
    bottlePrice,
    per1000Cost,
    sweetenerCost,
    excipientCost,
    conversionCost,
    ingredientsCost,
    sandCoatingCost,
    syrupBaseCost,
  } = formulaCost || {};

  return (
    <Fragment>
      {loading ? (
        <DataLoader columns={12} rows={6} />
      ) : (
        <Fragment>
          <RowView name={BOTTLE_COST_TEXT} value={bottlePrice ? `$${bottlePrice} / ${BOTTLE_TEXT}` : ''} />

          <RowView name={NECKBAND_COST_TEXT} value={neckband ? `$${neckband} / ${BOTTLE_TEXT}` : ''} />

          <RowView
            name={CONVERSION_COST_TEXT}
            value={conversionCost ? `$${conversionCost} / ${BOTTLE_TEXT}` : ''}
          />

          <RowView
            name={INGREDIENTS_COST_TEXT}
            value={ingredientsCost ? `$${ingredientsCost} / ${SERVING_TEXT}` : ''}
          />

          <RowView
            name={EXCIPIENT_COST_TEXT}
            value={excipientCost ? `$${excipientCost} / ${SERVING_TEXT}` : ''}
          />

          <RowView name={COATING_COST_TEXT} value={coatingCost ? `$${coatingCost} / ${SERVING_TEXT}` : ''} />

          <RowView
            name={`${SAND_COATING_TEXT} ${COST_TEXT}`}
            value={sandCoatingCost ? `$${sandCoatingCost} / ${SERVING_TEXT}` : ''}
          />

          <RowView
            name={`${SYRUP_BASE_TEXT} ${COST_TEXT}`}
            value={syrupBaseCost ? `$${syrupBaseCost} / ${SERVING_TEXT}` : ''}
          />

          <RowView
            name={SWEETENER_COST_TEXT}
            value={sweetenerCost ? `$${sweetenerCost} / ${SERVING_TEXT}` : ''}
          />

          <RowView name={FLAVOR_COST_TEXT} value={flavorCost ? `$${flavorCost} / ${SERVING_TEXT}` : ''} />

          <RowView name={LOH_COST_TEXT} value={lohCost ? `$${lohCost}  / ${BOTTLE_TEXT}` : ''} />

          <RowView name={PER_100_PRICE_TEXT} value={per1000Cost ? `$${per1000Cost}` : ''} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default FormulaCostCard;
