import { FC, useContext } from 'react';
// helper, interface, context
import { isAdmin } from 'lib/helper';
import { ChildrenType } from 'interfaces';
import { AuthContext } from 'contexts/AuthContext';

export const AdminGuard: FC<ChildrenType> = ({ children }) => {
  const { userRoles } = useContext(AuthContext);

  const isAdminUser = isAdmin(userRoles);

  return <>{isAdminUser ? children : null}</>;
};
