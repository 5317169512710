import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import DataLoader from 'components/common/DataLoader';
import BottlePriceForm from './components/BottlePriceForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  BottlePricePayload,
  BottleTypeEnum,
  useCreateBottlePriceMutation,
  useGetBottlePriceLazyQuery,
  useUpdateBottlePriceMutation,
} from 'generated/graphql';
import {
  HTTP_STATUS,
  bottlePriceInitialValue,
  EDIT_BOTTLE_PRICE_TEXT,
  BOTTLE_PRICE_IS_UPDATED_TEXT,
  BOTTLE_PRICE_IS_CREATING_TEXT,
  ADD_BOTTLE_PRICE_TEXT,
  ADD_TEXT,
} from 'constants/index';
import { AddBottlePriceFormType, ParamType } from 'interfaces';
import { updateBottlePriceTypeSchema } from 'validationSchema';

const Edit: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const bottlePriceMethods = useForm<AddBottlePriceFormType>({
    defaultValues: bottlePriceInitialValue,
    resolver: yupResolver(updateBottlePriceTypeSchema),
  });

  const { handleSubmit: handleBottlePriceSubmit, setValue: setBottlePriceValue } = bottlePriceMethods;

  const [updateBottlePrice, { loading: productTypeLoading }] = useUpdateBottlePriceMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateBottlePrice } = data;
      const { response } = updateBottlePrice || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [createBottlePrice, { loading: bottlePriceLoading }] = useCreateBottlePriceMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createBottlePrice } = data;
      const { response } = createBottlePrice || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getBottlePrice, { loading: getLoading }] = useGetBottlePriceLazyQuery({
    onCompleted: (data) => {
      const { getBottlePrice } = data || {};
      const { response, bottlePrice } = getBottlePrice || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(bottlePrice as BottlePricePayload['bottlePrice']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });
  /**
   * Datas on supplier submit
   * @param data
   */
  const onBottlePriceSubmit: SubmitHandler<AddBottlePriceFormType> = async (data) => {
    const { isCr, price, bottleSize, type, suppliers } = data || {};
    const { value } = bottleSize || {};
    const suppliersIds = suppliers?.map((item) => {
      return item?.value;
    });

    if (id) {
      await updateBottlePrice({
        variables: {
          updateBottlePriceInput: {
            id: id || '',
            isCr: isCr || false,
            price: parseFloat(price) || 0,
            type: type as BottleTypeEnum,
            bottleSizeId: value || '',
            bottlePriceSuppliers: suppliersIds ?? [],
          },
        },
      });
    } else {
      await createBottlePrice({
        variables: {
          createBottlePriceInput: {
            isCr: isCr || false,
            price: parseFloat(price) || 0,
            type: type as BottleTypeEnum,
            bottleSizeId: value || '',
            bottlePriceSuppliers: suppliersIds ?? [],
          },
        },
      });
    }
  };

  const fetchBottlePrice = useCallback(async () => {
    id &&
      (await getBottlePrice({
        variables: {
          getBottlePriceInput: {
            id,
          },
        },
      }));
  }, [id, getBottlePrice]);

  const setValues = (params: BottlePricePayload['bottlePrice']) => {
    const { isCr, price, bottleSize, type, bottlePriceSuppliers } = params || {};
    const { name, id } = bottleSize || {};

    const suppliers = bottlePriceSuppliers?.map((item) => {
      return { name: item?.supplier?.name || '', value: item?.supplier?.id || '' };
    });

    setBottlePriceValue('isCr', isCr || false);
    setBottlePriceValue('price', `${price}` || '0');
    setBottlePriceValue('type', type || '');
    setBottlePriceValue('bottleSize', {
      value: id ?? '',
      name: name ?? '',
    });
    setBottlePriceValue('suppliers', suppliers ?? []);
  };

  useEffect(() => {
    id && fetchBottlePrice();
  }, [id, fetchBottlePrice]);

  const loading = productTypeLoading || getLoading || bottlePriceLoading;

  return (
    <Fragment>
      <FormProvider {...bottlePriceMethods}>
        <form onSubmit={handleBottlePriceSubmit(onBottlePriceSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{id ? EDIT_BOTTLE_PRICE_TEXT : ADD_BOTTLE_PRICE_TEXT}</Typography>
            {!id && (
              <Button type="submit" variant="contained" size="small">
                {ADD_TEXT}
              </Button>
            )}
          </Box>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12}>
              {getLoading ? (
                <DataLoader rows={5} columns={12} />
              ) : (
                <BottlePriceForm loading={loading} isEdit={!!id} />
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <BackdropLoader
        open={loading}
        text={id ? BOTTLE_PRICE_IS_UPDATED_TEXT : BOTTLE_PRICE_IS_CREATING_TEXT}
      />
    </Fragment>
  );
};

export default Edit;
