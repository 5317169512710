import { CloseIcon } from 'assets/svgs';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
// components
import { Alert } from 'components/common/Alert';
import { BackdropLoader } from 'components/common/BackdropLoader';
import SweetenerElementForm from './components/SweetenerElementForm';
// Schema, styles, graphql, constants
import { updateSweetenerElementTypeSchema } from 'validationSchema';
import { useCreateSweetenerElementMutation, useUpdateSweetenerElementMutation } from 'generated/graphql';
import {
  SAVE_TEXT,
  HTTP_STATUS,
  sweetenerElementInitialValue,
  EDIT_SWEETENER_ELEMENT_TEXT,
  ADD_SWEETENER_ELEMENT_TEXT,
  CREATE_SWEETENER_ELEMENT_TEXT,
  EDIT_SWEETENER_ELEMENT_UPDATED_TEXT,
} from 'constants/index';
import { AddSweetenerElementFormType, EditSweetenerElementProps } from 'interfaces';

const Edit: FC<EditSweetenerElementProps> = (props): JSX.Element => {
  const { sweetenerElement, open, onClose, fetch, isEdit } = props || {};

  const { sweetenerId } = useParams<{ sweetenerId: string }>();

  const { id } = sweetenerElement || {};

  const methods = useForm<AddSweetenerElementFormType>({
    defaultValues: sweetenerElementInitialValue,
    resolver: yupResolver(updateSweetenerElementTypeSchema),
  });

  const { handleSubmit, setValue, reset } = methods;

  const [createSweetenerElement, { loading: createLoading }] = useCreateSweetenerElementMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createSweetenerElement } = data;
      const { response } = createSweetenerElement || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        fetch();
        reset();
        onClose();
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updateSweetenerElement, { loading: updateLoading }] = useUpdateSweetenerElementMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateSweetenerElement } = data;
      const { response } = updateSweetenerElement || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        fetch();
        reset();
        onClose();
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<AddSweetenerElementFormType> = async (data) => {
    const { qtPerMg, sweetenerComponent } = data || {};
    const { value: sweetenerComponentId } = sweetenerComponent || {};

    if (id) {
      await updateSweetenerElement({
        variables: {
          updateSweetenerElementsInput: {
            id: id || '',
            sweetenerId: sweetenerId || '',
            sweetenerComponentId: sweetenerComponentId || '',
            qtPerMg: parseFloat(qtPerMg),
          },
        },
      });
    } else {
      await createSweetenerElement({
        variables: {
          createSweetenerElementsInput: {
            sweetenerId: sweetenerId || '',
            sweetenerComponentId: sweetenerComponentId || '',
            qtPerMg: parseFloat(qtPerMg),
          },
        },
      });
    }
  };

  const setValues = useCallback(() => {
    if (open && sweetenerElement && isEdit) {
      const { qtPerMg, sweetenerComponent } = sweetenerElement || {};
      const { id: sweetenerComponentId, name: sweetenerComponentName } = sweetenerComponent || {};

      setValue('qtPerMg', `${qtPerMg ?? 0}`);

      setValue('sweetenerComponent', {
        value: sweetenerComponentId ?? '',
        name: sweetenerComponentName ?? '',
      });
    } else {
      reset();
    }
  }, [open, sweetenerElement, isEdit, setValue, reset]);

  useEffect(() => {
    open && setValues();
  }, [open, setValues]);

  const loading = updateLoading || createLoading;

  return (
    <Fragment>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle variant="h6">
          {id ? EDIT_SWEETENER_ELEMENT_TEXT : ADD_SWEETENER_ELEMENT_TEXT}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent dividers>
              <SweetenerElementForm loading={loading} />
            </DialogContent>

            <DialogActions>
              <Box>
                <Button type="submit" variant="contained" size="small" disabled={loading}>
                  {SAVE_TEXT}
                </Button>
              </Box>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>

      <BackdropLoader
        open={loading}
        text={id ? EDIT_SWEETENER_ELEMENT_UPDATED_TEXT : CREATE_SWEETENER_ELEMENT_TEXT}
      />
    </Fragment>
  );
};

export default Edit;
