import { HttpStatusCode } from 'axios';
import { CloseIcon } from 'assets/svgs';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
// components
import Form from './components/Form';
import { Alert } from 'components/common/Alert';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { ingredientSyrupTypeSchema } from 'validationSchema';
import { IngredientSyrupTypeFormType, IngredientSyrupModalProps, ParamType } from 'interfaces';
import {
  useCreateProductIngredientSyrupTypeMutation,
  useUpdateProductIngredientSyrupTypeMutation,
} from 'generated/graphql';
import {
  SAVE_TEXT,
  ingredientSyrupTypeInitialValue,
  FORMULATION_SYRUP_TYPE_INGREDIENT_TEXT,
} from 'constants/index';
import {
  useAddTitleText,
  useAddLoadingText,
  useUpdateTitleText,
  useUpdateLoadingText,
} from '../../../../hooks';

const Modal: FC<IngredientSyrupModalProps> = (props): JSX.Element => {
  const { item, open, onClose, fetch, isEdit } = props || {};

  const addText = useAddTitleText(FORMULATION_SYRUP_TYPE_INGREDIENT_TEXT);
  const editText = useUpdateTitleText(FORMULATION_SYRUP_TYPE_INGREDIENT_TEXT);
  const creatingText = useAddLoadingText(FORMULATION_SYRUP_TYPE_INGREDIENT_TEXT);
  const editingText = useUpdateLoadingText(FORMULATION_SYRUP_TYPE_INGREDIENT_TEXT);

  const { id: productFormulationSyrupTypeId } = useParams<ParamType>();

  const { id } = item || {};

  const methods = useForm<IngredientSyrupTypeFormType>({
    defaultValues: ingredientSyrupTypeInitialValue,
    resolver: yupResolver(ingredientSyrupTypeSchema),
  });

  const { handleSubmit, setValue, reset } = methods;

  const [createProductIngredientSyrupType, { loading: createLoading }] =
    useCreateProductIngredientSyrupTypeMutation({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { createProductIngredientSyrupType } = data;
        const { response } = createProductIngredientSyrupType || {};
        const { status, message } = response || {};
        if (status === HttpStatusCode.Created) {
          Alert.success(message || '');
          fetch();
          reset();
          onClose();
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    });

  const [updateProductIngredientSyrupType, { loading: updateLoading }] =
    useUpdateProductIngredientSyrupTypeMutation({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { updateProductIngredientSyrupType } = data;
        const { response } = updateProductIngredientSyrupType || {};
        const { status, message } = response || {};
        if (status === HttpStatusCode.Ok) {
          Alert.success(message || '');
          fetch();
          reset();
          onClose();
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    });

  const onSubmit: SubmitHandler<IngredientSyrupTypeFormType> = async (data) => {
    const { percentage, productIngredient } = data || {};
    const { value: productIngredientId } = productIngredient || {};

    if (id) {
      await updateProductIngredientSyrupType({
        variables: {
          updateProductIngredientSyrupTypeInput: {
            id: id || '',
            productIngredientId,
            percentage: parseFloat(percentage),
            productFormulationSyrupTypeId: productFormulationSyrupTypeId || '',
          },
        },
      });
    } else {
      await createProductIngredientSyrupType({
        variables: {
          createProductIngredientSyrupTypeInput: {
            productIngredientId,
            percentage: parseFloat(percentage),
            productFormulationSyrupTypeId: productFormulationSyrupTypeId || '',
          },
        },
      });
    }
  };

  const setValues = useCallback(() => {
    if (open && item && isEdit) {
      const { percentage, productIngredients } = item || {};
      const { id: productIngredientId, name } = productIngredients || {};

      setValue('percentage', `${percentage || ''}`);
      setValue('productIngredient', {
        value: productIngredientId ?? '',
        name: name ?? '',
      });
    } else {
      reset();
    }
  }, [open, item, isEdit, setValue, reset]);

  useEffect(() => {
    open && setValues();
  }, [open, setValues]);

  const loading = updateLoading || createLoading;

  return (
    <Fragment>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle variant="h6">{id ? editText : addText}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent dividers>
              <Form />
            </DialogContent>

            <DialogActions>
              <Box>
                <Button type="submit" variant="contained" size="small" disabled={loading}>
                  {SAVE_TEXT}
                </Button>
              </Box>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>

      <BackdropLoader open={loading} text={id ? editingText : creatingText} />
    </Fragment>
  );
};

export default Modal;
