import { Box, Button, Card, Grid } from '@mui/material';
import { FC } from 'react';
// component
import DataLoader from 'components/common/DataLoader';
import InputController from 'controller/InputController';
// styles, interface, constants
import {
  ADDED_SUGAR_TEXT,
  BIOTIN_TEXT,
  BORON_TEXT,
  CALCIUM_TEXT,
  CALORIES_TEXT,
  CARBOHYDRATES_TEXT,
  CHLORIDE_TEXT,
  CHOLESTEROL_TEXT,
  CHOLINE_TEXT,
  CHROMIUM_TEXT,
  COPPER_TEXT,
  DIETARY_FIBER_TEXT,
  FOLATE_TEXT,
  IODINE_TEXT,
  IRON_TEXT,
  MAGNESIUM_TEXT,
  MANGANESE_TEXT,
  MOISTURE_IN_GRAMS_TEXT,
  MOLYBDENUM_TEXT,
  MONOUNSATURATED_FAT_TEXT,
  NIACIN_TEXT,
  ORGANIC_NUMERICAL_TEXT,
  PANTOTHENIC_ACID_TEXT,
  PHOSPHORUS_TEXT,
  POLYUNSATURATED_FAT_TEXT,
  POTASSIUM_TEXT,
  PROTEIN_TEXT,
  RIBOFLAVIN_TEXT,
  SATURATED_FAT_TEXT,
  SAVE_TEXT,
  SELENIUM_TEXT,
  SODIUM_TEXT,
  THIAMIN_TEXT,
  TOTAL_FAT_TEXT,
  TOTAL_SUGARS_TEXT,
  TRANS_FAT_TEXT,
  VITAMIN_A_TEXT,
  VITAMIN_B12_TEXT,
  VITAMIN_B6_TEXT,
  VITAMIN_C_TEXT,
  VITAMIN_D_TEXT,
  VITAMIN_E_TEXT,
  VITAMIN_K2_TEXT,
  ZINC_TEXT,
} from 'constants/index';
import { NutrientsInfoCardProps } from 'interfaces';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';

const NutrientsInfoCard: FC<NutrientsInfoCardProps> = ({ loading, isEdit }) => {
  return (
    <Card sx={forInputLabelBg}>
      {loading ? (
        <DataLoader columns={6} rows={21} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <InputController
              name="ingredientInfo.organic"
              fieldType="number"
              title={ORGANIC_NUMERICAL_TEXT}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController
              name="ingredientInfo.moisture"
              fieldType="number"
              title={MOISTURE_IN_GRAMS_TEXT}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.calories" fieldType="number" title={CALORIES_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.protein" fieldType="number" title={PROTEIN_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController
              name="ingredientInfo.carbohydrates"
              fieldType="number"
              title={CARBOHYDRATES_TEXT}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController
              name="ingredientInfo.dietaryFiber"
              fieldType="number"
              title={DIETARY_FIBER_TEXT}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.totalSugars" fieldType="number" title={TOTAL_SUGARS_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.addedSugar" fieldType="number" title={ADDED_SUGAR_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.totalFat" fieldType="number" title={TOTAL_FAT_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController
              name="ingredientInfo.saturatedFat"
              fieldType="number"
              title={SATURATED_FAT_TEXT}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController
              name="ingredientInfo.monounsaturatedFat"
              fieldType="number"
              title={MONOUNSATURATED_FAT_TEXT}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController
              name="ingredientInfo.polyunsaturatedFat"
              fieldType="number"
              title={POLYUNSATURATED_FAT_TEXT}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.transFat" fieldType="number" title={TRANS_FAT_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.cholesterol" fieldType="number" title={CHOLESTEROL_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.vitaminA" fieldType="number" title={VITAMIN_A_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.biotin" fieldType="number" title={BIOTIN_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController
              name="ingredientInfo.pantothenicAcid"
              fieldType="number"
              title={PANTOTHENIC_ACID_TEXT}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.thiamin" fieldType="number" title={THIAMIN_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.riboflavin" fieldType="number" title={RIBOFLAVIN_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.niacin" fieldType="number" title={NIACIN_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.vitaminB6" fieldType="number" title={VITAMIN_B6_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.vitaminB12" fieldType="number" title={VITAMIN_B12_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.vitaminC" fieldType="number" title={VITAMIN_C_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.vitaminD" fieldType="number" title={VITAMIN_D_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.vitaminE" fieldType="number" title={VITAMIN_E_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.folate" fieldType="number" title={FOLATE_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.magnesium" fieldType="number" title={MAGNESIUM_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.zinc" fieldType="number" title={ZINC_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.selenium" fieldType="number" title={SELENIUM_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.copper" fieldType="number" title={COPPER_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.manganese" fieldType="number" title={MANGANESE_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.chromium" fieldType="number" title={CHROMIUM_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.molybdenum" fieldType="number" title={MOLYBDENUM_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.chloride" fieldType="number" title={CHLORIDE_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.vitaminK2" fieldType="number" title={VITAMIN_K2_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.choline" fieldType="number" title={CHOLINE_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.iodine" fieldType="number" title={IODINE_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.calcium" fieldType="number" title={CALCIUM_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.phosphorus" fieldType="number" title={PHOSPHORUS_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.iron" fieldType="number" title={IRON_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.potassium" fieldType="number" title={POTASSIUM_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.boron" fieldType="number" title={BORON_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="ingredientInfo.sodium" fieldType="number" title={SODIUM_TEXT} />
          </Grid>
        </Grid>
      )}

      {isEdit ? (
        <Box sx={flexMarginTopEndCenter}>
          <Button type="submit" id="nutrient-btn" variant="contained" size="small" disabled={loading}>
            {SAVE_TEXT}
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default NutrientsInfoCard;
