import { FC, Fragment } from 'react';
import { FormProvider } from 'react-hook-form';
import { Box, Step, StepConnector, StepLabel, Stepper } from '@mui/material';
// components
import Step3 from './Step3';
import FactBoxStep1 from './Step1';
import FactBoxStep2 from './Step2';
// interfaces
import { FactBoxStepFormProps } from 'interfaces';

const steps = [{ id: 1 }, { id: 2 }, { id: 3 }];

const FactBoxStepForm: FC<FactBoxStepFormProps> = ({
  activeStep,
  dosageMethods,
  formulaMethods,
  ingredientMethods,
}) => {
  const { getValues, setValue } = ingredientMethods;
  const { formulaIngredients } = getValues();

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <FormProvider {...ingredientMethods}>
            <FactBoxStep1 />
          </FormProvider>
        );

      case 1:
        return (
          <FormProvider {...dosageMethods}>
            <FactBoxStep2 formulaIngredients={formulaIngredients} setValue={setValue} />
          </FormProvider>
        );

      case 2:
        return (
          <FormProvider {...ingredientMethods}>
            <Step3 formulaMethods={formulaMethods} />
          </FormProvider>
        );

      default:
        return <Fragment />;
    }
  };

  return (
    <Fragment>
      <Stepper activeStep={activeStep} connector={<StepConnector />} alternativeLabel>
        {steps.map(({ id }) => (
          <Step key={id}>
            <StepLabel></StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box mt="24px !important">{renderStepContent(activeStep)}</Box>
    </Fragment>
  );
};

export default FactBoxStepForm;
