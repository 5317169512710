import { FC, Fragment, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
// interfaces, context
import { SidebarMainProps } from 'interfaces';
import { DrawerContext } from 'contexts/DrawerContext';

const SidebarMain: FC<SidebarMainProps> = ({ list }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setMobileOpen } = useContext(DrawerContext);

  const routeChange = (path: string) => {
    navigate(path);
    setMobileOpen(false);
  };

  return (
    <Fragment>
      {list?.map((item) => {
        const { route, icon, text } = item;
        const isActive = pathname.includes(route);

        return (
          <ListItem key={text} disablePadding>
            <ListItemButton
              selected={isActive}
              id={route?.replace('/', '')}
              onClick={() => routeChange(route)}
              className={isActive ? 'selectedList' : ''}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </Fragment>
  );
};

export default SidebarMain;
