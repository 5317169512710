import { FC, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Card, Grid } from '@mui/material';
// components
import SelectController from 'controller/Select';
import CheckboxController from 'controller/Checkbox';
import DataLoader from 'components/common/DataLoader';
import InputController from 'controller/InputController';
// styles, constants, interfaces
import {
  SAVE_TEXT,
  POTENCY_TEXT,
  SHELF_LIFE_TEXT,
  GUMMY_OVERAGE_TEXT,
  COST_DOLLAR_KG_TEXT,
  DEFINING_AMOUNT_TEXT,
  SUGGESTED_OVERAGE_TEXT,
  SHOW_B3,
  NUTRIENT_NAME_TEXT,
  NUTRIENTS_TEXT,
} from 'constants/index';
import { DEFINING_AMOUNT_DATA } from 'constants/arrayMappedData';
import { IngredientCostInfoCardProps, AddNewIngredientFormType } from 'interfaces';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';

const IngredientCostInfoCard: FC<IngredientCostInfoCardProps> = ({ loading, isEdit }) => {
  const { watch } = useFormContext<AddNewIngredientFormType>();
  const { amount } = watch();

  return (
    <Card sx={forInputLabelBg}>
      {loading ? (
        <DataLoader columns={6} rows={3} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <SelectController
              isRequired
              name="amount"
              title={DEFINING_AMOUNT_TEXT}
              options={DEFINING_AMOUNT_DATA}
            />
          </Grid>
          {amount === '1' && (
            <Grid item xs={12} lg={6}>
              <CheckboxController name="nutrients" title={NUTRIENTS_TEXT} />
            </Grid>
          )}

          <Grid item xs={12} lg={6}>
            <CheckboxController name="nutrientName" title={NUTRIENT_NAME_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <CheckboxController name="showB3" title={SHOW_B3} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController isRequired name="potency" fieldType="number" title={`${POTENCY_TEXT} %`} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="cost" fieldType="number" title={COST_DOLLAR_KG_TEXT} isRequired />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="shelfLife" fieldType="number" title={SHELF_LIFE_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController
              name="suggestedOverage"
              fieldType="number"
              title={SUGGESTED_OVERAGE_TEXT}
              inputAdornmentIcon={'%'}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController
              name="gummyOverage"
              fieldType="number"
              title={GUMMY_OVERAGE_TEXT}
              inputAdornmentIcon={'%'}
            />
          </Grid>
        </Grid>
      )}
      {isEdit ? (
        <Box sx={flexMarginTopEndCenter}>
          <Button type="submit" variant="contained" id="cost-info-btn" size="small" disabled={loading}>
            {SAVE_TEXT}
          </Button>
        </Box>
      ) : (
        <Fragment></Fragment>
      )}
    </Card>
  );
};

export default IngredientCostInfoCard;
