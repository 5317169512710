import { FC, Fragment } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
//context, interfaces, utils
import { getToken } from 'lib/helper';
import { PublicRouteProps } from 'interfaces';

const PublicRoute: FC<PublicRouteProps> = ({ children }) => {
  const location = useLocation();
  const token = getToken();

  if (token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else {
    return <Fragment>{children}</Fragment>;
  }
};

export default PublicRoute;
