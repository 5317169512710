import { SelectType } from 'interfaces';
import { BottleCapacitiesPayload } from 'generated/graphql';
import { TabEnum, PAGE_LIMIT, selectTypeInitialValues } from 'constants/index';

export type State = {
  page: number;
  count: number;
  rowsPerPage: number;
  bottleSize: SelectType;
  productType: SelectType;
  servingSize: SelectType;
  productSize: SelectType;
  activeTab: TabEnum;
  data: BottleCapacitiesPayload['data'];
};

export const initialState: State = {
  page: 1,
  count: 0,
  data: [],
  rowsPerPage: PAGE_LIMIT,
  bottleSize: selectTypeInitialValues,
  productType: selectTypeInitialValues,
  servingSize: selectTypeInitialValues,
  productSize: selectTypeInitialValues,
  activeTab: TabEnum.Active,
};

export enum ActionType {
  SET_PAGE = 'setPage',
  SET_DATA = 'setData',
  SET_COUNT = 'setCount',
  SET_ACTIVE_TAB = 'setActiveTab',
  SET_BOTTLE_SIZE = 'setBottleSize',
  SET_PRODUCT_SIZE = 'setProductSize',
  SET_SERVING_SIZE = 'setServingSize',
  SET_PRODUCT_TYPE = 'setProductType',
  SET_ROWS_PER_PAGE = 'setRowsPerPage',
}

export type Action =
  | { type: ActionType.SET_PAGE; page: number }
  | { type: ActionType.SET_COUNT; count: number }
  | { type: ActionType.SET_ROWS_PER_PAGE; rowsPerPage: number }
  | { type: ActionType.SET_BOTTLE_SIZE; bottleSize: SelectType }
  | { type: ActionType.SET_SERVING_SIZE; servingSize: SelectType }
  | { type: ActionType.SET_PRODUCT_TYPE; productType: SelectType }
  | { type: ActionType.SET_PRODUCT_SIZE; productSize: SelectType }
  | { type: ActionType.SET_DATA; data: BottleCapacitiesPayload['data'] }
  | { type: ActionType.SET_ACTIVE_TAB; activeTab: TabEnum };

export const bottleCapacityReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case ActionType.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
      };

    case ActionType.SET_COUNT:
      return {
        ...state,
        count: action.count,
      };

    case ActionType.SET_DATA:
      return {
        ...state,
        data: action.data,
      };

    case ActionType.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };

    case ActionType.SET_BOTTLE_SIZE:
      return {
        ...state,
        bottleSize: action.bottleSize,
      };

    case ActionType.SET_PRODUCT_TYPE:
      return {
        ...state,
        productType: action.productType,
      };

    case ActionType.SET_SERVING_SIZE:
      return {
        ...state,
        servingSize: action.servingSize,
      };

    case ActionType.SET_PRODUCT_SIZE:
      return {
        ...state,
        productSize: action.productSize,
      };
  }
};
