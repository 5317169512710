import { PAGE_LIMIT, selectTypeInitialValues, TabEnum } from 'constants/index';
import { ProductSizesPayload } from 'generated/graphql';
import { SelectType } from '../interfaces';

export type State = {
  page: number;
  count: number;
  search: string;
  rowsPerPage: number;
  activeTab: TabEnum;
  productType: SelectType;
  subProductType: SelectType;
  data: ProductSizesPayload['data'];
};

export const initialState: State = {
  page: 1,
  count: 0,
  data: [],
  search: '',
  productType: selectTypeInitialValues,
  subProductType: selectTypeInitialValues,
  rowsPerPage: PAGE_LIMIT,
  activeTab: TabEnum.Active,
};

export enum ActionType {
  SET_PAGE = 'setPage',
  SET_DATA = 'setData',
  SET_COUNT = 'setCount',
  SET_SEARCH = 'setSearch',
  SET_ACTIVE_TAB = 'setActiveTab',
  SET_PRODUCT_TYPE = 'setProductType',
  SET_ROWS_PER_PAGE = 'setRowsPerPage',
  SET_SUB_PRODUCT = 'setSubProductType',
}

export type Action =
  | { type: ActionType.SET_PAGE; page: number }
  | { type: ActionType.SET_COUNT; count: number }
  | { type: ActionType.SET_SEARCH; search: string }
  | { type: ActionType.SET_ACTIVE_TAB; activeTab: TabEnum }
  | { type: ActionType.SET_ROWS_PER_PAGE; rowsPerPage: number }
  | { type: ActionType.SET_PRODUCT_TYPE; productType: SelectType }
  | { type: ActionType.SET_SUB_PRODUCT; subProductType: SelectType }
  | { type: ActionType.SET_DATA; data: ProductSizesPayload['data'] };

export const productSizeReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case ActionType.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
      };

    case ActionType.SET_COUNT:
      return {
        ...state,
        count: action.count,
      };

    case ActionType.SET_DATA:
      return {
        ...state,
        data: action.data,
      };

    case ActionType.SET_SEARCH:
      return {
        ...state,
        search: action.search,
      };

    case ActionType.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };

    case ActionType.SET_PRODUCT_TYPE:
      return {
        ...state,
        productType: action.productType,
      };

    case ActionType.SET_SUB_PRODUCT:
      return {
        ...state,
        subProductType: action.subProductType,
      };
  }
};
