import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
//components
import StaffForm from './StaffForm';
import { Alert } from 'components/common/Alert';
//interfaces, schema, graphql, constants
import { AddNewStaffFormType } from 'interfaces';
import { addNewStaffSchema } from 'validationSchema';
import { useCreateStaffMutation } from 'generated/graphql';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { ADD_TEXT, HTTP_STATUS, STAFF_TEXT, addNewStaffInitialValue } from 'constants/index';

const AddStaff = () => {
  const navigate = useNavigate();

  const methods = useForm<AddNewStaffFormType>({
    resolver: yupResolver(addNewStaffSchema),
    defaultValues: addNewStaffInitialValue,
  });

  const { handleSubmit } = methods;

  const [createStaff, { loading }] = useCreateStaffMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createStaff } = data;
      const { response } = createStaff || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigate(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<AddNewStaffFormType> = async (data) => {
    const { email, firstName, lastName, password, phone, role } = data || {};
    await createStaff({
      variables: {
        createStaffInput: {
          email: email?.toLowerCase(),
          lastName,
          password,
          firstName,
          staffRole: role,
          ...(phone && { phone: phone }),
        },
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={flexCenterBetween}>
          <Typography variant="h5">{STAFF_TEXT}</Typography>
          <Button variant="contained" color="primary" type="submit">
            {ADD_TEXT}
          </Button>
        </Box>
        <StaffForm loading={loading} />
      </form>
    </FormProvider>
  );
};

export default AddStaff;
