import { FC } from 'react';
import { Box, Grid } from '@mui/material';
// components
import TableSearchField from 'components/common/TableSearchField';
// constants, reducer, interface
import { ActionType } from 'reducer/ingredientReducer';
import { IngredientsTableFilterProps } from 'interfaces';

const IngredientsTableFilter: FC<IngredientsTableFilterProps> = ({ dispatch, state }) => {
  const { search } = state || {};

  const onSearch = (val: string) => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_SEARCH, search: val });
  };

  const onClear = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_SEARCH, search: '' });
  };

  return (
    <Box sx={{ px: { xs: 2, lg: 3 }, py: { xs: 2, lg: 3 } }}>
      <Grid container spacing={2} sx={{ alignItems: 'center' }}>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <TableSearchField search={search} withSpacing={false} onChange={onSearch} onClear={onClear} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default IngredientsTableFilter;
