import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Checkbox, FormControlLabel, FormHelperText, Grid, Typography } from '@mui/material';
// components
import DataLoader from 'components/common/DataLoader';
// interfaces
import { HELPER_TEXT } from 'constants/index';
import { CheckboxControllerProps } from 'interfaces';

const CheckboxGroupController: FC<CheckboxControllerProps> = ({
  name,
  title,
  options,
  isRequired,
  loading = false,
  loaderRows = 1,
  loaderColumns = 12,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field, fieldState }) => {
        const { onChange, value } = field || {};
        const { invalid, error } = fieldState || {};
        const { message } = error || {};

        return (
          <Box>
            <Typography variant="body2" fontSize={14} fontWeight={400} marginY={2}>
              {isRequired ? `${title} *` : title}
            </Typography>

            <Grid container spacing={2}>
              {loading ? (
                <DataLoader columns={loaderColumns} rows={loaderRows} />
              ) : (
                <Fragment>
                  {options?.map((checkbox) => (
                    <Grid item xs={4} key={checkbox.value}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={loading}
                            id={checkbox.name}
                            checked={value.includes(checkbox.value)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                onChange([...value, checkbox.value]);
                              } else {
                                const updatedValues = value.filter(
                                  (value: string) => value !== checkbox.value,
                                );
                                onChange(updatedValues);
                              }
                            }}
                            value={checkbox.value}
                          />
                        }
                        label={checkbox.name}
                      />
                    </Grid>
                  ))}
                </Fragment>
              )}
            </Grid>
            <FormHelperText error={invalid} id={`${name}-${HELPER_TEXT}`}>
              {message || ''}
            </FormHelperText>
          </Box>
        );
      }}
    />
  );
};

export default CheckboxGroupController;
