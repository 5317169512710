import { useNavigate } from 'react-router-dom';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { FC, Fragment, MouseEvent, useState } from 'react';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
// constants, interface
import { ActionMenuDropdownProps } from 'interfaces';
import {
  ACTIVATE_TEXT,
  DEACTIVATE_TEXT,
  EDIT_TEXT,
  FORMULA_DETAIL_ROUTE,
  REMOVE_TEXT,
  VIEW_TEXT,
} from 'constants/index';

const ActionMenuDropdown: FC<ActionMenuDropdownProps> = ({
  id,
  isActive,
  editRoute,
  onActiveHandler,
  isDraft = false,
  showView = false,
  viewText = VIEW_TEXT,
  editText = EDIT_TEXT,
  viewRoute = FORMULA_DETAIL_ROUTE,
  showActive = true,
  onDelete,
  onEdit,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editHandler = () => {
    if (onEdit) {
      handleClose();
      onEdit && onEdit();
    } else {
      navigate(`${editRoute ?? ''}/${id}`);
    }
  };

  const ActiveTab: FC = () => {
    return showActive ? (
      <MenuItem
        onClick={() => {
          onActiveHandler && onActiveHandler(id ?? '', isActive ?? false);
        }}>
        {isActive ? DEACTIVATE_TEXT : ACTIVATE_TEXT}
      </MenuItem>
    ) : (
      <Fragment />
    );
  };

  const MenuItems: FC = (): JSX.Element => {
    if (showView) {
      if (isDraft) {
        return <MenuItem onClick={editHandler}>{editText}</MenuItem>;
      }
      return (
        <Fragment>
          <MenuItem onClick={() => navigate(`${viewRoute}/${id}`)}>{viewText}</MenuItem>
          <MenuItem onClick={editHandler}>{editText}</MenuItem>
          <ActiveTab />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <MenuItem onClick={editHandler}>{editText}</MenuItem>
        <ActiveTab />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <IconButton
        id="basic-button"
        aria-haspopup="true"
        onClick={handleClick}
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'basic-menu' : undefined}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        open={open}
        id="basic-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuItems />
        {onDelete ? <MenuItem onClick={() => onDelete(id)}>{REMOVE_TEXT}</MenuItem> : <Fragment />}
      </Menu>
    </Fragment>
  );
};

export default ActionMenuDropdown;
