import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
//components
import { Alert } from 'components/common/Alert';
import InputController from 'controller/InputController';
//constants, interfaces, schemas, graphql
import {
  BACK_TEXT,
  AUTH_LINKS,
  HTTP_STATUS,
  EMAIL_ADDRESS_TEXT,
  FORGOT_PASSWORD_TEXT,
} from 'constants/index';
import { ForgotUserInput } from 'interfaces';
import { forgotSchema } from 'validationSchema';
import { useForgotPasswordMutation } from 'generated/graphql';

const ForgotPassword: FC = () => {
  const methods = useForm<ForgotUserInput>({
    mode: 'all',
    resolver: yupResolver(forgotSchema),

    defaultValues: {
      email: '',
    },
  });

  const { handleSubmit } = methods;

  const navigate = useNavigate();

  const [forgotPassword, { loading }] = useForgotPasswordMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onError: ({ message }) => {
      return Alert.error(message);
    },
    onCompleted: (data) => {
      if (data) {
        const {
          forgotPassword: { response },
        } = data;

        if (response) {
          const { status } = response;

          if (status === HTTP_STATUS.NOT_FOUND) {
            navigate(AUTH_LINKS.FORGOT_PASSWORD_LINK);
          }

          if (status === HTTP_STATUS.SUCCESS) {
            navigate(AUTH_LINKS.REQUEST_SENT_SUCCESSFULLY);
          }
        }
      }
    },
  });

  const onSubmit: SubmitHandler<ForgotUserInput> = async (ForgotPasswordInput) => {
    await forgotPassword({
      variables: {
        forgotPasswordInput: ForgotPasswordInput,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputController fieldType={'email'} title={EMAIL_ADDRESS_TEXT} name={'email'} isRequired />

        <Box mt={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={loading}
            endIcon={loading && <CircularProgress size={20} color="inherit" />}>
            {FORGOT_PASSWORD_TEXT}
          </Button>
        </Box>
      </form>

      <Box height={'48px'} lineHeight={'48px'} mt={1} textAlign="center">
        <Typography component={Link} variant="subtitle2" to={AUTH_LINKS.LOGIN_LINK}>
          {BACK_TEXT}
        </Typography>
      </Box>
    </FormProvider>
  );
};

export default ForgotPassword;
