import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { FC, Fragment, useContext, useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
// components
import { ArrowIcon } from 'assets/svgs';
import StepForm from '../Steps/StepForm';
import { Alert } from 'components/common/Alert';
import DialogBox from 'components/common/DialogBox';
import { BackdropLoader } from 'components/common/BackdropLoader';
import SaveAsDraftButton from 'components/common/SaveAsDraftButton';
import SaveAsDraftDialogBox from 'components/common/SaveAsDraftDialogBox';
// reducer, actions, graphql, constants, schema, context, helper, hooks
import {
  BACK_TEXT,
  SAVE_TEXT,
  HTTP_STATUS,
  INGREDIENTS_TEXT,
  EDIT_FORMULA_TEXT,
  FORMULA_FETCH_TEXT,
  FORMULA_IS_UPDATING_TEXT,
} from 'constants/index';
import {
  FormulaBlendInput,
  BottleCapType,
  FactBoxType,
  MiniTabFormulationType,
  ProteinBaseType,
  SweetenerNatureType,
  TabletCoatingColor,
  VeganType,
  useUpdateFormulaMutation,
  useUpdateDraftedFormulaMutation,
} from 'generated/graphql';
import {
  EditFormulaFormProps,
  DosageFormulationFormType,
  AddNewFormulaFormType,
  ParamType,
  SaveFormulaAsDraftFormType,
} from 'interfaces';
import useCustomBlocker from 'hooks/useCustomBlocker';
import { ActionType } from 'reducer/addFormulaReducer';
import { FormulaContext } from 'contexts/FormulaContext';
import { formatCalcFormulaIngredients, formatCalcFormulaIngredientsWithOptionalType } from 'lib/helper';
import { flexCenterBetween } from 'styles/commonComponentStyle';

const EditFormulaForm: FC<EditFormulaFormProps> = ({ methods, formulaMethods, loading: getLoading }) => {
  const { id: formulaId } = useParams<ParamType>();

  const navigate = useNavigate();
  const { state, dispatch } = useContext(FormulaContext);
  const { activeStep, formulation, ingredientsBlend } = state || {};

  // local state
  const [isBlocker, setIsBlocker] = useState(true);
  const [autoSaveFormula, setAutoSaveFormula] = useState(false);
  const [isOpenDraft, setIsOpenDraft] = useState(false);

  const {
    getValues: getFormulaValues,
    formState: formulaFormState,
    handleSubmit: handleFormulaSubmit,
  } = formulaMethods;

  const { name: formulaNameInForm } = getFormulaValues();

  const { isDirty: isFormulaFormDirty } = formulaFormState;

  const { handleSubmit, formState, getValues } = methods;
  const { isDirty } = formState;

  const blocker = useCustomBlocker(isDirty, isFormulaFormDirty, isBlocker);

  const [updateFormula, { loading: updateLoading }] = useUpdateFormulaMutation({
    onCompleted: (res) => {
      const { updateFormula } = res || {};
      const { response } = updateFormula || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        if (isOpenDraft) {
          setIsOpenDraft(false);
          navigate(-1);
        }
        if (autoSaveFormula) {
          blocker?.proceed && blocker?.proceed();
        } else {
          navigate(-1);
        }
      } else {
        Alert.warning(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updateDraftedFormula, { loading: updateDraftLoading }] = useUpdateDraftedFormulaMutation({
    onCompleted: (res) => {
      const { updateDraftedFormula } = res || {};
      const { response } = updateDraftedFormula || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigate(-1);
      } else {
        Alert.warning(message || '');
      }
    },
    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  // setting payload for formulaBlends
  const formulaBlends = ingredientsBlend?.map<FormulaBlendInput>((item) => {
    const { name: blendName, ingredients } = item;

    const ingredientBlends = formatCalcFormulaIngredients(ingredients);

    return {
      name: blendName,
      formulaIngredients: ingredientBlends,
    };
  });

  const onSave: SubmitHandler<AddNewFormulaFormType> = async (data) => {
    const { formulaIngredients, name } = data;
    const formatFormulaIngredients = formatCalcFormulaIngredients(formulaIngredients);
    const {
      beadlets,
      bottleCap,
      capsuleType,
      coatingColor,
      coatingColor2,
      coatingColor3,
      colorsHex,
      desiredServingSize,
      excipientType,
      factBoxType,
      flavorSystem,
      flavorType,
      innerCapsuleType,
      miniTabFormulation,
      outerCapsuleType,
      productFormulationType,
      productSubType,
      productType,
      proteinBase,
      servingPerContainer,
      sweetenerSystem,
      sweetenerType,
      tabletCoating,
      tabletType,
      veganType,
      servingUnit,
      productSize,
      sandCoating,
    } = formulation;
    setIsBlocker(false);

    await updateFormula({
      variables: {
        updateFormulaInput: {
          id: formulaId ?? '',
          name,
          servingUnit,
          productTypeId: productType,
          bottleCap: bottleCap as BottleCapType,
          servingSize: Number(desiredServingSize),
          servingContainer: Number(servingPerContainer),
          productFormulationTypeId: productFormulationType?.value ?? '',

          ...(productSize?.value && { productSizeId: productSize?.value }),
          ...(ingredientsBlend?.length && { formulaBlends: formulaBlends }),
          ...(formulaIngredients?.length && { formulaIngredients: formatFormulaIngredients }),

          ...(colorsHex?.length && { colorsHex: colorsHex }),
          ...(beadlets && { beadletId: beadlets }),
          ...(productSubType && { subProductTypeId: productSubType }),
          ...(tabletType && { tabletTypeId: tabletType }),
          ...(excipientType?.value && { excipientTypeId: excipientType?.value }),

          ...(veganType && { veganType: veganType as VeganType }),
          ...(factBoxType && { factBoxType: factBoxType as FactBoxType }),
          ...(proteinBase && { proteinBase: proteinBase as ProteinBaseType }),
          ...(miniTabFormulation && { miniTabFormulation: miniTabFormulation as MiniTabFormulationType }),

          ...(flavorSystem?.value && { flavorSystemId: flavorSystem?.value }),
          ...(flavorType && { flavorType: flavorType as SweetenerNatureType }),

          ...(sweetenerSystem?.value && { sweetenerSystemId: sweetenerSystem?.value }),
          ...(sweetenerType && { sweetenerType: sweetenerType as SweetenerNatureType }),

          ...(coatingColor && { colorCoating: coatingColor as TabletCoatingColor }),
          ...(coatingColor2 && { colorCoating2: coatingColor2 as TabletCoatingColor }),
          ...(coatingColor3 && { colorCoating3: coatingColor3 as TabletCoatingColor }),

          ...(sandCoating && { sandCoatingId: sandCoating }),
          ...(tabletCoating && { tabletCoatingId: tabletCoating }),

          ...(capsuleType && { capsuleTypeId: capsuleType }),
          ...(innerCapsuleType && { innerCapsuleTypeId: innerCapsuleType }),
          ...(outerCapsuleType && { outerCapsuleTypeId: outerCapsuleType }),
        },
      },
    });
  };

  const onSaveAsDraft = () => {
    setIsOpenDraft(true);
    setIsBlocker(false);
  };

  const onSubmit: SubmitHandler<DosageFormulationFormType> = (data) => {
    dispatch({ type: ActionType.SET_FORMULATION, formulation: data });
    dispatch({ type: ActionType.SET_ACTIVE_STEP, activeStep: activeStep + 1 });
  };

  const saveFormulaAsDraftAndContinue: SubmitHandler<SaveFormulaAsDraftFormType> = async (data) => {
    setAutoSaveFormula(true);
    const { name: formulaName } = data;
    const {
      beadlets,
      bottleCap,
      capsuleType,
      coatingColor,
      coatingColor2,
      coatingColor3,
      colorsHex,
      desiredServingSize,
      excipientType,
      factBoxType,
      flavorSystem,
      flavorType,
      innerCapsuleType,
      miniTabFormulation,
      outerCapsuleType,
      productFormulationType,
      proteinBase,
      servingPerContainer,
      servingUnit,
      sweetenerSystem,
      sweetenerType,
      tabletCoating,
      tabletType,
      veganType,
      productType,
      productSubType,
      productSize,
      sandCoating,
    } = getValues();

    const { formulaIngredients } = getFormulaValues();

    const formatFormulaIngredients = formatCalcFormulaIngredientsWithOptionalType(formulaIngredients);

    await updateDraftedFormula({
      variables: {
        updateDraftedFormulaInput: {
          id: formulaId ?? '',
          name: formulaName,
          ...(productSize?.value && { productSizeId: productSize?.value }),
          ...(servingUnit && { servingUnit: servingUnit }),
          ...(productType && { productTypeId: productType }),
          ...(productType && { productTypeId: productType }),
          ...(desiredServingSize && { servingSize: Number(desiredServingSize) }),
          ...(servingPerContainer && { servingContainer: Number(servingPerContainer) }),
          ...(productFormulationType?.value && { productFormulationTypeId: productFormulationType?.value }),
          ...(bottleCap && { bottleCap: bottleCap as BottleCapType }),
          ...(ingredientsBlend?.length && { formulaBlends: formulaBlends }),
          ...(formulaIngredients?.length && { formulaIngredients: formatFormulaIngredients }),

          ...(colorsHex?.length && { colorsHex: colorsHex }),
          ...(beadlets && { beadletId: beadlets }),
          ...(productSubType && { subProductTypeId: productSubType }),
          ...(tabletType && { tabletTypeId: tabletType }),
          ...(excipientType?.value && { excipientTypeId: excipientType?.value }),

          ...(veganType && { veganType: veganType as VeganType }),
          ...(factBoxType && { factBoxType: factBoxType as FactBoxType }),
          ...(proteinBase && { proteinBase: proteinBase as ProteinBaseType }),
          ...(miniTabFormulation && { miniTabFormulation: miniTabFormulation as MiniTabFormulationType }),

          ...(flavorSystem?.value && { flavorSystemId: flavorSystem?.value }),
          ...(flavorType && { flavorType: flavorType as SweetenerNatureType }),

          ...(sweetenerSystem?.value && { sweetenerSystemId: sweetenerSystem?.value }),
          ...(sweetenerType && { sweetenerType: sweetenerType as SweetenerNatureType }),

          ...(coatingColor && { colorCoating: coatingColor as TabletCoatingColor }),
          ...(coatingColor2 && { colorCoating2: coatingColor2 as TabletCoatingColor }),
          ...(coatingColor3 && { colorCoating3: coatingColor3 as TabletCoatingColor }),

          ...(sandCoating && { sandCoatingId: sandCoating }),
          ...(tabletCoating && { tabletCoatingId: tabletCoating }),

          ...(capsuleType && { capsuleTypeId: capsuleType }),
          ...(innerCapsuleType && { innerCapsuleTypeId: innerCapsuleType }),
          ...(outerCapsuleType && { outerCapsuleTypeId: outerCapsuleType }),
        },
      },
    });
  };

  const onClose = () => {
    setIsOpenDraft(false);
    setIsBlocker(true);
  };

  const loading = getLoading || updateLoading || updateDraftLoading;

  return (
    <Fragment>
      <DialogBox
        formulaName={formulaNameInForm}
        cancelNavigation={() => {
          setAutoSaveFormula(false);
          blocker?.reset && blocker?.reset();
        }}
        confirmNavigation={blocker?.proceed}
        showDialog={blocker.state === 'blocked'}
        saveAndLeaveNavigation={saveFormulaAsDraftAndContinue}
      />

      <SaveAsDraftDialogBox
        onClose={onClose}
        open={isOpenDraft}
        formulaName={formulaNameInForm}
        onSave={saveFormulaAsDraftAndContinue}
      />

      <BackdropLoader open={loading} text={getLoading ? FORMULA_FETCH_TEXT : FORMULA_IS_UPDATING_TEXT} />
      <FormProvider {...methods}>
        <Box sx={flexCenterBetween}>
          <Typography variant="h5">{EDIT_FORMULA_TEXT}</Typography>
          {activeStep === 1 ? (
            <Box display="flex" justifyContent="space-between" gap={2}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  dispatch({ type: ActionType.SET_ACTIVE_STEP, activeStep: activeStep - 1 });
                }}>
                {BACK_TEXT}
              </Button>
              <Box>
                <SaveAsDraftButton onClick={onSaveAsDraft} loading={loading} />

                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={handleFormulaSubmit(onSave)}>
                  {SAVE_TEXT}
                </Button>
              </Box>
            </Box>
          ) : (
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowIcon />}
              onClick={handleSubmit(onSubmit)}>
              {`Choose ${INGREDIENTS_TEXT}`}
            </Button>
          )}
        </Box>
        <StepForm formulaMethods={formulaMethods} activeStep={activeStep} />
      </FormProvider>
    </Fragment>
  );
};

export default EditFormulaForm;
