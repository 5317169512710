import { FC, JSX } from 'react';
import { Grid } from '@mui/material';
// components
import InputController from 'controller/InputController';
import SweetenerComponentSelector from 'components/common/selectors/sweetenerComponent';
// constants, styles, interfaces
import { SweetenerElementFormProps } from 'interfaces';
import { forInputLabelBg } from 'styles/commonComponentStyle';
import { QT_MG_TEXT, SWEETENER_COMPONENT_TEXT } from 'constants/index';

const SweetenerElementForm: FC<SweetenerElementFormProps> = ({ loading = false }): JSX.Element => {
  return (
    <Grid container spacing={2} sx={forInputLabelBg}>
      <Grid item xs={12} lg={12}>
        <InputController isRequired fieldType="number" name="qtPerMg" disabled={loading} title={QT_MG_TEXT} />
      </Grid>

      <Grid item xs={12} lg={12}>
        <SweetenerComponentSelector
          isRequired
          title={SWEETENER_COMPONENT_TEXT}
          disabled={loading}
          name="sweetenerComponent"
        />
      </Grid>
    </Grid>
  );
};

export default SweetenerElementForm;
