import { FC, useContext } from 'react';
import { Card, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
// components
import RadioGroupController from 'controller/RadioGroupController';
import IngredientsList from '../../Dosage/Add/components/IngredientsList';
// constants, reducer, context, interfaces
import { ActionType } from 'reducer/addFactBoxReducer';
import { FACT_BOX_MAPPED, FACT_BOX_TEXT } from 'constants/index';
import { AddFactBoxFormulaContext } from 'contexts/AddFactBoxContext';
import { BlendItemType, FactBoxFormulationFormType } from 'interfaces';

const FactBoxStep1: FC = () => {
  const { watch } = useFormContext<FactBoxFormulationFormType>();
  const { state, dispatch } = useContext(AddFactBoxFormulaContext);

  const { ingredientsBlend } = state || {};
  const { formulaIngredients } = watch();

  const onBlendAdd = (data: BlendItemType[]) => {
    dispatch({ type: ActionType.SET_INGREDIENTS_BLEND, ingredientsBlend: data });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={7}>
        <Card sx={{ mb: 2 }}>
          <RadioGroupController
            isRequired
            name="factBoxType"
            title={FACT_BOX_TEXT}
            options={FACT_BOX_MAPPED}
          />
        </Card>

        <IngredientsList
          xl={4}
          isFactBox
          showAddBlendBtn={false}
          onBlendAdd={onBlendAdd}
          ingredientsBlend={ingredientsBlend}
          formulaIngredients={formulaIngredients}
        />
      </Grid>
    </Grid>
  );
};

export default FactBoxStep1;
