import { FC, Fragment, JSX } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Card, CardActions, CardContent, Grid } from '@mui/material';
// components
import SelectController from 'controller/Select';
import InputController from 'controller/InputController';
import ProductSizeSelector from 'components/common/selectors/productSize';
import ProductTypesSelector from 'components/common/selectors/productType';
import ProductFormulationTypeSelector from 'components/common/selectors/productFormulationType';
// constants, styles, interfaces
import { AddSweetenerSystemFormType, SweetenerFormProps } from 'interfaces';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';
import {
  COST_PER_SERVING_TEXT,
  MG_PER_SERVING_TEXT,
  NAME_TEXT,
  NATURE_TEXT,
  PRODUCT_FORMULATION_TEXT,
  PRODUCT_SIZE_TEXT,
  PRODUCT_TYPE_TEXT,
  SAVE_TEXT,
  SIZE_TEXT,
  SWEETENER_NATURE_MAPPED,
  SWEETENER_SIZE_MAPPED,
} from 'constants/index';

const SweetenerForm: FC<SweetenerFormProps> = ({ isEdit = false, loading = false }): JSX.Element => {
  const { watch } = useFormContext<AddSweetenerSystemFormType>();
  const { productType } = watch();
  const { value: productTypeId } = productType || {};
  return (
    <Card sx={forInputLabelBg}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <InputController isRequired fieldType="text" name="name" disabled={loading} title={NAME_TEXT} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <SelectController
              isRequired
              name="nature"
              disabled={loading}
              title={NATURE_TEXT}
              options={SWEETENER_NATURE_MAPPED}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <SelectController
              isRequired
              name="size"
              disabled={loading}
              title={SIZE_TEXT}
              options={SWEETENER_SIZE_MAPPED}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <InputController
              isRequired
              fieldType="number"
              name="mgPerServing"
              disabled={loading}
              title={MG_PER_SERVING_TEXT}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <InputController
              isRequired
              fieldType="number"
              name="costPerServing"
              disabled={loading}
              title={COST_PER_SERVING_TEXT}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ProductTypesSelector isRequired title={PRODUCT_TYPE_TEXT} name="productType" />
          </Grid>
          <Grid item xs={12} xl={12}>
            <ProductSizeSelector
              name="productSize"
              title={PRODUCT_SIZE_TEXT}
              productTypeId={productTypeId}
              // productSubTypeId={productSubType}
              disabled={!productTypeId}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ProductFormulationTypeSelector
              productTypeId={productTypeId}
              title={PRODUCT_FORMULATION_TEXT}
              name="productFormulationType"
            />
          </Grid>
        </Grid>
      </CardContent>

      {isEdit ? (
        <CardActions>
          <Box sx={flexMarginTopEndCenter}>
            <Button type="submit" variant="contained" size="small" disabled={loading}>
              {SAVE_TEXT}
            </Button>
          </Box>
        </CardActions>
      ) : (
        <Fragment />
      )}
    </Card>
  );
};

export default SweetenerForm;
