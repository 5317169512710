import { HttpStatusCode } from 'axios';
import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import BottleCapacityForm from './components/BottleCapacityForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import {
  ADD_TEXT,
  ADD_BOTTLE_CAPACITY_TEXT,
  bottleCapacityInitialValue,
  BOTTLE_CAPACITY_IS_CREATING_TEXT,
} from 'constants/index';
import { AddBottleCapacityFormType } from 'interfaces';
import { createBottleCapacityInput } from 'lib/helper';
import { bottleCapacityTypeSchema } from 'validationSchema';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { useCreateBottleCapacityMutation } from 'generated/graphql';

const Add: FC = (): JSX.Element => {
  const navigation = useNavigate();

  const methods = useForm<AddBottleCapacityFormType>({
    defaultValues: bottleCapacityInitialValue,
    resolver: yupResolver(bottleCapacityTypeSchema),
  });

  const { handleSubmit } = methods;

  const [createBottleCapacity, { loading }] = useCreateBottleCapacityMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createBottleCapacity } = data;
      const { response } = createBottleCapacity || {};
      const { status, message } = response || {};
      if (status === HttpStatusCode.Created) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<AddBottleCapacityFormType> = async (data) => {
    const input = createBottleCapacityInput(data);

    await createBottleCapacity({
      variables: {
        createBottleCapacityInput: input,
      },
    });
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{ADD_BOTTLE_CAPACITY_TEXT}</Typography>
            <Button type="submit" variant="contained" size="small">
              {ADD_TEXT}
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12}>
              <BottleCapacityForm loading={loading} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <BackdropLoader open={loading} text={BOTTLE_CAPACITY_IS_CREATING_TEXT} />
    </Fragment>
  );
};

export default Add;
