import { Palette } from '@mui/material';
import { PRIMARY_FONT, SECONDARY_FONT } from 'constants/fonts';

const typography = (palette: Palette) => ({
  fontFamily: PRIMARY_FONT,

  body1: {
    fontSize: 12,
    fontWeight: 700,
    color: palette.common.white,
  },

  body2: {
    fontSize: 16,
    fontWeight: 500,
  },

  caption: {
    fontSize: 14,
    fontWeight: 400,
  },

  h1: {
    fontSize: 48,
    fontWeight: 700,
    color: palette.common.white,
  },

  h2: {
    fontSize: 24,
    fontWeight: 700,
    color: palette.common.white,
  },

  h3: {
    fontSize: 32,
    fontWeight: 900,
    fontFamily: SECONDARY_FONT,
    color: palette.common.white,
    textTransform: 'uppercase' as const,
  },

  h5: {
    fontSize: 24,
    fontWeight: 700,
    color: palette.common.white,
    fontFamily: SECONDARY_FONT,
  },

  h6: {
    fontSize: 18,
    fontWeight: 700,
    fontFamily: SECONDARY_FONT,
  },

  subtitle1: {
    fontSize: 16,
    fontWeight: 400,
    color: palette.customGrayColor.light,
  },

  subtitle2: {
    fontSize: 14,
    fontWeight: 600,
    color: palette.primary.main,
  },
});

export default typography;
