import { useParams } from 'react-router-dom';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
import { FC, Fragment, JSX, Reducer, useCallback, useEffect, useReducer } from 'react';
import { Box, Button, TableCell, TableRow, Typography } from '@mui/material';
// components
import AddProductExcipient from './Add';
import EditProductExcipient from './Edit';
import { Alert } from 'components/common/Alert';
import NoDataFound from 'components/common/NoDataFound';
import TableLoader from 'components/common/TableLoader';
import EmptyTableTab from 'components/common/EmptyTableTab';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import ExcipientTypeCard from './components/ExcipientTypeCard';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
// constant, styles, interfaces
import {
  HTTP_STATUS,
  ADD_NEW_TEXT,
  LOADING_TABLE_ROWS,
  PRODUCT_EXCIPIENT_TEXT,
  PRODUCT_EXCIPIENT_TABLE_HEADER,
} from 'constants/index';
import {
  ProductExcipient,
  ExcipientTypePayload,
  ProductExcipientsPayload,
  useRemoveProductExcipientMutation,
  useFindAllProductExcipientsLazyQuery,
} from 'generated/graphql';
import {
  State,
  Action,
  ActionType,
  initialState,
  ProductExcipientReducer,
} from 'reducer/productExcipientReducer';
import { ParamType } from 'interfaces';
import { flexCenterBetween, textWhiteSpace } from 'styles/commonComponentStyle';

const ProductExcipientTable: FC = (): JSX.Element => {
  const params = useParams<ParamType>();
  const { excipientTypeId } = params || {};

  const [state, dispatch] = useReducer<Reducer<State, Action>>(ProductExcipientReducer, initialState);
  const { data, addOpen, editOpen, excipientType, productExcipient } = state;

  const [findAllProductExcipients, { loading: fetchLoading, error }] = useFindAllProductExcipientsLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { getExcipientType } = data;
      const { response, excipientType } = getExcipientType || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { productExcipients, ...rest } = excipientType || {};
        dispatch({ type: ActionType.SET_DATA, data: productExcipients as ProductExcipientsPayload['data'] });
        dispatch({
          type: ActionType.SET_EXCIPIENT_TYPE,
          excipientType: rest as ExcipientTypePayload['excipientType'],
        });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const [removeProductExcipient, { loading: delLoading }] = useRemoveProductExcipientMutation({
    onCompleted: (data) => {
      const { removeProductExcipient } = data;
      const { response } = removeProductExcipient || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message ?? '');
        fetchProductExcipients();
      } else {
        Alert.error(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onDelete = async (delId: string) => {
    try {
      await removeProductExcipient({
        variables: {
          removeProductExcipientInput: {
            id: delId,
          },
        },
      });
    } catch (error) {}
  };

  const resetPage = () => {
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchProductExcipients = useCallback(async () => {
    if (excipientTypeId) {
      await findAllProductExcipients({
        variables: {
          getExcipientTypeInput: {
            id: excipientTypeId,
          },
        },
      });
    }
  }, [findAllProductExcipients, excipientTypeId]);

  useEffect(() => {
    fetchProductExcipients();
  }, [fetchProductExcipients]);

  const loading = fetchLoading || delLoading;

  const noData = Boolean((!fetchLoading && data?.length === 0) || error);

  const onAddOpen = () => {
    dispatch({ type: ActionType.SET_ADD_OPEN, addOpen: true });
  };

  const onAddClose = () => {
    dispatch({ type: ActionType.SET_ADD_OPEN, addOpen: false });
  };

  const onEditOpen = (item: ProductExcipient) => {
    dispatch({ type: ActionType.SET_PRODUCT_EXCIPIENT, productExcipient: item });
    dispatch({ type: ActionType.SET_EDIT_OPEN, editOpen: true });
  };

  const onEditClose = () => {
    dispatch({ type: ActionType.SET_EDIT_OPEN, editOpen: false });
    dispatch({ type: ActionType.SET_PRODUCT_EXCIPIENT, productExcipient: null });
  };

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{PRODUCT_EXCIPIENT_TEXT}</Typography>
        <Button variant="contained" color="primary" onClick={onAddOpen}>
          <AddRoundedIcon />
          {ADD_NEW_TEXT}
        </Button>
      </Box>

      <TableContainer>
        <EmptyTableTab />

        <ExcipientTypeCard excipientType={excipientType} loading={loading} />

        <TableComponent noData tableHeader={PRODUCT_EXCIPIENT_TABLE_HEADER}>
          {loading ? (
            <TableLoader columns={5} rows={LOADING_TABLE_ROWS} />
          ) : (
            <Fragment>
              {data?.map((cell) => {
                const { id, perKgCost, percentage, excipient, productExcipientSupplier } = cell || {};
                const { name: excipientName } = excipient || {};
                // create a comma separated string of suppliers
                const suppliers = productExcipientSupplier
                  ?.map((supplier) => supplier?.supplier?.name)
                  .join(', ');

                return (
                  <TableRow key={id}>
                    <TableCell sx={textWhiteSpace}>{excipientName ?? '--'}</TableCell>
                    <TableCell sx={textWhiteSpace}>{perKgCost ? `$${perKgCost}` : '--'}</TableCell>
                    <TableCell sx={textWhiteSpace}>{percentage ?? '--'}</TableCell>
                    <TableCell sx={textWhiteSpace}>{suppliers ?? '--'}</TableCell>

                    <TableCell>
                      <ActionMenuDropdown
                        id={id ?? ''}
                        showActive={false}
                        onDelete={onDelete}
                        onEdit={() => onEditOpen(cell)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </Fragment>
          )}
        </TableComponent>

        <NoDataFound noData={noData} />
      </TableContainer>

      <AddProductExcipient
        open={addOpen}
        onClose={onAddClose}
        excipientType={excipientType}
        fetch={fetchProductExcipients}
      />

      <EditProductExcipient
        open={editOpen}
        onClose={onEditClose}
        excipientType={excipientType}
        fetch={fetchProductExcipients}
        productExcipient={productExcipient}
      />
    </Fragment>
  );
};

export default ProductExcipientTable;
