import { Box, Button, Card, Grid } from '@mui/material';
import { FC } from 'react';
// component
import DataLoader from 'components/common/DataLoader';
import InputController from 'controller/InputController';
// styles, interface, constants
import { HeavyMetalsCardProps } from 'interfaces';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';
import { ARSENIC_TEXT, CADMIUM_TEXT, LEAD_TEXT, MERCURY_TEXT, SAVE_TEXT } from 'constants/index';

const HeavyMetalsCard: FC<HeavyMetalsCardProps> = ({ loading, isEdit }) => {
  return (
    <Card sx={forInputLabelBg}>
      {loading ? (
        <DataLoader columns={6} rows={21} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <InputController name="arsenic" fieldType="text" title={ARSENIC_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="cadmium" fieldType="text" title={CADMIUM_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="lead" fieldType="text" title={LEAD_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="mercury" fieldType="text" title={MERCURY_TEXT} />
          </Grid>
        </Grid>
      )}

      {isEdit ? (
        <Box sx={flexMarginTopEndCenter}>
          <Button type="submit" variant="contained" id="heavy-metal-btn" size="small" disabled={loading}>
            {SAVE_TEXT}
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default HeavyMetalsCard;
