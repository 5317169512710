import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useCallback, useContext, useEffect } from 'react';
// component
import EditFormulaForm from './EditFormulaForm';
// interfaces, graphql, helper, reducer, context, schema
import {
  SelectType,
  ProductTypeSelect,
  IngredientItemType,
  AddNewFormulaFormType,
  SetEditFormulaFormProps,
  DosageFormulationFormType,
} from 'interfaces';
import {
  SweetenerType,
  ServingUnitType,
  TabletCoatingType,
  FormulaIngredient,
  FormulaCapsuleTypeEnum,
  ProductFormulationTypes,
} from 'generated/graphql';
import { formatFormulaIngredients } from 'lib/helper';
import { ActionType } from 'reducer/addFormulaReducer';
import { FormulaContext } from 'contexts/FormulaContext';
import { addFormulaSchema, formulationSchema } from 'validationSchema';
import { addNewFormulaInitialValue, dosageFormulationFormInitialValues } from 'constants/index';

const SetEditFormulaFormValues: FC<SetEditFormulaFormProps> = ({ formula, loading }) => {
  const { state, dispatch } = useContext(FormulaContext);
  const { productFormulationType } = state || {};
  const { type } = productFormulationType || {};

  const methods = useForm<DosageFormulationFormType>({
    resolver: yupResolver(formulationSchema(type as ProductFormulationTypes)),
    defaultValues: dosageFormulationFormInitialValues,
  });

  const { setValue } = methods;

  const formulaMethods = useForm<AddNewFormulaFormType>({
    resolver: yupResolver(addFormulaSchema),
    defaultValues: addNewFormulaInitialValue,
  });

  const { setValue: setFormulaValues } = formulaMethods;

  const setValues = useCallback(() => {
    const {
      name: formulaName,
      beadletId,
      tabletTypeId,
      productType,
      productFormulationType,
      subProductType,
      servingContainer,
      servingSize,
      bottleCap,
      proteinBase,
      excipientType,
      veganType,
      factBoxType,
      miniTabFormulation,
      formulaBlends,
      formulaIngredients,
      tabletCoatingId,
      formulaCapsuleTypes,
      formulaTabletCoatings,
      formulaSweeteners,
      servingUnit,
      sandCoatingId,
      productSize,
    } = formula || {};

    // setting formula step1 values

    const { id, name, type } = productType || {};

    const productTypeObj: ProductTypeSelect = {
      value: id ?? '',
      name: name ?? '',
      type: type ?? '',
    };

    const {
      id: productFormulationTypeId,
      name: productFormulationTypeName,
      type: productFormulationTypeType,
    } = productFormulationType || {};

    const productFormulationTypeObj: ProductTypeSelect = {
      name: productFormulationTypeName ?? '',
      value: productFormulationTypeId ?? '',
      type: productFormulationTypeType ?? '',
    };

    const subProductTypeObj: SelectType = {
      value: subProductType?.id ?? '',
      name: subProductType?.name ?? '',
    };

    if (productType) {
      setValue('productType', productType?.id ?? '');
      dispatch({ type: ActionType.SET_PRODUCT_TYPE, productType: productTypeObj });
    }

    if (productFormulationType) {
      setValue('productFormulationType', productFormulationTypeObj);

      dispatch({
        type: ActionType.SET_PRODUCT_FORMULATION_TYPE,
        productFormulationType: productFormulationTypeObj,
      });
    }

    if (subProductType?.id) {
      setValue('productSubType', subProductType?.id ?? '');
      dispatch({
        type: ActionType.SET_SUB_PRODUCT_TYPE,
        subProductType: subProductTypeObj,
      });
    }

    if (productSize) {
      const { id, name } = productSize || {};
      setValue('productSize', { name: name || '', value: id });
    }

    setValue('servingPerContainer', `${servingContainer}` ?? '');
    setValue('desiredServingSize', `${servingSize}` ?? '');
    setValue('bottleCap', bottleCap ?? '');
    setValue('servingUnit', servingUnit ?? ServingUnitType.Capsule);

    const { excipientLabel } = excipientType || {};
    const { id: excipientLabelId } = excipientLabel || {};

    const excipientTypeObj: SelectType = {
      value: excipientType?.id ?? '',
      name: excipientType?.name ?? '',
    };

    setValue('excipientType', excipientTypeObj);
    setValue('excipientLabel', excipientLabelId ?? '');
    setValue('beadlets', beadletId ?? '');
    setValue('tabletType', tabletTypeId ?? '');
    setValue('factBoxType', factBoxType ?? '');
    setValue('proteinBase', proteinBase ?? '');
    setValue('veganType', veganType ?? '');
    setValue('miniTabFormulation', miniTabFormulation ?? '');

    const polymer = formulaCapsuleTypes?.find((item) => {
      return item?.type === FormulaCapsuleTypeEnum.Polymer;
    });

    if (polymer) {
      const { capsuleType } = polymer || {};
      const { id } = capsuleType || {};
      setValue('capsuleType', id ?? '');
    }

    const innerPolymer = formulaCapsuleTypes?.find((item) => {
      return item?.type === FormulaCapsuleTypeEnum.InnerPolymer;
    });

    if (innerPolymer) {
      const { capsuleType } = innerPolymer || {};
      const { id } = capsuleType || {};
      setValue('innerCapsuleType', id ?? '');
    }

    const outerPolymer = formulaCapsuleTypes?.find((item) => {
      return item?.type === FormulaCapsuleTypeEnum.OuterPolymer;
    });

    if (outerPolymer) {
      const { capsuleType } = outerPolymer || {};
      const { id } = capsuleType || {};

      setValue('outerCapsuleType', id ?? '');
    }

    setValue('tabletCoating', tabletCoatingId ?? '');
    setValue('sandCoating', sandCoatingId ?? '');

    const singleLayer = formulaTabletCoatings?.find((item) => {
      return item?.type === TabletCoatingType.SingleLayered;
    });

    if (singleLayer) {
      const { coatingColor, colorsHex } = singleLayer || {};
      setValue('coatingColor', coatingColor ?? '');
      setValue('colorsHex', colorsHex ?? []);
    }

    const biLayer = formulaTabletCoatings?.find((item) => {
      return item?.type === TabletCoatingType.BiLayered;
    });

    if (biLayer) {
      setValue('coatingColor2', biLayer?.coatingColor ?? '');
    }

    const triLayer = formulaTabletCoatings?.find((item) => {
      return item?.type === TabletCoatingType.TriLayered;
    });

    if (triLayer) {
      setValue('coatingColor3', triLayer?.coatingColor ?? '');
    }

    const flavorType = formulaSweeteners?.find((item) => {
      return item?.type === SweetenerType.Flavor;
    });

    if (flavorType) {
      const { sweetener, nature } = flavorType || {};
      const { id, name } = sweetener || {};
      const flavorSystemObj: SelectType = {
        value: id ?? '',
        name: name ?? '',
      };

      setValue('flavorType', nature ?? '');
      setValue('flavorSystem', flavorSystemObj);
    }

    const sweetenerType = formulaSweeteners?.find((item) => {
      return item?.type === SweetenerType.Sweetener;
    });

    if (sweetenerType) {
      const { sweetener, nature } = sweetenerType || {};
      const { id, name } = sweetener || {};
      const sweetenerSystemObj: SelectType = {
        value: id ?? '',
        name: name ?? '',
      };

      setValue('sweetenerType', nature ?? '');
      setValue('sweetenerSystem', sweetenerSystemObj);
    }

    // setting formula step2 values

    const formulaIngredient = formatFormulaIngredients(formulaIngredients as FormulaIngredient[]);

    const formulaBlend =
      formulaBlends?.map((item) => {
        const { name, formulaIngredients } = item || {};

        const ingredient = formatFormulaIngredients(formulaIngredients as FormulaIngredient[]);

        return {
          name: name ?? '',
          ingredients: (ingredient as IngredientItemType[]) ?? [],
        };
      }) || [];

    setFormulaValues('name', formulaName ?? '');
    setFormulaValues('formulaIngredients', formulaIngredient);
    if (formulaBlend) {
      dispatch({ type: ActionType.SET_INGREDIENTS_BLEND, ingredientsBlend: formulaBlend ?? [] });
    }
  }, [dispatch, formula, setFormulaValues, setValue]);

  useEffect(() => {
    formula && setValues();
  }, [setValues, formula]);

  return <EditFormulaForm methods={methods} formulaMethods={formulaMethods} loading={loading} />;
};

export default SetEditFormulaFormValues;
