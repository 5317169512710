import { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
// components
import BCAA from './components/Bcaa';
import Protein from './components/Protein';
import PrePost from './components/PrePost';
import AllOthers from './components/AllOthers';
import RadioGroupController from 'controller/RadioGroupController';
import CommonSweetenerAndFlavorRadioGroup from 'components/common/CommonSweetenerAndFlavorRadioGroup';
// constants, interface, context, graphql
import { ProductFormulationTypes } from 'generated/graphql';
import { FACT_BOX_TEXT, FACT_BOX_MAPPED } from 'constants/index';
import { DosageFormulationFormType, PowderFormProps } from 'interfaces';

const PowderForm: FC<PowderFormProps> = ({ isFactBox = false }) => {
  const { watch } = useFormContext<DosageFormulationFormType>();
  const { productFormulationType } = watch();

  const { type } = productFormulationType || {};

  const getForm = () => {
    switch (type) {
      case ProductFormulationTypes.ProteinPowder:
        return <Protein />;

      case ProductFormulationTypes.PreOrPostPowder:
        return <PrePost />;

      case ProductFormulationTypes.HydrationPowder:
        return <Fragment />;

      case ProductFormulationTypes.GreensOrRedsPowder:
        return <Fragment />;

      case ProductFormulationTypes.BcaaPowder:
        return <BCAA />;

      case ProductFormulationTypes.AllOthers:
        return <AllOthers />;

      default:
        return <Fragment />;
    }
  };

  return (
    <Fragment>
      {isFactBox ? (
        <Fragment />
      ) : (
        <Grid item xs={12} xl={12}>
          <RadioGroupController
            isRequired
            name="factBoxType"
            title={FACT_BOX_TEXT}
            options={FACT_BOX_MAPPED}
          />
        </Grid>
      )}

      {getForm()}

      <CommonSweetenerAndFlavorRadioGroup />
    </Fragment>
  );
};
export default PowderForm;
