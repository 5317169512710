import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import DataLoader from 'components/common/DataLoader';
import SweetenerForm from './components/SweetenerSystemForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  SweetenerNatureType,
  SweetenerPayload,
  SweetenerSizeType,
  SweetenerType,
  useCreateSweetenerMutation,
  useGetSweetenerLazyQuery,
  useUpdateSweetenerMutation,
} from 'generated/graphql';
import {
  HTTP_STATUS,
  sweetenerSystemInitialValue,
  EDIT_SWEETENER_TEXT,
  SWEETENER_IS_UPDATED_TEXT,
  ADD_SWEETENER_TEXT,
  ADD_TEXT,
  SWEETENER_IS_CREATED_TEXT,
} from 'constants/index';
import { AddSweetenerSystemFormType, ParamType } from 'interfaces';
import { updateSweetenerSystemTypeSchema } from 'validationSchema';

const Edit: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const sweetenerMethods = useForm<AddSweetenerSystemFormType>({
    defaultValues: sweetenerSystemInitialValue,
    resolver: yupResolver(updateSweetenerSystemTypeSchema),
  });

  const { handleSubmit, setValue: setSweetenerValue } = sweetenerMethods;

  const [createSweetener, { loading: CreateLoading }] = useCreateSweetenerMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createSweetener } = data;
      const { response } = createSweetener || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updateSweetener, { loading: UpdateLoading }] = useUpdateSweetenerMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateSweetener } = data;
      const { response } = updateSweetener || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getSweetener, { loading: getLoading }] = useGetSweetenerLazyQuery({
    onCompleted: (data) => {
      const { getSweetener } = data || {};
      const { response, sweetener } = getSweetener || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(sweetener as SweetenerPayload['sweetener']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });

  const onSweetenerSubmit: SubmitHandler<AddSweetenerSystemFormType> = async (data) => {
    const {
      mgPerServing,
      costPerServing,
      name,
      nature,
      productFormulationType,
      size,
      productSize,
      productType,
    } = data || {};
    const { value: productSizeId } = productSize || {};
    const { value: productTypeId } = productType || {};
    const { value: productFormulationId } = productFormulationType || {};
    if (id) {
      await updateSweetener({
        variables: {
          updateSweetenerInput: {
            id: id || '',
            name: name || '',
            size: size as SweetenerSizeType,
            nature: nature as SweetenerNatureType,
            type: SweetenerType.Sweetener,
            costPerServing: parseFloat(costPerServing) || 0,
            mgPerServing: Number(mgPerServing),
            productTypeId: productTypeId || '',
            ...(productSizeId && { productSizeId: productSizeId }),
            ...(productFormulationId && { productFormulationTypeId: productFormulationId }),
          },
        },
      });
    } else {
      await createSweetener({
        variables: {
          createSweetenerInput: {
            name: name || '',
            size: size as SweetenerSizeType,
            nature: nature as SweetenerNatureType,
            type: SweetenerType.Sweetener,
            costPerServing: parseFloat(costPerServing) || 0,
            mgPerServing: Number(mgPerServing),
            productTypeId: productTypeId || '',
            ...(productSizeId && { productSizeId: productSizeId }),
            ...(productFormulationId && { productFormulationTypeId: productFormulationId }),
          },
        },
      });
    }
  };

  const fetchSweetener = useCallback(async () => {
    id &&
      (await getSweetener({
        variables: {
          getSweetenerInput: {
            id,
          },
        },
      }));
  }, [id, getSweetener]);

  const setValues = (params: SweetenerPayload['sweetener']) => {
    const {
      costPerServing,
      name,
      mgPerServing,
      nature,
      size,
      type,
      productType,
      productSize,
      productFormulationType,
    } = params || {};
    const { name: productFormulationTypeName, id } = productFormulationType || {};
    const { name: productTypeName, id: productTypeId } = productType || {};
    const { name: productSizeName, id: productSizeId } = productSize || {};
    setSweetenerValue('name', name || '');
    setSweetenerValue('nature', nature || '');
    setSweetenerValue('size', size || '');
    setSweetenerValue('type', type || '');
    setSweetenerValue('costPerServing', `${costPerServing ?? 0}`);
    setSweetenerValue('mgPerServing', `${mgPerServing ?? 0}`);
    setSweetenerValue('productFormulationType', {
      value: id ?? '',
      name: productFormulationTypeName ?? '',
    });
    setSweetenerValue('productSize', {
      value: productSizeId ?? '',
      name: productSizeName ?? '',
    });
    setSweetenerValue('productType', {
      value: productTypeId ?? '',
      name: productTypeName ?? '',
    });
  };

  useEffect(() => {
    id && fetchSweetener();
  }, [id, fetchSweetener]);

  const loading = UpdateLoading || CreateLoading;

  return (
    <Fragment>
      <FormProvider {...sweetenerMethods}>
        <form onSubmit={handleSubmit(onSweetenerSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{id ? EDIT_SWEETENER_TEXT : ADD_SWEETENER_TEXT}</Typography>
            {!id && (
              <Button variant="contained" type="submit">
                {ADD_TEXT}
              </Button>
            )}
          </Box>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12}>
              {getLoading ? (
                <DataLoader columns={12} rows={6} />
              ) : (
                <SweetenerForm loading={loading} isEdit={!!id} />
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <BackdropLoader open={loading} text={id ? SWEETENER_IS_UPDATED_TEXT : SWEETENER_IS_CREATED_TEXT} />
    </Fragment>
  );
};

export default Edit;
