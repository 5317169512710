import { createTheme } from '@mui/material';
// packages import
import palette from 'theme/palette';
import typography from 'theme/typography';
import components from 'theme/components';

const customTheme = createTheme({
  palette,
  typography,
  components,
  breakpoints: {
    values: {
      xs: 0,
      sm: 479,
      md: 767,
      lg: 1023,
      xl: 1279,
    },
  },
});

export default customTheme;
