import { FC } from 'react';
//interfaces
import { TabPanelProps } from 'interfaces';

const TabPanel: FC<TabPanelProps> = (props): JSX.Element => {
  const { children, value, activeTab } = props;

  return (
    <div role="tabpanel" hidden={value !== activeTab}>
      {value === activeTab && <>{children}</>}
    </div>
  );
};

export default TabPanel;
