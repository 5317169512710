import { FC } from 'react';
import { Box, Button, Card, Grid } from '@mui/material';
// components
import DataLoader from 'components/common/DataLoader';
import CountrySelector from 'components/common/selectors/country';
// interfaces, styles, constants
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';
import { IngredientManufacturingInfoCardProps } from 'interfaces';
import { COUNTRY_OF_MANUFACTURING_TEXT, COUNTRY_OF_ORIGIN_TEXT, SAVE_TEXT } from 'constants/index';

const IngredientManufacturingInfoCard: FC<IngredientManufacturingInfoCardProps> = ({ loading, isEdit }) => {
  return (
    <Card sx={forInputLabelBg}>
      {loading ? (
        <DataLoader columns={6} rows={1} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <CountrySelector
              disabled={loading}
              name="manufacturingCountry"
              title={COUNTRY_OF_MANUFACTURING_TEXT}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <CountrySelector disabled={loading} name="originCountry" title={COUNTRY_OF_ORIGIN_TEXT} />
          </Grid>
        </Grid>
      )}

      {isEdit ? (
        <Box sx={flexMarginTopEndCenter}>
          <Button type="submit" variant="contained" id="manufacture-info-btn" size="small" disabled={loading}>
            {SAVE_TEXT}
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default IngredientManufacturingInfoCard;
