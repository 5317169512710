import { FC, Fragment, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LogoutRounded as LogoutRoundedIcon, Menu as MenuIcon } from '@mui/icons-material';
import { Box, AppBar, Hidden, Button, Drawer, Toolbar, IconButton, CssBaseline } from '@mui/material';
//components
import DemoAlert from './DemoAlert';
import { LogoIcon } from 'assets/svgs';
import Sidebar from 'components/Layouts/Sidebar';
//constants, theme, context, helpers, styles
import {
  navComponent,
  drawerPermanent,
  drawerOpenButton,
  appBarFixedStyles,
  drawerKeepMounted,
  toolbarIconButton,
  drawerContentBoxStyled,
} from 'styles/DrawerSidebarStyle';
import { MainLayoutProps } from 'interfaces';
import { AuthContext } from 'contexts/AuthContext';
import { DrawerContentBox } from 'theme/styleComponent';
import { cursorPointer } from 'styles/commonComponentStyle';
import { DrawerContext, DrawerContextProvider } from 'contexts/DrawerContext';
import { TOKEN, AUTH_LINKS, LOG_OUT_BUTTON_TEXT, DASHBOARD_ROUTE } from 'constants/index';

const CustomDrawer: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { setUser, setIsLoggedIn } = useContext(AuthContext);

  const logoutHandler = () => {
    setUser(null);
    setIsLoggedIn(false);
    localStorage.removeItem(TOKEN);
  };

  return (
    <Fragment>
      <Toolbar sx={{ paddingY: 4, paddingX: '40px !important' }}>
        <Box onClick={() => navigate(DASHBOARD_ROUTE)}>
          <LogoIcon />
        </Box>
      </Toolbar>

      <Box paddingX="20px">
        <Box minHeight="calc(100vh - 232px)">
          <Sidebar />
        </Box>

        <Box paddingX={{ md: 1, xs: 1.375 }}>
          <Link to={AUTH_LINKS.LOGIN_LINK} onClick={logoutHandler}>
            <Button variant="contained" color="secondary" fullWidth startIcon={<LogoutRoundedIcon />}>
              {LOG_OUT_BUTTON_TEXT}
            </Button>
          </Link>
        </Box>
      </Box>
    </Fragment>
  );
};

const Layout: FC<MainLayoutProps> = (props) => {
  const navigate = useNavigate();
  const { window, children } = props;
  const { mobileOpen, setMobileOpen } = useContext(DrawerContext);

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box display="flex">
      <CssBaseline />
      <AppBar position="fixed" sx={appBarFixedStyles}>
        <Toolbar sx={toolbarIconButton}>
          <Hidden only={['lg', 'xl']}>
            <Box flex="1" sx={cursorPointer} onClick={() => navigate(DASHBOARD_ROUTE)}>
              <LogoIcon height={50} width={125} />
            </Box>
          </Hidden>

          <IconButton
            edge="start"
            id="menu-icon-btn-id"
            onClick={() => setMobileOpen(true)}
            sx={drawerOpenButton}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box component="nav" sx={navComponent}>
        <Hidden only={['lg', 'xl']}>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={() => setMobileOpen(false)}
            sx={drawerKeepMounted}>
            <CustomDrawer />
          </Drawer>
        </Hidden>

        <Hidden only={['xs', 'sm', 'md']}>
          <Drawer variant="permanent" sx={drawerPermanent} open>
            <CustomDrawer />
          </Drawer>
        </Hidden>
      </Box>
      <DrawerContentBox component="main" sx={drawerContentBoxStyled}>
        <DemoAlert />
        {children}
      </DrawerContentBox>
    </Box>
  );
};

const MainLayout: FC<MainLayoutProps> = (props) => {
  return (
    <DrawerContextProvider>
      <Layout {...props} />
    </DrawerContextProvider>
  );
};

export default MainLayout;
