import { FC } from 'react';
// components
import { StyledTab, StyledTabs } from 'theme/styleComponent';
// interfaces
import { TableTabsComponentProps } from 'interfaces';

const TableTabsComponent: FC<TableTabsComponentProps> = ({ activeTab, tabHandler, tabsList }) => {
  return (
    <StyledTabs value={activeTab} onChange={tabHandler} textColor="inherit" variant="scrollable">
      {tabsList?.map((item) => {
        const { name, value } = item;

        return <StyledTab key={name} value={value} label={name} id={value} />;
      })}
    </StyledTabs>
  );
};

export default TableTabsComponent;
