import { SweetenerElementPayload, SweetenerElementsPayload, SweetenerPayload } from 'generated/graphql';

export type State = {
  data: SweetenerElementsPayload['data'];
  sweetener: SweetenerPayload['sweetener'];
  sweetenerElement: SweetenerElementPayload['sweetenerElement'];
};

export const initialState: State = {
  data: [],
  sweetener: null,
  sweetenerElement: null,
};

export enum ActionType {
  SET_DATA = 'setData',
  SET_SWEETENER = 'setSweetener',
  SET_SWEETENER_COMPONENT = 'setSweetenerElement',
}

export type Action =
  | { type: ActionType.SET_DATA; data: SweetenerElementsPayload['data'] }
  | { type: ActionType.SET_SWEETENER; sweetener: SweetenerPayload['sweetener'] }
  | {
      type: ActionType.SET_SWEETENER_COMPONENT;
      sweetenerElement: SweetenerElementPayload['sweetenerElement'];
    };

export const SweetenerElementReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_DATA:
      return {
        ...state,
        data: action.data,
      };

    case ActionType.SET_SWEETENER:
      return {
        ...state,
        sweetener: action.sweetener,
      };

    case ActionType.SET_SWEETENER_COMPONENT:
      return {
        ...state,
        sweetenerElement: action.sweetenerElement,
      };
  }
};
