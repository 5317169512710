import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
//components
import SweetenerSystemTable from './SweetenerSystemTable';
// constant, styles, reducer, graphql
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { ADD_NEW_TEXT, ADD_SWEETENER_ROUTE, SWEETENER_SYSTEM_TEXT } from 'constants/index';

const SweetenerSystem: FC = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{SWEETENER_SYSTEM_TEXT}</Typography>
        <Button variant="contained" color="primary" onClick={() => navigate(ADD_SWEETENER_ROUTE)}>
          <AddRoundedIcon />
          {ADD_NEW_TEXT}
        </Button>
      </Box>
      <SweetenerSystemTable />
    </Fragment>
  );
};

export default SweetenerSystem;
