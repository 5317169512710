import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
//components
import EditIngredientForm from 'components/main/Ingredients/Edit/EditIngredientForm';
//constants, interfaces, graphql
import { ParamType } from 'interfaces';
import { EDIT_INGREDIENT_TEXT, HTTP_STATUS } from 'constants/index';
//styles
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { IngredientPayload, useGetIngredientLazyQuery } from 'generated/graphql';

const EditIngredient: FC = () => {
  const { id } = useParams<ParamType>();
  const [ingredient, setIngredient] = useState<IngredientPayload['ingredient']>(null);

  const [getIngredient, { loading }] = useGetIngredientLazyQuery({
    onCompleted: (data) => {
      const { getIngredient } = data || {};
      const { response, ingredient } = getIngredient || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setIngredient(ingredient as IngredientPayload['ingredient']);
      }
    },
    onError: () => {
      setIngredient(null);
    },
  });

  const fetchIngredient = useCallback(async () => {
    await getIngredient({
      variables: {
        getIngredientInput: { id: id ?? '' },
      },
    });
  }, [getIngredient, id]);

  useEffect(() => {
    id && fetchIngredient();
  }, [fetchIngredient, id]);

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{EDIT_INGREDIENT_TEXT}</Typography>
      </Box>

      <EditIngredientForm loading={loading} ingredient={ingredient} fetchIngredient={fetchIngredient} />
    </Fragment>
  );
};

export default EditIngredient;
