import { FC } from 'react';
import { Box, Typography } from '@mui/material';
// components
import { LogoIcon } from 'assets/svgs';
import { AuthLayoutContainer } from '../../theme/styleComponent';
// interfaces
import { AuthLayoutProps } from '../../interfaces';
// theme
import { authLogoBox, AuthLogoContainer, flexCenterBetween } from '../../styles/commonComponentStyle';

export const AuthLayout: FC<AuthLayoutProps> = ({
  icon,
  title,
  mb = 1,
  children,
  subTitle,
  logoutBtn,
  layoutContainer,
  maxWidth = '480px',
  paddingX = 'initial',
  lineHeight = 'initial',
}) => {
  return (
    <>
      <AuthLogoContainer maxWidth={false}>
        <Box sx={[authLogoBox, flexCenterBetween]}>
          <Box flex="1">
            <LogoIcon height={50} width={125} />
          </Box>

          {logoutBtn && <>{logoutBtn}</>}
        </Box>
      </AuthLogoContainer>

      <AuthLayoutContainer sx={layoutContainer}>
        <Box width="100%" maxWidth={maxWidth} paddingX={paddingX} px={{ xs: 0 }}>
          {icon && (
            <Box textAlign="center" mb={5}>
              {icon}
            </Box>
          )}

          <Box>
            <Box pb={5} textAlign="center">
              <Typography variant="h5" mb={mb}>
                {title}
              </Typography>

              <Box>
                {subTitle && (
                  <Typography variant="subtitle1" component="p" lineHeight={lineHeight}>
                    {subTitle}
                  </Typography>
                )}
              </Box>
            </Box>

            {children}
          </Box>
        </Box>
      </AuthLayoutContainer>
    </>
  );
};
