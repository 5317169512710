import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
//components
import { PasswordSuccessIcon } from 'assets/svgs';
import { AuthLayout } from 'components/common/AuthLayout';
//constants
import {
  AUTH_LINKS,
  GO_TO_SIGNIN_BUTTON,
  PASSWORD_SET_SUCCESS_SUBTITLE,
  PASSWORD_SET_SUCCESS_TITLE,
} from 'constants/index';

const SetPasswordSuccess: FC = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout
      title={PASSWORD_SET_SUCCESS_TITLE}
      subTitle={PASSWORD_SET_SUCCESS_SUBTITLE}
      icon={<PasswordSuccessIcon />}>
      <Button fullWidth variant="contained" color="primary" onClick={() => navigate(AUTH_LINKS.LOGIN_LINK)}>
        {GO_TO_SIGNIN_BUTTON}
      </Button>
    </AuthLayout>
  );
};

export default SetPasswordSuccess;
