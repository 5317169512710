import { FC } from 'react';
import { Box, Button, Card, Grid } from '@mui/material';
//components
import DataLoader from 'components/common/DataLoader';
import InputController from 'controller/InputController';
import { InfoTooltip } from 'components/common/InfoTooltip';
import TextEditorController from 'controller/RichTextEditor';
import CheckboxGroupController from 'controller/CheckboxGroup';
import CategorySelector from 'components/common/selectors/category';
import ProductTypeSelector from 'components/common/radioGroups/productType';
//constants, interfaces, helper, interface, styles
import {
  SAVE_TEXT,
  TYPE_TEXT,
  TRADEMARK_TEXT,
  LABEL_NAME_TEXT,
  COMMON_NAME_TEXT,
  WORK_IN_FORMAT_TEXT,
  SCIENTIFIC_NAME_TEXT,
  PRIMARY_CATEGORY_TEXT,
  SECONDARY_CATEGORY_TEXT,
  INGREDIENTS_TYPE_MAPPED,
  SCIENTIFIC_NAME_INFO,
  LABEL_NAME_INFO,
} from 'constants/index';
import { IngredientBasicInfoCardProps } from 'interfaces';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';

const IngredientBasicInfoCard: FC<IngredientBasicInfoCardProps> = ({ loading, isEdit }): JSX.Element => {
  return (
    <Card sx={forInputLabelBg}>
      {loading ? (
        <DataLoader columns={12} rows={5} />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <CategorySelector name="category" title={PRIMARY_CATEGORY_TEXT} isRequired disabled={loading} />
            </Grid>

            <Grid item xs={12} lg={6}>
              <CategorySelector
                name="subCategory"
                title={SECONDARY_CATEGORY_TEXT}
                primary={false}
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <InputController
                name="name"
                fieldType="text"
                title={COMMON_NAME_TEXT}
                isRequired
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <InputController
                name="scientificName"
                fieldType="text"
                title={SCIENTIFIC_NAME_TEXT}
                disabled={loading}
                inputAdornmentIcon={<InfoTooltip title={SCIENTIFIC_NAME_INFO} />}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputController
                name="tradeMarkName"
                fieldType="text"
                title={TRADEMARK_TEXT}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextEditorController
                name="latinName"
                disabled={loading}
                title={`${LABEL_NAME_TEXT} ${LABEL_NAME_INFO}`}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Box mt={3}>
        <ProductTypeSelector name="productTypes" title={WORK_IN_FORMAT_TEXT} isRequired loading={loading} />
      </Box>

      <CheckboxGroupController
        name="type"
        loaderRows={1}
        loaderColumns={4}
        title={TYPE_TEXT}
        loading={loading}
        options={INGREDIENTS_TYPE_MAPPED}
      />

      {isEdit ? (
        <Box sx={flexMarginTopEndCenter}>
          <Button type="submit" variant="contained" id="basic-info-btn" size="small" disabled={loading}>
            {SAVE_TEXT}
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default IngredientBasicInfoCard;
