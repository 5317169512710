import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
// interfaces, constants
import { HELPER_TEXT } from 'constants/index';
import { CheckboxControllerProps } from 'interfaces';

const CheckboxController: FC<CheckboxControllerProps> = ({ name, title, onChange: handleChange }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { name, onChange, value } = field;
        const { error } = fieldState;
        const { message } = error || {};
        return (
          <FormControl required error={!!error} component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id={name}
                    name={name}
                    checked={!!value}
                    onChange={(value, checked) => {
                      handleChange && handleChange(checked);
                      onChange(value);
                    }}
                  />
                }
                label={title}
              />
            </FormGroup>
            <FormHelperText id={`${name}-${HELPER_TEXT}`}>{message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default CheckboxController;
