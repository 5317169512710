import { FC, useMemo } from 'react';
import { Box, MenuItem, TextField } from '@mui/material';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
// interfaces
import { SelectorProps } from 'interfaces';
import { ADD_EMPTY } from 'constants/index';

const Selector: FC<SelectorProps> = ({
  value,
  title,
  options,
  onChange,
  placeholder,
  defaultValue,
  margin,
  displayEmpty,
  id,
  addAll,
}) => {
  const updatedOptions = useMemo(() => {
    if (addAll) {
      return [...options, ADD_EMPTY];
    }
    return options;
  }, [addAll, options]);
  return (
    <TextField
      select
      fullWidth
      label={title}
      value={value}
      margin={margin}
      name={`${id}-selector`}
      variant="outlined"
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={(e) => onChange(e.target.value)}
      InputLabelProps={displayEmpty ? { shrink: true } : {}}
      SelectProps={{
        IconComponent: () => (
          <Box mr={1} minWidth={28} height={28} color={`customBlackColor.contrastText`}>
            <KeyboardArrowDownRoundedIcon />
          </Box>
        ),
        displayEmpty: displayEmpty,
      }}>
      {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
      {updatedOptions?.map((item) => {
        const { name, value } = item;
        return (
          <MenuItem key={value} value={value}>
            {name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default Selector;
