import { FormulaPayload } from '../generated/graphql';

export type State = {
  formula: FormulaPayload['formula'];
};

export const initialState: State = {
  formula: null,
};

export enum ActionType {
  SET_FORMULA = 'setFormula',
}

export type Action = { type: ActionType.SET_FORMULA; formula: FormulaPayload['formula'] };

export const formulaDetailReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_FORMULA:
      return {
        ...state,
        formula: action.formula,
      };
  }
};
