import { FC } from 'react';
import { Box, Card, Typography } from '@mui/material';
// components
import FormulaBlends from './FormulaBlends';
import FormulaIngredients from './FormulaIngredients';
// constants
import { INGREDIENTS_TEXT } from 'constants/index';

const IngredientsList: FC = () => {
  return (
    <Card>
      <Typography variant="h6">{INGREDIENTS_TEXT}</Typography>
      <Box
        maxHeight={400}
        sx={{
          overflowY: 'auto',
          pr: 1,
        }}>
        <FormulaBlends />
        <FormulaIngredients />
      </Box>
    </Card>
  );
};

export default IngredientsList;
