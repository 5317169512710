import { Chip, TableCell, TableRow } from '@mui/material';
import { FC, Fragment, Reducer, useCallback, useEffect, useReducer } from 'react';
// components
import TableLoader from 'components/common/TableLoader';
import { Alert } from 'components/common/Alert';
import NoDataFound from 'components/common/NoDataFound';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import TableSearchField from 'components/common/TableSearchField';
import TableTabsComponent from 'components/common/TableTabsComponent';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
// constants, reducers, graphql, styles
import {
  HTTP_STATUS,
  ACTIVE_TEXT,
  INACTIVE_TEXT,
  TABS,
  TabEnum,
  LOADING_TABLE_ROWS,
  EDIT_PRODUCT_TYPE_ROUTE,
  PRODUCT_TYPE_TABLE_HEADER,
} from 'constants/index';
import { textWhiteSpace } from 'styles/commonComponentStyle';
import {
  useUpdateProductTypeMutation,
  useFindProductTypeTableLazyQuery,
  ProductTypesPayload,
} from 'generated/graphql';
import { Action, ActionType, State, initialState, productTypeReducer } from 'reducer/productTypeReducer';

const ProductTypeTable: FC = () => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(productTypeReducer, initialState);
  const { page, rowsPerPage, search, count, data, activeTab } = state;

  const [findAllProductType, { loading: fetchLoading, error }] = useFindProductTypeTableLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllProductType } = data;
      const { pagination, response, data: productTypeData } = findAllProductType || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { page, totalCount } = pagination || {};
        dispatch({ type: ActionType.SET_PAGE, page: page || 1 });
        dispatch({ type: ActionType.SET_COUNT, count: totalCount || 0 });
        dispatch({ type: ActionType.SET_DATA, data: productTypeData as ProductTypesPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const [updateProductTypeStatus, { loading: updateLoading }] = useUpdateProductTypeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateProductType } = data;
      const { response } = updateProductType || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        fetchProductType();
      } else {
        Alert.error(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_COUNT, count: 0 });
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchProductType = useCallback(async () => {
    await findAllProductType({
      variables: {
        findAllProductTypeInput: {
          paginationOptions: {
            limit: rowsPerPage,
            page,
          },
          search,
          ...(activeTab !== TabEnum.All && { isActive: activeTab === TabEnum.Active }),
        },
      },
    });
  }, [findAllProductType, search, rowsPerPage, page, activeTab]);

  useEffect(() => {
    (!search.length || search.length > 2) && fetchProductType();
  }, [fetchProductType, search.length]);

  const tabHandler = (_: React.SyntheticEvent<Element, Event>, value: string) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab: value as TabEnum });
  };

  const onActiveHandler = async (id: string, status: boolean) => {
    await updateProductTypeStatus({
      variables: {
        updateProductTypeInput: {
          id,
          isActive: !status,
        },
      },
    });
  };

  const loading = fetchLoading || updateLoading;

  const noData = Boolean((!fetchLoading && data?.length === 0) || error);

  return (
    <TableContainer>
      <TableTabsComponent activeTab={activeTab} tabsList={TABS} tabHandler={tabHandler} />

      <TableSearchField
        search={search}
        onClear={() => dispatch({ type: ActionType.SET_SEARCH, search: '' })}
        onChange={(val) => dispatch({ type: ActionType.SET_SEARCH, search: val })}
      />

      <TableComponent
        noData={noData}
        page={page - 1}
        count={count}
        rowsPerPage={rowsPerPage}
        tableHeader={PRODUCT_TYPE_TABLE_HEADER}
        setPage={(p: number) => dispatch({ type: ActionType.SET_PAGE, page: p + 1 })}
        setRowsPerPage={(r: number) => dispatch({ type: ActionType.SET_ROWS_PER_PAGE, rowsPerPage: r })}>
        {loading ? (
          <TableLoader columns={PRODUCT_TYPE_TABLE_HEADER?.length} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const { id, name, isActive, type } = cell || {};

              return (
                <TableRow key={id}>
                  <TableCell sx={textWhiteSpace}>{name ?? '--'}</TableCell>
                  <TableCell>{type ?? '--'}</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={isActive ? ACTIVE_TEXT : INACTIVE_TEXT}
                      color={isActive ? 'success' : 'error'}
                    />
                  </TableCell>
                  <TableCell>
                    <ActionMenuDropdown
                      id={id ?? ''}
                      isActive={!!isActive}
                      editRoute={EDIT_PRODUCT_TYPE_ROUTE}
                      onActiveHandler={onActiveHandler}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>

      <NoDataFound noData={noData} />
    </TableContainer>
  );
};

export default ProductTypeTable;
