import { useNavigate } from 'react-router-dom';
import { FC, Fragment, JSX, useState } from 'react';
import { Box, Button, ClickAwayListener, MenuItem, Typography } from '@mui/material';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
//components
import FormulasTable from './FormulasTable';
// constant, styles
import {
  BY_TEXT,
  DOSAGE_TEXT,
  FORMULA_TEXT,
  ADD_NEW_TEXT,
  FACT_BOX_TEXT,
  INGREDIENTS_TEXT,
  ADD_FORMULA_BY_DOSAGE_ROUTE,
  ADD_FORMULA_BY_FACT_BOX_ROUTE,
  ADD_FORMULA_BY_INGREDIENTS_ROUTE,
} from 'constants/index';
import { ExportBoxStyle, ExportMenuStyles, flexCenterBetween } from 'styles/commonComponentStyle';

const Formulas: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const navigateTo = (route: string) => {
    navigate(route);
  };

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{FORMULA_TEXT}</Typography>

        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box position="relative">
            <Button
              variant="contained"
              onClick={() => setOpen(!open)}
              endIcon={<KeyboardArrowDownRoundedIcon />}>
              {ADD_NEW_TEXT}
            </Button>

            {open ? (
              <Box sx={ExportBoxStyle} py={1}>
                <MenuItem sx={ExportMenuStyles} onClick={() => navigateTo(ADD_FORMULA_BY_DOSAGE_ROUTE)}>
                  {BY_TEXT} {DOSAGE_TEXT}
                </MenuItem>

                <MenuItem sx={ExportMenuStyles} onClick={() => navigateTo(ADD_FORMULA_BY_FACT_BOX_ROUTE)}>
                  {BY_TEXT} {FACT_BOX_TEXT}
                </MenuItem>

                <MenuItem sx={ExportMenuStyles} onClick={() => navigateTo(ADD_FORMULA_BY_INGREDIENTS_ROUTE)}>
                  {BY_TEXT} {INGREDIENTS_TEXT}
                </MenuItem>
              </Box>
            ) : (
              <Fragment />
            )}
          </Box>
        </ClickAwayListener>
      </Box>
      <FormulasTable />
    </Fragment>
  );
};

export default Formulas;
