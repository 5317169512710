import { Fragment, Suspense, lazy } from 'react';
import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
// components
import NotFound from 'pages/NotFound';
// routes
import PrivateRoute from 'routes/PrivateRoute';
import PublicRoute from 'routes/PublicRoute';
// constants
import { AUTH_LINKS, ROOT_ROUTE } from 'constants/index';
import { appRoutes } from 'routes/routes';

const Login = lazy(() => import('pages/auth/Login'));

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Fragment>
      {/* public routes start */}
      <Route caseSensitive path={ROOT_ROUTE} element={<Navigate replace to={AUTH_LINKS.LOGIN_LINK} />} />
      <Route
        caseSensitive
        path={AUTH_LINKS.LOGIN_LINK}
        element={
          <Suspense fallback={null}>
            <Login />
          </Suspense>
        }
      />
      {appRoutes?.map(({ element, isPrivate, url, id }) => {
        return (
          <Route
            key={id}
            caseSensitive
            path={url}
            element={
              isPrivate ? <PrivateRoute>{element}</PrivateRoute> : <PublicRoute>{element}</PublicRoute>
            }
          />
        );
      })}
      <Route path="/*" element={<NotFound />} />
    </Fragment>,
  ),
);
