import { HttpStatusCode } from 'axios';
import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FC, memo, useCallback, useEffect, useState } from 'react';
// components
import RowView from 'components/common/RowView';
import DataLoader from 'components/common/DataLoader';
// interfaces, constants, helper
import { ParamType } from '../../../../../../interfaces';
import {
  COST_TEXT,
  WEIGHT_TEXT,
  PRODUCT_SIZE_TEXT,
  PRODUCT_TYPE_TEXT,
  SUB_PRODUCT_TYPE_TEXT,
  PRODUCT_FORMULATION_TEXT,
} from 'constants/index';
import {
  ProductFormulationSyrupTypePayload,
  useGetProductFormulationSyrupTypeLazyQuery,
} from '../../../../../../generated/graphql';

const Card: FC = () => {
  const params = useParams<ParamType>();
  const { id } = params || {};

  const [data, setData] = useState<ProductFormulationSyrupTypePayload['productFormulationSyrupType']>(null);

  const [getFormulationSyrupType, { loading }] = useGetProductFormulationSyrupTypeLazyQuery({
    onCompleted: (data) => {
      const { getProductFormulationSyrupType } = data || {};
      const { response, productFormulationSyrupType } = getProductFormulationSyrupType || {};
      const { status } = response || {};
      if (status === HttpStatusCode.Ok) {
        setData(
          productFormulationSyrupType as ProductFormulationSyrupTypePayload['productFormulationSyrupType'],
        );
      }
    },
    onError: () => {
      setData(null);
    },
  });

  const { productFormulationType, productType, cost, weight, productSize, subProductType } = data || {};
  const { name: productName } = productType || {};
  const { name: productSizeName } = productSize || {};
  const { name: subProductName } = subProductType || {};
  const { name: productFormulationTypeName } = productFormulationType || {};

  const fetchFormulationSyrupType = useCallback(async () => {
    id &&
      (await getFormulationSyrupType({
        variables: {
          getProductFormulationSyrupTypeInput: {
            id,
          },
        },
      }));
  }, [id, getFormulationSyrupType]);

  useEffect(() => {
    id && fetchFormulationSyrupType();
  }, [id, fetchFormulationSyrupType]);

  return (
    <Box m={4}>
      {loading ? (
        <DataLoader columns={4} rows={2} />
      ) : (
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={6}>
            <RowView name={WEIGHT_TEXT} value={weight ? `${weight} mg` : '--'} isInfo={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <RowView name={COST_TEXT} value={cost ? `$${cost}` : '--'} isInfo={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <RowView name={PRODUCT_TYPE_TEXT} value={productName || '--'} isInfo={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <RowView name={SUB_PRODUCT_TYPE_TEXT} value={subProductName || '--'} isInfo={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <RowView name={PRODUCT_SIZE_TEXT} value={productSizeName || '--'} isInfo={false} />
          </Grid>

          <Grid item xs={12} md={6}>
            <RowView
              name={PRODUCT_FORMULATION_TEXT}
              value={`${productFormulationTypeName || ''}`}
              isInfo={false}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default memo(Card);
