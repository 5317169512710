import { FC, Fragment } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
//constants, svg, interface, styles
import { SEARCH_TEXT } from 'constants/index';
import { SearchFieldProps } from 'interfaces';
import { CloseIcon, SearchInputIcon } from 'assets/svgs';
import { forInputLabelBg } from 'styles/commonComponentStyle';

const SearchField: FC<SearchFieldProps> = ({
  size,
  value,
  onClear,
  onChange,
  placeholder,
  fullWidth = true,
}) => {
  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchInputIcon />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <IconButton onClick={onClear}>
            <CloseIcon />
          </IconButton>
        ) : (
          <Fragment />
        ),
      }}
      autoComplete="off"
      size={size}
      name="search"
      value={value}
      sx={forInputLabelBg}
      fullWidth={fullWidth}
      placeholder={placeholder || SEARCH_TEXT}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default SearchField;
