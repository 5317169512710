import { FC } from 'react';
import { Grid } from '@mui/material';
// components
import BeadletsRadioGroup from 'components/common/selectors/beadlets';
// constants
import { BEADLETS_TEXT } from 'constants/index';

const Beadlets: FC = () => {
  return (
    <Grid item xs={12} xl={12}>
      <BeadletsRadioGroup isRequired name="beadlets" title={BEADLETS_TEXT} />
    </Grid>
  );
};

export default Beadlets;
