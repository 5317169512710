// packages import
import { FC } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { Typography, Backdrop, Box, useTheme } from '@mui/material';
// constants, interfaces
import { BackdropLoaderProps } from 'interfaces';
import { APP_LOADING_TEXT } from 'constants/index';

export const BackdropLoader: FC<BackdropLoaderProps> = ({ text, open }) => {
  const theme = useTheme();

  return (
    <Backdrop
      open={open}
      sx={{
        zIndex: ({ zIndex }) => zIndex.modal + 1,
        backdropFilter: 'brightness(0.5)',
      }}>
      <Box>
        <ThreeCircles
          height="50"
          width="50"
          visible={true}
          color={theme.palette.primary.main}
          wrapperStyle={{ justifyContent: 'center' }}
        />
        <Typography color="white" variant="subtitle1">
          {text ?? APP_LOADING_TEXT}
        </Typography>
      </Box>
    </Backdrop>
  );
};
