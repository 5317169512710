// imports
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, MenuItem, TextField } from '@mui/material';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
// interfaces, constants, helper
import { renderProductSubTypes } from 'lib/helper';
import { SelectType, SubProductTypeSelectProps } from 'interfaces';
import { HTTP_STATUS, selectTypeInitialValues } from 'constants/index';
import { SubProductTypesPayload, useFindAllSubProductTypeLazyQuery } from 'generated/graphql';

const SubProductTypeSelect: FC<SubProductTypeSelectProps> = ({
  name,
  title,
  value,
  isActive,
  defaultValue,
  handleChange,
  productTypeId,
  margin = 'dense',
  isClearable = false,
}) => {
  const [options, setOptions] = useState<SelectType[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [findAllSubProductType, { loading }] = useFindAllSubProductTypeLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllSubProductType } = data;
      const { response, data: productSubTypeData } = findAllSubProductType || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setOptions(renderProductSubTypes(productSubTypeData as SubProductTypesPayload['data']));
      } else {
        setOptions([]);
      }
    },

    onError: () => {
      setOptions([]);
    },
  });

  const fetchSubProductTypes = useCallback(async () => {
    await findAllSubProductType({
      variables: {
        findAllSubProductTypeInput: {
          paginationOptions: {
            limit: 10,
            page: 1,
          },
          ...(searchQuery && { search: searchQuery }),
          ...(productTypeId && { productTypeId }),
          ...(typeof isActive === 'boolean' && { isActive }),
        },
      },
    });
  }, [findAllSubProductType, productTypeId, isActive, searchQuery]);

  useEffect(() => {
    fetchSubProductTypes();
  }, [fetchSubProductTypes, searchQuery.length]);

  return (
    <Autocomplete
      disableClearable={!isClearable}
      options={options}
      value={value}
      onChange={(_, val) => {
        handleChange(val ?? selectTypeInitialValues);
      }}
      onClose={() => setSearchQuery('')}
      popupIcon={<KeyboardArrowDownRoundedIcon />}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={({ value: option }, { value: val }) => val === option}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          margin={margin}
          fullWidth
          variant="outlined"
          defaultValue={defaultValue}
          label={title}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}>
          {options?.map((item) => {
            const { name, value } = item;
            return (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            );
          })}
        </TextField>
      )}
    />
  );
};

export default SubProductTypeSelect;
