import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, Fragment, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
//components
import { Alert } from 'components/common/Alert';
import StaffPasswordCard from './StaffPasswordCard';
import StaffCard from 'components/main/Staff/Add/StaffCard';
//interfaces , constants , validationSchema, graphql
import { updatePasswordSchema, updateStaffSchema } from 'validationSchema';
import { useUpdatePasswordMutation, useUpdateStaffInfoMutation } from 'generated/graphql';
import { EditStaffFormType, EditUserFormProps, UpdatePasswordFormType } from 'interfaces';
import { HTTP_STATUS, updatePasswordInitialValues, updateStaffInitialValues } from 'constants/index';

const EditStaffForm: FC<EditUserFormProps> = ({ loading: getLoading, id, user: staff }) => {
  const navigate = useNavigate();
  const methods = useForm<EditStaffFormType>({
    defaultValues: updateStaffInitialValues,
    resolver: yupResolver(updateStaffSchema),
  });

  const passwordMethods = useForm<UpdatePasswordFormType>({
    defaultValues: updatePasswordInitialValues,
    resolver: yupResolver(updatePasswordSchema),
  });

  const { handleSubmit, setValue } = methods;

  const { handleSubmit: passwordSubmit } = passwordMethods;

  const [updateStaffInfo, { loading: updateStaffLoading }] = useUpdateStaffInfoMutation({
    onCompleted: (data) => {
      const { updateStaffInfo } = data;
      const { response } = updateStaffInfo || {};
      const { status, message } = response || {};
      if (message && status === HTTP_STATUS.SUCCESS) {
        Alert.success(message);
        navigate(-1);
      } else {
        Alert.warning(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updatePassword, { loading: updatePasswordLoading }] = useUpdatePasswordMutation({
    onCompleted: (data) => {
      const { updatePassword } = data;
      const { response } = updatePassword || {};
      const { status, message } = response || {};
      if (message && status === HTTP_STATUS.SUCCESS) {
        Alert.success(message);
      } else {
        Alert.warning(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<EditStaffFormType> = async (data) => {
    const { email, firstName, lastName, phone, role } = data;
    if (id) {
      await updateStaffInfo({
        variables: {
          updateStaffInfoInput: {
            id,
            email: email?.toLowerCase(),
            lastName,
            firstName,
            staffRole: role,
            ...(phone && { phone: phone }),
          },
        },
      });
    }
  };

  const onPasswordSubmit: SubmitHandler<UpdatePasswordFormType> = async (data) => {
    const { password } = data;
    if (id) {
      await updatePassword({
        variables: {
          updatePasswordInput: {
            id: id ?? '',
            password: password ?? '',
          },
        },
      });
    }
  };

  const setValues = useCallback(() => {
    const { firstName, lastName, email, phone, staffRole } = staff || {};
    setValue('email', email ?? '');
    setValue('firstName', firstName ?? '');
    setValue('lastName', lastName ?? '');
    setValue('role', staffRole ?? '');
    setValue('phone', phone ?? '');
  }, [setValue, staff]);

  useEffect(() => {
    staff && setValues();
  }, [setValues, staff]);

  const loading = getLoading || updateStaffLoading || updatePasswordLoading;

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={7}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <StaffCard loading={loading} isEdit />
            </form>
          </FormProvider>
        </Grid>
      </Grid>

      <Grid container spacing={3} marginTop={1}>
        <Grid item xs={12} xl={7}>
          <FormProvider {...passwordMethods}>
            <form onSubmit={passwordSubmit(onPasswordSubmit)}>
              <StaffPasswordCard loading={loading} isEdit />
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default EditStaffForm;
