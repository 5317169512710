import { FC, Fragment } from 'react';
import { Add as AddIcon, Delete } from '@mui/icons-material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Button, Card, CardHeader, Grid, IconButton, Link, Typography } from '@mui/material';
// component
import DataLoader from 'components/common/DataLoader';
import InputController from 'controller/InputController';
// styles, interface, constants
import { AdditionalContactFormType, AdditionalContactTabProps } from 'interfaces';
import { forInputLabelBg } from 'styles/commonComponentStyle';
import { displayFlexAlignCenter, flexCenterJustifyBetween } from 'theme/styleConstant';
import {
  ADD_TEXT,
  SAVE_TEXT,
  NAME_TEXT,
  EMAIL_TEXT,
  additionalContactSelectTypeInitialValue,
  ADDITIONAL_CONTACT_TEXT,
} from 'constants/index';

const AdditionalContactForm: FC<AdditionalContactTabProps> = ({ loading, isEdit }) => {
  const { control } = useFormContext<AdditionalContactFormType>();

  const { fields, remove, append } = useFieldArray({
    name: 'additionalContacts',
    control,
  });

  const handleAddItem = () => {
    if (fields.length < 3) {
      append(additionalContactSelectTypeInitialValue);
    }
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };

  const isAddDisabled = loading || fields.length >= 3;

  return (
    <Card sx={forInputLabelBg}>
      <CardHeader title={ADDITIONAL_CONTACT_TEXT} />
      {loading ? (
        <DataLoader columns={2} rows={2} />
      ) : (
        <>
          {fields?.length ? (
            <Grid container spacing={3}>
              {fields?.map((item, index) => (
                <Fragment key={item.id}>
                  <Grid item xs={12} sm={12} md={6} lg={12} xl={6}>
                    <InputController
                      isRequired
                      fieldType="text"
                      disabled={loading}
                      title={NAME_TEXT}
                      name={`additionalContacts.[${index}].name`}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={5} lg={12} xl={5}>
                    <InputController
                      isRequired
                      fieldType="text"
                      disabled={loading}
                      title={EMAIL_TEXT}
                      name={`additionalContacts.[${index}].email`}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={1} lg={12} xl={1} mt={1}>
                    <Box display={'flex'} justifyContent={'flex-end'}>
                      <IconButton color="error" aria-label="delete" onClick={() => handleRemoveItem(index)}>
                        <Delete />
                      </IconButton>
                    </Box>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          ) : null}
          <Box sx={flexCenterJustifyBetween} mt={1.5}>
            <Link
              component="button"
              type="button"
              disabled={isAddDisabled}
              onClick={handleAddItem}
              sx={[
                displayFlexAlignCenter,
                isAddDisabled
                  ? { color: 'customGrayColor.light', cursor: 'not-allowed' }
                  : { color: 'primary.main' },
              ]}>
              <AddIcon />{' '}
              <Typography variant="caption" pl={0.5} fontWeight={600}>
                {ADD_TEXT}
              </Typography>
            </Link>

            {isEdit && (
              <Button type="submit" variant="contained" id="allergen-btn" size="small" disabled={loading}>
                {SAVE_TEXT}
              </Button>
            )}
          </Box>
        </>
      )}
    </Card>
  );
};

export default AdditionalContactForm;
