import { TabEnum, PAGE_LIMIT } from 'constants/index';
import { ExcipientTypesPayload } from 'generated/graphql';

export type State = {
  page: number;
  count: number;
  search: string;
  rowsPerPage: number;
  activeTab: TabEnum;
  data: ExcipientTypesPayload['data'];
};

export const initialState: State = {
  page: 1,
  count: 0,
  data: [],
  search: '',
  rowsPerPage: PAGE_LIMIT,
  activeTab: TabEnum.Active,
};

export enum ActionType {
  SET_PAGE = 'setPage',
  SET_DATA = 'setData',
  SET_COUNT = 'setCount',
  SET_SEARCH = 'setSearch',
  SET_ACTIVE_TAB = 'setActiveTab',
  SET_ROWS_PER_PAGE = 'setRowsPerPage',
}

export type Action =
  | { type: ActionType.SET_PAGE; page: number }
  | { type: ActionType.SET_COUNT; count: number }
  | { type: ActionType.SET_DATA; data: ExcipientTypesPayload['data'] }
  | { type: ActionType.SET_SEARCH; search: string }
  | { type: ActionType.SET_ACTIVE_TAB; activeTab: TabEnum }
  | { type: ActionType.SET_ROWS_PER_PAGE; rowsPerPage: number };

export const excipientTypeReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case ActionType.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
      };

    case ActionType.SET_COUNT:
      return {
        ...state,
        count: action.count,
      };

    case ActionType.SET_DATA:
      return {
        ...state,
        data: action.data,
      };

    case ActionType.SET_SEARCH:
      return {
        ...state,
        search: action.search,
      };

    case ActionType.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };
  }
};
