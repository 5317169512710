import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import ExcipientLabelForm from './ExcipientLabelForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { useCreateExcipientLabelMutation } from 'generated/graphql';
import {
  ADD_TEXT,
  HTTP_STATUS,
  ADD_EXCIPIENT_LABEL_TEXT,
  excipientLabelInitialValue,
  EXCIPIENT_LABEL_IS_CREATING_TEXT,
} from 'constants/index';
import { AddExcipientLabelFormType } from 'interfaces';
import { updateExcipientLabelSchema } from 'validationSchema';

const Add: FC = (): JSX.Element => {
  const navigation = useNavigate();

  const methods = useForm<AddExcipientLabelFormType>({
    defaultValues: excipientLabelInitialValue,
    resolver: yupResolver(updateExcipientLabelSchema),
  });

  const { handleSubmit } = methods;

  const [createExcipientLabel, { loading }] = useCreateExcipientLabelMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createExcipientLabel } = data;
      const { response } = createExcipientLabel || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<AddExcipientLabelFormType> = async (data) => {
    const { name } = data || {};

    await createExcipientLabel({
      variables: {
        createExcipientLabelInput: {
          name,
        },
      },
    });
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{ADD_EXCIPIENT_LABEL_TEXT}</Typography>
            <Button variant="contained" color="primary" type="submit">
              {ADD_TEXT}
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12}>
              <ExcipientLabelForm loading={loading} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <BackdropLoader open={loading} text={EXCIPIENT_LABEL_IS_CREATING_TEXT} />
    </Fragment>
  );
};

export default Add;
