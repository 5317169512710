import { Box, Grid } from '@mui/material';
import { FormProvider, useFormContext } from 'react-hook-form';
import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
// components
import { Alert } from 'components/common/Alert';
import PackageNCost from '../../Dosage/Add/components/PackageNCost';
import { AddFactBoxFormulaContext } from 'contexts/AddFactBoxContext';
import FactSheetModal from '../../Dosage/Add/components/FactSheetModal';
import IngredientsList from '../../Dosage/Add/components/IngredientsList';
import FormulaInfoCard from '../../Dosage/Add/components/FormulaInfoCard';
// constants, schemas, interfaces
import {
  ProductTypes,
  BottleCapType,
  CalcFormulaCostPayload,
  useCalcFormulaCostLazyQuery,
} from 'generated/graphql';
import { HTTP_STATUS } from 'constants/index';
import { ActionType } from 'reducer/addFactBoxReducer';
import { formatCalcFormulaIngredients } from 'lib/helper';
import { FORM_FILLED_ERROR_MESSAGE_TEXT } from 'constants/constant';
import { BlendItemType, CalcFormulaCost, FactBoxStep3Props, FactBoxFormulationFormType } from 'interfaces';

const Step3: FC<FactBoxStep3Props> = ({ formulaMethods }) => {
  // local states
  const [open, setOpen] = useState(false);
  const [formulaCost, setFormulaCost] = useState<CalcFormulaCost>(null);

  const { state, dispatch } = useContext(AddFactBoxFormulaContext);
  const ingredientMethods = useFormContext<FactBoxFormulationFormType>();

  const { trigger } = ingredientMethods;

  const { formulation, productType, productFormulationType, formulaInfo, subProductType, ingredientsBlend } =
    state;

  const {
    bottleCap,

    productSize: productSizeType,

    productSubType,

    sandCoating,
    tabletCoating,

    excipientType,

    flavorSystem,
    sweetenerSystem,

    capsuleType,
    innerCapsuleType,
    outerCapsuleType,

    servingUnit,
    desiredServingSize,
    servingPerContainer,
  } = formulaInfo || {};

  const { formulaIngredients, factBoxType } = formulation || {};

  const { value: productSizeId } = productSizeType || {};
  const { value: excipientTypeId } = excipientType || {};

  const { value: subProductTypeId } = subProductType || {};
  const { value: productTypeId, type } = productType || {};
  const { value: productFormulationTypeId } = productFormulationType || {};

  const { value: sweetenerSystemId } = sweetenerSystem || {};

  const isValid = formulaIngredients.every((item) => item?.value && item?.type);

  const [getFormulaCost, { loading }] = useCalcFormulaCostLazyQuery({
    onCompleted: (res) => {
      const { calcFormulaCost } = res || {};
      const { formulaCostSuggestion, response } = calcFormulaCost || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setFormulaCost(formulaCostSuggestion as CalcFormulaCostPayload['formulaCostSuggestion']);
      } else {
        setFormulaCost(null);
      }
    },
    onError: ({ message }) => {
      setFormulaCost(null);
      Alert.error(message);
    },
  });

  const handleFactSheet = async () => {
    const isValid = await trigger();
    if (isValid) {
      setOpen(!open);
    } else {
      Alert.error(FORM_FILLED_ERROR_MESSAGE_TEXT);
    }
  };

  const fetchFormulaCost = useCallback(async () => {
    const isValid = await trigger();
    if (isValid) {
      const ingredients = formatCalcFormulaIngredients(formulaIngredients);
      const ingredientBlends = ingredientsBlend?.map(({ ingredients, name: blendName }) => {
        return {
          name: blendName,
          formulaIngredients: formatCalcFormulaIngredients(ingredients),
        };
      });
      if (formulaIngredients?.length || ingredientBlends?.length) {
        await getFormulaCost({
          variables: {
            calcFormulaCostInput: {
              formulaIngredients: ingredients,
              formulaBlends: ingredientBlends,

              bottleCap: bottleCap as BottleCapType,

              servingContainer: Number(servingPerContainer),
              servingSize: Number(desiredServingSize),

              productTypeId: productTypeId || '',
              productFormulationTypeId: productFormulationTypeId || '',
              ...(productSubType && { subProductTypeId: productSubType }),

              ...(excipientTypeId && { excipientTypeId: excipientTypeId }),

              ...(sandCoating && { sandCoatingId: sandCoating }),
              ...(tabletCoating && { tabletCoatingId: tabletCoating }),

              ...(productSizeId && { productSizeId: productSizeId }),

              ...(flavorSystem?.value && { flavorSystemId: flavorSystem?.value }),
              ...(sweetenerSystemId && { sweetenerSystemId: sweetenerSystemId }),

              ...(capsuleType && { capsuleTypeId: capsuleType }),
              ...(innerCapsuleType && { innerCapsuleTypeId: innerCapsuleType }),
              ...(outerCapsuleType && { outerCapsuleTypeId: outerCapsuleType }),
            },
          },
        });
      }
    } else {
      Alert.error(FORM_FILLED_ERROR_MESSAGE_TEXT);
    }
  }, [
    bottleCap,
    capsuleType,
    desiredServingSize,
    excipientTypeId,
    flavorSystem?.value,
    formulaIngredients,
    getFormulaCost,
    ingredientsBlend,
    innerCapsuleType,
    outerCapsuleType,
    productFormulationTypeId,
    productSubType,
    productTypeId,
    sandCoating,
    servingPerContainer,
    sweetenerSystemId,
    productSizeId,
    tabletCoating,
    trigger,
  ]);

  const onBlendAdd = (data: BlendItemType[]) => {
    dispatch({ type: ActionType.SET_INGREDIENTS_BLEND, ingredientsBlend: data });
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isValid) {
      timeoutId = setTimeout(() => {
        fetchFormulaCost();
      }, 1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [fetchFormulaCost, isValid]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={8}>
          <Box mb={2}>
            <FormProvider {...formulaMethods}>
              <FormulaInfoCard />
            </FormProvider>
          </Box>

          <IngredientsList
            onBlendAdd={onBlendAdd}
            handleFactSheet={handleFactSheet}
            productType={type as ProductTypes}
            ingredientsBlend={ingredientsBlend}
            formulaIngredients={formulaIngredients}
            productFormulationType={productFormulationType}
          />
        </Grid>
        <Grid item xs={12} xl={4}>
          <PackageNCost
            calculateCost={fetchFormulaCost}
            formulaCost={formulaCost}
            loading={loading}
            bottleCap={bottleCap}
            productType={productType}
            subProductType={subProductType}
            productFormulationType={productFormulationType}
          />
        </Grid>
      </Grid>

      <FactSheetModal
        open={open}
        factBoxType={factBoxType}
        servingUnit={servingUnit}
        capsuleTypeId={capsuleType}
        productTypeId={productTypeId}
        onClose={() => setOpen(!open)}
        ingredientsBlend={ingredientsBlend}
        innerCapsuleTypeId={innerCapsuleType}
        outerCapsuleTypeId={outerCapsuleType}
        excipientTypeId={excipientTypeId}
        formulaIngredients={formulaIngredients}
        desiredServingSize={desiredServingSize}
        tabletCoating={tabletCoating}
        servingPerContainer={servingPerContainer}
        sweetenerSystemId={sweetenerSystemId}
        productFormulationTypeId={productFormulationTypeId}
        productSizeId={productSizeId}
        sandCoatingId={sandCoating}
        subProductTypeId={subProductTypeId}
      />
    </Fragment>
  );
};

export default Step3;
