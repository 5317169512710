import {
  from,
  HttpLink,
  NextLink,
  Operation,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  DefaultOptions,
} from '@apollo/client';
import { GraphQLErrorExtensions } from 'graphql';
import { onError } from '@apollo/client/link/error';
//components
import { Alert } from 'components/common/Alert';
import { ExceptionalModalUtil } from 'components/common/ExceptionModalUtils';
//constants, helpers
import { getToken, handleLogout } from 'lib/helper';
import { UNAUTHORIZED, TOKEN_INVALID, MAINTENANCE_ALERT, BAD_REQUEST_EXCEPTION } from 'constants/index';

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
});

const authMiddleware = new ApolloLink((operation: Operation, forward: NextLink) => {
  const token = getToken();

  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return forward(operation);
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    const [{ message, extensions }] = graphQLErrors;
    const { originalError } = extensions || {};
    const { message: originalErrorMessages } = (originalError as GraphQLErrorExtensions) || {};

    if (message === UNAUTHORIZED || message === TOKEN_INVALID) handleLogout();
    if (message === BAD_REQUEST_EXCEPTION) {
      ExceptionalModalUtil.showModal(originalErrorMessages as string[]);
    }
  }

  if (networkError) {
    Alert.error(MAINTENANCE_ALERT);
    client.clearStore();
  }
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  },

  query: {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  },
};

const client = new ApolloClient({
  connectToDevTools: true,
  defaultOptions: defaultOptions,
  cache: new InMemoryCache({ addTypename: false }),
  link: from([authMiddleware, errorLink, httpLink]),
});

export default client;
