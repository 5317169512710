import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FC, Fragment, useContext, useState } from 'react';
// component
import { ArrowIcon } from 'assets/svgs';
import { Alert } from 'components/common/Alert';
import DialogBox from 'components/common/DialogBox';
import IngredientStepForm from '../Steps/IngredientStepForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
import SaveAsDraftButton from 'components/common/SaveAsDraftButton';
import SaveAsDraftDialogBox from 'components/common/SaveAsDraftDialogBox';
// constants, reducer, context
import {
  BY_TEXT,
  BACK_TEXT,
  NEXT_TEXT,
  SAVE_TEXT,
  HTTP_STATUS,
  INGREDIENTS_TEXT,
  CREATE_FORMULA_TEXT,
  FORMULA_IS_CREATING_TEXT,
  ADD_INGREDIENT_ERROR_MESSAGE,
  formulaInfoCardFormInitialValue,
  dosageFormulationFormInitialValues,
  formulaIngredientFormInitialValues,
} from 'constants/index';
import {
  VeganType,
  FactBoxType,
  BottleCapType,
  ProteinBaseType,
  FormulaBlendInput,
  TabletCoatingColor,
  SweetenerNatureType,
  MiniTabFormulationType,
  ProductFormulationTypes,
  useCreateFormulaMutation,
  useSaveFormulaAsDraftMutation,
} from 'generated/graphql';
import useCustomBlocker from 'hooks/useCustomBlocker';
import { ActionType } from 'reducer/addFactBoxReducer';
import { formatCalcFormulaIngredients } from 'lib/helper';
import { FormulaIngredientContext } from 'contexts/FormulaByIngredientContext';
import { StickyTopHeader, flexCenterBetween } from 'styles/commonComponentStyle';
import { formulaInfoCardSchema, formulaIngredientSchema, formulationSchema } from 'validationSchema';
import {
  DosageFormulationFormType,
  FormulaIngredientFormType,
  FormulaInfoCardFormType,
  SaveFormulaAsDraftFormType,
} from 'interfaces';

const AddFormula: FC = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(FormulaIngredientContext);
  // local state
  const [isBlocker, setIsBlocker] = useState(true);
  const [isOpenDraft, setIsOpenDraft] = useState(false);

  const { ingredientsBlend, activeStep, productFormulationType, formulaInfo } = state;
  const { type } = productFormulationType || {};

  const ingredientMethods = useForm<FormulaIngredientFormType>({
    resolver: yupResolver(formulaIngredientSchema(activeStep === 0 ? true : false)),
    defaultValues: formulaIngredientFormInitialValues,
  });

  const formulaInfoMethods = useForm<FormulaInfoCardFormType>({
    resolver: yupResolver(formulaInfoCardSchema),
    defaultValues: formulaInfoCardFormInitialValue,
  });

  const dosageMethods = useForm<DosageFormulationFormType>({
    resolver: yupResolver(formulationSchema(type as ProductFormulationTypes)),
    defaultValues: dosageFormulationFormInitialValues,
  });

  const { formState, handleSubmit, getValues: getIngredientsValues } = ingredientMethods;
  const { formulaIngredients } = getIngredientsValues();
  const { isDirty } = formState;

  const { getValues, formState: step2FormState, handleSubmit: dosageSubmit } = dosageMethods;
  const { isDirty: isFormulaFormDirty } = step2FormState;

  const { trigger, getValues: getFormulaInfo } = formulaInfoMethods || {};

  const blocker = useCustomBlocker(isDirty, isFormulaFormDirty, isBlocker);

  const [saveFormulaAsDraft, { loading: saveFormulaAsLoading }] = useSaveFormulaAsDraftMutation({
    onCompleted: (res) => {
      const { saveFormulaAsDraft } = res || {};
      const { response } = saveFormulaAsDraft || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigate(-1);
      } else {
        Alert.warning(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [createFormula, { loading: createFormulaLoading }] = useCreateFormulaMutation({
    onCompleted: (res) => {
      const { createFormula: generateFormula } = res || {};
      const { response } = generateFormula || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigate(-1);
      } else {
        Alert.warning(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const saveFormulaAsDraftAndContinue: SubmitHandler<SaveFormulaAsDraftFormType> = async (data) => {
    const { name: formulaName } = data;

    const formatFormulaIngredients = formatCalcFormulaIngredients(formulaIngredients);

    const {
      beadlets,
      bottleCap,
      capsuleType,
      coatingColor,
      coatingColor2,
      coatingColor3,
      colorsHex,
      desiredServingSize,
      excipientType,
      flavorSystem,
      flavorType,
      innerCapsuleType,
      miniTabFormulation,
      outerCapsuleType,
      productFormulationType,
      proteinBase,
      servingPerContainer,
      servingUnit,
      sweetenerSystem,
      sweetenerType,
      tabletCoating,
      tabletType,
      veganType,
      productType,
      productSubType,
      factBoxType,
      productSize,
      sandCoating,
    } = getValues();

    const formulaBlends = ingredientsBlend?.map<FormulaBlendInput>((item) => {
      const { name: blendName, ingredients } = item;

      const ingredientBlends = formatCalcFormulaIngredients(ingredients);

      return {
        name: blendName,
        formulaIngredients: ingredientBlends,
      };
    });

    await saveFormulaAsDraft({
      variables: {
        saveFormulaAsDraftInput: {
          name: formulaName,

          ...(beadlets && { beadletId: beadlets }),
          ...(tabletType && { tabletTypeId: tabletType }),

          ...(servingUnit && { servingUnit: servingUnit }),
          ...(desiredServingSize && { servingSize: Number(desiredServingSize) }),
          ...(servingPerContainer && { servingContainer: Number(servingPerContainer) }),

          ...(veganType && { veganType: veganType as VeganType }),

          ...(sandCoating && { sandCoatingId: sandCoating }),
          ...(tabletCoating && { tabletCoatingId: tabletCoating }),

          ...(productType && { productTypeId: productType }),
          ...(productSubType && { subProductTypeId: productSubType }),
          ...(productFormulationType?.value && { productFormulationTypeId: productFormulationType?.value }),

          ...(bottleCap && { bottleCap: bottleCap as BottleCapType }),

          ...(factBoxType && { factBoxType: factBoxType as FactBoxType }),

          ...(capsuleType && { capsuleTypeId: capsuleType }),
          ...(innerCapsuleType && { innerCapsuleTypeId: innerCapsuleType }),
          ...(outerCapsuleType && { outerCapsuleTypeId: outerCapsuleType }),

          ...(productSize?.value && { productSizeId: productSize?.value }),

          ...(proteinBase && { proteinBase: proteinBase as ProteinBaseType }),
          ...(miniTabFormulation && { miniTabFormulation: miniTabFormulation as MiniTabFormulationType }),

          ...(excipientType?.value && { excipientTypeId: excipientType?.value }),

          ...(colorsHex?.length && { colorsHex: colorsHex }),
          ...(coatingColor && { colorCoating: coatingColor as TabletCoatingColor }),
          ...(coatingColor3 && { colorCoating3: coatingColor3 as TabletCoatingColor }),
          ...(coatingColor2 && { colorCoating2: coatingColor2 as TabletCoatingColor }),

          ...(sweetenerSystem?.value && { sweetenerSystemId: sweetenerSystem?.value }),
          ...(sweetenerType && { sweetenerType: sweetenerType as SweetenerNatureType }),

          ...(flavorSystem?.value && { flavorSystemId: flavorSystem?.value }),
          ...(flavorType && { flavorType: flavorType as SweetenerNatureType }),

          ...(ingredientsBlend?.length && { formulaBlends: formulaBlends }),
          ...(formulaIngredients?.length && { formulaIngredients: formatFormulaIngredients }),
        },
      },
    });
    blocker?.proceed && blocker?.proceed();
  };

  const onNext: SubmitHandler<DosageFormulationFormType> = async (data) => {
    dispatch({ type: ActionType.SET_FORMULA_INFO, formulaInfo: data });
    dispatch({ type: ActionType.SET_ACTIVE_STEP, activeStep: activeStep + 1 });
  };

  const onSave: SubmitHandler<FormulaIngredientFormType> = async (data) => {
    const isValid = await trigger();
    if (isValid) {
      const { formulaIngredients } = data;
      const { name } = getFormulaInfo();

      const formulaBlends = ingredientsBlend?.map<FormulaBlendInput>((item) => {
        const { name: blendName, ingredients } = item;

        const ingredientBlends = formatCalcFormulaIngredients(ingredients);

        return {
          name: blendName,
          formulaIngredients: ingredientBlends,
        };
      });

      const formatFormulaIngredients = formatCalcFormulaIngredients(formulaIngredients);

      const {
        beadlets,
        bottleCap,
        capsuleType,
        coatingColor,
        coatingColor2,
        coatingColor3,
        colorsHex,
        desiredServingSize,
        excipientType,
        factBoxType,
        flavorSystem,
        flavorType,
        innerCapsuleType,
        miniTabFormulation,
        outerCapsuleType,
        productSubType,
        productType,
        proteinBase,
        servingPerContainer,
        sweetenerSystem,
        sweetenerType,
        tabletCoating,
        tabletType,
        veganType,
        servingUnit,
        productSize,
        sandCoating,
      } = formulaInfo;

      await createFormula({
        variables: {
          createFormulaInput: {
            name,
            bottleCap: bottleCap as BottleCapType,

            servingUnit,
            servingSize: Number(desiredServingSize),
            servingContainer: Number(servingPerContainer),

            productTypeId: productType,
            ...(productSubType && { subProductTypeId: productSubType }),
            productFormulationTypeId: productFormulationType?.value ?? '',

            ...(productSize?.value && { productSizeId: productSize?.value }),

            ...(ingredientsBlend?.length && { formulaBlends: formulaBlends }),
            ...(formulaIngredients?.length && { formulaIngredients: formatFormulaIngredients }),

            ...(beadlets && { beadletId: beadlets }),
            ...(tabletType && { tabletTypeId: tabletType }),
            ...(proteinBase && { proteinBase: proteinBase as ProteinBaseType }),

            ...(veganType && { veganType: veganType as VeganType }),
            ...(miniTabFormulation && { miniTabFormulation: miniTabFormulation as MiniTabFormulationType }),

            ...(excipientType?.value && { excipientTypeId: excipientType?.value }),

            ...(factBoxType && { factBoxType: factBoxType as FactBoxType }),

            ...(flavorSystem?.value && { flavorSystemId: flavorSystem?.value }),
            ...(flavorType && { flavorType: flavorType as SweetenerNatureType }),

            ...(sweetenerSystem?.value && { sweetenerSystemId: sweetenerSystem?.value }),
            ...(sweetenerType && { sweetenerType: sweetenerType as SweetenerNatureType }),

            ...(colorsHex?.length && { colorsHex: colorsHex }),
            ...(coatingColor && { colorCoating: coatingColor as TabletCoatingColor }),
            ...(coatingColor2 && { colorCoating2: coatingColor2 as TabletCoatingColor }),
            ...(coatingColor3 && { colorCoating3: coatingColor3 as TabletCoatingColor }),

            ...(sandCoating && { sandCoatingId: sandCoating }),
            ...(tabletCoating && { tabletCoatingId: tabletCoating }),

            ...(capsuleType && { capsuleTypeId: capsuleType }),
            ...(innerCapsuleType && { innerCapsuleTypeId: innerCapsuleType }),
            ...(outerCapsuleType && { outerCapsuleTypeId: outerCapsuleType }),
          },
        },
      });
      setIsBlocker(false);
    }
  };

  const onSaveAsDraft = () => {
    setIsOpenDraft(true);
    setIsBlocker(false);
  };

  const onSubmit: SubmitHandler<FormulaIngredientFormType> = (data) => {
    if (formulaIngredients.length || ingredientsBlend?.length) {
      dispatch({ type: ActionType.SET_FORMULATION, formulation: data });
      dispatch({ type: ActionType.SET_INGREDIENTS_BLEND, ingredientsBlend: ingredientsBlend });
      dispatch({ type: ActionType.SET_ACTIVE_STEP, activeStep: activeStep + 1 });
    } else {
      Alert.error(ADD_INGREDIENT_ERROR_MESSAGE);
    }
  };

  const getActiveButtons = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box>
            {isDirty && <SaveAsDraftButton onClick={onSaveAsDraft} loading={loading} />}

            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowIcon />}
              onClick={handleSubmit(onSubmit)}>
              {NEXT_TEXT}
            </Button>
          </Box>
        );

      case 1:
        return (
          <Box display="flex" justifyContent="space-between" gap={2}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                dispatch({ type: ActionType.SET_ACTIVE_STEP, activeStep: activeStep - 1 });
              }}>
              {BACK_TEXT}
            </Button>
            <Box>
              <SaveAsDraftButton onClick={onSaveAsDraft} loading={loading} />

              <Button
                color="primary"
                variant="contained"
                disabled={loading}
                endIcon={<ArrowIcon />}
                onClick={dosageSubmit(onNext)}>
                {NEXT_TEXT}
              </Button>
            </Box>
          </Box>
        );

      case 2:
        return (
          <Box display="flex" justifyContent="space-between" gap={2}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                dispatch({ type: ActionType.SET_ACTIVE_STEP, activeStep: activeStep - 1 });
              }}>
              {BACK_TEXT}
            </Button>
            <Box>
              <SaveAsDraftButton onClick={onSaveAsDraft} loading={loading} />

              <Button variant="contained" color="primary" disabled={loading} onClick={handleSubmit(onSave)}>
                {SAVE_TEXT}
              </Button>
            </Box>
          </Box>
        );

      default:
        return <Fragment />;
    }
  };

  const { name: formulaNameInForm } = getFormulaInfo();

  const onClose = () => {
    setIsOpenDraft(false);
    setIsBlocker(true);
  };

  const loading = saveFormulaAsLoading || createFormulaLoading;

  return (
    <Fragment>
      <DialogBox
        formulaName={formulaNameInForm}
        cancelNavigation={blocker?.reset}
        confirmNavigation={blocker?.proceed}
        showDialog={blocker.state === 'blocked'}
        saveAndLeaveNavigation={saveFormulaAsDraftAndContinue}
      />
      <SaveAsDraftDialogBox
        onClose={onClose}
        open={isOpenDraft}
        formulaName={formulaNameInForm}
        onSave={saveFormulaAsDraftAndContinue}
      />
      <BackdropLoader open={loading} text={FORMULA_IS_CREATING_TEXT} />
      <Box sx={[flexCenterBetween, StickyTopHeader]}>
        <Typography variant="h5">
          {CREATE_FORMULA_TEXT} {BY_TEXT} {INGREDIENTS_TEXT}
        </Typography>
        {getActiveButtons()}
      </Box>
      <IngredientStepForm
        activeStep={activeStep}
        dosageMethods={dosageMethods}
        formulaMethods={formulaInfoMethods}
        ingredientMethods={ingredientMethods}
      />
    </Fragment>
  );
};

export default AddFormula;
