import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
//components
import EditUserForm from 'components/main/Users/Edit/EditUserForm';
//constants, interfaces, graphql
import { ParamType } from 'interfaces';
import { EDIT_USER_TEXT, HTTP_STATUS } from 'constants/index';
import { UserPayload, useGetUserLazyQuery } from 'generated/graphql';
//styles
import { flexCenterBetween } from 'styles/commonComponentStyle';

const EditUser: FC = () => {
  const { id } = useParams<ParamType>();
  const [user, setUser] = useState<UserPayload['user']>(null);

  const [getUser, { loading }] = useGetUserLazyQuery({
    onCompleted: (data) => {
      const { getUser } = data || {};
      const { response, user } = getUser || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setUser(user as UserPayload['user']);
      }
    },
    onError: () => {
      setUser(null);
    },
  });

  const fetchUser = useCallback(async () => {
    await getUser({
      variables: {
        getUserInput: { id: id ?? '' },
      },
    });
  }, [getUser, id]);

  useEffect(() => {
    id && fetchUser();
  }, [fetchUser, id]);

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{EDIT_USER_TEXT}</Typography>
      </Box>

      <EditUserForm loading={loading} id={id} user={user} />
    </Fragment>
  );
};

export default EditUser;
