import { SelectType } from 'interfaces';

// Text Constants
export const ACCEPT_TEXT = 'Accept';
export const ACTIVATE_TEXT = 'Activate';
export const ADD_BLEND_TEXT = 'Add Blend';
export const ADD_INGREDIENT_ERROR_MESSAGE = 'Please select at least 1 ingredient';
export const ADD_INGREDIENT_TEXT = 'Add Ingredient';
export const ADD_NEW_TEXT = 'Add New';
export const ADD_NEW_USER_TEXT = 'Add new user';
export const ADD_TEXT = 'Add';
export const ADD_CARRIER_TEXT = 'Add Carrier';
export const NO_CARRIER_TEXT = 'No Carriers';
export const NO_CARRIER_DESCRIPTION_TEXT = 'Click the button below to add';
export const ADD_SUPPLIER_TEXT = 'Add Supplier';
export const DUPLICATE_SUPPLIER_TEXT = 'Duplicate Supplier';
export const ADD_SUB_PRODUCT_TYPE_TEXT = 'Add Sub Product Type';
export const ADD_USER_TEXT = 'Add User';
export const ADDED_SUGAR_TEXT = 'Added Sugar (g)';
export const ALLERGENS_TEXT = 'Allergens';
export const CARRIERS_TEXT = 'Carriers';
export const SUB_COMPONENT_TEXT = 'Sub Component';
export const ADD_SUB_COMPONENT_TEXT = 'Add Sub Component';
export const NO_SUB_COMPONENT_TEXT = 'No Sub Components';
export const APP_LOADING_TEXT = 'App is loading please wait...';
export const ARSENIC_TEXT = 'Arsenic (mcg/serv)';
export const AVERAGE_PARTICLE_SIZE_TEXT = 'Average Particle Size';
export const BACK_TEXT = 'Back';
export const BEADLETS_TEXT = 'Beadlets';
export const BIOTIN_TEXT = 'Biotin (mcg)';
export const BLEND_NAME_TEXT = 'Blend Name';
export const BLEND_TEXT = 'Blend';
export const BORON_TEXT = 'Boron (mg)';
export const BOTTLE_CAP_TEXT = 'Bottle Cap';
export const BOTTLE_COST_TEXT = 'Bottle Cost';
export const BOTTLE_FILL_TEXT = 'Bottle Fill';
export const BOTTLE_TEXT = 'Bottle';
export const BULK_DENSITY_TEXT = 'Bulk Density';
export const BY_TEXT = 'By';
export const CADMIUM_TEXT = 'Cadmium (mcg/serv)';
export const CALCIUM_TEXT = 'Calcium (mg)';
export const CALCULATE_TEXT = 'Calculate';
export const CALORIES_TEXT = 'Calories (kCal)';
export const CANCEL_TEXT = 'Cancel';
export const FORM_TEXT = 'Form';
export const SYRUP_BASE_TEXT = 'Syrup Base';
export const CAPSULE_PRICE_TEXT = 'Capsule Price';
export const CAPSULE_TEXT = 'Capsule';
export const CAPSULES_TEXT = 'Capsules';
export const CARBOHYDRATES_TEXT = 'Carbohydrates (g)';
export const CELERY_TEXT = 'Celery';
export const CHILD_RESISTANT_TEXT = 'Child Resistant';
export const CHLORIDE_TEXT = 'Chloride (mg)';
export const CHOLESTEROL_TEXT = 'Cholesterol (mg)';
export const CHOLINE_TEXT = 'Choline (mg)';
export const CHROMIUM_TEXT = 'Chromium (mcg)';
export const CLEAR_TEXT = 'Clear';
export const CLOSE_TEXT = 'Close';
export const COATING_COLORS_HEX_TEXT = 'Coating Colors Hex';
export const COATING_COST_TEXT = 'Coating Cost';
export const COATING_TEXT = 'Coating';
export const SAND_COATING_TEXT = 'Sand Coating';
export const COATING_WEIGHT_TEXT = 'Coating Weight';
export const COLOR_TEXT = 'Color';
export const COMING_SOON_TEXT = 'Coming Soon';
export const COMMON_NAME_TEXT = 'Common Name';
export const CONFIRM_NEW_PASSWORD_TEXT = 'Confirm New Password';
export const CONFIRM_PASSWORD_MATCH = 'Passwords must match';
export const CONFIRM_PASSWORD_TEXT = 'Confirm Password';
export const CONTACT_TEXT = 'Contact';
export const ADDITIONAL_CONTACT_TEXT = 'Additional Contact';
export const CONVERSION_COST_TEXT = 'Conversion Cost';
export const COPPER_TEXT = 'Copper (mg)';
export const COST_DOLLAR_KG_TEXT = 'Cost ($/kg) ';
export const COST_PER_KG_TEXT = 'Cost (Per KG)';
export const COST_PER_MG_TEXT = 'Cost (Per MG)';
export const COST_PER_SERVING_TEXT = 'Cost Per Serving';
export const COST_TEXT = 'Cost ';
export const COST_VIA_CO_MFG = 'Cost via Co-MFG';
export const COST_VIA_MFG = 'Cost via MFG';
export const COUNTRY_OF_MANUFACTURING_TEXT = 'Country of Final Manufacturing';
export const COUNTRY_OF_ORIGIN_TEXT = 'Country of Origin';
export const COUNTRY_TEXT = 'Country';
export const STATE_TEXT = 'State';
export const CITY_TEXT = 'City';
export const CREATE_FORMULA_TEXT = 'Create Formula';
export const CREATED_AT_TEXT = 'Created At';
export const CREATED_BY_TEXT = 'Created By';
export const CREATED_INGREDIENTS_TEXT = 'Ingredients Created';
export const CRUSTACEANS_TEXT = 'Crustaceans';
export const CURRENT_MONTH_USERS_TEXT = 'Current Month Users';
export const DASHBOARD_TEXT = 'Dashboard';
export const DEACTIVATE_TEXT = 'Deactivate';
export const DECLINE_TEXT = 'Decline';
export const DEFINING_AMOUNT_TEXT = 'Defining Amount';
export const DESICCANT_TEXT = 'Desiccant';
export const DESIRED_SERVING_SIZE = 'Desired Serving Size';
export const DIETARY_FIBER_TEXT = 'Dietary Fiber (g)';
export const DISCARD_AND_LEAVE = 'Discard & Leave';
export const DOSAGE_TEXT = 'Dosage';
export const DUPLICATE_TEXT = 'Duplicate';
export const DUPLICATE_FORMULA_TEXT = 'Duplicate Formula';
export const EDIT_FORMULA_TEXT = 'Edit Formula';
export const EDIT_SUPPLIER_TEXT = 'Edit Supplier';
export const EDIT_PRODUCT_TYPE_TEXT = 'Edit Product Type';
export const EDIT_EXCIPIENT_TEXT = 'Edit Excipient & Carrier';
export const EDIT_EXCIPIENT_LABEL_TEXT = 'Edit Label Excipient';
export const ADD_EXCIPIENT_LABEL_TEXT = 'Add Label Excipient';
export const ADD_EXCIPIENT_TEXT = 'Add Excipient & Carrier';
export const EDIT_EXCIPIENT_TYPE_TEXT = 'Edit Excipient Type';
export const ADD_EXCIPIENT_TYPE_TEXT = 'Add Excipient Type';
export const EDIT_PRODUCT_EXCIPIENT_TEXT = 'Edit Product Excipient';
export const EDIT_SWEETENER_ELEMENT_UPDATED_TEXT = 'Sweetener Element is updated...';
export const CREATE_SWEETENER_ELEMENT_TEXT = 'Create Sweetener Element....';
export const EDIT_SWEETENER_ELEMENT_TEXT = 'Edit Sweetener Element';
export const ADD_PRODUCT_EXCIPIENT_TEXT = 'Add Product Excipient';
export const ADD_SWEETENER_ELEMENT_TEXT = 'Add Sweetener Element';
export const EDIT_PRODUCT_SIZE_TEXT = 'Edit Product Size';
export const ADD_PRODUCT_SIZE_TEXT = 'Add Product Size';
export const EDIT_SUB_PRODUCT_TYPE_TEXT = 'Edit Sub Product Type';
export const EDIT_PRODUCT_FORMULATION_TYPE_TEXT = 'Edit Product Formulation Type';
export const EDIT_INGREDIENT_TEXT = 'Edit Ingredient';
export const EDIT_STAFF_TEXT = 'Edit Staff';
export const EDIT_TEXT = 'Edit';
export const EDIT_USER_TEXT = 'Edit User';
export const EMAIL_ADDRESS_TEXT = 'Email address';
export const EMAIL_TEXT = 'Email';
export const ENTER_DETAIL_TEXT = 'Enter your details below';
export const EXCEL_SHEET_TEXT = ' Excel Sheet';
export const EXCIPIENT_COST_TEXT = 'Excipient Cost';
export const EXCIPIENT_LABEL_TEXT = 'Excipient Label';
export const EXCIPIENT_GENERATOR_TEXT = 'Excipient Generator';
export const EXCIPIENT_TEXT = 'Excipient';
export const EXCIPIENT_AND_CARRIER_TEXT = 'Excipient & Carrier';
export const EXCIPIENT_TYPES_TEXT = 'Excipient Type';
export const PRODUCT_EXCIPIENT_TEXT = 'Product Excipient';
export const LOAD_ADD_TEXT = 'Load Add';
export const LOAD_ADD_UNIT_TEXT = 'Load Add Unit';
export const PER_KG_COST_TEXT = 'Per KG Cost';
export const QT_PER_MG_TEXT = 'QT Per Mg';
export const PERCENTAGE_TEXT = 'Percentage';
export const EXCIPIENT_TYPE_TEXT = 'Choose the excipient profile';
export const EXCIPIENT_WEIGHT_TEXT = 'Excipient Weight';
export const EXPORT_TEXT = 'Export';
export const FACT_BOX_TEXT = 'Fact Box';
export const FACT_SHEET_TEXT = 'Fact Sheet';
export const FIRST_NAME_TEXT = 'First Name';
export const FISH_TEXT = 'Fish';
export const FLAVOR_COST_TEXT = 'Flavor Cost';
export const FLAVOR_SYSTEM_TEXT = 'Flavor System';
export const FLAVOR_TYPE_TEXT = 'Flavor Type';
export const FLAVOR_WEIGHT_TEXT = 'Flavor Weight';
export const FOLIC_ACID_TEXT = 'Folic Acid';
export const FOLATE_TEXT = `Folate/${FOLIC_ACID_TEXT} (mcg)`;
export const FOOD_FOLATE_TEXT = 'Food Folate (mcg)';
export const FORGOT_PASSWORD_HEADING = 'Forgot your password?';
export const FORGOT_PASSWORD_TEXT = 'Forgot password';
export const FORM_FILLED_ERROR_MESSAGE_TEXT = 'Please fill the form';
export const FORMULA_DETAILS_TEXT = 'Formula Details';
export const FORMULA_FETCH_TEXT = 'Formula is Fetching...';
export const FORMULA_IS_CREATING_TEXT = 'Formula is Creating...';
export const FORMULA_IS_UPDATING_TEXT = 'Formula is Updating...';
export const FORMULA_NAME_TEXT = 'Formula Name';
export const FORMULA_TEXT = 'Formula';
export const FORMULAS_CREATED_TEXT = 'Formulas Created';
export const FORMULATION_TEXT = 'Formulation';
export const GENERAL_TEXT = 'General';
export const GENERATE_PASSWORD_TEXT = 'Generate Random Password';
export const GLUTEN_TEXT = 'Gluten';
export const GO_TEXT = 'Go';
export const GO_TO_SIGNIN_BUTTON = 'Go To Sign In';
export const HEAVY_METALS_TEXT = 'Heavy Metals';
export const IMAGE_FORMAT_TEXT = 'Allowed *.jpeg, *.jpg, *.png, *.svg, *.gif';
export const IMAGE_MAX_SIZE_TEXT = 'Max size of 3 MB';
export const IMAGE_SIZE_ERROR_MESSAGE = 'Image cannot be more than 3 MB';
export const INFO_TEXT = 'Info';
export const INGREDIENT_ALREADY_EXIST_TEX = 'Ingredient Already added';
export const INGREDIENT_IS_CREATING_TEXT = 'Ingredient is Creating...';
export const SUPPLIER_IS_CREATING_TEXT = 'Supplier is Creating...';
export const SUB_PRODUCT_TYPE_IS_CREATING_TEXT = 'Sub Product Type is Creating...';
export const PRODUCT_TYPE_IS_UPDATED_TEXT = 'Product Type is updated...';
export const BOTTLE_SIZE_IS_UPDATED_TEXT = 'Bottle Size is updated...';
export const BOTTLE_SIZE_IS_CREATED_TEXT = 'Bottle Size is creating...';
export const EXCIPIENT_IS_UPDATED_TEXT = 'Excipient & Carrier is updated...';
export const EXCIPIENT_LABEL_IS_UPDATED_TEXT = 'Excipient Label is updated...';
export const EXCIPIENT_TYPE_IS_UPDATED_TEXT = 'Excipient Type is updating...';
export const EXCIPIENT_TYPE_IS_CREATING_TEXT = 'Excipient Type is creating...';
export const EXCIPIENT_LABEL_IS_CREATING_TEXT = 'Excipient Label is creating...';
export const EXCIPIENT_IS_CREATING_TEXT = 'Excipient & Carrier is creating...';
export const PRODUCT_EXCIPIENT_IS_UPDATED_TEXT = 'Product Excipient is updated...';
export const PRODUCT_EXCIPIENT_IS_CREATING_TEXT = 'Product Excipient is creating...';
export const SWEETENER_ELEMENT_IS_CREATING_TEXT = 'Sweetener Element is creating...';
export const PRODUCT_SIZE_IS_UPDATED_TEXT = 'Product Size is updated...';
export const PRODUCT_SIZE_IS_CREATING_TEXT = 'Product Size is creating...';
export const SUB_PRODUCT_TYPE_IS_UPDATED_TEXT = 'Sub Product Type is updated...';
export const PRODUCT_FORMULATION_TYPE_IS_UPDATED_TEXT = 'Product Formulation Type is updated...';
export const GO_TO_HOME_TEXT = 'Go to Home';
export const INGREDIENT_WEIGHT_TEXT = 'Ingredient Weight';
export const INGREDIENTS_COST_TEXT = 'Ingredients Cost';
export const INGREDIENTS_TEXT = 'Ingredients';
export const INNER_CAPSULE_POLYMER_TEXT = 'Type of Inner capsule polymer';
export const INNER_CAPSULE_PRICE_TEXT = 'Inner Capsule Price';
export const INNER_CAPSULE_TABLET_TEXT = 'Inner Capsule Tablet';
export const INNER_COMPONENT_COST_TEXT = 'Inner Component Cost';

export const INVALID_IMAGE_EXTENSION_MESSAGE = 'Invalid image extension';
export const IODINE_TEXT = 'Iodine (mcg)';
export const IRON_TEXT = 'Iron (mg)';
export const JOINING_DATE_TEXT = 'Joining Date';
export const MAX_STAFF = 'Max Staff';
export const ACTIVE_STAFF_TEXT = 'Active staff';
export const KOSHER_TEXT = 'Kosher';
export const LABEL_NAME_TEXT = 'Label Name';
export const LABEL_NAME_INFO = '(Herbs, Amino acids, Specialty, etc)';
export const LAST_NAME_TEXT = 'Last Name';
export const LAYER_1_TEXT = 'Layer 1';
export const LAYER_2_TEXT = 'Layer 2';
export const LAYER_3_TEXT = 'Layer 3';
export const LEAD_TEXT = 'Lead (mcg/serv)';
export const LID_TEXT = 'Lid';
export const LOG_IN_BUTTON_TEXT = 'Login';
export const LOG_OUT_BUTTON_TEXT = 'Log Out';
export const LOH_COST_TEXT = 'LOH Cost';
export const LOSS_ON_DRYING_TEXT = 'Loss on drying (LOD)';
export const LUPIN_TEXT = 'Lupin';
export const MAGNESIUM_TEXT = 'Magnesium (mg)';
export const MANAGEMENT_TEXT = 'Management';
export const MANGANESE_TEXT = 'Manganese (mg)';
export const MERCURY_TEXT = 'Mercury (mcg/serv)';
export const METRICS_TEXT = 'Metrics';
export const MG_TEXT = 'mg';
export const MILK_TEXT = 'Milk';
export const MINI_TABLET_COATING_TEXT = 'Mini-Tablet Coating';
export const MINI_TABLET_COLOR_TEXT = 'Mini-Tablet Color';
export const MINI_TABLETS_FORMULATION_TEXT = 'Mini-Tablets Formulation';
export const MOISTURE_IN_GRAMS_TEXT = 'Moisture (g)';
export const MOLLUSCS_TEXT = 'Molluscs';
export const MOLYBDENUM_TEXT = 'Molybdenum (mcg)';
export const MONOUNSATURATED_FAT_TEXT = 'Monounsaturated Fat (g)';
export const MUSTARD_TEXT = 'Mustard';
export const MY_FORMULAS_TEXT = 'My Formulas';
export const N_A_TEXT = 'N/A';
export const NAME_TEXT = 'Name';
export const ACTIVE_TEXT = 'Active';
export const INACTIVE_TEXT = 'Inactive';
export const COMPANY_NAME_TEXT = 'Company Name';
export const ZIP_CODE_TEXT = 'Zip Code';
export const DESIGNATION_TEXT = 'Designation';
export const NECKBAND_COST_TEXT = 'Neckband Cost';
export const NEW_PASSWORD_TEXT = 'New Password';
export const NEW_STAFF_TEXT = 'New Staff';
export const NEW_USERS_TEXT = 'New Users';
export const NEXT_TEXT = 'Next';
export const ADDRESS_1_TEXT = 'Address 1';
export const ADDRESS_2_TEXT = 'Address 2';
export const DESCRIPTION_TEXT = 'Description';
export const NIACIN_TEXT = 'Niacin (mg)';
export const NO_DATA_FOUND = 'No Records Found';
export const NO_INGREDIENT_TEXT = 'No Ingredients Added';
export const NO_UPLOAD_TEXT = 'No Upload';
export const NON_GMO_TEXT = 'NON-GMO';
export const NUTRIENTS_TEXT = 'Nutrients';
export const NUTRIENT_NAME_TEXT = 'Show source of nutrients';
export const SHOW_B3 = 'Show B3';
export const OLD_PASSWORD_TEXT = 'Old Password';
export const ORG_FORMULAS_TEXT = 'Organizational Formulas';
export const ORG_INGREDIENTS_TEXT = 'Organizational Ingredients';
export const ORGANIC_NUMERICAL_TEXT = '% Organic (numerical)';
export const ORGANIZATION_NAME_TEXT = 'Organization Name';
export const ORGANIZATION_TEXT = 'Organization';
export const OUTER_CAPSULE_POLYMER_TEXT = 'Type of Outer capsule polymer';
export const OUTER_CAPSULE_PRICE_TEXT = 'Outer Capsule Price';
export const PACKAGE_ESTIMATOR_TEXT = 'Package Estimator';
export const PANTOTHENIC_ACID_TEXT = 'Pantothenic Acid (mg)';
export const PASSWORD_SET_SUCCESS_TITLE = 'Password Successfully Set!';
export const PASSWORD_TEXT = 'Password';
export const PATH_FORWARD_LOGO_TEXT = 'Path Forward';
export const PEANUT_TEXT = 'Peanut';
export const PER_100_PRICE_TEXT = 'Per 1000 Costs';
export const PER_BOTTLE_COST_TEXT = 'Per Bottle Cost';
export const PHONE_TEXT = 'Phone';
export const PHOSPHORUS_TEXT = 'Phosphorus (mg)';
export const POLYUNSATURATED_FAT_TEXT = 'Polyunsaturated Fat (g)';
export const POTASSIUM_TEXT = 'Potassium (mg)';
export const POTENCY_TEXT = 'Potency';
export const POWDER_TEXT = 'Powder';
export const PRIMARY_CATEGORY_TEXT = 'Primary Category';
export const PRODUCT_FORMULATION_TEXT = 'Product Formulation';
export const PRODUCT_FORMULATION_TYPE_TEXT = 'Product Formulation Type';
export const PRODUCT_TYPE_TEXT = 'Product Type';
export const PRODUCT_TEXT = 'Product';
export const PROFILE_TEXT = 'Profile';
export const PROTEIN_BASE_TEXT = 'Protein Base';
export const PROTEIN_TEXT = 'Protein (g)';
export const RECENT_FORMULAS_TEXT = 'Recent Formulas';
export const RECOMMENDED_TEXT = 'Recommended';
export const REMOVE_IMAGE = 'Remove Image';
export const REMOVE_TEXT = 'Remove';
export const REQUEST_SENT_TITLE = 'Request sent successfully!';
export const RIBOFLAVIN_TEXT = 'Riboflavin (mg)';
export const ROLE_TEXT = 'Role';
export const SATURATED_FAT_TEXT = 'Saturated Fat (g)';
export const SAVE_AS_DRAFT_TEXT = 'Save as Draft';
export const SAVE_CHANGES_TEXT = 'Save Changes';
export const SAVE_DRAFT_AND_LEAVE_TEXT = 'Save Draft & Leave';
export const SAVE_TEXT = 'Save';
export const SCOPE_TEXT = 'Scope';
export const SCIENTIFIC_NAME_TEXT = 'Scientific / Botanical Name';
export const SCIENTIFIC_NAME_INFO = 'Label name for Vitamins and Minerals';
export const SEARCH_AND_ADD_INGREDIENTS_TEXT = 'Search & add ingredients';
export const SEARCH_TEXT = 'Search';
export const SECONDARY_CATEGORY_TEXT = 'Secondary Category';
export const SELENIUM_TEXT = 'Selenium (mcg)';
export const SERVING_PER_CONTAINER = 'Serving Per Container';
export const SERVING_TEXT = 'Serving';
export const SERVING_UNIT_TEXT = 'Serving Unit';
export const SESAME_SEEDS_TEXT = 'Sesame Seeds';
export const SET_PASSWORD_TEXT = 'Set Password';
export const SETTINGS_TEXT = 'Settings';
export const SHEET_NOT_FOUND_TEXT = 'Sheet not found';
export const SHELF_LIFE_TEXT = 'Shelf Life (Months) ';
export const SHELL_FISH = 'Shell Fish';
export const SIGN_IN_TEXT = 'Sign in';
export const SODIUM_TEXT = 'Sodium (mg)';
export const SOURCE_TEXT = 'Source';
export const SOY_TEXT = 'Soy';
export const SPEC_SHEET_TEXT = 'Spec Sheet';
export const STAFF_TEXT = 'Staff';
export const STATUS_TEXT = 'Status';
export const SUB_PRODUCT_TYPE_TEXT = 'Sub Product Type';
export const PRODUCT_SIZE_TEXT = 'Product Size';
export const SUB_TYPE_TEXT = 'Sub-Type';
export const SUGGESTED_OVERAGE_TEXT = 'Suggested Overage';
export const GUMMY_OVERAGE_TEXT = 'Gummy Overage';
export const SUGGESTED_SUPPLIER_TEXT = 'Suggested Supplier';
export const SUPPLIERS_TEXT = 'Suppliers';
export const SUPPLIER_TEXT = 'Supplier';
export const SULPHITES_TEXT = 'Sulphites';
export const SWEETENER_COST_TEXT = 'Sweetener Cost';
export const SWEETENER_SYSTEM_TEXT = 'Sweetener';
export const PRODUCT_INGREDIENT_TEXT = 'Product ingredient';
export const FORMULATION_SYRUP_TYPE_TEXT = 'Formulation syrup type';
export const FORMULATION_SYRUP_TYPE_INGREDIENT_TEXT = 'Ingredient syrup type';
export const SWEETENER_TYPE_TEXT = 'Sweetener Type';
export const SWEETENER_WEIGHT_TEXT = 'Sweetener Weight';
export const SWEETENER_COMPONENT_TEXT = 'Sweetener Component';
export const TABLET_COATING_TEXT = 'Tablet Coating';
export const TABLET_COLOR_TEXT = 'Tablet Color';
export const TABLET_SIZE_TEXT = 'Tablet Size';
export const TABLET_TEXT = 'Tablet';
export const TABLET_TYPE_TEXT = 'Tablet Type';
export const TAPPED_DENSITY_TEXT = 'Tapped Density';
export const TERMS_OF_USE_TEXT = 'Terms of use';
export const THIAMIN_TEXT = 'Thiamin (mg)';
export const TOKEN_NOT_FOUND_TEXT = 'Token not found';
export const TOP_CONTRIBUTOR_TEXT = 'Top Contributor';
export const TOTAL_FAT_TEXT = 'Total Fat (g)';
export const TOTAL_INGREDIENTS_TEXT = 'Total Ingredients';
export const TOTAL_STAFF_TEXT = 'Total Staff';
export const TOTAL_SUGARS_TEXT = 'Total Sugars (g)';
export const TOTAL_USERS_TEXT = 'Total Users';
export const TOTAL_WEIGHT_TEXT = 'Total Weight';
export const TRADEMARK_TEXT = 'Trademark Name';
export const TRANS_FAT_TEXT = 'Trans Fat (g)';
export const TREE_NUTS_TEXT = 'Tree Nuts';
export const TRYPTOPHAN_TEXT = 'Tryptophan';
export const TYPE_OF_CAPSULE_POLYMER_TEXT = 'Type of Capsule Polymer';
export const TYPE_TEXT = 'Type';
export const UNIT_TEXT = 'Unit';
export const UNSAVED_FORMULA_TEXT = 'Unsaved Formula';
export const UPDATE_BLEND_TEXT = 'Update Blend';
export const UPDATE_IMAGE = 'Update Image';
export const UPLOAD_IMAGE = 'Upload photo';
export const UPLOAD_IMAGE_TEXT = 'Upload Image';
export const USERS_TEXT = 'Users';
export const VALUE_TEXT = 'Value';
export const LABEL_CLAIM_TEXT = 'Label Claim';
export const VEGAN_TEXT = 'Vegan';
export const VIEW_ALL_TEXT = 'View All';
export const VIEW_TEXT = 'View';
export const VITAMIN_A_TEXT = 'Vitamin A (mcg)';
export const VITAMIN_B12_TEXT = 'Vitamin B12 (mcg)';
export const VITAMIN_B6_TEXT = 'Vitamin B6 (mg)';
export const VITAMIN_C_TEXT = 'Vitamin C (mg)';
export const VITAMIN_D_TEXT = 'Vitamin D (mcg)';
export const VITAMIN_E_TEXT = 'Vitamin E (mg)';
export const VITAMIN_K2_TEXT = 'Vitamin K2 (mg)';
export const WEIGHT_TEXT = 'Weight';
export const COST_PERCENTAGE_TEXT = 'Cost %';
export const WEIGHTS_PERCENTAGE_TEXT = 'Weight %';
export const WHEAT_TEXT = 'Wheat';
export const WORK_IN_FORMAT_TEXT = 'Works in Format';
export const ZINC_TEXT = 'Zinc (mg)';
export const OPTIONAL_TEXT = 'Optional';
export const PAGE_NOT_FOUND_TEXT = 'Sorry, page not found!';
export const EMPTY_WEIGHT_TEXT = 'Empty Weight';
export const GUMMY_WEIGHT_TEXT = 'Gummy Weight';
export const MIN_TEXT = 'Min (mg)';
export const MAX_TEXT = 'Max (mg)';
export const BOTTLE_SIZE_TEXT = 'Bottle Size';
export const LID_SIZE_TEXT = 'Lid Size';
export const CC_TEXT = 'CC';
export const OZ_TEXT = 'OZ';
export const GRAMS_TEXT = 'Grams';
export const EDIT_BOTTLE_SIZE_TEXT = 'Edit Bottle Size';
export const ADD_BOTTLE_SIZE_TEXT = 'Add Bottle Size';
export const EDIT_BOTTLE_PRICE_TEXT = 'Edit Bottle Price';
export const EDIT_BOTTLE_CAPACITY_TEXT = 'Edit Bottle Capacity';
export const ADD_BOTTLE_CAPACITY_TEXT = 'Add Bottle Capacity';
export const EDIT_SWEETENER_TEXT = 'Edit Sweetener';
export const ADD_SWEETENER_TEXT = 'Add Sweetener';
export const EDIT_SWEETENER_COMPONENT_TEXT = 'Edit Sweetener Component';
export const ADD_SWEETENER_COMPONENT_TEXT = 'Add Sweetener Component';
export const BOTTLE_PRICE_IS_UPDATED_TEXT = 'Bottle Price is updated...';
export const BOTTLE_PRICE_IS_CREATING_TEXT = 'Bottle Price is creating...';
export const BOTTLE_PRICE_TEXT = 'Bottle Price';
export const ADD_BOTTLE_PRICE_TEXT = 'Add Bottle Price';
export const BOTTLE_CAPACITY_TEXT = 'Bottle Capacity';
export const PRICE_TEXT = 'Price';
export const IS_CR_TEXT = 'Is CR';
export const APPLY_TEXT = 'Apply';
export const IS_CHILD_RESISTANT_TEXT = 'Is Child Resistant';
export const SERVING_SIZE_TEXT = 'Serving Size';
export const FILING_PERCENTAGE_TEXT = 'Filing Percentage';
export const BOTTLE_CAPACITY_IS_UPDATED_TEXT = 'Bottle Capacity is updated...';
export const BOTTLE_CAPACITY_IS_CREATING_TEXT = 'Bottle Capacity is creating...';
export const SWEETENER_IS_UPDATED_TEXT = 'Sweetener is updated...';
export const SWEETENER_IS_CREATED_TEXT = 'Sweetener is creating...';
export const QT_MG_TEXT = 'QT Mg';
export const SWEETENER_COMPONENT_IS_UPDATED_TEXT = 'Sweetener component is updated...';
export const SWEETENER_COMPONENT_IS_CREATING_TEXT = 'Sweetener component is creating...';

export const NATURE_TEXT = 'Nature';
export const SIZE_TEXT = 'Size';
export const MG_PER_SERVING_TEXT = 'Mg Per Serving';
export const PER_SERVING_COST_TEXT = 'Per Serving Cost';
export const SWEETENER_ELEMENT_TEXT = 'Sweetener Element';

// messages

export const DEMO_ACCOUNT_TEXT = 'You are currently using a demo account';
export const LIMIT_UPTO_300_MSG = 'Please limit your input upto 300 characters';
export const INGREDIENTS_VALUE_ERROR_MESSAGE_TEXT = 'Please enter Valid value for all ingredients';
export const ADD_BLEND_ERROR_MESSAGE = 'Please select at least 1 ingredient to create blend';
export const UNSAVED_FORMULA_MESSAGE =
  'Are you sure you want to leave this page? You have unsaved data in your formula.';
export const FORGOT_PASSWORD_SUBTITLE =
  "Please enter the email address associated with your account, and we'll email you a link to reset your password.";
export const REQUEST_SENT_SUBTITLE =
  "We've sent a password reset link to your email. Please check and reset your password by using that link.";
export const PASSWORD_SET_SUCCESS_SUBTITLE =
  'Your new password has been securely saved. Please make sure to remember this password, as it will be necessary to log in to your account in the future.';
export const PAGE_NOT_FOUND_MSG_TEXT = `Sorry, we couldn't find the page you're looking for. Perhaps you've mistyped the URL? Be sure to check your spellings.`;

export const OVERAGE_INFO_MESSAGE = 'Enter any number from 0 to 1 as overage';

export const CONTACT_UPDATE_SUCCESS_MESSAGE = 'Contact updated successfully.';

// type

export const TAB_QUERY_TYPE = 'tab';
export const AGREEMENT_CHECKBOX_TEXT =
  'I agree to the terms and conditions as set out by the user agreement.';
export const AGREEMENT_PARA_2_TEXT = `Please read these Terms of Use carefully before accessing or using this Site. All information,
features, products, and services available on this Site are conditioned upon your acceptance of
these Terms of Use. If you do not agree to be bound by these Terms of Use, please do not use this
Site. Path Forward Formulator reserves the right to change any part of these Term of Use, and its
Privacy Policy, at any time without notice. Your continued use of this Site constitutes your
agreement to these Terms of Use.`;
export const AGREEMENT_PARA_1_TEXT = `This website (the “Site”) is owned and operated by Path Forward Formulator, LLC, an Illinois limited
liability company (“Path Forward Formulator,” “us,” “we” or “our”). Whether you are a browser,
vendor, supplier, customer, client, or merchant, these terms of use (“Terms of Use”) apply to your
use of this Site. Additionally, your use of this Site is governed by the Privacy Policy, which is
incorporated herein by reference.`;

// controller ids

export const HELPER_TEXT = 'helper-text';
export const ADD_EMPTY: SelectType = {
  name: '--',
  value: '',
};
