import { FC, JSX } from 'react';
import { Grid } from '@mui/material';
// components
import InputController from 'controller/InputController';
import SupplierSelector from 'components/common/selectors/supplier';
import ExcipientSelector from 'components/common/selectors/excipient';
// constants, styles, interfaces
import { ProductExcipientFormProps } from 'interfaces';
import { forInputLabelBg } from 'styles/commonComponentStyle';
import { EXCIPIENT_TEXT, PER_KG_COST_TEXT, PERCENTAGE_TEXT, SUGGESTED_SUPPLIER_TEXT } from 'constants/index';

const ProductExcipientForm: FC<ProductExcipientFormProps> = ({ loading = false }): JSX.Element => {
  return (
    <Grid container spacing={2} sx={forInputLabelBg}>
      <Grid item xs={12} lg={12}>
        <InputController
          isRequired
          fieldType="text"
          name="percentage"
          disabled={loading}
          title={PERCENTAGE_TEXT}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <InputController
          isRequired
          fieldType="text"
          name="perKgCost"
          disabled={loading}
          title={PER_KG_COST_TEXT}
        />
      </Grid>

      <Grid item xs={12} lg={12}>
        <ExcipientSelector isRequired title={EXCIPIENT_TEXT} disabled={loading} name="excipient" />
      </Grid>

      <Grid item xs={12} lg={12}>
        <SupplierSelector title={SUGGESTED_SUPPLIER_TEXT} name="suppliers" isMultiple />
      </Grid>
    </Grid>
  );
};

export default ProductExcipientForm;
