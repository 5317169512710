import { FC } from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
// components
import PhoneInput from 'react-phone-input-2';
// styles
import 'react-phone-input-2/lib/material.css';
// interfaces
import { HELPER_TEXT } from 'constants/index';
import { PhoneInputControllerProps } from 'interfaces';

const PhoneInputController: FC<PhoneInputControllerProps> = ({
  name,
  title,
  disabled,
  margin = '8px',
  isRequired = false,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { name, onBlur, onChange, value } = field;
        const { error } = fieldState;
        const { message } = error || {};
        return (
          <FormControl
            fullWidth
            error={!!error}
            className={!!error ? 'phoneInput phoneInputError' : 'phoneInput'}>
            <PhoneInput
              country={'us'}
              value={value}
              disableDropdown
              onBlur={onBlur}
              isValid={!!error}
              onChange={onChange}
              specialLabel={isRequired ? `${title} *` : title}
              disabled={disabled}
              inputProps={{
                name: name,
                autoComplete: 'none',
              }}
              containerStyle={{
                marginTop: margin,
              }}
            />
            {message ? <FormHelperText id={`${name}-${HELPER_TEXT}`}>{message}</FormHelperText> : <></>}
          </FormControl>
        );
      }}
    />
  );
};

export default PhoneInputController;
