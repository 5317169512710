import { FC, Fragment } from 'react';
//components
import AdminSidebar from './AdminSidebar';
import StaffSidebar from './StaffSidebar';
import SuperAdminSidebar from './SuperAdminSidebar';
// Guards
import { AdminGuard } from 'routes/Guards/AdminGuard';
import { StaffGuard } from 'routes/Guards/StaffGuard';
import { SuperAdminGuard } from 'routes/Guards/SuperAdminGuard';

const Sidebar: FC = (): JSX.Element => {
  return (
    <Fragment>
      <SuperAdminGuard>
        <SuperAdminSidebar />
      </SuperAdminGuard>

      <AdminGuard>
        <AdminSidebar />
      </AdminGuard>

      <StaffGuard>
        <StaffSidebar />
      </StaffGuard>
    </Fragment>
  );
};

export default Sidebar;
