import { SelectType } from 'interfaces';
import { ProductFormulationSyrupTypesPayload } from 'generated/graphql';
import { PAGE_LIMIT, TabEnum, selectTypeInitialValues } from 'constants/index';

export type State = {
  page: number;
  size: string;
  count: number;
  search: string;
  activeTab: TabEnum;
  rowsPerPage: number;
  productType: SelectType;
  productSize: SelectType;
  subProductType: SelectType;
  productFormulation: SelectType;
  data: ProductFormulationSyrupTypesPayload['data'];
};

export const initialState: State = {
  page: 1,
  count: 0,
  data: [],
  search: '',
  size: '',
  rowsPerPage: PAGE_LIMIT,
  activeTab: TabEnum.Active,
  productType: selectTypeInitialValues,
  productSize: selectTypeInitialValues,
  subProductType: selectTypeInitialValues,
  productFormulation: selectTypeInitialValues,
};

export enum ActionType {
  SET_PAGE = 'setPage',
  SET_DATA = 'setData',
  SET_COUNT = 'setCount',
  SET_SEARCH = 'setSearch',
  SET_ACTIVE_TAB = 'setActiveTab',
  SET_SIZE = 'setSize',
  SET_SUB_PRODUCT_TYPE = 'setNature',
  SET_PRODUCT_TYPE = 'setProductType',
  SET_PRODUCT_SIZE = 'setProductSize',
  SET_PRODUCT_FORMULATION = 'setProductFormulation',
  SET_ROWS_PER_PAGE = 'setRowsPerPage',
}

export type Action =
  | { type: ActionType.SET_PAGE; page: number }
  | { type: ActionType.SET_COUNT; count: number }
  | { type: ActionType.SET_ROWS_PER_PAGE; rowsPerPage: number }
  | { type: ActionType.SET_SEARCH; search: string }
  | { type: ActionType.SET_SIZE; size: string }
  | { type: ActionType.SET_SUB_PRODUCT_TYPE; subProductType: SelectType }
  | { type: ActionType.SET_PRODUCT_TYPE; productType: SelectType }
  | { type: ActionType.SET_PRODUCT_SIZE; productSize: SelectType }
  | { type: ActionType.SET_PRODUCT_FORMULATION; productFormulation: SelectType }
  | { type: ActionType.SET_DATA; data: ProductFormulationSyrupTypesPayload['data'] }
  | { type: ActionType.SET_ACTIVE_TAB; activeTab: TabEnum };

export const formulationSyrupTypeReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case ActionType.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
      };

    case ActionType.SET_COUNT:
      return {
        ...state,
        count: action.count,
      };

    case ActionType.SET_DATA:
      return {
        ...state,
        data: action.data,
      };

    case ActionType.SET_SEARCH:
      return {
        ...state,
        search: action.search,
      };

    case ActionType.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      };

    case ActionType.SET_SIZE:
      return {
        ...state,
        size: action.size,
      };

    case ActionType.SET_SUB_PRODUCT_TYPE:
      return {
        ...state,
        subProductType: action.subProductType,
      };

    case ActionType.SET_PRODUCT_TYPE:
      return {
        ...state,
        productType: action.productType,
      };

    case ActionType.SET_PRODUCT_SIZE:
      return {
        ...state,
        productSize: action.productSize,
      };

    case ActionType.SET_PRODUCT_FORMULATION:
      return {
        ...state,
        productFormulation: action.productFormulation,
      };
  }
};
