import { ExcipientTypePayload, ProductExcipientPayload, ProductExcipientsPayload } from 'generated/graphql';

export type State = {
  addOpen: boolean;
  editOpen: boolean;
  data: ProductExcipientsPayload['data'];
  excipientType: ExcipientTypePayload['excipientType'];
  productExcipient: ProductExcipientPayload['productExcipient'];
};

export const initialState: State = {
  data: [],
  addOpen: false,
  editOpen: false,
  excipientType: null,
  productExcipient: null,
};

export enum ActionType {
  SET_DATA = 'setData',
  SET_ADD_OPEN = 'setAddOpen',
  SET_EDIT_OPEN = 'setEditOpen',
  SET_ACTIVE_TAB = 'setActiveTab',
  SET_ROWS_PER_PAGE = 'setRowsPerPage',
  SET_EXCIPIENT_TYPE = 'setExcipientType',
  SET_PRODUCT_EXCIPIENT = 'setProductExcipient',
}

export type Action =
  | { type: ActionType.SET_ADD_OPEN; addOpen: boolean }
  | { type: ActionType.SET_EDIT_OPEN; editOpen: boolean }
  | { type: ActionType.SET_DATA; data: ProductExcipientsPayload['data'] }
  | { type: ActionType.SET_EXCIPIENT_TYPE; excipientType: ExcipientTypePayload['excipientType'] }
  | { type: ActionType.SET_PRODUCT_EXCIPIENT; productExcipient: ProductExcipientPayload['productExcipient'] };

export const ProductExcipientReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_DATA:
      return {
        ...state,
        data: action.data,
      };

    case ActionType.SET_ADD_OPEN:
      return {
        ...state,
        addOpen: action.addOpen,
      };

    case ActionType.SET_EDIT_OPEN:
      return {
        ...state,
        editOpen: action.editOpen,
      };

    case ActionType.SET_EXCIPIENT_TYPE:
      return {
        ...state,
        excipientType: action.excipientType,
      };

    case ActionType.SET_PRODUCT_EXCIPIENT:
      return {
        ...state,
        productExcipient: action.productExcipient,
      };
  }
};
