import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import ProductTypeForm from './components/ProductTypeForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  ProductTypePayload,
  useGetProductTypeLazyQuery,
  useUpdateProductTypeMutation,
} from 'generated/graphql';
import {
  HTTP_STATUS,
  EDIT_PRODUCT_TYPE_TEXT,
  productTypeInitialValue,
  PRODUCT_TYPE_IS_UPDATED_TEXT,
} from 'constants/index';
import { AddProductTypeFormType, ParamType } from 'interfaces';
import { updateProductTypeSchema } from 'validationSchema';

const Edit: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const productTypeMethods = useForm<AddProductTypeFormType>({
    defaultValues: productTypeInitialValue,
    resolver: yupResolver(updateProductTypeSchema),
  });

  const { handleSubmit: handleProductTypeSubmit, setValue: setProductTypeValue } = productTypeMethods;

  const [updateProductType, { loading: productTypeLoading }] = useUpdateProductTypeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateProductType } = data;
      const { response } = updateProductType || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getProductType, { loading: getLoading }] = useGetProductTypeLazyQuery({
    onCompleted: (data) => {
      const { getProductType } = data || {};
      const { response, productType } = getProductType || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(productType as ProductTypePayload['productType']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });
  /**
   * Datas on supplier submit
   * @param data
   */
  const onProductTypeSubmit: SubmitHandler<AddProductTypeFormType> = async (data) => {
    const { name } = data || {};

    await updateProductType({
      variables: {
        updateProductTypeInput: {
          id: id || '',
          name,
        },
      },
    });
  };

  const fetchProductType = useCallback(async () => {
    id &&
      (await getProductType({
        variables: {
          getProductTypeInput: {
            id,
          },
        },
      }));
  }, [id, getProductType]);

  const setValues = (params: ProductTypePayload['productType']) => {
    const { name } = params || {};
    setProductTypeValue('name', name || '');
  };

  useEffect(() => {
    id && fetchProductType();
  }, [id, fetchProductType]);

  const loading = productTypeLoading || getLoading;

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{EDIT_PRODUCT_TYPE_TEXT}</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={6} md={12}>
          <FormProvider {...productTypeMethods}>
            <form onSubmit={handleProductTypeSubmit(onProductTypeSubmit)}>
              <ProductTypeForm loading={loading} isEdit />
            </form>
          </FormProvider>
        </Grid>
      </Grid>

      <BackdropLoader open={loading} text={PRODUCT_TYPE_IS_UPDATED_TEXT} />
    </Fragment>
  );
};

export default Edit;
