import { FC, Fragment } from 'react';
import { Add as AddIcon, Delete } from '@mui/icons-material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Button, Card, Grid, IconButton, Link, Typography } from '@mui/material';
// component
import DataLoader from 'components/common/DataLoader';
import InputController from 'controller/InputController';
import NoDataCard from 'components/common/NoDataCard';
import ExcipientSelector from 'components/common/selectors/excipient';
// styles, interface, constants
import { AddNewIngredientFormType, CarriersTabProps } from 'interfaces';
import { forInputLabelBg, textDecoration } from 'styles/commonComponentStyle';
import { displayFlexAlignCenter, flexCenterJustifyBetween } from 'theme/styleConstant';
import {
  ADD_TEXT,
  SAVE_TEXT,
  NAME_TEXT,
  PERCENTAGE_TEXT,
  carrierSelectTypeInitialValue,
} from 'constants/index';

const CarriersCard: FC<CarriersTabProps> = ({ loading, isEdit }) => {
  const { control, watch } = useFormContext<AddNewIngredientFormType>();
  const { carriers } = watch();

  const { fields, remove, append } = useFieldArray({
    name: 'carriers',
    control,
  });

  const handleAddItem = () => {
    append(carrierSelectTypeInitialValue);
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };

  return (
    <Card sx={forInputLabelBg}>
      {loading ? (
        <DataLoader columns={6} rows={21} />
      ) : (
        <>
          {fields?.length ? (
            <Grid container spacing={3}>
              {fields?.map((item, index) => (
                <Fragment key={item.id}>
                  <Grid item xs={12} lg={6}>
                    <ExcipientSelector
                      isRequired
                      carriers={carriers}
                      title={NAME_TEXT}
                      disabled={loading}
                      name={`carriers.[${index}].excipient`}
                    />
                  </Grid>

                  <Grid item xs={12} lg={5}>
                    <InputController
                      isRequired
                      fieldType="number"
                      disabled={loading}
                      title={PERCENTAGE_TEXT}
                      name={`carriers.[${index}].percentage`}
                    />
                  </Grid>

                  <Grid item xs={12} lg={1} mt={1}>
                    <IconButton color="error" aria-label="delete" onClick={() => handleRemoveItem(index)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          ) : (
            <NoDataCard />
          )}
          <Box sx={flexCenterJustifyBetween} mt={1.5}>
            <Link
              component="button"
              type="button"
              disabled={loading}
              onClick={handleAddItem}
              sx={[displayFlexAlignCenter, textDecoration]}>
              <AddIcon />{' '}
              <Typography variant="caption" pl={0.5} fontWeight={600}>
                {ADD_TEXT}
              </Typography>
            </Link>

            {isEdit && (
              <Button type="submit" variant="contained" id="allergen-btn" size="small" disabled={loading}>
                {SAVE_TEXT}
              </Button>
            )}
          </Box>
        </>
      )}
    </Card>
  );
};

export default CarriersCard;
