// imports
import { Controller, useFormContext } from 'react-hook-form';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, MenuItem, TextField } from '@mui/material';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
//interfaces , constants , helper
import { renderServingSize } from 'lib/helper';
import { SelectType, ServingSizeSelectorProps } from 'interfaces';
import { HTTP_STATUS, selectTypeInitialValues } from 'constants/index';
import { ServingSizesPayload, useFindAllServingSizeSelectorLazyQuery } from 'generated/graphql';

const ServingSizeSelector: FC<ServingSizeSelectorProps> = ({
  name,
  title,
  disabled,
  isRequired,
  defaultValue,
  handleChange,
  margin = 'dense',
  isMultiple = false,
  isClearable = true,
}) => {
  const [options, setOptions] = useState<ServingSizesPayload['data']>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const updatedOptions = [...renderServingSize(options ?? [])];
  const { control } = useFormContext();

  const [findAllServingSize, { loading }] = useFindAllServingSizeSelectorLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllServingSize } = data;
      const { data: servingSize, response } = findAllServingSize || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setOptions(servingSize as ServingSizesPayload['data']);
      }
    },
    onError: () => {
      setOptions([]);
    },
  });

  const fetchAllServingSize = useCallback(async () => {
    await findAllServingSize({
      variables: {
        findAllServingSizesInput: {
          paginationOptions: { page: 1, limit: 10 },
          search: searchQuery,
        },
      },
    });
  }, [findAllServingSize, searchQuery]);

  useEffect(() => {
    (!searchQuery.length || searchQuery.length > 1) && fetchAllServingSize();
  }, [fetchAllServingSize, searchQuery.length]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { name, onBlur, onChange, value, ref } = field;
        const { error } = fieldState;
        const { message } = error || {};
        return (
          <Autocomplete
            ref={ref}
            multiple={isMultiple}
            onBlur={onBlur}
            disableClearable={!isClearable}
            disabled={disabled}
            options={updatedOptions}
            value={isMultiple ? (value as SelectType[]) : (value as SelectType)}
            onChange={(_, value) => {
              onChange(value ?? selectTypeInitialValues);
              handleChange && handleChange(value ?? selectTypeInitialValues);
            }}
            popupIcon={<KeyboardArrowDownRoundedIcon />}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={({ value: option }, { value }) => value === option}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                margin={margin}
                variant="outlined"
                helperText={message}
                error={Boolean(error)}
                defaultValue={defaultValue}
                label={isRequired ? `${title} *` : title}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}>
                {updatedOptions?.map((item) => {
                  const { name, value } = item;
                  return (
                    <MenuItem key={value} value={value}>
                      {name}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          />
        );
      }}
    />
  );
};

export default ServingSizeSelector;
