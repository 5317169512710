import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import ProductIngredientForm from './FormCard';
import { Alert } from 'components/common/Alert';
import DataLoader from 'components/common/DataLoader';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  ProductIngredientPayload,
  useGetProductIngredientLazyQuery,
  useCreateProductIngredientMutation,
  useUpdateProductIngredientMutation,
} from 'generated/graphql';
import {
  ADD_TEXT,
  HTTP_STATUS,
  PRODUCT_INGREDIENT_TEXT,
  productIngredientInitialValue,
} from 'constants/index';
import { productIngredientSchema } from 'validationSchema';
import { ProductIngredientFormType, ParamType } from 'interfaces';
import { useAddTitleText, useUpdateTitleText, useAddLoadingText, useUpdateLoadingText } from '../../../hooks';

const Edit: FC = (): JSX.Element => {
  const addText = useAddTitleText(PRODUCT_INGREDIENT_TEXT);
  const updateText = useUpdateTitleText(PRODUCT_INGREDIENT_TEXT);
  const creatingText = useAddLoadingText(PRODUCT_INGREDIENT_TEXT);
  const updatingText = useUpdateLoadingText(PRODUCT_INGREDIENT_TEXT);

  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const methods = useForm<ProductIngredientFormType>({
    defaultValues: productIngredientInitialValue,
    resolver: yupResolver(productIngredientSchema),
  });

  const { handleSubmit, setValue } = methods;

  const [createProductIngredient, { loading: createLoading }] = useCreateProductIngredientMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createProductIngredient } = data;
      const { response } = createProductIngredient || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updateProductIngredient, { loading: updateLoading }] = useUpdateProductIngredientMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateProductIngredient } = data;
      const { response } = updateProductIngredient || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getProductIngredient, { loading: getLoading }] = useGetProductIngredientLazyQuery({
    onCompleted: (data) => {
      const { getProductIngredient } = data || {};
      const { response, productIngredient } = getProductIngredient || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(productIngredient as ProductIngredientPayload['productIngredient']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });

  const onSubmit: SubmitHandler<ProductIngredientFormType> = async (data) => {
    const { name } = data || {};

    if (id) {
      await updateProductIngredient({
        variables: {
          updateProductIngredientInput: {
            id: id || '',
            name,
          },
        },
      });
    } else {
      await createProductIngredient({
        variables: {
          createProductIngredientInput: {
            name,
          },
        },
      });
    }
  };

  const fetchProductIngredient = useCallback(async () => {
    id &&
      (await getProductIngredient({
        variables: {
          getProductIngredientInput: {
            id,
          },
        },
      }));
  }, [id, getProductIngredient]);

  const setValues = (params: ProductIngredientPayload['productIngredient']) => {
    const { name } = params || {};

    setValue('name', name || '');
  };

  useEffect(() => {
    id && fetchProductIngredient();
  }, [id, fetchProductIngredient]);

  const loading = updateLoading || createLoading;

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{id ? updateText : addText}</Typography>
            {!id && (
              <Button variant="contained" type="submit">
                {ADD_TEXT}
              </Button>
            )}
          </Box>

          {getLoading ? (
            <DataLoader columns={12} rows={6} />
          ) : (
            <ProductIngredientForm loading={loading} isEdit={!!id} />
          )}
        </form>
      </FormProvider>

      <BackdropLoader open={loading} text={id ? updatingText : creatingText} />
    </Fragment>
  );
};

export default Edit;
