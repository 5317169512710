import { Box, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
// component
import ClearComponent from 'components/common/ClearComponent';
import RadioGroupController from 'controller/RadioGroupController';
// interfaces, constants, helper, graphql
import { HTTP_STATUS, OPTIONAL_TEXT } from 'constants/index';
import { renderExcipientLabel } from 'lib/helper';
import { flexCenterJustifyBetween } from 'theme/styleConstant';
import { ExcipientLabelSelectorProps, SelectType } from 'interfaces';
import { ExcipientLabelsPayload, useFindAllExcipientLabelsLazyQuery } from 'generated/graphql';

const ExcipientLabelSelector: FC<ExcipientLabelSelectorProps> = ({
  name,
  title,
  onClear,
  isRequired = false,
  loading: getLoading,
  onChange: handleOnChange,
}) => {
  const [options, setOptions] = useState<SelectType[]>([]);

  const [findAllExcipientLabel, { loading: getProductTypesLoading }] = useFindAllExcipientLabelsLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllExcipientLabels } = data;
      const { response, data: excipient } = findAllExcipientLabels || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setOptions(renderExcipientLabel(excipient as ExcipientLabelsPayload['data']));
      } else {
        setOptions([]);
      }
    },

    onError: () => {
      setOptions([]);
    },
  });

  const fetchProductTypes = useCallback(async () => {
    await findAllExcipientLabel({
      variables: {
        findAllExcipientLabelsInput: {
          paginationOptions: {
            limit: 10,
            page: 1,
          },
          isActive: true,
        },
      },
    });
  }, [findAllExcipientLabel]);

  useEffect(() => {
    fetchProductTypes();
  }, [fetchProductTypes]);

  const loading = getProductTypesLoading || getLoading;

  const label = onClear ? (
    <Box sx={flexCenterJustifyBetween}>
      <Box>
        {title}
        <ClearComponent onClick={onClear} />
      </Box>

      <Typography color="primary">{OPTIONAL_TEXT}</Typography>
    </Box>
  ) : (
    title
  );

  return (
    <RadioGroupController
      name={name}
      title={label}
      loaderRows={1}
      loading={loading}
      loaderColumns={4}
      options={options}
      showTitle={!!options?.length}
      isRequired={isRequired}
      handleOnChange={handleOnChange}
    />
  );
};

export default ExcipientLabelSelector;
