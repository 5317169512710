import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import ContactForm from './components/ContactForm';
import SupplierForm from './components/SupplierForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
import AdditionalContactForm from './components/AdditionalContact';
// Schema, styles, graphql, constants
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { additionalContactsSchema, contactSchema, supplierFormSchema } from 'validationSchema';
import {
  AdditionalContactFormType,
  AdditionalContactSelectType,
  ContactFormType,
  ParamType,
  SupplierFormType,
} from 'interfaces';
import {
  ContactType,
  ContactableType,
  SupplierPayload,
  useGetSupplierLazyQuery,
  useUpdateSupplierMutation,
  useUpdateContactByContactableIdMutation,
  useCreateContactMutation,
  useUpdateAdditionalContactMutation,
} from 'generated/graphql';
import {
  HTTP_STATUS,
  EDIT_SUPPLIER_TEXT,
  contactInitialValues,
  supplierInitialValues,
  SUPPLIER_IS_CREATING_TEXT,
  CONTACT_UPDATE_SUCCESS_MESSAGE,
} from 'constants/index';
import { formatAdditionalContact } from 'lib/helper';

const Edit: FC = (): JSX.Element => {
  const params = useParams<ParamType>();
  const { id } = params || {};

  const supplierMethods = useForm<SupplierFormType>({
    defaultValues: supplierInitialValues,
    resolver: yupResolver(supplierFormSchema),
  });

  const contactMethods = useForm<ContactFormType>({
    defaultValues: contactInitialValues,
    resolver: yupResolver(contactSchema),
  });

  const additionalContactMethods = useForm<AdditionalContactFormType>({
    defaultValues: { additionalContacts: [] },
    resolver: yupResolver(additionalContactsSchema),
  });

  const { handleSubmit: handleSupplierSubmit, setValue: setSupplierValue } = supplierMethods;
  const { handleSubmit: handleContactSubmit, setValue: setContactValue } = contactMethods;
  const { handleSubmit: handleAdditionalContactSubmit, setValue: setAdditionalContactValue } =
    additionalContactMethods;

  const [updateSupplier, { loading: supplierLoading }] = useUpdateSupplierMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateSupplier } = data;
      const { response } = updateSupplier || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updateContact, { loading: contactLoading }] = useUpdateContactByContactableIdMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateContactByContactableId } = data;
      const { response } = updateContactByContactableId || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updateAdditionalContact, { loading: updateAdditionalContactLoading }] =
    useUpdateAdditionalContactMutation({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { updateAdditionalContact } = data;
        const { response } = updateAdditionalContact || {};
        const { status, message } = response || {};
        if (status === HTTP_STATUS.SUCCESS) {
          Alert.success(message || '');
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    });

  const [createContact, { loading: createContactLoading }] = useCreateContactMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createContact } = data;
      const { response } = createContact || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(CONTACT_UPDATE_SUCCESS_MESSAGE || '');
        fetchSupplier();
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getSupplier, { loading: getLoading }] = useGetSupplierLazyQuery({
    onCompleted: (data) => {
      const { getSupplier } = data || {};
      const { response, supplier } = getSupplier || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(supplier as SupplierPayload['supplier']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });

  const onSupplierSubmit: SubmitHandler<SupplierFormType> = async (data) => {
    const { companyName, description } = data || {};

    await updateSupplier({
      variables: {
        updateSupplierInput: {
          id: id || '',
          description,
          name: companyName,
        },
      },
    });
  };

  const onContactSubmit: SubmitHandler<ContactFormType> = async (data) => {
    const { id: contactId, country, state, phoneNo, ...rest } = data || {};
    const { name, value } = country || {};
    if (contactId) {
      await updateContact({
        variables: {
          updateContactByContactableIdInput: {
            ...rest,
            ...(phoneNo && { phoneNo }),
            state: state?.value,
            country: name,
            contactableId: id || '',
            contactType: ContactType.Company,
            contactableType: ContactableType.Supplier,
            ...(value && { countryCode: value }),
          },
        },
      });
    } else {
      await createContact({
        variables: {
          createContactInput: {
            ...rest,
            ...(phoneNo && { phoneNo }),
            state: state?.value,
            country: name,
            contactableType: ContactableType.Supplier,
            contactType: ContactType.Company,
            contactableId: id || '',
            ...(value && { countryCode: value }),
          },
        },
      });
    }
  };

  const onAdditionalContactSubmit: SubmitHandler<AdditionalContactFormType> = async (data) => {
    const { additionalContacts } = data || {};

    const additionalContactList = formatAdditionalContact(additionalContacts);

    await updateAdditionalContact({
      variables: {
        updateAdditionalContactsInput: {
          additionalContacts: additionalContactList?.length ? additionalContactList : [],
          contactableId: id || '',
        },
      },
    });
  };

  const fetchSupplier = useCallback(async () => {
    id &&
      (await getSupplier({
        variables: {
          getSupplierInput: {
            id,
          },
        },
      }));
  }, [id, getSupplier]);

  const setValues = (params: SupplierPayload['supplier']) => {
    const { name: companyName, description, contact, additionalContacts } = params || {};
    setSupplierValue('companyName', companyName || '');
    setSupplierValue('description', description || '');

    const {
      id,
      name,
      city,
      state,
      email,
      country,
      phoneNo,
      zipCode,
      address1,
      address2,
      designation,
      countryCode,
    } = contact || {};
    setContactValue('id', id || '');
    setContactValue('city', city || '');
    setContactValue('name', name || '');
    setContactValue('email', email || '');
    setContactValue('phoneNo', phoneNo || '');
    setContactValue('zipCode', zipCode || '');
    setContactValue('address1', address1 || '');
    setContactValue('address2', address2 || '');
    setContactValue('designation', designation || '');
    setContactValue('state', { name: state || '', value: state || '' });
    setContactValue('country', { name: country || '', value: countryCode || '' });
    setAdditionalContactValue(
      'additionalContacts',
      (additionalContacts as AdditionalContactSelectType[]) || [],
    );
  };

  useEffect(() => {
    id && fetchSupplier();
  }, [id, fetchSupplier]);

  const loading =
    supplierLoading || getLoading || contactLoading || createContactLoading || updateAdditionalContactLoading;

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{EDIT_SUPPLIER_TEXT}</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={6} md={12}>
          <FormProvider {...supplierMethods}>
            <form onSubmit={handleSupplierSubmit(onSupplierSubmit)}>
              <SupplierForm loading={loading} isEdit />
            </form>
          </FormProvider>
        </Grid>
        <Grid item lg={6} md={12}>
          <FormProvider {...additionalContactMethods}>
            <form onSubmit={handleAdditionalContactSubmit(onAdditionalContactSubmit)}>
              <AdditionalContactForm loading={loading} isEdit />
            </form>
          </FormProvider>
        </Grid>
        <Grid item lg={6} md={12}>
          <FormProvider {...contactMethods}>
            <form onSubmit={handleContactSubmit(onContactSubmit)}>
              <ContactForm loading={loading} isEdit />
            </form>
          </FormProvider>
        </Grid>
      </Grid>

      <BackdropLoader open={loading} text={SUPPLIER_IS_CREATING_TEXT} />
    </Fragment>
  );
};

export default Edit;
