import { FC, Fragment } from 'react';
import { Box, List, Typography } from '@mui/material';
// interfaces
import { SidebarListsProps } from 'interfaces';

const SidebarLists: FC<SidebarListsProps> = ({ list }) => {
  return (
    <Fragment>
      {list?.map((item) => {
        const { children, heading, id } = item || {};
        return (
          <Fragment key={id}>
            <Box paddingX="12px" mt={2}>
              <Typography variant="body1" textTransform="uppercase">
                {heading}
              </Typography>
            </Box>
            <List>{children}</List>
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default SidebarLists;
