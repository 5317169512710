// packages block
import { FC, Fragment } from 'react';
import { Skeleton, TableRow, TableCell } from '@mui/material';
// interfaces/types block
import { TableLoaderProps } from 'interfaces';

const TableLoader: FC<TableLoaderProps> = ({ rows, columns }): JSX.Element => {
  return (
    <Fragment>
      {new Array(rows).fill(1).map((_, index) => (
        <TableRow key={`${index}-TableLoader`} className="table-loader-row">
          {new Array(columns).fill(2).map((_, childIndex) => (
            <TableCell key={`${index}-TableCell-${childIndex}`}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Fragment>
  );
};

export default TableLoader;
