import { useBlocker } from 'react-router-dom';

const useCustomBlocker = (isDirty: boolean, isFormulaFormDirty: boolean, isBlocker: boolean) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      (isDirty || isFormulaFormDirty) && isBlocker && currentLocation.pathname !== nextLocation.pathname,
  );
  return blocker;
};

export default useCustomBlocker;
