import { FC, useContext } from 'react';
// helper, interfaces, context
import { isAdminOrSuperAdminOrStaff } from 'lib/helper';
import { ChildrenType } from 'interfaces';
import { AuthContext } from 'contexts/AuthContext';

export const SuperOrAdminOrStaffGuard: FC<ChildrenType> = ({ children }) => {
  const { userRoles } = useContext(AuthContext);

  const isSuperAndAdminAndStaff = isAdminOrSuperAdminOrStaff(userRoles);

  return <>{isSuperAndAdminAndStaff ? children : null}</>;
};
