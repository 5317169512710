import { Box, Typography } from '@mui/material';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
// component
import ProfileForm from './ProfileForm';
// constants, schema, style
import { HTTP_STATUS, PROFILE_TEXT } from 'constants/index';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { UserPayload, useFetchUserSettingLazyQuery } from 'generated/graphql';

const Profile: FC = () => {
  const [user, setUser] = useState<UserPayload['user']>(null);

  const [getUser, { loading }] = useFetchUserSettingLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { fetchUser } = data;
      const { response, user } = fetchUser;

      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setUser(user as UserPayload['user']);
      }
    },
    onError: () => {
      setUser(null);
    },
  });

  const fetchUser = useCallback(async () => {
    await getUser();
  }, [getUser]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{PROFILE_TEXT}</Typography>
      </Box>
      <ProfileForm user={user} loading={loading} />
    </Fragment>
  );
};

export default Profile;
