import { FC, Fragment } from 'react';
import { Box, Typography } from '@mui/material';
// interface, style
import { ColumnForFormulaEstimatorProps } from 'interfaces';
import { flexCenterJustifyBetween } from 'theme/styleConstant';

const ColumnForFormulaEstimator: FC<ColumnForFormulaEstimatorProps> = ({ children, title, heading }) => {
  return (
    <Fragment>
      <Box mt={2} sx={flexCenterJustifyBetween}>
        <Typography variant="body2" fontWeight={700}>
          {title}
        </Typography>
        <Typography variant="caption" fontWeight={700}>
          {heading}
        </Typography>
      </Box>

      {children}
    </Fragment>
  );
};

export default ColumnForFormulaEstimator;
