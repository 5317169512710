import { FC } from 'react';
// components
import AllergenInfoCard from 'components/main/Ingredients/Add/components/Allergens/AllergenInfoCard';
// interfaces
import { AllergensTabProps } from 'interfaces';

const Allergens: FC<AllergensTabProps> = ({ isEdit = false, loading }) => {
  return <AllergenInfoCard loading={loading} isEdit={isEdit} />;
};

export default Allergens;
