import React, { FC, Reducer, createContext, useReducer } from 'react';
// interfaces, reducers
import { ChildrenType, IngredientFormulaContextType } from 'interfaces';
import { formulaIngredientReducer, Action, State, initialState } from 'reducer/formulaIngredientReducer';

export const FormulaIngredientContext = createContext<IngredientFormulaContextType>({
  state: initialState,
  dispatch: () => {},
});

export const FormulaIngredientContextProvider: FC<ChildrenType> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(formulaIngredientReducer, initialState);

  return (
    <FormulaIngredientContext.Provider value={{ state, dispatch }}>
      {children}
    </FormulaIngredientContext.Provider>
  );
};
