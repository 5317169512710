import { FC, JSX } from 'react';
import { Box, Button, Card, CardActions, CardContent, Grid } from '@mui/material';
// components
import InputController from 'controller/InputController';
// constants, styles, interfaces
import { ExcipientFormProps } from 'interfaces';
import { NAME_TEXT, SAVE_TEXT } from 'constants/index';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';

const ExcipientForm: FC<ExcipientFormProps> = ({ isEdit = false, loading = false }): JSX.Element => {
  return (
    <Card sx={forInputLabelBg}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <InputController isRequired fieldType="text" name="name" disabled={loading} title={NAME_TEXT} />
          </Grid>
        </Grid>
      </CardContent>

      {isEdit && (
        <CardActions>
          <Box sx={flexMarginTopEndCenter}>
            <Button type="submit" variant="contained" size="small" disabled={loading}>
              {SAVE_TEXT}
            </Button>
          </Box>
        </CardActions>
      )}
    </Card>
  );
};

export default ExcipientForm;
