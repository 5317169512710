import { FC, Fragment, JSX } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material';
// components
import InputController from 'controller/InputController';
// constants, styles, interfaces
import { SupplierFormProps } from 'interfaces';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';
import { COMPANY_NAME_TEXT, DESCRIPTION_TEXT, SAVE_TEXT, SUPPLIER_TEXT } from 'constants/index';

const SupplierForm: FC<SupplierFormProps> = ({ isEdit = false, loading = false }): JSX.Element => {
  return (
    <Card sx={forInputLabelBg}>
      <CardHeader title={SUPPLIER_TEXT} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <InputController
              isRequired
              fieldType="text"
              name="companyName"
              disabled={loading}
              title={COMPANY_NAME_TEXT}
            />
          </Grid>

          <Grid item xs={12} lg={12}>
            <InputController
              isMultiLine
              maxRows="4"
              minRows="3"
              fieldType="text"
              name="description"
              disabled={loading}
              title={DESCRIPTION_TEXT}
            />
          </Grid>
        </Grid>
      </CardContent>

      {isEdit ? (
        <CardActions>
          <Box sx={flexMarginTopEndCenter}>
            <Button type="submit" variant="contained" size="small" disabled={loading}>
              {SAVE_TEXT}
            </Button>
          </Box>
        </CardActions>
      ) : (
        <Fragment />
      )}
    </Card>
  );
};

export default SupplierForm;
