import { Box, Chip, Grid, TableCell, TableRow, useTheme } from '@mui/material';
import { FC, Fragment, Reducer, useCallback, useEffect, useReducer } from 'react';
// components
import { Alert } from 'components/common/Alert';
import TableLoader from 'components/common/TableLoader';
import NoDataFound from 'components/common/NoDataFound';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import TableTabsComponent from 'components/common/TableTabsComponent';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
import BottleSizeSelect from 'components/common/simpleSelectors/BottleSize';
import ServingSizeSelect from 'components/common/simpleSelectors/ServingSize';
import ProductTypesSelect from 'components/common/simpleSelectors/ProductType';
import ProductSizeSelect from 'components/common/simpleSelectors/ProductSize';
// constants, reducers, graphql, styles
import {
  HTTP_STATUS,
  ACTIVE_TEXT,
  INACTIVE_TEXT,
  BOTTLE_SIZE_TEXT,
  PRODUCT_TYPE_TEXT,
  LOADING_TABLE_ROWS,
  TabEnum,
  TABS,
  EDIT_BOTTLE_CAPACITY_ROUTE,
  BOTTLE_CAPACITY_TABLE_HEADER,
  SERVING_SIZE_TEXT,
  PRODUCT_SIZE_TEXT,
} from 'constants/index';
import { textWhiteSpace } from 'styles/commonComponentStyle';
import {
  BottleCapacitiesPayload,
  useUpdateBottleCapacityMutation,
  useFindAllBottleCapacityLazyQuery,
} from 'generated/graphql';
import {
  State,
  Action,
  ActionType,
  initialState,
  bottleCapacityReducer,
} from 'reducer/bottleCapacityReducer';

const BottleCapacityTable: FC = () => {
  const theme = useTheme();
  const [state, dispatch] = useReducer<Reducer<State, Action>>(bottleCapacityReducer, initialState);
  const { page, rowsPerPage, count, data, activeTab, bottleSize, productType, servingSize, productSize } =
    state;
  const { value: bottleSizeId } = bottleSize;
  const { value: productTypeId } = productType;
  const { value: servingSizeId } = servingSize;
  const { value: productSizeId } = productSize;

  const [findAllBottleCapacity, { loading: fetchLoading, error }] = useFindAllBottleCapacityLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllBottleCapacity } = data;
      const { pagination, response, data: bottleCapacityData } = findAllBottleCapacity || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { page, totalCount } = pagination || {};
        dispatch({ type: ActionType.SET_PAGE, page: page || 1 });
        dispatch({ type: ActionType.SET_COUNT, count: totalCount || 0 });
        dispatch({ type: ActionType.SET_DATA, data: bottleCapacityData as BottleCapacitiesPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const [updateBottleCapacityStatus, { loading: updateLoading }] = useUpdateBottleCapacityMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateBottleCapacity } = data;
      const { response } = updateBottleCapacity || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        fetchBottleCapacity();
      } else {
        Alert.error(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_COUNT, count: 0 });
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchBottleCapacity = useCallback(async () => {
    await findAllBottleCapacity({
      variables: {
        findAllBottleCapacitiesInput: {
          paginationOptions: {
            limit: rowsPerPage,
            page,
          },
          ...(bottleSizeId && { bottleSizeId }),
          ...(productTypeId && { productTypeId }),
          ...(servingSizeId && { servingSizeId }),
          ...(productSizeId && { productSizeId }),
          ...(activeTab !== TabEnum.All && { isActive: activeTab === TabEnum.Active }),
        },
      },
    });
  }, [
    page,
    activeTab,
    rowsPerPage,
    bottleSizeId,
    productTypeId,
    servingSizeId,
    productSizeId,
    findAllBottleCapacity,
  ]);

  useEffect(() => {
    fetchBottleCapacity();
  }, [fetchBottleCapacity]);

  const tabHandler = (_: React.SyntheticEvent<Element, Event>, value: string) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab: value as TabEnum });
  };

  const onActiveHandler = async (id: string, status: boolean) => {
    await updateBottleCapacityStatus({
      variables: {
        updateBottleCapacityInput: {
          id,
          isActive: !status,
        },
      },
    });
  };

  const loading = fetchLoading || updateLoading;

  const noData = Boolean((!fetchLoading && data?.length === 0) || error);

  return (
    <TableContainer>
      <TableTabsComponent activeTab={activeTab} tabsList={TABS} tabHandler={tabHandler} />
      <Box m={({ spacing }) => spacing(2, 3)}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <BottleSizeSelect
              isClearable
              value={bottleSize}
              name="bottle-size"
              title={BOTTLE_SIZE_TEXT}
              handleChange={(item) => dispatch({ type: ActionType.SET_BOTTLE_SIZE, bottleSize: item })}
            />
          </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ProductTypesSelect
              isClearable
              value={productType}
              name="product-type"
              title={PRODUCT_TYPE_TEXT}
              handleChange={(item) => dispatch({ type: ActionType.SET_PRODUCT_TYPE, productType: item })}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ServingSizeSelect
              isClearable
              value={servingSize}
              name="serving-size"
              title={SERVING_SIZE_TEXT}
              handleChange={(item) => dispatch({ type: ActionType.SET_SERVING_SIZE, servingSize: item })}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ProductSizeSelect
              isClearable
              value={productSize}
              name="product-size"
              title={PRODUCT_SIZE_TEXT}
              handleChange={(item) => dispatch({ type: ActionType.SET_PRODUCT_SIZE, productSize: item })}
            />
          </Grid>
        </Grid>
      </Box>
      <TableComponent
        noData={noData}
        page={page - 1}
        count={count}
        rowsPerPage={rowsPerPage}
        tableHeader={BOTTLE_CAPACITY_TABLE_HEADER}
        setPage={(p: number) => dispatch({ type: ActionType.SET_PAGE, page: p + 1 })}
        setRowsPerPage={(r: number) => dispatch({ type: ActionType.SET_ROWS_PER_PAGE, rowsPerPage: r })}>
        {loading ? (
          <TableLoader columns={8} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const {
                id,
                lohCost,
                fillPercentage,
                isActive,
                bottleSize,
                servingSize,
                productSize,
                productType,
              } = cell || {};
              const { name: bottleSizeName, isActive: bottleSizeActive } = bottleSize || {};
              const { name: productSizeName } = productSize || {};
              const { name: productTypeName } = productType || {};
              const { size } = servingSize || {};

              return (
                <TableRow key={id}>
                  <TableCell sx={textWhiteSpace}>{productTypeName ?? '--'}</TableCell>
                  <TableCell>{productSizeName ?? '--'}</TableCell>
                  <TableCell
                    sx={{
                      color: `${bottleSizeActive ? 'inherit' : theme.palette.customRedColor.main} !important`,
                    }}>
                    {bottleSizeName ?? '--'}
                  </TableCell>
                  <TableCell>{size || '--'}</TableCell>
                  <TableCell>{lohCost ? `$${lohCost}` : '--'}</TableCell>
                  <TableCell>{fillPercentage ? `${fillPercentage}%` : '--'}</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={isActive ? ACTIVE_TEXT : INACTIVE_TEXT}
                      color={isActive ? 'success' : 'error'}
                    />
                  </TableCell>
                  <TableCell>
                    <ActionMenuDropdown
                      id={id ?? ''}
                      isActive={!!isActive}
                      editRoute={EDIT_BOTTLE_CAPACITY_ROUTE}
                      onActiveHandler={onActiveHandler}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>

      <NoDataFound noData={noData} />
    </TableContainer>
  );
};

export default BottleCapacityTable;
