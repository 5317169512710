import { FC } from 'react';
import { Grid } from '@mui/material';
// components
import StaffCard from './StaffCard';
// interfaces
import { UserFormProps } from 'interfaces';

const StaffForm: FC<UserFormProps> = ({ loading }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} xl={7}>
        <StaffCard loading={loading} />
      </Grid>
    </Grid>
  );
};

export default StaffForm;
