import { Grid } from '@mui/material';
import { FC, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
// components
import InputController from 'controller/InputController';
import RadioGroupController from 'controller/RadioGroupController';
import TabletCoatingRadioGroup from 'components/common/radioGroups/TabletCoating';
// constants, graphql, interfaces
import { DosageFormulationFormType } from 'interfaces';
import { ProductTypes, TabletCoatingColor } from 'generated/graphql';
import { COLOR_TEXT, TABLET_COLOR_MAPPED, COATING_COLORS_HEX_TEXT, COATING_TEXT } from 'constants/index';

const SingleLayer: FC = () => {
  const { setValue, watch } = useFormContext<DosageFormulationFormType>();
  const { coatingColor } = watch();

  const onTabletCoatingClear = () => {
    setValue('tabletCoating', '');
  };

  return (
    <Fragment>
      <Grid item xs={12} xl={12}>
        <TabletCoatingRadioGroup
          name="tabletCoating"
          title={COATING_TEXT}
          productType={ProductTypes.Tablet}
          onClear={onTabletCoatingClear}
        />
      </Grid>
      <Grid item xs={12} xl={12}>
        <RadioGroupController
          isRequired
          name="coatingColor"
          title={COLOR_TEXT}
          options={TABLET_COLOR_MAPPED}
        />
      </Grid>

      {coatingColor === TabletCoatingColor.Multi ? (
        <Grid item xs={12} xl={12}>
          <InputController isMultiValue name="colorsHex" fieldType="color" title={COATING_COLORS_HEX_TEXT} />
        </Grid>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
export default SingleLayer;
