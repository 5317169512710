import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
//components
import { RequestSentSuccessIcon } from 'assets/svgs';
import { AuthLayout } from 'components/common/AuthLayout';
//constants
import { AUTH_LINKS, GO_TO_SIGNIN_BUTTON, REQUEST_SENT_SUBTITLE, REQUEST_SENT_TITLE } from 'constants/index';

const RequestSent: FC = () => {
  let navigate = useNavigate();

  return (
    <AuthLayout title={REQUEST_SENT_TITLE} subTitle={REQUEST_SENT_SUBTITLE} icon={<RequestSentSuccessIcon />}>
      <Button fullWidth variant="contained" color="primary" onClick={() => navigate(AUTH_LINKS.LOGIN_LINK)}>
        {GO_TO_SIGNIN_BUTTON}
      </Button>
    </AuthLayout>
  );
};

export default RequestSent;
