import { FC, Fragment, JSX } from 'react';
import { Box, Typography } from '@mui/material';
//components
import ProductFormulationTypeTable from './ProductFormulationTypeTable';
// constant, styles, reducer, graphql
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { PRODUCT_FORMULATION_TYPE_TEXT } from 'constants/index';

const ProductFormulationType: FC = (): JSX.Element => {
  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{PRODUCT_FORMULATION_TYPE_TEXT}</Typography>
      </Box>
      <ProductFormulationTypeTable />
    </Fragment>
  );
};

export default ProductFormulationType;
