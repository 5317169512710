import { FC, useCallback, useEffect, useState } from 'react';
// component
import RadioGroupController from 'controller/RadioGroupController';
// interfaces, constants, helper, graphql
import { HTTP_STATUS } from 'constants/index';
import { renderCapsuleTypes } from 'lib/helper';
import { CapsuleTypeSelectorSelectorProps, SelectType } from 'interfaces';
import { CapsuleTypesPayload, useFindAllCapsuleTypeLazyQuery } from 'generated/graphql';

const CapsuleTypeRadioGroup: FC<CapsuleTypeSelectorSelectorProps> = ({
  name,
  title,
  isRequired = false,
  loading: getLoading,
}) => {
  const [options, setOptions] = useState<SelectType[]>([]);

  const [findAllCapsuleType, { loading: findAllCapsuleTypeLoading }] = useFindAllCapsuleTypeLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllCapsuleType } = data;
      const { response, data: productSubTypeData } = findAllCapsuleType || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setOptions(renderCapsuleTypes(productSubTypeData as CapsuleTypesPayload['data']));
      } else {
        setOptions([]);
      }
    },

    onError: () => {
      setOptions([]);
    },
  });

  const fetchAllCapsuleType = useCallback(async () => {
    await findAllCapsuleType({
      variables: {
        findAllCapsuleTypesInput: {
          paginationOptions: {
            limit: 10,
            page: 1,
          },
        },
      },
    });
  }, [findAllCapsuleType]);

  useEffect(() => {
    fetchAllCapsuleType();
  }, [fetchAllCapsuleType]);

  const loading = findAllCapsuleTypeLoading || getLoading;

  return (
    <RadioGroupController
      showTitle
      name={name}
      title={title}
      loaderRows={1}
      loading={loading}
      loaderColumns={6}
      options={options}
      isRequired={isRequired}
    />
  );
};

export default CapsuleTypeRadioGroup;
