import { FC } from 'react';
// components
import NutrientsInfoCard from './NutrientsInfoCard';
// interfaces
import { NutrientsTabProps } from 'interfaces';

const Nutrients: FC<NutrientsTabProps> = ({ loading, isEdit = false }) => {
  return <NutrientsInfoCard loading={loading} isEdit={isEdit} />;
};

export default Nutrients;
