import { yupResolver } from '@hookform/resolvers/yup';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
// components
import { Alert } from 'components/common/Alert';
import { BackdropLoader } from 'components/common/BackdropLoader';
import ProductExcipientForm from './components/ProductExcipientForm';
// Schema, styles, graphql, constants
import { useUpdateProductExcipientMutation } from 'generated/graphql';
import {
  SAVE_TEXT,
  HTTP_STATUS,
  EDIT_PRODUCT_EXCIPIENT_TEXT,
  productExcipientInitialValue,
  PRODUCT_EXCIPIENT_IS_UPDATED_TEXT,
} from 'constants/index';
import { ProductExcipientFormType, EditProductExcipientProps } from 'interfaces';
import { productExcipientTypeSchema } from 'validationSchema';
import { CloseIcon } from 'assets/svgs';
import { suppliers } from 'lib/helper';

const Edit: FC<EditProductExcipientProps> = (props): JSX.Element => {
  const { excipientType, productExcipient, open, onClose, fetch } = props || {};
  const { id: excipientTypeId } = excipientType || {};
  const { id } = productExcipient || {};

  const methods = useForm<ProductExcipientFormType>({
    defaultValues: productExcipientInitialValue,
    resolver: yupResolver(productExcipientTypeSchema),
  });

  const { handleSubmit, setValue, clearErrors } = methods;

  const [updateProductExcipient, { loading }] = useUpdateProductExcipientMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateProductExcipient } = data;
      const { response } = updateProductExcipient || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        fetch();
        onClose();
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const handleClick = () => {
    clearErrors();
    onClose();
  };

  const onSubmit: SubmitHandler<ProductExcipientFormType> = async (data) => {
    const { percentage, perKgCost, excipient, suppliers } = data || {};
    const { value: excipientId } = excipient || {};

    const suppliersIds = suppliers?.map((item) => {
      return item?.value;
    });

    await updateProductExcipient({
      variables: {
        updateProductExcipientInput: {
          id: id || '',
          excipientId: excipientId,
          excipientTypeId: excipientTypeId ?? '',
          percentage: parseFloat(percentage),
          perKgCost: parseFloat(perKgCost),
          productExcipientSuppliers: suppliersIds,
        },
      },
    });
  };

  const setValues = useCallback(() => {
    if (open && productExcipient) {
      const { percentage, perKgCost, excipient, productExcipientSupplier } = productExcipient || {};
      const { id: excipientId, name: excipientName } = excipient || {};

      console.log('productExcipientSupplier', productExcipientSupplier);
      setValue('perKgCost', `${perKgCost ?? 0}`);
      setValue('percentage', `${percentage ?? ''}`);
      setValue('perKgCost', `${perKgCost ?? 0}`);
      setValue('suppliers', suppliers(productExcipientSupplier ?? []));
      setValue('excipient', {
        value: excipientId ?? '',
        name: excipientName ?? '',
      });
    }
  }, [open, productExcipient, setValue]);

  useEffect(() => {
    open && setValues();
  }, [open, setValues]);

  return (
    <Fragment>
      <Dialog open={open}>
        <DialogTitle variant="h6">{EDIT_PRODUCT_EXCIPIENT_TEXT}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClick}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent dividers>
              <ProductExcipientForm loading={loading} />
            </DialogContent>

            <DialogActions>
              <Box>
                <Button type="submit" variant="contained" size="small" disabled={loading}>
                  {SAVE_TEXT}
                </Button>
              </Box>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>

      <BackdropLoader open={loading} text={PRODUCT_EXCIPIENT_IS_UPDATED_TEXT} />
    </Fragment>
  );
};

export default Edit;
