import { FC, JSX } from 'react';
import { Box, Button, Card, CardActions, CardContent, Grid } from '@mui/material';
// components
import SelectController from 'controller/Select';
import InputController from 'controller/InputController';
import ProductTypesSelector from 'components/common/selectors/productType';
import ExcipientLabelSelector from 'components/common/radioGroups/ExcipientLabel';
// constants, styles, interfaces
import { ExcipientTypeFormProps } from 'interfaces';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';
import {
  NAME_TEXT,
  SAVE_TEXT,
  LOAD_ADD_TEXT,
  PER_KG_COST_TEXT,
  PRODUCT_TYPE_TEXT,
  LOAD_ADD_UNIT_TEXT,
  EXCIPIENT_LABEL_TEXT,
  LOAD_ADD_UNIT_MAPPED,
} from 'constants/index';

const ExcipientTypeForm: FC<ExcipientTypeFormProps> = ({ isEdit = false, loading = false }): JSX.Element => {
  return (
    <Card sx={forInputLabelBg}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <InputController isRequired fieldType="text" name="name" disabled={loading} title={NAME_TEXT} />
          </Grid>

          <Grid item xs={12} lg={12}>
            <InputController
              isRequired
              fieldType="number"
              name="perKgCost"
              disabled={loading}
              title={PER_KG_COST_TEXT}
            />
          </Grid>

          <Grid item xs={12} lg={12}>
            <InputController
              isRequired
              fieldType="number"
              name="loadAdd"
              disabled={loading}
              title={LOAD_ADD_TEXT}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <SelectController
              isRequired
              name="loadAddUnit"
              disabled={loading}
              title={LOAD_ADD_UNIT_TEXT}
              options={LOAD_ADD_UNIT_MAPPED}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ProductTypesSelector isRequired title={PRODUCT_TYPE_TEXT} name="productType" />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ExcipientLabelSelector isRequired title={EXCIPIENT_LABEL_TEXT} name="excipientLabel" />
          </Grid>
        </Grid>
      </CardContent>

      {isEdit && (
        <CardActions>
          <Box sx={flexMarginTopEndCenter}>
            <Button type="submit" variant="contained" size="small" disabled={loading}>
              {SAVE_TEXT}
            </Button>
          </Box>
        </CardActions>
      )}
    </Card>
  );
};

export default ExcipientTypeForm;
