import { Box } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, Fragment, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import SupplierInfoCard from '../Add/components/General/SupplierInfoCard';
import IngredientCostInfoCard from '../Add/components/General/IngredientCostInfoCard';
import IngredientBasicInfoCard from '../Add/components/General/IngredientBasicInfoCard';
import IngredientDensityInfoCard from '../Add/components/General/IngredientDensityInfoCard';
import IngredientManufacturingInfoCard from '../Add/components/General/IngredientManufacturingInfoCard';
// interfaces, constants, schema, graphql, helpers
import {
  SelectType,
  EditGeneralProps,
  IngredientDensityCardType,
  IngredientSupplierCardType,
  IngredientCostInfoCardType,
  IngredientBasicInfoCardType,
  IngredientCountriesCardType,
} from 'interfaces';
import {
  HTTP_STATUS,
  ingredientBasicInfoCardInitialValues,
  ingredientCountriesCardInitialValues,
  ingredientDensityCardInitialValues,
  ingredientSupplierCardInitialValues,
} from 'constants/index';
import {
  ingredientDensityCardSchema,
  ingredientSupplierCardSchema,
  ingredientBasicInfoCardSchema,
  ingredientCountriesCardSchema,
} from 'validationSchema';
import {
  SupplierType,
  IngredientType,
  IngredientSupplierInput,
  useUpdateIngredientMutation,
  useUpdateIngredientSuppliersMutation,
} from 'generated/graphql';
import { convertEditorStateToHtml, convertHtmlToEditorState } from 'lib/helper';

const EditGeneral: FC<EditGeneralProps> = ({
  isLoading,
  ingredient,
  costMethods,
  setIsLoading,
  fetchIngredient,
  loading: getLoading,
}) => {
  const { id: ingredientId } = ingredient || {};

  const basicInfoMethods = useForm<IngredientBasicInfoCardType>({
    defaultValues: ingredientBasicInfoCardInitialValues,
    resolver: yupResolver(ingredientBasicInfoCardSchema),
  });

  const supplierMethods = useForm<IngredientSupplierCardType>({
    defaultValues: ingredientSupplierCardInitialValues,
    resolver: yupResolver(ingredientSupplierCardSchema),
  });

  const countryMethods = useForm<IngredientCountriesCardType>({
    defaultValues: ingredientCountriesCardInitialValues,
    resolver: yupResolver(ingredientCountriesCardSchema),
  });

  const densityMethods = useForm<IngredientDensityCardType>({
    defaultValues: ingredientDensityCardInitialValues,
    resolver: yupResolver(ingredientDensityCardSchema),
  });

  const [updateIngredient, { loading: updateIngredientLoading }] = useUpdateIngredientMutation({
    onCompleted: (data) => {
      const { updateIngredient } = data;
      const { response } = updateIngredient || {};
      const { status, message } = response || {};
      if (message && status === HTTP_STATUS.SUCCESS) {
        Alert.success(message);
      } else {
        Alert.warning(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updateIngredientSuppliers, { loading: updateIngredientSuppliersLoading }] =
    useUpdateIngredientSuppliersMutation({
      onCompleted: (data) => {
        const { updateIngredientSuppliers } = data;
        const { response } = updateIngredientSuppliers || {};
        const { status, message } = response || {};
        if (message && status === HTTP_STATUS.SUCCESS) {
          Alert.success(message);
        } else {
          Alert.warning(message ?? '');
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    });

  const { handleSubmit: handleCostSubmit, setValue: setCostValue } = costMethods;
  const { handleSubmit: handleBasicSubmit, setValue: setBasicValue } = basicInfoMethods;
  const { handleSubmit: handleCountrySubmit, setValue: setCountryValue } = countryMethods;
  const { handleSubmit: handleDensitySubmit, setValue: setDensityValue } = densityMethods;
  const { handleSubmit: handleSupplierSubmit, setValue: setSupplierValue } = supplierMethods;

  const onBasicSubmit: SubmitHandler<IngredientBasicInfoCardType> = async (data) => {
    const { category, productTypes, latinName, name, scientificName, subCategory, tradeMarkName, type } =
      data;
    const { value: categoryId } = category || {};
    const { value: subCategoryId } = subCategory || {};
    const labelName = convertEditorStateToHtml(latinName);

    if (ingredientId) {
      await updateIngredient({
        variables: {
          updateIngredientInput: {
            id: ingredientId,
            name,
            latinName: labelName,
            scientificName,
            tradeMarkName,
            type,
            productTypes,
            categoryId,
            subCategoryId,
          },
        },
      });
    }
  };

  const onCostSubmit: SubmitHandler<IngredientCostInfoCardType> = async (data) => {
    const {
      amount,
      cost,
      potency,
      shelfLife,
      suggestedOverage,
      gummyOverage,
      nutrients,
      nutrientName,
      showB3,
    } = data;
    const potencyInDecimal = Number(potency) / 100;

    if (ingredientId) {
      const response = await updateIngredient({
        variables: {
          updateIngredientInput: {
            id: ingredientId,
            cost: parseFloat(cost),
            amount: parseFloat(amount),
            potency: `${potencyInDecimal}`,
            shelfLife: parseFloat(shelfLife),
            gummyOverage: parseFloat(gummyOverage),
            suggestedOverage: parseFloat(suggestedOverage),
            nutrients,
            nutrientName,
            showB3,
          },
        },
      });
      if (response) {
        fetchIngredient();
      }
    }
  };

  const onSupplierSubmit: SubmitHandler<IngredientSupplierCardType> = async (data) => {
    const { primarySupplier, secondarySupplier, tertiarySupplier } = data;

    const { value: primarySupplierId } = primarySupplier;
    const { value: secondarySupplierId } = secondarySupplier;
    const { value: tertiarySupplierId } = tertiarySupplier;

    const suppliersList: IngredientSupplierInput[] = [];
    if (primarySupplierId) {
      suppliersList.push({ type: SupplierType.Primary, supplier: primarySupplierId });
    }

    if (secondarySupplierId) {
      suppliersList.push({ type: SupplierType.Secondary, supplier: secondarySupplierId });
    }

    if (tertiarySupplierId) {
      suppliersList.push({ type: SupplierType.Tertiary, supplier: tertiarySupplierId });
    }

    if (ingredientId) {
      updateIngredientSuppliers({
        variables: {
          updateIngredientSuppliersInput: {
            ingredientId,
            ingredientSuppliers: suppliersList,
          },
        },
      });
    }
  };

  const onCountrySubmit: SubmitHandler<IngredientCountriesCardType> = async (data) => {
    const { manufacturingCountry, originCountry } = data;
    const { value: originCountryId } = originCountry || {};
    const { value: manufacturingCountryId } = manufacturingCountry || {};

    if (ingredientId) {
      updateIngredient({
        variables: {
          updateIngredientInput: {
            id: ingredientId,
            manufacturingCountry: manufacturingCountryId,
            originCountry: originCountryId,
          },
        },
      });
    }
  };

  const onDensitySubmit: SubmitHandler<IngredientDensityCardType> = async (data) => {
    const { averageParticleSize, bulkDensity, lossOnDrying, tappedDensity } = data;

    if (ingredientId) {
      updateIngredient({
        variables: {
          updateIngredientInput: {
            id: ingredientId,
            bulkDensity,
            lossOnDrying,
            tappedDensity,
            avergageParticleSize: averageParticleSize,
          },
        },
      });
    }
  };

  const setValues = useCallback(() => {
    const {
      name,
      type,
      amount,
      cost,
      potency,
      category,
      latinName,
      shelfLife,
      subCategory,
      gummyOverage,
      tradeMarkName,
      scientificName,
      suggestedOverage,
      ingredientSuppliers,
      avergageParticleSize,
      manufacturingCountry,
      originCountry,
      tappedDensity,
      lossOnDrying,
      bulkDensity,
      nutrients,
      ingredientProductTypes,
      showB3,
      nutrientName,
    } = ingredient || {};
    const { id, name: categoryName } = category || {};
    const { id: subCategoryId, name: subCategoryName } = subCategory || {};

    const categoryObj: SelectType = {
      value: id ?? '',
      name: categoryName ?? '',
    };

    const subCategoryObj: SelectType = {
      value: subCategoryId ?? '',
      name: subCategoryName ?? '',
    };

    const primarySupplier = ingredientSuppliers?.find((item) => item?.type === SupplierType.Primary);
    const secondarySupplier = ingredientSuppliers?.find((item) => item?.type === SupplierType.Secondary);
    const tertiarySupplier = ingredientSuppliers?.find((item) => item?.type === SupplierType.Tertiary);

    const IngredientProductType = ingredientProductTypes?.map((item) => item?.productTypeId);

    const labelName = convertHtmlToEditorState(latinName ?? '');

    // setting basic info card values
    setBasicValue('name', name ?? '');
    setBasicValue('latinName', labelName);
    setBasicValue('category', categoryObj);
    setBasicValue('subCategory', subCategoryObj);
    setBasicValue('tradeMarkName', tradeMarkName ?? '');
    setBasicValue('scientificName', scientificName ?? '');
    setBasicValue('type', (type as IngredientType[]) ?? []);
    setBasicValue('productTypes', IngredientProductType as string[]);

    // setting cost info card values
    if (isLoading) {
      const potencyInPercentage = Number(potency) * 100;
      setCostValue('cost', `${cost ?? ''}`);
      setCostValue('nutrients', nutrients ?? false);
      setCostValue('showB3', showB3 ?? false);
      setCostValue('nutrientName', nutrientName ?? false);
      setCostValue('potency', `${potencyInPercentage}`);
      setCostValue('amount', `${amount ?? ''}`);
      setCostValue('shelfLife', `${shelfLife ?? ''}`);
      setCostValue('gummyOverage', `${gummyOverage ?? ''}`);
      setCostValue('suggestedOverage', `${suggestedOverage ?? ''}`);
    }
    setIsLoading(false);

    // setting supplier info card values

    if (primarySupplier) {
      const { supplier } = primarySupplier || {};
      const { id, name, description } = supplier || {};

      setSupplierValue('primarySupplier', {
        value: id ?? '',
        name: description ? `${name ?? ''} : ${description}` : name ?? '',
        str: id ?? '',
      });
    }
    if (secondarySupplier) {
      const { supplier } = secondarySupplier || {};
      const { id, name, description } = supplier || {};

      setSupplierValue('secondarySupplier', {
        value: id ?? '',
        name: description ? `${name ?? ''} : ${description}` : name ?? '',
        str: id ?? '',
      });
    }

    if (tertiarySupplier) {
      const { supplier } = tertiarySupplier || {};
      const { id, name, description } = supplier || {};

      setSupplierValue('tertiarySupplier', {
        value: id ?? '',
        name: description ? `${name ?? ''} : ${description}` : name ?? '',
        str: id ?? '',
      });
    }

    // setting country info card values
    setCountryValue('manufacturingCountry', {
      name: manufacturingCountry ?? '',
      value: manufacturingCountry ?? '',
    });
    setCountryValue('originCountry', { name: originCountry ?? '', value: originCountry ?? '' });

    // setting country info card values
    setDensityValue('averageParticleSize', avergageParticleSize ?? '');
    setDensityValue('bulkDensity', bulkDensity ?? '');
    setDensityValue('lossOnDrying', lossOnDrying ?? '');
    setDensityValue('tappedDensity', tappedDensity ?? '');
  }, [
    ingredient,
    isLoading,
    setBasicValue,
    setCostValue,
    setCountryValue,
    setDensityValue,
    setIsLoading,
    setSupplierValue,
  ]);

  useEffect(() => {
    ingredient && setValues();
  }, [setValues, ingredient]);

  const loading = getLoading || updateIngredientLoading || updateIngredientSuppliersLoading;

  return (
    <Fragment>
      <FormProvider {...basicInfoMethods}>
        <form onSubmit={handleBasicSubmit(onBasicSubmit)}>
          <IngredientBasicInfoCard loading={loading} isEdit />
        </form>
      </FormProvider>
      <Box mt={3}>
        <FormProvider {...costMethods}>
          <form onSubmit={handleCostSubmit(onCostSubmit)}>
            <IngredientCostInfoCard loading={loading} isEdit />
          </form>
        </FormProvider>
      </Box>
      <Box mt={3}>
        <FormProvider {...supplierMethods}>
          <form onSubmit={handleSupplierSubmit(onSupplierSubmit)}>
            <SupplierInfoCard loading={loading} isEdit />
          </form>
        </FormProvider>
      </Box>
      <Box mt={3}>
        <FormProvider {...countryMethods}>
          <form onSubmit={handleCountrySubmit(onCountrySubmit)}>
            <IngredientManufacturingInfoCard loading={loading} isEdit />
          </form>
        </FormProvider>
      </Box>
      <Box mt={3}>
        <FormProvider {...densityMethods}>
          <form onSubmit={handleDensitySubmit(onDensitySubmit)}>
            <IngredientDensityInfoCard loading={loading} isEdit />
          </form>
        </FormProvider>
      </Box>
    </Fragment>
  );
};

export default EditGeneral;
