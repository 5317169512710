import { FC } from 'react';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
// component
import RadioGroupController from 'controller/RadioGroupController';
// constants
import { VEGAN_MAPPED, VEGAN_TEXT } from 'constants/index';
import { DosageFormulationFormType } from 'interfaces';

const VeganTypeRadioGroup: FC = () => {
  const { setValue } = useFormContext<DosageFormulationFormType>();
  const onClearVegan = () => {
    setValue('veganType', '');
  };
  return (
    <Grid item xs={12} xl={12}>
      <RadioGroupController
        name="veganType"
        title={VEGAN_TEXT}
        options={VEGAN_MAPPED}
        onClear={onClearVegan}
      />
    </Grid>
  );
};

export default VeganTypeRadioGroup;
