import { FC, Fragment } from 'react';
import { Box, Card, Grid } from '@mui/material';
//components
import GummyForm from './GummyForm';
import TabletForm from './TabletForm';
import PowderForm from './PowderForm';
import CapsuleForm from './CapsuleForm';
import SelectController from 'controller/Select';
import InputController from 'controller/InputController';
import RadioGroupController from 'controller/RadioGroupController';
import ProductTypeSelector from 'components/common/radioGroups/productType';
import ProductSubTypeSelector from 'components/common/radioGroups/productSubType';
import ProductFormulationTypeSelector from 'components/common/selectors/productFormulationType';
//constant, styles, context, interfaces
import {
  FORM_TEXT,
  BOTTLE_CAP_TEXT,
  formulationValues,
  BOTTLE_CAP_MAPPED,
  PRODUCT_TYPE_TEXT,
  SERVING_UNIT_TEXT,
  SERVING_UNIT_MAPPED,
  DESIRED_SERVING_SIZE,
  SERVING_PER_CONTAINER,
  selectTypeInitialValues,
  PRODUCT_FORMULATION_TEXT,
  dosageFormulaFormInitialValues,
  productFormulationTypeInitialValues,
} from 'constants/index';
import { ProductTypes } from 'generated/graphql';
import { forInputLabelBg } from 'styles/commonComponentStyle';
import { ProductTypeSelect, SelectType, Step1Props } from 'interfaces';

const Step1: FC<Step1Props> = ({
  type,
  reset,
  xl = 7,
  setValue,
  resetBlends,
  factBoxType,
  productTypeId,
  productSubType,
  isFactBox = false,
  productTypeDispatch,
  productSubTypeDispatch,
  resetFormulaIngredients,
  productFormulationTypeDispatch,
}) => {
  const formView = () => {
    switch (type) {
      case ProductTypes.Powder:
        return <PowderForm isFactBox={isFactBox} />;

      case ProductTypes.Tablet:
        return <TabletForm />;

      case ProductTypes.Capsule:
        return <CapsuleForm />;

      case ProductTypes.Gummies:
        return <GummyForm isFactBox={isFactBox} />;

      case ProductTypes.Bars:
        return <Fragment />;

      case ProductTypes.Beverages:
        return <Fragment />;

      default:
        return <Fragment />;
    }
  };

  const handleOnChange = (item: ProductTypeSelect) => {
    const { value } = item || {};
    reset({ ...dosageFormulaFormInitialValues });
    setValue('productType', value, { shouldDirty: true });
    productTypeDispatch(item);
    productSubTypeDispatch(selectTypeInitialValues);
    productFormulationTypeDispatch(productFormulationTypeInitialValues);
  };

  const handleSubProductOnChange = (item: SelectType) => {
    reset({
      productType: productTypeId,
      productSubType: item?.value,
      productFormulationType: productFormulationTypeInitialValues,
      ...formulationValues,
    });
    productSubTypeDispatch(item);
    productFormulationTypeDispatch(productFormulationTypeInitialValues);
  };

  const handleProductFormulationChange = (val: ProductTypeSelect) => {
    reset({
      productType: productTypeId,
      productSubType: productSubType,
      ...formulationValues,
    });
    setValue('productFormulationType', val, { shouldDirty: true });
    productFormulationTypeDispatch(val);
    resetFormulaIngredients && resetFormulaIngredients();
    resetBlends && resetBlends();
  };

  const isSubTypeProduct = type === ProductTypes.Capsule || type === ProductTypes.Gummies;

  return (
    <Grid container spacing={2}>
      {/* Product Type Card  */}
      <Grid item xs={12} xl={xl}>
        <Card sx={forInputLabelBg}>
          <ProductTypeSelector
            isRequired
            name="productType"
            isCheckbox={false}
            factBoxType={factBoxType}
            title={PRODUCT_TYPE_TEXT}
            onChange={handleOnChange}
          />
        </Card>
      </Grid>
      {/* Product Formulation Type Card  */}
      <Grid item xs={12} xl={xl}>
        <Card sx={forInputLabelBg}>
          {isSubTypeProduct ? (
            <ProductSubTypeSelector
              isRequired
              name="productSubType"
              type={type ?? ''}
              title={FORM_TEXT}
              productTypeId={productTypeId}
              onChange={handleSubProductOnChange}
            />
          ) : (
            <Fragment />
          )}

          <Box mt={2}>
            <ProductFormulationTypeSelector
              isRequired
              title={PRODUCT_FORMULATION_TEXT}
              name="productFormulationType"
              productTypeId={productTypeId}
              productSubTypeId={isSubTypeProduct ? productSubType : ''}
              disabled={!(isSubTypeProduct ? productTypeId && productSubType : productTypeId)}
              handleChange={handleProductFormulationChange}
            />
          </Box>
        </Card>
      </Grid>

      {/* Common Form Fields  */}
      <Grid item xs={12} xl={xl}>
        <Card sx={forInputLabelBg}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={6}>
              <InputController
                isRequired
                fieldType="number"
                name="desiredServingSize"
                title={DESIRED_SERVING_SIZE}
              />
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              <SelectController
                name="servingUnit"
                options={SERVING_UNIT_MAPPED}
                title={SERVING_UNIT_TEXT}
                isRequired
              />
            </Grid>

            <Grid item xs={12} md={6} xl={6}>
              <InputController
                name="servingPerContainer"
                fieldType="number"
                title={SERVING_PER_CONTAINER}
                isRequired
              />
            </Grid>

            <Grid item xs={12} xl={12}>
              <RadioGroupController
                isRequired
                name="bottleCap"
                loaderColumns={6}
                title={BOTTLE_CAP_TEXT}
                options={BOTTLE_CAP_MAPPED}
              />
            </Grid>
            {/* Dynamic Forms For Formula Card  */}
            {formView()}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Step1;
