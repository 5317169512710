import { redirect } from 'react-router-dom';
import { createContext, FC, useCallback, useEffect, useState } from 'react';
// components
import { Alert } from 'components/common/Alert';
// graphql, interfaces, constants
import { AUTH_LINKS } from 'constants/routes';
import { HTTP_STATUS } from 'constants/index';
import { clearToken, getToken } from 'lib/helper';
import { AuthContextProps, ChildrenType } from 'interfaces';
import { useFetchUserLazyQuery, User, UserRoles, useUpdateConsentMutation } from 'generated/graphql';

export const AuthContext = createContext<AuthContextProps>({
  userRoles: [],
  isLoaded: false,
  isLoading: false,
  currentUser: null,
  isLoggedIn: false,
  setUser: (user: null | User) => {},
  setIsLoggedIn: (param: boolean) => {},
  setIsAcceptConsent: (param: boolean) => {},
});

export const AuthContextProvider: FC<ChildrenType> = ({ children }) => {
  const hasToken = getToken();

  const [currentUser, setUser] = useState<null | User>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [roles, setRoles] = useState<UserRoles[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [getUser, { loading: userLoading }] = useFetchUserLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { fetchUser } = data;
      const { response, user } = fetchUser;
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { id, userRoles } = user || {};
        if (id) {
          setUser(user as User);
          setIsLoggedIn(true);
          const roles = userRoles?.map<UserRoles>((item) => {
            const { role } = item || {};
            const { type } = role || {};
            return type ?? UserRoles.Admin;
          });
          setRoles(roles ?? []);
          setIsLoaded(true);
        } else {
          resetValue();
        }
      } else {
        resetValue();
      }
    },

    onError: () => {
      resetValue();
    },
  });

  const [updateConsent, { loading }] = useUpdateConsentMutation({
    onCompleted: (data) => {
      const { updateConsent } = data || {};
      const { response } = updateConsent || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setUser((prev) => ({ ...prev, isAccepted: true } as User));
      } else {
        Alert.warning(message || '');
      }
    },
    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const resetValue = () => {
    setUser(null);
    setRoles([]);
    setIsLoaded(true);
    clearToken();
    redirect(AUTH_LINKS.LOGIN_LINK);
  };

  const fetchUser = useCallback(async () => {
    if (hasToken) await getUser();
    else {
      setIsLoaded(true);
    }
  }, [getUser, hasToken]);

  const setIsAcceptConsent = async () => {
    await updateConsent();
  };

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const isLoading = userLoading || loading;

  return (
    <AuthContext.Provider
      value={{
        setUser,
        isLoaded,
        isLoading,
        isLoggedIn,
        currentUser,
        setIsLoggedIn,
        userRoles: roles,
        setIsAcceptConsent,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
