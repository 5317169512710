// constants, interfaces
import {
  selectTypeInitialValues,
  formulaIngredientFormInitialValues,
  dosageFormulationFormInitialValues,
  productFormulationTypeInitialValues,
} from 'constants/index';
import {
  SelectType,
  BlendItemType,
  ProductTypeSelect,
  DosageFormulationFormType,
  FormulaIngredientFormType,
} from 'interfaces';

export type State = {
  activeStep: number;
  subProductType: SelectType;
  productType: ProductTypeSelect;
  ingredientsBlend: BlendItemType[];
  formulation: FormulaIngredientFormType;
  formulaInfo: DosageFormulationFormType;
  productFormulationType: ProductTypeSelect;
};

export const initialState: State = {
  activeStep: 0,
  ingredientsBlend: [],
  subProductType: selectTypeInitialValues,
  formulation: formulaIngredientFormInitialValues,
  formulaInfo: dosageFormulationFormInitialValues,
  productType: productFormulationTypeInitialValues,
  productFormulationType: productFormulationTypeInitialValues,
};

export enum ActionType {
  SET_ACTIVE_STEP = 'setActiveStep',
  SET_FORMULATION = 'setFormulation',
  SET_PRODUCT_TYPE = 'setProductType',
  SET_FORMULA_INFO = 'setFormulaInfo',
  SET_BOTTLE_CAPACITY = 'setBottleCapacity',
  SET_SUB_PRODUCT_TYPE = 'setSubProductType',
  SET_INGREDIENTS_BLEND = 'setIngredientsBlend',
  SET_PRODUCT_FORMULATION_TYPE = 'setProductFormulationType',
}

export type Action =
  | { type: ActionType.SET_ACTIVE_STEP; activeStep: number }
  | { type: ActionType.SET_PRODUCT_TYPE; productType: ProductTypeSelect }
  | { type: ActionType.SET_SUB_PRODUCT_TYPE; subProductType: SelectType }
  | { type: ActionType.SET_FORMULA_INFO; formulaInfo: DosageFormulationFormType }
  | { type: ActionType.SET_FORMULATION; formulation: FormulaIngredientFormType }
  | { type: ActionType.SET_INGREDIENTS_BLEND; ingredientsBlend: BlendItemType[] }
  | {
      type: ActionType.SET_PRODUCT_FORMULATION_TYPE;
      productFormulationType: ProductTypeSelect;
    };

export const formulaIngredientReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.activeStep,
      };

    case ActionType.SET_PRODUCT_TYPE:
      return {
        ...state,
        productType: action.productType,
      };

    case ActionType.SET_INGREDIENTS_BLEND:
      return {
        ...state,
        ingredientsBlend: action.ingredientsBlend,
      };

    case ActionType.SET_SUB_PRODUCT_TYPE:
      return {
        ...state,
        subProductType: action.subProductType,
      };

    case ActionType.SET_PRODUCT_FORMULATION_TYPE:
      return {
        ...state,
        productFormulationType: action.productFormulationType,
      };

    case ActionType.SET_FORMULATION:
      return {
        ...state,
        formulation: action.formulation,
      };

    case ActionType.SET_FORMULA_INFO:
      return {
        ...state,
        formulaInfo: action.formulaInfo,
      };
  }
};
