import { Box } from '@mui/material';
import { FC, Fragment } from 'react';
// component
import SearchField from './SearchField';
// interface
import { TableSearchFieldProps } from 'interfaces';

const TableSearchField: FC<TableSearchFieldProps> = ({ onChange, search, withSpacing = true, onClear }) => {
  return (
    <Fragment>
      {withSpacing ? (
        <Box maxWidth={510} sx={{ px: { xs: 2, lg: 3 }, py: { xs: 3, lg: 3 } }}>
          <SearchField onChange={onChange} value={search} onClear={onClear} />
        </Box>
      ) : (
        <SearchField onChange={onChange} value={search} onClear={onClear} />
      )}
    </Fragment>
  );
};

export default TableSearchField;
