import React, { FC } from 'react';
import { Box, Card, Typography } from '@mui/material';
// styles, interfaces, constants
import palette from 'theme/palette';
import { flexCenter } from 'theme/styleConstant';
import { TOP_CONTRIBUTOR_TEXT } from 'constants/index';
import { TOP_CONTRIBUTOR_CARD_DATA } from 'constants/arrayMappedData';
import { flexCenterBetween, userAvatar } from 'styles/commonComponentStyle';

const DashboardTopContributorCard: FC = () => {
  return (
    <Card>
      <Typography variant="h6" mb={3}>
        {TOP_CONTRIBUTOR_TEXT}
      </Typography>

      {TOP_CONTRIBUTOR_CARD_DATA.map((item, index) => {
        const { avatar, name, formulas, icon, bgColor } = item;
        const isLastChild = index === TOP_CONTRIBUTOR_CARD_DATA.length - 1;

        return (
          <Box
            key={index}
            sx={{
              ...flexCenterBetween,
              ...(isLastChild && { marginBottom: 0 }),
            }}>
            <Box sx={flexCenter}>
              <Box width={40} height={40} mr={2} borderRadius="50%" sx={userAvatar}>
                <img src={avatar} alt="profile" />
              </Box>

              <Box>
                <Typography variant="subtitle2" mb={0.5} color={palette.common?.white}>
                  {name}
                </Typography>
                <Typography variant="body1" color="customGrayColor.main">
                  {formulas}
                </Typography>
              </Box>
            </Box>

            <Box width={40} height={40} mr={2} borderRadius="50%" bgcolor={bgColor} sx={flexCenter}>
              {icon}
            </Box>
          </Box>
        );
      })}
    </Card>
  );
};

export default DashboardTopContributorCard;
