import { createContext, FC, useState } from 'react';
// component
import { ExceptionModal } from 'components/common/ExceptionModal';
//graphql, interfaces, constants
import { ChildrenType, ExceptionalContextProps } from 'interfaces';

export const ExceptionalContext = createContext<ExceptionalContextProps>({
  showModal: (msgs: string[]) => {},
  hideModal: () => {},
});

export const ExceptionalContextProvider: FC<ChildrenType> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [msgs, setMsgs] = useState<string[]>([]);

  const showModal = (msg: string[]) => {
    setOpen(true);
    setMsgs(msg);
  };

  const hideModal = () => {
    setOpen(false);
    setMsgs([]);
  };

  return (
    <ExceptionalContext.Provider
      value={{
        showModal,
        hideModal,
      }}>
      <ExceptionModal open={open} messages={msgs} onClose={() => hideModal()} />
      {children}
    </ExceptionalContext.Provider>
  );
};
