import { Box, Typography } from '@mui/material';
import { FC, Fragment } from 'react';
// component
import RowView from 'components/common/RowView';
import DataLoader from 'components/common/DataLoader';
// constants, context, graphql
import {
  MG_TEXT,
  WEIGHT_TEXT,
  SERVING_TEXT,
  SYRUP_BASE_TEXT,
  SAND_COATING_TEXT,
  TOTAL_WEIGHT_TEXT,
  FLAVOR_WEIGHT_TEXT,
  COATING_WEIGHT_TEXT,
  EXCIPIENT_WEIGHT_TEXT,
  SWEETENER_WEIGHT_TEXT,
  INGREDIENT_WEIGHT_TEXT,
} from 'constants/index';
import { FormulaWeightCardProps } from 'interfaces';

const FormulaWeightCard: FC<FormulaWeightCardProps> = ({ costing, loading }) => {
  const { formulaWeights } = costing || {};

  const {
    totalWeight,
    flavorWeight,
    coatingWeight,
    sweetenerWeight,
    excipientWeight,
    syrupBaseWeight,
    sandCoatingWeight,
    ingredientsWeight,
  } = formulaWeights || {};

  return (
    <Fragment>
      {loading ? (
        <DataLoader columns={12} rows={6} />
      ) : (
        <Fragment>
          <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
            <Typography>
              {MG_TEXT} / {SERVING_TEXT}
            </Typography>
          </Box>

          <RowView name={INGREDIENT_WEIGHT_TEXT} value={ingredientsWeight ? `${ingredientsWeight}` : ''} />

          <RowView
            name={`${SYRUP_BASE_TEXT} ${WEIGHT_TEXT}`}
            value={syrupBaseWeight ? `${syrupBaseWeight}` : ''}
          />

          <RowView
            name={`${SAND_COATING_TEXT} ${WEIGHT_TEXT}`}
            value={sandCoatingWeight ? `${sandCoatingWeight}` : ''}
          />

          <RowView name={EXCIPIENT_WEIGHT_TEXT} value={excipientWeight ? `${excipientWeight}` : ''} />

          <RowView name={COATING_WEIGHT_TEXT} value={coatingWeight ? `${coatingWeight}` : ''} />

          <RowView name={FLAVOR_WEIGHT_TEXT} value={flavorWeight ? `${flavorWeight}` : ''} />

          <RowView name={SWEETENER_WEIGHT_TEXT} value={sweetenerWeight ? `${sweetenerWeight}` : ''} />
          <RowView name={TOTAL_WEIGHT_TEXT} value={totalWeight ? `${totalWeight}` : ''} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default FormulaWeightCard;
