import { FC } from 'react';
//components
import { AuthLayout } from 'components/common/AuthLayout';
import ResetPasswordComponent from 'components/Auth/ResetPassword';
//constants
import { SET_PASSWORD_TEXT } from 'constants/index';

const ResetPassword: FC = () => (
  <AuthLayout title={SET_PASSWORD_TEXT}>
    <ResetPasswordComponent />
  </AuthLayout>
);

export default ResetPassword;
