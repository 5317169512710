import { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
// components
import ExcipientFields from '../../../Excipients';
import InputController from 'controller/InputController';
import RadioGroupController from 'controller/RadioGroupController';
import TabletCoatingRadioGroup from 'components/common/radioGroups/TabletCoating';
// constants, graphql, interfaces, context
import {
  TABLET_COLOR_MAPPED,
  MINI_TABLET_COLOR_TEXT,
  COATING_COLORS_HEX_TEXT,
  MINI_TABLET_COATING_TEXT,
  TABLET_FORMULATION_MAPPED,
  MINI_TABLETS_FORMULATION_TEXT,
} from 'constants/index';
import { DosageFormulationFormType } from 'interfaces';
import { ProductTypes, TabletCoatingColor } from 'generated/graphql';

const MiniTabletInCap: FC = () => {
  const { watch, setValue } = useFormContext<DosageFormulationFormType>();
  const { coatingColor } = watch();

  const onTabletCoatingClear = () => {
    setValue('tabletCoating', '');
  };

  return (
    <Fragment>
      <Grid item xs={12} xl={12}>
        <RadioGroupController
          isRequired
          name="miniTabFormulation"
          title={MINI_TABLETS_FORMULATION_TEXT}
          options={TABLET_FORMULATION_MAPPED}
        />
      </Grid>
      <Grid item xs={12} xl={12}>
        <TabletCoatingRadioGroup
          name="tabletCoating"
          title={MINI_TABLET_COATING_TEXT}
          productType={ProductTypes.Tablet}
          onClear={onTabletCoatingClear}
        />
      </Grid>
      <Grid item xs={12} xl={12}>
        <RadioGroupController
          isRequired
          name="coatingColor"
          title={MINI_TABLET_COLOR_TEXT}
          options={TABLET_COLOR_MAPPED}
        />
      </Grid>
      {coatingColor === TabletCoatingColor.Multi ? (
        <Grid item xs={12} xl={12}>
          <InputController name="colorsHex" fieldType="color" title={COATING_COLORS_HEX_TEXT} isMultiValue />
        </Grid>
      ) : (
        <Fragment />
      )}

      <ExcipientFields />
    </Fragment>
  );
};

export default MiniTabletInCap;
