import { FC, Fragment, JSX } from 'react';
import { Box, Button, Card, CardActions, CardContent, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { ProductTypes } from 'generated/graphql';
// components
import CheckboxController from 'controller/Checkbox';
import InputController from 'controller/InputController';
import ProductTypesSelector from 'components/common/selectors/productType';
import ProductSubTypesSelector from 'components/common/selectors/productSubType';
// constants, styles, interfaces
import { AddProductFormulationTypeForm, ProductFormulationTypeFormProps, SelectType } from 'interfaces';
import {
  CONVERSION_COST_TEXT,
  INNER_CAPSULE_TABLET_TEXT,
  INNER_COMPONENT_COST_TEXT,
  LOH_COST_TEXT,
  NAME_TEXT,
  PRODUCT_TYPE_TEXT,
  SAVE_TEXT,
  selectTypeInitialValues,
  SUB_PRODUCT_TYPE_TEXT,
} from 'constants/index';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';

const ProductFormulationTypeForm: FC<ProductFormulationTypeFormProps> = ({
  isEdit = false,
  loading = false,
}): JSX.Element => {
  const { watch, setValue } = useFormContext<AddProductFormulationTypeForm>();
  const { productType } = watch();

  const handleProductTypeChange = (value: SelectType) => {
    const { type } = value || {};
    if (type === ProductTypes.Powder || type === ProductTypes.Tablet) {
      setValue('productSubType', selectTypeInitialValues);
    }
  };

  return (
    <Card sx={forInputLabelBg}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <InputController isRequired fieldType="text" name="name" disabled={loading} title={NAME_TEXT} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <InputController
              isRequired
              fieldType="number"
              name="lohInside"
              disabled={loading}
              title={LOH_COST_TEXT}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <InputController
              isRequired
              fieldType="number"
              name="conversionCost"
              disabled={loading}
              title={CONVERSION_COST_TEXT}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <InputController
              isRequired
              fieldType="number"
              name="innerComponentCost"
              disabled={loading}
              title={INNER_COMPONENT_COST_TEXT}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <ProductTypesSelector
              title={PRODUCT_TYPE_TEXT}
              isRequired
              name="productType"
              handleChange={handleProductTypeChange}
            />
          </Grid>

          <Grid item xs={12} lg={12}>
            <ProductSubTypesSelector
              name="productSubType"
              title={SUB_PRODUCT_TYPE_TEXT}
              disabled={!productType?.value}
              productTypeId={productType?.value}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <CheckboxController name="isInnerCapTab" title={INNER_CAPSULE_TABLET_TEXT} loading={loading} />
          </Grid>
        </Grid>
      </CardContent>

      {isEdit ? (
        <CardActions>
          <Box sx={flexMarginTopEndCenter}>
            <Button type="submit" variant="contained" size="small" disabled={loading}>
              {SAVE_TEXT}
            </Button>
          </Box>
        </CardActions>
      ) : (
        <Fragment />
      )}
    </Card>
  );
};

export default ProductFormulationTypeForm;
