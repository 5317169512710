import { FC, Fragment, useContext } from 'react';
import {
  ExpandMore as ExpandMoreIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
// icons
import { ActiveDotIcon, SmallDotIcon } from '../../../assets/svgs';
// interfaces, context
import { SidebarSubListListProps } from '../../../interfaces';
import { DrawerContext } from '../../../contexts/DrawerContext';

const SidebarSubList: FC<SidebarSubListListProps> = ({ list, defaultRoute, mainTitle, icon }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setMobileOpen } = useContext(DrawerContext);

  const open = list?.some(({ route }) => route === pathname);

  const routeChange = (path: string) => {
    navigate(path);
    setMobileOpen(false);
  };

  return (
    <Fragment>
      <ListItem disablePadding>
        <ListItemButton
          // selected={open}
          onClick={() => routeChange(defaultRoute)}
          className={open ? 'selectedList' : ''}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={mainTitle} />
          <ListItemIcon>
            {open ? <ExpandMoreIcon color="primary" /> : <KeyboardArrowRightIcon />}
          </ListItemIcon>
        </ListItemButton>
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          {list?.map((item, index) => {
            const { route, text } = item;
            const isActive = pathname === route;

            return (
              <ListItemButton
                selected={isActive}
                key={`${text}-${index}`}
                id={route?.replace('/', '')}
                onClick={() => routeChange(route)}
                className={isActive ? 'selectedList' : ''}>
                <ListItemIcon sx={{ pl: 2 }}>{isActive ? ActiveDotIcon : SmallDotIcon}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default SidebarSubList;
