import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import DataLoader from 'components/common/DataLoader';
import { BackdropLoader } from 'components/common/BackdropLoader';
import SweetenerComponentForm from './components/SweetenerComponentForm';
// Schema, styles, graphql, constants
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  SweetenerComponentPayload,
  useCreateSweetenerComponentMutation,
  useGetSweetenerComponentLazyQuery,
  useUpdateSweetenerComponentMutation,
} from 'generated/graphql';
import {
  HTTP_STATUS,
  sweetenerComponentInitialValue,
  SWEETENER_COMPONENT_IS_UPDATED_TEXT,
  EDIT_SWEETENER_COMPONENT_TEXT,
  ADD_SWEETENER_COMPONENT_TEXT,
  ADD_TEXT,
  SWEETENER_COMPONENT_IS_CREATING_TEXT,
} from 'constants/index';
import { AddSweetenerComponentFormType, ParamType } from 'interfaces';
import { updateSweetenerComponentTypeSchema } from 'validationSchema';

const Edit: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const sweetenerComponentMethods = useForm<AddSweetenerComponentFormType>({
    defaultValues: sweetenerComponentInitialValue,
    resolver: yupResolver(updateSweetenerComponentTypeSchema),
  });

  const { handleSubmit, setValue } = sweetenerComponentMethods;

  const [createSweetenerComponent, { loading: createLoading }] = useCreateSweetenerComponentMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createSweetenerComponent } = data;
      const { response } = createSweetenerComponent || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updateSweetenerComponent, { loading: updateLoading }] = useUpdateSweetenerComponentMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateSweetenerComponent } = data;
      const { response } = updateSweetenerComponent || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getSweetenerComponent, { loading: getLoading }] = useGetSweetenerComponentLazyQuery({
    onCompleted: (data) => {
      const { getSweetenerComponent } = data || {};
      const { response, sweetenerComponent } = getSweetenerComponent || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(sweetenerComponent as SweetenerComponentPayload['sweetenerComponent']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });

  const onSweetenerComponentSubmit: SubmitHandler<AddSweetenerComponentFormType> = async (data) => {
    const { costPerKg, name } = data || {};

    if (id) {
      await updateSweetenerComponent({
        variables: {
          updateSweetenerComponentInput: {
            id: id || '',
            name,
            costPerKg: parseFloat(costPerKg),
          },
        },
      });
    } else {
      await createSweetenerComponent({
        variables: {
          createSweetenerComponentInput: {
            name,
            costPerKg: parseFloat(costPerKg),
          },
        },
      });
    }
  };

  const fetchSweetenerComponent = useCallback(async () => {
    id &&
      (await getSweetenerComponent({
        variables: {
          getSweetenerComponentInput: {
            id,
          },
        },
      }));
  }, [id, getSweetenerComponent]);

  const setValues = (params: SweetenerComponentPayload['sweetenerComponent']) => {
    const { name, costPerKg } = params || {};

    setValue('name', name || '');
    setValue('costPerKg', `${costPerKg || '0'}`);
  };

  useEffect(() => {
    id && fetchSweetenerComponent();
  }, [id, fetchSweetenerComponent]);

  const loading = updateLoading || createLoading;

  return (
    <Fragment>
      <FormProvider {...sweetenerComponentMethods}>
        <form onSubmit={handleSubmit(onSweetenerComponentSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">
              {id ? EDIT_SWEETENER_COMPONENT_TEXT : ADD_SWEETENER_COMPONENT_TEXT}
            </Typography>
            {!id && (
              <Button variant="contained" type="submit">
                {ADD_TEXT}
              </Button>
            )}
          </Box>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12}>
              {getLoading ? (
                <DataLoader columns={12} rows={6} />
              ) : (
                <SweetenerComponentForm loading={loading} isEdit={!!id} />
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <BackdropLoader
        open={loading}
        text={id ? SWEETENER_COMPONENT_IS_UPDATED_TEXT : SWEETENER_COMPONENT_IS_CREATING_TEXT}
      />
    </Fragment>
  );
};

export default Edit;
