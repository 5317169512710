import {
  FC,
  Reducer,
  useMemo,
  Fragment,
  useState,
  useEffect,
  useReducer,
  useCallback,
  SyntheticEvent,
} from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import EditGeneral from './EditGeneral';
import { Alert } from 'components/common/Alert';
import Carriers from '../Add/components/Carriers';
import TabPanel from 'components/common/TabPanel';
import Allergens from '../Add/components/Allergens';
import Nutrients from '../Add/components/Nutrients';
import HeavyMetals from '../Add/components/HeavyMetals';
import SubComponent from '../Add/components/SubComponent';
// interfaces , constants , validationSchema, graphql, reducer, helpers
import {
  HTTP_STATUS,
  INGREDIENT_TABS,
  IngredientTabsEnum,
  nutrientsInitialValues,
  heavyMetalsInitialValues,
  allergensCardInitialValue,
  carriersFormTypeInitialValues,
  subComponentFormTypeInitialValue,
  ingredientCostInfoCardInitialValues,
} from 'constants/index';
import {
  CarriersFormType,
  NutrientsCardType,
  CarrierSelectType,
  AllergensCardType,
  HeavyMetalsFormType,
  SubComponentsFormType,
  SubComponentSelectType,
  EditIngredientFormProps,
  IngredientCostInfoCardType,
} from 'interfaces';
import {
  carriersSchema,
  nutrientsSchema,
  heavyMetalSchema,
  subComponentSchema,
  allergensCardSchema,
  ingredientCostInfoCardSchema,
} from 'validationSchema';
import {
  useUpdateIngredientInfoMutation,
  useUpdateIngredientCarriersMutation,
  useUpdateIngredientAllergensMutation,
  useUpdateIngredientSubComponentsMutation,
} from 'generated/graphql';
import {
  formatCarriers,
  formatIngredientCarriers,
  formatIngredientSubComponents,
  formatSubComponents,
} from 'lib/helper';
import { Action, ActionType, State, ingredientReducer, initialState } from 'reducer/ingredientReducer';

const EditIngredientForm: FC<EditIngredientFormProps> = ({
  ingredient,
  fetchIngredient,
  loading: getLoading,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [state, dispatch] = useReducer<Reducer<State, Action>>(ingredientReducer, initialState);
  const { activeTab } = state;
  const { ingredientInfo } = ingredient || {};
  const { ingredientId } = ingredientInfo || {};

  const tabHandler = (_: SyntheticEvent, activeTab: IngredientTabsEnum) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab });
  };

  const nutrientMethods = useForm<NutrientsCardType>({
    defaultValues: nutrientsInitialValues,
    resolver: yupResolver(nutrientsSchema),
  });

  const heavyMetalsMethods = useForm<HeavyMetalsFormType>({
    defaultValues: heavyMetalsInitialValues,
    resolver: yupResolver(heavyMetalSchema),
  });

  const allergenMethods = useForm<AllergensCardType>({
    defaultValues: allergensCardInitialValue,
    resolver: yupResolver(allergensCardSchema),
  });

  const costMethods = useForm<IngredientCostInfoCardType>({
    defaultValues: ingredientCostInfoCardInitialValues,
    resolver: yupResolver(ingredientCostInfoCardSchema),
  });

  const carriersMethod = useForm<CarriersFormType>({
    defaultValues: carriersFormTypeInitialValues,
    resolver: yupResolver(carriersSchema),
  });

  const subComponentsMethod = useForm<SubComponentsFormType>({
    defaultValues: subComponentFormTypeInitialValue,
    resolver: yupResolver(subComponentSchema),
  });

  const { watch } = costMethods;
  const { amount, nutrients } = watch();
  const { handleSubmit: handleNutrientSubmit, setValue: setNutrientValue } = nutrientMethods;
  const { handleSubmit: handleAllergenSubmit, setValue: setAllergenValue } = allergenMethods;
  const { handleSubmit: handleHeavyMetalSubmit, setValue: setHeavyMetalValue } = heavyMetalsMethods;
  const { handleSubmit: handleCarrierSubmit, setValue: setCarrierValue } = carriersMethod;
  const { handleSubmit: handleSubComponentSubmit, setValue: setSubComponentValue } = subComponentsMethod;

  const [updateIngredientInfo, { loading: updateIngredientInfoLoading }] = useUpdateIngredientInfoMutation({
    onCompleted: (data) => {
      const { updateIngredientInfo } = data;
      const { response } = updateIngredientInfo || {};
      const { status, message } = response || {};
      if (message && status === HTTP_STATUS.SUCCESS) {
        Alert.success(message);
      } else {
        Alert.warning(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  // call the mutation to  updateIngredientCarriers

  const [updateIngredientCarriers, { loading: updateIngredientCarriersLoading }] =
    useUpdateIngredientCarriersMutation({
      onCompleted: (data) => {
        const { updateIngredientCarriers } = data;
        const { response } = updateIngredientCarriers || {};
        const { status, message } = response || {};
        if (message && status === HTTP_STATUS.SUCCESS) {
          Alert.success(message);
        } else {
          Alert.warning(message ?? '');
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    });

  const [updateIngredientAllergens, { loading: updateIngredientAllergensLoading }] =
    useUpdateIngredientAllergensMutation({
      onCompleted: (data) => {
        const { updateIngredientAllergens } = data;
        const { response } = updateIngredientAllergens || {};
        const { status, message } = response || {};
        if (message && status === HTTP_STATUS.SUCCESS) {
          Alert.success(message);
        } else {
          Alert.warning(message ?? '');
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    });

  const onAllergenSubmit: SubmitHandler<AllergensCardType> = async (data) => {
    const { allergens } = data || {};
    if (ingredientId) {
      await updateIngredientAllergens({
        variables: {
          updateIngredientAllergensInput: {
            ingredientId: ingredientId,
            ingredientAllergens: allergens,
          },
        },
      });
    }
  };

  const onNutrientSubmit: SubmitHandler<NutrientsCardType> = async (data) => {
    const { ingredientInfo } = data || {};
    const {
      addedSugar,
      biotin,
      boron,
      calcium,
      calories,
      carbohydrates,
      cholesterol,
      choline,
      dietaryFiber,
      folate,
      iodine,
      iron,
      magnesium,
      moisture,
      monounsaturatedFat,
      niacin,
      organic,
      polyunsaturatedFat,
      potassium,
      protein,
      riboflavin,
      saturatedFat,
      sodium,
      thiamin,
      totalFat,
      totalSugars,
      transFat,
      vitaminA,
      vitaminB12,
      vitaminB6,
      vitaminC,
      vitaminD,
      vitaminE,
      vitaminK2,
      zinc,
      chloride,
      chromium,
      copper,
      manganese,
      molybdenum,
      pantothenicAcid,
      phosphorus,
      selenium,
    } = ingredientInfo || {};

    if (ingredientId) {
      await updateIngredientInfo({
        variables: {
          updateIngredientInfoInput: {
            ingredientId: ingredientId,
            zinc: zinc,
            iron: iron,
            boron: boron,
            iodine: iodine,
            biotin: biotin,
            folate: folate,
            sodium: sodium,
            niacin: niacin,
            calcium: calcium,
            choline: choline,
            organic: organic,
            protein: protein,
            thiamin: thiamin,
            calories: calories,
            moisture: moisture,
            totalFat: totalFat,
            transFat: transFat,
            vitaminA: vitaminA,
            vitaminC: vitaminC,
            vitaminD: vitaminD,
            vitaminE: vitaminE,
            magnesium: magnesium,
            potassium: potassium,
            vitaminB6: vitaminB6,
            vitaminK2: vitaminK2,
            addedSugar: addedSugar,
            vitaminB12: vitaminB12,
            copper: Number(copper),
            riboflavin: riboflavin,
            totalSugars: totalSugars,
            cholesterol: cholesterol,
            dietaryFiber: dietaryFiber,
            saturatedFat: saturatedFat,
            chromium: Number(chromium),
            selenium: Number(selenium),
            chloride: Number(chloride),
            carbohydrates: carbohydrates,
            manganese: Number(manganese),
            phosphorus: Number(phosphorus),
            molybdenum: Number(molybdenum),
            monounsaturatedFat: monounsaturatedFat,
            polyunsaturatedFat: polyunsaturatedFat,
            pantothenicAcid: Number(pantothenicAcid),
          },
        },
      });
    }
  };

  const onHeavyMetalSubmit: SubmitHandler<HeavyMetalsFormType> = async (data) => {
    const { arsenic, cadmium, lead, mercury } = data || {};

    if (ingredientId) {
      await updateIngredientInfo({
        variables: {
          updateIngredientInfoInput: {
            ingredientId: ingredientId,
            arsenic,
            cadmium,
            lead,
            mercury,
          },
        },
      });
    }
  };

  const [updateIngredientSubComponents, { loading: UpdateIngredientSubComponentsLoading }] =
    useUpdateIngredientSubComponentsMutation({
      onCompleted: (data) => {
        const { updateIngredientSubComponents } = data;
        const { response } = updateIngredientSubComponents || {};
        const { status, message } = response || {};
        if (message && status === HTTP_STATUS.SUCCESS) {
          Alert.success(message);
        } else {
          Alert.warning(message ?? '');
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    });

  const onIngredientSubComponentsSubmit: SubmitHandler<SubComponentsFormType> = async (data) => {
    const { subComponents } = data || {};
    const subComponentsList = formatSubComponents(subComponents);
    if (ingredientId) {
      await updateIngredientSubComponents({
        variables: {
          updateIngredientSubComponentsInput: {
            ingredientId: ingredientId,
            subComponents: subComponentsList,
          },
        },
      });
    }
  };

  const onCarrierSubmit: SubmitHandler<CarriersFormType> = async (data) => {
    const { carriers } = data || {};
    const carriersList = formatCarriers(carriers);
    if (ingredientId) {
      await updateIngredientCarriers({
        variables: {
          updateIngredientCarriersInput: {
            ingredientId: ingredientId,
            carriers: carriersList,
          },
        },
      });
    }
  };

  const setValues = useCallback(() => {
    const { ingredientInfo, ingredientAllergens, ingredientCarriers, ingredientSubComponents } =
      ingredient || {};
    const allergensList = ingredientAllergens?.map((item) => item?.allergen?.id || '') || [];

    const carriersList = formatIngredientCarriers(ingredientCarriers ?? []);
    const subComponentList = formatIngredientSubComponents(ingredientSubComponents ?? []);

    const {
      addedSugar,
      arsenic,
      biotin,
      boron,
      cadmium,
      calcium,
      calories,
      carbohydrates,
      cholesterol,
      choline,
      dietaryFiber,
      folate,
      iodine,
      iron,
      lead,
      magnesium,
      mercury,
      moisture,
      monounsaturatedFat,
      niacin,
      organic,
      polyunsaturatedFat,
      potassium,
      protein,
      riboflavin,
      saturatedFat,
      sodium,
      thiamin,
      totalFat,
      totalSugars,
      transFat,
      vitaminA,
      vitaminB12,
      vitaminB6,
      vitaminC,
      vitaminD,
      vitaminE,
      vitaminK2,
      zinc,
      chloride,
      copper,
      chromium,
      manganese,
      molybdenum,
      pantothenicAcid,
      phosphorus,
      selenium,
    } = ingredientInfo || {};

    // setting allergens values
    ingredientAllergens && setAllergenValue('allergens', allergensList);
    // setting nutrients values
    lead && setHeavyMetalValue('lead', `${lead}`);
    arsenic && setHeavyMetalValue('arsenic', `${arsenic}`);
    mercury && setHeavyMetalValue('mercury', `${mercury}`);
    cadmium && setHeavyMetalValue('cadmium', `${cadmium}`);
    addedSugar && setNutrientValue('ingredientInfo.addedSugar', `${addedSugar}`);
    organic && setNutrientValue('ingredientInfo.organic', `${organic}`);
    biotin && setNutrientValue('ingredientInfo.biotin', `${biotin}`);
    boron && setNutrientValue('ingredientInfo.boron', `${boron}`);
    calcium && setNutrientValue('ingredientInfo.calcium', `${calcium}`);
    cholesterol && setNutrientValue('ingredientInfo.cholesterol', `${cholesterol}`);
    carbohydrates && setNutrientValue('ingredientInfo.carbohydrates', `${carbohydrates}`);
    choline && setNutrientValue('ingredientInfo.choline', `${choline}`);
    dietaryFiber && setNutrientValue('ingredientInfo.dietaryFiber', `${dietaryFiber}`);
    folate && setNutrientValue('ingredientInfo.folate', `${folate}`);
    iodine && setNutrientValue('ingredientInfo.iodine', `${iodine}`);
    iron && setNutrientValue('ingredientInfo.iron', `${iron}`);
    magnesium && setNutrientValue('ingredientInfo.magnesium', `${magnesium}`);
    moisture && setNutrientValue('ingredientInfo.moisture', `${moisture}`);
    monounsaturatedFat && setNutrientValue('ingredientInfo.monounsaturatedFat', `${monounsaturatedFat}`);
    niacin && setNutrientValue('ingredientInfo.niacin', `${niacin}`);
    polyunsaturatedFat && setNutrientValue('ingredientInfo.polyunsaturatedFat', `${polyunsaturatedFat}`);
    potassium && setNutrientValue('ingredientInfo.potassium', `${potassium}`);
    protein && setNutrientValue('ingredientInfo.protein', `${protein}`);
    riboflavin && setNutrientValue('ingredientInfo.riboflavin', `${riboflavin}`);
    saturatedFat && setNutrientValue('ingredientInfo.saturatedFat', `${saturatedFat}`);
    sodium && setNutrientValue('ingredientInfo.sodium', `${sodium}`);
    calories && setNutrientValue('ingredientInfo.calories', `${calories}`);
    thiamin && setNutrientValue('ingredientInfo.thiamin', `${thiamin}`);
    totalFat && setNutrientValue('ingredientInfo.totalFat', `${totalFat}`);
    totalSugars && setNutrientValue('ingredientInfo.totalSugars', `${totalSugars}`);
    transFat && setNutrientValue('ingredientInfo.transFat', `${transFat}`);
    vitaminA && setNutrientValue('ingredientInfo.vitaminA', `${vitaminA}`);
    vitaminB12 && setNutrientValue('ingredientInfo.vitaminB12', `${vitaminB12}`);
    vitaminB6 && setNutrientValue('ingredientInfo.vitaminB6', `${vitaminB6}`);
    vitaminC && setNutrientValue('ingredientInfo.vitaminC', `${vitaminC}`);
    vitaminD && setNutrientValue('ingredientInfo.vitaminD', `${vitaminD}`);
    vitaminE && setNutrientValue('ingredientInfo.vitaminE', `${vitaminE}`);
    vitaminK2 && setNutrientValue('ingredientInfo.vitaminK2', `${vitaminK2}`);
    zinc && setNutrientValue('ingredientInfo.zinc', `${zinc}`);
    chloride && setNutrientValue('ingredientInfo.chloride', `${chloride}`);
    selenium && setNutrientValue('ingredientInfo.selenium', `${selenium}`);
    molybdenum && setNutrientValue('ingredientInfo.molybdenum', `${molybdenum}`);
    chromium && setNutrientValue('ingredientInfo.chromium', `${chromium}`);
    copper && setNutrientValue('ingredientInfo.copper', `${copper}`);
    phosphorus && setNutrientValue('ingredientInfo.phosphorus', `${phosphorus}`);
    manganese && setNutrientValue('ingredientInfo.manganese', `${manganese}`);
    pantothenicAcid && setNutrientValue('ingredientInfo.pantothenicAcid', `${pantothenicAcid}`);
    // setting carriers values
    ingredientCarriers && setCarrierValue('carriers', carriersList as CarrierSelectType[]);
    // setting subComponents values
    ingredientSubComponents &&
      setSubComponentValue('subComponents', subComponentList as SubComponentSelectType[]);
  }, [
    ingredient,
    setAllergenValue,
    setHeavyMetalValue,
    setNutrientValue,
    setCarrierValue,
    setSubComponentValue,
  ]);

  useEffect(() => {
    ingredient && setValues();
  }, [setValues, ingredient]);

  const loading =
    getLoading ||
    updateIngredientInfoLoading ||
    updateIngredientAllergensLoading ||
    updateIngredientCarriersLoading ||
    UpdateIngredientSubComponentsLoading;

  const tabMappedData = useMemo(() => {
    if (amount === '100' || (amount === '1' && nutrients)) {
      return INGREDIENT_TABS;
    } else {
      return INGREDIENT_TABS?.filter(({ value }) => value !== IngredientTabsEnum.NUTRIENTS);
    }
  }, [amount, nutrients]);

  return (
    <Fragment>
      <Tabs value={activeTab} onChange={tabHandler} textColor="inherit" variant="scrollable">
        {tabMappedData?.map((item) => {
          const { name, value } = item;
          return <Tab value={value} key={value} label={name} iconPosition="start" />;
        })}
      </Tabs>

      <Box pt={6}>
        <TabPanel activeTab={activeTab} value={IngredientTabsEnum.GENERAL}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <EditGeneral
                fetchIngredient={fetchIngredient}
                loading={getLoading}
                ingredient={ingredient}
                costMethods={costMethods}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel activeTab={activeTab} value={IngredientTabsEnum.NUTRIENTS}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <FormProvider {...nutrientMethods}>
                <form onSubmit={handleNutrientSubmit(onNutrientSubmit)}>
                  <Nutrients loading={loading} isEdit />
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel activeTab={activeTab} value={IngredientTabsEnum.ALLERGENS}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <FormProvider {...allergenMethods}>
                <form onSubmit={handleAllergenSubmit(onAllergenSubmit)}>
                  <Allergens loading={loading} isEdit />
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel activeTab={activeTab} value={IngredientTabsEnum.HEAVY_METALS}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <FormProvider {...heavyMetalsMethods}>
                <form onSubmit={handleHeavyMetalSubmit(onHeavyMetalSubmit)}>
                  <HeavyMetals loading={getLoading} isEdit />
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel activeTab={activeTab} value={IngredientTabsEnum.CARRIERS}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <FormProvider {...carriersMethod}>
                <form onSubmit={handleCarrierSubmit(onCarrierSubmit)}>
                  <Carriers loading={loading} isEdit />
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel activeTab={activeTab} value={IngredientTabsEnum.SUB_COMPONENT}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <FormProvider {...subComponentsMethod}>
                <form onSubmit={handleSubComponentSubmit(onIngredientSubComponentsSubmit)}>
                  <SubComponent loading={loading} isEdit />
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Fragment>
  );
};

export default EditIngredientForm;
