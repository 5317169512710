import { FC, Fragment, useMemo } from 'react';
import { Box, Chip, Divider, Grid, IconButton, Typography } from '@mui/material';
// components
import { CloseIcon } from 'assets/svgs';
import SelectController from 'controller/Select';
import InputController from 'controller/InputController';
// constants, interfaces, graphql, helper
import { formatValue, getPotencyValues, getType } from 'lib/helper';
import { ProductFormulationTypes } from 'generated/graphql';
import { IngredientItemType, IngredientsCardProps, SelectType } from 'interfaces';
import {
  INACTIVE_TEXT,
  LABEL_CLAIM_TEXT,
  POTENCY_TEXT,
  TYPE_TEXT,
  UNIT_SELECT_MAPPED,
  UNIT_TEXT,
  VALUE_TEXT,
} from 'constants/index';

const IngredientsCard: FC<IngredientsCardProps> = ({
  xl = 3,
  fields,
  onRemove,
  productType,
  isFactBox = false,
  productFormulationType,
}) => {
  const { type } = productFormulationType || {};

  const formulationType = useMemo(() => {
    if (productType && type) {
      return getType(productType, type as ProductFormulationTypes);
    }
    return [];
  }, [type, productType]);

  return (
    <Grid container mt={2}>
      {fields?.map((item: IngredientItemType, index) => {
        const { name, ingredientId, ingredientPotency, isActive } = item || {};
        const potencyObj: SelectType[] = getPotencyValues(ingredientPotency ?? 1);

        return (
          <Fragment key={ingredientId}>
            <Grid item xl={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="body2"
                      color={isActive ? 'primary.main' : 'error'}
                      fontWeight={600}
                      flex={1}>
                      {name}
                      {!isActive && (
                        <Chip
                          sx={{ ml: 1 }}
                          size="small"
                          variant="outlined"
                          label={formatValue(INACTIVE_TEXT)}
                          color="error"
                        />
                      )}
                    </Typography>

                    <Box mx={1}>
                      <IconButton onClick={() => onRemove(index)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xl={xl} lg={6} md={6} sm={12} xs={12}>
                  <InputController
                    isRequired
                    fieldType="number"
                    name={`formulaIngredients.${index}.value`}
                    title={`${VALUE_TEXT} (${LABEL_CLAIM_TEXT})`}
                  />
                </Grid>

                <Grid item xl={xl} lg={6} md={6} sm={12} xs={12}>
                  <SelectController
                    isRequired
                    title={UNIT_TEXT}
                    options={UNIT_SELECT_MAPPED}
                    name={`formulaIngredients.${index}.unit`}
                  />
                </Grid>

                <Grid item xl={xl} lg={6} md={6} sm={12} xs={12}>
                  <SelectController
                    title={`${POTENCY_TEXT} %`}
                    options={potencyObj}
                    name={`formulaIngredients.${index}.potency`}
                  />
                </Grid>

                {isFactBox ? (
                  <Fragment />
                ) : (
                  <Grid item xl={xl} lg={6} md={6} sm={12} xs={12}>
                    <SelectController
                      isRequired
                      title={TYPE_TEXT}
                      options={formulationType}
                      name={`formulaIngredients.${index}.type`}
                    />
                  </Grid>
                )}

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} marginY={2}>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
};

export default IngredientsCard;
