// imports
import { FC } from 'react';
// component
import SidebarMain from 'components/common/SidebarMain';
//constants
import { ADMIN_GENERAL_LIST } from 'constants/index';

const GeneralList: FC = () => <SidebarMain list={ADMIN_GENERAL_LIST} />;

export default GeneralList;
