import { FC, Fragment } from 'react';
// components
import RowView from 'components/common/RowView';
import DataLoader from 'components/common/DataLoader';
// constants, context, graphql
import {
  COST_TEXT,
  LOH_COST_TEXT,
  SYRUP_BASE_TEXT,
  FLAVOR_COST_TEXT,
  BOTTLE_COST_TEXT,
  COATING_COST_TEXT,
  SAND_COATING_TEXT,
  NECKBAND_COST_TEXT,
  SWEETENER_COST_TEXT,
  EXCIPIENT_COST_TEXT,
  CONVERSION_COST_TEXT,
  INGREDIENTS_COST_TEXT,
} from 'constants/index';
import { FormulaCostPercentageCardProps } from 'interfaces';

const FormulaCostPercentageCard: FC<FormulaCostPercentageCardProps> = ({ costing, loading }) => {
  const { formulaCostPercentage } = costing || {};

  const {
    lohCost,
    neckband,
    flavorCost,
    coatingCost,
    bottlePrice,

    sweetenerCost,
    excipientCost,
    conversionCost,
    ingredientsCost,

    sandCoatingCost,
    syrupBaseCost,
  } = formulaCostPercentage || {};

  return (
    <Fragment>
      {loading ? (
        <DataLoader columns={12} rows={6} />
      ) : (
        <Fragment>
          <RowView name={BOTTLE_COST_TEXT} value={bottlePrice ? `${bottlePrice}%` : ''} />

          <RowView name={NECKBAND_COST_TEXT} value={neckband ? `${neckband}%` : ''} />

          <RowView name={CONVERSION_COST_TEXT} value={conversionCost ? `${conversionCost}%` : ''} />

          <RowView name={INGREDIENTS_COST_TEXT} value={ingredientsCost ? `${ingredientsCost}%` : ''} />

          <RowView name={EXCIPIENT_COST_TEXT} value={excipientCost ? `${excipientCost}%` : ''} />

          <RowView name={COATING_COST_TEXT} value={coatingCost ? `${coatingCost}%` : ''} />

          <RowView
            name={`${SAND_COATING_TEXT} ${COST_TEXT}`}
            value={sandCoatingCost ? `${sandCoatingCost}%` : ''}
          />

          <RowView
            name={`${SYRUP_BASE_TEXT} ${COST_TEXT}`}
            value={syrupBaseCost ? `${syrupBaseCost}%` : ''}
          />

          <RowView name={SWEETENER_COST_TEXT} value={sweetenerCost ? `${sweetenerCost}%` : ''} />

          <RowView name={FLAVOR_COST_TEXT} value={flavorCost ? `${flavorCost}%` : ''} />

          <RowView name={LOH_COST_TEXT} value={lohCost ? `${lohCost}%` : ''} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default FormulaCostPercentageCard;
