import { FC } from 'react';
import { CircleRounded as CircleRoundedIcon } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
// interface, constant
import { ExceptionModalProps } from 'interfaces';
import { CLOSE_TEXT, SERVER_ERRORS } from 'constants/index';

export const ExceptionModal: FC<ExceptionModalProps> = ({ open, messages, onClose }) => {
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle textAlign="center">{SERVER_ERRORS}</DialogTitle>
      <DialogContent>
        {messages?.map((msg) => (
          <Box display="flex" alignItems="center" mt={1} key={msg}>
            <CircleRoundedIcon fontSize="inherit" />
            <Typography ml={1} variant="caption">
              {msg}
            </Typography>
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {CLOSE_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
