import { createContext, FC, useState } from 'react';
// interfaces
import { ChildrenType, DrawerContextProps } from 'interfaces';

export const DrawerContext = createContext<DrawerContextProps>({
  mobileOpen: false,
  setMobileOpen: (param: boolean) => {},
});

export const DrawerContextProvider: FC<ChildrenType> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  return (
    <DrawerContext.Provider
      value={{
        mobileOpen,
        setMobileOpen,
      }}>
      {children}
    </DrawerContext.Provider>
  );
};
