import React, { FC, Reducer, createContext, useReducer } from 'react';
// interfaces, reducers
import { ChildrenType, FormulaContextType } from 'interfaces';
import { Action, State, addFormulaReducer, initialState } from 'reducer/addFormulaReducer';

export const FormulaContext = createContext<FormulaContextType>({
  state: initialState,
  dispatch: () => {},
});

export const FormulaContextProvider: FC<ChildrenType> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(addFormulaReducer, initialState);

  return <FormulaContext.Provider value={{ state, dispatch }}>{children}</FormulaContext.Provider>;
};
