import { FC } from 'react';
import { Box } from '@mui/material';
// styles
import { tabComponentPaper } from 'styles/commonComponentStyle';
// interface
import { ChildrenType } from 'interfaces';

const TableContainer: FC<ChildrenType> = ({ children }) => {
  return <Box sx={tabComponentPaper}>{children}</Box>;
};

export default TableContainer;
