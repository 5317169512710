import { FC } from 'react';
import { Box } from '@mui/material';

const EmptyTableTab: FC = () => {
  return (
    <Box
      height={48}
      bgcolor={({ palette }) => palette.customGrayColor.dark}
      borderRadius={({ spacing }) => spacing(2, 2, 0, 0)}
      mb={({ spacing }) => spacing(2)}
    />
  );
};

export default EmptyTableTab;
