import { FC } from 'react';
import { Grid } from '@mui/material';
// components
import RadioGroupController from 'controller/RadioGroupController';
// constants
import { PROTEIN_BASE_MAPPED, PROTEIN_BASE_TEXT } from 'constants/index';

const Protein: FC = () => (
  <Grid item xs={12} xl={12}>
    <RadioGroupController
      isRequired
      name="proteinBase"
      title={PROTEIN_BASE_TEXT}
      options={PROTEIN_BASE_MAPPED}
    />
  </Grid>
);

export default Protein;
