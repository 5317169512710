import { createContext, FC, Reducer, useCallback, useEffect, useReducer } from 'react';
// graphql, interfaces, constants
import { HTTP_STATUS } from 'constants/index';
import { FormulaDetailContextProps, FormulaDetailContextType } from 'interfaces';
import { FormulaPayload, useGetFormulaDetailLazyQuery } from '../../generated/graphql';
import { State, Action, ActionType, initialState, formulaDetailReducer } from 'reducer/formulaDetailReducer';

export const FormulaDetailContext = createContext<FormulaDetailContextType>({
  loading: false,
  fetchFormula: () => {},
  state: initialState,
  dispatch: () => {},
});

export const FormulaDetailContextProvider: FC<FormulaDetailContextProps> = ({ children, id }) => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(formulaDetailReducer, initialState);

  const [getFormula, { loading: getFormulaLoading }] = useGetFormulaDetailLazyQuery({
    onCompleted: (data) => {
      const { getFormula } = data;
      const { response, formula } = getFormula || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        dispatch({ type: ActionType.SET_FORMULA, formula: formula as FormulaPayload['formula'] });
      } else {
        dispatch({ type: ActionType.SET_FORMULA, formula: null });
      }
    },
    onError: () => {
      dispatch({ type: ActionType.SET_FORMULA, formula: null });
    },
  });

  const fetchFormula = useCallback(async () => {
    await getFormula({
      variables: {
        getFormulaInput: {
          id: id ?? '',
        },
      },
    });
  }, [getFormula, id]);

  useEffect(() => {
    if (id) {
      fetchFormula();
    }
  }, [id, fetchFormula]);

  const loading = getFormulaLoading;

  return (
    <FormulaDetailContext.Provider value={{ loading, fetchFormula, dispatch, state }}>
      {children}
    </FormulaDetailContext.Provider>
  );
};
