// imports
import { FC } from 'react';
// component
import SidebarMain from 'components/common/SidebarMain';
//constants
import { ADMIN_MANAGEMENT_LIST } from 'constants/index';

const ManagementList: FC = () => <SidebarMain list={ADMIN_MANAGEMENT_LIST} />;

export default ManagementList;
