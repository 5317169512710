import { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
// components
import ExcipientFields from '../../../Excipients';
import CapsuleTypeRadioGroup from 'components/common/selectors/capsuleType';
// constants, graphql
import { ProductTypes } from 'generated/graphql';
import { INNER_CAPSULE_POLYMER_TEXT, OUTER_CAPSULE_POLYMER_TEXT } from 'constants/index';

const CapInCap: FC = () => {
  return (
    <Fragment>
      <Grid item xs={12} xl={12}>
        <CapsuleTypeRadioGroup isRequired name="innerCapsuleType" title={INNER_CAPSULE_POLYMER_TEXT} />
      </Grid>
      <Grid item xs={12} xl={12}>
        <CapsuleTypeRadioGroup isRequired name="outerCapsuleType" title={OUTER_CAPSULE_POLYMER_TEXT} />
      </Grid>
      <ExcipientFields productType={ProductTypes.Capsule} />
    </Fragment>
  );
};

export default CapInCap;
