import { FC } from 'react';
import { Box, Button, Card, Grid } from '@mui/material';
// components
import DataLoader from 'components/common/DataLoader';
import SupplierSelector from 'components/common/selectors/supplier';
// styles, constants, interfaces
import { SupplierInfoCardProps } from 'interfaces';
import { SAVE_TEXT, SUGGESTED_SUPPLIER_TEXT } from 'constants/index';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';

const SupplierInfoCard: FC<SupplierInfoCardProps> = ({ loading, isEdit }) => {
  return (
    <Card sx={forInputLabelBg}>
      {loading ? (
        <DataLoader columns={12} rows={3} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <SupplierSelector
              name="primarySupplier"
              title={`${SUGGESTED_SUPPLIER_TEXT} 1`}
              disabled={loading}
              isClearable
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <SupplierSelector
              name="secondarySupplier"
              title={`${SUGGESTED_SUPPLIER_TEXT} 2`}
              disabled={loading}
              isClearable
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <SupplierSelector
              name="tertiarySupplier"
              title={`${SUGGESTED_SUPPLIER_TEXT} 3`}
              disabled={loading}
              isClearable
            />
          </Grid>
        </Grid>
      )}

      {isEdit ? (
        <Box sx={flexMarginTopEndCenter}>
          <Button type="submit" variant="contained" id="supplier-btn" size="small" disabled={loading}>
            {SAVE_TEXT}
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default SupplierInfoCard;
