import { Grid } from '@mui/material';
import { FC, Fragment, JSX } from 'react';
import { useFormContext } from 'react-hook-form';
// components
import RadioGroupController from 'controller/RadioGroupController';
import ProductSizeSelector from 'components/common/selectors/productSize';
import SandCoatingRadioGroup from 'components/common/radioGroups/SandCoating';
import TabletCoatingRadioGroup from 'components/common/radioGroups/TabletCoating';
import CommonSweetenerAndFlavorRadioGroup from 'components/common/CommonSweetenerAndFlavorRadioGroup';
// constants, interfaces, graphQL
import { DosageFormulationFormType, GummyFormProps } from 'interfaces';
import {
  COATING_TEXT,
  FACT_BOX_TEXT,
  FACT_BOX_MAPPED,
  SAND_COATING_TEXT,
  PRODUCT_SIZE_TEXT,
} from 'constants/index';
import { ProductTypes } from 'generated/graphql';

const GummyForm: FC<GummyFormProps> = ({ isFactBox = false }): JSX.Element => {
  const { watch, setValue } = useFormContext<DosageFormulationFormType>();
  const { productType: productTypeId, productSubType, productSize } = watch();
  const { value: productSizeId } = productSize || {};

  const onSandClear = () => {
    setValue('sandCoating', '');
  };

  const onTabletCoatingClear = () => {
    setValue('tabletCoating', '');
  };

  return (
    <Fragment>
      <Grid item xs={12} xl={12}>
        <ProductSizeSelector
          isRequired
          name="productSize"
          title={PRODUCT_SIZE_TEXT}
          productTypeId={productTypeId}
          productSubTypeId={productSubType}
          disabled={!(productSubType && productTypeId)}
        />
      </Grid>

      {isFactBox ? (
        <Fragment />
      ) : (
        <Grid item xs={12} xl={12}>
          <RadioGroupController
            isRequired
            name="factBoxType"
            title={FACT_BOX_TEXT}
            options={FACT_BOX_MAPPED}
          />
        </Grid>
      )}

      <CommonSweetenerAndFlavorRadioGroup productSizeId={productSizeId} />

      <Grid item xs={12} xl={12}>
        <TabletCoatingRadioGroup
          name="tabletCoating"
          title={COATING_TEXT}
          productType={ProductTypes.Gummies}
          onClear={onTabletCoatingClear}
        />
      </Grid>

      <Grid item xs={12} xl={12}>
        <SandCoatingRadioGroup name="sandCoating" title={SAND_COATING_TEXT} onClear={onSandClear} />
      </Grid>
    </Fragment>
  );
};

export default GummyForm;
