import { useSearchParams } from 'react-router-dom';
import { Chip, TableCell, TableRow } from '@mui/material';
import { FC, Fragment, Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';
//components
import { Alert } from 'components/common/Alert';
import TableLoader from 'components/common/TableLoader';
import NoDataFound from 'components/common/NoDataFound';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import TableSearchField from 'components/common/TableSearchField';
import TableTabsComponent from 'components/common/TableTabsComponent';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
//constants, reducers, graphql, styles
import {
  UserStatus,
  UsersPayload,
  useFindAllStaffLazyQuery,
  useUpdateUserStatusMutation,
} from 'generated/graphql';
import {
  STAFF_TABS,
  HTTP_STATUS,
  TAB_QUERY_TYPE,
  EDIT_STAFF_ROUTE,
  STAFF_TABLE_HEADER,
  LOADING_TABLE_ROWS,
  ACTIVE_TEXT,
  INACTIVE_TEXT,
} from 'constants/index';
import { StaffTabType } from 'interfaces/TabTypes';
import { formatPhone, formatTimeStamp } from 'lib/helper';
import { textWhiteSpace } from 'styles/commonComponentStyle';
import { Action, ActionType, State, initialState, staffReducer } from 'reducer/staffReducer';

const StaffTable: FC = () => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(staffReducer, initialState);
  const { page, rowsPerPage, search, count, data, activeTab } = state;

  const [searchParams] = useSearchParams();
  const tab = searchParams.get(TAB_QUERY_TYPE);

  const [findAllStaff, { loading: findAllStaffLoading, error }] = useFindAllStaffLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllStaff } = data;
      const { pagination, response, data: staffData } = findAllStaff || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        const { page, totalCount } = pagination || {};
        dispatch({ type: ActionType.SET_PAGE, page: page || 1 });
        dispatch({ type: ActionType.SET_COUNT, count: totalCount || 0 });
        dispatch({ type: ActionType.SET_DATA, data: staffData as UsersPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const [updateUserStatus, { loading: updateUserStatusLoading }] = useUpdateUserStatusMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateUserStatus } = data;
      const { response } = updateUserStatus || {};
      const { message, status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        fetchStaff();
      } else {
        Alert.error(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_COUNT, count: 0 });
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchStaff = useCallback(async () => {
    await findAllStaff({
      variables: {
        findAllStaffInput: {
          paginationOptions: {
            limit: rowsPerPage,
            page,
          },
          search,
          ...(activeTab !== 'all' && { status: activeTab }),
        },
      },
    });
  }, [findAllStaff, search, rowsPerPage, page, activeTab]);

  useEffect(() => {
    (!search.length || search.length > 2) && fetchStaff();
  }, [fetchStaff, search.length]);

  const tabHandler = (_: React.SyntheticEvent<Element, Event>, value: string) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab: value as StaffTabType });
  };

  const onActiveHandler = async (id: string, status: boolean) => {
    await updateUserStatus({
      variables: {
        updateUserStatusInput: {
          id,
          status: status ? UserStatus.Inactive : UserStatus.Active,
        },
      },
    });
  };

  useMemo(() => {
    if (tab === 'all') dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab: 'all' });
  }, [tab]);

  const loading = findAllStaffLoading || updateUserStatusLoading;

  const noData = Boolean((!findAllStaffLoading && data?.length === 0) || error);

  return (
    <TableContainer>
      <TableTabsComponent activeTab={activeTab} tabsList={STAFF_TABS} tabHandler={tabHandler} />

      <TableSearchField
        search={search}
        onClear={() => dispatch({ type: ActionType.SET_SEARCH, search: '' })}
        onChange={(val) => dispatch({ type: ActionType.SET_SEARCH, search: val })}
      />

      <TableComponent
        noData={noData}
        page={page - 1}
        count={count}
        rowsPerPage={rowsPerPage}
        tableHeader={STAFF_TABLE_HEADER}
        setPage={(p: number) => dispatch({ type: ActionType.SET_PAGE, page: p + 1 })}
        setRowsPerPage={(r: number) => dispatch({ type: ActionType.SET_ROWS_PER_PAGE, rowsPerPage: r })}>
        {loading ? (
          <TableLoader columns={7} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const { id, email, firstName, lastName, phone, createdAt, staffRole, status } = cell || {};

              return (
                <TableRow key={id}>
                  <TableCell sx={textWhiteSpace}>
                    {firstName ?? '-'} {lastName ?? '-'}
                  </TableCell>
                  <TableCell sx={textWhiteSpace}>{staffRole ?? '--'}</TableCell>
                  <TableCell>{email ?? '--'}</TableCell>
                  <TableCell sx={textWhiteSpace}>{formatPhone(phone || '')}</TableCell>
                  <TableCell>{formatTimeStamp(createdAt, 'MM/DD/YY')}</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={status === UserStatus.Active ? ACTIVE_TEXT : INACTIVE_TEXT}
                      color={status === UserStatus.Active ? 'success' : 'error'}
                    />
                  </TableCell>
                  <TableCell>
                    <ActionMenuDropdown
                      id={id ?? ''}
                      isActive={status === UserStatus.Active}
                      editRoute={EDIT_STAFF_ROUTE}
                      onActiveHandler={onActiveHandler}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>

      <NoDataFound noData={noData} />
    </TableContainer>
  );
};

export default StaffTable;
