import React, { FC, Reducer, createContext, useReducer } from 'react';
// interfaces, reducers
import { AddFactBoxFormulaContextType, ChildrenType } from 'interfaces';
import { Action, State, addFactBoxReducer, initialState } from 'reducer/addFactBoxReducer';

export const AddFactBoxFormulaContext = createContext<AddFactBoxFormulaContextType>({
  state: initialState,
  dispatch: () => {},
});

export const AddFactBoxFormulaContextProvider: FC<ChildrenType> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(addFactBoxReducer, initialState);

  return (
    <AddFactBoxFormulaContext.Provider value={{ state, dispatch }}>
      {children}
    </AddFactBoxFormulaContext.Provider>
  );
};
