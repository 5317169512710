import { FC } from 'react';
import { Button } from '@mui/material';
// interface, constant
import { SaveAsDraftButtonProps } from 'interfaces';
import { SAVE_AS_DRAFT_TEXT } from 'constants/index';

const SaveAsDraftButton: FC<SaveAsDraftButtonProps> = ({
  mr = 2,
  loading,
  onClick,
  btnText = SAVE_AS_DRAFT_TEXT,
}) => {
  return (
    <Button variant="outlined" color="primary" disabled={loading} sx={{ mr: mr }} onClick={onClick}>
      {btnText}
    </Button>
  );
};

export default SaveAsDraftButton;
