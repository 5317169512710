import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  TablePagination,
  Box,
} from '@mui/material';
import { FC } from 'react';
// interfaces
import { EMPTY_CELL, PAGINATION } from 'constants/index';
import { TableComponentType } from 'interfaces';
import { tablePagination } from 'styles/commonComponentStyle';

const TableComponent: FC<TableComponentType> = ({
  page,
  count,
  setPage,
  noData,
  noPadding,
  children,
  rowsPerPage,
  tableHeader,
  setRowsPerPage,
}) => {
  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage && setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage && setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage && setPage(0);
  };

  return (
    <>
      <Box sx={noPadding ? { px: 0 } : { px: { xs: 2, lg: 3 }, boxShadow: 'none' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeader.map((cell, index) => {
                  if (cell === EMPTY_CELL) {
                    return null;
                  }
                  return <TableCell key={`${cell}-${index}`}>{cell}</TableCell>;
                })}
              </TableRow>
            </TableHead>
            <TableBody>{children}</TableBody>
          </Table>
        </TableContainer>
      </Box>
      {!noData && (
        <TablePagination
          page={page ?? 1}
          count={count ?? 5}
          component="div"
          sx={tablePagination}
          rowsPerPage={rowsPerPage ?? 5}
          onPageChange={handleChangePage}
          rowsPerPageOptions={PAGINATION}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default TableComponent;
