import { FC } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
// components
import NoDataFound from 'components/common/NoDataFound';
// constants, graphql, interface, styles
import { flexEnd } from 'theme/styleConstant';
import { FactSheetCardProps } from 'interfaces';
import { CLOSE_TEXT, FACT_SHEET_TEXT } from 'constants/index';

const FactSheetCard: FC<FactSheetCardProps> = ({ onClose, open, loading, sheetUrl }) => {
  return (
    <Dialog open={open} fullWidth maxWidth={'lg'}>
      <DialogTitle>{FACT_SHEET_TEXT}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box textAlign="center">
            <CircularProgress size={40} />
          </Box>
        ) : sheetUrl ? (
          <object>
            <embed type="text/html" width="100%" height="600" src={sheetUrl} />
          </object>
        ) : (
          <NoDataFound noData={!sheetUrl} />
        )}
      </DialogContent>
      <DialogActions>
        <Box sx={flexEnd}>
          <Button variant="outlined" color="primary" onClick={onClose} size="small">
            {CLOSE_TEXT}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FactSheetCard;
