import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import SubProductTypeForm from './components/SubProductTypeForm';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { flexCenterBetween } from 'styles/commonComponentStyle';
import {
  SubProductTypePayload,
  useGetSubProductTypeLazyQuery,
  useUpdateSubProductTypeMutation,
} from 'generated/graphql';
import {
  HTTP_STATUS,
  productSubTypeInitialValue,
  EDIT_SUB_PRODUCT_TYPE_TEXT,
  SUB_PRODUCT_TYPE_IS_UPDATED_TEXT,
} from 'constants/index';
import { AddSubProductTypeFormType, ParamType } from 'interfaces';
import { updateSubProductTypeSchema } from 'validationSchema';

const Edit: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const subProductTypeMethods = useForm<AddSubProductTypeFormType>({
    defaultValues: productSubTypeInitialValue,
    resolver: yupResolver(updateSubProductTypeSchema),
  });

  const { handleSubmit: handleSubProductTypeSubmit, setValue: setSubProductTypeValue } =
    subProductTypeMethods;

  const [updateSubProductType, { loading: subProductTypeLoading }] = useUpdateSubProductTypeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateSubProductType } = data;
      const { response } = updateSubProductType || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getSubProductType, { loading: getLoading }] = useGetSubProductTypeLazyQuery({
    onCompleted: (data) => {
      const { getSubProductType } = data || {};
      const { response, subProductType } = getSubProductType || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(subProductType as SubProductTypePayload['subProductType']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });

  const onSubProductTypeSubmit: SubmitHandler<AddSubProductTypeFormType> = async (data) => {
    const { name, productType } = data || {};

    await updateSubProductType({
      variables: {
        updateSubProductTypeInput: {
          id: id || '',
          name,
          productTypeId: productType?.value,
        },
      },
    });
  };

  const fetchSubProductType = useCallback(async () => {
    id &&
      (await getSubProductType({
        variables: {
          getSubProductTypeInput: {
            id,
          },
        },
      }));
  }, [id, getSubProductType]);

  const setValues = (params: SubProductTypePayload['subProductType']) => {
    const { name, productType } = params || {};
    const { id, name: productName } = productType || {};
    setSubProductTypeValue('name', name || '');
    setSubProductTypeValue('productType', {
      value: id ?? '',
      name: productName ?? '',
    });
  };

  useEffect(() => {
    id && fetchSubProductType();
  }, [id, fetchSubProductType]);

  const loading = subProductTypeLoading || getLoading;

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{EDIT_SUB_PRODUCT_TYPE_TEXT}</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={6} md={12}>
          <FormProvider {...subProductTypeMethods}>
            <form onSubmit={handleSubProductTypeSubmit(onSubProductTypeSubmit)}>
              <SubProductTypeForm loading={loading} isEdit />
            </form>
          </FormProvider>
        </Grid>
      </Grid>

      <BackdropLoader open={loading} text={SUB_PRODUCT_TYPE_IS_UPDATED_TEXT} />
    </Fragment>
  );
};

export default Edit;
