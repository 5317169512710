import { FC } from 'react';
import { SnackbarProvider } from 'notistack';
import { ApolloProvider } from '@apollo/client';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
//components
import { ExceptionalContextProvider } from 'contexts/ExceptionalContext';
import { ExceptionalModalConfig } from 'components/common/ExceptionModalUtils';
import { CloseButton, SnackbarUtilsConfiguration } from 'components/common/Alert';
//context, apollo, routes, theme
import { router } from 'routes';
import client from 'apollo';
import customTheme from 'theme';
import { AuthContextProvider } from 'contexts/AuthContext';

const App: FC = () => {
  return (
    <SnackbarProvider
      maxSnack={5}
      autoHideDuration={5000}
      preventDuplicate={true}
      action={(key) => <CloseButton id={key} />}
      classes={{ containerRoot: 'snackbarProvider' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <SnackbarUtilsConfiguration />
      <ApolloProvider client={client}>
        <AuthContextProvider>
          <ThemeProvider theme={customTheme}>
            <ExceptionalContextProvider>
              <ExceptionalModalConfig />
              <CssBaseline />
              <RouterProvider router={router} />
            </ExceptionalContextProvider>
          </ThemeProvider>
        </AuthContextProvider>
      </ApolloProvider>
    </SnackbarProvider>
  );
};
export default App;
