import { FC } from 'react';
import { Tooltip } from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
// constants
import { OVERAGE_INFO_MESSAGE } from 'constants/index';
// interfaces
import { InfoTooltipProps } from 'interfaces';

export const InfoTooltip: FC<InfoTooltipProps> = ({ title = OVERAGE_INFO_MESSAGE, fontSize = 'large' }) => {
  return (
    <Tooltip arrow title={title}>
      <InfoIcon fontSize={fontSize} />
    </Tooltip>
  );
};
