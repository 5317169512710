import { FC, useContext } from 'react';
// helper, interface, context
import { isStaff } from 'lib/helper';
import { ChildrenType } from 'interfaces';
import { AuthContext } from 'contexts/AuthContext';

export const StaffGuard: FC<ChildrenType> = ({ children }) => {
  const { userRoles } = useContext(AuthContext);

  const isStaffUser = isStaff(userRoles);

  return <>{isStaffUser ? children : null}</>;
};
