import { IngredientItemFormType } from 'interfaces';
import { IngredientsItemFormInitialValues } from 'constants/formInitialValues';

export const TOKEN = 'access_token';
export const UNAUTHORIZED = 'Unauthorized';
export const TOKEN_INVALID = 'Token Invalid';
export const MAINTENANCE_ALERT = 'Maintenance is in progress';
export const BAD_REQUEST_EXCEPTION = 'Bad Request Exception';
export const SERVER_ERRORS = 'Server Errors';
// Http Statuses
export const HTTP_STATUS = {
  SUCCESS: 200,
  CREATED: 201,
  SERVER_ERROR: 500,
  BAD_REQUEST: 400,
  NOT_FOUND: 404,
};

// Invalid Messages
export const WRONG_EMAIL_OR_PASSWORD = 'You have entered wrong email or password';
export const INVALID_EMAIL = 'Invalid email address';
export const PASSWORD_VALIDATION_MESSAGE =
  'Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character';

// values constants
const REACT_ENVIRONMENT = process.env.REACT_APP_REACT_ENVIRONMENT || 'production';

export const IS_STAGE = REACT_ENVIRONMENT === 'staging';
export const PAGE_LIMIT = 5;
export const drawerWidth = 280;
export const EMPTY_CELL = 'empty';
export const LOADING_TABLE_ROWS = 8;
export const MAX_IMAGE_SIZE = 3000000;
export const PAGINATION = [5, 10, 25];
export const EMPTY_OPTION = { value: '', name: '--' };
export const EMPTY_INGREDIENT_OPTION: IngredientItemFormType = IngredientsItemFormInitialValues;

// exports
export * from 'constants/constant';
export * from 'constants/tabs';
export * from 'constants/regex';
export * from 'constants/routes';
export * from 'constants/sideBarData';
export * from 'constants/tableHeaders';
export * from 'constants/countriesList';
export * from 'constants/states';
export * from 'constants/formInitialValues';
export * from 'constants/mappedData';
