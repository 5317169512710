import { FC, Fragment, useContext } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import { Box, Step, StepConnector, StepLabel, Stepper } from '@mui/material';
// components
import Step1 from './Step1';
import Step2 from './Step2';
// interfaces, context, graphql, reducer, helper
import { ProductTypes } from 'generated/graphql';
import { modifyFormulaIngredient } from 'lib/helper';
import { ActionType } from 'reducer/addFormulaReducer';
import { FormulaContext } from 'contexts/FormulaContext';
import {
  SelectType,
  StepFormProps,
  ProductTypeSelect,
  IngredientItemType,
  DosageFormulationFormType,
} from 'interfaces';

const StepForm: FC<StepFormProps> = ({ formulaMethods, activeStep }) => {
  const { dispatch, state } = useContext(FormulaContext);
  const { watch, reset, setValue } = useFormContext<DosageFormulationFormType>();

  const { getValues, setValue: setFormulaValues } = formulaMethods;

  const { formulaIngredients } = getValues();

  const { productType } = state || {};
  const { value: productTypeId, type } = productType;
  const { productSubType } = watch();

  const steps = [{ id: 1 }, { id: 2 }];

  const productTypeDispatch = (item: ProductTypeSelect) => {
    dispatch({ type: ActionType.SET_PRODUCT_TYPE, productType: item });
  };

  const productSubTypeDispatch = (item: SelectType) => {
    dispatch({ type: ActionType.SET_SUB_PRODUCT_TYPE, subProductType: item });
  };

  const productFormulationTypeDispatch = (item: ProductTypeSelect) => {
    dispatch({ type: ActionType.SET_PRODUCT_FORMULATION_TYPE, productFormulationType: item });
  };

  const setFormulaIngredients = () => {
    const modifiedFormulaIngredients = modifyFormulaIngredient(formulaIngredients);
    setFormulaValues('formulaIngredients', modifiedFormulaIngredients as IngredientItemType[]);
  };

  const resetBlends = () => {
    dispatch({ type: ActionType.SET_INGREDIENTS_BLEND, ingredientsBlend: [] });
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Step1
            reset={reset}
            setValue={setValue}
            resetBlends={resetBlends}
            type={type as ProductTypes}
            productTypeId={productTypeId}
            productSubType={productSubType}
            productTypeDispatch={productTypeDispatch}
            productSubTypeDispatch={productSubTypeDispatch}
            resetFormulaIngredients={setFormulaIngredients}
            productFormulationTypeDispatch={productFormulationTypeDispatch}
          />
        );
      case 1:
        return (
          <FormProvider {...formulaMethods}>
            <Step2 />
          </FormProvider>
        );

      default:
        return null;
    }
  };

  return (
    <Fragment>
      <Stepper activeStep={activeStep} connector={<StepConnector />} alternativeLabel>
        {steps.map(({ id }) => (
          <Step key={id}>
            <StepLabel></StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box mt="24px !important">{renderStepContent(activeStep)}</Box>
    </Fragment>
  );
};

export default StepForm;
