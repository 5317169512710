import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Typography } from '@mui/material';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import ExcipientForm from './ExcipientForm';
import { Alert } from 'components/common/Alert';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { ExcipientPayload, useGetExcipientLazyQuery, useUpdateExcipientMutation } from 'generated/graphql';
import {
  HTTP_STATUS,
  EDIT_EXCIPIENT_TEXT,
  excipientInitialValue,
  EXCIPIENT_IS_UPDATED_TEXT,
} from 'constants/index';
import { AddExcipientFormType, ParamType } from 'interfaces';
import { updateExcipientSchema } from 'validationSchema';

const Edit: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const excipientMethods = useForm<AddExcipientFormType>({
    defaultValues: excipientInitialValue,
    resolver: yupResolver(updateExcipientSchema),
  });

  const { handleSubmit: handleExcipientSubmit, setValue: setExcipientValue } = excipientMethods;

  const [updateExcipient, { loading: ExcipientLoading }] = useUpdateExcipientMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateExcipient } = data;
      const { response } = updateExcipient || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getExcipient, { loading: getLoading }] = useGetExcipientLazyQuery({
    onCompleted: (data) => {
      const { getExcipient } = data || {};
      const { response, excipient } = getExcipient || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setValues(excipient as ExcipientPayload['excipient']);
      }
    },
    onError: () => {
      setValues(null);
    },
  });

  const onExcipientSubmit: SubmitHandler<AddExcipientFormType> = async (data) => {
    const { name } = data || {};

    await updateExcipient({
      variables: {
        updateExcipientInput: {
          id: id || '',
          name,
        },
      },
    });
  };

  const fetchExcipient = useCallback(async () => {
    id &&
      (await getExcipient({
        variables: {
          getExcipientInput: {
            id,
          },
        },
      }));
  }, [id, getExcipient]);

  const setValues = (params: ExcipientPayload['excipient']) => {
    const { name } = params || {};
    setExcipientValue('name', name || '');
  };

  useEffect(() => {
    id && fetchExcipient();
  }, [id, fetchExcipient]);

  const loading = ExcipientLoading || getLoading;

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{EDIT_EXCIPIENT_TEXT}</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={6} md={12}>
          <FormProvider {...excipientMethods}>
            <form onSubmit={handleExcipientSubmit(onExcipientSubmit)}>
              <ExcipientForm loading={loading} isEdit />
            </form>
          </FormProvider>
        </Grid>
      </Grid>

      <BackdropLoader open={loading} text={EXCIPIENT_IS_UPDATED_TEXT} />
    </Fragment>
  );
};

export default Edit;
