//import
import { FC } from 'react';
//components
import { AuthLayout } from 'components/common/AuthLayout';
import ForgetPasswordComponent from 'components/Auth/ForgetPassword';
//constants
import { FORGOT_PASSWORD_HEADING, FORGOT_PASSWORD_SUBTITLE } from 'constants/index';

const ForgetPassword: FC = () => (
  <AuthLayout title={FORGOT_PASSWORD_HEADING} subTitle={FORGOT_PASSWORD_SUBTITLE} mb={2} lineHeight="24px">
    <ForgetPasswordComponent />
  </AuthLayout>
);

export default ForgetPassword;
