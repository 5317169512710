import { FC } from 'react';
import { Box, Button, Card, Grid } from '@mui/material';
// components
import DataLoader from 'components/common/DataLoader';
import InputController from 'controller/InputController';
// interfaces, constants, styles
import {
  SAVE_TEXT,
  BULK_DENSITY_TEXT,
  LOSS_ON_DRYING_TEXT,
  TAPPED_DENSITY_TEXT,
  AVERAGE_PARTICLE_SIZE_TEXT,
} from 'constants/index';
import { IngredientDensityInfoCardProps } from 'interfaces';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';

const IngredientDensityInfoCard: FC<IngredientDensityInfoCardProps> = ({ loading, isEdit }) => {
  return (
    <Card sx={forInputLabelBg}>
      {loading ? (
        <DataLoader columns={6} rows={2} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <InputController name="bulkDensity" fieldType="number" title={BULK_DENSITY_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="tappedDensity" fieldType="number" title={TAPPED_DENSITY_TEXT} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController
              name="averageParticleSize"
              fieldType="number"
              title={AVERAGE_PARTICLE_SIZE_TEXT}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController name="lossOnDrying" fieldType="string" title={LOSS_ON_DRYING_TEXT} />
          </Grid>
        </Grid>
      )}

      {isEdit ? (
        <Box sx={flexMarginTopEndCenter}>
          <Button type="submit" variant="contained" id="density-info-btn" size="small" disabled={loading}>
            {SAVE_TEXT}
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default IngredientDensityInfoCard;
