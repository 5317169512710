import { FC, useCallback, useEffect, useState } from 'react';
// component
import RadioGroupController from 'controller/RadioGroupController';
// interfaces, constants, helper, graphql
import { HTTP_STATUS } from 'constants/index';
import { renderProductSubTypes } from 'lib/helper';
import { ProductSubTypeSelectorProps, SelectType } from 'interfaces';
import { SubProductTypesPayload, useFindAllSubProductTypeLazyQuery } from 'generated/graphql';

const ProductSubTypeSelector: FC<ProductSubTypeSelectorProps> = ({
  name,
  type,
  title,
  productTypeId,
  isRequired = false,
  loading: getLoading,
  onChange: handleOnChange,
}) => {
  const [options, setOptions] = useState<SelectType[]>([]);

  const [findAllSubProductType, { loading: getProductTypesLoading }] = useFindAllSubProductTypeLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { findAllSubProductType } = data;
      const { response, data: productSubTypeData } = findAllSubProductType || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        setOptions(renderProductSubTypes(productSubTypeData as SubProductTypesPayload['data']));
      } else {
        setOptions([]);
      }
    },

    onError: () => {
      setOptions([]);
    },
  });

  const fetchProductTypes = useCallback(async () => {
    if (productTypeId && type) {
      await findAllSubProductType({
        variables: {
          findAllSubProductTypeInput: {
            paginationOptions: {
              limit: 10,
              page: 1,
            },
            productTypeId: productTypeId,
          },
        },
      });
    }
  }, [findAllSubProductType, productTypeId, type]);

  useEffect(() => {
    productTypeId && fetchProductTypes();
  }, [fetchProductTypes, productTypeId]);

  const loading = getProductTypesLoading || getLoading;

  return (
    <RadioGroupController
      name={name}
      title={title}
      loaderRows={1}
      loading={loading}
      loaderColumns={4}
      options={options}
      columnSize={6}
      isRequired={isRequired}
      showTitle={!!options?.length}
      handleOnChange={handleOnChange}
    />
  );
};

export default ProductSubTypeSelector;
