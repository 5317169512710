// imports
import { Controller, useFormContext } from 'react-hook-form';
import { FC, useMemo, useState } from 'react';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon } from '@mui/icons-material';
//interfaces , constants , helper
import { SelectType, StateSelectorProps } from 'interfaces';
import { stateToSelectType } from 'lib/helper';

const StateSelector: FC<StateSelectorProps> = ({
  name,
  title,
  disabled,
  isRequired,
  defaultValue,
  margin = 'dense',
  countryCode = 'US',
}) => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  const { control } = useFormContext();

  const options = useMemo(() => stateToSelectType(searchQuery, countryCode), [searchQuery, countryCode]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { name, onBlur, onChange, value, ref } = field;
        const { error } = fieldState;
        const { message } = error || {};
        return (
          <Autocomplete
            ref={ref}
            multiple={false}
            onBlur={onBlur}
            disableClearable
            disabled={disabled}
            options={options}
            value={value as SelectType}
            onChange={(_, value) => {
              onChange(value);
              setSearchQuery('');
            }}
            popupIcon={<KeyboardArrowDownRoundedIcon />}
            getOptionLabel={(option) => option.name || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                margin={margin}
                variant="outlined"
                helperText={message}
                error={Boolean(error)}
                defaultValue={defaultValue}
                label={isRequired ? `${title} *` : title}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                }}>
                {options.slice(0, 10)?.map((item) => {
                  const { name, value } = item;
                  return (
                    <MenuItem key={value} value={value}>
                      {name}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          />
        );
      }}
    />
  );
};

export default StateSelector;
