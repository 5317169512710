import { Container, Theme, styled } from '@mui/material';
import { listItemClasses } from '@mui/material/ListItem';
import { formLabelClasses } from '@mui/material/FormLabel';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { menuClasses } from '@mui/material/Menu';
// styles
import { flexCenterJustifyBetween, displayFlexAlignCenter, flexTextCenterColumn } from 'theme/styleConstant';

export const cursorPointer = {
  cursor: 'pointer',
};

export const textTransform = {
  textTransform: 'initial',
};

export const authLogoBox = {
  p: {
    md: '50px 32px 0px',
    sm: '20px 0px 0px',
    xs: '20px 0px 0px',
  },
};

export const flexCenterBetween = {
  flexWrap: 'wrap',
  marginBottom: ({ spacing }: Theme) => spacing(3),
  ...flexCenterJustifyBetween,
};

export const flexMarginTopEndCenter = {
  ...displayFlexAlignCenter,
  justifyContent: 'flex-end',
  marginTop: ({ spacing }: Theme) => spacing(2),
};

export const tablePagination = {
  boxShadow: ({ palette }: Theme) => `inset 0px 1px 0px ${palette.customGrayColor.dark}`,
};

export const textWhiteSpace = {
  whiteSpace: 'nowrap',
};

export const boldText = {
  fontWeight: `700 !important`,
};

export const tabComponentPaper = {
  borderRadius: ({ spacing }: Theme) => spacing(2),
  backgroundColor: ({ palette }: Theme) => palette.customShadowColor.dark,
};

export const forInputLabelBg = {
  [`& .${formLabelClasses.root}`]: {
    backgroundColor: ({ palette }: Theme) => `${palette.customBlackColor.dark} !important`,
  },

  [`& .${inputLabelClasses.shrink}`]: {
    width: 'auto',
  },

  [`& .${outlinedInputClasses.input}`]: {
    '&:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 1000px transparent inset !important`,
    },
  },
};

export const avatarBox = {
  width: 44,
  height: 44,
  fontSize: 14,
  borderRadius: '100%',
  backgroundColor: ({ palette }: Theme) => palette.primary.main,
  border: ({ palette }: Theme) => `1px solid ${palette.common.white}`,
};

export const userAvatar = {
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '50%',
  },
};

export const lastChildNoSpacing = {
  marginBottom: ({ spacing }: Theme) => spacing(0),
};

export const orderViewAllFooter = {
  textAlign: 'right',
  padding: ({ spacing }: Theme) => spacing(1.625),
  borderTop: ({ palette }: Theme) => `1px solid ${palette.customGrayColor.dark}`,
};

export const popoverListItemLabel = {
  [`& .${listItemClasses.root}`]: {
    '&:hover': {
      backgroundColor: ({ palette }: Theme) => palette.customCommonColor.main,
    },
  },
};

export const dropdownMenuList = {
  [`& .${menuClasses.root}`]: {
    width: 200,
  },
};

export const ExportBoxStyle = {
  mt: 1,
  left: 0,
  right: 0,
  zIndex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  position: 'absolute',
  borderRadius: ({ spacing }: Theme) => spacing(1.5),
  backgroundColor: ({ palette }: Theme) => palette.common.black,
  boxShadow: ({ palette }: Theme) =>
    `0px 0px 2px ${palette.myCustomColor.dark}, 0px 12px 24px -4px ${palette.myCustomColor.contrastText}`,
};

export const ExportMenuStyles = {
  fontWeight: 400,
  fontSize: ({ spacing }: Theme) => spacing(1.75),
  borderRadius: ({ spacing }: Theme) => spacing(1),
  color: ({ palette }: Theme) => palette.common.white,
};

export const StickyTopHeader = {
  top: 0,
  paddingY: 3,
  zIndex: 1050,
  position: 'sticky',
  backgroundColor: ({ palette }: Theme) => palette.secondary.main,
};

export const FileUploadBoxBorder = {
  width: ({ spacing }: Theme) => spacing(18),
  height: ({ spacing }: Theme) => spacing(18),
  padding: ({ spacing }: Theme) => spacing(1),
  margin: ({ spacing }: Theme) => spacing(0, 'auto'),
  borderRadius: ({ spacing }: Theme) => spacing(12.5),
  border: ({ palette }: Theme) => `1px dashed ${palette.customCommonColor.dark}`,
};

export const FileUploadBox = {
  fontWeight: 400,
  flexDirection: 'column',
  height: ({ spacing }: Theme) => spacing(17),
  minWidth: ({ spacing }: Theme) => spacing(17),
  fontSize: ({ spacing }: Theme) => spacing(1.5),
  borderRadius: ({ spacing }: Theme) => spacing(12.5),
  color: ({ palette }: Theme) => palette.customGrayColor.light,
  backgroundColor: ({ palette }: Theme) => palette.customGrayColor.contrastText,

  '& svg': {
    margin: ({ spacing }: Theme) => spacing(0),
  },

  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: ({ spacing }: Theme) => spacing(12.5),
  },
};

export const imageUploadCard = {
  minHeight: ({ spacing }: Theme) => spacing(38),
  ...flexTextCenterColumn,
};

export const AuthLogoContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    paddingBottom: theme.spacing(3.75),
  },
}));

export const textDecoration = {
  textDecoration: 'none',
};
