import { Box, Grid, Typography, useTheme } from '@mui/material';
import { FC, Fragment, useCallback, useEffect, useState, JSX } from 'react';
// components
import { DashboardStatsChartImg } from 'assets/svgs';
import DashboardStatsCard from '../DashboardStatsCard';
import UsersTableDashboard from './DashboardUsersTable';
import UserProfilePopover from 'components/main/Dashboard/UserAvatarPopover';
// styles, interfaces
import {
  HTTP_STATUS,
  DASHBOARD_TEXT,
  TOTAL_USERS_TEXT,
  superInitialValues,
  TOTAL_INGREDIENTS_TEXT,
  CURRENT_MONTH_USERS_TEXT,
} from 'constants/index';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { SuperDashboardPayload, useGetSuperDashboardLazyQuery } from 'generated/graphql';

const SuperAdminDashboard: FC = (): JSX.Element => {
  const { palette } = useTheme();

  const [data, setData] = useState<SuperDashboardPayload>(superInitialValues);

  const [getSuperDashboard, { loading }] = useGetSuperDashboardLazyQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,

    onCompleted: (res) => {
      const { getSuperDashboard } = res || {};
      const { response } = getSuperDashboard || {};
      const { status } = response || {};

      if (status === HTTP_STATUS.SUCCESS) {
        setData(getSuperDashboard);
      } else {
        setData(superInitialValues);
      }
    },

    onError: () => {
      setData(superInitialValues);
    },
  });

  const fetchDashboard = useCallback(async () => {
    try {
      await getSuperDashboard();
    } catch (error) {
      console.log('error', error);
    }
  }, [getSuperDashboard]);

  useEffect(() => {
    fetchDashboard();
  }, [fetchDashboard]);

  const { ingredients, currentMonthUsers, users } = data || {};

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5" id={'dashboard-text'}>
          {DASHBOARD_TEXT}
        </Typography>

        <UserProfilePopover />
      </Box>
      <Grid container spacing={3} mt={1}>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <DashboardStatsCard
            loading={loading}
            icon={<DashboardStatsChartImg color={palette.primary.main} />}
            stats={`${users ?? 0}`}
            title={TOTAL_USERS_TEXT}
          />
        </Grid>

        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <DashboardStatsCard
            loading={loading}
            icon={<DashboardStatsChartImg color={palette.bluePaletteColor.main} />}
            stats={`${ingredients ?? 0}`}
            title={TOTAL_INGREDIENTS_TEXT}
          />
        </Grid>

        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <DashboardStatsCard
            loading={loading}
            icon={<DashboardStatsChartImg color={palette.customRedColor.main} />}
            stats={`${currentMonthUsers ?? 0}`}
            title={CURRENT_MONTH_USERS_TEXT}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={1}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <UsersTableDashboard />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SuperAdminDashboard;
