import { FC, Fragment, JSX } from 'react';
import { Box, Button, Card, CardActions, CardContent, Grid } from '@mui/material';
// components
import SelectController from 'controller/Select';
import CheckboxController from 'controller/Checkbox';
import InputController from 'controller/InputController';
import SupplierSelector from 'components/common/selectors/supplier';
import BottleSizeSelector from 'components/common/selectors/bottleSize';
// constants, styles, interfaces
import { BottlePriceFormProps } from 'interfaces';
import { flexMarginTopEndCenter } from 'styles/commonComponentStyle';
import {
  SAVE_TEXT,
  TYPE_TEXT,
  PRICE_TEXT,
  BOTTLE_SIZE_TEXT,
  BOTTLE_TYPE_MAPPED,
  IS_CHILD_RESISTANT_TEXT,
  SUGGESTED_SUPPLIER_TEXT,
} from 'constants/index';

const BottlePriceForm: FC<BottlePriceFormProps> = ({ isEdit = false, loading = false }): JSX.Element => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <InputController isRequired fieldType="text" name="price" disabled={loading} title={PRICE_TEXT} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <BottleSizeSelector isRequired title={BOTTLE_SIZE_TEXT} name="bottleSize" />
          </Grid>
          <Grid item xs={12} lg={12}>
            <SelectController
              isRequired
              name="type"
              disabled={loading}
              title={TYPE_TEXT}
              options={BOTTLE_TYPE_MAPPED}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <SupplierSelector title={SUGGESTED_SUPPLIER_TEXT} isRequired name="suppliers" isMultiple />
          </Grid>
          <Grid item xs={12} lg={12}>
            <CheckboxController name="isCr" title={IS_CHILD_RESISTANT_TEXT} loading={loading} />
          </Grid>
        </Grid>
      </CardContent>

      {isEdit ? (
        <CardActions>
          <Box sx={flexMarginTopEndCenter}>
            <Button type="submit" variant="contained" size="small" disabled={loading}>
              {SAVE_TEXT}
            </Button>
          </Box>
        </CardActions>
      ) : (
        <Fragment />
      )}
    </Card>
  );
};

export default BottlePriceForm;
