import { FC, Fragment } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
// component
import ClearComponent from './ClearComponent';
import SweetenerSelector from './selectors/sweetener';
import RadioGroupController from 'controller/RadioGroupController';
// constants, interface, graphql
import {
  OPTIONAL_TEXT,
  FLAVOR_TYPE_TEXT,
  FLAVOR_SYSTEM_TEXT,
  SWEETENER_TYPE_TEXT,
  SWEETENER_SYSTEM_TEXT,
  selectTypeInitialValues,
  SWEETENER_NATURE_MAPPED,
} from 'constants/index';
import { CommonSweetenerAndFlavorRadioGroupProps, DosageFormulationFormType } from 'interfaces';
import { SweetenerType, SweetenerNatureType } from 'generated/graphql';
import { flexCenterJustifyBetween } from 'theme/styleConstant';

const CommonSweetenerAndFlavorRadioGroup: FC<CommonSweetenerAndFlavorRadioGroupProps> = ({
  productSizeId,
}) => {
  const { watch, setValue } = useFormContext<DosageFormulationFormType>();

  const { flavorType, sweetenerType, productType: productTypeId, productFormulationType } = watch();
  const { value: productFormulationTypeId } = productFormulationType || {};

  const resetSweetenerSystemValueOnChange = () => {
    setValue('sweetenerSystem', selectTypeInitialValues);
  };

  const resetFlavorSystemValueOnChange = () => {
    setValue('flavorSystem', selectTypeInitialValues);
  };

  const handleFlavorClear = () => {
    setValue('flavorType', '');
    setValue('flavorSystem', selectTypeInitialValues);
  };

  const handleSweetenerClear = () => {
    setValue('sweetenerType', '');
    setValue('sweetenerSystem', selectTypeInitialValues);
  };

  const isFlavorSelectTypes =
    flavorType && productTypeId && productFormulationTypeId && (productSizeId ?? 'no_need');
  const isSweetenerSelectTypes = sweetenerType && productTypeId && productFormulationTypeId;

  return (
    <Fragment>
      <Grid item xs={12} xl={12}>
        <RadioGroupController
          name="flavorType"
          title={
            <Box sx={flexCenterJustifyBetween}>
              <Box>
                {FLAVOR_TYPE_TEXT}
                <ClearComponent onClick={handleFlavorClear} />
              </Box>
              <Typography color="primary">{OPTIONAL_TEXT}</Typography>
            </Box>
          }
          options={SWEETENER_NATURE_MAPPED}
          handleOnChange={resetFlavorSystemValueOnChange}
        />
      </Grid>
      <Grid item xs={12} xl={12}>
        <SweetenerSelector
          name="flavorSystem"
          title={FLAVOR_SYSTEM_TEXT}
          type={SweetenerType.Flavor}
          productTypeId={productTypeId}
          productSizeId={productSizeId}
          disabled={!isFlavorSelectTypes}
          nature={flavorType as SweetenerNatureType}
          productFormulationTypeId={productFormulationTypeId}
        />
      </Grid>
      <Grid item xs={12} xl={12}>
        <RadioGroupController
          title={
            <Box sx={flexCenterJustifyBetween}>
              <Box>
                {SWEETENER_TYPE_TEXT}
                <ClearComponent onClick={handleSweetenerClear} />
              </Box>

              <Typography color="primary">{OPTIONAL_TEXT}</Typography>
            </Box>
          }
          name="sweetenerType"
          options={SWEETENER_NATURE_MAPPED}
          handleOnChange={resetSweetenerSystemValueOnChange}
        />
      </Grid>

      <Grid item xs={12} xl={12}>
        <SweetenerSelector
          name="sweetenerSystem"
          productTypeId={productTypeId}
          title={SWEETENER_SYSTEM_TEXT}
          type={SweetenerType.Sweetener}
          disabled={!isSweetenerSelectTypes}
          nature={sweetenerType as SweetenerNatureType}
          handleChange={resetSweetenerSystemValueOnChange}
          productFormulationTypeId={productFormulationTypeId}
        />
      </Grid>
    </Fragment>
  );
};

export default CommonSweetenerAndFlavorRadioGroup;
