import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
// components
import TextEditor from 'components/common/TextEditor';
// interfaces
import { TextEditorControllerProps } from 'interfaces';

const TextEditorController: FC<TextEditorControllerProps> = ({ name, title }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { onChange, value } = field;
        const { error } = fieldState;
        const { message } = error || {};

        return (
          <FormControl fullWidth error={!!error}>
            <FormLabel>{title}</FormLabel>

            <TextEditor
              editorState={value || ''}
              onEditorStateChange={(state) => {
                onChange(state);
              }}
            />

            <FormHelperText error={!!error}>{message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default TextEditorController;
