import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImageListType, ImageType } from 'react-images-uploading';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FC, useCallback, useEffect, useState } from 'react';
// components
import ProfileCard from './ProfileCard';
import { Alert } from 'components/common/Alert';
import UploadImage from 'components/common/UploadImage';
// constants, schema, interfaces, validation, reducer
import { updateUserProfileSchema } from 'validationSchema';
import { ProfileFormProps, UpdateUserProfileType } from 'interfaces';
import { HTTP_STATUS, UpdateUserProfileInitialValues } from 'constants/index';
import { AttachmentTitle, AttachmentType, useUpdateUserInfoMutation } from 'generated/graphql';

const ProfileForm: FC<ProfileFormProps> = ({ user, loading: getLoading }) => {
  const { id } = user || {};
  // local states
  const [images, setImages] = useState<ImageType[]>([]);
  const [thumbnailId, setThumbnailId] = useState<string>('');

  const methods = useForm<UpdateUserProfileType>({
    defaultValues: UpdateUserProfileInitialValues,
    resolver: yupResolver(updateUserProfileSchema),
  });

  const { handleSubmit, setValue } = methods;

  const [updateUser, { loading: updateUserInfoLoading }] = useUpdateUserInfoMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateUserInfo } = data;
      const { response } = updateUserInfo;

      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
      } else {
        Alert.warning(message || '');
      }
    },
    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<UpdateUserProfileType> = async (data) => {
    const { firstName, lastName, phone } = data;
    await updateUser({
      variables: {
        updateUserInfoInput: {
          firstName: firstName,
          lastName: lastName,
          phone: phone,
        },
      },
    });
  };
  const setFormValues = useCallback(() => {
    const { firstName, lastName, phone, email } = user || {};
    setValue('email', email ?? '');
    setValue('phone', phone ?? '');
    setValue('lastName', lastName ?? '');
    setValue('firstName', firstName ?? '');
  }, [setValue, user]);

  useEffect(() => {
    user && setFormValues();
  }, [setFormValues, user]);

  const loading = getLoading || updateUserInfoLoading;

  const onImagesChange = (list: ImageListType, attachmentId?: string) => {
    attachmentId && setThumbnailId(attachmentId);
    setImages(list);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} xl={7}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ProfileCard loading={loading} />
          </form>
        </FormProvider>
      </Grid>
      <Grid item xs={12} xl={5}>
        <UploadImage
          isEdit
          id={id}
          imageType={AttachmentType.User}
          imageLogoTitle={AttachmentTitle.UserThumbnail}
          images={images}
          attachmentId={thumbnailId}
          onImageChange={onImagesChange}
          onChangeAttachmentId={(str) => setThumbnailId(str)}
        />
      </Grid>
    </Grid>
  );
};

export default ProfileForm;
