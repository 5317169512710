//imports
import * as yup from 'yup';
import { EditorState } from 'draft-js';
//constants, helper, interfaces
import {
  PASSWORD_TEXT,
  PASSWORD_REGEX,
  PASSWORD_VALIDATION_MESSAGE,
  CONFIRM_PASSWORD_TEXT,
  CONFIRM_PASSWORD_MATCH,
  ORGANIZATION_NAME_TEXT,
  PRIMARY_CATEGORY_TEXT,
  PRODUCT_TYPE_TEXT,
  SECONDARY_CATEGORY_TEXT,
  DEFINING_AMOUNT_TEXT,
  COST_TEXT,
  COMMON_NAME_TEXT,
  ADDED_SUGAR_TEXT,
  ARSENIC_TEXT,
  BIOTIN_TEXT,
  BORON_TEXT,
  CADMIUM_TEXT,
  CALCIUM_TEXT,
  CALORIES_TEXT,
  CARBOHYDRATES_TEXT,
  CHOLESTEROL_TEXT,
  CHOLINE_TEXT,
  DIETARY_FIBER_TEXT,
  FOLATE_TEXT,
  IODINE_TEXT,
  IRON_TEXT,
  LEAD_TEXT,
  MAGNESIUM_TEXT,
  MERCURY_TEXT,
  MOISTURE_IN_GRAMS_TEXT,
  MONOUNSATURATED_FAT_TEXT,
  NIACIN_TEXT,
  ORGANIC_NUMERICAL_TEXT,
  POLYUNSATURATED_FAT_TEXT,
  POTASSIUM_TEXT,
  PROTEIN_TEXT,
  RIBOFLAVIN_TEXT,
  SATURATED_FAT_TEXT,
  SODIUM_TEXT,
  THIAMIN_TEXT,
  TOTAL_FAT_TEXT,
  TOTAL_SUGARS_TEXT,
  TRANS_FAT_TEXT,
  VITAMIN_A_TEXT,
  VITAMIN_B12_TEXT,
  VITAMIN_B6_TEXT,
  VITAMIN_C_TEXT,
  VITAMIN_D_TEXT,
  VITAMIN_E_TEXT,
  VITAMIN_K2_TEXT,
  ZINC_TEXT,
  SUGGESTED_SUPPLIER_TEXT,
  COUNTRY_OF_MANUFACTURING_TEXT,
  COUNTRY_OF_ORIGIN_TEXT,
  OLD_PASSWORD_TEXT,
  NEW_PASSWORD_TEXT,
  CONFIRM_NEW_PASSWORD_TEXT,
  NUTRIENT_INFO_REGEX,
  FIRST_NAME_TEXT,
  LAST_NAME_TEXT,
  PHONE_TEXT,
  SHELF_LIFE_TEXT,
  SUGGESTED_OVERAGE_TEXT,
  LIMIT_UPTO_300_MSG,
  LABEL_NAME_TEXT,
  BULK_DENSITY_TEXT,
  TAPPED_DENSITY_TEXT,
  AVERAGE_PARTICLE_SIZE_TEXT,
  LOSS_ON_DRYING_TEXT,
  CITY_TEXT,
  STATE_TEXT,
  COUNTRY_TEXT,
  TEXT_REGEX,
  COMPANY_NAME_TEXT,
  NAME_TEXT,
  GUMMY_OVERAGE_TEXT,
  EMPTY_WEIGHT_TEXT,
  MIN_TEXT,
  MAX_TEXT,
  EXCIPIENT_TEXT,
  PERCENTAGE_TEXT,
  PER_KG_COST_TEXT,
  MAX_STAFF,
  LOAD_ADD_TEXT,
  LID_SIZE_TEXT,
  CC_TEXT,
  OZ_TEXT,
  BOTTLE_SIZE_TEXT,
  PRICE_TEXT,
  LOH_COST_TEXT,
  FILING_PERCENTAGE_TEXT,
  PRODUCT_SIZE_TEXT,
  SERVING_SIZE_TEXT,
  LOAD_ADD_UNIT_TEXT,
  TYPE_TEXT,
  QT_PER_MG_TEXT,
  SWEETENER_COMPONENT_TEXT,
  MG_PER_SERVING_TEXT,
  NATURE_TEXT,
  SIZE_TEXT,
  EXCIPIENT_LABEL_TEXT,
  PRODUCT_FORMULATION_TEXT,
  WEIGHT_TEXT,
  SUB_PRODUCT_TYPE_TEXT,
  PRODUCT_INGREDIENT_TEXT,
  CONVERSION_COST_TEXT,
} from 'constants/index';
import {
  maxMessage,
  invalidMessage,
  maxFieldMessage,
  minFieldMessage,
  requiredMessage,
  convertHtmlToText,
  invalidFloatMessage,
  invalidPhoneMessage,
  convertEditorStateToHtml,
} from 'lib/helper';
import {
  AddBottleCapacityFormType,
  AddBottlePriceFormType,
  AddBottleSizeFormType,
  AddExcipientFormType,
  AddExcipientLabelFormType,
  AddExcipientTypeFormType,
  ProductExcipientFormType,
  AddProductFormulationTypeForm,
  AddProductSizeFormType,
  AddProductTypeFormType,
  AddSubProductTypeFormType,
  AllergensCardType,
  ContactFormType,
  ForgotPasswordInput,
  ForgotUserInput,
  HeavyMetalsFormType,
  IngredientBasicInfoCardType,
  IngredientCostInfoCardType,
  IngredientCountriesCardType,
  IngredientDensityCardType,
  IngredientSupplierCardType,
  LoginUserInput,
  NutrientsCardType,
  NutrientsTypeObject,
  OrganizationFormType,
  StaffRoleFormType,
  SupplierFormType,
  UpdatePasswordFormType,
  UpdateUserProfileType,
  UserFormType,
  YupSchemaShape,
  AddSweetenerSystemFormType,
  AddSweetenerComponentFormType,
  AddSweetenerElementFormType,
  SubComponentsFormType,
  CarriersFormType,
  SubComponentSelectType,
  CarrierSelectType,
  AdditionalContactFormType,
  AdditionalContactSelectType,
  ProductIngredientFormType,
  FormulationSyrupTypeFormType,
  IngredientSyrupTypeFormType,
} from 'interfaces';
import { notRequiredPercentage, percentageRequired } from './commonValidations';
import {
  requiredMatches,
  notRequiredMatches,
  isEmail,
  requiredInteger,
  notRequiredPhone,
  selectorSchema,
  potencySchema,
  notRequiredPositiveNumber,
  requiredPositiveNumber,
  nutrientInfoSchema,
  zipCodeSchema,
  requiredFloat,
  notRequiredFloat,
} from './commonValidations';

export const heavyMetal = (label: string, isRequired: boolean = false) =>
  isRequired
    ? requiredMatches(label, invalidFloatMessage(label), NUTRIENT_INFO_REGEX)
    : notRequiredMatches(invalidFloatMessage(label), NUTRIENT_INFO_REGEX);

export const loginSchema = yup.object().shape<YupSchemaShape<LoginUserInput>>({
  email: isEmail(),
  password: yup
    .string()
    .required(requiredMessage(PASSWORD_TEXT))
    .matches(PASSWORD_REGEX, PASSWORD_VALIDATION_MESSAGE),
});

export const forgotSchema = yup.object().shape<YupSchemaShape<ForgotUserInput>>({
  email: isEmail(),
});

export const forgotPasswordSchema = yup.object().shape<YupSchemaShape<ForgotPasswordInput>>({
  password: yup
    .string()
    .required(requiredMessage(PASSWORD_TEXT))
    .matches(PASSWORD_REGEX, PASSWORD_VALIDATION_MESSAGE),
  confirmPassword: yup
    .string()
    .required(requiredMessage(CONFIRM_PASSWORD_TEXT))
    .oneOf([yup.ref('password'), ''], CONFIRM_PASSWORD_MATCH),
});

export const organizationSchema = yup.object().shape<YupSchemaShape<OrganizationFormType>>({
  organizationName: yup.string().trim().required(requiredMessage(ORGANIZATION_NAME_TEXT)).max(300),
  maxUser: requiredInteger(MAX_STAFF, 4, true),
});

export const roleSchema = yup.object().shape<YupSchemaShape<StaffRoleFormType>>({
  role: yup.string(),
});

export const updatePasswordSchema = yup.object().shape<YupSchemaShape<UpdatePasswordFormType>>({
  password: yup
    .string()
    .required(requiredMessage(PASSWORD_TEXT))
    .matches(PASSWORD_REGEX, PASSWORD_VALIDATION_MESSAGE),
});

export const userSchema = yup.object().shape<YupSchemaShape<UserFormType>>({
  firstName: yup.string().trim().required(requiredMessage(FIRST_NAME_TEXT)),
  lastName: yup.string().trim().required(requiredMessage(LAST_NAME_TEXT)),
  phone: notRequiredPhone(invalidPhoneMessage(PHONE_TEXT)),
  email: isEmail(),
});

export const addNewUserSchema = userSchema.concat(updatePasswordSchema).concat(organizationSchema);

export const editUserSchema = userSchema.concat(organizationSchema);

export const addNewStaffSchema = userSchema.concat(updatePasswordSchema).concat(roleSchema);

export const updateStaffSchema = userSchema.concat(roleSchema);

export const htmlValidation = (label: string) => {
  return yup.mixed().test('', maxMessage(label, 500), (val) => {
    if (val) {
      const html = convertEditorStateToHtml(val as EditorState);
      const txt = convertHtmlToText(html);
      return txt?.length <= 500;
    }
    return false;
  });
};

export const ingredientBasicInfoCardSchema = yup.object().shape<YupSchemaShape<IngredientBasicInfoCardType>>({
  latinName: htmlValidation(LABEL_NAME_TEXT),
  tradeMarkName: yup.string().trim().max(300, LIMIT_UPTO_300_MSG),
  scientificName: yup.string().trim().max(300, LIMIT_UPTO_300_MSG),
  type: yup.array().of(yup.string()),
  productTypes: yup.array().of(yup.string()).min(1, minFieldMessage(PRODUCT_TYPE_TEXT, 1)),
  category: selectorSchema(PRIMARY_CATEGORY_TEXT),
  name: yup.string().trim().required(requiredMessage(COMMON_NAME_TEXT)).max(300, LIMIT_UPTO_300_MSG),
  subCategory: selectorSchema(SECONDARY_CATEGORY_TEXT, false),
});

export const ingredientCostInfoCardSchema = yup.object().shape<YupSchemaShape<IngredientCostInfoCardType>>({
  potency: potencySchema(),
  shelfLife: notRequiredPositiveNumber(SHELF_LIFE_TEXT, 5),
  suggestedOverage: notRequiredPercentage(SUGGESTED_OVERAGE_TEXT),
  gummyOverage: notRequiredPercentage(GUMMY_OVERAGE_TEXT),
  cost: requiredPositiveNumber(COST_TEXT, 15),
  amount: yup.string().required(requiredMessage(DEFINING_AMOUNT_TEXT)),
});

export const ingredientSupplierCardSchema = yup.object().shape<YupSchemaShape<IngredientSupplierCardType>>({
  primarySupplier: selectorSchema(SUGGESTED_SUPPLIER_TEXT, false),
  secondarySupplier: selectorSchema(SUGGESTED_SUPPLIER_TEXT, false),
  tertiarySupplier: selectorSchema(SUGGESTED_SUPPLIER_TEXT, false),
});

export const ingredientCountriesCardSchema = yup.object().shape<YupSchemaShape<IngredientCountriesCardType>>({
  manufacturingCountry: selectorSchema(COUNTRY_OF_MANUFACTURING_TEXT, false),
  originCountry: selectorSchema(COUNTRY_OF_ORIGIN_TEXT, false),
});

export const ingredientDensityCardSchema = yup.object().shape<YupSchemaShape<IngredientDensityCardType>>({
  bulkDensity: nutrientInfoSchema(BULK_DENSITY_TEXT),
  tappedDensity: nutrientInfoSchema(TAPPED_DENSITY_TEXT),
  averageParticleSize: nutrientInfoSchema(AVERAGE_PARTICLE_SIZE_TEXT),
  lossOnDrying: heavyMetal(LOSS_ON_DRYING_TEXT),
});

export const allergensCardSchema = yup.object().shape<YupSchemaShape<AllergensCardType>>({
  allergens: yup.array().of(yup.string()),
});

export const nutrientsCardSchema = yup.object().shape<YupSchemaShape<NutrientsTypeObject>>({
  zinc: nutrientInfoSchema(ZINC_TEXT),
  addedSugar: nutrientInfoSchema(ADDED_SUGAR_TEXT),
  biotin: nutrientInfoSchema(BIOTIN_TEXT),
  boron: nutrientInfoSchema(BORON_TEXT),
  calcium: nutrientInfoSchema(CALCIUM_TEXT),
  calories: nutrientInfoSchema(CALORIES_TEXT),
  carbohydrates: nutrientInfoSchema(CARBOHYDRATES_TEXT),
  cholesterol: nutrientInfoSchema(CHOLESTEROL_TEXT),
  choline: nutrientInfoSchema(CHOLINE_TEXT),
  dietaryFiber: nutrientInfoSchema(DIETARY_FIBER_TEXT),
  folate: nutrientInfoSchema(FOLATE_TEXT),
  iodine: nutrientInfoSchema(IODINE_TEXT),
  iron: nutrientInfoSchema(IRON_TEXT),
  magnesium: nutrientInfoSchema(MAGNESIUM_TEXT),
  moisture: nutrientInfoSchema(MOISTURE_IN_GRAMS_TEXT),
  monounsaturatedFat: nutrientInfoSchema(MONOUNSATURATED_FAT_TEXT),
  niacin: nutrientInfoSchema(NIACIN_TEXT),
  organic: nutrientInfoSchema(ORGANIC_NUMERICAL_TEXT),
  polyunsaturatedFat: nutrientInfoSchema(POLYUNSATURATED_FAT_TEXT),
  potassium: nutrientInfoSchema(POTASSIUM_TEXT),
  protein: nutrientInfoSchema(PROTEIN_TEXT),
  riboflavin: nutrientInfoSchema(RIBOFLAVIN_TEXT),
  saturatedFat: nutrientInfoSchema(SATURATED_FAT_TEXT),
  sodium: nutrientInfoSchema(SODIUM_TEXT),
  thiamin: nutrientInfoSchema(THIAMIN_TEXT),
  totalFat: nutrientInfoSchema(TOTAL_FAT_TEXT),
  totalSugars: nutrientInfoSchema(TOTAL_SUGARS_TEXT),
  transFat: nutrientInfoSchema(TRANS_FAT_TEXT),
  vitaminA: nutrientInfoSchema(VITAMIN_A_TEXT),
  vitaminB12: nutrientInfoSchema(VITAMIN_B12_TEXT),
  vitaminB6: nutrientInfoSchema(VITAMIN_B6_TEXT),
  vitaminC: nutrientInfoSchema(VITAMIN_C_TEXT),
  vitaminD: nutrientInfoSchema(VITAMIN_D_TEXT),
  vitaminE: nutrientInfoSchema(VITAMIN_E_TEXT),
  vitaminK2: nutrientInfoSchema(VITAMIN_K2_TEXT),
  chloride: yup.string(),
  chromium: yup.string(),
  copper: yup.string(),
  manganese: yup.string(),
  molybdenum: yup.string(),
  pantothenicAcid: yup.string(),
  phosphorus: yup.string(),
  selenium: yup.string(),
});

export const heavyMetalSchema = yup.object().shape<YupSchemaShape<HeavyMetalsFormType>>({
  arsenic: heavyMetal(ARSENIC_TEXT),
  cadmium: heavyMetal(CADMIUM_TEXT),
  lead: heavyMetal(LEAD_TEXT),
  mercury: heavyMetal(MERCURY_TEXT),
});

export const nutrientsSchema = yup.object().shape<YupSchemaShape<NutrientsCardType>>({
  ingredientInfo: nutrientsCardSchema,
});

const carriersCardSchema = yup.object().shape<YupSchemaShape<CarrierSelectType>>({
  excipient: selectorSchema(NAME_TEXT),
  percentage: percentageRequired(PERCENTAGE_TEXT),
});

export const carriersSchema = yup.object().shape<YupSchemaShape<CarriersFormType>>({
  carriers: yup.array().of(carriersCardSchema),
});

const subComponentCardSchema = yup.object().shape<YupSchemaShape<SubComponentSelectType>>({
  name: yup.string().trim().required(requiredMessage(NAME_TEXT)),
  percentage: percentageRequired(PERCENTAGE_TEXT),
});

export const subComponentSchema = yup.object().shape<YupSchemaShape<SubComponentsFormType>>({
  subComponents: yup.array().of(subComponentCardSchema),
});

export const addNewIngredientSchema = ingredientBasicInfoCardSchema
  .concat(allergensCardSchema)
  .concat(nutrientsSchema)
  .concat(ingredientCostInfoCardSchema)
  .concat(ingredientSupplierCardSchema)
  .concat(ingredientCountriesCardSchema)
  .concat(heavyMetalSchema)
  .concat(ingredientDensityCardSchema)
  .concat(carriersSchema)
  .concat(subComponentSchema);

export const changePasswordSchema = yup.object({
  oldPassword: yup
    .string()
    .required(requiredMessage(OLD_PASSWORD_TEXT))
    .matches(PASSWORD_REGEX, PASSWORD_VALIDATION_MESSAGE),
  newPassword: yup
    .string()
    .required(requiredMessage(NEW_PASSWORD_TEXT))
    .matches(PASSWORD_REGEX, PASSWORD_VALIDATION_MESSAGE),
  conPassword: yup
    .string()
    .required(requiredMessage(CONFIRM_NEW_PASSWORD_TEXT))
    .oneOf([yup.ref('newPassword'), ''], CONFIRM_PASSWORD_MATCH),
});

export const updateUserProfileSchema = yup.object().shape<YupSchemaShape<UpdateUserProfileType>>({
  firstName: yup.string().trim().required(requiredMessage(FIRST_NAME_TEXT)),
  lastName: yup.string().trim().required(requiredMessage(LAST_NAME_TEXT)),
  phone: notRequiredPhone(invalidPhoneMessage(PHONE_TEXT)),
});

export const contactSchema = yup.object().shape<YupSchemaShape<ContactFormType>>({
  designation: yup.string(),
  address1: yup.string().trim(),
  address2: yup.string().trim(),
  phoneNo: yup.string().trim(),
  state: selectorSchema(STATE_TEXT, false),
  country: selectorSchema(COUNTRY_TEXT, false),
  email: isEmail(),
  name: yup.string().required(requiredMessage(NAME_TEXT)),
  city: notRequiredMatches(invalidMessage(CITY_TEXT), TEXT_REGEX),
  zipCode: zipCodeSchema(),
});

const additionalContactsCardSchema = yup.object().shape<YupSchemaShape<AdditionalContactSelectType>>({
  name: yup.string().trim().required(requiredMessage(NAME_TEXT)).max(100, maxMessage(NAME_TEXT, 100)),

  email: isEmail(true),
});

export const additionalContactsSchema = yup.object().shape<YupSchemaShape<AdditionalContactFormType>>({
  additionalContacts: yup.array().of(additionalContactsCardSchema),
});

export const supplierFormSchema = yup.object().shape<YupSchemaShape<SupplierFormType>>({
  companyName: yup.string().trim().required(requiredMessage(COMPANY_NAME_TEXT)),
  description: yup.string(),
});

export const supplierSchema = supplierFormSchema.concat(contactSchema).concat(additionalContactsSchema);

export const updateProductTypeSchema = yup.object().shape<YupSchemaShape<AddProductTypeFormType>>({
  name: yup.string().trim().required(requiredMessage(NAME_TEXT)),
});

export const updateSubProductTypeSchema = yup.object().shape<YupSchemaShape<AddSubProductTypeFormType>>({
  name: yup.string().trim().required(requiredMessage(NAME_TEXT)),
});

export const addSubProductTypeSchema = yup.object().shape<YupSchemaShape<AddSubProductTypeFormType>>({
  name: yup.string().trim().required(requiredMessage(NAME_TEXT)),
  productType: selectorSchema(PRODUCT_TYPE_TEXT),
});

export const updateProductSizeSchema = yup.object().shape<YupSchemaShape<AddProductSizeFormType>>({
  name: yup.string().trim().required(requiredMessage(NAME_TEXT)),
  emptyWtMg: notRequiredPositiveNumber(EMPTY_WEIGHT_TEXT, 5, true),
  min: notRequiredPositiveNumber(MIN_TEXT, 11, true),
  max: notRequiredPositiveNumber(MAX_TEXT, 11, true),
  productType: selectorSchema(PRODUCT_TYPE_TEXT),
});

export const updateProductFormulationTypeSchema = yup
  .object()
  .shape<YupSchemaShape<AddProductFormulationTypeForm>>({
    name: yup.string().trim().required(requiredMessage(NAME_TEXT)),
    conversionCost: requiredPositiveNumber(CONVERSION_COST_TEXT, 5, true),
    innerComponentCost: yup.string(),
    isInnerCapTab: yup.boolean(),
    lohInside: yup.string(),
    productType: selectorSchema(PRODUCT_TYPE_TEXT),
  });

export const updateExcipientSchema = yup.object().shape<YupSchemaShape<AddExcipientFormType>>({
  name: yup.string().trim().required(requiredMessage(NAME_TEXT)),
});
export const updateExcipientLabelSchema = yup.object().shape<YupSchemaShape<AddExcipientLabelFormType>>({
  name: yup.string().trim().required(requiredMessage(NAME_TEXT)),
});

export const updateExcipientTypeSchema = yup.object().shape<YupSchemaShape<AddExcipientTypeFormType>>({
  name: yup.string().trim().required(requiredMessage(NAME_TEXT)),
  perKgCost: requiredFloat(PER_KG_COST_TEXT, 1, 5),
  loadAdd: requiredFloat(LOAD_ADD_TEXT, 1, 5),
  loadAddUnit: yup.string().required(requiredMessage(LOAD_ADD_UNIT_TEXT)),
  productType: selectorSchema(PRODUCT_TYPE_TEXT),
  excipientLabel: yup.string().required(requiredMessage(EXCIPIENT_LABEL_TEXT)),
});

export const productExcipientTypeSchema = yup.object().shape<YupSchemaShape<ProductExcipientFormType>>({
  perKgCost: requiredFloat(PER_KG_COST_TEXT, 1, 6),
  percentage: percentageRequired(PERCENTAGE_TEXT),
  excipient: selectorSchema(EXCIPIENT_TEXT),
  suppliers: yup
    .array()
    .of(selectorSchema(SUGGESTED_SUPPLIER_TEXT))
    .max(3, maxFieldMessage(SUGGESTED_SUPPLIER_TEXT, 3)),
});

export const updateBottleSizeTypeSchema = yup.object().shape<YupSchemaShape<AddBottleSizeFormType>>({
  name: yup.string().trim().required(requiredMessage(NAME_TEXT)),
  lidSize: notRequiredFloat(LID_SIZE_TEXT, 1, 5),
  cc: notRequiredFloat(CC_TEXT, 1, 5),
  oz: notRequiredFloat(OZ_TEXT, 1, 5),
  productTypes: yup
    .array()
    .of(selectorSchema(PRODUCT_TYPE_TEXT))
    .min(1, minFieldMessage(PRODUCT_TYPE_TEXT, 1)),
});

export const updateBottlePriceTypeSchema = yup.object().shape<YupSchemaShape<AddBottlePriceFormType>>({
  price: requiredFloat(PRICE_TEXT),
  bottleSize: selectorSchema(BOTTLE_SIZE_TEXT),
  type: yup.string().trim().required(requiredMessage(TYPE_TEXT)),
  suppliers: yup
    .array()
    .of(selectorSchema(SUGGESTED_SUPPLIER_TEXT))
    .min(1, minFieldMessage(SUGGESTED_SUPPLIER_TEXT, 1))
    .max(3, maxMessage(SUGGESTED_SUPPLIER_TEXT, 3)),
});

export const bottleCapacityTypeSchema = yup.object().shape<YupSchemaShape<AddBottleCapacityFormType>>({
  lohCost: requiredFloat(LOH_COST_TEXT),
  fillingPercentage: notRequiredPercentage(FILING_PERCENTAGE_TEXT),
  bottleSize: selectorSchema(BOTTLE_SIZE_TEXT),
  productType: selectorSchema(PRODUCT_TYPE_TEXT),
  productSize: selectorSchema(PRODUCT_SIZE_TEXT),
  servingSize: selectorSchema(SERVING_SIZE_TEXT, false),
});

export const updateSweetenerSystemTypeSchema = yup
  .object()
  .shape<YupSchemaShape<AddSweetenerSystemFormType>>({
    name: yup.string().trim().required(requiredMessage(NAME_TEXT)),
    nature: yup.string().trim().required(requiredMessage(NATURE_TEXT)),
    size: yup.string().trim().required(requiredMessage(SIZE_TEXT)),
    productType: selectorSchema(PRODUCT_TYPE_TEXT),
    costPerServing: requiredPositiveNumber(COST_TEXT, 9),
    mgPerServing: requiredPositiveNumber(MG_PER_SERVING_TEXT, 9),
  });

export const updateSweetenerComponentTypeSchema = yup
  .object()
  .shape<YupSchemaShape<AddSweetenerComponentFormType>>({
    name: yup.string().trim().required(requiredMessage(NAME_TEXT)),
    costPerKg: requiredPositiveNumber(PER_KG_COST_TEXT, 9),
  });

export const productIngredientSchema = yup.object().shape<YupSchemaShape<ProductIngredientFormType>>({
  name: yup.string().trim().required(requiredMessage(NAME_TEXT)).max(100, maxMessage(NAME_TEXT, 100)),
});

export const updateSweetenerElementTypeSchema = yup
  .object()
  .shape<YupSchemaShape<AddSweetenerElementFormType>>({
    qtPerMg: requiredPositiveNumber(QT_PER_MG_TEXT, 9),
    sweetenerComponent: selectorSchema(SWEETENER_COMPONENT_TEXT),
  });

export const formulationSyrupTypeSchema = yup.object().shape<YupSchemaShape<FormulationSyrupTypeFormType>>({
  cost: requiredPositiveNumber(COST_TEXT, 9),
  weight: requiredPositiveNumber(WEIGHT_TEXT, 9),
  productType: selectorSchema(PRODUCT_TYPE_TEXT),
  productSize: selectorSchema(PRODUCT_SIZE_TEXT),
  subProductType: selectorSchema(SUB_PRODUCT_TYPE_TEXT, false),
  productFormulationType: selectorSchema(PRODUCT_FORMULATION_TEXT),
});

export const ingredientSyrupTypeSchema = yup.object().shape<YupSchemaShape<IngredientSyrupTypeFormType>>({
  productIngredient: selectorSchema(PRODUCT_INGREDIENT_TEXT),
  percentage: percentageRequired(),
});

export * from './formulaValidation';
