import { FC } from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NoDataFoundIcon } from 'assets/svgs';
// components
import { AuthLayout } from '../Auth/AuthLayout';
// constants
import {
  AUTH_LINKS,
  GO_TO_HOME_TEXT,
  PAGE_NOT_FOUND_TEXT,
  PAGE_NOT_FOUND_MSG_TEXT,
} from '../../constants/index';
import { textTransform } from '../../styles/commonComponentStyle';

const NotFound: FC = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <AuthLayout title={PAGE_NOT_FOUND_TEXT} subTitle={PAGE_NOT_FOUND_MSG_TEXT}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mt={5} mb={10}>
          <NoDataFoundIcon />
        </Box>

        <Button variant="contained" sx={textTransform} onClick={() => navigate(AUTH_LINKS.LOGIN_LINK)}>
          {GO_TO_HOME_TEXT}
        </Button>
      </Box>
    </AuthLayout>
  );
};

export default NotFound;
