// imports
import { drawerClasses, SxProps, Theme } from '@mui/material';
// constants
import theme from 'theme';
import { drawerWidth } from 'constants/index';

export const shapeStyles: SxProps<Theme> = {
  borderRadius: '50%',
  width: ({ spacing }: Theme) => spacing(5),
  height: ({ spacing }: Theme) => spacing(5),
  bgcolor: ({ palette }: Theme) => palette.customBlackColor.light,
};

export const appBarFixedStyles = {
  width: { lg: `calc(100% - ${drawerWidth}px)` },
  ml: { lg: `${drawerWidth}px` },
};

export const drawerOpenButton: SxProps<Theme> = {
  display: { lg: 'none' },
  color: ({ palette }: Theme) => palette.primary.main,
};

export const navComponent = {
  width: { lg: drawerWidth },
  flexShrink: { lg: 0 },
};

export const drawerKeepMounted = {
  display: { xs: 'block', sm: 'block', md: 'block', lg: 'none' },
  [`& .${drawerClasses.paper}`]: {
    boxSizing: 'border-box',
    width: drawerWidth,
    backgroundColor: ({ palette }: Theme) => palette.secondary.main,
  },
};

export const drawerPermanent = {
  // display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: ({ palette }: Theme) => palette.secondary.main,
  },
};

export const drawerContentBoxStyled = {
  width: { lg: `calc(100% - ${drawerWidth}px)` },
};

export const toolbarIconButton: SxProps<Theme> = {
  py: { sm: 2, xs: 2 },
  borderBottom: `1px dashed ${theme.palette.customBlueColor.contrastText}`,

  [theme.breakpoints.up('lg')]: {
    boxShadow: 'none',
    p: theme.spacing(0),
    minHeight: theme.spacing(0),
  },
};
