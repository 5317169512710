import { styled, Box, Tabs, Tab } from '@mui/material';
import { flexCenter } from 'theme/styleConstant';

export const AuthLayoutContainer = styled(Box)(({ theme }) => ({
  paddingInline: theme.spacing(3),
  minHeight: 'calc(100vh - 169px)',
  ...flexCenter,

  [theme.breakpoints.down('md')]: {
    paddingInline: theme.spacing(2),
  },
}));

export const DrawerContentBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(5),
  paddingTop: theme.spacing(0),
  marginTop: theme.spacing(4.375),

  [theme.breakpoints.down('lg')]: {
    width: '100%',
    padding: theme.spacing(11, 2, 2),
  },

  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: theme.spacing(8, 2, 2),
  },
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  padding: theme.spacing(0, 3),
  borderRadius: theme.spacing(2, 2, 0, 0),
  backgroundColor: theme.palette.customShadowColor.main,
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'capitalize',
  color: theme.palette.customGrayColor.main,
}));
