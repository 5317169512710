import { FC, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
// components
import Mini from './components/Mini';
import BiLayer from './components/BiLayer';
import ExcipientFields from '../Excipients';
import Chewable from './components/Chewable';
import TriLayer from './components/TriLayer';
import SingleLayer from './components/SingleLayer';
import Effervescent from './components/Effervescent';
// graphql, constants, interface
import { DosageFormulationFormType } from 'interfaces';
import { ProductFormulationTypes } from 'generated/graphql';

const TabletForm: FC = () => {
  const { watch } = useFormContext<DosageFormulationFormType>();
  const { productFormulationType } = watch();
  const { type } = productFormulationType || {};

  const getForm = () => {
    switch (type) {
      case ProductFormulationTypes.SingleLayerTablet:
        return <SingleLayer />;

      case ProductFormulationTypes.BiLayeredTablet:
        return <BiLayer />;

      case ProductFormulationTypes.TriLayerTablet:
        return <TriLayer />;

      case ProductFormulationTypes.MiniTablet:
        return <Mini />;

      case ProductFormulationTypes.ChewableTablet:
        return <Chewable />;

      case ProductFormulationTypes.EffervescentTablet:
        return <Effervescent />;

      default:
        return <Fragment />;
    }
  };

  return (
    <Fragment>
      {getForm()}
      <ExcipientFields />
    </Fragment>
  );
};
export default TabletForm;
